import React from 'react'
import styled from 'styled-components';

const Styles = styled.div`
`;

function JugadorCard({
    esConductor,
    item,
}) {
    return (
        <Styles className="row border border-primary redondeado shadow-sm p-3 m-3">
            <div className="col-10 d-flex justify-content-between">
                <h6 className="text-bold m-0">
                    {item.nombreCompleto}
                </h6>
                {
                    esConductor && (
                        <span className="badge rounded-pill badge-warning">CONDUCTOR</span>
                    )
                }
            </div>
            
        </Styles>
    )
}

JugadorCard.defaultProps = {
    esConductor: false,
};

export default JugadorCard
