import React, {useState, useEffect, useCallback, useMemo} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import * as CuadrillasAction from "@app/store/actions/cuardillas";
import { Spinner, Collapse } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import CuadrillaLugaresService from '@app/services/cuadrillaLugares.service';
import { Button, DateWeek, Grilla, InputCheck, TableCellFlota, TablePaginated, WidgetCuadrilla, WidgetTitle } from "@app/components/index";
import { format, getDay, parseISO, addDays, isSameDay } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';
import FlotaLugaresService from '@app/services/flotaLugares.service';
import usePermisos from '@app/hooks/usePermisos';

function CuadrillasPlanificacionPage({
    cuadrillas,
    flotas,

    limpiar,
    iniciar,
    modificar,
    recuperarFlotas,
}) {
    AOS.init();
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek())
    const [isLoading, setIsLoading] = useState(false);
    const [flotasData, setFlotasData] = useState([]);
    const [lugaresData, setLugaresData] = useState([]);
    const [cuadrillasData, setCuadrillasData] = useState([]);
    const [isMostrarFlotas, setIsMostrarFlotas] = useState(false)
    const [isFlotasTable, setIsFlotasTable] = useState(true);
    const [weekGrid, setWeekGrid] = useState([]);
    const [defaultCuadrillas, setDefaultCuadrillas] = useState([]);

    const onGetHeight = (item)=>{
        const newH = item.flota ? 3: 2;
        return newH;
    }

    const onChangeMostrar = () => {
        setIsMostrarFlotas(!isMostrarFlotas)
    }


    const iniciarWeekGrid = (start) => {
        const newWeekGrid = [1,2,3,4,5,6].map(day=>{
            const date = addDays(start,(day-1));
            return {
                id: uuidv4(),
                title: FechaFunction.format(date,'eee').toUpperCase(),
                subtitle: FechaFunction.format(date,'dd'),
                weekDay: day,
                active: isSameDay(date,new Date()),
            }
        });
        return newWeekGrid;
    }

    const weekGridMemo = useMemo(() => iniciarWeekGrid(rangeDate.start), [rangeDate]);

    const actualizar = ( customDates = rangeDate) => {
        const { start, end} = customDates; 
        const query = {
            fechaIni: format(start,'yyyy-MM-dd'),
            fechaFin: format(end,'yyyy-MM-dd'),
        };
        return Promise.all([
            recuperarFlotas(query).then((response)=>{
                setFlotasData(response);
                return response;
            }),
            CuadrillaLugaresService.getAll({
                ...query,
                periodo: 'TT,TM,TD,TC',
                sinFlotas: true,
            }).then((response)=>{
                setLugaresData(response);
                return response;
            }),
            FlotaLugaresService.getAll(query).then((response)=>{
                setCuadrillasData(response);
                return response;
            }),
        ]).then((response)=>{
            const [responseFlotas,responseLugares,responseCuadrillas] = response;
            const aux = responseLugares
            .map((cl)=>{
                const fecha = parseISO(cl.fecha);
                return {
                    id: uuidv4(),
                    cuadrillaLugarId:cl.id,
                    cuadrillaDisponibleLugar: cl,
                    weekDay: getDay(fecha),
                };
            });
            responseCuadrillas.forEach(fj=>{
                const fecha = parseISO(fj.fecha);
                fj.weekDay = getDay(fecha);
            });
            const aux2 = [...responseCuadrillas,...aux];
            setDefaultCuadrillas(aux2);
            iniciar(aux2);
        })
    }

    const onChangeDate = (newDates) => {
        setRangeDate(newDates);
        setIsLoading(true);
        iniciarWeekGrid(newDates.start);
        actualizar(newDates).finally(()=>{
            setIsLoading(false);
        });
    }

    const onFilterFlotas = useCallback((rows, columnNames, filterValue) => {
        return rows.filter(({original}) => {
            const { jugadores } = original;
            const cleanRowData = jugadores.flatMap(fj=>[String(fj.dni),String(fj.nombre).toLowerCase()]);
            return cleanRowData.find(rowString => rowString.includes(filterValue.toLowerCase()));
        });
    }, []);

    const columnsFlotaTable = React.useMemo(
        () => [
            {
                Header: 'Flota',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0 d-flex justify-content-between',
                isGlobalFilter: true,
                Cell: (props) => <TableCellFlota {...props} isSelected={cuadrillas.find(({flota})=>flota?.id===props?.original?.id)} />,
            },
        ],
        []
    );

    const onChangeGrilla = (stacks,weekDay) => {
        stacks.forEach(stack=>{
            modificar(stack)
        })
    }

    const onClickGuardar = () =>{
        const forms = [];
        cuadrillas
            .filter(cl=>cl.flota)
            .forEach(cl=>{
            const form = {
                flotaId: cl.flota.id,
                cuadrillaLugarId: cl.cuadrillaLugarId,
            }
            if(Number.isInteger(cl.id)){
                form.id = cl.id;
                forms.push(FlotaLugaresService.update(form));
            } else {
                forms.push(FlotaLugaresService.register(form));
            }
        });
        cuadrillasData.forEach(fl=>{
            if(cuadrillas.findIndex(cl=>cl.cuadrillaLugarId===fl.cuadrillaLugarId && cl.flota)<0){
                forms.push(FlotaLugaresService.destroy(fl.id));
            }
        })
        setIsLoading(true);
        Promise.all(forms).then(()=>{
            return actualizar();
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }

    useEffect(() => {
        // window.location.reload(true);
        setIsLoading(true);
        actualizar().then(()=>{
            setIsLoading(false);
        });
        iniciarWeekGrid(rangeDate.start);
    }, []);


    return (
        <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 my-2 text-center">
                            <h3 className="" data-aos="fade-up">
                                Cuadrillas
                                {
                                    isLoading && (
                                        <Spinner className="spinner-border text-primary ml-1 mb-1" animation="border" />
                                    )
                                }
                            </h3>
                        </div>
                        <div className="col-3 text-left bg-white border shadow-sm rounded-lg p-3 bordered" data-aos="fade-up">
                            <div id="draggableZoneFlotaJugadores">
                                <div className="grid-panel">
                                    {
                                        (permisos['api.flotaLugar.lugares.store'] ||
                                        permisos['api.flotaLugar.lugares.update']) && (
                                        <div className="d-grid gap-2">
                                            <Button block onClick={onClickGuardar} isLoading={isLoading}>GUARDAR</Button>
                                        </div>
                                    )}
                                    <div className="form-group mt-2">
                                        <label htmlFor="semana-input">Semana</label>
                                        <DateWeek onChange={onChangeDate} isLoading={isLoading} />
                                    </div>
                                    <div className="form-check">
                                        <InputCheck label="Mostrar todas las flotas" onChange={onChangeMostrar} checked={isMostrarFlotas} />
                                    </div>
                                    <Button block theme='gray' className="my-1" onClick={() => setIsFlotasTable(!isFlotasTable)}>Flotas</Button>
                                    <Collapse in={isFlotasTable}>
                                        <div id="vehiculosTable">
                                            <TablePaginated 
                                                customGlobalFilter={onFilterFlotas} 
                                                isShowHeader={false} 
                                                columns={columnsFlotaTable} 
                                                data={flotasData} 
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="col-9" id="draggableGrid" data-aos="fade-up">
                            <div className="row week-grid ml-1">
                                {
                                    weekGridMemo.map(({id,weekDay,title,subtitle,active})=>{
                                        const currentCuadrillas = useMemo(() => defaultCuadrillas.filter(cl=>cl.weekDay===weekDay), [defaultCuadrillas]);
                                        return (
                                            <div className={`col-2 bg-white border pt-2 rounded shadow-sm week-${weekDay}`} key={id}>
                                                <WidgetTitle
                                                    title={title}
                                                    subtitle={subtitle}
                                                    active={active}
                                                />
                                                <Grilla
                                                    isLoading={isLoading}
                                                    isFullWidth
                                                    acceptWidgets={false}
                                                    values={cuadrillas}
                                                    defaultValues={currentCuadrillas}
                                                    widget={WidgetCuadrilla}
                                                    uniqueKeyLabel='cuadrillaLugarId'
                                                    getHeight={onGetHeight}
                                                    onChange={(stacks)=>onChangeGrilla(stacks,weekDay)}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    flotas: state.cuadrillas.flotas,
    cuadrillas: state.cuadrillas.cuadrillas,
});

const mapDispatchToProps = (dispatch) => ({
    limpiar: () => dispatch(CuadrillasAction.limpiarCuadrillas()),
    iniciar: (data) => dispatch(CuadrillasAction.iniciarCuadrillas(data)),
    modificar: (data) => dispatch(CuadrillasAction.modificarCuadrillas(data)),
    recuperarFlotas: (data) => dispatch(CuadrillasAction.recuperarFlotas(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CuadrillasPlanificacionPage);
