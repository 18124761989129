import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import CuadrillaDisponiblesService from '@app/services/cuadrillaDisponibles.service';

function CuadrillaDisponibleVerPage({
    location,
}) {
    const { item } = (location && location.state) || { };
    const { id } = useParams();
    const [cuadrilla, setCuadrilla] = useState(item);

    useEffect(() => {
        CuadrillaDisponiblesService.getById((id)).then((response)=>{
            setCuadrilla(response);
        })
        return () => {
            
        }
    }, [])
    return (
        <div>
            <h1>Nada</h1>
        </div>
    )
}

export default CuadrillaDisponibleVerPage
