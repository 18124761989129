import React,{useState,useCallback, useEffect} from 'react'
import { CuadrillaDisponibleCard, TimeSlider } from '@app/components/index';
import styled from 'styled-components';

const Styles = styled.div`
`;

function FormTareaHorario({
    cuadrillaLugar,
    onChange,
}) {
    const [time, setTime] = useState(null);

    useEffect(() => {
        onChange(time);
        return () => {

        }
    }, [time])
    return (
        <Styles>
            <CuadrillaDisponibleCard item={cuadrillaLugar} />
                {
                    cuadrillaLugar.periodo === 'TM' && (
                        <div className="col-lg-12">
                            {cuadrillaLugar.tareasTM.map(t=>{
                                return (
                                    <TimeSlider 
                                        key={t.id}
                                        disabled
                                        hideButtons
                                        value={{
                                            start: t.horaIni,
                                            end: t.horaFin,
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )
                }
                {
                    cuadrillaLugar.periodo === 'TT' && (
                        <div className="col-lg-12">
                            {cuadrillaLugar.tareasTT.map(t=>{
                                return (
                                    <TimeSlider 
                                        key={t.id}
                                        disabled
                                        hideButtons
                                        value={{
                                            start: t.horaIni,
                                            end: t.horaFin,
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )
                }
                <div className="col-lg-12 mt-4 mb-5">
                    <TimeSlider
                        defaultPeriodo={cuadrillaLugar.periodo}
                        onChange={setTime}
                        id="timerInput"
                    />
                </div>
        </Styles>
    )
}

export default FormTareaHorario
