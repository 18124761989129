import axios from 'axios';
import store from '@app/store/index';
import {logout} from '@app/store/actions/auth';
import {toast} from 'react-toastify';
import EchoWS from '@app/utils/ws';

// axios.defaults.baseURL = "http://127.0.0.1:8000/api";

axios.defaults.baseURL = "http://calendario.nubicom.com.ar/api";

/* axios.defaults.baseURL = "http://45.224.141.50/api"; */

axios.defaults.headers.common = {
    "Accept": "application/json"
};
  
function closeModals(){
    const {stack} = store.getState().modalProvider;
    if(stack){
        stack.forEach(({id})=>{
            store.dispatch({
                type: '@react-redux-modal-provider.hide',
                id,
            })
        })
    }
    
}

axios.interceptors.request.use((config) => {
    const {token, tokenNubi} = store.getState().auth;
    if(token){
        config.headers.Authorization = `Bearer ${token}`;
        axios.defaults.headers.common = {
            "nubi-token": tokenNubi
        };
    }
    const socketId = EchoWS.socketId();
    if(socketId){
        config.headers['X-Socket-ID'] = socketId;
    }
    return config;
}, (error) => {
    
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if(response?.config?.responseType === "blob"){
        return response;
    }
    return response.data;
}, (error) => {
    if(!error.response){
        return false;
    }
    const {token} = store.getState().auth;
    if (error && error.response.status === 401) {
        closeModals();
        if(token){
            store.dispatch(logout());
        }
    }
    if (error.response.status === 403 || error.response.status === 422 ) {
        toast.error(error.response?.data?.message || error.response?.data?.error || 'Ups! Ocurrío un problema.',{
            autoClose: false,
            hideProgressBar: false,
        });
        return Promise.reject(error.response.data);
    }
    if (error.response.status === 500 ){
        toast.error('Ups! Ocurrío un problema. Comuniquese con el Administrador del sistema.',{
            autoClose: true,
        });
    }

    if (error.response.status === 423) {
        toast.error(error.response?.data?.message || error.response?.data?.error || 'Ups! Ocurrío un problema.',{
            autoClose: false,
            hideProgressBar: false,
        });
        window.location.href = "/";
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
});
