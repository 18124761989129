import axios from 'axios';
const base = 'logs';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}
function getAllTareas(query = null) {
    return axios.get(`${base}/tareas`, {
        params: query
    });
}


const LogService = {
    getAll: getAll,
    getAllTareas: getAllTareas,
    
};

export default LogService;