import React, {useState, useEffect, useCallback} from "react";
import {connect} from 'react-redux';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import { Spinner, Collapse } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { Button, DateWeek, Grilla, InputCheck, TableCellFlota, TablePaginated, WidgetTarea, WidgetTitle } from "@app/components/index";
import { format, getDay, parseISO, addDays, isSameDay } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';
import * as CuadrillasAction from "@app/store/actions/cuardillas";
import TareasService from "@app/services/tareas.service";
import {showModal} from 'react-redux-modal-provider';
import ModalTareaFechaEditar from "@app/modals/tarea/ModalTareaFechaEditar";
import ModalFlotaJugadorVer from "@app/modals/flota-jugador/ModalFlotaJugadorVer";
import usePermisos from '@app/hooks/usePermisos';

function itemListFlota(flota){
    return (
        <li className="btn-jugadores" id={flota.id}>
            <div className="list-group-item-title">{flota.vehiculoPatente}</div>
            <div className="list-group-item-subtitle"></div>
        </li>
    );
}

/**
 * ASIGNACION DE TAREAS PYMES POR FLOTA
 * @returns
 */
function CuadrillaLugaresPage ({
    tareas,
    flotas,

    limpiar,
    iniciar,
    agregar,
    modificar,
    recuperarFlotas,
}) {
    AOS.init();
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek())
    const [isLoading, setIsLoading] = useState(false);
    const [flotasData, setFlotasData] = useState([]);
    const [isMostrarFlotas, setIsMostrarFlotas] = useState(false);
    const [isTareasTable, setIsTareasTable] = useState(true);
    const [isFlotasTable, setIsFlotasTable] = useState(false);
    const [weekGrid, setWeekGrid] = useState([]);
    const [tareasSinFechaData, setTareasSinFechaData] = useState([]);
    const [tareasData, setTareasData] = useState([]);

    const onClickTareasTable = () =>{
        setIsTareasTable(!isTareasTable);
        setIsFlotasTable(isTareasTable);
    }
    const onClickFlotasTable = () =>{
        setIsTareasTable(isFlotasTable);
        setIsFlotasTable(!isFlotasTable);
    }

    const onGetHeight = (item) => {
        const newH = item.flota ? 3: 2;
        return newH;
    }

    const onChangeMostrar = () => {
        setIsMostrarFlotas(!isMostrarFlotas)
    }

    const iniciarWeekGrid = (start) => {
        const newWeekGrid = [1,2,3,4,5,6].map(day=>{
            const date = addDays(start,(day-1));
            return {
                id: uuidv4(),
                title: FechaFunction.format(date,'eee').toUpperCase(),
                subtitle: FechaFunction.format(date,'dd'),
                weekDay: day,
                active: isSameDay(date,new Date()),
            }
        });
        setWeekGrid(newWeekGrid);
    }

    const actualizar = ( customDates = rangeDate) => {
        const { start, end} = customDates; 
        const query = {
            fechaIni: format(start,'yyyy-MM-dd'),
            fechaFin: format(end,'yyyy-MM-dd'),
            estadoTareaId: '1,2,3',
            categoria: "VIP 1,VIP 2,VIP 3,PYME 1,PYME 2,PYME 3,PYME 4,SC",
            sinCuadrillaLugar: true,
        };
        const queryTarea = {
            estadoTareaId: '1,2,3',
            sinCuadrillaLugar: true,
            sinAsignar: true,
            sinFecha: true,
            categoria: "VIP 1,VIP 2,VIP 3,PYME 1,PYME 2,PYME 3,PYME 4,SC",
        }
        return Promise.all([
            recuperarFlotas(query).then((response)=>{
                setFlotasData(response);
                return response;
            }),
            TareasService.getAll(queryTarea).then((response)=>{
                setTareasSinFechaData(response);
                return response;
            }),
            TareasService.getAll(query).then((response)=>{
                setTareasData(response);
                return response;
            }),
        ]).then((response)=>{
            const [responseFlotas,responseTareasSinFecha,responseTareas] = response;
            iniciar(responseTareas.map((ta)=>{
                const fecha = parseISO(ta.fecha);
                return {
                    ...ta,
                    weekDay: getDay(fecha),
                }
            }));
        });
    }

    const onChangeDate = (newDates) => {
        setRangeDate(newDates);
        setIsLoading(true);
        iniciarWeekGrid(newDates.start);
        actualizar(newDates).finally(()=>{
            setIsLoading(false);
        });
    }

    const onFilterTareas = useCallback((rows, columnNames, filterValue) => {
        return rows.filter(({original}) => {
            const { clientenombre, clienteDocumento } = original;
            const cleanRowData = [String(clientenombre).toLowerCase(), String(clienteDocumento).toLowerCase()];
            return cleanRowData.find(rowString => rowString.includes(filterValue.toLowerCase()));
        });
    }, []);

    const onFilterFlotas = useCallback((rows, columnNames, filterValue) => {
        return rows.filter(({original}) => {
            const { jugadores, vehiculoPatente, vehiculo } = original;
            const cleanRowData = jugadores.flatMap(fj=>[String(fj.dni),String(fj.nombre).toLowerCase()])
                .concat([String(vehiculoPatente).toLowerCase(),]);
            return cleanRowData.find(rowString => rowString.includes(filterValue.toLowerCase()));
        });
    }, []);

    const onTareaAgregar = (item) => {
        item.weekDay = getDay(parseISO(item.fecha));
        const index = tareas.findIndex(ta=>ta.id === item.id);
        if(index>=0){
            modificar(item);
        } else {
            agregar(item);
        }
    }

    const onClickTareaAgregar = (item) => {
        const { start, end } = rangeDate;
        let newItem = tareas.find(ta=>ta.id === item.id);
        if(!newItem){
            newItem = item;
        }

        showModal(ModalTareaFechaEditar,{
            item: newItem,
            defaultDate: start,
            minDate: start,
            maxDate: end,
            onGuardar: onTareaAgregar,
        });
    }

    const columnsTareaTable = React.useMemo(
        () => [
            {
                Header: 'Tareas',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0 d-flex justify-content-between',
                isGlobalFilter: true,
                Cell: ({row}) => {
                    const { original } = row;
                    const title = original?.clienteNombre || ''
                    const subtitle = `${original.lugar?.nombre}`;
                    const seleccionado = tareas.find(({id})=>id===original.id);
                    return (
                        <>
                            <div className="table-tareas-row-descripcion">
                                {title}
                                <div>
                                    {subtitle}
                                </div>
                            </div>
                            <div className={`table-tareas-row-patente grid-stack-item-content grid-stack-row list-${original.id}-vehiculo ${seleccionado?'grid-stack-item-seleccionado':''}`}>
                                <div
                                    id={original.id}
                                    className={`grid-stack-item grid-stack-item-tarea tarea-${original.id}`}
                                    gs-w="12"
                                    gs-h="2"
                                    gs-id={original.id}
                                >
                                    <div className="grid-stack-item-content">
                                        <div
                                            className="grid-stack-item-nombre"
                                            onClick={() => onClickTareaAgregar(original)}
                                            aria-hidden="true"
                                        >
                                            {original.lugar?.nombreCorto}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                }
            },
        ],
        [tareas]
    );

    const columnsFlotaTable = React.useMemo(
        () => [
            {
                Header: 'Flota',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0 d-flex justify-content-between',
                isGlobalFilter: true,
                Cell: (props) => <TableCellFlota {...props} isSelected={tareas.find(({flotaId})=>flotaId===props?.original?.id)} /> 
            },
        ],
        [tareas]
    );

    const onChangeGrilla = (stacks,weekDay) => {
        stacks.forEach(stack=>{
            modificar(stack)
        })
    }

    const onClickGuardar = () =>{
        const forms = [];
        tareas
            .forEach(ta=>{
            const form = {
                ...ta,
                flotaId: null,
                flotaRespaldoId: null,
            }
            if(ta.flotas){
                ta.flotas.forEach((flota,id)=>{
                    switch(id){
                        case 0:
                            form.flotaId = flota.id;
                            break;
                        case 1:
                            form.flotaRespaldoId = flota.id;
                            break;
                        default:
                            break;
                    }
                })
            }
            forms.push(TareasService.update(form));
        });
        setIsLoading(true);
        Promise.all(forms).then(()=>{
            return actualizar();
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        actualizar().then(()=>{
            setIsLoading(false);
        });
        iniciarWeekGrid(rangeDate.start);
    }, []);

    return (
        <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="my-2 col-12 text-center">
                            <h3 className="" data-aos="fade-up">
                                Cuadrillas
                                {isLoading && (<Spinner className="ml-1 mb-1 spinner-border text-primary" animation="border" />)}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 text-left bg-white border shadow-sm rounded-lg p-3 bordered" data-aos="fade-up">
                            <div id="draggableZoneFlotaJugadores">
                                <div className="grid-panel">
                                    {
                                        permisos['api.tareas.update'] &&
                                        permisos['api.logistica'] && (
                                        <div className="d-grid gap-2">
                                            <Button block onClick={onClickGuardar} isLoading={isLoading}>GUARDAR</Button>
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="semana-input">Semana</label>
                                        <DateWeek onChange={onChangeDate} isLoading={isLoading} />
                                    </div>
                                    <div className="form-check">
                                        <InputCheck label="Mostrar todas las flotas" onChange={onChangeMostrar} checked={isMostrarFlotas} />
                                    </div>
                                    <Button block theme='gray' className="my-1" onClick={onClickTareasTable}>Tareas</Button>
                                    <Collapse in={isTareasTable}>
                                        <div id="tareasTable">
                                            <TablePaginated 
                                                customGlobalFilter={onFilterTareas} 
                                                isShowHeader={false} 
                                                columns={columnsTareaTable} 
                                                data={tareasSinFechaData}
                                            />
                                        </div>
                                    </Collapse>
                                    <Button block theme='gray' className="my-1" onClick={onClickFlotasTable}>Flotas</Button>
                                    <Collapse in={isFlotasTable}>
                                        <div id="vehiculosTable">
                                            <TablePaginated 
                                                customGlobalFilter={onFilterFlotas} 
                                                isShowHeader={false} 
                                                columns={columnsFlotaTable} 
                                                data={flotasData} 
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-9" id="draggableGrid">
                            <div className="row week-grid border rounded pt-lg-1 shadow-sm bg-white ml-1">
                                {
                                    weekGrid.map(({id,weekDay,title,subtitle,active})=>{
                                        return (
                                            <div className={`col-2 border-right week-${weekDay}`} key={id}>
                                                <WidgetTitle
                                                    title={title}
                                                    subtitle={subtitle}
                                                    active={active}
                                                />
                                                <Grilla
                                                    isFullWidth
                                                    acceptWidgets={false}
                                                    values={tareas}
                                                    entryValues={tareas.filter(ta=>ta.weekDay===weekDay)}
                                                    widget={WidgetTarea}
                                                    getHeight={onGetHeight}
                                                    onChange={(stacks)=>onChangeGrilla(stacks,weekDay)}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    tareas: state.cuadrillas.tareas,
});

const mapDispatchToProps = (dispatch) => ({
    limpiar: () => dispatch(CuadrillasAction.limpiarTareas()),
    iniciar: (data) => dispatch(CuadrillasAction.iniciarTareas(data)),
    agregar: (data) => dispatch(CuadrillasAction.agregarTarea(data)),
    modificar: (data) => dispatch(CuadrillasAction.modificarTarea(data)),
    recuperarFlotas: (data) => dispatch(CuadrillasAction.recuperarFlotas(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CuadrillaLugaresPage);