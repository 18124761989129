import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import {Dropdown, Spinner} from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import {Button, DateRange, ModalConfirm, SelectPeriodo, SelectTipoTrabajo, SelectZona} from '@app/components/index';
import CuadrillaLugaresService from '@services/cuadrillaLugares.service';
import ModalCuadrillaLugarEditar from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarEditar';
import ModalCuadrillaDisponibleLugarVer from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarVer';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import FechaFunction from '@app/functions/FechaFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';

const CuadrillaLugarListadoPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek());
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);

    const actualizar = async () => {
        const { start, end} = rangeDate;
        const query = {
            fechaIni: FechaFunction.format(start),
            fechaFin: FechaFunction.format(end),
        };
        setIsLoading(true);
        const response = await CuadrillaLugaresService.getAll(query);
        setItems(response);
        setIsLoading(false);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Cuadrilla',
            message: '¿Esta seguro que desea eliminar la Cuadrilla?',
            onConfirm: () => {
                CuadrillaLugaresService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickVer = (item) => {
        showModal(ModalCuadrillaDisponibleLugarVer,{item})
    }
    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(CuadrillaLugaresService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenEditar = (item) => {
        showModal(ModalCuadrillaLugarEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Fecha',
                Cell: ({row}) => {
                    return FechaFunction.format(row.original.fecha,'dd/MM/yyyy');
                },
                accessor: 'fecha',
                Filter: false,
                disableGlobalFilter: true,
                Width: 80,
            },
            {
                Header: 'Turno',
                Width: 200,
                accessor: 'periodoNombre',
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectPeriodo
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.value) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
            },
            {
                Header: 'Zona TM',
                ClassName: 'm-0',
                accessor: 'zonaTM.nombre',
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectZona
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.label) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
            },
            {
                Header: 'Zona TT',
                ClassName: 'm-0',
                accessor: 'zonaTT.nombre',
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectZona
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.label) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
            },
            {
                Header: 'Tipo',
                Cell: ({row}) => {
                    const response = [row.original.tipoTrabajo];
                    if(row.original.periodo==='TC'){
                        response.push(row.original.tipoTrabajoC)
                    }
                    return response.join('/');
                },
                accessor: 'tipoTrabajo',
                Width: 100,
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectTipoTrabajo
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.value) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
            },
            {
                Header: 'REPRO',
                Cell: ({row}) => {
                    return row.original.checkRepro?'REPRO':'';
                },
                accessor: 'checkRepro',
                Filter: false,
                disableGlobalFilter: true,
                Width: 100,
            },{
                Width: 10,
                Header: 'Acciones',
                accessor: 'id',
                ClassName: 'm-0',
                Filter: false,
                disableSortBy: true,
                disableGlobalFilter: true,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onClickVer(row.original)}
                                >
                                    Ver
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    const onChangeDate = (newRange) => {
        setRangeDate(newRange);
    };

    useEffect(() => {
        actualizar();
    }, [rangeDate]);

    return (
            <div className="container-fluid my-lg-3">
                <div className="row">
                    <div className="col-4 col-md-8 col-lg-4">
                        <h3 className="m-0" data-aos="fade-right">
                            Cuadrillas Lugares
                            {
                                isLoading && (
                                    <Spinner className="spinner-border text-primary ml-1 mb-1" animation="border" />
                                )
                            }
                        </h3>
                    </div>

                    <div className="col-4 text-center" data-aos="fade-right">
                        <label className="label-control" htmlFor="fecha">Fecha</label>
                        <DateRange defaultValue={rangeDate} onChange={onChangeDate} />
                    </div>

                    {
                        permisos['api.cuadrilla.disponibleLugar.exportar'] && (
                        <div className="col-4 col-md-2 offset-lg-2 col-lg-2" data-aos="fade-up">
                            <Button disabled={isLoadingExportar} icon={['fas','file-excel']} className="btn btn-block btn-success" onClick={() => onClickExportar()}>
                                EXPORTAR
                            </Button>
                        </div>
                    )}

                    <div className="col-12" data-aos="fade-right">
                            <TablePaginated columns={columns} data={items} />
                    </div>

                </div>

            </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CuadrillaLugarListadoPage);
