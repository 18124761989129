import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,SelectPersonal,SelectTipoFoto} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import FotoService from '@app/services/foto.service';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({
    foto: Yup.mixed()
    .test(
      "type",
      "We only support jpeg",
      (value) => !value || (value && value[0].type === "image/jpeg")
    ),
});

function ModalFoto({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id,
            tabla: 'asesor',
            tablaCampo: 'dni_p'
        };
        // console.log(form)
        setIsLoading(true);
        if (form.id > 0) {
            FotoService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            FotoService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };
    const tipoFotoId = watch('tipoFotoId', item.tipoFotoId);
    const tablaId = watch('tablaId', item.tablaId);
    useEffect(() => {
        register('tipoFotoId');
        register('tablaId');
    }, [register]);
    useEffect(() => {
        if (item.id > 0) {
            setValue('tablaId', item.tablaId);
            setValue('tipoFotoId', item.tipoFotoId);
        }
    }, []);
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar' : 'Registrar'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombreL">
                            Personal
                        </label>
                        <SelectPersonal
                            id="tablaId"
                            isInvalid={errors.tablaId}
                            value={tablaId}
                            onChange={(opt)=>setValue('tablaId',opt.value)}
                        />
                        {errors.tablaId && (
                        <div className="default-invalid-feedback">
                            {errors.tablaId.message}
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombreL">
                            Tipo De Archivo
                        </label>
                        <SelectTipoFoto
                            id="tipoFotoId"
                            isInvalid={errors.tipoFotoId}
                            value={tipoFotoId}
                            onChange={(opt)=>setValue('tipoFotoId',opt.value)}
                        />
                        {errors.tipoFotoId && (
                        <div className="default-invalid-feedback">
                            {errors.tipoFotoId.message}
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="foto">
                            Archivo
                        </label>
                        <input
                            className={`form-control ${errors.foto? 'is-invalid': ''}`}
                            name="foto"
                            type="file"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.foto && (
                            <div className="invalid-feedback">
                                {errors.foto.message}
                            </div>
                        )}
                    </div>        
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalFoto.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
        tipoFotoId: '',
        tablaId: ''
    }
};

export default ModalFoto;