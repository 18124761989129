import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory, Prompt} from 'react-router-dom';
import TareasService from '@services/tareas.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import { Spinner } from 'react-bootstrap';
import {Button, DateRange, ModalConfirm, Select, SelectEstadoTarea, SelectLugar, SelectPersonal, SelectTipoTarea, SelectZona} from '@app/components/index';
import * as CalendarAction from '@app/store/actions/calendar';
import useGetTareas from '@app/hooks/useGetTareas';
import FechaFunction from '@app/functions/FechaFunction';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import usePreventReload from '@app/hooks/usePreventReload';
import ModalCuadrillaLugarDiponibles from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarDiponibles';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import EchoWS from '@app/utils/ws';
import usePermisos from '@app/hooks/usePermisos';

function TareasPymesVipPage({
    agregarReprogramacion,
    location,
}) {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const {
        estadoTareaId
    } = (location && location.state) || { };
    const [t] = useTranslation();
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(10);
    const [lugarId, setLugarId] = useState(0);
    const [search, setSearch] = useState([]);
    const [sortBy, setSortBy] = useState()
    const [tipoTareaId, setTipoTareaId] = useState('');
    const [rangeFecha, setRangeFecha] = useState();
    const [rangeFechaInsercion, setRangeFechaInsercion] = useState();
    const [createdPersonaId, setCreatedPersonaId] = useState();
    const [sinCuadrillaLugar, setSinCuadrillaLugar] = useState();
    const [sinAsignar, setSinAsignar] = useState();
    const [sinFecha, setSinFecha] = useState();
    const [isLoadingExportar, setisLoadingExportar] = useState(false);
    const [esTareaId, setEsTareaId] = useState(0)
    const [zonaId, setZonaId] = useState();
    const [filterDisabled, setfilterDisabled] = useState(true)

    const history = useHistory();
    usePreventReload(isLoadingExportar);

    const {response:{items,total},setQuery, isLoading} = useGetTareas({
        search:search,
        page:page,
        length:length,
    });

    const actualizar = () => {
        const query = {
            categoria: "VIP 1,VIP 2,VIP 3,PYME 1,PYME 2,PYME 3,PYME 4,SC",
            search:search,
            page:page,
            length:length,
            createdPersonaId:createdPersonaId,
            zonaId:zonaId,
            ...(rangeFecha?.start && { fechaIni: FechaFunction.format(rangeFecha.start) }),
            ...(rangeFecha?.end && { fechaFin: FechaFunction.format(rangeFecha.end) }),
            ...(rangeFechaInsercion?.start && { fechaIniInsercion: FechaFunction.format(rangeFechaInsercion.start) }),
            ...(rangeFechaInsercion?.end && { fechaFinInsercion: FechaFunction.format(rangeFechaInsercion.end) }),
        };

        if(lugarId){
            query.lugarId = lugarId;
        }
        if(sortBy && sortBy.length>0){
            query.sort = sortBy[0].id;
            query.order = sortBy[0].desc ? 'desc':'asc';
        }
        if(sinCuadrillaLugar){
            query.sinCuadrillaLugar = sinCuadrillaLugar;
        }
        if(tipoTareaId){
            query.tipoTareaId = tipoTareaId;
        }
        if(esTareaId){
            query.estadoTareaId = esTareaId;
        }
        if(sinAsignar){
            query.sinAsignar = sinAsignar;
        }
        if(sinFecha){
            query.sinFecha = sinFecha;
        }
        setQuery(query);
    };

    const onClickAprobar = (item) => {
        showModal(ModalConfirm, {
            title: 'Aprobar Tarea',
            message: '¿Esta seguro que desea aprobar la tarea?',
            onConfirm: () => {
                TareasService.estados({
                    id:item.id,
                    estadoTareaId:1
                }).then(() => {
                    actualizar();
                });
            }
        });
    }

    const onGuardarReprogramar = (newTarea) => {
        TareasService.register(newTarea)
        .then(() => {
            actualizar();
        });
    }

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Tarea',
            message: '¿Esta seguro que desea eliminar la tarea?',
            onConfirm: () => {
                TareasService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        const query = {
            categoria: "VIP 1,VIP 2,VIP 3,PYME 1,PYME 2,PYME 3,PYME 4,SC",
            search:search,
            createdPersonaId:createdPersonaId,
            zonaId:zonaId,
            ...(rangeFecha?.start && { fechaIni: FechaFunction.format(rangeFecha.start) }),
            ...(rangeFecha?.end && { fechaFin: FechaFunction.format(rangeFecha.end) }),
            ...(rangeFechaInsercion?.start && { fechaIniInsercion: FechaFunction.format(rangeFechaInsercion.start) }),
            ...(rangeFechaInsercion?.end && { fechaFinInsercion: FechaFunction.format(rangeFechaInsercion.end) }),
        };
        if(sinCuadrillaLugar){
            query.sinCuadrillaLugar = sinCuadrillaLugar;
        }
        if(tipoTareaId){
            query.tipoTareaId = tipoTareaId;
        }
        if(esTareaId){
            query.estadoTareaId = esTareaId;
        }
        if(sinAsignar){
            query.sinAsignar = sinAsignar;
        }
        if(sinFecha){
            query.sinFecha = sinFecha;
        }
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(TareasService.exportar(query)).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenNuevo = () => {
        history.push({
            pathname:'/tareaspymes/nuevo',
            state: {
                backTo: '/tareaspymes',
            }
        });
    };

    const onOpenEditar = (item) => {
        history.push({
            pathname: `/tareaspymes/${item.id}/editar`,
            state:{
                item: item,
                backTo: '/tareaspymes'
            }
        });
    };

    const onOpenModalRepro = (item) => {
        showModal(ModalCuadrillaLugarDiponibles,{item, onGuardar:onGuardarReprogramar});
    };

    const onClickIrAlCalendario =(item) =>{
        agregarReprogramacion(item);
        history.push('/calendario');
    }

    useEffect(() => {
        actualizar();
        return () => {

        }
    }, [page,length,rangeFecha,rangeFechaInsercion,createdPersonaId,zonaId,sortBy,sinAsignar,sinCuadrillaLugar,sinFecha,tipoTareaId,esTareaId,lugarId])

    useEffect(() => {
        if(page === 0){
            actualizar();
        } else {
            setPage(0);
        }
    }, [search])

    useEffect(() => {
        const listen = EchoWS.private('tareas')
            .listen('.tareas.created',actualizar)
            .listen('.tareas.deleted',actualizar)
            .listen('.tareas.updated',actualizar)
        return () => {
            listen.unsubscribe();
        }
    }, [])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Registrado',
                Filter: false,
                accessor: 'createdAt',
                disableGlobalFilter: true,
                Width: 80,
                Cell: ({row}) => {
                    return FechaFunction.format(row.original.createdAt,'dd/MM/yyyy');
                },
            },
            {
                Header: 'Agendado',
                Filter: false,
                accessor: 'fecha',
                disableGlobalFilter: true,
                Width: 80,
                Cell: ({row}) => {
                    if(row.original.fecha){
                        return FechaFunction.format(`${row.original.fecha} ${row.original.horaIni}`,'dd/MM/yyyy HH:mm');
                    }
                    return 'SIN ASIGNAR';
                },
            },
            {
                Header: 'Tipo',
                accessor: 'tipoTareaId',
                ClassName: 'm-0',
                Width: 130,
                Filter:false,
                Cell: ({row}) => {
                    return row.original.tipoTarea;
                },
                filter: 'includes'
            },
            {
                Header: 'Estado',
                ClassName: 'm-0',
                accessor: 'estadoTareaId',
                Width: 140,
                Filter: false,
                Cell: ({row}) => {
                    return row.original.estadoTarea;
                },
                filter: 'includes'
            },
            {
                Header: 'Cliente',
                ClassName: 'm-0',
                accessor: 'clienteNombre',
                Filter: false,
            },
            {
                Header: 'Documento',
                Width: 100,
                ClassName: 'm-0',
                accessor: 'clienteDocumento',
                Filter: false,
            },
            {
                Header: 'Cliente ID',
                ClassName: 'm-0',
                accessor: 'clienteId',
                Filter: false
            },
            {
                Header: 'Lugar',
                ClassName: 'm-0',
                accessor: 'lugarId',
                Width: 130,
                Filter: false,
                Cell: ({row}) => {
                    if(row.original.lugar){
                        return row.original.lugar.nombre;
                    }
                    return '';
                },
                filter: 'includes'
            },{
                Width: 10,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                disableGlobalFilter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                   row.original.estadoTareaId === 7 && (
                                        <Dropdown.Item
                                        onClick={() => onOpenModalRepro(row.original)}
                                        >
                                            Seleccionar Fecha
                                        </Dropdown.Item>
                                    )
                                }
                                {
                                   row.original.estadoTareaId === 8 ? (
                                    <Dropdown.Item
                                        onClick={() => onClickAprobar(row.original)}
                                    >
                                        Aprobar Tarea
                                    </Dropdown.Item>
                                    ):(
                                    <Dropdown.Item
                                        onClick={() => onOpenEditar(row.original)}
                                    >
                                        Editar
                                    </Dropdown.Item>
                                    )
                                }
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    {row.original.estadoTareaId === 8 ? (
                                        "Rechazar Tarea"
                                    ):(
                                        "Eliminar"
                                    )}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );


    return (
        <>
            <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3">
                            <h3 className="mt-4 ml-1" data-aos="fade-right">
                                Tareas Pymes Vip
                                {
                                    isLoading && <Spinner className="ml-2 mb-1 spinner-border text-primary" animation="border" />
                                }
                            </h3>
                        </div>
                        <div className="col-6 col-md-3" data-aos="fade-right">
                            <div className="col-form-label">Filtro por Registrado</div>
                            <div className="form-group">
                                <DateRange
                                    onChange={setRangeFechaInsercion}
                                    defaultValue={rangeFechaInsercion}
                                    isClearable
                                />
                            </div>
                        </div>
                        <div className="col-6 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <div className="col-form-label">Filtro por Agendado</div>
                                <DateRange
                                    onChange={setRangeFecha}
                                    defaultValue={rangeFecha}
                                    isClearable
                                />
                            </div>
                        </div>
                        {
                            permisos['api.tareas.exportar'] && (
                            <div className="offset-lg-1 col-lg-1" data-aos="fade-up">
                                <Button disabled={isLoadingExportar} className="btn btn-block btn-success mt-4" onClick={() => onClickExportar()}>
                                    EXPORTAR
                                </Button>
                            </div>
                        )}
                        {
                            permisos['api.tareas.store'] && (
                            <div className="col-lg-1" data-aos="fade-up">
                                <Button color="primary" className="btn btn-block mt-4" onClick={() => onOpenNuevo()}>
                                    NUEVO
                                </Button>
                            </div>
                        )}

                        {/* <div className="col-6 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <label className="col-form-label" htmlFor="createdPersonaId">Responsable</label>
                                <SelectPersonal
                                    id="createdPersonaId"
                                    onChange={(opt) => {
                                        if (opt) {
                                            setCreatedPersonaId(String(opt.value) || undefined);
                                        } else {
                                            setCreatedPersonaId('');
                                        }
                                    }}
                                    defaultValue={createdPersonaId}
                                    isClearable
                                />
                            </div>
                        </div> */}
                        <div className="col-6 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <SelectZona
                                    id="zonaId"
                                    onChange={(opt) => {
                                        if (opt) {
                                            setZonaId(String(opt.value) || undefined);
                                        } else {
                                            setZonaId('');
                                        }
                                    }}
                                    defaultValue={zonaId}
                                    isClearable
                                    placeHolder="Zona"
                                />
                            </div>
                        </div>

                        {/* <div className="col-6 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <div className="col-form-label">Por cuadrilla</div>
                                <Select
                                    isClearable
                                    onChange={(opt)=>setSinCuadrillaLugar(opt?.value || null)}
                                    options={[
                                        {
                                            label:'SIN ASIGNAR',
                                            value: '1',
                                        },
                                        {
                                            label:'CON ASIGNACION',
                                            value: '0',
                                        },
                                        {
                                            label:'SIN FILTRAR',
                                            value: '',
                                        },
                                    ]}
                                />
                            </div>
                        </div> */}
                        {/* <div className="col-6 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <div className="col-form-label">Por flota</div>
                                <Select
                                    isClearable
                                    onChange={(opt)=>setSinAsignar(opt?.value || null)}
                                    options={[
                                        {
                                            label:'SIN ASIGNAR',
                                            value: '1',
                                        },
                                        {
                                            label:'CON ASIGNACION',
                                            value: '0',
                                        },
                                    ]}
                                />
                            </div>
                        </div> */}
                        {/* <div className="col-12 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <div className="col-form-label">Por Fecha</div>
                                <Select
                                    isClearable
                                    onChange={(opt)=>setSinFecha(opt?.value || null)}
                                    options={[
                                        {
                                            label:'SIN ASIGNAR',
                                            value: '1',
                                        },
                                        {
                                            label:'CON ASIGNACION',
                                            value: '0',
                                        },
                                    ]}
                                />
                            </div>
                        </div> */}
                        <div className="col-12 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                    <SelectTipoTarea
                                        onChange={(opt) => {
                                            if (opt) {
                                                setTipoTareaId(String(opt.value) || undefined);
                                            } else {
                                                setTipoTareaId('');
                                            }
                                        }}
                                        isClearable
                                        placeHolder="Tipo Tarea"
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                    <SelectEstadoTarea
                                        value={estadoTareaId}
                                        onChange={(opt) => {
                                            if (opt) {
                                                setEsTareaId(String(opt.value));
                                            } else {
                                                setEsTareaId('');
                                            }
                                        }}
                                        isClearable
                                        placeHolder="Estado"
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                    <SelectLugar
                                        onChange={(opt) => {
                                            if (opt) {
                                                setLugarId(String(opt.value));
                                            } else {
                                                setLugarId('');
                                            }
                                        }}
                                        isClearable
                                        placeHolder="Lugar"
                                    />
                            </div>
                        </div>
                        <div className="col-12" data-aos="fade-right">
                            <TablePaginated
                                filterDisabled={filterDisabled}
                                columns={columns}
                                data={items}
                                onSearch={setSearch}
                                onPageChange={setPage}
                                onLengthChange={setLength}
                                onSortedChange={setSortBy}
                                total={total}
                                length={length}
                                currentPage={page}
                                search={search}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
        </>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    agregarReprogramacion: (data) =>  dispatch(CalendarAction.agregarReprogramacion(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TareasPymesVipPage);
