import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, SelectAccion, SelectMultiPersonal,SelectPersonal, SelectPuesto} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import RoleService from '@app/services/roles.service';
import {yupResolver} from '@hookform/resolvers/yup';


const schema = Yup.object({
    nombre: Yup.string().max(200).required(),
});

function ModalRol({show, hideModal, item, onGuardar,Tipo}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            Selectacciones: item.acciones?.map(acc=>acc.id),
            Selectpuestos: item.puestos?.map(acc=>acc.id),
            Selectpersonas: item.personas?.map(acc=>acc.dni),
        }
    });
    const Selectacciones = watch('Selectacciones');
    const Selectpuestos = watch('Selectpuestos');
    const Selectpersonas = watch('Selectpersonas');

    useEffect(() => {
        register('Selectacciones');
        register('Selectpuestos');
        register('Selectpersonas');
    }, [register]);

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id,
            alcance: 0
        };
        const acciones =[];
        const puestos =[];
        form.Selectacciones.forEach(id=>{
            acciones.push({
                'accionId':id
            })
        }) 
        if(Tipo === 'Puestos'){
            form.Selectpuestos.forEach(id=>{
                puestos.push({
                    'tipo':'PUESTO',
                    'referencia':id
                })
            }) 
        }
        if(Tipo === 'DNI'){
            puestos.push({
                'tipo':'DNI',
                'referencia': Number(Selectpersonas)
            })
        }
        form.acciones = acciones;
        form.puestos = puestos;
        setIsLoading(true);
        if (form.id > 0) {
            RoleService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            RoleService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };
    const handleSelectPersonal = (data) => {
        setValue('Selectpersonas', data.value)
        setValue('nombre', data.label);
    }
        useEffect(() => {
        if (item.id > 0) {
            setValue('nombre', item.nombre);
        }
    }, []);
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar Rol' : 'Registrar Rol'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>   
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombreL">
                            Nombre
                        </label>
                        <input
                            className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                            id="nombre"
                            name="nombre"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                            required
                        />
                        {errors.nombre && (
                            <div className="invalid-feedback">
                                {errors.nombre.message}
                            </div>
                        )}
                    </div>
                    {Tipo === 'Puestos' && (
                        <div className="form-group">
                            <label
                                className="label-control"
                                htmlFor="Selectpuestos"
                            >
                                Puestos: 
                            </label>
                            <SelectPuesto
                                isMulti
                                id="Selectpuestos"
                                isInvalid={Boolean(errors.Selectpuestos)}
                                placeholder="Seleccione..."
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                menuPortalTarget={document.body}
                                value={Selectpuestos}
                                onChange={(opt) => setValue('Selectpuestos', opt.map(op=>op.value))}
                            />
                        </div>
                    )}
                    {item.id === 0 && Tipo === 'DNI' && (
                        <div className="form-group">
                            <label
                                className="label-control"
                                htmlFor="Selectpuestos"
                            >
                                Personal
                            </label>
                            <SelectMultiPersonal
                                id="Selectpersonas"
                                isInvalid={Boolean(errors.Selectpersonas)}
                                placeholder="Seleccione..."
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                menuPortalTarget={document.body}
                                value={Selectpersonas}
                                onChange={(opt) => handleSelectPersonal(opt)}
                            />
                        </div>
                    )}
                    <div className="form-group">
                        <label
                            className="label-control"
                            htmlFor="Selectacciones"
                        >
                            Acciones
                        </label>
                        <SelectAccion
                            isMulti
                            id="Selectacciones"
                            isInvalid={Boolean(errors.Selectacciones)}
                            placeholder="Seleccione..."
                            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                            menuPortalTarget={document.body}
                            value={Selectacciones}
                            onChange={(opt) => setValue('Selectacciones', opt.map(op=>op.value))}
                        />
                    </div>         
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalRol.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
        nombre: PropTypes.string
    })
};

ModalRol.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
    }
};

export default ModalRol;