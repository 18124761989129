import axios from 'axios';
const base = 'contactos/envioEmail';

function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

const ContactoEmailService = {
    update: update
};
export default ContactoEmailService;