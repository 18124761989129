import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import { Button } from '@app/components/index';
import CuadrillaLugaresService from '@app/services/cuadrillaLugares.service';
import FechaFunction from '@app/functions/FechaFunction';
import {showModal} from 'react-redux-modal-provider';
import ModalCuadrillaLugarEditar from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarEditar';


function ModalCrearEspCuadrillaLugarDisponibles({
    show,
    hideModal,
    onActualizar
}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState();
    
    const {handleSubmit} = useForm({
        resolver: yupResolver(),
    });
    useEffect(() => {
        CuadrillaLugaresService.fechasDisp().then((response)=>{
            // console.log(response)
            setItems(response[0]) 
        })
    }, []);

    const ClickButton = (it) =>{
        showModal(ModalCuadrillaLugarEditar, {
            item: {
                fecha: it.fecha,
                cuadrillaDisponibleId: items.cuadrillaDisponibleId
            },
            onConfirm: onActualizar
        });
    }
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} style={{ backdropFilter: 'blur(0.7px)' }} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                     Fechas Disponibles Para Cuadrillas Especiales
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {
                        items?.disponibilidad.map((it)=>{
                            return (
                                    <div className='mt-2 ml-3'>
                                        <Button
                                            key={it.fecha}
                                            theme="primary"
                                            onClick={() => ClickButton(it)}
                                            size="lg"
                                        >
                                            {FechaFunction.format(it.fecha,'dd/MM/yyyy')}
                                        </Button>
                                    </div>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalCrearEspCuadrillaLugarDisponibles.propTypes = {

}

export default ModalCrearEspCuadrillaLugarDisponibles