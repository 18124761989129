import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import TipoCanalesService from '@services/tipoCanales.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalCanalTarea from '@app/modals/tarea/ModalCanalTarea';
import usePermisos from '@app/hooks/usePermisos';
import {Button, ModalConfirm} from '@app/components/index';

const CanalTareaPage = () => {
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await TipoCanalesService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar el Canal de Tarea',
            message: '¿Esta seguro que desea eliminar el Canal de Tarea?',
            onConfirm: () => {
                TipoCanalesService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalCanalTarea, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },{
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-lg-6 my-lg-3">
                        <h3>Canal Tarea</h3>
                    </div>
                    {
                        permisos['api.canales.store'] && (
                        <div className="offset-2 col-4 offset-lg-4 col-lg-2 my-lg-3">
                            <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                                NUEVO
                            </Button>     
                        </div>
                    )}

                    <div className="col-12">
                            <TablePaginated columns={columns} data={items} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CanalTareaPage);
