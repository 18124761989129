import axios from 'axios';
const base = 'contactos/estado/crm';

function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

const ContactoEstadoService = {
    update: update
};
export default ContactoEstadoService;