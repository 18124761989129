import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import MotivoReprogramadoService from '@services/motivoReprogramado.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalMotivoReprogramado from '@app/modals/motivos/ModalMotivoReprogamadoEditar';
import usePermisos from '@app/hooks/usePermisos';
import {Button, ModalConfirm} from '@app/components/index';

const MotivoReprogramadosPage = () => {
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await MotivoReprogramadoService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Motivo',
            message: '¿Esta seguro que desea eliminar el motivo?',
            onConfirm: () => {
                MotivoReprogramadoService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalMotivoReprogramado, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'Descripcion',
                accessor: 'descripcion'
            },{
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-8 col-md-6 col-8 col-lg-8 my-lg-3">
                        <h3>Motivos de Reprogramación de tareas</h3>
                    </div>
                    {
                        permisos['api.reprogramados.store'] && (
                        <div className="col-4 offset-md-3 col-md-3 offset-lg-2 col-lg-2 my-lg-3">
                            <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                                NUEVO
                            </Button>
                        </div>
                    )}
                    <div className="col-12">
                            <TablePaginated columns={columns} data={items} />
                    </div>
                        
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MotivoReprogramadosPage);
