import * as Pusher from "pusher-js";
import Echo from "laravel-echo";
import store from '@app/store/index';

/*
const pusher = new Pusher('425a7efdac245e408249', {
    httpHost: 'localhost',
    httpPort: 6001,
    wsPort: 6001,
    wsHost: window.location.hostname,
    forceTLS: false,
    disableStats: true,
    cluster: 'mt1',
});
*/

const EchoWS = new Echo({
    broadcaster: 'pusher',
    key: '425a7efdac245e408249',
    /*
    client: pusher,
    */
    wsHost: window.location.hostname,
    wsPort: 6001,
    wssHost: window.location.hostname,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    forceTLS: false,
    // authEndpoint: 'http://45.224.141.50/broadcasting/auth',
    authEndpoint: 'http://calendario.nubicom.com.ar/broadcasting/auth',
    // authEndpoint: 'http://127.0.0.1:8000/broadcasting/auth',
    auth:{
        headers:{
            'Authorization': `Bearer ${store.getState().auth.token}`
        }
    }

});
export default EchoWS;