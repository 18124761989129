import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,ComisionCard } from '@app/components/index'; 
import ComisionService from '@services/comision.service';
import FechaFunction from '@app/functions/FechaFunction';
import { useDispatch, useSelector } from 'react-redux';
import { addDetalle } from '@app/store/slices/recibos.slice';

function ModalReciboAgregar({show, hideModal, item, onGuardar}) {

    const dispatch = useDispatch()
    const detalles = useSelector(state => state.recibos.detalles)

    const handleClose = () => hideModal();
    const [items, setItems] = useState([]);


    const onSeleccionar = (ite) =>{
        dispatch(addDetalle(ite))
    }


    useEffect(() => {
        ComisionService.getAll({personaId:item.personaId}).then((response)=>{
            setItems(response);
        })
    }, [])

    return (
        <Modal size="lg" show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Buscar Obligaciones Disponibles
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 text-center">
                    <div className="row">
                        <div className="col-12">
                            <div className="row bg-gray text-white">
                                <div className="col">Cliente</div>
                                <div className="col">Perido</div>
                                <div className="col">Comision</div>
                                <div className="col">Estado</div>
                                <div className="col">Agregar</div>
                                {/* <div className="col-3">Estado</div> */}
                            </div>
                        </div>
                        {
                            items
                            .filter(ite=> !detalles.find((list)=>list.id === ite.id) )
                            .map((ite)=>{
                                return ( 
                                    <div className="col-12 shadow-sm">
                                        <div className="row border bg-white">
                                            <div className="col p-1 p-lg-3">{ite.contrato.nombreCompleto}</div>
                                            <div className="col p-1 p-lg-3 text-capitalize">{`${FechaFunction.format(ite.fechaIni,'MMMM')}-${FechaFunction.format(ite.fechaFin,'MMMM')}`}</div>
                                            <div className="col p-1 p-lg-3">
                                                $
                                                {ite.monto}
                                            </div>
                                            <div className="col p-1 p-lg-3">
                                                {
                                                    ite.estadoCuenta === 'PAGADO' && (
                                                        <button type="button" className="btn btn-outline-info">{ite.estadoCuenta}</button>
                                                    )
                                                }
                                                {
                                                    ite.estadoCuenta !== 'PAGADO' && (
                                                        <button type="button" className="btn btn-outline-warning">{ite.estadoCuenta}</button>
                                                    )
                                                }
                                            </div>
                                            <div className="col p-1 p-lg-3">
                                                <button type="button" className="btn btn-sm btn-primary" onClick={()=>onSeleccionar(ite)}>+</button>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            })
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalReciboAgregar
