import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import axios from 'axios';
const base = 'contactos';
const baseActualizar = 'contactosTareas';
function getAll(query=null,cancel=null){
    const params = AuxiliarFunction.formatQueryWithSearch(query);
    return axios.get(base,{
        params: params,
        cancelToken: cancel,
    });
}

function register(data) {
    return axios.post(base, data);
}

function getById(id) {
    return axios.get([base, id].join('/'));
}

function getCrmContacto(search) {
    return axios.get(['crm/contactos', search].join('/'));
}



function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

function updateTareas(data) {
    return axios.post(baseActualizar, data);
}
const ContactosService = {
    getAll: getAll,
    register: register,
    getById: getById,
    update: update,
    destroy: destroy,
    updateTareas:updateTareas,
    getCrmContacto:getCrmContacto,
};
export default ContactosService;