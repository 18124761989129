import React, {useRef, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { useSelector } from 'react-redux';
import FechaFunction from '@app/functions/FechaFunction';
import {showModal} from 'react-redux-modal-provider';
import ModalMisMensajes from '@app/modals/mensajes/ModalMisMensajes';

const NotificationsDropdown = () => {
    const mensajes = useSelector(state => state.auth.mensajes_last);
    const total = useSelector(state => state.auth.mensajes_total);
    const noVistos = useSelector(state => state.auth.mensajes_noVistos);

    const dropdownRef = useRef(null);
    const {t} = useTranslation();

    const [dropdownState, updateDropdownState] = useState({
        isDropdownOpen: false
    });

    const toggleDropdown = () => {
        updateDropdownState({isDropdownOpen: !dropdownState.isDropdownOpen});
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            updateDropdownState({isDropdownOpen: false});
        }
    };

    const onClickVerMensaje = (item) => {
        toggleDropdown();
        showModal(ModalMisMensajes,{item});
    }

    const onClickMisMensajes = () => {
        toggleDropdown();
        showModal(ModalMisMensajes);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, false);
        
        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutside,
                false
            );
        };
    },[]);

    let className = 'dropdown-menu dropdown-menu-lg dropdown-menu-right';

    if (dropdownState.isDropdownOpen) {
        className += ' show';
    }

    return (
        <li
            ref={dropdownRef}
            className="nav-item d-none d-sm-inline-block dropdown"
        >
            {/* <button
                onClick={toggleDropdown}
                type="button"
                className="nav-link"
                data-toggle="dropdown"
            >
                <i className="far fa-bell" />
                {
                    noVistos > 0 && (
                        <span className="badge badge-warning navbar-badge">{noVistos}</span>
                    )
                }
            </button> */}
            <div className={className}>
                    <span className="dropdown-item dropdown-header">
                        {t('header.notifications.count', {quantity: total})}
                    </span>
                {
                    mensajes.map((mensaje)=>{
                        return (
                            <div className="dropdown-item" key={mensaje.id} onClick={()=>onClickVerMensaje(mensaje)} aria-hidden="true">
                                <i className="fas fa-envelope mr-2" />
                                <span>
                                    {mensaje.titulo}
                                </span>
                                <span className="float-right text-muted text-sm">
                                    {FechaFunction.timeAgo(mensaje.fecha)}
                                </span>
                            </div>
                        )
                    })
                }
                {/**
                 * 
                <div className="dropdown-divider" />
                <Link to="/" className="dropdown-item">
                    <i className="fas fa-envelope mr-2" />
                    <span>
                        {t('header.notifications.newMessagesCount', {
                            quantity: '4'
                        })}
                    </span>
                    <span className="float-right text-muted text-sm">
                        {t('measurement.quantityUnit', {
                            quantity: '3',
                            unit: 'mins'
                        })}
                    </span>
                </Link>
                <div className="dropdown-divider" />
                <Link to="/" className="dropdown-item">
                    <i className="fas fa-users mr-2" />
                    <span>
                        {t('header.notifications.friendRequestsCount', {
                            quantity: '5'
                        })}
                    </span>
                    <span className="float-right text-muted text-sm">
                        {t('measurement.quantityUnit', {
                            quantity: '12',
                            unit: 'hours'
                        })}
                    </span>
                </Link>
                <div className="dropdown-divider" />
                <Link to="/" className="dropdown-item">
                    <i className="fas fa-file mr-2" />
                    <span>
                        {t('header.notifications.reportsCount', {
                            quantity: '3'
                        })}
                    </span>
                    <span className="float-right text-muted text-sm">
                        {t('measurement.quantityUnit', {
                            quantity: '2',
                            unit: 'days'
                        })}
                    </span>
                </Link>
                */}
                <div className="dropdown-divider" />
                {
                    total>0 && (
                        <div className="dropdown-item dropdown-footer" onClick={()=>onClickMisMensajes()} aria-hidden="true">
                            {t('header.notifications.seeAll')}
                        </div>
                    )
                }
                
            </div>
        </li>
    );
};

export default NotificationsDropdown;
