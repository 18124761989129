import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {setLocale} from 'yup';

import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
};

function buildYupLocale(_, t) {
    setLocale({
        mixed: {
            required: (e) => i18n.t('form.validation.required', e)
        },
        number: {
            required: (e) => i18n.t('form.validation.required', e),
            min: (e) => i18n.t('form.validation.min', e),
            max: (e) => i18n.t('form.validation.max', e)
        },
        string: {
            required: (e) => i18n.t('form.validation.required', e),
            email: (e) => i18n.t('form.validation.email', e),
            max: (e) => i18n.t('form.validation.maxLenght', e)
        }
    });
}
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init(
        {
            resources,
            lng: 'es',
            interpolation: {
                escapeValue: false // react already safes from xss
            },
            react: {
                wait: true
            }
        },
        buildYupLocale
    );

export default i18n;
