import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {Button} from '@components';

const schema = Yup.object({
    nombre: Yup.string().required(),
});

const SettingsTab = ({
    isActive,
    user,
}) => {

    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(schema),
        defaultValues: user
    });

    const onSubmit = (values) => {
        
    }

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
                <div className="form-group row">
                    <label
                        htmlFor="nombre"
                        className="col-sm-2 col-form-label"
                    >
                        Nombre
                    </label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
                            id="nombre"
                            name="nombre"
                            placeholder="Nombre"
                            ref={register}
                        />
                        {errors.nombre ? (
                            <div className="default-invalid-feedback">
                                {errors.nombre?.message}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor="apellido"
                        className="col-sm-2 col-form-label"
                    >
                        Apellido
                    </label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className={`form-control ${errors.apellido ? 'is-invalid' : ''}`}
                            id="apellido"
                            name="apellido"
                            placeholder="Name"
                            ref={register}
                        />
                        {errors.apellido ? (
                            <div className="default-invalid-feedback">
                                {errors.apellido?.message}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group row">
                    <label
                        htmlFor="email"
                        className="col-sm-2 col-form-label"
                    >
                        Email
                    </label>
                    <div className="col-sm-10">
                        <input
                            type="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            placeholder="Email"
                            readOnly
                            ref={register}
                        />
                        {errors.email ? (
                            <div className="default-invalid-feedback">
                                {errors.email?.message}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="offset-sm-2 col-sm-10">
                        <Button type="submit" theme="danger">
                            Guardar
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTab);