import React, {useState} from 'react';
import { Polygon } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import MapaFunction from '@app/functions/MapaFunction';

function Poligono({
    item,
    selected,
    editable,
    onLoaded,
    defaultProps
}) {
    const [polygon, setPolygon] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const paths = MapaFunction.gJsonToGoogleMaps(item.aristas);
    let colors = {
        strokeColor: 'red',
        fillColor: 'transparent',
    };
    if(item.tipoCovertura){
        colors = MapaFunction.getPoligonColor(item.tipoCovertura,selected);
    }
    const options = {
        ...colors,
        clickable: false,
        strokeOpacity: 0.9,
        strokeWeight: 3,
        fillOpacity: 0.09,
    }

    const onLoad = React.useCallback(function callback(newPolygon) {
        setPolygon(newPolygon);
        onLoaded(newPolygon);
    }, []);

    const onUnmount = React.useCallback(function callback() {
        setPolygon(null);
    }, []);

    return (
        <Polygon
            onLoad={onLoad}
            paths={paths}
            options={options}
            editable={editable}
        />
    );
}

Poligono.defaultProps = {
    selected: false,
    editable: false,
    onLoaded: () => {},
}

export default React.memo(Poligono);
