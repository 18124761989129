export const geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
    maximumAge: 1000 * 3600 * 24, // 24 hour
};

export const defaultCoordinates = {
    lat: -24.7821,
    lng: -65.4232
};

/**
 * distancia en kilometros
 */
export const defaultQueryPoligonos = {
    distancia:13,
}

/**
 * distance in kilometers
 */
export const defaultMinMax = {
    distance: 6,
}