import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import ModalLugarEditar from '@app/modals/lugar/ModalLugarEditar';
import {Button, ModalConfirm, SelectZona, TablePaginated} from '@app/components/index';
import TarjetaVentasService from '@app/services/tarjetaventa.service';

const TarjetasVentasPage = () => {
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await TarjetaVentasService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Lugar',
            message: '¿Esta seguro que desea eliminar la tarjeta?',
            onConfirm: () => {
                TarjetaVentasService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalLugarEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Width: 15,
                MaxWidth: 40,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <DropdownButton
                            size="sm"
                            variant="success"
                            id={row.original.id}
                            title=""
                        >
                            <Dropdown.Item
                                className="d-flex justify-content-between align-items-center"
                                onClick={() => onOpenEditar(row.original)}
                            >
                                <i className="fa fa-edit"></i>
                                Editar
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="d-flex justify-content-between align-items-center"
                                onClick={() => onEliminar(row.original)}
                            >
                                <i className="fa fa-trash"></i>
                                Eliminar
                            </Dropdown.Item>
                        </DropdownButton>
                    );
                }
            },
            {
                Header: 'Titulo',
                accessor: 'titulo'
            },
            {
                Header: 'Subtitulo',
                accessor: 'subtitulo'
            },
            {
                Header: 'Url',
                accessor: 'url'
            }
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 col-lg-6">
                            <h3 className="">Lugares</h3>
                        </div>
                        <div className="offset-2 col-4 offset-lg-4 col-lg-2">
                    <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                        NUEVO
                    </Button>
                           
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <TablePaginated columns={columns} data={items} />
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TarjetasVentasPage);
