import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux';
import { ContratoCard } from '@app/components/index';
import ContratosService from '@app/services/contratos.service';

function TabContactoContratos({
    contacto,
}) {
    const [contratos, setContratos] = useState([]);

    useEffect(() => {
        ContratosService.getAll({
            contactoId: contacto.id,
        })
        .then(({items})=>{
            setContratos(items);
        })
        return () => {
            
        }
    }, [])

    return (
        <div className="">
            <div className="row">
                <div className="col-lg-12">
                    {
                        contratos.map((it)=>{
                            return <ContratoCard item={it} key={it.id} canShowMore className="mb-3" />
                        })
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
})

const mapDispatchToProps = (dispatch) => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(TabContactoContratos)
