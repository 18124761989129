import React, {Component, useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputRange from 'react-input-range';
import CalendarFunction from '@app/functions/CalendarFunction';
import ButtonToggle from '../button/ButtonToggle';
import './styles.scss';

const Styles = styled.div`
    z-index: 0;

    ${({ hideButtons }) => !hideButtons &&`
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
        padding: 10px;

        .time-range {
            margin-top: 20px;
        }
    `}

    .button-group {
        display: flex;
        justify-content: center;
    }

    
    ${({ hideButtons }) => hideButtons && `
        .input-range__label{
            display: none;
        }
        .input-range__slider{
            background: #3f51b5 !important;
            border: 1px solid #3f51b5 !important;
        }
        .input-range__track--active {
            background: #3f51b5 !important;
        }

        .time-range{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        
    `}
    
`;

const TimeSlider = ({
    disabled,
    hideButtons,
    draggableTrack,
    maxValue,
    minValue,
    onChange,
    onChangeComplete,
    onChangeStart,
    step,
    value,
    defaultValue,
    defaultPeriodo,
    turno
}) => {
    const [periodo, setPeriodo] = useState(CalendarFunction.getPeriodoFromTime(value?.start));
    const [start, setStart] = useState(CalendarFunction.timeToMinute(value?.start));
    const [end, setEnd] = useState(CalendarFunction.timeToMinute(value?.end));
    const [innerMinValue, setMinValue] = useState(
        CalendarFunction.timeToMinute(minValue)
    );
    const [innerMaxValue, setMaxValue] = useState(
        CalendarFunction.timeToMinute(maxValue)
    );

    const updateLimit = (newValue) => {
        let newStart = newValue.min;
        let newEnd = newValue.max;
        const diff = newValue.max - newValue.min;
        const minDiff = 60;
        if (newStart < innerMinValue) {
            newStart = innerMinValue;
        }
        if (diff < minDiff) {
            newEnd = newStart + minDiff;
        }
        if (newEnd > innerMaxValue) {
            newEnd = innerMaxValue;
            if (diff < minDiff) {
                newStart = newEnd - minDiff;
            }
        }
        setStart(newStart);
        setEnd(newEnd);
    };

    const onChangeInput = (newValue) => {
        updateLimit(newValue);
        const auxStart = CalendarFunction.minuteToTime(newValue.min);
        const auxEnd = CalendarFunction.minuteToTime(newValue.max);
        if (onChange) {
            onChange({
                start: auxStart,
                end: auxEnd
            });
        }
    };

    const onChangeCompleteInput = (newValue) => {
        updateLimit(newValue);
        /*
        setStart(newValue.min);
        if(newValue.max>innerMaxValue){
            setEnd(innerMaxValue);
        } else {
            setEnd(newValue.max);
        }
        */
        const auxStart = CalendarFunction.minuteToTime(newValue.min);
        const auxEnd = CalendarFunction.minuteToTime(newValue.max);
        if (onChangeComplete) {
            onChangeComplete({
                start: auxStart,
                end: auxEnd
            });
        }
    };

    const onChangeStartInput = (newValue) => {
        /*
        setStart(newValue.min);
        if(newValue.max>innerMaxValue){
            setEnd(innerMaxValue);
        } else {
            setEnd(newValue.max);
        }
        */
        const auxStart = CalendarFunction.minuteToTime(newValue.min);
        const auxEnd = CalendarFunction.minuteToTime(newValue.max);
        if (onChangeStart) {
            onChangeStart({
                start: auxStart,
                end: auxEnd
            });
        }
    };

    const periodos = [
        {
            name: 'TURNO MAÑANA',
            value: 'TM',
            minValue: '08:00:00',
            maxValue: '12:00:00'
        },
        {
            name: 'TURNO TARDE',
            value: 'TT',
            minValue: '13:00:00',
            maxValue: '17:00:00'
        }
    ];

    useEffect(() => {
        const p = periodos.find((per) => per.value === periodo);
        setMinValue(CalendarFunction.timeToMinute(p.minValue));
        setMaxValue(CalendarFunction.timeToMinute(p.maxValue));
        const aux = CalendarFunction.getPeriodoFromTime(value?.start);
        switch (periodo) {
            case 'TM':
                if(aux === 'TM' && value){
                    setStart(CalendarFunction.timeToMinute(value.start));
                    setEnd(CalendarFunction.timeToMinute(value.end));
                } else {
                    setStart(CalendarFunction.timeToMinute('09:00:00'));
                    setEnd(CalendarFunction.timeToMinute('10:00:00'));
                    onChange({
                        start: '09:00:00',
                        end: '10:00:00',
                    });
                }

                break;
            case 'TT':
                if(aux === 'TT' && value){
                    setStart(CalendarFunction.timeToMinute(value.start));
                    setEnd(CalendarFunction.timeToMinute(value.end));
                } else {
                    setStart(CalendarFunction.timeToMinute('14:00:00'));
                    setEnd(CalendarFunction.timeToMinute('15:00:00'));
                    onChange({
                        start: '14:00:00',
                        end: '15:00:00',
                    });
                }

                break;
            default:
                break;
        }
    }, [periodo]);

    useEffect(() => {
        if(defaultPeriodo){
            setPeriodo(defaultPeriodo);
        }
    }, [defaultPeriodo]);

    useEffect(() => {
        if(value){
            setStart(CalendarFunction.timeToMinute(value.start));
            setEnd(CalendarFunction.timeToMinute(value.end));
            setPeriodo(CalendarFunction.getPeriodoFromTime(value?.start));
        }
        return () => {

        }
    }, [value])

    useEffect(() => {
        if(value === null){
            switch (periodo) {
                case 'TM':
                    onChange({
                        start: '09:00:00',
                        end: '10:00:00',
                    });
                    break;
                case 'TT':
                    onChange({
                        start: '14:00:00',
                        end: '15:00:00',
                    });
                    break;
                default:
                    break;
            }
        }
        return () => {

        }
    }, [])

    return (
        <Styles className="row" hideButtons={hideButtons}>
            <div className="col-12">
                {
                    !hideButtons && (
                    <div className="button-group mb-4 mb-lg-0">
                        <ButtonToggle
                            disabled={typeof defaultPeriodo === 'string'}
                            items={periodos}
                            value={periodo}
                            defaultValue={defaultPeriodo}
                            onChange={setPeriodo}
                            periodo={periodo}
                            turno={turno}
                        >
                        </ButtonToggle>
                    </div>
                    )
                }
                {
                    hideButtons && (
                    <div className="time-range">
                        <b>{CalendarFunction.formattedTime(start,'HH:mm')}</b>
                        <b>{CalendarFunction.formattedTime(end,'HH:mm')}</b>
                    </div>
                    )
                }
                <div className={`time-group ${hideButtons?'':'mt-lg-5'}`}>
                    <InputRange
                        formatLabel={(labelValue) =>
                            CalendarFunction.formattedTime(labelValue,'HH:mm')
                        }
                        disabled={disabled}
                        draggableTrack={draggableTrack}
                        maxValue={innerMaxValue}
                        minValue={innerMinValue}
                        onChangeStart={onChangeStartInput}
                        onChange={onChangeInput}
                        onChangeComplete={onChangeCompleteInput}
                        step={step}
                        value={{min: start, max: end}}
                    />
                </div>
            </div>
        </Styles>
    );
};

TimeSlider.propTypes = {};

TimeSlider.defaultProps = {
    disabled: false,
    hideButtons: false,
    draggableTrack: true,
    maxValue: '23:59:59',
    minValue: '00:00:00',
    onChange: () => {},
    onChangeComplete: () => {},
    onChangeStart: () => {},
    step: 15,
    value: null,
    defaultValue: null,
};

export default TimeSlider;
