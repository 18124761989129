import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import TipoCanalesService from '@services/tipoCanales.service';
import styled from 'styled-components';
import {connect} from 'react-redux';


const Styles = styled.div``;

const SelectTipoCanal = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    isLoading,
    user
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    // console.log(user)

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    const updateOptions = () => {
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                options.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>({label:va.nombre,value:va.id})));
        }
    }

    useEffect(() => {
        updateOptions();
    }, [isLoading])

    useEffect(() => {
        TipoCanalesService.getAll().then((response) => {

            // console.log(response)
            if(user.puesto ==="Asesor"){
                const opciones = response.filter(option => option.nombre === "VENTA TERCERIZADA")
                setItems(opciones);
                const newOptions = opciones.map((item) => {
                    return {
                        value: item.id,
                        label: item.nombre
                    };
                });
                setOptions(newOptions);
                updateOptions();
            }else{
                setItems(response);
                const newOptions = response.map((item) => {
                    return {
                        value: item.id,
                        label: item.nombre
                    };
                });
                setOptions(newOptions);
                updateOptions();
            }
        });
    }, []);
    return (
        <Styles>
            <Select
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isDisabled={disabled}
            />
        </Styles>
    );
};

SelectTipoCanal.defaultProps = {
    isClearable: false
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(SelectTipoCanal);


