import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { Button, TimeSlider } from '@app/components/index';
import ModalTareaLog from '@app/modals/tarea/ModalTareaLog';
import {showModal} from 'react-redux-modal-provider';

const Style = styled.div`
    .card-title-overflow{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .card-body{
        position: relative;
    }
    .tipo-tarea-badge{
        position: absolute;
        left: 3px;
        top: -12px;
    }
`;

function TareaCard({
    className,
    hideClickMap,
    item,

    onClickMap,
}) {

    const onClickLog = () => {
        showModal(ModalTareaLog,{item: item});
    }
    
    return (
        <Style className={`card ${className}`}>
            <div className="card-header p-1 d-flex align-items-center justify-content-between">
                <small className="card-title-overflow">{item.clienteNombre}</small>
                {
                    item.distancia > 0 && (
                        <b>{`${item.distancia}km`}</b>
                    )
                }
            </div>
            <div className="card-body pr-3 pl-3 pt-1 pb-1">
                <div className="tipo-tarea-badge">
                    <span className="badge badge-pill badge-info">
                        { item.tipoTareaId === 1 && (<b>A</b>) }
                        { item.tipoTareaId === 2 && (<b>R</b>) }
                        { item.tipoTareaId === 3 && (<b>I</b>) }
                        { item.tipoTareaId === 6 && (<b>RL</b>) }
                    </span>
                    <span className="badge btn badge-pill badge-warning" onClick={onClickLog} aria-hidden="true">
                        L
                    </span>
                </div>
                <div className="row">
                    <div className={hideClickMap?"col-12 p-0":'col-10'}>
                        <TimeSlider 
                            disabled
                            hideButtons
                            value={{
                                start: item.horaIni,
                                end: item.horaFin,
                            }}
                        />
                    </div>
                    {
                        !hideClickMap && (
                            <div className="col-2">
                                {
                                    item.coordenadas && (
                                        <Button icon="fas fa-map-signs" onClick={()=>onClickMap(item)} />
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </Style>
    )
}

TareaCard.propTypes = {

}

TareaCard.defaultProps = {
    className: 'm-0',
    onClickMap: () => {},
    hideClickMap: false,
}

export default TareaCard

