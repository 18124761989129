import { useState, useEffect } from "react";
import {geolocationOptions,defaultCoordinates} from "@app/constants/geolocationOptions";

const useCurrentLocation = (options = geolocationOptions) => {
    // store location in state
    const [location, setLocation] = useState(defaultCoordinates);
    // store error message in state
    const [error, setError] = useState();

    // Success handler for geolocation's `getCurrentPosition` method
    const handleSuccess = (pos) => {
        const { latitude, longitude } = pos.coords;

        setLocation({
            lat:latitude,
            lng:longitude,
        });
    };

    // Error handler for geolocation's `getCurrentPosition` method
    const handleError = (newError) => {
        setError(newError.message);
    };

    useEffect(() => {
        const { geolocation } = navigator;

        // If the geolocation is not defined in the used browser we handle it as an error
        if (!geolocation) {
        setError("Geolocation is not supported.");
        return;
        }

        // Call Geolocation API
        geolocation.getCurrentPosition(handleSuccess, handleError, options);
    }, [options]);

    return { location, error };
};

export default useCurrentLocation;
