import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectServicioCorporativo = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps,
    isDisabled
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    const options = [
        {
            value: 'HOGAR S - 7MB',
            label: 'HOGAR S - 7MB'
        },
        {
            value: 'HOGAR M - 12MB',
            label: 'HOGAR M - 12MB'
        },
        {
            value: 'HOGAR L - 30MB',
            label: 'HOGAR L - 30MB'
        },
        {
            value: 'HOGAR XL - 60MB',
            label: 'HOGAR XL - 60MB'
        },
        {
            value: 'PLAN 5 MB NUBICOM',
            label: 'PLAN 5 MB NUBICOM'
        },
        {
            value: 'PLAN 10 MB NUBICOM',
            label: 'PLAN 10 MB NUBICOM'
        },
        {
            value: 'PLAN 15 MB NUBICOM',
            label: 'PLAN 15 MB NUBICOM'
        },
        {
            value: 'NUBIHOGAR 10 MB',
            label: 'NUBIHOGAR 10 MB'
        },
        {
            value: 'ADSL GOBIERNO',
            label: 'ADSL GOBIERNO'
        },
        {
            value: 'LAN TO LAN',
            label: 'LAN TO LAN'
        },
        {
            value: 'LIBRE',
            label: 'LIBRE'
        },
        {
            value: 'PYME PLATA 10/3',
            label: 'PYME PLATA 10/3'
        },
        {
            value: 'PYME ORO 20/5',
            label: 'PYME ORO 20/5'
        },
        {
            value: 'PYME PLATINUM 40/10',
            label: 'PYME PLATINUM 40/10'
        },
        {
            value: 'SIMETRICO IP TRANSIT',
            label: 'SIMETRICO IP TRANSIT'
        },
        {
            value: 'PLAN 8 MB NUBICOM',
            label: 'PLAN 8 MB NUBICOM'
        },
        {
            value: 'C8 SALTA CONECTADA',
            label: 'C8 SALTA CONECTADA'
        },
        {
            value: 'C9 SALTA CONECTADA',
            label: 'C9 SALTA CONECTADA'
        },
        {
            value: 'C10 SALTA CONECTADA',
            label: 'C10 SALTA CONECTADA'
        },
        {
            value: 'C11 SALTA CONECTADA',
            label: 'C11 SALTA CONECTADA'
        },
        {
            value: 'SALTA CONECTADA',
            label: 'SALTA CONECTADA'
        },
        {
            value: 'EVENTUAL',
            label: 'EVENTUAL'
        },
        {
            value: 'MANTENIMIENTO',
            label: 'MANTENIMIENTO'
        },
        {
            value: 'IMPLEMENTACION OBRA',
            label: 'IMPLEMENTACION OBRA'
        },
        {
            value: '6M PYMES ADSL',
            label: '6M PYMES ADSL'
        },
        {
            value: '10M PYMES ADSL',
            label: '10M PYMES ADSL'
        },
        {
            value: '15M PYMES ADSL',
            label: '15M PYMES ADSL'
        },
        {
            value: 'INTERNET ASIMETRICO 10M',
            label: 'INTERNET ASIMETRICO 10M'
        },
        {
            value: 'INTERNET ASIMETRICO 3M',
            label: 'INTERNET ASIMETRICO 3M'
        },
        {
            value: 'INTERNET ASIMETRICO 5M',
            label: 'INTERNET ASIMETRICO 5M'
        },
        {
            value: 'ENERGIA Y CONECTIVIDAD GOB',
            label: 'ENERGIA Y CONECTIVIDAD GOB'
        },
        {
            value: 'RED WIFI INTERNA GOBIERNO',
            label: 'RED WIFI INTERNA GOBIERNO'
        },
        {
            value: 'IP TRANSIT GOBIERNO',
            label: 'IP TRANSIT GOBIERNO'
        },
        {
            value: 'PTP',
            label: 'PTP'
        },
        {
            value: 'ADSL GENDARMERIA',
            label: 'ADSL GENDARMERIA'
        },
        {
            value: 'SIN DATOS',
            label: 'SIN DATOS'
        },
        {
            value: 'HOUSING',
            label: 'HOUSING'
        },
        {
            value: 'SOPORTE IT',
            label: 'SOPORTE IT'
        },
        {
            value: 'INTERNET FIBRA OPTICA',
            label: 'INTERNET FIBRA OPTICA'
        },
        {
            value: 'ADSL 10MB',
            label: 'ADSL 10MB'
        },
        {
            value: '3M NUBIHOGAR ADSL',
            label: '3M NUBIHOGAR ADSL'
        },
        {
            value: '5M NUBIHOGAR ADSL',
            label: '5M NUBIHOGAR ADSL'
        },
        {
            value: '8M NUBIHOGAR ADSL',
            label: '8M NUBIHOGAR ADSL'
        },
        {
            value: 'SIN DATO',
            label: 'SIN DATO'
        },
        {
            value: 'ADSL 6MB',
            label: 'ADSL 6MB'
        },
        {
            value: 'PYME 6MB/3MB',
            label: 'PYME 6MB/3MB'
        },
        {
            value: 'ADSL CORPORATIVO',
            label: 'ADSL CORPORATIVO'
        },
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, [value]);

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isDisabled={isDisabled}
            />
        </Styles>
    );
};

SelectServicioCorporativo.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectServicioCorporativo;
