import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Collapse } from 'react-bootstrap';
import { Button, MapBase, MarkerContacto } from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import { defaultCoordinates } from '@app/constants/geolocationOptions';
import MapaFunction from '@app/functions/MapaFunction';

const Styles = styled.div`

`;

function ContratoCard({
    item,
    canShowMore,
    buttons: ButtonComponent,
}) {
    const [isShowMore, setIsShowMore] = useState(false);
    const [coordinates, setCoordinates] = useState(defaultCoordinates);

    useEffect(() => {
        if(item.coordenadas){
            setCoordinates(MapaFunction.gJsonToGoogleMaps(item.coordenadas)[0]);
        }
        return () => {
            
        }
    }, [])
    return (
        <Styles className="card border border-primary redondeado shadow-sm p-3 m-3">
            <div className="card-title text-truncate">
                {item.nombreCompleto}
                <div className="float-right">
                    {
                        ButtonComponent && (
                            <ButtonComponent />
                        )
                    }
                </div>
            </div>
            <div className="card-subtitle text-truncate">
                {`${item.createdPersona?.nombreCompleto} - ${FechaFunction.format(item.createdAt,'dd/MM/yyyy')}`}
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">{`${item.planNombre} - $${item.planImporte}`}</div>
                    <div className="col-md-4">{`${item.planAnchoBajada} ${item.planTipoAnchoBajada}`}</div>
                    <div className="col-md-4">{`${item.planAnchoSubida} ${item.planTipoAnchoSubida}`}</div>
                </div>
            </div>
            {
                canShowMore && (
                    <>
                        <Collapse in={isShowMore}>
                            <div className="row">
                                <div className="col-12">
                                    <MapBase
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: 400
                                        }}
                                        isSearchable={false}
                                        coordinates={coordinates}
                                        options={{
                                            maxZoom: 18,
                                            minZoom: 10,
                                        }}
                                        zoom={15}
                                    >
                                        <MarkerContacto item={item} />
                                    </MapBase>
                                </div>
                            </div>
                        </Collapse>
                        <div className="row">
                            <Button block onClick={()=>setIsShowMore(!isShowMore)}>
                                {
                                    isShowMore?('Ocultar'):('Mostrar mas')
                                }
                            </Button>
                        </div>
                    </>
                )
            }
        </Styles>
    )
}

ContratoCard.defaultProps = {
    canShowMore:false,
    buttons:null,
};

export default ContratoCard
