import AuthService from "../../services/auth.service";

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_MENSAJES = 'LOAD_MENSAJES';

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then(
    (data) => {
        // console.log(data)
        document.getElementById('root').classList.remove('login-page');
        document.getElementById('root').classList.remove('hold-transition');
        dispatch({
            type: LOGIN_USER,
            payload: { token: data.access_token, tokenNubi: data?.tokenNubi },
        });
        return Promise.resolve(data);
    });
};

export const me = () => (dispatch) => {
    return AuthService.me().then(
    (data) => {
        dispatch({
            type: LOAD_USER,
            payload: { currentUser: data },
        });
        AuthService.mensajes({
            length: 3,
            vistos: false,
        }).then((response)=>{
            dispatch({
                type: LOAD_MENSAJES,
                payload: response,
            });
        });
        return Promise.resolve();
    });
};

export const logout = () => (dispatch) => {
    return AuthService.logout().then(()=>{

    })
    .finally(()=>{
        dispatch({
            type: LOGOUT_USER,
        });
    });

};

export const mensajes = () => (dispatch) => {
    return AuthService.mensajes({
        length:3,
        vistos: false,
    }).then((response)=>{
        dispatch({
            type: LOAD_MENSAJES,
            payload: response,
        });
    });
}