import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { useHistory} from 'react-router-dom';
import TareaForm1 from '@app/pages/contactoContactCenter/TareaForm1';
import TareaForm2 from '@app/pages/contactoContactCenter/TareaForm2';
import TareaForm3 from '@app/pages/contactoContactCenter/TareaForm3';
import TareaForm4 from '@app/pages/contactoContactCenter/TareaForm4';
import TareaForm5 from '@app/pages/contactoContactCenter/TareaForm5';
import { Wizard } from '@app/components/index';
import ContactosAction from '@app/store/actions/contactos';
import ZohoService from '@app/services/zoho.service';

const ContactoContactCenter = ({
    esHabilitadoCrearContrato,
    iniciarContacto,
    iniciarContrato,
    location,
}) => {
    const [t] = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const { backTo, item , action } = (location && location.state) || { };
    
    const options = [
        {
            title: 'Contacto',
            subtitle: 'Datos del cliente',
        },
        {
            title: 'Motivo',
            subtitle: 'Informacion de la Tarea',
        },
        {
            title: 'Documentacion',
            subtitle: 'Informacion de la Tarea',
        },
        {
            title: 'Plan',
            subtitle: 'Informacion de la Tarea',
        },
        {
            title: 'Pactar Fecha',
            subtitle: 'Seleccionar Fecha y Turno',
        },
    ];

    const traerDatosZoho = async (aux) => {
        const search = {
            documento: item.clienteDocumento,
        }
        await ZohoService.searchContacts(search)
        .then((response)=>{
            if(response){
                [item.contacto,] = response.data;
            }
        })
        iniciarContacto(aux);
    };

    useEffect(() => {
        if(item){
            iniciarContacto(item);
            if(item.contrato){
                iniciarContrato(item.contrato);
            }
        }
        history.replace('/ContactoContactCenter#step1');
        return () => {
            iniciarContacto(null);
            iniciarContrato(null);
        }
    }, []);

    return (
        <>
        <div className="container-fluid mt-3 mt-md-3 mt-lg-0">
            <div className="row">
                <div className="col-12">
                    {
                        !isLoading && (
                            <Wizard options={options}>
                                <TareaForm1 item={item} action={action} />
                                <TareaForm2 />
                                <TareaForm3 />
                                <TareaForm4 />
                                <TareaForm5 />
                            </Wizard>
                        )
                    }
                </div>
            </div>
        </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    contactoExistente: state.contactos.current,
    esHabilitadoCrearContrato: state.contactos.esHabilitadoCrearContrato,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactoContactCenter);
