import React, {useState, useCallback} from 'react'
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

const StylesThumbContainer = styled.div`
    width: 100%;
    height: 100%;
    img{
        object-fit: cover;
    }
`;

const StylesMessages = styled.div`
    font-size: small;
    height: 14;

    .dropzone-accepted{
        font-weight: bold;
    }
    .dropzone-rejected{
        color: red;
    }
`;


function InputDropZone({
    id,
    defaultImage,
    backgroundImage,
    enablePreview,
    accept,
    onChange,
    required
}) {
    const [files, setFiles] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        onChange(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

    }, [])
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        maxFiles:1,
        accept: accept,
    })

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {`${file.path} ${file.size} bytes`}
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {`${file.path} ${file.size} bytes`}
            <ul>
                {errors.map(e => (
                <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const thumbs = files.map(file => (
        <StylesThumbContainer key={file.name}>
            <img
                src={file.preview}
                alt=""
            />
        </StylesThumbContainer>
    ));


    return (
        <section className="container">
            <div {...getRootProps()}>
                <input {...getInputProps()} id={id} required={required} />
                {
                    files.length === 0 && backgroundImage && (
                        defaultImage ? (
                            <img src={defaultImage} alt="" className="img-seleccionar" />
                        ) : (
                            <img src={backgroundImage} alt="" className="img-seleccionar" />
                        )
                    )
                }
                {
                    (files.length === 0 && !backgroundImage) && (
                        isDragActive ?
                        ( <p>Drop the files here ...</p> ):
                        ( <p>Drag n drop some files here, or click to select files</p> )
                    )
                }
                {
                    files.length > 0 && !enablePreview && (
                        <i className="fas fa-file" />
                    )
                }
                {
                    files.length > 0 && enablePreview && (
                        thumbs
                    )
                }
            </div>
            <StylesMessages>
                <div className="dropzone-accepted">{acceptedFileItems}</div>
                <div className="dropzona-rejected">{fileRejectionItems}</div>
            </StylesMessages>
        </section>
    )
}

InputDropZone.defaultProps = {
    accept: 'image/jpeg, image/png, image/jpg, image/svg, image/x-png',
    backgroundImage: null,
    defaultImage: null,
    enablePreview: true,
    id: '',
    onChange: () => {},
};

export default InputDropZone;