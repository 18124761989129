import React, {useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback} from "react";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import {showModal} from 'react-redux-modal-provider';

import * as FlotasAction from "@app/store/actions/flotas";
import AuxiliarFunction from "@app/functions/AuxiliarFunction";
import { Button, ModalSelect } from "@app/components/index";
import ModalFlotaJugadorVer from "@app/modals/flota-jugador/ModalFlotaJugadorVer";

function existeJugadorEnFlota(dni,flotas){
    const existe = flotas.find(({jugadores})=>{
        return jugadores.find(jg=>jg.dni === dni);
    });
    return Boolean(existe);
}

function badgeTipoJugador(index){
    let tipo = "CONDUCTOR";
    let clase = "bg-warning text-dark";
    if(index>0){
        tipo = `TECNICO ${index}`;
        clase = "bg-info text-dark";
    }
    return (<span className="text-gray">{tipo}</span>);
}

const WidgetFlota = (({
    item,
    grid,
    widget,
    flotas,
    jugadores,
    
    onChange,
    onChangeWidget,
    onRemove,

    ...defaultProps
})=>{
    const sortableRef = useRef();
    const [innerList, setInnerList] = useState(item.jugadores);

    /**
     * Si el from id es un numero entonces viene de la tabla
     * @param {DOM} to 
     * @param {DOM} from 
     * @param {DOM} el 
     * @returns 
     */
    const onCheckIntegrity = (to,from,el) => {
        if(to.el.id === from.el.id){
            return true;
        } 
        const jugadorId = AuxiliarFunction.toIntegerNumber(from.el.id);
        if(jugadorId>0){
            const existe = existeJugadorEnFlota(jugadorId,flotas);
            if(existe){
                return false;
            }
        }
        return to.el.children.length < item.vehiculo.cantidad;
    }

    const onMouseDown = () => {
        if(grid && widget){
            grid.movable(widget,false);
        }
    }

    const onMouseLeave = () => {
        if(grid && widget){
            grid.movable(widget,true);
        }
    }

    const onClickDescartarJugador = (currentDni) => {
        grid.movable(widget,true);
        const newList = [...innerList].filter(jg=>jg.dni !== currentDni);
        setInnerList(newList);
        onChange({
            ...item,
            jugadores: newList,
        });
    }

    const onClickChangeCampo = () => {
        showModal(ModalSelect, {
            title: 'Seleccionar campo',
            value: item.campo,
            options: [
                {
                    label: 'IDA',
                    value: 'IDA',
                },
                {
                    label: 'SC',
                    value: 'SC',
                },
                {
                    label: 'SS',
                    value: 'SS',
                },
                {
                    label: 'IDS',
                    value: 'IDS',
                },
                {
                    label: 'PYME',
                    value: 'PYME',
                },
                {
                    label: 'OBRA',
                    value: 'OBRA',
                },
            ],
            onConfirm: ({value}) => {
                onChange({
                    ...item,
                    campo: value,
                })
            }
        });
    }

    const onClickDescartarFlota = () =>{
        if(sortableRef && sortableRef.current && sortableRef.current.sortable){
            sortableRef.current.sortable.destroy();
        }
        onRemove()
    }

    const onClickVer = () => {
        if(typeof item.id === 'number'){
            showModal(ModalFlotaJugadorVer,{item:item})
        }
    }

    useEffect(() => {
        const height = innerList.length + 2;
        if(grid && widget){
            const newOptions = {h:height,minH:height};
            onChangeWidget(newOptions);
            grid.update(widget,newOptions);
        }
    }, [widget,innerList]);

    useEffect(() => {
        if(sortableRef && sortableRef.current && sortableRef.current.sortable){
            sortableRef.current.sortable.option('group',{
                name:'shared',
                put:onCheckIntegrity
            });
        }
    }, [item,flotas]);

    return (
        <>
            <div
                className="grid-stack-item-nombre bg-primary"
            >
                <div className="row">
                    <div className="col-7">
                        <button className="btn btn-xs" type="button" onClick={onClickVer}>
                            <div className="row">
                                <div className="col-6">
                                    <i className="fa-solid fa-car-side text-white" />
                                </div>
                                <div className="col-6">
                                <h6 className="text-white">{AuxiliarFunction.formatMatricula(item.vehiculo?.patente)}</h6>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className="offset-3 col-1">
                        <button className="btn btn-primary btn-xs float" type="button" onClick={onClickDescartarFlota}>
                        X    
                        </button>
                    </div>
                </div>
            </div>
            <ReactSortable
                ref={sortableRef}
                id={`jugadores-${item.id}`}
                tag='div'
                className='list-group'
                group={{
                    name:'shared',
                    put:onCheckIntegrity
                }}
                emptyInsertThreshold={40}
                animation={150}
                ghostClass="grid-stack-item-content"
                list={innerList}
                setList={setInnerList}
                onAdd={(ev)=>{
                    const newId = AuxiliarFunction.toIntegerNumber(ev.item.id);
                    onChange({
                        ...item,
                        jugadores: [...innerList,jugadores.find(fl=>fl.dni === newId)],
                    });
                }}
                onRemove={(ev)=>{
                    const newId = AuxiliarFunction.toIntegerNumber(ev.item.id);
                    onChange({
                        ...item,
                        jugadores: [...innerList].filter((jg)=>jg.dni !== newId),
                    });
                }}
            >
                {innerList.map((jugador,index) => {
                    const { dni, nombre } = jugador;
                    return(
                        <div
                            className="list-group-item"
                            id={dni}
                            key={dni}
                            onMouseDown={onMouseDown}
                            onMouseLeave={onMouseLeave}
                            role="presentation"
                        >
                            <div className="row my-2">
                                    <div className="col-1">
                                        <i className="fa-solid fa-user mt-2 ml-1 text-primary" />
                                    </div>
                                    <div className="col-9">
                                    <div className="list-group-item-title text-left ml-2" style={{fontSize:13}}>{nombre}</div>
                                    <div className="list-group-item-title text-left ml-2" style={{fontSize:13}}>{badgeTipoJugador(index)}</div>
                                    </div>
                                    <div className="col-2 text-left">
                                        <Button
                                            className="text-danger border shadow-sm position-absolute" 
                                            size="xs" 
                                            theme="" 
                                            icon="fa fa-trash fa-xs" 
                                            onClick={()=>onClickDescartarJugador(dni)}
                                        />
                                    </div>
                            </div>
                        </div>
                    )}
                )}
            </ReactSortable>
            <div className="">
                <div className="row">
                    <div className="col-5">
                        <span className="ml-2">DESTINO</span>
                    </div>
                    <div className="col-4">
                        <strong>{item.campo}</strong>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-xs" type="button" onClick={onClickChangeCampo} title="Cambiar campo de trabajo">
                            <i className="fa-solid fa-pen-to-square text-primary"  />
                        </button>
                    </div>

                </div>
            </div>
        </>
    );
})

WidgetFlota.defaultProps = {
    item:{
        id:0,
        vehiculo:{
            patente: '',
        },
    },
    onChange: () => {},
    onRemove: () => {},
};

const mapStateToProps = (state) => ({
    flotas: state.flotas.flotas,
    jugadores: state.flotas.jugadores,
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetFlota);