import React, {useState, useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import { useSelector, useDispatch, connect } from 'react-redux';
import {useWindowWidth} from '@react-hook/window-size';
/*
import LogRocket from 'logrocket';
*/
import { actualizarLugares,actualizarTareasEstados, actualizarTiposTareas, actualizarZonas, actualizarAcciones } from '@app/store/slices/default.slice';
import AdministracionPage from '@app/pages/coorporativos/Administracion';
import Dashboard from '@pages/Dashboard';
import HomePage from '@pages/home/home';
import Profile from '@pages/profile/Profile';
import LugaresPage from '@pages/lugares/Lugares';
import CalendarioPage from '@app/pages/calendario/Calendario';
import CuadrillaDisponiblesPage from '@app/pages/cuadrillas/disponibles/CuadrillaDisponibles';
import CuadrillaDisponibleVerPage from '@app/pages/cuadrillas/disponibles/ver/CuadrillaDisponibleVer';
import CuadrillaLugarListadoPage from '@app/pages/cuadrillas/lugares/Listado';
import CuadrillaLugaresPage from '@app/pages/cuadrillas/lugares/CuadrillaLugares';
import DatosCoorporativosPage from '@app/pages/coorporativos/DatosCoorporativos';
import DatosClientesPage from '@app/pages/coorporativos/DatosClientes';
import FlotaJugadoresPage from '@app/pages/flotas/jugadores/FlotaJugadores';
import FlotaLugaresPage from '@app/pages/flotas/lugares/FlotaLugares';
import InformacionTecnicaPage from '@app/pages/coorporativos/InformacionTecnica';
import NodosPage from '@app/pages/mapas/nodos/Nodos';
import PoligonosPage from '@app/pages/mapas/poligonos/Poligonos';
import PuestosPage from '@app/pages/puestos/Puestos';
import TareasPage from '@app/pages/tareas/Tareas';
import TareaNuevoPage from '@app/pages/tareas/TareaNuevo';
import TareaPymesNuevoPage from '@app/pages/tareas/TareaPymesNuevo';
import TareasPymesVipPage from '@app/pages/tareas/TareasPymesVip';
import MapaComercialPage from '@app/pages/mapas/MapaComercial';
import MapaIngenieriaPage from '@app/pages/mapas/MapaIngenieria';
import FlotasAsignacionPage from '@app/pages/flotas/FlotasAsignacion';
import FlotaVerPage from '@app/pages/flotas/ver/FlotaVer';
import CuadrillasPlanificacionPage from '@app/pages/cuadrillas/CuadrillasPlanificacion';
import MotivoReprogramadosPage from '@app/pages/motivos/MotivoReprogramados';
import MotivoTareasPage from '@app/pages/motivos/MotivoTareas';
import TipoFotoPage from '@app/pages/tipoFoto/TipoFoto';
import AsesorPage from '@app/pages/asesor/Asesor';
import FormAsesorPage from '@app/pages/asesor/FormAsesor';
import AsesorVerPage from '@app/pages/asesor/ver/AsesorVer';
import JugadoresPage from '@app/pages/flotas/jugadores/Jugadores';
import FotoPage from '@app/pages/tipoFoto/Foto';
import AsesorxLugarPage from '@app/pages/asesor/asesorxLugar';
import AsesorxLugarTablePage from '@app/pages/asesor/asesorxLugarTable';
import DetallePage from '@app/pages/asesor/Detalle';
import ContactosPage from '@app/pages/contactos/contactos';
import ContactoNuevoPage from '@app/pages/contactos/ContactoNuevo';
import ContactoMapaPage from '@app/pages/contactos/ContactoMapa';
import ContactoVerPage from '@app/pages/contactos/ver/ContactoVer';
import MapaAsesorPage from '@app/pages/mapas/MapaAsesor';
import AdministrarUsuariosPage from '@app/pages/coorporativos/AdministrarUsuarios';
import VehiculoPage from '@app/pages/flotas/vehiculos/Vehiculos';
import CanalTareaPage from '@app/pages/tareas/canalTareas';
import ZonasPage from '@app/pages/zonas/zonas';
import PlataformaPage from '@app/pages/coorporativos/Plataforma';
import PlanesPage from '@app/pages/planes/Planes';
import TareaForm1 from '@app/pages/contactos/TareaForm1';
import TareaForm2 from '@app/pages/contactos/TareaForm2';
/* import MensajePage from '@app/pages/mensajes/Mensajes'; */
import ContactoExistentePage from '@app/pages/contactos/ContactoExistente';
import ContactoContactCenter from '@app/pages/contactoContactCenter/ContactoExistente';
import ComisionesGeneralesPage from '@app/pages/comisiones/ComisionesGenerales';
import RecibosPage from '@app/pages/recibos/Recibos';
import ReciboNuevo from '@app/pages/recibos/ReciboNuevo';
import TarjetasVentasPage from '@app/pages/asesor/tarjetas/TarjetasVentas';
import PasosPage from '@pages/home/pasos';
import Logs from '@app/pages/logs/Logs';
import RolesPage from '@app/pages/roles/Roles';
import AccionesPage from '@app/pages/acciones/Acciones';
import InicioCorporativoPage from '@app/pages/coorporativos/InicioCorporativo';
import CorporativoLogPage from '@app/pages/coorporativos/CorporativoLog';

import usePermisos from '@app/hooks/usePermisos';

import Header from './header/Header';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import * as AuthAction from '../../store/actions/auth';


const Main = ({
    onUserLoad,
    user,
}) => {
    const history = useHistory();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [menusidebarState, updateMenusidebarState] = useState({
        isMenuSidebarCollapsed: false
    });
    const onlyWidth = useWindowWidth();

    const toggleMenuSidebar = () => {
        updateMenusidebarState({
            isMenuSidebarCollapsed: !menusidebarState.isMenuSidebarCollapsed
        });
    };

    const onClickMenu = () => {
        if (onlyWidth < 992) {
            toggleMenuSidebar();
        }
    };
    /*
    useEffect(() => {
        if(user && user.dni){
            LogRocket.identify(user.dni, {
                name: user.nombreCompleto,
                email: user.email,
            });
        }
    }, [user])
    */


    useEffect(() => {
        /*
        LogRocket.init('1mv8fo/modoki');
        */
        onUserLoad();
        Promise.all([
            dispatch(actualizarZonas()),
            dispatch(actualizarLugares()),
            dispatch(actualizarTareasEstados()),
            dispatch(actualizarTiposTareas()),
            /*
            dispatch(actualizarAcciones()),
            */
        ]);

    }, []);

    return (
        <div
            className={`sidebar-mini layout-fixed ${
                menusidebarState.isMenuSidebarCollapsed
                    ? 'sidebar-open'
                    : 'sidebar-collapse'
            }`}
            style={{height:'auto'}}
        >
            <div className="wrapper">
                <Header toggleMenuSidebar={toggleMenuSidebar} />
                <MenuSidebar toggleMenuSidebar={onClickMenu} />
                <div className="content-wrapper">
                    <div className="pt-1" />
                    <section className="content">
                        <Switch>
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/" component={Dashboard} />
                            <Route
                                exact
                                path="/calendario"
                                component={CalendarioPage}
                            />
                            <Route
                                exact
                                path="/cuadrillas/disponibles"
                                component={CuadrillaDisponiblesPage}
                            />
                            <Route
                                exact
                                path="/cuadrillas/disponibles/:id/ver"
                                component={CuadrillaDisponibleVerPage}
                            />
                            <Route
                                exact
                                path="/cuadrillas/lugares/listado"
                                component={CuadrillaLugarListadoPage}
                            />
                            <Route
                                exact
                                path="/cuadrillas/lugares"
                                component={CuadrillaLugaresPage}
                            />
                            <Route
                                exact
                                path="/cuadrillas/planificaciones"
                                component={CuadrillasPlanificacionPage}
                            />
                            <Route
                                exact
                                path="/flotas/jugadores"
                                component={FlotaJugadoresPage}
                            />
                            <Route
                                exact
                                path="/flotas/lugares"
                                component={FlotaLugaresPage}
                            />
                            <Route
                                exact
                                path="/flotas/asignaciones"
                                component={FlotasAsignacionPage}
                            />
                            <Route
                                exact
                                path="/flotas/{:id}/ver"
                                component={FlotaVerPage}
                            />
                            <Route
                                exact
                                path="/mapas/lugares"
                                component={LugaresPage}
                            />
                            <Route
                                exact
                                path="/mapas/nodos"
                                component={NodosPage}
                            />
                            <Route
                                exact
                                path="/mapas/poligonos"
                                component={PoligonosPage}
                            />
                            <Route
                                exact
                                path="/mapas/comercial"
                                component={MapaComercialPage}
                            />
                            <Route
                                exact
                                path="/mapas/ingenieria"
                                component={MapaIngenieriaPage}
                            />
                            <Route
                                exact
                                path="/mapas/asesor"
                                component={MapaAsesorPage}
                            />
                            <Route
                                exact
                                path="/tareas"
                                component={TareasPage}
                            />
                            <Route
                                exact
                                path="/tareas/pymes"
                                component={TareasPymesVipPage}
                            />
                            <Route
                                exact
                                path="/tareas/nuevo"
                                component={TareaNuevoPage}
                            />
                            <Route
                                exact
                                path="/tareas/:id/editar"
                                component={TareaNuevoPage}
                            />
                            <Route
                                exact
                                path="/tareaspymes/nuevo"
                                component={TareaPymesNuevoPage}
                            />
                            <Route
                                exact
                                path="/tareaspymes/:id/editar"
                                component={TareaPymesNuevoPage}
                            />
                            <Route
                                exact
                                path="/motivos/reprogramados"
                                component={MotivoReprogramadosPage}
                            />
                            <Route
                                exact
                                path="/motivos/tareas"
                                component={MotivoTareasPage}
                            />
                            <Route
                                exact
                                path="/tipo/TipoFoto"
                                component={TipoFotoPage}
                            />
                            <Route
                                exact
                                path="/fotos"
                                component={FotoPage}
                            />
                            <Route
                                exact
                                path="/personas"
                                component={AsesorPage}
                            />
                            <Route
                                exact
                                path="/personas/nuevo"
                                component={FormAsesorPage}
                            />
                            <Route
                                exact
                                path="/personas/:id/editar"
                                component={FormAsesorPage}
                            />
                            <Route
                                exact
                                path="/personas/:id/ver"
                                component={AsesorVerPage}
                            />
                            <Route
                                exact
                                path="/asesor/detalle"
                                component={DetallePage}
                            />
                            <Route
                                exact
                                path="/jugadores"
                                component={JugadoresPage}
                            />
                            <Route
                                exact
                                path="/asesores/lugares"
                                component={AsesorxLugarTablePage}
                            />
                            <Route
                                exact
                                path="/asesores/lugares/nuevo"
                                component={AsesorxLugarPage}
                            />
                            <Route
                                exact
                                path="/contactos"
                                component={ContactosPage}
                            />
                            <Route
                                exact
                                path="/contactos/nuevo"
                                component={ContactoNuevoPage}
                            />
                            <Route
                                exact
                                path="/contactos/mapa"
                                component={ContactoMapaPage}
                            />
                            <Route
                                exact
                                path="/contactos/:id/ver"
                                component={ContactoVerPage}
                            />
                            <Route
                                exact
                                path="/tipoCanales"
                                component={CanalTareaPage}
                            />
                            <Route
                                exact
                                path="/vehiculos"
                                component={VehiculoPage}
                            />
                            <Route
                                exact
                                path="/zonas"
                                component={ZonasPage}
                            />
                            <Route
                                exact
                                path="/planes"
                                component={PlanesPage}
                            />
                            <Route
                                exact
                                path="/puestos"
                                component={PuestosPage}
                            />
                            <Route
                                exact
                                path="/TareaForm1"
                                component={TareaForm1}
                            />
                            <Route
                                exact
                                path="/TareaForm2"
                                component={TareaForm2}
                            />
{/*                             <Route
                                exact
                                path="/mensajes"
                                component={MensajePage}
                            /> */}
                            <Route
                                exact
                                path="/ContactoExistente"
                                component={ContactoExistentePage}
                            />
                            <Route
                                exact
                                path="/ContactoContactCenter"
                                component={ContactoContactCenter}
                            />
                            <Route
                                path="/comisiones/generales"
                                component={ComisionesGeneralesPage}
                            />
                            <Route
                                path="/recibos/nuevo"
                                component={ReciboNuevo}
                            />
                            <Route
                                path="/recibos"
                                component={RecibosPage}
                            />
                            <Route
                                path="/asesor/tarjetas/ventas"
                                component={TarjetasVentasPage}
                            />
                            <Route
                                path="/home/pasos"
                                component={PasosPage}
                            />
                            <Route
                                path="/home"
                                component={HomePage}
                            />
                            <Route
                                path="/Log/log"
                                component={Logs}
                            />
                            <Route
                                path="/roles"
                                component={RolesPage}
                            />
                            <Route
                                path="/acciones"
                                component={AccionesPage}
                            />
                            <Route
                                path="/inicio/coorporativos"
                                component={InicioCorporativoPage}
                            />
                            <Route
                                path="/coorporativos"
                                component={DatosCoorporativosPage}
                            />
                            <Route
                                path="/coorporativo/administracion"
                                component={AdministracionPage}
                            />
                            <Route
                                path="/coorporativo/clientes"
                                component={DatosClientesPage}
                            />
                            <Route
                                path="/coorporativo/plataforma"
                                component={PlataformaPage}
                            />
                            <Route
                                path="/coorporativo/tecnica"
                                component={InformacionTecnicaPage}
                            />
                            <Route
                                path="/coorporativo/administrar/usuarios"
                                component={AdministrarUsuariosPage}
                            />
                            <Route
                                path="/coorporativo/logs"
                                component={CorporativoLogPage}
                            />
                        </Switch>
                    </section>
                </div>
                <div
                    id="sidebar-overlay"
                    role="presentation"
                    onClick={toggleMenuSidebar}
                    onKeyDown={() => {}}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    onUserLoad: () => dispatch(AuthAction.me())
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
