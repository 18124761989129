import { defaultQueryPoligonos } from '@app/constants/geolocationOptions';
import PoligonosService from '@app/services/poligonos.service';
import TareasService from '@app/services/tareas.service';

export const MAPAS_POLIGONOS = 'MAPAS_POLIGONOS';
export const MAPAS_TAREAS = 'MAPAS_TAREAS';
export const MAPAS_PREFACTIBILIDAD = 'MAPAS_PREFACTIBILIDAD';

export const MAPAS_MOSTRAR_TAREAS = 'MAPAS_MOSTRAR_TAREAS';
export const MAPAS_MOSTRAR_PANELES = 'MAPAS_MOSTRAR_PANELES';


export const recuperarPoligonos = (data) => (dispatch) => {
    const query = {
        ...defaultQueryPoligonos,
        ...data
    }
    return PoligonosService.getAll(query).then(({items}) => {
        dispatch({
            type: MAPAS_POLIGONOS,
            payload: items
        });
        return Promise.resolve(items);
    });
};

export const recuperarTareas = (data) => (dispatch) => {
    const query = {
        ...defaultQueryPoligonos,
        ...data
    }
    return TareasService.tareasbuscarmap(data).then((response) => {
        dispatch({
            type: MAPAS_TAREAS,
            payload: response
        });
        return Promise.resolve(response);
    });
};

export const recuperarPrefactibilidad = (data) => (dispatch) => {
    const query = {
        esPrefactibilidad:true,
        ...data
    }
    return PoligonosService.getAll(query).then(({prefactibilidad}) => {
        dispatch({
            type: MAPAS_PREFACTIBILIDAD,
            payload: prefactibilidad
        });
        return Promise.resolve(prefactibilidad);
    });
};

export const cambiarMostrarTareas = () => ({
    type: MAPAS_MOSTRAR_TAREAS,
});

export const cambiarMostrarPaneles = () => ({
    type: MAPAS_MOSTRAR_PANELES,
});
