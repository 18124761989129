import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import AsesorService from '@app/services/asesor.service';
import FotoService from '@app/services/foto.service';
import TabAsesorResumen from '@app/pages/asesor/ver/TabAsesorResumen';
import {Tabs, Tab} from 'react-bootstrap';

function AsesorVerPage({
    location
}) {
    const history = useHistory();
    const {id} = useParams();
    const { item } = (location && location.state) || { };
    const [asesor, setAsesor] = useState(item);
    const [key, setKey] = useState('resumen');
    const [fotos, setFotos] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const onBack = () =>{
        history.goBack()
    }
    useEffect(() => {
        AsesorService.getById(id).then((response)=>{
            setAsesor(response)
        })
        .finally(()=>{
            setIsLoading(false);
        })
        return () => {

        }
    }, [])

    useEffect(() => {
        FotoService.getAll({
            personaId: asesor.dni
        })
        .then((response)=>{
            setFotos(response);
        });
        return () => {

        }
    }, [])

    return (
            <div className="container-fluid p-3">
                <div className="row">
                    <div className="col-lg-2">
                        <button className="btn btn-primary btn-regresar" type="button" onClick={onBack}>
                            <i className="fas fa-arrow-left" />
                        </button>
                    </div>
                    <div className="col-md-12 col-lg-8 my-lg-3 text-center">
                        <h2>
                            {
                                asesor && (
                                    `Asesor #${id} - ${asesor.nombreCompleto??''}`
                                )
                            }
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <img
                                        className="profile-user-img img-fluid img-circle"
                                        src={fotos[0]?.tipoFoto.nombre === "FOTO ASESOR" ? fotos[0].foto_url : "/img/default-profile.png"}
                                        alt="User profile"
                                    />
                                </div>
                                <h3 className="profile-username text-center">
                                    {asesor.nombreCompleto}
                                </h3>
                                <p className="text-muted text-center">
                                    {`${asesor.puesto} / ${asesor.departamento}`}
                                </p>
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Apellido</b>
                                        <span className="float-right">
                                            {asesor.apellido}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Nombre</b>
                                        <span className="float-right">
                                            {asesor.nombre}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Cuit</b>
                                        <span className="float-right">
                                            {asesor.cuit}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Dirección</b>
                                        <span className="float-right">
                                            {asesor.domicilio}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Celular</b>
                                        <span className="float-right">
                                            {asesor.telefono}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Email</b>
                                        <span className="float-right">
                                            {asesor.email}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="card">
                            {
                                !isLoading && (
                                    <Tabs
                                        id="contactosTab"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                    >
                                        <Tab eventKey="resumen" title="Imagenes" tabClassName="card-body">
                                            <TabAsesorResumen item={asesor} />
                                        </Tab>
                                    </Tabs>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
    )
}

export default AsesorVerPage
