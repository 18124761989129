import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link , Prompt} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalDatosCoorporativosEditar from '@app/modals/coorporativos/ModalDatosCoorporativos';
import {Button, ModalConfirm} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';
import ModalDatosClientesEditar from '@app/modals/coorporativos/ModalDatosClientesEditar';
import DatosClientesService from '@app/services/coorporativo/datosClientes.service';
import FechaFunction from '@app/functions/FechaFunction';
import CamposVisiblesService from '@app/services/coorporativo/camposVisibles.service';

const DatosClientesPage = ({user}) => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);

    const actualizar = async () => {
        const response = await DatosClientesService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(DatosClientesService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenEditar = async (item) => {

        const query={
            camposVisibles:true
        }

        const campos = await CamposVisiblesService.getAll(query);

        showModal(ModalDatosClientesEditar, {item: item, onGuardar: onGuardar, camposVisibles:campos});
    };

    const datosCliente = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'clienteNombre'
            },
            {
                Header: 'Id Servicio',
                accessor: 'servicioId'
            },
            {
                Header: 'Punto',
                accessor: 'punto'
            },
            {
                Header: 'Localidad',
                accessor: 'localidad'
            },
            {
                Header: 'Servicio Vigente',
                accessor: 'servicioVigente'
            },
            {
                Header: 'Segmentos',
                accessor: 'segmento'
            },
            {
                Header: 'Servicio',
                accessor: 'servicio'
            },
            {
                Header: 'Det de Servicio',
                accessor: 'detalleServicio'
            },
            {
                Header: 'Categoria',
                accessor: 'categoria'
            },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd-MM-yyyy')}
                        </span>
                    );
                }
            },
            {
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => onOpenEditar(row.original)}
                        >
                                <i className="fas fa-edit" />
                        </button>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);    

    return (
        <>
                <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-lg-3 my-lg-3">
                                <h3 className="ml-1" data-aos="fade-right">Datos Clientes</h3>
                            </div>
                            <div className="col-lg-5 mt-2">
                            </div>
                            {/* <div className="offset-2 col-lg-2 mt-2">
                                <Button disabled={isLoadingExportar} icon={['fas','file-excel']} className="btn btn-block btn-success" data-aos="fade-up" onClick={()=>onClickExportar()}>
                                    EXCEL
                                </Button>
                            </div> */}

                            <div className="col-12 overflow-hidden" data-aos="fade-right">
                                    <TablePaginated columns={datosCliente} data={items} />
                            </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DatosClientesPage);
