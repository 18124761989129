import axios from 'axios';
const base = 'zoho';

function searchLocalidad(data) {
    return axios.get([base, 'searchLocalidades'].join('/'), {
        params: data
    });
}

const ZohoLocalidadService = {
    searchLocalidad: searchLocalidad
};
export default ZohoLocalidadService;