import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, MapClick, BoxPrefactibilidad} from '@app/components/index';
import {useForm} from 'react-hook-form';
import {Card,Row,Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import MapaFunction from '@app/functions/MapaFunction';
import { defaultCoordinates } from '@app/constants/geolocationOptions';
import * as CalendarAction from '@app/store/actions/calendar';

function ModalDejarRepro({show, hideModal,item,agregarReprogramacion, itemReprogramado}) {
    const handleClose = () => hideModal();
    const [mapLocation, setMapLocation] = useState(defaultCoordinates);
    const [prefactibilidadLocation, setPrefactibilidadLocation] = useState();
   
    useEffect(() => {
         if(item.coordenadas_t)
        setMapLocation(MapaFunction.gJsonToGoogleMaps(item.coordenadas_t));
    }, []);
   
    const {register,errors,setValue} = useForm();
    const DatosCliente = () =>{
        return (
            <div>
                <div>
                    <div className="">
                        Nombre: 
                    </div>
                        {item.clienteNombre}
                </div>
                <div>
                    <div className="">
                        Documento: 
                    </div>
                        {item.clienteDocumento}
                </div>
                <div>
                    <div className="">
                        Direccion: 
                    </div>
                        {item.clienteDireccion}
                </div>
                <div>
                    <div className="">
                        Celular:
                    </div>
                        {item.clienteCelular}
                </div>
                <div>
                    <div className="">
                    Cliente Nro: 
                    </div>
                        {item.clienteId}
                </div>
            </div>
            
        )
    }
    const onClickEliminarRepro = () => {
        agregarReprogramacion(null);
        handleClose();
    }
    return (
                <Modal className="modal fade" show={show} backdrop="static" size="lg">
                    <div className="col-12 text-center">
                        <Modal.Header closeButton className="border-0">
                            <Modal.Title className="text-center">
                                Tarea Para Reprogramar
                            </Modal.Title>
                        </Modal.Header>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col p-2 mx-1 shadow-sm border border-gray card rounded">                             
                                <b className="text-center">Datos del Cliente</b>                    
                                <div>
                                    {DatosCliente()}
                                </div>
                            </div>
                            <div className="col mx-1 p-2 shadow-sm border border-gray card rounded">
                                <b className="text-center">Motivo de Reprogramacion</b>
                                <div>
                                    {item.motivoReprogramado.nombre}
                                </div>
                                <div>
                                    <label>{item.motivoReprogramadoObservaciones}</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <MapClick 
                                    id="mapaComercial"
                                    options={{
                                        maxZoom: 19,
                                        minZoom: 14,
                                    }}
                                    zoom={15}
                                    /* coordinates={mapLocation} */
                                >
                                </MapClick>
                                <Modal.Footer>
                                    <Button theme="default" onClick={handleClose}>
                                        Cerrar
                                    </Button>
                                    <Button
                                        theme="warning"
                                        onClick={onClickEliminarRepro}
                                    >
                                        Dejar de Reprogramar
                                    </Button>
                                </Modal.Footer>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
}



const mapStateToProps = (state) => ({
    itemReprogramado: state.calendar.itemReprogramado,
});

const mapDispatchToProps = (dispatch) => ({
    agregarReprogramacion: (data) =>  dispatch(CalendarAction.agregarReprogramacion(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDejarRepro);