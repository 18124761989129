import AccionesService from '@app/services/acciones.service';
import LugaresService from '@app/services/lugares.service'
import TiposService from '@app/services/tipos.service';
import ZonasService from '@app/services/zonas.service';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const actualizarZonas = createAsyncThunk(
    "default/zonas", async () => ZonasService.getAll()
);

export const actualizarLugares = createAsyncThunk(
    "default/lugares", async () => LugaresService.getAll()
);

export const actualizarTareasEstados = createAsyncThunk(
    "default/tareasEstados", async () => TiposService.tareasEstados()
);

export const actualizarTiposTareas = createAsyncThunk(
    "default/TiposTareas", async () => TiposService.tareas()
);

export const actualizarAcciones = createAsyncThunk(
    "default/acciones", async () => AccionesService.getAll()
);

export const defaultSlice = createSlice({
    name: 'default',
    initialState: {
        zonas: [],
        lugares: [],
        tareasEstados: [],
        tiposTareas: [],
        acciones: [],
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(actualizarZonas.fulfilled, (state, { payload }) => {
            state.zonas = payload;
        });
        builder.addCase(actualizarLugares.fulfilled, (state, { payload }) => {
            state.lugares = payload;
        });
        builder.addCase(actualizarTareasEstados.fulfilled, (state, { payload }) => {
            state.tareasEstados = payload;
        });
        builder.addCase(actualizarTiposTareas.fulfilled, (state, { payload }) => {
            state.tiposTareas = payload;
        });
        builder.addCase(actualizarAcciones.fulfilled, (state, { payload }) => {
            state.acciones = payload;
        });
    }
});

/*
export const {  } = defaultSlice.actions;
*/
export default defaultSlice.reducer
