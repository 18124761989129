import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link , Prompt} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalDatosCoorporativosEditar from '@app/modals/coorporativos/ModalDatosCoorporativos';
import {Button, ModalConfirm} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';
import InformacionTecnicaService from '@app/services/coorporativo/informacionTecnica.service';
import ModalInformacionTecnicaEditar from '@app/modals/coorporativos/ModalInformacionTecnicaEditar';
import FechaFunction from '@app/functions/FechaFunction';
import ReactTooltip from 'react-tooltip';
import ModalEditarObservacionesInformacionTecnica from '@app/modals/coorporativos/ModalEditarObservacionesTecnica';
import CamposVisiblesService from '@app/services/coorporativo/camposVisibles.service';

const InformacionTecnicaPage = ({user}) => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);
    const [estadoTabla, setEstadoTabla] = useState('d.cliente');
    const [tituloTabla, setTituloTabla] = useState()
    const [tabla, setTabla] = useState([])

    const actualizar = async () => {
        const response = await InformacionTecnicaService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Datos',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                InformacionTecnicaService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(InformacionTecnicaService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenEditar = async (item) => {
        const query={
            camposVisibles:true
        }

        const camposVisibles = await CamposVisiblesService.getAll(query);
        showModal(ModalInformacionTecnicaEditar, {item: item, onGuardar: onGuardar, camposVisibles: camposVisibles});
    };

    const editarObservacionesTecnicas = (item) =>{
        showModal(ModalEditarObservacionesInformacionTecnica, {item: item, onGuardar: onGuardar});
    }


    const informacionTecnica = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'contactoCorporativo.clienteNombre'
            },
            {
                Header: 'Id Servicio',
                accessor: 'contactoCorporativo.servicioId'
            },
            {
                Header: 'Contactos Tecnicos',
                accessor: 'contactoTecnico',
                Cell: ({row}) => {

                    const lista = row.original.contactoTecnico
                    
                    return (
                        <>
                            {lista !== "[]" ? (
                                <>
                                    <a
                                        className='btn btn-sm btn-info'
                                        data-tip
                                        data-for={row.original?.id.toString()}
                                    >
                                        Ver
                                    </a>
                                        <ul>
                                            <ReactTooltip id={row.original.id.toString()} aria-haspopup='true'>
                                                    <p className='text-center'>Contactos Tecnicos</p>
                                                {JSON.parse(row.original.contactoTecnico).map((contacto) => {
                                                    return (
                                                        <>
                                                                <li className='text-left'>
                                                                        {contacto.item}
                                                                </li>
                                                        </>
                                                   )
                                                })}
                                            </ReactTooltip>
                                        </ul>
                                </>
                            ) : (
                                <p>
                                    Sin contactos
                                </p>
                            )}
                        </>
                    );
                }
            },
            {
                Header: 'F. Instalación',
                accessor: 'fechaInstalacion',
                Cell: ({row}) => {
                    return row.original.fechaInstalacion && (
                        <span>
                            {FechaFunction.format(row.original.fechaInstalacion,'dd/MM/yyyy')}
                        </span>
                    )
                }, 
            },
            {
                Header: 'Fecha Alta',
                accessor: 'fechaAlta',
                Cell: ({row}) => {
                    return row.original.fechaAlta && (
                        <span>
                        {FechaFunction.format(row.original.fechaAlta,'dd/MM/yyyy')}
                        </span>
                        )
                }
            },
            {
                Header: 'Fecha Baja',
                accessor: 'fechaBaja',
                 Cell: ({row}) => {
                    return row.original.fechaBaja && (
                        <span>
                            {FechaFunction.format(row.original.fechaBaja,'dd/MM/yyyy')}
                        </span>
                    )
                }
            },
            {
                Header: 'Latitud',
                accessor: 'latitud'
            },
            {
                Header: 'Longitud',
                accessor: 'longitud'
            },
            {
                Header: 'MB Subida',
                accessor: 'mbSubida'
            },
            {
                Header: 'MB Bajada',
                accessor: 'mbBajada'
            },
            {
                Header: 'Cantidad IP Publica',
                accessor: 'cantidadIpPublica'
            },
            {
                Header: 'Número de IP Publicas',
                accessor: 'numeroIpPublica'
            },
            {
                Header: 'TK Abiertos',
                accessor: 'tkAbierto'
            },
            {
                Header: 'Obs',
                accessor: 'observacionTecnica',
                Cell: ({row}) => {
                    return(
                        <>
                            <button
                                type='button'
                                className='btn btn-sm btn-info'
                                data-tip
                                data-for={row.original.id.toString()+1}
                                onClick={()=>editarObservacionesTecnicas(row.original)}
                            >
                                <i className="fas fa-eye" />
                            </button>
                                <ul>
                                    <ReactTooltip id={row.original.id.toString()+1} aria-haspopup='true'>
                                            <p className='text-center'>Observaciones</p>
                                            <p>{row.original.observacionTecnica}</p>
                                    </ReactTooltip>
                                </ul>
                        </>
                    )
                }
            },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd/MM/yyyy')}
                        </span>
                    );
                }
            },
            {
                width: 10,
                Header: 'Editar',
                // accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        
                                <button
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    <i className="fas fa-edit" />
                                </button>
    
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();     
    }, []);


    return (
        <>
                <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-lg-3 my-lg-3">
                                <h3 className="ml-1" data-aos="fade-right">Información Técnica</h3>
                            </div>
                            <div className="col-lg-5 mt-2">
                            </div>
                            {/* <div className="offset-2 col-lg-2 mt-2">
                                <Button disabled={isLoadingExportar} icon={['fas','file-excel']} className="btn btn-block btn-success" data-aos="fade-up">
                                    EXCEL
                                </Button>
                            </div> */}


                            <div className="col-12 overflow-hidden" data-aos="fade-right">
                                    <TablePaginated columns={informacionTecnica} data={items} />
                            </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InformacionTecnicaPage);
