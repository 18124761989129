import React,{useState,useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import EstadisticasService from '@app/services/estadisticas.service';
import { Doughnut, Line } from 'react-chartjs-2';
import {Link, useHistory} from 'react-router-dom';
import { DateRange, SmallBox } from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';

const diariasLineOptions = {
    responsive: true,
    hoverMode: 'index',
    stacked: false,
    title: {
        display: true,
        text: 'Tareas'
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            },
        }],
    }
};

const totalesDoughnutOptions = {
    responsive: true,
    legend: {
        position: 'left',
    },
    title: {
        display: true,
        text: 'Tipo de tareas programadas y registradas'
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    circumference: 180,
    rotation: -90,
};

const Dashboard = () => {
    AOS.init();
    const history = useHistory();
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek());
    const [totales, setTotales] = useState({});
    const [diarias, setDiarias] = useState([]);
    const [diariasLine, setDiariasLine] = useState([]);
    const [totalesDoughnut, setTotalesDoughnut] = useState(null);


    const actualizar = (defaultRange = rangeDate) => {
        const { start, end} = defaultRange;
        const query = {
            fechaIni: FechaFunction.format(start),
            fechaFin: FechaFunction.format(end),
        };
        EstadisticasService.totales(query).then((response)=>{
            setTotales(response);
            const doughnutProgramadasDataset = {
                data: [
                    response.programados.totalRetiro,
                    response.programados.totalInstalacion,
                    response.programados.totalAsistencia,
                ],
                backgroundColor: [
                    'red',
                    'orange',
                    'yellow',
                ],
                label: "Tareas programadas",
            };
            const doughnutRegistradosDataset = {
                data: [
                    response.registrados.totalRetiro,
                    response.registrados.totalInstalacion,
                    response.registrados.totalAsistencia,
                ],
                backgroundColor: [
                    'red',
                    'orange',
                    'yellow',
                ],
                label: "Tareas registradas",
            };
            setTotalesDoughnut({
                datasets: [doughnutProgramadasDataset,doughnutRegistradosDataset],
                labels: ['Retiros','Instalaciones','Asistencias'],
            });
        });

        EstadisticasService.diarias(query).then((response)=>{
            setDiarias(response);
            const labels = response.registrados.map(item=>FechaFunction.format(item.fecha,'dd/MM'));
            const registradosDataSet = {
                label: "Tareas registradas",
                borderColor: 'red',
                data: response.registrados.map(item=>Number(item.total)),
            }
            const programadosDataSet = {
                label: "Tareas programadas",
                borderColor: 'blue',
                data: response.programados.map(item=>Number(item.total)),
            }
            setDiariasLine({
                datasets: [registradosDataSet, programadosDataSet],
                labels: labels,
            });
        });
    }
    const onChangeDate = (newRange) => {
        setRangeDate(newRange);
        actualizar(newRange);
    };

    const onClickTotalBox = (estado) =>{
        switch(estado){
            case 'ENESPERA':
                history.push({
                    pathname: '/tareas',
                    state: {
                        estadoTareaId: 1
                    }
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        actualizar();
        return () => {

        }
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-4 col-lg-3 my-lg-3 my-3">
                    <h1 className="text-gray-dark" data-aos="fade-right">Tareas</h1>
                </div>
                <div className="offset-1 col-7 offset-lg-6 col-lg-3 my-lg-3 my-3">
                    <DateRange defaultValue={rangeDate} onChange={onChangeDate} />
                </div>

                <div className="col-lg-3 col-6 col-md-3 border-redondo" data-aos="fade-up"> 
                    <SmallBox
                        count={totales.programados?.totalEnEspera || 0}
                        title="En Espera"
                        type="info"
                        onClick={() => onClickTotalBox('ENESPERA')}
                        navigateTo="/tareas"
                    />
                </div>
                <div className="col-lg-3 col-6 col-md-3 border-redondo" data-aos="fade-up">
                    <SmallBox
                        count={totales.registrados?.totalEnEspera || 0}
                        title="Registradas"
                        type="success"
                        onClick={() => onClickTotalBox('REGISTRADOS')}
                        navigateTo="/tareas"
                    />
                </div>
                <div className="col-lg-3 col-6 col-md-3 border-redondo text-white" data-aos="fade-up">
                    <SmallBox
                        count={totales.programados?.totalAsignado || 0}
                        title="Asignadas"
                        type="warning"
                        onClick={() => onClickTotalBox('ASIGNADOS')}
                        navigateTo="/tareas"
                    />
                </div>
                <div className="col-lg-3 col-6 col-md-3 border-redondo" data-aos="fade-up">
                    <SmallBox
                        count={totales.programados?.totalVencido || 0}
                        title="Vencidas"
                        type="danger"
                        onClick={() => onClickTotalBox('VENCIDOS')}
                        navigateTo="/tareas"
                    />
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12 col-lg-8 col-md-8">
                    <div className="card h-100">
                        <div className="card-body">
                            <Line data={diariasLine} options={diariasLineOptions} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mt-3 mt-md-0 mt-lg-0 col-md-4">
                    <div className="card h-100">
                        <div className="card-body">
                            <Doughnut data={totalesDoughnut} options={totalesDoughnutOptions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;