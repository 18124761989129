import React, {useState, useEffect} from 'react';
import axios from 'axios';
import TareasService from '@app/services/tareas.service';
import CalendarioService from '@app/services/calendario.service';
import FechaFunction from '@app/functions/FechaFunction';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
/**
 * Con cancelacion de tareas
 * @returns 
 */
export default function useGetCalendario({
    defaultQuery,
    defaultResponse,
}) {
    const [source, setSource] = useState()
    const [query, setQuery] = useState(defaultQuery || {})
    const [response, setResponse] = useState(defaultResponse || {items:[],total:0});
    const [isLoading, setIsLoading] = useState(false);
    
    const request = () => {
        if(!query){
            return;
        }
        if (source) {
            source.cancel();
        }
        const newSource = axios.CancelToken.source();
        setSource(newSource);
        setIsLoading(true);
        CalendarioService.getAll(query,newSource.token).then((newResponse)=>{
            if(newResponse){
                let fecha;
                const { items } = response;
                let aux = [...items];
                aux = aux.filter((el)=>{
                    return FechaFunction.format(el.start) < query.fechaIni && FechaFunction.format(el.start) > query.fechaFin;
                })
                if(items.length>1000){
                    aux = [];
                }
                newResponse.forEach((evento) => {
                    let newEvent = null;
                    switch (evento.tipo) {
                        case 'CUADRILLASDISPONIBLES':
                            fecha = FechaFunction.parse(evento.fecha);
                            if((evento.raw_data.cantidad - evento.raw_data.asignados)<1){
                                return;
                            }
                            newEvent = {
                                id: evento.id,
                                groupId: 'GRP-CUADRILLASDISPONIBLES',
                                title: `CD: ${(evento.raw_data.cantidad - evento.raw_data.asignados)}`,
                                start: fecha,
                                end: fecha,
                                className: 'calendar-event-cuadrillasDisponibles',
                                allDay: true,
                                extendedProps: {
                                    ...evento.raw_data,
                                    _tipo: 'CUADRILLASDISPONIBLES'
                                }
                            };
                            break;
                        case 'CUADRILLADISPONIBLELUGAR':
                            fecha = FechaFunction.parse(evento.fecha);
                            newEvent = {
                                id: evento.raw_data.id,
                                groupId: 'GRP-CUADRILLADISPONIBLELUGAR',
                                title: evento.raw_data.descripcion,
                                start: fecha,
                                end: fecha,
                                className: `calendar-event-cuadrillaDisponibleLugar calendar-event-cuadrillaDisponibleLugar-${evento.raw_data.id}`,
                                allDay: true,
                                backgroundColor: evento.raw_data.color,
                                textColor: AuxiliarFunction.getContrastYIQ(
                                    evento.raw_data.color
                                ),
                                extendedProps: {
                                    ...evento.raw_data,
                                    _tipo: 'CUADRILLADISPONIBLELUGAR'
                                }
                            };
                            break;
                        case 'TAREAS':
                            newEvent = {
                                id: evento.id,
                                groupId: 'GRP-TAREAS',
                                title: evento.raw_data.lugarNombre,
                                start: FechaFunction.parse(
                                    `${evento.raw_data.fecha} ${evento.raw_data.horaIni}`
                                ),
                                end: FechaFunction.parse(
                                    `${evento.raw_data.fecha} ${evento.raw_data.horaFin}`
                                ),
                                className: 'calendar-event-tareas',
                                backgroundColor: evento.raw_data.lugar?.color,
                                textColor: AuxiliarFunction.getContrastYIQ(
                                    evento.raw_data.lugar?.color || '#FFFFFF'
                                ),
                                extendedProps: {...evento.raw_data, _tipo: 'TAREAS'}
                            };
                            break;
                        default:
                            break;
                    }
                    if(newEvent){
                        const index = aux.findIndex(a=>a.id === newEvent.id);
                        if(index>=0){
                            aux[index] = newEvent;
                        } else {
                            aux.push(newEvent);
                        }
                    }
                });
                setResponse({
                    items: aux,
                    total: aux.length,
                });
            }
            setIsLoading(false);
        })
        .catch(()=>{
            setIsLoading(false);
        })
        .finally(()=>{
            
        });
    }

    useEffect(() => {
        request();
        return () => {
            
        }
    }, [query])

    return {response, setQuery, isLoading };
}
