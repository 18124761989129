import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import MotivoTareaService from '@app/services/motivoTarea.service';

const Styles = styled.div``;

const SelectMotivoTicket = ({id, value, onChange, isInvalid, isClearable,type,emp,tipoTareaId,soloInstalacion}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    const updateOptions = () => {
        let resp = items;
        if (tipoTareaId > 0) {
            const newOptions = items
                .filter((it) => String(it.tipoTareaId_mt) === tipoTareaId || it.tipoTareaId_mt)    
                .map((item) => {
                return {
                    value: item.id_mt,
                    label: item.motivo_mt,
                    descripcion: item.cuerpo_mt,
                    destino: item.grupoId_mt,
                    tipoTicket: item.tipoTicketId_mt,
                    subTipoTicket: item.subTipoTicketId_mt,
                    nivelAdm: item.nivelAdmTkId_mt,
                    motivoBaja: item.motivoBajaTk_mt,
                    dayResolucion: item.dayResolucion_mt
                };
            });
            setOptions(newOptions);
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    newOptions.find((opt) => opt.value === Number(value) || opt.value === String(value) )
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>({label:va.nombre,value:va.id})));
            }
        } else {
            setOptions();
        }

        if(soloInstalacion){
            resp = items.filter(res => res.id_mt === 4 || res.id_mt === 10)
        }
        const newOptions = resp.map((item) => {
            return {
                value: item.id_mt,
                label: item.motivo_mt,
                descripcion: item.cuerpo_mt,
                destino: item.grupoId_mt,
                tipoTicket: item.tipoTicketId_mt,
                subTipoTicket: item.subTipoTicketId_mt,
                nivelAdm: item.nivelAdmTkId_mt,
                motivoBaja: item.motivoBajaTk_mt,
                dayResolucion: item.dayResolucion_mt
            };
        });
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(
                newOptions.find((opt) => opt.value === Number(value))
            );
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>({label:va.motivo_mt,value:va.id_mt})));
        }
    };

    useEffect(() => {
        updateOptions();
        return () => {
            
        }
    }, [items]);

    useEffect(() => {
        updateOptions();
        setSelectedValue(null);
    }, [tipoTareaId]);
    
    useEffect(() => {
        MotivoTareaService.getAll().then((response) => {
            const resultado = response.filter((resp) => resp.tipoTareaId_mt === tipoTareaId)
            setItems(resultado)
        });
    }, [tipoTareaId]);

    return (
        <Styles>
            <Select
                inputId={id}
                // isDisabled={!tipoTareaId}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectMotivoTicket.defaultProps = {
    isClearable: false
};

export default SelectMotivoTicket;