import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button, SelectLugar} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import AsesorService from '@app/services/asesor.service';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({
    nombre: Yup.string().max(200).required(),
});

function ModalAsesor({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const localidad = watch('localidad', item.localidad.id);

    useEffect(() => {
        register('localidad');
    }, [register]);

    const onSubmit = (data) => {
        const form = {
            ...data,
            dni: item.dni
        };
        setIsLoading(true);
        if (form.id > 0) {
            AsesorService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            AsesorService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
        if (item && item.dni > 0) {
            setValue('nombre', item.nombre);
            setValue('dni', item.dni);
            setValue('telefono', item.telefono);
            setValue('email', item.email);
            setValue('domicilio', item.domicilio);
            setValue('barrio', item.barrio);
            setValue('vtoLicencia', item.vtoLicencia);
            setValue('cuit', item.cuit);
            setValue('fNacimiento', item.fNacimiento);
            setValue('localidad',item.localidad.id);
        }
    }, []);
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.dni > 0 ? 'Editar Asesor' : 'Registrar Asesor'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombreL">
                                        Apellido y Nombre
                                    </label>
                                    <input
                                        className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        autoComplete="off"
                                        required
                                        ref={register} 
                                    />
                                    {errors.nombre && (
                                        <div className="invalid-feedback">
                                            {errors.nombre.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="dniL">
                                        DNI
                                    </label>
                                    <input
                                        className={`form-control ${errors.dni? 'is-invalid': ''}`}
                                        id="dni"
                                        name="dni"
                                        type="text"
                                        autoComplete="off"
                                        required
                                        ref={register} 
                                    />
                                    {errors.dni && (
                                        <div className="invalid-feedback">
                                            {errors.dni.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="telefonoL">
                                        Telefono
                                    </label>
                                    <input
                                        className={`form-control ${errors.telefono? 'is-invalid': ''}`}
                                        id="telefono"
                                        name="telefono"
                                        type="text"
                                        autoComplete="off"
                                        required
                                        ref={register} 
                                    />
                                    {errors.telefono && (
                                        <div className="invalid-feedback">
                                            {errors.telefono.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="emailL">
                                        Email
                                    </label>
                                    <input
                                        className={`form-control ${errors.email? 'is-invalid': ''}`}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.email && (
                                        <div className="invalid-feedback">
                                            {errors.email.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="domicilioL">
                                        Domicilio
                                    </label>
                                    <input
                                        className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                        id="domicilio"
                                        name="domicilio"
                                        type="text"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.domicilio && (
                                        <div className="invalid-feedback">
                                            {errors.domicilio.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="barrioL">
                                        Barrio
                                    </label>
                                    <input
                                        className={`form-control ${errors.barrio? 'is-invalid': ''}`}
                                        id="barrio"
                                        name="barrio"
                                        type="text"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.barrio && (
                                        <div className="invalid-feedback">
                                            {errors.barrio.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="vtoLicenciaL">
                                        Localidad
                                    </label>
                                    <SelectLugar 
                                        id="localidad"
                                        isInvalid={Boolean(errors.localidad)}
                                        value={localidad}
                                        onChange={(opt) => {
                                        setValue('localidad', opt.value);
                                        }}
                                    />
                                    {errors.localidad && (
                                        <div className="default-invalid-feedback">
                                            {errors.localidad?.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="vtoLicenciaL">
                                        Venc. de Licencia
                                    </label>
                                    <input
                                        className={`form-control ${errors.vtoLicencia? 'is-invalid': ''}`}
                                        id="vtoLicencia"
                                        name="vtoLicencia"
                                        type="date"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.vtoLicencia && (
                                        <div className="invalid-feedback">
                                            {errors.vtoLicencia.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="cuitL">
                                        Cuit/Cuil
                                    </label>
                                    <input
                                        className={`form-control ${errors.cuit? 'is-invalid': ''}`}
                                        id="cuit"
                                        name="cuit"
                                        type="text"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.cuit && (
                                        <div className="invalid-feedback">
                                            {errors.cuit.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="fNacimientoL">
                                        Fecha de Nacimiento
                                    </label>
                                    <input
                                        className={`form-control ${errors.fNacimiento? 'is-invalid': ''}`}
                                        id="fNacimiento"
                                        name="fNacimiento"
                                        type="date"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.cuit && (
                                        <div className="invalid-feedback">
                                            {errors.fNacimiento.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalAsesor.defaultProps = {
    show: false,
    item: {
        dni: 0,
        nombre: '',
        telefono: '',
        email: '',
        domicilio: '',
        barrio: '',
        vtoLicencia: '',
        cuit: '',
        fNacimiento: '',
        localidad: ''
    }
};

export default ModalAsesor;