import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';

const Styles = styled.div``;

const SelectEstadoTarea = ({
    id,
    value,
    zonaId,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    stack,
    placeHolder,

    ...defaultProps
}) => {
    const items = useSelector(state => state.default.tareasEstados);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.id === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.id === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };

    useEffect(() => {
        setOptions(
            items
            .filter((lu) => lu.zonaId === zonaId)
            .map((item) => {
                return {
                    value: item.id,
                    label: item.nombre
                };
            })
        );
    }, [items,zonaId]);

    useEffect(() => {
        let newOptions = items
            .map((item) => {
            return {
                value: item.id,
                label: item.nombre
            };
        });
        if(zonaId>0){
            newOptions = items
                .filter((lu) => lu.zonaId === zonaId)
                .map((item) => {
                return {
                    value: item.id,
                    label: item.nombre
                };
            });
        }
        setOptions(newOptions);
        if (typeof value === 'string' || typeof value === 'number') {
            setSelectedValue(newOptions.find((opt) => opt.value === Number(value)));
        } else if(Array.isArray(value)){
            setSelectedValue(value.map(va=>({label:va.nombre,value:va.id})));
        }
    }, [items]);

    const conditionalProps = {}
    if(stack && stack.length === 0){
        conditionalProps.menuPortalTarget = document.body;
    }
    return (
        <Styles>
            <Select
                {...defaultProps}
                isDisabled={disabled}
                inputId={id}
                name={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                noOptionsMessage={()=>"Sin opciones"}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                {...conditionalProps}
                classNamePrefix="react-select"
                placeholder={placeHolder === null || placeHolder === undefined ? ("Seleccione...") : placeHolder}
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectEstadoTarea.defaultProps = {
    isClearable: false,
    onChange: () => {},
};

const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SelectEstadoTarea)
