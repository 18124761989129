import React, {Component, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {filter, deepMap} from 'react-children-utilities';

import Item from './SideItem';
import Searchbar from './SideSearchBar';

const Sidebar = ({children, searchbarFilter, toggleMenuSidebar}) => {
    const [searchValue, setSearchValue] = useState('initialState');
    const onSearchValueChange = ({target: {value}}) => {
        setSearchValue({searchValue: value});
    };
    const widgetReference = useRef(null);
    const flatten = (element) => {
        if (!element.props) {
            return [];
        }
        const {children: elemChildren, ...otherProps} = element.props;
        const elemWithoutChildren = React.cloneElement(
            element,
            otherProps,
            null
        );
        if (elemChildren) {
            let intermediate;
            if (typeof elemChildren !== 'string' && elemChildren.length) {
                intermediate = elemChildren.map(flatten).flat();
            } else {
                intermediate = flatten(elemChildren);
            }
            intermediate.push(elemWithoutChildren);
            return intermediate;
        }
        return [elemWithoutChildren];
    };

    let localChildren;
    if (children) {
        localChildren = children.length ? children : [children];
    } else {
        localChildren = [];
    }
    if (searchbarFilter) {
        const localSearchbar = localChildren.find((p) => p.type === Searchbar);
        if (localSearchbar) {
            const index = localChildren.map((p) => p.type).indexOf(Searchbar);
            const temp = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < localChildren.length; ++i) {
                if (i !== index) {
                    temp.push(localChildren[i]);
                } else {
                    const {onChange, ...tempProps} = localSearchbar.props;
                    temp.push(
                        React.cloneElement(localSearchbar, {
                            onChange: onSearchValueChange,
                            ...tempProps
                        })
                    );
                }
            }
            localChildren = temp;
            if (searchValue.length > 0) {
                const flattenChildren = localChildren
                    .filter((p) => p.type === Item)
                    .map(flatten)
                    .flat()
                    .filter((p) => p.props.to);
                const others = localChildren.filter(
                    (p) => p.type === Searchbar
                );
                localChildren = others.concat(
                    flattenChildren.filter(
                        (p) =>
                            p.props.text
                                .toUpperCase()
                                .indexOf(searchValue.toUpperCase()) > -1
                    )
                );
            }
        }
    }
    return (
        <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
            ref={widgetReference}
        >
            {
                deepMap(filter(localChildren,child=>child!==null),
                (child) => {
                    return React.cloneElement(child, {toggleMenuSidebar});
                })
            }
        </ul>
    );
};

Sidebar.defaultProps = {
    children: null,
    searchbarFilter: false
};

Sidebar.propTypes = {
    children(props, propName, componentName) {
        const prop = props[propName];
        let error;
        React.Children.forEach(prop, (el) => {
            if (error) return;
            if (el.type !== Item && el.type !== 'li' && el.type !== Searchbar) {
                error = new Error(
                    `\`${componentName}\` only accepts \`Item's, Searchbar and li's\`.`
                );
            }
        });
        return error;
    },
    searchbarFilter: PropTypes.bool
};
export default Sidebar;
