import React, {useState} from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import MapaFunction from '@app/functions/MapaFunction';

function MarkerContacto({
    item,

    ...defaultProps
}) {
    const [marker, setMarker] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    let newCoord;
    const { coordenadas } = item;
    if(coordenadas){
        newCoord = MapaFunction.gJsonToGoogleMaps(coordenadas).pop();
    } else {
        newCoord = {
            lat: item.latitud || item.clienteLatitud,
            lng: item.longitud || item.clienteLongitud,
        };
    }
    
    const onLoadMarker = React.useCallback(function callback(loaded) {
        setMarker(loaded);
    }, []);

    const onLoadInfoWindow = React.useCallback(function callback(loaded) {
        setInfoWindow(loaded);
    }, []);

    const onClickMarker = () => {
        setIsVisible(true);
    }

    return (
        <Marker 
            {...defaultProps}
            onLoad={onLoadMarker}
            position={newCoord}
            onClick={onClickMarker}
            icon={{
                url:'/img/location_contact.svg',
                scaledSize: {
                    width:50,
                    height:50,
                }
            }}
        >
            { isVisible && (
                <InfoWindow
                    className="card"
                    onLoad={onLoadInfoWindow}
                    position={newCoord}
                    onCloseClick={()=>{setIsVisible(false)}}
                >
                    <>
                        <div>{item.nombreCompleto}</div>
                        <div>{item.documento}</div>
                        <div>{item.direccion}</div>
                        <small>{item.createdPersona?.nombreCompleto}</small>
                    </>
                </InfoWindow>
            )}
        </Marker>
    );
}

export default React.memo(MarkerContacto);
