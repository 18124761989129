import React, { useEffect, useState } from 'react'
import useCurrentLocation from '@app/hooks/useCurrentLocation';
import AOS from 'aos';
import { connect } from 'react-redux'
import useGetContactos from '@app/hooks/useGetContactos';
import { Button, Carousel, ContactoCard, MapBase, MarkerContacto, SelectLugar } from '@app/components/index';
import { Spinner } from 'react-bootstrap';
import MapaFunction from '@app/functions/MapaFunction';

function ContactoMapaPage({
    location
}){
    AOS.init();
    const { location: currentLocation, error: currentError } = useCurrentLocation();
    const [mapLocation, setMapLocation] = useState(currentLocation);

    const { isLoading, response:{items}, setQuery} = useGetContactos({
        defaultQuery:{coordenadas: `${mapLocation.lat},${mapLocation.lng}`,}
    });

    const onActualizar = (newCoordinates) => {
        setQuery({
            coordenadas: `${newCoordinates.lat},${newCoordinates.lng}`,
        });
    }

    const onSelectLugar = ({item}) => {
        setMapLocation(MapaFunction.gJsonToGoogleMaps(item.coordenadas)[0])
    }

    const onClickCardMapaContacto = (item) => {
        setMapLocation(MapaFunction.gJsonToGoogleMaps(item.coordenadas)[0])
    }

    useEffect(() => {
        return () => {
        }
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row my-lg-3">
                    <div className="col-lg-8">
                        <h2 className="my-2 my-lg-0 ml-lg-2" data-aos="fade-up">
                            Contactos - Mapa
                            { isLoading && ( <Spinner className=" ml-2 mb-1 spinner-border text-primary" animation="border" />) }
                        </h2>
                    </div>

                    <div className="my-3 my-lg-0 col-lg-4" data-aos="fade-up">
                        <SelectLugar onChange={onSelectLugar} />
                    </div>
                </div>

                <div className="row" data-aos="fade-up">
                    <div className="offset-1 offset-lg-0 mt-lg-0 col-10 col-lg-12 bg-white border rounded py-2">
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '0px',
                                width: '100%',
                                zIndex: 10,
                            }}
                        >
                            <div className="col-12">
                                <Carousel>
                                    {items.map((item)=>{
                                        return (
                                            <div className="col-lg-5">
                                                <ContactoCard
                                                    item={item}
                                                    key={`card_${item.id}`}
                                                    buttons={()=>(
                                                        <Button theme="info" icon="fas fa-map-marker-alt" onClick={()=>onClickCardMapaContacto(item)} />
                                                    )}
                                                />
                                            </div>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </div>
                        <MapBase
                            id="mapaContactos"
                            mapContainerStyle={{
                                width: '100%',
                                height: '70vh'
                            }}
                            options={{
                                maxZoom: 19,
                                minZoom: 14,
                            }}
                            zoom={15}
                            coordinates={mapLocation}
                            onChange={onActualizar}
                            controlPanelOptions={{
                                bottomMin:100
                            }}
                        >
                            {
                                items.map(item=>{
                                    return (
                                        <MarkerContacto item={item} key={item.id} />
                                    )
                                })
                            }
                        </MapBase>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ContactoMapaPage)
