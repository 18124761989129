import React from 'react'
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';

const Styles = styled.div`
    
`;
function MensajeCard({
    item,
    isFullContent,
    buttons: ButtonComponent,
}) {
    return (
        <Styles className="card border border-primary redondeado shadow-sm p-3 m-3">
            <div className="row">
                <div className="col-10">
                    <i className="fas fa-envelope mr-2" />
                    <span>
                        {item.titulo}
                    </span>
                    <span className="float-right text-muted text-sm">
                        {FechaFunction.timeAgo(item.fecha)}
                    </span>
                </div>
                <div className="col-2">
                    {
                        ButtonComponent && (
                            <ButtonComponent />
                        )
                    }
                </div>
            </div>
            {
                isFullContent && (
                    <div className="row">
                        <div className="col-12">
                            {item.cuerpo}
                        </div>
                    </div>
                )
            }
            
        </Styles>
    )
}

MensajeCard.defaultProps = {
    item: {},
    isFullContent: false,
    buttons: null,
};

export default MensajeCard
