import React, {useState, useEffect, useRef, createRef, forwardRef, useCallback} from "react";
import {useWindowWidth} from '@react-hook/window-size';
import styled,{css} from 'styled-components';

const StyleCircle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    .box-circle{
        width: 2em;
        height: 2em;
        text-align: center;
        line-height: 2em;
        border-radius: 1em;
        background: ${props => props.background};
        display: inline-block;
        color: ${props => props.color};
        position: relative;
        
    }
    
    .box-circle::before{
        content: "";
        position: absolute;
        zindex: 1;
        left: 2.5em;
        top: 50%;
        transform: translateY(-50%);
        height: 3px;
        width: 100%;
        background: #000;
        
    }
    
    .title{
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        height: 2rem;
        text-align: center;
        font-weight: bold;
        font-size: ${props => props.selected?'normal':'x-small'};
    }
`

const StylesBox = styled.div`
    flex: 1;
    
    .box-step{
        
        
        cursor: ${props => props.validate?'pointer':'default'};
        color: ${props => props.color};
        display:block;
        background: ${props => props.background};
        text-decoration: none;
        position:relative;
        height: 80px;
        padding: 0 10px 0 5px;
        margin-right: 1.7rem;
        padding-left: 1.5rem;
        

        line-height: 20px;

        align-items: center;
        display: flex;

        .box-header{
            text-align: left;
            .title{
                font-weight: bold;
                
            }
    
            .subtitle{
                font-size: small;
            }
        }
        

        &:before,&:after{
            content: "";
            position:absolute;
            top: 0;
            border:0 solid ${props => props.background};
            border-width: 40px 20px;
            width: 0;
            height: 0;
            
        }
        &:before{
            left:-20px;
            border-left-color:transparent;
            
        }
        &:after{
            left:100%;
            border-color:transparent;
            border-left-color: ${props => props.background};
            
        }
        ${props => props.validate && css`
            &:hover{
                background-color: #4AA8EA;
                color: white;
                
                &:before{
                    border-color: #4AA8EA;
                    border-left-color:transparent;
                }
                &:after{
                    border-left-color:#4AA8EA;
                }
            }
        `}
        
        &:active{
            background-color: #4AA8EA;
            
            &:before{
                border-color: #4AA8EA;
                border-left-color:transparent;
            }
            &:after{
                border-left-color: #4AA8EA;
            }
        }
    }
`;

function StepTitle({
    title,
    index,
    subtitle,
    selected,
    validate,
    className,
    onClick,
}){
    const onlyWidth = useWindowWidth();

    let background = 'white';
    let color = 'black';
    if(selected){
        background = '#687385';
        color = 'white';
    } else if(validate){
        background = 'white';
        color = '#4582ec';
    } else {
        background = 'white';
        color = 'grey';
    }
    const onClickStep = () => {
        if(validate){
            onClick()
        }
    }
    return(
        <>
            {
                onlyWidth < 992 ? (
                    <StyleCircle className={className} color={color} background={background} validate={validate} onClick={onClickStep} selected={selected} aria-hidden="true">
                        <div className="box-circle">{index}</div>
                        <div className="title">{title}</div>
                    </StyleCircle>
                ) : (
                    <StylesBox className={className} color={color} background={background} validate={validate}>
            
                        <div className="box-step shadow-sm" onClick={onClickStep} aria-hidden="true">
                            <div className="box-header">
                                <div className="title">{title}</div>
                                <div className="subtitle">{subtitle}</div>
                            </div>
                        </div>
                    </StylesBox>
                )
            }
        </>
        
    );
}

StepTitle.defaultProps = {
    title:'',
    subtitle:'',
    selected: false,
    validate: false,
    onClick: () => {},
};

export default StepTitle;