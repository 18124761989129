import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import { useSelector } from 'react-redux';
import Button from '../button/Button';

function ModalConfirm({id, show, hideModal, title, message, onConfirm}) {
    const handleClose = () => hideModal();
    const onClickConfirm = () => {
        onConfirm();
        handleClose();
    };

    const isLoadingBlock = useSelector(state => state.modal.isLoadingBlock);
    const isPresent = useSelector(state => state.modal.isPresent);

    useEffect(() => {
        if(!isLoadingBlock && isPresent){
            hideModal();
        }
    }, [isLoadingBlock])

    return (
        <Modal show={show} backdrop="static" className="fade" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-gray" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button color="primary" onClick={onClickConfirm}>
                    CONFIRMAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalConfirm.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
};

ModalConfirm.defaultProps = {
    show: false,
    title: '',
    message: ''
};

export default ModalConfirm;
