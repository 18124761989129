import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link , Prompt} from 'react-router-dom';
import VehiculosService from '@services/vehiculos.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalVehiculoEditar from '@app/modals/vehiculo/ModalVehiculoEditar';
import {Button, ModalConfirm} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';

const VehiculoPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);

    const actualizar = async () => {
        const response = await VehiculosService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Vehiculo',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                VehiculosService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(VehiculosService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenEditar = (item) => {
        showModal(ModalVehiculoEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Patente',
                accessor: 'patente'
            },
            {
                Header: 'Marca',
                accessor: 'marca'
            },
            {
                Header: 'Modelo',
                accessor: 'modelo'
            },
            {
                Header: 'Estado',
                accessor: 'estado'
            },
            {
                Header: 'Cantidad',
                accessor: 'cantidad'
            },{
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
                <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-lg-8 my-2 my-lg-3">
                                <h3 className="ml-3" data-aos="fade-right">Vehiculos</h3>
                            </div>

                            {
                                permisos['api.vehiculos.exportar'] && (
                                <div className="col-3 col-md-2 col-lg-2 mt-2">
                                    <Button disabled={isLoadingExportar} icon={['fas','file-excel']} className="btn btn-block btn-success" onClick={() => onClickExportar()} data-aos="fade-up">
                                        EXCEL
                                    </Button>
                                </div>
                            )}

                            {
                                permisos['api.vehiculos.store'] && (
                                <div className="col-lg-2 mt-2">
                                    <Button color="primary" className="btn-block" onClick={() => onOpenEditar()} data-aos="fade-up">
                                        NUEVO
                                    </Button>
                                </div>
                            )}

                            <div className="col-12" data-aos="fade-right">
                                    <TablePaginated columns={columns} data={items} />
                            </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VehiculoPage);
