import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {
    Button,
    DateSingle,
    TimeSlider,
} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import { parseISO } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';

const schema = Yup.object({
    
});

const AuxDefaultTime = {
    start: '09:00:00',
    end: '10:00:00'
};

function ModalTareaFechaEditar({
    show,
    hideModal,
    item,
    defaultDate,
    minDate,
    maxDate,
    onGuardar,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [fecha, setFecha] = useState(item.fecha?item.fecha:FechaFunction.format(defaultDate, 'Y-MM-dd'));
    const [time, setTime] = useState((item.horaIni && item.horaFin)?{start:item.horaIni, end:item.horaFin}:AuxDefaultTime)
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            
        }
    });
    const onSubmit = (data) => {
        const form = {
            ...item,
            ...data,
            fecha: fecha,
            horaIni: time.start,
            horaFin: time.end
        };
        onGuardar(form);
        hideModal();
    };

    useEffect(() => {
        
    }, []);

    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.fecha? 'Editar Fecha' : 'Asignar Fecha'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label
                                className="label-control"
                                htmlFor="fecha"
                            >
                                Fecha
                            </label>
                            <DateSingle
                                id="fecha"
                                className="form-control"
                                value={fecha}
                                onChange={setFecha}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 mt-1 mb-2">
                        <TimeSlider
                            value={time}
                            onChange={setTime}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalTareaFechaEditar.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
    }),
    onGuardar: PropTypes.func,
};

ModalTareaFechaEditar.defaultProps = {
    show: false,
    item: {
        id: 0,
    },
    onGuardar: () => {},
};

export default ModalTareaFechaEditar;