import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Carousel, InputDropZone, FormTipoMaterial, FormTipoMaterialSchema} from '@app/components/index';
import FotoService from '@app/services/foto.service';
import ZohoNotaImagenService from '@app/services/zohoNotaImagen.service';
import styled from 'styled-components';
import ContactosAction from '@app/store/actions/contactos';
import Compressor from 'compressorjs';

const WrapImage = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
`;

const {defaultImage} = InputDropZone;

const ContactosF2Page = ({
    contacto,
    nextStep,
    previousStep,
    iniciarContrato,
    user
}) => {
    const {handleSubmit,register, setValue, errors} = useForm({
        resolver: yupResolver(FormTipoMaterialSchema),
        defaultProps: contacto,
    });

    const {puesto}=user;
    const [isLoading, setIsLoading] = useState(false);
    const [defaultImageDF, setDefaultImageDF] = useState(null);
    const [defaultImageDD, setDefaultImageDD] = useState(null)
    const [defaultImageF1, setDefaultImageF1] = useState(null);
    const [defaultImageF2, setDefaultImageF2] = useState(null)
    const [imgPreviewDF, setImgPreviewDF] = useState(null);
    const [imgPreviewDD, setImgPreviewDD] = useState(null);
    const [imgPreviewF1, setImgPreviewF1] = useState(null);
    const [imgPreviewF2, setImgPreviewF2] = useState(null);
    const [validarPreviewDF, setValidarPreviewDF] = useState(false);
    const [validarPreviewDD, setValidarPreviewDD] = useState(false);
    const [validarPreviewF1, setValidarPreviewF1] = useState(false);
    const [validarPreviewF2, setValidarPreviewF2] = useState(false);
    const [fotos, setFotos] = useState([]);
    const extensionPermitida = ['png','jpg','jpeg','svg','x-png'];

    const actualizar = () => {
        FotoService.getAll({
            contactoId:contacto.id
        })
        .then((response)=>{
            setFotos(response);
            response.forEach((foto)=>{
                switch (foto.tipoFotoId) {
                    case 1:  /* foto DNI FRENTE */
                        setDefaultImageDF(foto);
                        break;
                    case 2: /* foto DNI DORSO */
                        setDefaultImageDD(foto);
                        break;
                    default:
                        break;
                }
            });
        });

    }

    const onSubmit = (data) => {
        const forms = [];
        setIsLoading(true);
        const contrato = {
            ...data,
            contactoId: contacto.id,
            contacto: contacto,
        };



        if(imgPreviewDF){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 1,
                foto: imgPreviewDF,
            }).then((response)=>{
                ZohoNotaImagenService.register({
                    id:contacto.zohoCRMContactId,
                    nombre: 'imagen DNI FRENTE cargado.',
                    url: response.foto_url,
                })
                setDefaultImageDF(response);
                setImgPreviewDF(null);
                contrato.fotoDocumentoFrenteId = response.id;
                contrato.fotoDocumentoFrente = response;
            }))
        } else if(defaultImageDF){
            contrato.fotoDocumentoFrenteId = defaultImageDF.id;
            contrato.fotoDocumentoFrente = defaultImageDF;
        } else {
            contrato.fotoDocumentoFrenteId = null;
            contrato.fotoDocumentoFrente = null;
        }


        if(imgPreviewDD){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 2,
                foto: imgPreviewDD,
            }).then((response)=>{
                ZohoNotaImagenService.register({
                    id:contacto.zohoCRMContactId,
                    nombre: 'imagen DNI DORSO cargado.',
                    url: response.foto_url,
                })
                setDefaultImageDD(response);
                setImgPreviewDD(null);
                contrato.fotoDocumentoDorsoId = response.id;
                contrato.fotoDocumentoDorso = response;
            }))
        } else if(defaultImageDD){
            contrato.fotoDocumentoDorsoId = defaultImageDD.id;
            contrato.fotoDocumentoDorso = defaultImageDD;
        } else {
            contrato.fotoDocumentoDorsoId = null;
            contrato.fotoDocumentoDorso = null;
        }

        if(imgPreviewF1){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 3,
                foto: imgPreviewF1,
            }).then((response)=>{
                setDefaultImageF1(response);
                setImgPreviewF1(null);
                contrato.fotoLugar1Id = response.id;
                contrato.fotoLugar1 = response;
            }))
        } else if(defaultImageF1){
            contrato.fotoLugar1Id = defaultImageF1.id;
            contrato.fotoLugar1 = defaultImageF1;
        } else {
            contrato.fotoLugar1Id = null;
            contrato.fotoLugar1 = null;
        }

        if(imgPreviewF2){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 4,
                foto: imgPreviewF2,
            }).then((response)=>{
                setDefaultImageF2(response);
                setImgPreviewF2(null);
                contrato.fotoLugar2Id = response.id;
                contrato.fotoLugar2 = response;
            }))
        } else if(defaultImageF2){
            contrato.fotoLugar2Id = defaultImageF2.id;
            contrato.fotoLugar2 = defaultImageF2;
        } else {
            contrato.fotoLugar2Id = null;
            contrato.fotoLugar2 = null;
        }

        Promise.all(forms).then(()=>{
            iniciarContrato(contrato);
            actualizar();
            nextStep();
        })
        .finally(()=>{
            setIsLoading(false);
        });
    };

    const onClickF1 = (foto) =>{
        setDefaultImageF1(foto)
    }
    const onClickF2 = (foto) =>{
        setDefaultImageF2(foto)
    }

    useEffect(() => {
        setDefaultImageDF(null);
        setDefaultImageDD(null);
        setDefaultImageF1(null);
        setDefaultImageF2(null);
        if(contacto){
            actualizar();
        }
    }, [contacto])

    useEffect(() => {
        if(imgPreviewDF?.length > 0){
            const {name,size} = imgPreviewDF[0];
            if(size > 20000000){
                setImgPreviewDF(null)
                setValidarPreviewDF(true)
            }else{
                setValidarPreviewDF(false)
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    console.log("Extension Permitida")
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewDF[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewDF(result);
                        }
                        
                    })
                }else{
                    console.log("Extension no permitida")
                }
            }
        }
        
    }, [imgPreviewDF])
    useEffect(() => {
        if(imgPreviewDD?.length > 0){
            const {name,size} = imgPreviewDD[0];
            if(size > 20000000){
                setImgPreviewDD(null)
                setValidarPreviewDD(true)
            }else{
                setValidarPreviewDD(false)
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewDD[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewDD(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewDD])
    useEffect(() => {
        if(imgPreviewF1?.length > 0){
            const {name,size} = imgPreviewF1[0];
            if(size > 20000000){
                setImgPreviewF1(null)
                setValidarPreviewF1(true)
            }else{
                setValidarPreviewF1(false)
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF1[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF1(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF1])
    useEffect(() => {
        if(imgPreviewF2?.length > 0){
            const {name,size} = imgPreviewF2[0];
            if(size > 20000000){
                setImgPreviewF2(null)
                setValidarPreviewF2(true)
            }else{
                setValidarPreviewF2(false)
                const nombreCortado = name.split('.');
                let extension = nombreCortado[ nombreCortado.length - 1 ];
                extension = extension.toLowerCase();
                if(extensionPermitida.includes( extension ) ){
                    setIsLoading(true);
                    const img = new Compressor(imgPreviewF2[0], {
                        quality: 0.3,
                        convertSize:2000000,
                        success(result) {
                            setIsLoading(false);
                            setImgPreviewF2(result);
                        }
                    })
                }
            }
        }
    }, [imgPreviewF2])

    return (
            <div className="container-fluid shadow-sm ">
                <div className="row bg-white rounded-lg border p-lg-3 p-1 p-md-3 pt-md-5">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-4 text-gray my-3">
                                <h5>
                                    <i className="fas fa-angle-down mr-2"></i>
                                    Documento de Identidad
                                </h5>
                                <span>Frente y Dorso</span>
                            </div>
                            <div className="col-12 col-md-8 my-lg-2">
                                <div className="row">
                                    <div className={validarPreviewDF ? ("col-12 col-md-5 offset-md-1 col-lg-5 bg-danger rounded") : ("col-12 col-md-5 offset-md-1 col-lg-5")}>
                                        <label className="control-label" htmlFor="dniF">
                                            DNI Frente
                                        </label>
                                        <InputDropZone
                                            id="dniF"
                                            defaultImage={defaultImageDF?.foto_url}
                                            backgroundImage="/img/Frente.png"
                                            onChange={setImgPreviewDF}
                                            // validar={this.validar}
                                        />
                                        {validarPreviewDD && (
                                            <p className="text-center mt-1">La imagen pesa más de 20MB</p>
                                        )}
                                    </div>
                                    <div className={validarPreviewDD ? ("col-12 my-lg-0 offset-md-1 col-md-5 col-lg-5 bg-danger rounded") : ("col-12 my-lg-0 offset-md-1 col-md-5 col-lg-5")}>
                                        <label className="control-label mt-3 mt-md-0 mt-lg-0" htmlFor="dniD">
                                            DNI Dorso
                                        </label>
                                        <InputDropZone
                                            id="dniD"
                                            defaultImage={defaultImageDD?.foto_url}
                                            backgroundImage="/img/Dorso.png"
                                            onChange={setImgPreviewDD}
                                        />
                                        {validarPreviewDD && (
                                            <p className="text-center mt-1">La imagen pesa más de 20MB</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 border my-3">
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <FormTipoMaterial register={register} setValue={setValue} errors={errors} />
                            <div className="col-12 col-md-8 my-2">
                                <div className="row">
                                    <div className={validarPreviewF1 ? ("col-12 offset-md-1 col-md-5 bg-danger rounded") : ("col-12 offset-md-1 col-md-5")}>
                                        <label className="control-label" htmlFor="foto1L">
                                            Foto 1
                                        </label>
                                        <InputDropZone
                                            id="foto1L"
                                            defaultImage={defaultImageF1?.foto_url}
                                            backgroundImage="/img/Imagen_1.png"
                                            onChange={setImgPreviewF1}
                                        />
                                        {validarPreviewF1 && (
                                        <p className="text-center mt-1">La imagen pesa más de 20MB</p>
                                        )}
                                        {
                                            fotos.filter(fo=>fo.tipoFotoId===3).length>0 && (
                                                <Carousel size="small">
                                                    {
                                                        fotos.filter(fo=>fo.tipoFotoId===3).map((fo)=>{
                                                            return (
                                                                <WrapImage key={fo.id} onClick={()=>onClickF1(fo)} aria-hidden="false">
                                                                    <img src={fo.foto_url} alt="" className="w-50 img-thumbnail" />
                                                                </WrapImage>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            )
                                        }
                                    </div>
                                    <div className={validarPreviewF2 ? ("col-12 my-lg-0 offset-md-1 col-md-5 bg-danger rounded") : ("col-12 my-lg-0 offset-md-1 col-md-5")}>
                                        <label className="label-control mt-4 mt-md-0 mt-lg-0" htmlFor="foto2L">
                                            Foto 2
                                        </label>
                                        <InputDropZone
                                            id="foto2L"
                                            defaultImage={defaultImageF2?.foto_url}
                                            backgroundImage="/img/Imagen_2.png"
                                            onChange={setImgPreviewF2}
                                        />
                                        {validarPreviewF2 && (
                                            <p className="text-center mt-1">La imagen pesa más de 20MB</p>
                                        )}
                                        {
                                            fotos.filter(fo=>fo.tipoFotoId===4).length>0 && (
                                                <Carousel size="small">
                                                    {
                                                        fotos.filter(fo=>fo.tipoFotoId===4).map((fo)=>{
                                                            return (
                                                                <WrapImage key={fo.id} onClick={()=>onClickF2(fo)} aria-hidden="false">
                                                                    <img src={fo.foto_url} alt="" className="w-50 img-thumbnail" />
                                                                </WrapImage>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4 my-md-3 my-lg-3 text-right">
                        <div className="row">
                            <div className="col-12 offset-md-6 col-md-6 py-md-4 pt-md-5  pb-lg-2 pt-lg-3 offset-lg-8 col-lg-4">
                                <Button className="btn-secondary" onClick={previousStep}>
                                    Anterior
                                </Button>

                                {puesto ==="Asesor" && defaultImageDF !==null && defaultImageDD !==null  || puesto ==="Asesor" && imgPreviewDD !== null && imgPreviewDF !==null && validarPreviewDF === false && validarPreviewDD === false && validarPreviewF1 === false && validarPreviewF2 === false? (
                                    <Button
                                        isLoading={isLoading}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Guardar y Continuar
                                    </Button>

                                ):""}

                                {
                                    puesto !== "Asesor" && (
                                        <Button
                                            isLoading={isLoading}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                               Guardar y Continuar
                                        </Button>
                                    )
                                }
                                <Link to={puesto === "Asesor"?("/Home"):("/contactos")}>
                                    <Button theme="default">
                                        Cancelar
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactosF2Page);