import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm, Controller} from 'react-hook-form';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col,Spinner} from 'react-bootstrap';
import TareasService from '@services/tareas.service';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Link, useHistory, useParams} from 'react-router-dom';
import usePermisos from '@app/hooks/usePermisos';
import {
    Button,
    DateSingle,
    SelectLugar,
    SelectTipoTarea,
    SelectMotivoTarea,
    SelectTipoCanal,
    TimeSlider,
    InputZohoSearch,
    InputEditor,
    MapClick,
    InputCheck,
} from '@app/components/index';

import * as CalendarAction from '@app/store/actions/calendar';
import useCurrentLocation from '@app/hooks/useCurrentLocation';
import MapaFunction from '@app/functions/MapaFunction';
import FechaFunction from '@app/functions/FechaFunction';
import SelectTratosxContacto from '@app/components/select/SelectTratosxContacto';
import SelectMotivoTicket from '@app/components/select/SelectMotivoTicket';
import { number } from 'prop-types';


const schema = Yup.object({
    // id:Yup.mixed(),
    // lugarId: Yup.mixed().required(),
    // tipoTareaId: Yup.mixed().required(),
    // motivoTareaId: Yup.mixed().when('tipoTareaId', {
    //     is: (id) => id > 0,
    //     then: Yup.mixed().required()
    // }),
    // clienteDireccion: Yup.string().max(200),
    // clienteTelefono: Yup.string()
    //     .max(200),
    // clienteCelular: Yup.string()
    //     .max(200),
    // clienteId: Yup.string()
    //     .matches(/^(\s*|\d+)$/, 'Debe ser solo numeros')
    //     .max(200),
    // clienteCategoria: Yup.string().max(200),
    // categoria: Yup.string().when('id',{
    //     is: (value) => value > 0,
    //     then: Yup.string(),
    //     otherwise: Yup.string().required(),
    // }),
    // tipoCanalId: Yup.string().required()
});


    function ModalCrearTareaRecibidaCrm({show, hideModal, onGuardar,itemReprogramado,location,cuadrillas,eliminarReprogramacion, nuevaTarea,fechaCalendar,fechaCalendario,cancelarSeleccionFecha}) {
    const {calendar}=useSelector(state=>state)
    const handleClose = () => hideModal();
    const {
        item,
        action,
    } = (location && location.state) || { item:{}};
    const [fecha, setFecha] = useState(FechaFunction.format(fechaCalendario,'yyyy-MM-dd'));
    const permisos = usePermisos();
    const [cuadrillaDisponible, setcuadrillaDisponible] = useState(0)
    const [tarea, setTarea] = useState(item || {});
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [zonaId, setZonaId] = useState(0);
    const [coordenadas, setCoordenadas] = useState({lat: nuevaTarea.clienteLatitud, lng: nuevaTarea.clienteLongitud});
    const [guardarCoordenadas, setGuardarCoordenadas] = useState(`${nuevaTarea.clienteLatitud},${nuevaTarea.clienteLongitud}`);
    const [time, setTime] = useState();
    const [validacionHora, setValidacionHora] = useState((FechaFunction.format(new Date(fechaCalendar), 'H')));
    const [hora, setHora] = useState((FechaFunction.format(new Date(fechaCalendar), 'HH:mm:ss')));
    const [turno, setTurno] = useState('TM')
    const [verObservaciones, setVerObservaciones] = useState(false)

    const [lugar, setLugar] = useState(nuevaTarea?.lugarId)
    const [nombre, setNombre] = useState(nuevaTarea?.clienteNombre)
    const [dni, setDni] = useState(nuevaTarea?.clienteDocumento)
    const [clienteId, setClienteId] = useState(nuevaTarea?.clienteId)
    const [direccion, setDireccion] = useState(nuevaTarea?.clienteDireccion)
    const [celular, setCelular] = useState(nuevaTarea?.clienteCelular)
    const [telefono, setTelefono] = useState(nuevaTarea?.clienteTelefono)
    const [categoria, setCategoria] = useState(nuevaTarea?.categoria)
    const [canal, setCanal] = useState(nuevaTarea?.tipoCanalId)
    const [observaciones, setObservaciones] = useState(nuevaTarea?.motivoTareaObservaciones)
    const [tipoTarea, setTipoTarea] = useState(nuevaTarea?.tipoTareaId)
    const [contrato, setContrato] = useState(nuevaTarea?.contratoId)
    const [cuenta, setCuenta] = useState(nuevaTarea?.cuentaId)
    const [motivoTareaId, setMotivoTareaId] = useState(nuevaTarea?.motivoTareaId)
    /* const [enviarCorreo, setEnviarCorreo] = useState(true) */
    const [btnGuardar, setBtnGuardar] = useState(false)


    // const [mapa, setMapa] = useState({lat:-25.1211557, lng:-64.1267489})

    const {register, handleSubmit, errors, setValue, watch, control} = useForm({
        resolver: yupResolver(schema)
    });

    const lugarId = watch('lugarId',item?.lugarId || 0);
    const cuadrillaLugarId = watch('cuadrillaLugarId', 0);
    const tipoTareaId = watch('tipoTareaId', item?.tipoTareaId || itemReprogramado?.tipoTareaId);

    const copiarCoordenadas = () =>{
        const aux = document.createElement("input");
        aux.setAttribute("value",guardarCoordenadas);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }


    const onSubmit = (data) => {
        setBtnGuardar(true)
        const form = {
            ...nuevaTarea,
            ...data,
            tipoTareaId:tipoTarea,
            estadoTareaId:nuevaTarea.estadoTareaId,
            motivoTareaId:nuevaTarea.motivoTareaId,
            motivoTareaObservaciones:nuevaTarea.motivoTareaObservaciones,
            motivoReprogramadoId:nuevaTarea.motivoReprogramadoId,
            motivoReprogramadoObservaciones:nuevaTarea.motivoReprogramadoObservaciones,
            contactoId:nuevaTarea?.contactoId,
            clienteId:nuevaTarea?.clienteId,
            clienteNombre:nombre,
            clienteDocumento:dni,
            clienteCelular:celular,
            clienteTelefono:telefono,
            clienteDireccion:direccion,
            clienteLatitud: coordenadas.lat,
            clienteLongitud: coordenadas.lng,
            clienteCategoria:nuevaTarea.clienteCategoria,
            descripcion:nuevaTarea.descripcion,
            categoria:categoria,
            fecha:fecha,
            horaIni: time.start,
            horaFin: time.end,
            cuadrillaLugarId:cuadrillaDisponible,
            lugarId:lugar,
            flotaId:nuevaTarea.flotaId,
            flotaRespaldoId:nuevaTarea.flotaRespaldoId,
            tipoCanalId:canal,
            crm:true,
            turno:turno,
            tareaId:nuevaTarea.id,
            cuentaId: nuevaTarea?.cuentaId,
            enviarCorreo: true
        };
        TareasService.register(form)
        .then(() => {
            onGuardar();
            cancelarSeleccionFecha();
            hideModal();
        })
        .finally(() => {
            setIsLoading(false);
            setBtnGuardar(false)
        });
    };

    useEffect(() => {
        const filtrarZona = (cuadrillas.filter(cuadrilla => cuadrilla.id === Number(cuadrillaDisponible)))

        if(filtrarZona.length !== 0){
            if(filtrarZona[0].zonaIdTM !== null && filtrarZona[0].zonaIdTT === null){
                setZonaId(filtrarZona[0].zonaIdTM)
            }else if(filtrarZona[0].zonaIdTT  !== null && filtrarZona[0].zonaIdTM === null ){
                    setZonaId(filtrarZona[0].zonaIdTT)
            }else if(filtrarZona[0].zonaIdTT === filtrarZona[0].zonaIdTM){
                setZonaId(filtrarZona[0].zonaIdTT)
            }else if(filtrarZona[0].zonaIdTT !== null && filtrarZona[0].zonaIdTM !==null && filtrarZona[0].zonaIdTM !== filtrarZona[0].zonaIdTT && validacionHora >=8 && validacionHora < 13){
                setZonaId(filtrarZona[0].zonaIdTM)
            }else if(filtrarZona[0].zonaIdTT !== null && filtrarZona[0].zonaIdTM !==null && filtrarZona[0].zonaIdTM !== filtrarZona[0].zonaIdTT && validacionHora >= 13){
                setZonaId(filtrarZona[0].zonaIdTT)
            }else{
                setZonaId(undefined)
            }
        }

}, [cuadrillaDisponible, zonaId])

useEffect(() => {
    if(Number(validacionHora) === 12){
        setTime({
            start: '11:00:00',
            end: '12:00:00',
        });
    }else if(Number(validacionHora) === 17){
        setTime({
            start: '16:00:00',
            end: '17:00:00',
        });
        setTurno('TT');
    }else{
        setTime({
            start: hora,
            end: `${String((Number(validacionHora)+1))}:00:00`,
        });
    }

    if(validacionHora >= 8 && validacionHora < 13 ){
        setTurno('TM');
    }else{
        setTurno('TT');
    }
}, [])

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <div className="container">
                    <div className="row">
                        <div className="col-8">
                            <h3>
                                Seleccionar Fecha
                                { isLoading && <Spinner className="ml-2 mb-2 spinner-border text-primary" animation="border" /> }
                            </h3>
                        </div>
                        <div className="col-4">
                            <Button
                                className="btn p-2 btn-warning text-dark float-right"
                                type="button"
                                onClick={copiarCoordenadas}
                            >
                                COPIAR COORDENADAS
                                <i className="fas fa-map-marker-alt ml-1" />
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="fecha"
                                    >
                                        Fecha
                                    </label>
                                    <DateSingle
                                        id="fecha"
                                        value={fechaCalendario}
                                        ref={register}
                                        name="fecha"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="cuadrillasDisponibles"
                                    >
                                        Cuadrilla Disponible
                                    </label>
                                    <select
                                        id="cuadrillasDisponibles"
                                        onChange={
                                            (e)=>{setcuadrillaDisponible(e.target.value);
                                                setValue('cuadrillaLugarId', e.target.value);
                                            }}
                                        name="cuadrillasDisponibles"
                                        className={`form-control ${
                                            errors.cuadrillasDisponibles ? 'is-invalid' : ''
                                        }`}
                                    >
                                        <option value={null}>Seleccione una cuadrilla</option>
                                        {
                                            validacionHora >= 8 && validacionHora < 13 ? (
                                            cuadrillas.map((cuadrilla)=>
                                                (
                                                    <option
                                                        value={cuadrilla.id}
                                                        disabled={cuadrilla.periodoNombre === "Turno tarde"}
                                                    >
                                                        {cuadrilla.descripcion.split(" - T",1)}
                                                        - TAREAS DISPONIBLES: 
                                                        {cuadrilla.tareasDisponiblesCantidadTM + cuadrilla.tareasDisponiblesCantidadTT}
                                                    </option>
                                                )
                                            )
                                            ):(
                                                cuadrillas.map((cuadrilla)=>
                                                (
                                                    <option
                                                        value={cuadrilla.id}
                                                        disabled={cuadrilla.periodoNombre === "Turno mañana"}
                                                    >
                                                        {cuadrilla.descripcion.split(" - T",1)} 
                                                        - TAREAS DISPONIBLES: 
                                                        {cuadrilla.tareasDisponiblesCantidadTM + cuadrilla.tareasDisponiblesCantidadTT}
                                                    </option>
                                                )
                                            )
                                            )
                                        }
                                    </select>
                                    {errors.categoria ? (
                                        <div className="invalid-feedback">
                                            {errors.categoria?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoTareaId"
                                    >
                                        Tipo de Tarea
                                    </label>
                                    <SelectTipoTarea
                                        id="tipoTareaId"
                                        value={tipoTarea}
                                        isInvalid={Boolean(errors.tipoTareaId)}
                                        disabled
                                    />
                                    {errors.tipoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="lugarId"
                                    >
                                        Lugar
                                    </label>
                                    <SelectLugar
                                        id="lugarId"
                                        value={lugar}
                                        disabled
                                        isInvalid={Boolean(errors.lugarId)}
                                        onChange={(opt) => {
                                            setValue('lugarId', opt.value || undefined);
                                        }}
                                    />
                                    {errors.lugarId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.lugarId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaId"
                                    >
                                        Motivo de la Tarea
                                    </label>
                                    <SelectMotivoTarea
                                        id="motivoTareaId"
                                        value={motivoTareaId}
                                        tipoTareaId={tipoTarea}
                                        isInvalid={Boolean(
                                            errors.motivoTareaId
                                        )}
                                        onChange={(opt) => {
                                            // console.log(opt)
                                            // setDataTicket(opt)
                                        }}
                                    />
                                    {errors.motivoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.motivoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaObservaciones"
                                    >
                                        <button
                                            className="btn btn-secondary"
                                            type="button"
                                            onClick={() => setVerObservaciones(!verObservaciones)}
                                        >
                                            Observaciones
                                            <i className={verObservaciones === false ? ("fas fa-chevron-down ml-3") : ("fas fa-chevron-up ml-3")} />
                                        </button>
                                    </label>
                                    {verObservaciones !== false && (
                                        <InputEditor
                                            id="motivoTareaObservaciones"
                                            template={observaciones}
                                            disabled={!(tipoTareaId > 0)}
                                            onChange={(html) => {
                                                setValue(
                                                    'motivoTareaObservaciones',
                                                    html
                                                    );
                                                }}
                                        />
                                    )}
                                    {errors.motivoTareaObservaciones ? (
                                        <div className="default-invalid-feedback">
                                            {
                                                errors.motivoTareaObservaciones
                                                ?.message
                                            }
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-12 mt-1 mb-2">
                                <TimeSlider
                                    defaultPeriodo={time}
                                    value={time}
                                    onChange={setTime}
                                    id="timerInput"
                                    turno={turno}
                                />
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className='form-group'>
                                    <label
                                        className="label-control"
                                        htmlFor="clienteNombre"
                                    >
                                        Nombre del cliente
                                    </label>
                                    <input
                                        className='form-control'
                                        name="clienteNombre"
                                        id="clienteNombre"
                                        value={nombre}
                                        disabled
                                    />
                                    {errors.clienteNombre&& (
                                        <div className="default-invalid-feedback">
                                            {errors.clienteNombre?.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className='form-group'>
                                    <label
                                        className="label-control"
                                        htmlFor="clienteDocumento"
                                    >
                                        Dni del cliente
                                    </label>
                                    <input
                                        className="form-control"
                                        name="clienteDocumento"
                                        id="clienteDocumento"
                                        value={dni}
                                        disabled
                                    />
                                    {errors.clienteDocumento ? (
                                        <div className="default-invalid-feedback">
                                            {errors.clienteDocumento?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteId"
                                    >
                                        Cliente ID
                                    </label>
                                    <input
                                        id="clienteId"
                                        className={`form-control ${
                                            errors.clienteId ? 'is-invalid' : ''
                                        }`}
                                        type="text"
                                        name="clienteId"
                                        value={clienteId || cuenta}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteId"
                                    >
                                        Contrato
                                    </label>
                                    <input
                                        id="contrato"
                                        className="form-control"
                                        type="number"
                                        name="contrato"
                                        value={contrato}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteDireccion"
                                    >
                                        Dirección
                                    </label>
                                    <input
                                        id="clienteDireccion"
                                        className={`form-control ${
                                            errors.clienteDireccion
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteDireccion"
                                        value={direccion}
                                        disabled
                                    />
                                    {errors.clienteDireccion ? (
                                        <div className="invalid-feedback">
                                            {errors.clienteDireccion?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteTelefono"
                                    >
                                        Telefono
                                    </label>
                                    <input
                                        id="clienteTelefono"
                                        className={`form-control ${
                                            errors.clienteTelefono
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteTelefono"
                                        value={telefono}
                                        disabled
                                    />
                                    {errors.clienteTelefono ? (
                                        <div className="invalid-feedback">
                                            {errors.clienteTelefono?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteCelular"
                                    >
                                        Celular
                                    </label>
                                    <input
                                        id="clienteCelular"
                                        className={`form-control ${
                                            errors.clienteCelular
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteCelular"
                                        value={celular}
                                        disabled
                                    />
                                    {errors.clienteCelular ? (
                                        <div className="invalid-feedback">
                                            {errors.clienteCelular?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="categoria"
                                    >
                                        Categoria
                                    </label>
                                    <select
                                        id="categoria"
                                        name="categoria"
                                        className={`form-control ${
                                            errors.categoria ? 'is-invalid' : ''
                                        }`}
                                        disabled
                                        value={categoria}
                                    >
                                        <option value="">
                                            Seleccione una opcion
                                        </option>
                                        <option value="NORMAL">NORMAL</option>
                                        <option value="VIP 1">VIP 1</option>
                                        <option value="VIP 2">VIP 2</option>
                                        <option value="VIP 3">VIP 3</option>
                                    </select>
                                    {errors.categoria ? (
                                        <div className="invalid-feedback">
                                            {errors.categoria?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoCanalId"
                                    >
                                        Canal
                                    </label>
                                    <SelectTipoCanal
                                        id="canal"
                                        value={canal}
                                        onChange={(opt) => {
                                            setValue('tipoCanalId', opt.value);
                                        }}
                                        disabled
                                    />
                                    {errors.tipoCanalId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoCanalId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 border bg-white shadow-sm ml-1 mt-2 mt-md-0 mt-lg-0 py-2 rounded">
                                <MapClick
                                    zoom={15}
                                    coordinates={coordenadas}
                                    onChange={setCoordenadas}
                                    options={{
                                        maxZoom: 19,
                                        minZoom: 14,
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12">
                    <div className="row">
                        <div className="col-6 text-center">
                            {`Coordenadas :   ${coordenadas.lat}, ${coordenadas.lng}`}
                        </div>
                        <div className="col-3 text-center">
                            {/* <InputCheck label="Enviar Correo" checked={enviarCorreo} onChange={()=>setEnviarCorreo(!enviarCorreo)} /> */}
                        </div>
                        <div className="col-3 text-right">
                                <Button theme="default" onClick={handleClose}>
                                    CANCELAR
                                </Button>
                            {
                                (permisos['api.tareas.store'] ||
                                permisos['api.tareas.update']) && (
                                <Button
                                    theme="primary"
                                    onClick={
                                        handleSubmit(onSubmit)
                                    }
                                    isLoading={isLoading}
                                    disabled={btnGuardar}
                                >
                                    GUARDAR
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

ModalCrearTareaRecibidaCrm.defaultProps = {
    show: false,
    item: {
        id: 0,
        latitud: -24.7821,
        longitud: -65.4232,
        motivoTareaObservaciones: '',
        lugarId: 0,
        tipoTareaId: 0,
        tipoCanalId: 0,
        motivoTarea: {
            id: 0,
        }
    },
    cuadrillas: []
};

const mapStateToProps = (state) => ({
    itemReprogramado: state.calendar.itemReprogramado,
});

const mapDispatchToProps = (dispatch) => ({
    agregarReprogramacion: (data) =>  dispatch(CalendarAction.agregarReprogramacion(data)),
    recuperar: (data) => dispatch(CalendarAction.recuperarEventos(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalCrearTareaRecibidaCrm);