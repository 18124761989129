import React, {useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback} from "react";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import {showModal} from 'react-redux-modal-provider';
import ModalCuadrillaDisponibleLugarVer from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarVer';

import AuxiliarFunction from "@app/functions/AuxiliarFunction";
import { Button, ModalSelect } from "@app/components/index";
import ModalFlotaJugadorVer from "@app/modals/flota-jugador/ModalFlotaJugadorVer";

function existeJugadorEnFlota(dni,flotas){
    const existe = flotas.find(({jugadores})=>{
        return jugadores.find(jg=>jg.dni === dni);
    });
    return Boolean(existe);
}

const WidgetCuadrilla = (({
    item,
    grid,
    widget,
    flotas,
    cuadrillas,

    onChange,
    onChangeWidget,
    onRemove,

    ...defaultProps
})=>{
    const sortableRef = useRef();
    const [innerList, setInnerList] = useState(item.flota?[item.flota]:[]);

    const onCheckIntegrity = (to,from) => {
        const jugadorId = AuxiliarFunction.toIntegerNumber(from.el.id);
        if(jugadorId>0){
            const existe = existeJugadorEnFlota(jugadorId,flotas);
            if(existe){
                return false;
            }
        } else if(to.el.id === from.el.id){
            return true;
        }
        return to.el.children.length < 1;
    }

    const onMouseDown = () => {
        if(grid && widget){
            grid.movable(widget,false);
        }
    }

    const onMouseLeave = () => {
        if(grid && widget){
            grid.movable(widget,true);
        }
    }

    const onClickDescartar = () => {
        grid.movable(widget,true);
        onChange({
            ...item,
            flota: null,
        })
        setInnerList([]);
    }

    const onClickVer = () => {
        showModal(ModalCuadrillaDisponibleLugarVer,{item:item.cuadrillaDisponibleLugar})
    }

    const onClickVerFlota = () => {
        showModal(ModalFlotaJugadorVer,{item:innerList[0]})
    }

    useEffect(() => {
        const height = innerList.length + 2;
        if(grid && widget){
            const newOptions = {h:height,minH:height};
            onChangeWidget(newOptions);
            grid.update(widget,newOptions);
        }
    }, [widget,innerList]);

    useEffect(() => {
        if(sortableRef && sortableRef.current && sortableRef.current.sortable){
            sortableRef.current.sortable.option('group',{
                name:'shared',
                put:onCheckIntegrity
            });
        }
    }, [item,flotas]);

    return (
        <>
            <button
                type="button"
                className="grid-stack-item-nombre"
                onClick={onClickVer}
            >
                {item.cuadrillaDisponibleLugar?.descripcionCorto}
            </button>
            <ReactSortable
                ref={sortableRef}
                id={`jugadores-${item.id}`}
                tag='div'
                className='list-group'
                group={{
                    name:'shared',
                    put:onCheckIntegrity
                }}
                emptyInsertThreshold={40}
                animation={150}
                ghostClass="grid-stack-item-content"
                list={innerList}
                setList={setInnerList}
                onAdd={(ev)=>{
                    const newId = AuxiliarFunction.toIntegerNumber(ev.item.id);
                    onChange({
                        ...item,
                        flota: flotas.find(fl=>fl.id === newId),
                    })
                }}
                onRemove={()=>{
                    onChange({
                        ...item,
                        flota: null,
                    })
                }}
            >
                {innerList.map((flota,index) => {
                    const { id, vehiculoPatente } = flota;
                    return(
                        <div
                            className="list-group-item"
                            id={id}
                            key={id}
                            onMouseDown={onMouseDown}
                            onMouseLeave={onMouseLeave}
                            role="presentation"
                        >
                            <div className="list-group-item-title">{AuxiliarFunction.formatMatricula(vehiculoPatente)}</div>
                            <div className="list-group-item-subtitle">
                                <Button
                                    className="float-right"
                                    size="xs"
                                    theme="danger"
                                    icon="fa fa-trash fa-xs"
                                    onClick={()=>onClickDescartar()}
                                />
                                <Button
                                    className="float-right"
                                    size="xs"
                                    icon="fa fa-eye fa-xs"
                                    onClick={()=>onClickVerFlota()}
                                />
                            </div>
                        </div>
                    )}
                )}
            </ReactSortable>
        </>
    );
})

WidgetCuadrilla.defaultProps = {
    item:{
        id:0,
        cuadrillaDisponibleLugar:{
            descripcionCorto: '',
        },
    },
    onChange: () => {},
    onRemove: () => {},
    onChangeWidget: () => {},
};

const mapStateToProps = (state) => ({
    flotas: state.cuadrillas.flotas,
    cuadrillas: state.cuadrillas.cuadrillas,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetCuadrilla);