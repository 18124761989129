import React, {useState, useEffect, useMemo} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, InputCheck, MapBase, MarkerTarea, MarkerContacto, TareaCard, EficienciaCostoEstadoBadge} from '@app/components/index';
import PropTypes from 'prop-types';
import CuadrillaDisponiblesService from '@app/services/cuadrillaDisponibles.service';
import MapaFunction from '@app/functions/MapaFunction';
import { defaultCoordinates } from '@app/constants/geolocationOptions';
import { connect } from 'react-redux';
import CuadrillaLugaresService from '@app/services/cuadrillaLugares.service';
import { Spinner } from 'react-bootstrap';

function ModalCuadrillaDisponibleLugarVer({
    contacto,
    show,
    hideModal,
    item,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [cuadrilla, setCuadrilla] = useState(item);
    const [coordinates, setCoordinates] = useState(defaultCoordinates);
    const [tareasTM, setTareasTM] = useState([]);
    const [tareasTT, setTareasTT] = useState([]);
    const [isMostrarTareasTM, setIsMostrarTareasTM] = useState(true);
    const [isMostrarTareasTT, setIsMostrarTareasTT] = useState(true);

    const handleClose = () => hideModal();

    const actualizar = () => {
        setIsLoading(true);
        CuadrillaLugaresService.getById(item.id).then((response)=>{
            if(response && response.tareasTM && response.tareasTT){
                setTareasTM(response.tareasTM.filter(t=>t.coordenadas))
                setTareasTT(response.tareasTT.filter(t=>t.coordenadas))
            }
            setCuadrilla(response)
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }

    const onClickTareaMap = (tarea) => {
        setCoordinates(MapaFunction.gJsonToGoogleMaps(tarea.coordenadas)[0]);
    }

    const marks = useMemo(() => {
        let aux = [];
        if(isMostrarTareasTM && cuadrilla.tareasTM && cuadrilla.tareasTM.filter(t=>t.coordenadas).length>0){
            aux = [...aux, ...cuadrilla.tareasTM.filter(t=>t.coordenadas).map(t=>MapaFunction.gJsonToGoogleMaps(t.coordenadas)[0])];
        }
        if(isMostrarTareasTT && cuadrilla.tareasTT && cuadrilla.tareasTT.filter(t=>t.coordenadas).length>0){
            aux = [...aux, ...cuadrilla.tareasTT.filter(t=>t.coordenadas).map(t=>MapaFunction.gJsonToGoogleMaps(t.coordenadas)[0])];
        }
        if(contacto && (typeof item.incrementoCostoTM === 'number' || typeof item.incrementoCostoTT === 'number')){
            let to;
            if(contacto.coordenadas){
                [to,] = MapaFunction.gJsonToGoogleMaps(contacto.coordenadas);
            } else {
                to = {
                    lat: contacto.latitud || contacto.clienteLatitud,
                    lng: contacto.longitud || contacto.clienteLongitud,
                };
            }
            aux.push(to);
        }
        return aux;
    }, [isMostrarTareasTM,isMostrarTareasTT,contacto,cuadrilla])

    useEffect(() => {
        actualizar();
        return () => {
            
        }
    }, []);
    
    return (
        <Modal show={show} size="lg" backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${item.id} - CUADRILLA `}
                    {
                        item.cuadrillaLugarOrden && (
                            <b>
                                {`(${item.cuadrillaLugarOrden})`}
                            </b>
                        )
                    }
                    {
                        isLoading && (
                            <Spinner animation="border" className="text-primary" />
                        )
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-4 text-center">
                        <h6 className="card-subtitle border border-primary rounded bg-primary t-white">
                            {
                                item.periodo === 'TC' ?(
                                    `${item.tipoTrabajo} | ${item.tipoTrabajoC}`
                                ):(
                                    `${item.tipoTrabajo?item.tipoTrabajo:item.tipoTrabajoC}`
                                )
                            }
                        </h6>
                    </div>
                    <div className="col-4 text-center">
                        <h6 className="card-subtitle border border-primary rounded bg-primary t-white">{item.periodoNombre}</h6>
                    </div>
                    <div className="col-4 text-center">
                        <h6 className="card-subtitle border border-primary rounded bg-primary t-white">
                            {`TM: ${item.tareasDisponiblesCantidadTM} TT: ${item.tareasDisponiblesCantidadTT}`}
                        </h6>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="card-title">{`${cuadrilla.descripcion.split("- T", 1)} - TAREAS DISPONIBLES: ${cuadrilla.tareasDisponiblesCantidadTM + cuadrilla.tareasDisponiblesCantidadTT}`}</p>
                </div>
                <div className="row">
                    <div className="col-6">
                        {
                            cuadrilla.tareasTM && cuadrilla.tareasTM.map((t)=>{
                                return <TareaCard className="my-3 shadow border" item={t} key={t.id} onClickMap={onClickTareaMap} />
                            })
                        }
                    </div>
                    <div className="col-6">
                        {
                            cuadrilla.tareasTT && cuadrilla.tareasTT.map((t)=>{
                                return <TareaCard className="my-3 shadow border" item={t} key={t.id} onClickMap={onClickTareaMap} />
                            })
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {
                            tareasTM.length>0 && (
                                <InputCheck 
                                    label="Ocultar Tareas TM" 
                                    checked={isMostrarTareasTM} 
                                    onChange={()=>setIsMostrarTareasTM(!isMostrarTareasTM)}
                                />
                            )
                        }
                        {
                            item?.costoMinimoTM >= 0 && item?.incrementoCostoTM >= 0 && (
                                <EficienciaCostoEstadoBadge item={item} periodo='TM' key={1} />
                            )
                        }
                    </div>
                    <div className="col-6">
                        {
                            tareasTT.length>0 && (
                                <InputCheck 
                                    label="Ocultar Tareas TT" 
                                    checked={isMostrarTareasTT} 
                                    onChange={()=>setIsMostrarTareasTT(!isMostrarTareasTT)}
                                />
                            )
                        }
                        {
                            item?.costoMinimoTT >= 0 && item?.incrementoCostoTT >= 0 && (
                                <EficienciaCostoEstadoBadge item={item} periodo='TT' key={2} />
                            )
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 border shadow">
                        <MapBase 
                            id="mapaCuadrillaDisponibleLugar"
                            mapContainerStyle={{
                                width: '100%',
                                height: 400
                            }}
                            isSearchable={false}
                            coordinates={coordinates}
                            options={{
                                maxZoom: 19,
                                minZoom: 9,
                            }}
                            zoom={15}
                            marks={marks}
                        >
                        {
                            isMostrarTareasTM && tareasTM.map(tarea=>{
                                return <MarkerTarea item={tarea} key={tarea.id} />
                            })
                        }
                        {
                            isMostrarTareasTT && tareasTT.map(tarea=>{
                                return <MarkerTarea item={tarea} key={tarea.id} />
                            })
                        }
                        {
                            contacto && (typeof item.incrementoCostoTM === 'number' || typeof item.incrementoCostoTT === 'number') && (
                                <MarkerContacto item={contacto} />
                            )
                        }
                        </MapBase>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalCuadrillaDisponibleLugarVer.propTypes = {

}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalCuadrillaDisponibleLugarVer);

