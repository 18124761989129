import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LogCard } from '@app/components/index';
import LogService from '@services/log.service';

function CorporativoLogPage() {
    AOS.init();
    const [items, setItems] = useState([]);

    useEffect(() => {
        /** AL SER INSTANCIADO */
        LogService.getAll({length:50, logCorp:true}).then((response)=>{
            setItems(response);

        })
        return () => {
            /** AL SER ELIMINADO */
        }
    }, [])
    // console.log(items)
    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center my-3">
                        <h3 className="" data-aos="fade-up">General</h3>
                    </div>

                    <div className="offset-1 col-10">
                        <div className='row'>
                                {
                                    items.map((item)=>{
                                        return (
                                                <div className='col-6'>
                                                    <LogCard item={item} key={item.id} />
                                                </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default CorporativoLogPage;
