import React, {useState, useEffect} from "react";
import { Modal, Spinner} from "react-bootstrap";
import { Button, ListCard, LogTareaCard } from "@app/components/index";
import LogService from "@app/services/log.service";

function ModalTareaLog({
    show,
    hideModal,
    item,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [tarea, setTarea] = useState(item);
    const [logs, setLogs] = useState([]);
    const handleClose = () => hideModal();

    useEffect(() => {
        setIsLoading(true);
        LogService.getAllTareas({
            tareaId: item.id,
        }).then((response)=>{
            setLogs(response);
        })
        .finally(()=>{
            setIsLoading(false);
        })
        return () => {
            
        }
    }, [])

    return (
        <Modal show={show} backdrop="static" size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className="text-center">
                        {`LOGS TAREA #${tarea.id}`}
                    </span>
                    {
                        isLoading && (
                            <Spinner animation="border" className="text-primary ml-2" />
                        )
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListCard>
                    {
                    logs.map((log)=>{
                        return <LogTareaCard item={log} key={log.id} />
                    })
                    }
                </ListCard>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalTareaLog;