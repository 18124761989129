import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button, SelectLugar, SelectPersonal} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import AsesorxLugaresService from '@app/services/asesorxLugares.service';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({

});

function ModalAsesorxLugar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const lugarId = watch('lugarId', item.lugarId);
    const dni = watch('dni', item.dni);


    useEffect(() => {
        register('dni');
        register('lugarId');
    }, [register]);

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id
        };
        setIsLoading(true);
        if (form.id > 0) {
            AsesorxLugaresService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            AsesorxLugaresService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
        if (item.id > 0) {
            setValue('aristas', item.aristas);
            setValue('lugarId',item.lugarId);
            setValue('dni',item.dni);
        }
    }, []);

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar AsesorxLugar' : 'Registrar AsesorxLugar'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="vtoLicenciaL">
                                        Personal
                                    </label>
                                    <SelectPersonal
                                        id="dni"
                                        isInvalid={Boolean(errors.dni)}
                                        value={dni}
                                        onChange={(opt) => {
                                        setValue('dni', opt.value);
                                        }}
                                    />
                                    {errors.dni && (
                                        <div className="default-invalid-feedback">
                                            {errors.dni?.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="vtoLicenciaL">
                                        Localidad
                                    </label>
                                    <SelectLugar
                                        id="lugarId"
                                        isInvalid={Boolean(errors.lugarId)}
                                        value={lugarId}
                                        onChange={(opt) => {
                                        setValue('lugarId', opt.value);
                                        }}
                                    />
                                    {errors.lugarId && (
                                        <div className="default-invalid-feedback">
                                            {errors.lugarId?.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalAsesorxLugar.defaultProps = {
    show: false,
    item: {
        id: 0,
        dni: '',
        lugarId: ''
    }
};

export default ModalAsesorxLugar;