import axios from 'axios';
const base = 'estadisticas';

function totales(query = null) {
    return axios.get([base,'totales'].join('/'), {
        params: query
    });
}

function diarias(query = null) {
    return axios.get([base,'diarias'].join('/'), {
        params: query
    });
}


const EstadisticasService = {
    totales,
    diarias,
};
export default EstadisticasService;
