
/**
 * 
 * @param {Contrato} contrato 
 */
function armarMotivoTareaObservaciones(motivoTareaId, contrato){
    const {tipoMaterial, tipoTecho, altura, cableEmbutido} = contrato;
    let motivoTarea;
    let embutido;
    if(motivoTareaId === "4" || Number(motivoTareaId) === 4){
        motivoTarea="SIN";
    } else if(motivoTareaId === "10" || Number(motivoTareaId) === 10){
        motivoTarea="CON";
    }
    if(cableEmbutido === true){
        embutido = "SI"
    }else{
        embutido = "NO"
    }
    const response = `Instalación&nbsp;<span style="font-weight: bolder;">
    Confirmada</span>&nbsp;con el cliente y registrada en el Sistema.<u><br></u>
    <div style="text-align: center;"><span style="font-weight: bolder; font-size: 1rem;">
    <font color="#000000"><u>INSTALACION ${motivoTarea} COSTO</u></font></span></div><font color="#0000ff">
    Tipo de Material de construcción del domicilio:</font>&nbsp;<span style="font-weight: bolder;">
    ${tipoMaterial}</span><font color="#0000ff"><br>
    Tipo de Material de techo del domicilio:</font>&nbsp;<span style="font-weight: bolder;">
    ${tipoTecho}</span><font color="#0000ff"><br>
    Altura Máxima Aproximada del domicilio:</font>&nbsp;<span style="font-weight: bolder;">
    ${altura} M.</span><br><font color="#0000ff">¿Requiere embutido de cables?:</font>&nbsp;
    <span style="font-weight: bolder;">${embutido}.</span><br>`;

    return response;
}

const TareaFuncion = {
    armarMotivoTareaObservaciones,
}

export default TareaFuncion;