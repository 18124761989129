import React, {useState, useEffect} from 'react';
import { SelectTipoMaterial, SelectTipoTecho } from '@app/components/index';
import * as Yup from 'yup';

export const schema = Yup.object({
    cableEmbutido: Yup.mixed().required(),
    altura: Yup.number().required(),
    tipoMaterial: Yup.mixed().required(),
    tipoTecho:Yup.mixed().required(),
});

function FormTipoMaterial({
    register,
    setValue,
    errors,
}) {
    useEffect(() => {
        register('tipoMaterial');
        register('tipoTecho');
    }, [register]);
    return (
        <>
            <div className="col-12 col-md-4 text-gray my-3">
                <div className="row">
                    <div className="col-12">
                        <h5>
                            <i className="fas fa-angle-down mr-2"></i>
                            Informacion del Domicilio
                        </h5>
                    </div>
                    <div className="col-12 col-md-10 mt-lg-3 ml-lg-2">
                        <div className="form-group">
                            <label className="label-control" htmlFor="direccion">
                                Material
                            </label>
                            <SelectTipoMaterial
                                id="tipoMaterial"
                                name="tipoMaterial"
                                onChange={(opt) => {
                                    setValue('tipoMaterial', opt.value);
                                }}
                            />
                            {errors.tipoMaterial ? (
                                <div className="default-invalid-feedback">
                                    {errors.tipoMaterial.message}
                                </div>
                            ):null}
                        </div>
                        <div className="form-group">
                            <label className="label-control" htmlFor="altura">
                                Altura Aproximada
                            </label>
                            <input
                                className={`form-control ${errors.altura? 'is-invalid': ''}`}
                                type="number"
                                id="altura"
                                name="altura"
                                autoComplete="off"
                                ref={register}
                            />
                            {errors.altura ? (
                                <div className="invalid-feedback">
                                    {errors.altura.message}
                                </div>
                            ):null}
                        </div>
                        <div className="form-group">
                            <label className="label-control" htmlFor="direccion">
                                Tipo de Techo
                            </label>
                            <SelectTipoTecho
                                id="tipoTecho"
                                name="tipoTecho"
                                onChange={(opt) => {
                                    setValue('tipoTecho', opt.value);
                                }}
                            />
                            {errors.tipoTecho ? (
                                <div className="default-invalid-feedback">
                                    {errors.tipoTecho.message}
                                </div>
                            ):null}
                        </div>
                        <div className="form-group row">
                            <label className="col-form-label col-7" htmlFor="embutido">
                                Cable Embutido
                            </label>
                            <div className="form-check-inline col-2">
                                <label className="form-check-label" htmlFor="siEmbutido"> Si </label>
                                <input
                                    id="siEmbutido"
                                    className="ml-1 form-check-input"
                                    type="radio"
                                    value="true"
                                    name="cableEmbutido"
                                    ref={register}
                                />
                            </div>
                            <div className="form-check-inline col-2">
                                <label className="form-check-label" htmlFor="noEmbutido"> No </label>
                                <input
                                    id="noEmbutido"
                                    className="ml-1 form-check-input"
                                    type="radio"
                                    value="false"
                                    name="cableEmbutido"
                                    ref={register}
                                />
                            </div>
                            {errors.cableEmbutido ? (
                                <div className="default-invalid-feedback col-12">
                                    {errors.cableEmbutido.message}
                                </div>
                            ):null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormTipoMaterial;
