import React from 'react'
import styled from 'styled-components';

const Styles = styled.div`
    width: 100%;
`;

function ContactoCard({
    item,
    buttons:ButtonsComponent,
}) {
    const noButtons = !ButtonsComponent;
    return (
        <Styles className="card border border-primary redondeado shadow-sm p-3 m-3">
            <div className="row ">
                <div className={`${noButtons?'col-10':'col-8'} d-flex flex-column`}>
                    <div className="card-title text-truncate">
                        {item.nombreCompleto}
                    </div>
                    <div className="card-subtitle text-truncate">
                        {item.direccion}
                    </div>
                </div>
                {
                    !noButtons && (
                        <div className="col-4">
                            <ButtonsComponent />
                        </div>
                    )
                }
            </div>
        </Styles>
    )
}

ContactoCard.defaultProps = {
    buttons: null,
};

export default ContactoCard
