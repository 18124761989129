import React, {useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination,
    useAsyncDebounce,
    useSortBy
} from 'react-table';
import {Button, Select} from '@app/components/index';
import BTable from 'react-bootstrap/Table';
import {matchSorter} from 'match-sorter';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

const Styles = styled.div`
    width: 100%;

`;
function DefaultColumnFilter({
    column: {filterValue, preFilteredRows, setFilter}
}) {
    const count = preFilteredRows?.length;

    return (
        <div className="form-group m-0">
            <input
                value={filterValue || ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                className="form-control"
                placeholder="Buscar..."
            />
        </div>
    );
}
function GlobalFilter({className, preGlobalFilteredRows, globalFilter, setGlobalFilter}) {
    const count = preGlobalFilteredRows?.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((toValue) => {
        setGlobalFilter(toValue || undefined);
    }, 200);

    return (
        <div className={`form-group m-0 ${className}`} id="globalFilter">
            <input
                value={value || ''}
                type="text"
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                className="form-control"
                placeholder="Buscador"
            />
        </div>
    );
}

const defaultPropGetter = () => ({});

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {keys: [(row) => row.values[id]]});
}

function Table({
    currentPage,
    length,
    total,
    search,

    isLoading,
    isShowHeader,
    onSearch,
    onPageChange,
    onLengthChange,
    onFilteredChange,
    onSortedChange,
    customGlobalFilter,
    filterDisabled,

    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
}) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter
        }),
        []
    );
    const {
        visibleColumns,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize, globalFilter, filters, sortBy },
        preGlobalFilteredRows,
        setGlobalFilter,
        setAllFilters,
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: currentPage},
            defaultColumn,
            filterTypes,

            ...(total>0 && length>0 ? {
                manualPagination: true,
                pageCount: Math.ceil(total / length),
                autoResetPage: false,
                manualFilters: true,
                manualGlobalFilter: true,
                manualSortBy: true,
                autoResetGlobalFilter: false,
                autoResetFilters: false,
                autoResetSortBy: false,
            } : {
                manualGlobalFilter: false,
            }),
            globalFilter: customGlobalFilter,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const [isFirstStart, setIsFirstStart] = useState(true);

    const onSearchEnd = () => {
        const searchableColumns = visibleColumns.filter(c=>!c.disableGlobalFilter).map(c=>c.id);
        const newFilters = [...filters];
        if(globalFilter){
            newFilters.push({id:'search',value:`${searchableColumns}:${globalFilter}`});
        }
        onSearch(newFilters);
    }

    useEffect(() => {
        if(!isFirstStart){
            onSortedChange(sortBy);
        }
    }, [sortBy ])

    useEffect(() => {
        if(!isFirstStart){
            onPageChange(pageIndex);
        }
    }, [pageIndex]);

    useEffect(() => {
        if(length>0 && !isFirstStart){
            onLengthChange(pageSize)
        }
    }, [pageSize]);

    useEffect(() => {
        if(!isFirstStart){
            onFilteredChange(globalFilter);
            onSearchEnd();
        }
    }, [filters,globalFilter]);

    useEffect(() => {
        if( !Array.isArray(customGlobalFilter) && !(total>0) ){
            setGlobalFilter(globalFilter);
        }
        if(isFirstStart){
            setIsFirstStart(false);
        }
    }, [data]);

    return (
        <>
            <div className={`row ${isShowHeader?'':'d-none'}`}>
                <div className="col-3 col-sm-4 col-lg-2 col-md-2">
                    <Select
                        placeholder="Mostrar"
                        options={[10, 20, 30, 40, 50].map((iPageSize) => {
                            return {
                                value: iPageSize,
                                label: String(iPageSize)
                            };
                        })}
                        defaultValue={pageSize}
                        onChange={(opt) => {
                            setPageSize(Number(opt.value));
                        }}
                    />
                </div>
                <div className="col-5 offset-4 offset-md-5 col-md-5 offset-lg-7 col-lg-3">
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
            </div>
            <BTable
                responsive="sm"
                size="sm"
                className="table mt-2"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => {
                        return (
                            <React.Fragment key={`header_${index}`}>
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps([
                                                column.getSortByToggleProps(),
                                                {
                                                    className: column.className,
                                                    style: column.style
                                                },
                                                getColumnProps(column),
                                                getHeaderProps(column)
                                            ])}
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted &&
                                                    (column.isSortedDesc
                                                        ?  <i className="ml-2 fas fa-sort-down"></i>
                                                        : <i className="ml-2 fas fa-sort-up"></i> )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                                {headerGroup.headers.find(
                                    (column) => column.canFilter === true
                                ) && (
                                    <tr
                                        className={filterDisabled ? ("d-none") : ("")}
                                        role="row"
                                        key={`headerFilter_${index}`}
                                    >
                                        {headerGroup.headers.map(
                                            (column, indexTh) => {
                                                return (
                                                    <th
                                                        className={column.ClassName}
                                                        style={column.style}
                                                        key={`innerFilter_${indexTh}`}
                                                    >
                                                        {column.isGlobalFilter ? (
                                                            <GlobalFilter
                                                                className="m-0 w-100"
                                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                                globalFilter={globalFilter}
                                                                setGlobalFilter={setGlobalFilter}
                                                            />
                                                        ):(
                                                            <>
                                                            {column.canFilter ? column.render('Filter') : null}
                                                            </>
                                                        )}
                                                    </th>
                                                )
                                            }
                                        )}
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps([
                                                {
                                                    className:
                                                        cell.column.className,
                                                    style: cell.column.style
                                                },
                                                getColumnProps(cell.column),
                                                getCellProps(cell)
                                            ])}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </BTable>
            <div className="pagination d-flex justify-content-between">
                <div>
                    <Button
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {'<<'}
                    </Button>
                    <Button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {'<'}
                    </Button>
                    <Button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </Button>
                    <Button
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {'>>'}
                    </Button>
                </div>
                <div>
                    <p>

                        Pagina&nbsp;
                            {pageIndex + 1}
                            &nbsp;de&nbsp;
                            {pageOptions?.length}
                        {
                            total > 0 && (
                                <b>
                                    &nbsp;
                                    {`Total: ${total}`}
                                </b>
                            )
                        }
                    </p>
                </div>
            </div>
        </>
    );
}

const TablePaginated = (props) => {
    return (
        <Styles>
            <Table
                {...props}
                getColumnProps={(column) => ({
                    className: column.ClassName,
                    style: {
                        width: column.Width,
                        maxWidth: column.MaxWidth
                    }
                })}
            />
        </Styles>
    );
};

TablePaginated.defaultProps = {
    total: null,
    currentPage: 0,
    length: 10,
    isShowHeader: true,
    isLoading:false,
    search:[],
    onSearch: () => {},
    onPageChange: () => {},
    onLengthChange: () => {},
    customGlobalFilter: [],
    onFilteredChange: () => {},
    onSortedChange: () => {},
}
export default TablePaginated;
