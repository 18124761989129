import ZohoContactoService from '@app/services/zohoContacto.service';
import React, {useState, useEffect, useRef, forwardRef, Fragment} from 'react';
import {AsyncTypeahead, InputProps} from 'react-bootstrap-typeahead';

const InputContactsSearchZoho = forwardRef(({
    id,
    field,
    value,
    onSelect,
    ...defaultProps
},ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query) => {
        setIsLoading(true);
        const search = {
            word: query
        };
        ZohoContactoService.searchContacts(search)
            .then(({data}) => {
                // console.log(data)
                setOptions(
                    data.map((item) => {
                        // console.log(item)
                        let returnField = '';
                        const clienteNombre = `${item.Full_Name}`;
                        const clienteDocumento = `${item.DNI_CUIT}`;
                        /* let clienteId = `${item.customFields['Nro Cliente']}`;
                        if (item.customFields['ID Cliente']) {
                            clienteId = `${item.customFields['ID Cliente']}`;
                        } */
                        /* const clienteDireccion = item.street;
                        const clienteCelular = item.mobile;
                        const clienteTelefono = item.phone;
                        const clienteCategoria =
                            item.customFields.Categoria ?? '';
                        let ubicacion;
                        let coordenadas = item.customFields['Coordenadas de instalacion']; */
                        /* if (coordenadas) {
                            ubicacion = coordenadas?.split(',');
                            if(ubicacion.length > 1){
                                coordenadas = {
                                    lat: Number(ubicacion[0]),
                                    lng: Number(ubicacion[1])
                                };
                            } else {
                                ubicacion = coordenadas?.split('-');
                                if(ubicacion.length > 2){
                                    coordenadas = {
                                        lat: Number(-ubicacion[1]),
                                        lng: Number(-ubicacion[2])
                                    };
                                }
                            }
                        } else {
                            coordenadas = null;
                        } */
                        switch (field) {
                            case 'clienteNombre':
                                returnField = clienteNombre;
                                break;
                            default:
                                break;
                        }
                        return {
                            ...item,
                            [field]: returnField,
                            clienteNombre: clienteNombre,
                            clienteDocumento: clienteDocumento,
                            /* clienteId: clienteId,
                            clienteDireccion: clienteDireccion,
                            clienteCelular: clienteCelular,
                            clienteTelefono: clienteTelefono,
                            clienteCategoria: clienteCategoria,
                            coordenadas: coordenadas */
                        };
                    })
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onSelected = (newSelect) => {
        onSelect(newSelect);
    };

    useEffect(() => {
        /*
        console.log(ref.current);
        */
        ref.current.clear()
        ref.current.setState({text:value})
    }, [value])

    return (
        <AsyncTypeahead
            {...defaultProps}
            ref={ref}
            id={id}
            inputProps={{
                id: id
            }}
            isLoading={isLoading}
            labelKey={field}
            minLength={3}
            onSearch={handleSearch}
            onChange={onSelected}
            filterBy={() => true}
            options={options}
            placeholder="Buscar en ZOHO CRM..."
            emptyLabel="No hay coincidencias"
            promptText="Escribe para buscar..."
            renderMenuItemChildren={(option, props) => {
                return (
                    <>
                        <p>
                            <strong>{option.clienteNombre}</strong>
                            <br />
                            {option.clienteDocumento !== "null"?`DNI: ${option.clienteDocumento}`:`Nro. Cliente: ${option.Nro_Cliente}`}
                        </p>
                    </>
                )
            }}
            useCache={false}
        />
    );
});

/**
 * documento
 * clienteId
 */
 InputContactsSearchZoho.defaultProps = {
    field: 'clienteNombre',
    value: '',
    onSelect: () => {},
};

export default InputContactsSearchZoho;
