import React, {useMemo, useState} from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {InputGroup, FormControl} from 'react-bootstrap';
import { Button } from '@app/components/index';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import { addWeeks, format, isSunday, subWeeks } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';

const Styles = styled.div`
    width: 100%;

    .react-datepicker-wrapper{
        width: 100%;
    }

    input{
        text-align: center;
        font-weight: bold;
        font-size: larger;
    }
`;

const options = {
    weekStartsOn:1,
}

function DateWeek({
    id,
    value,
    onChange,
    disabled,
    isLoading,
    ...defaultProps
}){
    const initialValue = useMemo(() => {
        if(FechaFunction.isDate(value)){
            return FechaFunction.rangeWeek(value)
        }
        if(typeof value === 'object'){
            return value;
        }
        return FechaFunction.rangeWeek();
    }, [])

    const [startDate, setStartDate] = useState(initialValue.start);
    const [endDate, setEndDate] = useState(initialValue.end);

    const onClickLeft = () =>{
        const newDate = subWeeks(startDate,1);
        const newStart = startOfWeek(newDate,options);
        const newEnd = endOfWeek(newDate);
        setStartDate(newStart);
        setEndDate(newEnd);
        onChange({
            start:newStart,
            end:newEnd,
        });
    }

    const onClickRight = () => {
        const newDate = addWeeks(startDate,1);
        const newStart = startOfWeek(newDate,options);
        const newEnd = endOfWeek(newDate);
        setStartDate(newStart);
        setEndDate(newEnd);
        onChange({
            start:newStart,
            end:newEnd,
        });
    }

    const CustomInput = React.forwardRef(
        ({value: valueField, onClick}, ref) => (
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <Button icon='fa fa-chevron-left' onClick={onClickLeft} isLoading={isLoading} />
                </InputGroup.Prepend>
                <FormControl
                    onClick={onClick}
                    className="form-control"
                    value={`${format(startDate,'dd/MM')} - ${format(endDate,'dd/MM/yyyy')}`}
                    ref={ref}
                    readOnly
                />
                <InputGroup.Append>
                    <Button icon='fa fa-chevron-right' onClick={onClickRight} isLoading={isLoading} />
                </InputGroup.Append>
            </InputGroup>
        )
    );

    const onDateSelected = (newDate) => {
        const newStart = startOfWeek(newDate,options);
        const newEnd = endOfWeek(newDate);
        setStartDate(newStart);
        setEndDate(newEnd);
        onChange({
            start:newStart,
            end:newEnd,
        });
    };

    const filterDate = (currentDate) =>{
        return !isSunday(currentDate)
    }

    return (
        <Styles>
            <DatePicker
                {...defaultProps}
                id={id}
                name={id}
                disabled={disabled}
                dateFormat="P"
                customInput={<CustomInput />}
                selected={startDate}
                onChange={onDateSelected}
                filterDate={filterDate}
                startDate={startDate}
                endDate={endDate}
            />
        </Styles>
    );
};

DateWeek.defaultProps = {
    value: new Date(),
    disabled: false,
    onChange: () => {},
};

export default DateWeek;
