import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const icons = {
    facebook: 'fab fa-facebook',
    google: 'fab fa-google',
    googlePlus: 'fab fa-google-plus'
};

const AppButton = ({
    tooltip,
    children,
    isLoading,
    icon,
    theme = 'primary',
    disabled,
    onClick,
    ...otherProps
}) => {
    let spinnerTemplate;
    let iconTemplate;

    if (isLoading) {
        spinnerTemplate = (
            <Spinner
                className="ml-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        );
    }
    if (Array.isArray(icon)){
        iconTemplate = <FontAwesomeIcon className="mr-2" icon={icon} />;
    } else if (icon && icons[icon]) {
        iconTemplate = <i className={`${icons[icon]} ${children?'mr-2':''}`} />;
    } else if (typeof icon === 'string') {
        iconTemplate = <i className={`${icon} ${children?'mr-2':''}`} />;
    }

    const overlayToolTip = (props) => {
        return (
            <Tooltip id={uuidv4()} {...props}>
                {tooltip}
            </Tooltip>
        )
    };

    const CustomOverlayTrigger = (props) => {
        const {children:defaultChildren} = props
        return (
            <OverlayTrigger
                placement="auto"
                delay={{ show: 100, hide: 400 }}
                overlay={overlayToolTip}
            >
                {defaultChildren}
            </OverlayTrigger>
        )
    };

    return (
        <>
            {
                tooltip !== null ? (
                    <CustomOverlayTrigger>
                        <Button
                            {...otherProps}
                            onClick={onClick}
                            variant={theme}
                            disabled={isLoading || disabled}
                        >
                            {
                                !isLoading && (
                                    <>{iconTemplate}</>
                                )
                            }
                            {children}
                            {spinnerTemplate}
                        </Button>
                    </CustomOverlayTrigger>
                ):(
                    <Button
                        {...otherProps}
                        onClick={onClick}
                        variant={theme}
                        disabled={isLoading || disabled}
                    >
                        {
                            !isLoading && (
                                <>{iconTemplate}</>
                            )
                        }
                        {children}
                        {spinnerTemplate}
                    </Button>
                )
            }
        </>
    );
};

AppButton.defaultProps = {
    tooltip: null,
    size: 'sm',
    onClick: () => {},
};

export default AppButton;
