import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux';
import { TareaCard } from '@app/components/index';
import TareasService from '@app/services/tareas.service';

function TabContactoTareas({
    contacto
}) {
    const [tareas, setTareas] = useState([]);

    useEffect(() => {
        TareasService.getAll({
            contactoId: contacto.id,
        })
        .then((response)=>{
            setTareas(response);
        })
        return () => {
            
        }
    }, [])
    return (
        <div className="p-5">
            <div className="row">
                <div className="offset-lg-2 col-lg-8">
                    {
                        tareas.map((tarea)=>{
                            return <TareaCard item={tarea} key={tarea.id} className="mb-3" hideClickMap />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
})

const mapDispatchToProps = (dispatch) => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(TabContactoTareas)