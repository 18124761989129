import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from '../../components/button/Button';
import {login} from '../../store/actions/auth';

const schema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required(),
    password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required()
});



const LoginScreen = ({onUserLogin}) => {

    AOS.init();
    const [isAuthLoading, setAuthLoading] = useState(false);
    const [mostrarpass, setMostrarpass] = useState(false)
    const history = useHistory();
    const [t] = useTranslation();

    const onLogin = (email, password) => {
        setAuthLoading(true);
        onUserLogin(email, password).then((resp) => {
            toast.success('Iniciaste Sesion!');
            if(resp.persona.puesto === "Asesor" || resp.persona.puesto === "Agencia" || resp.persona.puesto === "Corporativo"){
                history.push('/home');
                window.location.reload(true);
            }else{
                history.push('/');
                window.location.reload(true);
            }
        }).catch(()=>{
            setAuthLoading(false);
        });
    };

    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            email: '',
            password: ''
        }
    });

    const onSubmit = (values) => {
        onLogin(values.email,values.password);
    }

    document.getElementById('root').classList = 'hold-transition login-page';


    return (
        <div className="login-box mb-5">
            <div className="card card-outline card-primary" data-aos="fade-up">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <img src="/img/logo_login.png" alt="login" width="100%"></img>
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">{t('login.label.signIn')}</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <div className="input-group">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder="Email"
                                    ref={register}
                                />
                            </div>
                            {errors.email ? (
                                <div className="default-invalid-feedback">
                                    {errors.email?.message}
                                </div>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <div className="input-group">
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                                <input
                                    id="password"
                                    name="password"
                                    type={mostrarpass === false? `password` : ``}
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder="Contraseña"
                                    ref={register}
                                />


                                    <div className="input-group-append">
                                            <button type="button" onClick={()=>setMostrarpass(!mostrarpass)} className="btn btn-primary btn-mostrar-pass">
                                                <span className="fas fa-eye" />
                                            </button>
                                    </div>


                            </div>
                            {errors.password ? (
                                <div className="default-invalid-feedback">
                                    {errors.password?.message}
                                </div>
                            ) : null}
                        </div>

                        <div className="row">
                            <div className="col-8">
                            {/**
                                <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        {t('login.label.rememberMe')}
                                    </label>
                                </div>
                             */}
                            </div>
                            <div className="offset-3 mt-2 col-6">
                                <Button
                                    block
                                    type="submit"
                                    isLoading={isAuthLoading}
                                    disabled={isAuthLoading}
                                >
                                    {t('login.button.signIn.label')}
                                </Button>
                            </div>
                        </div>
                    </form>
                    <div className="social-auth-links text-center mt-2 mb-3"></div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onUserLogin: (email,password) => dispatch(login(email,password))
});

export default connect(null, mapDispatchToProps)(LoginScreen);
