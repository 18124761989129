import React, {useState, useEffect} from 'react'
import axios from 'axios';
import TareasService from '@app/services/tareas.service';
import ContactosService from '@app/services/contactos.service';
/**
 * Con cancelacion de tareas
 * @returns
 */
export default function useGetContactos({
    defaultQuery
}) {
    const [source, setSource] = useState()
    const [query, setQuery] = useState(defaultQuery)
    const [response, setResponse] = useState({items:[],total:0});
    const [isLoading, setIsLoading] = useState(false);

    const request = () => {
        if(!query){
            return;
        }
        if (source) {
            source.cancel();
        }
        const newSource = axios.CancelToken.source();
        setSource(newSource);
        setIsLoading(true);
        ContactosService.getAll(query,newSource.token).then((newResponse)=>{
            if(newResponse){
                setResponse(newResponse);

            }
        })
        .finally(()=>{
            setIsLoading(false);
        });
    }


    useEffect(() => {
        request();
    }, [query])


    return {response, setQuery, isLoading };
}
