import CalendarioService from '@app/services/calendario.service';
import {
    format,
    parse,
    parseISO,
    isSameDay,
    endOfDay,
    startOfDay,
    endOfWeek,
    startOfWeek,
    endOfMonth,
    startOfMonth
} from 'date-fns';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

export const CALENDAR_ADD_CUADRILLA_LUGAR = 'CALENDAR_ADD_CUADRILLA_LUGAR';
export const CALENDAR_SUB_CUADRILLA_LUGAR = 'CALENDAR_SUB_CUADRILLA_LUGAR';
export const CALENDAR_CLEAR_CUADRILLA_LUGAR = 'CALENDAR_CLEAR_CUADRILLA_LUGAR';
export const CALENDAR_START_CUADRILLA_LUGAR = 'CALENDAR_START_CUADRILLA_LUGAR';
export const CALENDAR_WS = 'CALENDAR_WEBSOCKET';

export const CALENDAR_ESTADO = 'CALENDAR_ESTADO';
export const CALENDAR_EVENTOS = 'CALENDAR_EVENTOS';
export const CALENDAR_REPROGRAMACION = 'CALENDAR_REPROGRAMACION';


export const agregarCuadrillaLugar = (data) => ({
    type: CALENDAR_ADD_CUADRILLA_LUGAR,
    payload: data
});

export const quitarCuadrillaLugar = (data) => ({
    type: CALENDAR_SUB_CUADRILLA_LUGAR,
    payload: data
});

export const limpiarCuadrillaLugar = () => ({
    type: CALENDAR_CLEAR_CUADRILLA_LUGAR,
});

export const iniciarCuadrillaLugar = (data) => ({
    type: CALENDAR_START_CUADRILLA_LUGAR,
    payload: data
});

export const guardarEstadoCalendario = (data) => {
    const {initialView, initialDate} = data;
    let end;
    let start;
    switch (initialView) {
        case 'dayGridMonth':
            end = endOfMonth(initialDate);
            start = startOfMonth(initialDate);
            break;
        case 'timeGridWeek':
            end = endOfWeek(initialDate);
            start = startOfWeek(initialDate);
            break;
        case 'timeGridDay':
            end = endOfDay(initialDate);
            start = startOfDay(initialDate);
            break;
        default:
            break;
    }
    return {
        type: CALENDAR_ESTADO,
        payload: {
            ...data,
            end: end,
            start: start
        }
    };
};

export const recuperarEventos = (data) => (dispatch) => {
    dispatch({
        type: CALENDAR_EVENTOS,
        payload: data
    });
};

export const enviarWS = (data) => ({
    type: CALENDAR_WS,
    payload: data
});

export const agregarReprogramacion = (data) => ({
    type: CALENDAR_REPROGRAMACION,
    payload: data,
})

