import React, {useState, useEffect, Fragment} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styled from 'styled-components';

const Styles = styled.div`
    .demo-root {
        width: 90%;
        display: flex;
        font-family: Roboto;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .demo-label {
        display: block;
        font-size: 20px;
        font-weight: 500;
        margin: 50px 0 25px;
    }
    .demo-label-high {
        display: block;
        font-size: 20px;
        font-weight: 500;
        margin: 50px 0 90px;
    }
    .demo-subLabel {
        margin-bottom: 20px;
    }
    .demo-editorSection {
        display: flex;
        justify-content: space-between;
    }
    .demo-wrapper {
        width: 100% !important;
        display: block !important;
        margin-bottom: 25px !important;
    }
    .demo-wrapper-medium {
        width: 650px !important;
        display: block !important;
        margin-bottom: 50px !important;
        height: 300px !important;
    }
    .demo-editor {
        background-color: white;
        height: 275px !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }
    .demo-editorWrapper {
    }
    .demo-content {
        border: 1px solid #f1f1f1;
        padding: 5px;
        border-radius: 5px;
        width: 100%;
        border-radius: 2px;
        resize: none;
        height: 200px;
    }
    .demo-toolbar-absolute {
        position: absolute !important;
        top: -40px !important;
        width: 550px !important;
        border-radius: 3px !important;
        background: #f3f3f3 !important;
        border: 1px solid #e3e3e3 !important;
    }
    .demo-toolbar-absolute-high {
        position: absolute !important;
        top: -80px !important;
        width: 550px !important;
        border-radius: 3px !important;
        background: #f3f3f3 !important;
        border: 1px solid #e3e3e3 !important;
    }
    .demo-editor-plain {
        height: 80% !important;
        border-width: 0px !important;
    }
    .demo-editor-embedded {
        height: 90% !important;
        border-width: 0px !important;
    }
    .demo-wrapper-relative {
        display: block !important;
        position: relative !important;
        margin-bottom: 25px !important;
        height: 200px !important;
    }
    .demo-wrapper-relative-long {
        width: 715px !important;
        display: block !important;
        position: relative !important;
        margin-bottom: 25px !important;
        height: 450px !important;
    }
    .bordered-option-classname {
        border: 1px solid #e3e3e3 !important;
    }
    .demo-toolbar-absolute > div:not(:last-of-type) {
        border-right: 2px solid #e3e3e3;
        padding-right: 5px;
    }
    .demo-toolbar-absolute > div:not(:first-of-type) {
        padding-left: 5px;
    }
    .demo-toolbar-custom {
        border-color: rgb(0, 47, 126) !important;
        background-color: #9ebef5 !important;
    }
    .demo-editor-custom {
        height: 200px !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        height: 75% !important;
        border-radius: 2px !important;
        border-color: rgb(0, 47, 126) !important;
    }
    .demo-option-custom {
        width: 15px !important;
        height: 15px !important;
        border-color: rgb(0, 47, 126) !important;
        color: rgb(0, 47, 126);
    }
    .demo-option-custom-wide {
        width: 125px !important;
        height: 25px !important;
        border-color: rgb(0, 47, 126) !important;
        color: rgb(0, 47, 126) !important;
    }
    .demo-option-custom-medium {
        height: 25px !important;
        border-color: rgb(0, 47, 126) !important;
        color: rgb(0, 47, 126) !important;
    }
    .demo-dropdown-custom {
        border-color: rgb(0, 47, 126) !important;
    }
    .demo-popup-custom {
        border-color: rgb(0, 47, 126) !important;
        padding: 5px !important;
    }
    .demo-popup-custom input {
        border-color: rgb(0, 47, 126) !important;
    }
    .demo-popup-custom button {
        border-color: rgb(0, 47, 126) !important;
    }
    .demo-section-wrapper {
        display: flex;
    }
    .demo-editor-wrapper {
        width: 60%;
    }
    .demo-section {
        margin: 50px 30px 20px;
        width: 95%;
    }
    .ReactCodeMirror {
        width: 38%;
        margin-left: 2%;
        border: 1px solid #e4e4e4;
        height: 400px;
    }
    .CodeMirror {
        height: 400px !important;
    }
    .demo-custom-option {
        width: 26px;
        height: 26px;
        border: 1px solid #f1f1f1;
        text-align: center;
        padding-right: 5px;
        padding-bottom: 2px;
        cursor: pointer;
    }
`;

const toolbar = {
    options: [
        'inline',
        'blockType',
        'fontSize',
        'list',
        'textAlign',
        'colorPicker',
        'remove',
        'history'
    ],
    blockType: {
        inDropdown: true,
        options: [
            'Normal',
            'H1',
            'H2',
            'H3',
            'H4',
            'H5',
            'H6',
            'Blockquote',
            'Code'
        ],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered', 'indent', 'outdent']
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify']
    },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo']
    }
};
const InputEditor = ({onChange, template}) => {
    const [editor, setEditor] = useState(EditorState.createEmpty());

    const onEditorStateChange = (newEditor) => {
        setEditor(newEditor);
        if (onChange)
            onChange(draftToHtml(convertToRaw(newEditor.getCurrentContent())));
    };

    useEffect(() => {
        const contentBlock = htmlToDraft(template);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const newEditor = EditorState.createWithContent(contentState);
            setEditor(newEditor);
        }
    }, [template]);

    return (
        <Styles>
            <Editor
                toolbar={toolbar}
                editorState={editor}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
            />
        </Styles>
    );
};
export default InputEditor;
