import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Modal, Container} from 'react-bootstrap';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import { Button, FormContacto, FormContactoSchema } from '@app/components/index';
import ContactosService from '@app/services/contactos.service';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

function ModalContactoEditar({
    show, hideModal, item, onGuardar
}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);

    const {handleSubmit,control,errors,register, setValue, setError} = useForm({
        resolver: yupResolver(FormContactoSchema),
        defaultValues: item,
    });

    const onSubmit = (data) => {
        const form = {
            ...item,
            ...data,
        };
        setIsLoading(true);
        ContactosService.update(form).then(()=>{
            onGuardar();
            hideModal();
        })
        .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
        .finally(()=>{
            setIsLoading(false);
        });
    };

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar Contacto' : 'Registrar registrar Contacto'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <FormContacto isModal control={control} errors={errors} register={register} setValue={setValue} />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalContactoEditar.propTypes = {

}

export default ModalContactoEditar

