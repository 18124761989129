import React from 'react'

function PasosPage() {
    return (
        <>
            <div className="container">
                <div className="row">
                            <div className="col-12 mt-lg-5 mt-3 mb-3">
                                <h3>
                                    Esta guía te ayudara a lograr tus objetivos
                                </h3>
                                <h6 className="text-secondary">

                                    No te olvides de ir marcando los check a medida que avanzas
                                </h6>
                            </div>


                            <div className="offset-1 col-10 offset-lg-2 col-lg-8 border shadow-sm rounded mt-lg-3 mt-1 p-4 target">
                                <div className="row">

                                    <div className="col-3 col-lg-2 mt-2 mt-md-1 mt-lg-0">
                                        <img src="/img/tarjeta-tarea.png" className="w-100" alt="" />
                                    </div>

                                    <div className="col-9 col-lg-10 mt-md-3 mt-lg-1">
                                        <div className="row">
                                            <div className="col-9 col-lg-10">
                                                <h4>Titulo</h4>
                                                <h6>Subtitulo</h6>
                                            </div>
                                            <div className="col-3 col-lg-2">
                                                <div className="custom-control justify-content-center align-items-center custom-checkbox checkbox-xl">
                                                    <input type="checkbox" className="custom-control-input" id="" checked="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="offset-1 col-10 offset-lg-2 col-lg-8 border shadow-sm rounded mt-3 p-4 target">
                                <div className="row">
                                    <div className="col-3 col-lg-2 mt-2 mt-md-1 mt-lg-0">
                                        <img src="/img/tarjeta-tarea.png" className="w-100" alt="" />
                                    </div>
                                    <div className="col-9 col-lg-10 mt-md-3 mt-lg-1">
                                        <div className="row">
                                            <div className="col-9 col-lg-10">
                                                <h4>Titulo</h4>
                                                <h6>Subtitulo</h6>
                                            </div>
                                            <div className="col-3 col-lg-2">
                                                <div className="custom-control justify-content-center align-items-center custom-checkbox checkbox-xl">
                                                    <input type="checkbox" className="custom-control-input" id="" checked="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="offset-1 col-10 offset-lg-2 col-lg-8 border shadow-sm rounded mt-3 p-4 target">
                                <div className="row">

                                    <div className="col-3 col-lg-2 mt-2 mt-md-1 mt-lg-0">
                                        <img src="/img/tarjeta-tarea.png" className="w-100" alt="" />
                                    </div>

                                    <div className="col-9 col-lg-10 mt-md-3 mt-lg-1">
                                        <div className="row">
                                            <div className="col-9 col-lg-10">
                                                <h4>Titulo</h4>
                                                <h6>Subtitulo</h6>
                                            </div>
                                            <div className="col-3 col-lg-2">
                                                <div className="custom-control justify-content-center align-items-center custom-checkbox checkbox-xl">
                                                    <input type="checkbox" className="custom-control-input" id="" checked="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="offset-1 col-10 offset-lg-2 col-lg-8 border shadow-sm rounded mt-3 p-4 target">
                                <div className="row">

                                    <div className="col-3 col-lg-2 mt-2 mt-md-1 mt-lg-0">
                                        <img src="/img/tarjeta-tarea.png" className="w-100" alt="" />
                                    </div>

                                    <div className="col-9 col-lg-10 mt-md-3 mt-lg-1">
                                        <div className="row">
                                            <div className="col-9 col-lg-10">
                                                <h4>Titulo</h4>
                                                <h6>Subtitulo</h6>
                                            </div>
                                            <div className="col-3 col-lg-2">
                                                <div className="custom-control justify-content-center align-items-center custom-checkbox checkbox-xl">
                                                    <input type="checkbox" className="custom-control-input" id="" checked="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="offset-2 col-8 offset-lg-4 col-lg-4 my-5">
                                <button type="button" className="btn btn-primary btn-block">
                                    Realizar Venta
                                </button>
                            </div>



                </div>
            </div>
        </>
    )
}

export default PasosPage;
