import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import ContratosService from '@app/services/contratos.service';

const Styles = styled.div``;

const SelectTratosxContacto = ({id, value, onChange, isInvalid, isClearable, disabled, filtro}) => {
    
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        if(filtro){
            ContratosService.getCrmTrato(filtro).then((response) => {
                setItems(response);
                const newOptions = response.map((item) => {
                    if(item?.contrato){
                        return{
                            value: item.id_t,
                            label: `Contrato N° ${item.id_t} - ${item.nombre_t}`,
                            contrato: true,
                            ...item
                        }

                    }
                    return {
                        value: item.id_t,
                        label: `Pre-Contrato N° ${item.id_t} - ${item.nombre_t}`,
                        ...item
                    };
                });
                setOptions(newOptions);
                if (typeof value === 'string' || typeof value === 'number') {
                    setSelectedValue(
                        newOptions.find((opt) => opt.value === Number(value))
                    );
                } else if(Array.isArray(value)){
                    setSelectedValue(value.map(va=>({label:`# ${va.id_t} - ${va.nombre_t}`,value:va.id_t})));
                }else{
                    setSelectedValue(null)
                }
            });
        }
    }, [filtro]);
    return (
        <Styles>
            <Select
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                isDisabled={disabled}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectTratosxContacto.defaultProps = {
    isClearable: false
};

export default SelectTratosxContacto;