import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {BoxPrefactibilidad, Button, InputCheck, MapBase, Poligono, SelectLugar} from '@app/components/index';
import useCurrentLocation from "@app/hooks/useCurrentLocation";
import ContactosAction from '@app/store/actions/contactos';
import AsesorxLugaresService from '@app/services/asesorxLugares.service';
import './FormularioContacto.scss';
import PlanesService from '@app/services/planes.service';
import ContactosService from '@app/services/contactos.service';
import TareaFuncion from '@app/functions/TareaFunction';
import MapaFunction from '@app/functions/MapaFunction';

const TareaForm4 = ({
    contacto,
    contrato,
    prefactibilidad,
    nextStep,
    previousStep,
    iniciarContrato,
    iniciarContacto,
    user
}) => {
    const { location: currentLocation, error: currentError } = useCurrentLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [mapLocation, setMapLocation] = useState(currentLocation);
    const [prefactibilidadLocation, setPrefactibilidadLocation] = useState(currentLocation);
    const [zonasDisponibles, setZonasDisponibles] = useState([]);
    const [planId, setPlanId] = useState(contrato?.planId);
    const [planes, setPlanes] = useState([]);

    const recuperar = (coord) => {
        const data = {
            coordenadas: `${coord.lat},${coord.lng}`,
        }
    }
    const onChange = (newCoordinates) => {
        recuperar(newCoordinates);
    }

    const onClickMap = (newCoord) => {
        setPrefactibilidadLocation(newCoord);
        setPlanId(null);
    }

    const onClickPlan = (plan) => {
        const { rango } = prefactibilidad;
        if(plan.anchoBajada <= rango[1] && rango[0]<=plan.anchoBajada){
            setPlanId(plan.id);
        }
    }
    const onSubmit = () => {
        setIsLoading(true);
        const form = {
            ...contacto,
            latitud: prefactibilidadLocation.lat,
            longitud: prefactibilidadLocation.lng,
        };
        ContactosService.update(form).then((response)=>{
            if(Number(contacto.tipoTareaId) === 3){
                form.motivoTareaObservaciones = TareaFuncion.armarMotivoTareaObservaciones(contacto.motivoTareaId,contrato)
            }
            iniciarContacto(form);
            iniciarContrato({
                ...contrato,
                planId: planId,
                latitud: prefactibilidadLocation.lat,
                longitud: prefactibilidadLocation.lng,
            });
            nextStep();
        })
        .catch((e)=>{})
        .finally(()=>{
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if(contacto){
            AsesorxLugaresService.getAll({
                lugarId:contacto.lugarId,
            }).then((response)=>{
                setZonasDisponibles(response);
            });
            if(contacto.coordenadas){
                setMapLocation(MapaFunction.gJsonToGoogleMaps(contacto.coordenadas)[0]);
                setPrefactibilidadLocation(MapaFunction.gJsonToGoogleMaps(contacto.coordenadas)[0]);
            } else if(contacto.lugar){
                setMapLocation(MapaFunction.gJsonToGoogleMaps(contacto.lugar.coordenadas)[0]);
                setPrefactibilidadLocation(MapaFunction.gJsonToGoogleMaps(contacto.lugar.coordenadas)[0]);
            }
        }
    }, [contacto,contrato]);

    useEffect(() => {
        PlanesService.getAll().then((response)=>{
            setPlanes(response);
        })
    }, [])

    return (
            <div className="container-fluid">
                <div className="row bg-white border shadow-sm rounded pl-lg-5 pl-md-0 p-md-0">
                                {/* MAPA */}
                    <div className="border mt-3 offset-1 col-10 ml-lg-0 offset-lg-0 col-sm-10 mt-md-5 col-lg-7 mt-lg-4 text-center">
                            <MapBase
                                id="mapaComercial"
                                options={{
                                    maxZoom: 19,
                                    minZoom: 14,
                                }}
                                zoom={15}
                                coordinates={mapLocation}
                                onChange={onChange}
                                onClick={onClickMap}
                            >
                            <BoxPrefactibilidad className="d-none d-lg-block" coordinates={prefactibilidadLocation} />
                            {
                                zonasDisponibles.map((zona)=>{
                                    return <Poligono item={zona} key={zona.id} />
                                })
                            }
                            </MapBase>
                    </div>
                                        {/* Planes */}
                    <div className="mt-5 col-12 mt-lg-4 col-lg-5 col-md-5 mt-md-0 text-center text-gray p-lg-3">
                        <div className="row">
                            <div className="col-12 text-center mb-lg-3 my-sm-5 my-lg-0 ">
                                <h3>
                                    <i className="fas fa-angle-down mr-2"></i>
                                    Planes Disponibles
                                </h3>
                            </div>
                            {
                                planes.map((plan)=>{
                                    return (
                                        <div className="col-6" key={plan.id}>
                                            <div
                                                id="planS"
                                                onClick={()=>onClickPlan(plan)}
                                                aria-hidden="true"
                                                className={`${planId===plan.id?'imagen-seleccionada':'imagen-no-seleccionada'}`}
                                            >

                                                <img
                                                    src={plan.foto_url?plan.foto_url:'/img/P_L.svg'}
                                                    alt="Plan"
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="col-12 mt-lg-4 text-right">
                        <div className="row">
                            <div className="col-12 my-4 my-lg-0 offset-md-8 col-md-4 py-lg-3 offset-lg-9 col-lg-3 float-right">
                                <Button className="btn-secondary" onClick={previousStep}>
                                    Anterior
                                </Button>
                                <Button
                                    disabled={planId === null || isLoading}
                                    onClick={onSubmit}
                                >
                                    Continuar
                                </Button>
                                <Link to="/contactos">
                                    <Button theme="default">
                                        Cancelar
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
    contrato: state.contactos.contrato,
    prefactibilidad: state.mapas.prefactibilidad,
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TareaForm4);