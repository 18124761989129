import React, {useState, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ContratoDetalleService from '@services/ContratoDetalle.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import FechaFunction from '@app/functions/FechaFunction';
import {showModal} from 'react-redux-modal-provider';
import {ModalConfirm, SelectEstadoEmail, SelectPersonal , SelectTipoEstado} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import { Link } from 'react-router-dom';


const DetallePage = ({user}) => {
    const dispatch = useDispatch();
    const {puesto} = user;
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [personaId, setPersonaId] = useState(user.puesto==="Asesor"? user.dni :null);
    const [rangeFecha, setRangeFecha] = useState();
    const [email, setEmail] = useState(false);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);


    const actualizar = async () => {
        const query = {
            asesorId: personaId,
           filtroMes: rangeFecha
        };
        const response = await ContratoDetalleService.getAll(query);
        // console.log(response)
        setItems(response);
        // console.log(rangeFecha)
    };

    const onGuardar = () => {
        actualizar();
    };

    const onClickExportar = () => {
        const query = {
            asesorId: personaId,
           filtroMes: rangeFecha
        };
        // if(sinCuadrillaLugar){
        //     query.sinCuadrillaLugar = sinCuadrillaLugar;
        // }
        // if(sinAsignar){
        //     query.sinAsignar = sinAsignar;
        // }
        // if(sinFecha){
        //     query.sinFecha = sinFecha;
        // }
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(ContratoDetalleService.exportar(query)).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }


    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Asesor',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                ContratoDetalleService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Fecha de Creación',
                accessor: 'contrato.createdAt',
                Filter:false,
                Cell: ({row}) => {
                    return FechaFunction.format(row.original.contrato.createdAt,'dd/MM/yyyy');
                },
            },
            {
                Header: 'Fecha de Instalación',
                accessor: 'contrato.fechaInstalacion',
                Filter:false,
                Cell: ({row}) => {
                    return FechaFunction.format(row.original.contrato.fechaInstalacion,'dd/MM/yyyy');
                },
            },
            {
                Header: 'Nombre y Apellido',
                accessor: 'contrato.contacto.nombreCompleto'
            },
            {
                Header: 'Localidad',
                accessor: 'contrato.lugarId.nombre'
            },
            {
                Header: 'Barrio',
                accessor: 'contrato.contacto.barrio'
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                ClassName: 'm-0',
                Width: 130,
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectTipoEstado
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.value) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
                Cell: ({row}) =>{
                    if(row.original.estado === "ACTIVO"){
                        return (
                            <div className="text-center">
                                <button type="button" className="btn btn-outline-success btn-xs">Activo</button>
                            </div>
                        );
                    }
                    if(row.original.estado === "VENCIDO"){
                        return (
                                <div className="text-center">
                                    <button type="button" className="btn btn-outline-warning btn-xs">Vencido</button>
                                </div>

                        );
                    }
                    if(row.original.estado === "PENDIENTE"){
                    return (
                        <div className="text-center">
                            <button type="button" className="btn btn-outline-primary btn-xs">Pendiente</button>
                        </div>
                    );
                    }
                    return (
                        <div className="text-center">
                            <button type="button" className="btn btn-outline-danger btn-xs">Baja</button>
                        </div>
                    );
                }
            },
            {
                Header: 'Email',
                accessor: 'contacto.envioEmail',
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectEstadoEmail
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(opt.value);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
                Cell: ({row}) => {
                        return(
                                    <button className={row.original.contacto.envioEmail? ("btn btn-sm btn-success") : ("btn btn-sm btn-warning")} type="button">
                                        <i className={row.original.contacto.envioEmail ? ("fas fa-check") : ("fas fa-file-upload")}></i>
                                    </button>
                            )
                }
            }
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, [personaId,rangeFecha,email]);


    return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-10 col-lg-3 order-1 order-lg-1">
                            <h3 className="mt-4">Detalles de tratos</h3>
                            {puesto === "Asesor" && (
                                <p>{`${user.apellido} ${user.nombre}`}</p>
                            )}
                        </div>
                        <div className={puesto !== "Asesor" ? ("col-lg-3 mt-lg-2 order-3 order-lg-2") : ("col-lg-1 mt-lg-2 order-3 order-lg-2")}>
                            {puesto !== "Asesor" && (
                                <div className="form-group">
                                     <p className="text-center">Buscar asesor</p>
                                    <SelectPersonal
                                        filtroAsesor
                                        id="personaId"
                                        onChange={(option)=>(option ? setPersonaId(option.value) : setPersonaId(null))}
                                        isClearable
                                        defaultValue={null}
                                    >
                                    </SelectPersonal>
                                </div>

                            )}

                        </div>
                        <div className="col-12 col-lg-3 mt-lg-2 order-3">
                            <div className="form-group">
                            <p className="text-center">Mes</p>
                            <input type="month" className="form-control" onChange={(option)=>(option.target.value ? setRangeFecha(option.target.value) : setRangeFecha(null))}></input>
                            </div>
                        </div>
                            <div className={puesto !== "Asesor"? ("col-2 offset-lg-2 col-lg-1 mt-3 mt-lg-3 order-2 order-lg-4") : ("d-none")}>
                                <button type="button" onClick={onClickExportar} className="btn btn-block btn-success">Excel</button>
                            </div>
                        <div className="col-12 order-4">
                            <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
                </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DetallePage);
