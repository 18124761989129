import React, { useEffect, useMemo, useRef, useState } from 'react'
import {Modal, Spinner, Tabs, Tab} from 'react-bootstrap';
import FlotaJugadoresService from '@app/services/flotaJugadores.service';
import { Scatter, Bar, defaults } from 'react-chartjs-2';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import {
    Button,
    CuadrillaDisponibleCard,
    InputCheck,
    ListCard,
    MapBase,
    MarkerTarea,
    TareaCard,
    JugadorCard,
    VehiculoCard,
} from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import TareasService from '@app/services/tareas.service';
import MapaFunction from '@app/functions/MapaFunction';
import { defaultCoordinates } from '@app/constants/geolocationOptions';

defaults.animation = false;

function ModalFlotaJugadorVer({
    show,
    hideModal,
    item,
}) {
    const [coordinates, setCoordinates] = useState(defaultCoordinates);
    const [key, setKey] = useState('cuadrillas');
    const [isLoading, setIsLoading] = useState(false);
    const [flota, setFlota] = useState(item);
    const [cuadrillasBubbleData, setCuadrillasBubbleData] = useState();
    const [jugadores, setJugadores] = useState([]);
    const [cuadrillas, setCuadrillas] = useState([]);
    const [tareas, setTareas] = useState([]);
    const [fechas, setFechas] = useState([]);
    const [fechasFiltradas, setFechasFiltradas] = useState([]);
    const cuadrillasBarRef = useRef();
    const handleClose = () => hideModal();

    const cuadrillasBubbleOptions = useMemo(() => {
        return {
            responsive: true,
            plugins:{
                legend: {
                    display: false,
                },
                /*
                tooltip: {
                    enabled: true,
                    callbacks: {
                        label: (context) => {
                            console.log(context);
                            return FechaFunction.format(context.label,'dd/MM');
                        }
                    }
                },
                */
            },
            scales: {
                y: {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
                x:{
                    stacked: true,
                    ticks: {
                        callback: (value, index, values) => {
                            return FechaFunction.format(cuadrillasBarRef.current.scales['x'].getLabelForValue(value),'dd/MM');
                        },
                    },
                },
            },
        }
    }, [])

    const onClickTareaMap = (tarea) => {
        setCoordinates(MapaFunction.gJsonToGoogleMaps(tarea.coordenadas)[0]);
    }

    const tareasList = useMemo(() => tareas.filter(t=>fechasFiltradas.indexOf(t.fecha)<0), [fechasFiltradas,tareas]);
    const [marks,tareasMark] = useMemo(() => [
        tareasList.filter(t=>t.coordenadas).map(t=>MapaFunction.gJsonToGoogleMaps(t.coordenadas)[0]),
        tareasList.filter(t=>t.coordenadas),
    ],[tareasList]);

    const actualizar = () => {
        setIsLoading(true);
        FlotaJugadoresService.getById(item.id).then((response)=>{
            setFlota(response);
            setCuadrillas(response.cuadrillas);
            
            const labels = new Set();
            response.cuadrillas.forEach((cl)=>{
                labels.add(cl.fecha);
            });
            const tmDataSet = Array.from(labels).map(()=>0);
            const ttDataSet = Array.from(labels).map(()=>0);
            
            Array.from(labels).forEach((label,index)=>{
                response.cuadrillas.filter((cl)=>cl.fecha === label).forEach(cl=>{
                    if(cl.costoMinimoTM){
                        tmDataSet[index] += cl.costoMinimoTM;
                    }
                    if(cl.costoMinimoTT){
                        ttDataSet[index] += cl.costoMinimoTT;
                    }
                })
            })

            const periodosDataSets = [
                {
                    label: 'Turno mañana',
                    data: tmDataSet,
                    backgroundColor: 'rgb(255, 99, 132)',
                },
                {
                    label: 'Turno tarde',
                    data: ttDataSet,
                    backgroundColor: 'rgb(54, 162, 235)',
                },
            ];
            setCuadrillasBubbleData({
                datasets: periodosDataSets,
                labels: Array.from(labels),
            });
            setJugadores(response.jugadores);
            setFechas(Array.from(labels));
        })
        .finally(()=>{
            setIsLoading(false);
        });
        TareasService.getAll({flotaId:item.id}).then((response)=>{
            setTareas(response);
        });
    }

    const onChangeSelectedFecha = (checked,fecha) => {
        if(!checked){
            setFechasFiltradas([...fechasFiltradas,fecha])
        } else {
            const aux = [...fechasFiltradas];
            const index = fechasFiltradas.indexOf(fecha);
            if(index>=0){
                aux.splice(index,1);
            }
            setFechasFiltradas(aux);
        }
    }

    

    useEffect(() => {
        actualizar();
        return () => {
            
        }
    }, []);

    return (
        <Modal show={show} size="lg" backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`#${item.id} FLOTA `}
                    {
                        isLoading && (
                            <Spinner animation="border" className="text-primary" />
                        )
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    id="flotasTab"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="jugadores" title="Jugadores">
                        <>
                            <div className="row">
                                <div className="col-12">
                                {
                                    jugadores.map((it)=>{
                                        return (
                                            <JugadorCard 
                                                key={it.dni} 
                                                item={it} 
                                                esConductor={it.dni===item.colaboradorIdConductor} 
                                            />
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </>
                    </Tab>
                    <Tab eventKey="vehiculo" title="Vehiculo">
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <VehiculoCard item={flota.vehiculo} />
                                </div>
                            </div>
                        </>
                    </Tab>
                    <Tab eventKey="cuadrillas" title="Cuadrillas">
                        <>
                            <Bar
                                ref={cuadrillasBarRef}
                                data={cuadrillasBubbleData}
                                options={cuadrillasBubbleOptions}
                            />
                            <div className="row">
                                <div className="col-12">
                                    <ListCard isLoading={isLoading}>
                                    {
                                        cuadrillas.map((it)=>{
                                            return (
                                            <CuadrillaDisponibleCard 
                                                key={it.id}
                                                item={it}
                                            />
                                            )
                                        })
                                    }
                                    </ListCard>
                                </div>
                            </div>
                        </>
                    </Tab>
                    <Tab eventKey="tareas" title="Tareas">
                        <>
                            <div className="row my-2">
                                {
                                    fechas.map((fecha,index)=>{
                                        return (
                                            <div className="col" key={`check-${index}`}>
                                                <Button
                                                    className="pt-2"
                                                    block
                                                    theme="light"
                                                >
                                                <InputCheck
                                                    className="mt-2"
                                                    label={FechaFunction.format(fecha,'dd/MM')}
                                                    value={fecha}
                                                    onChange={(value)=>onChangeSelectedFecha(value,fecha)}
                                                    checked={fechasFiltradas.indexOf(fecha)<0}
                                                />
                                                </Button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="border shadow-sm">
                                    <MapBase 
                                        id="mapaTareas"
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: 400
                                        }}
                                        isSearchable={false}
                                        coordinates={coordinates}
                                        options={{
                                            maxZoom: 19,
                                            minZoom: 7,
                                        }}
                                        zoom={15}
                                        marks={marks}
                                    > 
                                        {
                                            tareasMark.map(tarea=>{
                                                return <MarkerTarea item={tarea} key={tarea.id} />
                                            })
                                        }
                                    </MapBase>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ListCard isLoading={isLoading} defaultLength={9} wrapperClassname="row">
                                    {
                                        tareasList.map((it)=>{
                                            return (
                                                <div className="col-4 my-2" key={it.id}>
                                                    <TareaCard
                                                        item={it}
                                                        onClickMap={onClickTareaMap}
                                                    />
                                                </div>
                                            
                                            )
                                        })
                                    }
                                    </ListCard>
                                </div>
                            </div>
                        </>
                    </Tab>
                </Tabs>
                
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalFlotaJugadorVer
