import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import ZonasService from '@app/services/zonas.service';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({
    nombre: Yup.string().max(200).required(),
    nombreCorto: Yup.string().max(100).required(),
    color: Yup.string().nullable()
});

function ModalZonaEditar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id,
        };
        setIsLoading(true);
        if (form.id > 0) {
            ZonasService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            ZonasService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
    }, [register]);

    useEffect(() => {
        if (item.id > 0) {
            setValue('nombre', item.nombre);
            setValue('nombreCorto', item.nombreCorto);
            setValue('color', item.color);
        }
    }, []);
    
    return (
        
        
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {item.id > 0 ? 'Editar zona' : 'Registrar zona'}
                                </Modal.Title>
                            </Modal.Header>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <Modal.Body>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="nombre">
                                                    Nombre
                                                </label>
                                                <input
                                                    className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                                    id="nombre"
                                                    name="nombre"
                                                    type="text"
                                                    autoComplete="off"
                                                    ref={register} 
                                                />
                                                {errors.nombre && (
                                                    <div className="invalid-feedback">
                                                        {errors.nombre.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                                <div className="col-12">
                                                    <div className="row">
                                                
                                                            <div className="col-10">
                                                                <div className="form-group">
                                                                    <label
                                                                        className="label-control"
                                                                        htmlFor="nombreCorto"
                                                                    >
                                                                        Nombre Corto
                                                                    </label>
                                                                    <input
                                                                        className={`form-control ${errors.nombreCorto? 'is-invalid': ''}`}
                                                                        id="nombreCorto"
                                                                        name="nombreCorto"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        ref={register} 
                                                                    />
                                                                    {errors.nombreCorto && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.nombreCorto.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                                    
                                                            <div className="col-2">
                                                                <div className="form-group">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="color"
                                                                    >
                                                                        Color
                                                                    </label>
                                                                    <input
                                                                        // className={`form-control form-control-color ${errors.color? 'is-invalid': ''}`}
                                                                        className="form-control-color rounded border border-ligth bg-white w-100"
                                                                        title="Elegi un color"
                                                                        id="color"
                                                                        name="color"
                                                                        type="color"
                                                                        ref={register} 
                                                                    />
                                                                    {errors.color && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.color.message}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                    </div>                                            
                                                </div>
                                    </form>
                            </Modal.Body>
                                        <div className="col-12">
                                            <Modal.Footer>
                                                <Button theme="default" onClick={handleClose}>
                                                    CANCELAR
                                                </Button>
                                                <Button
                                                    theme="primary"
                                                    onClick={handleSubmit(onSubmit)}
                                                    isLoading={isLoading}
                                                >
                                                    GUARDAR
                                                </Button>
                                            </Modal.Footer>
                                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

ModalZonaEditar.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
        nombre: PropTypes.string,
        nombreCorto: PropTypes.string,
        color: PropTypes.string,
    })
};

ModalZonaEditar.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
        nombreCorto: '',
        color: '#FFFFFF',
    }
};

export default ModalZonaEditar;
