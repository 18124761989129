import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import AsesorxLugaresService from '@services/asesorxLugares.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm, SelectLugar} from '@app/components/index';
import ModalAsesorxLugar from '@app/modals/asesor-lugar/ModalAsesorxLugar';
import ModalZonaAsesor from '@app/modals/asesor-lugar/ModalZonaAsesor';

const AsesorxLugarTablePage = () => {
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [zona, setZona] = useState([]);

    const actualizar = async () => {
        const response = await AsesorxLugaresService.getAll();
        setItems(response);
        // console.log(response)
    };


    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Asesor',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                AsesorxLugaresService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const verZona = (id,nombreCompleto,{coordinates},poliasesor) =>{

        showModal(ModalZonaAsesor,{id,nombreCompleto,coordinates,poliasesor});
    }


    const columns = React.useMemo(
        () => [
            {
                Header: 'Personal',
                accessor: 'personal.nombreCompleto',
                Cell: ({row}) =>{
                        return row.original.personal.nombreCompleto;
                }
            },
            {
                Width:200,
                Header: 'DNI',
                accessor: 'dni',
                Cell: ({row}) =>{
                        return row.original.dni;
                }
            },
            {
                Width:200,
                Header: 'Lugar',
                accessor: 'lugar',
                Cell: ({row}) =>{
                        return row.original.lugar.nombre  ;
                }
            },
            {
                Width: 200,
                Header: 'Estado',
                accessor: 'isDeleted',
                Cell: ({row}) =>{
                    if(row.original.isDeleted === false){
                        return (<div className="badge badge-success"><span><b>ACTIVO</b></span></div>
                                );
                    }
                    return (<div className="badge badge-danger"><span><b>INACTIVO</b></span></div> );
                }
            },
            {
                Header: 'Zona',
                accessor: 'zona',
                Filter:false,
                Cell: ({row}) =>{
                        return(
                            <button type="button" className="btn btn-sm btn-primary" onClick={()=>verZona(row.original.id,row.original.personal.nombreCompleto, row.original.aristas,row.original)}>
                                <i className="fas fa-street-view" />
                            </button>
                        );
                }
            },
            {
                Width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: false,
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
            // {
            //     Header: 'Localidad',
            //     ClassName: 'm-0',
            //     accessor: 'lugar.nombre',
            //     Cell: ({row}) =>{
            //             return row.original.lugar.nombre;
            //     },
            //     Filter: ({
            //         column: {filterValue, setFilter, preFilteredRows, id}
            //     }) => {
            //         return (
            //             <SelectLugar
            //                 onChange={(opt) => {
            //                     if (opt) {
            //                         setFilter(String(opt.label) || undefined);
            //                     } else {
            //                         setFilter('');
            //                     }
            //                 }}
            //                 isClearable
            //             />
            //         );
            //     },
            // },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8 col-md-9 col-lg-9 my-3">
                            <h3>Lugares Segun Asesor</h3>
                        </div>
                        <div className="offset-1 offset-md-0 col-3 col-md-3 offset-lg-1 col-lg-2 my-3">
                        <Link to="/asesores/lugares/nuevo">
                            <Button color="primary" className="btn-block">
                                NUEVO
                            </Button>
                        </Link>
                        </div>
                        <div className="col-12">
                            <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
                </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AsesorxLugarTablePage);
