import React, { useEffect, useMemo, useState, ReactElement } from 'react'
import { Button } from '@app/components/index';
import styled from 'styled-components';
import {filter, deepMap} from 'react-children-utilities';

const Styles = styled.div`
    .wrapper {
        
    }
    
    .container {
        will-change: width, height;
    }
    
    .item {
        will-change: transform, opacity;
    }
`;

function ListCard({
    children,
    defaultLength,
    isLoading,
    wrapperClassname,
}) {
    const [length, setLength] = useState(defaultLength);
    const count = useMemo(() => React.Children.count(children), [children]);
    
    const displayItems = useMemo(() => filter(children,(item,i)=>i<length), [children,length]);
    
    useEffect(() => {
        setLength(defaultLength);
        return () => {
            
        }
    }, [children])

    return (
        <Styles>
            <div className={`wrapper ${wrapperClassname}`}>
                {displayItems}
            </div>
            {
                count >= defaultLength && length < count && (
                    <div className="row">
                        <div className="col-12 mt-3">
                            <Button onClick={()=>setLength(length+defaultLength)}>
                                Mostrar mas
                            </Button>
                        </div>
                    </div>
                )
            }
            
        </Styles>
    )
}

ListCard.defaultProps = {
    isLoading: false,
    defaultLength: 6,
    wrapperClassname: '',
}

export default ListCard
