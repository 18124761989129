import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button, SelectPersonal} from '@app/components/index';
import {useForm, Controller} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import CamposVisiblesService from '@app/services/coorporativo/camposVisibles.service';
import Tabs from '@app/../node_modules/react-bootstrap/esm/Tabs';
import Tab from '@app/../node_modules/react-bootstrap/esm/Tab';
import {connect} from 'react-redux';
import usePermisos from '@app/hooks/usePermisos';

const schema = Yup.object({

});

function ModalPermisosEditar({show, hideModal, item, onGuardar,user}) {
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [todosDatosClientes, setTodosDatosClientes] = useState(false)
    const [todosPlataforma, setTodosPlataforma] = useState(false)
    const [todosAdministracion, setTodosAdministracion] = useState(false)
    const [todosInformacionTecnica, setTodosInformacionTecnica] = useState(false)


    const [persona, setPersona] = useState()
    
    // PERMISOS LECTURA

    // DATOS CLIENTE

    const [clienteLectura, setClienteLectura] = useState(JSON.parse(item.cliente_cv)[0].visible)
    const [servicioIdLectura, setServicioIdLectura] = useState(JSON.parse(item.servicioId_cv)[0].visible)
    const [localidadLectura, setLocalidadLectura] = useState(JSON.parse(item.localidad_cv)[0].visible)
    const [servicioVigenteLectura, setServicioVigenteLectura] = useState(JSON.parse(item.servicioVigente_cv)[0].visible)
    const [segmentosLectura, setSegmentosLectura] = useState(JSON.parse(item.segmento_cv)[0].visible)
    const [servicioLectura, setServicioLectura] = useState(JSON.parse(item.servicio_cv)[0].visible)
    const [detalleServicioLectura, setDetalleServicioLectura] = useState(JSON.parse(item.detalleServicio_cv)[0].visible)
    const [categoriaLectura, setCategoriaLectura] = useState(JSON.parse(item.categoria_cv)[0].visible)
    const [puntoLectura, setPuntoLectura] = useState(JSON.parse(item.punto_cv)[0].visible)

    // PLATAFORMA

    const [requerimientosLectura, setRequerimientosLectura] = useState(JSON.parse(item.requerimiento_cv)[0].visible)
    const [plataformaLectura, setPlataformaLectura] = useState(JSON.parse(item.plataforma_cv)[0].visible)
    const [linkLectura, setLinkLectura] = useState(JSON.parse(item.link_cv)[0].visible)
    const [claveLectura, setClaveLectura] = useState(JSON.parse(item.clave_cv)[0].visible)

    // ADMINISTRACION

    const [idFactLectura, setIdFactLectura] = useState(JSON.parse(item.facturaId_cv)[0].visible)
    const [cuitLectura, setCuitLectura] = useState(JSON.parse(item.cuit_cv)[0].visible)
    const [domicilioFiscalLectura, setDomicilioFiscalLectura] = useState(JSON.parse(item.domicilioFiscal_cv)[0].visible)
    const [mailFacturacionLectura, setMailFacturacionLectura] = useState(JSON.parse(item.emailFacturacion_cv)[0].visible)
    const [contactosAdministrativosLectura, setContactosAdministrativosLectura] = useState(JSON.parse(item.contactoAdministrativo_cv)[0].visible)
    const [fechaFinContratoLectura, setFechaFinContratoLectura] = useState(JSON.parse(item.fecha_cv)[0].visible)
    const [costoMbLectura, setCostoMbLectura] = useState(JSON.parse(item.costoMega_cv)[0].visible)
    const [costoInstalacionLectura, setCostoInstalacionLectura] = useState(JSON.parse(item.costoInstalacion_cv)[0].visible)
    const [contratoFirmadoLectura, setContratoFirmadoLectura] = useState(JSON.parse(item.contratoFirmado_cv)[0].visible)
    const [ordenDeCompraLectura, setOrdenDeCompraLectura] = useState(JSON.parse(item.ordenCompra_cv)[0].visible)
    const [contratoCertificadoLectura, setContratoCertificadoLectura] = useState(JSON.parse(item.contratoCertificado_cv)[0].visible)
    const [agendaLectura, setAgendaLectura] = useState(JSON.parse(item.agenda_cv)[0].visible)
    const [estatutoEmpresaLectura, setEstatutoEmpresaLectura] = useState(JSON.parse(item.estatutoEmpresa_cv)[0].visible)
    const [designacionActaLectura, setDesignacionActaLectura] = useState(JSON.parse(item.designacionActaActualizada_cv)[0].visible)
    const [contratoSocialLectura, setContratoSocialLectura] = useState(JSON.parse(item.contratoSocial_cv)[0].visible)
    const [poderLectura, setPoderLectura] = useState(JSON.parse(item.poderNombreFirma_cv)[0].visible)
    const [copiaDniLectura, setCopiaDniLectura] = useState(JSON.parse(item.copiaDni_cv)[0].visible)
    const [constanciaAfipLectura, setConstanciaAfipLectura] = useState(JSON.parse(item.constanciaAfip_cv)[0].visible)
    const [constaciaRentaLectura, setConstaciaRentaLectura] = useState(JSON.parse(item.constanciaRenta_cv)[0].visible)
    const [pagoAlDiaLectura, setPagoAlDiaLectura] = useState(JSON.parse(item.pagoAlDia_cv)[0].visible)
    const [observacionesLectura, setObservacionesLectura] = useState(JSON.parse(item.observacionFacturacion_cv)[0].visible)

    // INFORMACION TECNICA

    const [contactoTecnicoLectura, setContactoTecnicoLectura] = useState(JSON.parse(item.contactoTecnico_cv)[0].visible)
    const [fechaInstalacionLectura, setFechaInstalacionLectura] = useState(JSON.parse(item.fechaInstalacion_cv)[0].visible)
    const [fechaAltaLectura, setFechaAltaLectura] = useState(JSON.parse(item.fechaAlta_cv)[0].visible)
    const [fechaBajaLectura, setFechaBajaLectura] = useState(JSON.parse(item.fechaBaja_cv)[0].visible)
    const [latitudLectura, setLatitudLectura] = useState(JSON.parse(item.latitud_cv)[0].visible)
    const [longitudLectura, setLongitudLectura] = useState(JSON.parse(item.longitud_cv)[0].visible)
    const [mbSubidaLectura, setMbSubidaLectura] = useState(JSON.parse(item.mbSubida_cv)[0].visible)
    const [mbBajadaLectura, setMbBajadaLectura] = useState(JSON.parse(item.mbBajada_cv)[0].visible)
    const [cantidadIpPublicaLectura, setCantidadIpPublicaLectura] = useState(JSON.parse(item.cantidadIpPublica_cv)[0].visible)
    const [numeroIpPublicaLectura, setNumeroIpPublicaLectura] = useState(JSON.parse(item.numeroIpPublica_cv)[0].visible)
    const [tkAbiertosLectura, setTkAbiertosLectura] = useState(JSON.parse(item.tkAbierto_cv)[0].visible)
    const [observacionesTecnicaLectura, setObservacionesTecnicaLectura] = useState(JSON.parse(item.observacionTecnica_cv)[0].visible)


    // PERMISOS MODIFICACION

    // DATOS CLIENTE

    const [clienteModificacion, setClienteModificacion] = useState(JSON.parse(item.cliente_cv)[1].modificar)
    const [servicioIdModificacion, setServicioIdModificacion] = useState(JSON.parse(item.servicioId_cv)[1].modificar)
    const [localidadModificacion, setLocalidadModificacion] = useState(JSON.parse(item.localidad_cv)[1].modificar)
    const [servicioVigenteModificacion, setServicioVigenteModificacion] = useState(JSON.parse(item.servicioVigente_cv)[1].modificar)
    const [segmentosModificacion, setSegmentosModificacion] = useState(JSON.parse(item.segmento_cv)[1].modificar)
    const [servicioModificacion, setServicioModificacion] = useState(JSON.parse(item.servicio_cv)[1].modificar)
    const [detalleServicioModificacion, setDetalleServicioModificacion] = useState(JSON.parse(item.detalleServicio_cv)[1].modificar)
    const [categoriaModificacion, setCategoriaModificacion] = useState(JSON.parse(item.categoria_cv)[1].modificar)
    const [puntoModificacion, setPuntoModificacion] = useState(JSON.parse(item.punto_cv)[1].modificar)

    // PLATAFORMA

    const [requerimientosModificacion, setRequerimientosModificacion] = useState(JSON.parse(item.requerimiento_cv)[1].modificar)
    const [plataformaModificacion, setPlataformaModificacion] = useState(JSON.parse(item.plataforma_cv)[1].modificar)
    const [linkModificacion, setLinkModificacion] = useState(JSON.parse(item.link_cv)[1].modificar)
    const [claveModificacion, setClaveModificacion] = useState(JSON.parse(item.clave_cv)[1].modificar)


    // ADMINISTRACION

    const [idFactModificacion, setIdFactModificacion] = useState(JSON.parse(item.facturaId_cv)[1].modificar)
    const [cuitModificacion, setCuitModificacion] = useState(JSON.parse(item.cuit_cv)[1].modificar)
    const [domicilioFiscalModificacion, setDomicilioFiscalModificacion] = useState(JSON.parse(item.domicilioFiscal_cv)[1].modificar)
    const [mailFacturacionModificacion, setMailFacturacionModificacion] = useState(JSON.parse(item.emailFacturacion_cv)[1].modificar)
    const [contactosAdministrativosModificacion, setContactosAdministrativosModificacion] = useState(JSON.parse(item.contactoAdministrativo_cv)[1].modificar)
    const [fechaFinContratoModificacion, setFechaFinContratoModificacion] = useState(JSON.parse(item.fecha_cv)[1].modificar)
    const [costoMbModificacion, setCostoMbModificacion] = useState(JSON.parse(item.costoMega_cv)[1].modificar)
    const [costoInstalacionModificacion, setCostoInstalacionModificacion] = useState(JSON.parse(item.costoInstalacion_cv)[1].modificar)
    const [contratoFirmadoModificacion, setContratoFirmadoModificacion] = useState(JSON.parse(item.contratoFirmado_cv)[1].modificar)
    const [ordenDeCompraModificacion, setOrdenDeCompraModificacion] = useState(JSON.parse(item.ordenCompra_cv)[1].modificar)
    const [contratoCertificadoModificacion, setContratoCertificadoModificacion] = useState(JSON.parse(item.contratoCertificado_cv)[1].modificar)
    const [agendaModificacion, setAgendaModificacion] = useState(JSON.parse(item.agenda_cv)[1].modificar)
    const [estatutoEmpresaModificacion, setEstatutoEmpresaModificacion] = useState(JSON.parse(item.estatutoEmpresa_cv)[1].modificar)
    const [designacionActaModificacion, setDesignacionActaModificacion] = useState(JSON.parse(item.designacionActaActualizada_cv)[1].modificar)
    const [contratoSocialModificacion, setContratoSocialModificacion] = useState(JSON.parse(item.contratoSocial_cv)[1].modificar)
    const [poderModificacion, setPoderModificacion] = useState(JSON.parse(item.poderNombreFirma_cv)[1].modificar)
    const [copiaDniModificacion, setCopiaDniModificacion] = useState(JSON.parse(item.copiaDni_cv)[1].modificar)
    const [constanciaAfipModificacion, setConstanciaAfipModificacion] = useState(JSON.parse(item.constanciaAfip_cv)[1].modificar)
    const [constaciaRentaModificacion, setConstaciaRentaModificacion] = useState(JSON.parse(item.constanciaRenta_cv)[1].modificar)
    const [pagoAlDiaModificacion, setPagoAlDiaModificacion] = useState(JSON.parse(item.pagoAlDia_cv)[1].modificar)
    const [observacionesModificacion, setObservacionesModificacion] = useState(JSON.parse(item.observacionFacturacion_cv)[1].modificar)

    // INFORMACION TECNICA

    const [contactoTecnicoModificacion, setContactoTecnicoModificacion] = useState(JSON.parse(item.contactoTecnico_cv)[1].modificar)
    const [fechaInstalacionModificacion, setFechaInstalacionModificacion] = useState(JSON.parse(item.fechaInstalacion_cv)[1].modificar)
    const [fechaAltaModificacion, setFechaAltaModificacion] = useState(JSON.parse(item.fechaAlta_cv)[1].modificar)
    const [fechaBajaModificacion, setFechaBajaModificacion] = useState(JSON.parse(item.fechaBaja_cv)[1].modificar)
    const [latitudModificacion, setLatitudModificacion] = useState(JSON.parse(item.latitud_cv)[1].modificar)
    const [longitudModificacion, setLongitudModificacion] = useState(JSON.parse(item.longitud_cv)[1].modificar)
    const [mbSubidaModificacion, setMbSubidaModificacion] = useState(JSON.parse(item.mbSubida_cv)[1].modificar)
    const [mbBajadaModificacion, setMbBajadaModificacion] = useState(JSON.parse(item.mbBajada_cv)[1].modificar)
    const [cantidadIpPublicaModificacion, setCantidadIpPublicaModificacion] = useState(JSON.parse(item.cantidadIpPublica_cv)[1].modificar)
    const [numeroIpPublicaModificacion, setNumeroIpPublicaModificacion] = useState(JSON.parse(item.numeroIpPublica_cv)[1].modificar)
    const [tkAbiertosModificacion, setTkAbiertosModificacion] = useState(JSON.parse(item.tkAbierto_cv)[1].modificar)
    const [observacionesTecnicaModificacion, setObservacionesTecnicaModificacion] = useState(JSON.parse(item.observacionTecnica_cv)[1].modificar)


    const {register, handleSubmit, errors, control, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });


    const onSubmit = () => {
        // setIsLoading(true);

        const form = {

          id_cv:item.id_cv,
          cliente_cv:JSON.stringify([{visible:clienteLectura},{modificar:clienteModificacion}]),
          servicioId_cv:JSON.stringify([{visible:servicioIdLectura},{modificar:servicioIdModificacion}]),
          localidad_cv:JSON.stringify([{visible:localidadLectura},{modificar:localidadModificacion}]),
          servicioVigente_cv:JSON.stringify([{visible:servicioVigenteLectura},{modificar:servicioVigenteModificacion}]),
          segmento_cv:JSON.stringify([{visible:segmentosLectura},{modificar:segmentosModificacion}]),
          servicio_cv:JSON.stringify([{visible:servicioLectura},{modificar:servicioModificacion}]),
          detalleServicio_cv:JSON.stringify([{visible:detalleServicioLectura},{modificar:detalleServicioModificacion}]),
          categoria_cv:JSON.stringify([{visible:categoriaLectura},{modificar:categoriaModificacion}]),
          punto_cv:JSON.stringify([{visible:puntoLectura},{modificar:puntoModificacion}]),


          requerimiento_cv:JSON.stringify([{visible:requerimientosLectura},{modificar:requerimientosModificacion}]),
          plataforma_cv:JSON.stringify([{visible:plataformaLectura},{modificar:plataformaModificacion}]),
          link_cv:JSON.stringify([{visible:linkLectura},{modoficar:linkModificacion}]),
          clave_cv:JSON.stringify([{visible:claveLectura},{modoficar:claveModificacion}]),


          facturaId_cv:JSON.stringify([{visible:idFactLectura},{modificar:idFactModificacion}]),
          cuit_cv:JSON.stringify([{visible:cuitLectura},{modificar:cuitModificacion}]),
          domicilioFiscal_cv:JSON.stringify([{visible:domicilioFiscalLectura},{modificar:domicilioFiscalModificacion}]),
          emailFacturacion_cv:JSON.stringify([{visible:mailFacturacionLectura},{modificar:mailFacturacionModificacion}]),
          contactoAdministrativo_cv:JSON.stringify([{visible:contactosAdministrativosLectura},{modificar:contactosAdministrativosModificacion}]),
          fecha_cv:JSON.stringify([{visible:fechaFinContratoLectura},{modificar:fechaFinContratoModificacion}]),
          costoMega_cv:JSON.stringify([{visible:costoMbLectura},{modificar:costoMbModificacion}]),
          costoInstalacion_cv:JSON.stringify([{visible:costoInstalacionLectura},{modificar:costoInstalacionModificacion}]),
          contratoFirmado_cv:JSON.stringify([{visible:contratoFirmadoLectura},{modificar:contratoCertificadoModificacion}]),
          ordenCompra_cv:JSON.stringify([{visible:ordenDeCompraLectura},{modificar:ordenDeCompraModificacion}]),
          contratoCertificado_cv:JSON.stringify([{visible:contratoCertificadoLectura}, {modificar:contratoCertificadoModificacion}]),
          agenda_cv:JSON.stringify([{visible:agendaLectura},{modificar:agendaModificacion}]),
          estatutoEmpresa_cv:JSON.stringify([{visible:estatutoEmpresaLectura},{modificar:estatutoEmpresaModificacion}]),
          designacionActaActualizada_cv:JSON.stringify([{visible:designacionActaLectura},{modificar:designacionActaModificacion}]),
          contratoSocial_cv:JSON.stringify([{visible:contratoSocialLectura},{modificar:contratoSocialModificacion}]),
          poderNombreFirma_cv:JSON.stringify([{visible:poderLectura},{modificar:poderModificacion}]),
          copiaDni_cv:JSON.stringify([{visible:copiaDniLectura},{modificar:copiaDniModificacion}]),
          constanciaAfip_cv:JSON.stringify([{visible:constanciaAfipLectura},{modificar:constanciaAfipModificacion}]),
          constanciaRenta_cv:JSON.stringify([{visible:constaciaRentaLectura},{modificar:constaciaRentaModificacion}]),
          pagoAlDia_cv:JSON.stringify([{visible:pagoAlDiaLectura},{modificar:pagoAlDiaModificacion}]),
          observacionFacturacion_cv:JSON.stringify([{visible:observacionesLectura},{modificar:observacionesModificacion}]),


          contactoTecnico_cv:JSON.stringify([{visible:contactoTecnicoLectura},{modificar:contactoTecnicoModificacion}]),
          fechaInstalacion_cv:JSON.stringify([{visible:fechaInstalacionLectura},{modificar:fechaInstalacionModificacion}]),
          fechaAlta_cv:JSON.stringify([{visible:fechaAltaLectura},{modificar:fechaAltaModificacion}]),
          fechaBaja_cv:JSON.stringify([{visible:fechaBajaLectura},{modificar:fechaBajaModificacion}]),
          latitud_cv:JSON.stringify([{visible:latitudLectura},{modificar:latitudModificacion}]),
          longitud_cv:JSON.stringify([{visible:longitudLectura}, {modificar:longitudModificacion}]),
          mbSubida_cv:JSON.stringify([{visible:mbSubidaLectura},{modificar:mbSubidaModificacion}]),
          mbBajada_cv:JSON.stringify([{visible:mbBajadaLectura}, {modificar:mbBajadaModificacion}]),
          cantidadIpPublica_cv:JSON.stringify([{visible:cantidadIpPublicaLectura},{modificar:cantidadIpPublicaModificacion}]),
          numeroIpPublica_cv:JSON.stringify([{visible:numeroIpPublicaLectura},{modificar:numeroIpPublicaModificacion}]),
          tkAbierto_cv:JSON.stringify([{visible:tkAbiertosLectura},{modificar:tkAbiertosModificacion}]),
          observacionTecnica_cv:JSON.stringify([{visible:observacionesTecnicaLectura},{modificar:observacionesTecnicaModificacion}]),
        };


        CamposVisiblesService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    // SETEAR TODOS LOS CHECK DATOS CLIENTES
    useEffect(() => {
      if(todosDatosClientes){
        setClienteLectura(true)
        setClienteModificacion(true)
        setServicioIdLectura(true)
        setServicioIdModificacion(true)
        setLocalidadLectura(true)
        setLocalidadModificacion(true)
        setServicioVigenteLectura(true)
        setServicioVigenteModificacion(true)
        setSegmentosLectura(true)
        setSegmentosModificacion(true)
        setServicioLectura(true)
        setServicioModificacion(true)
        setDetalleServicioLectura(true)
        setDetalleServicioModificacion(true)
        setCategoriaLectura(true)
        setCategoriaModificacion(true)
        setPuntoLectura(true)
        setPuntoModificacion(true)
      }
    }, [todosDatosClientes])

    // SETEAR TODOS LOS CHECK PLATAFORMA
    
    useEffect(() => {
      if(todosPlataforma){
      setRequerimientosLectura(true)
      setRequerimientosModificacion(true)
      setPlataformaLectura(true)
      setPlataformaModificacion(true)
      setLinkLectura(true)
      setLinkModificacion(true)
      setClaveLectura(true)
      setClaveModificacion(true)
      }
    }, [todosPlataforma])
    
    // SETEAR TODOS LOS CHECK ADMINISTRACION

    useEffect(() => {
      if(todosAdministracion){
      setIdFactLectura(true)
      setIdFactModificacion(true)
      setCuitLectura(true)
      setCuitModificacion(true)
      setDomicilioFiscalLectura(true)
      setDomicilioFiscalModificacion(true)
      setMailFacturacionLectura(true)
      setMailFacturacionModificacion(true)
      setContactosAdministrativosLectura(true)
      setContactosAdministrativosModificacion(true)
      setFechaFinContratoLectura(true)
      setFechaFinContratoModificacion(true)
      setCostoMbLectura(true)
      setCostoMbModificacion(true)
      setCostoInstalacionLectura(true)
      setCostoInstalacionModificacion(true)
      setContratoFirmadoLectura(true)
      setContratoFirmadoModificacion(true)
      setOrdenDeCompraLectura(true)
      setOrdenDeCompraModificacion(true)
      setContratoCertificadoLectura(true)
      setContratoCertificadoModificacion(true)
      setAgendaLectura(true)
      setAgendaModificacion(true)
      setEstatutoEmpresaLectura(true)
      setEstatutoEmpresaModificacion(true)
      setDesignacionActaLectura(true)
      setDesignacionActaModificacion(true)
      setContratoSocialLectura(true)
      setContratoSocialModificacion(true)
      setPoderLectura(true)
      setPoderModificacion(true)
      setCopiaDniLectura(true)
      setCopiaDniModificacion(true)
      setConstanciaAfipLectura(true)
      setConstanciaAfipModificacion(true)
      setConstaciaRentaLectura(true)
      setConstaciaRentaModificacion(true)
      setPagoAlDiaLectura(true)
      setPagoAlDiaModificacion(true)
      setObservacionesLectura(true)
      setObservacionesModificacion(true)
    }

    }, [todosAdministracion])

    // SETEAR TODOS LOS CHECK INFORMACION TECNICA

    useEffect(() => {
      if(todosInformacionTecnica){
        setContactoTecnicoLectura(true)
        setContactoTecnicoModificacion(true)
        setFechaInstalacionLectura(true)
        setFechaInstalacionModificacion(true)
        setFechaAltaLectura(true)
        setFechaAltaModificacion(true)
        setFechaBajaLectura(true)
        setFechaBajaModificacion(true)
        setLatitudLectura(true)
        setLatitudModificacion(true)
        setLongitudLectura(true)
        setLongitudModificacion(true)
        setMbSubidaLectura(true)
        setMbSubidaModificacion(true)
        setMbBajadaLectura(true)
        setMbBajadaModificacion(true)
        setCantidadIpPublicaLectura(true)
        setCantidadIpPublicaModificacion(true)
        setNumeroIpPublicaLectura(true)
        setNumeroIpPublicaModificacion(true)
        setTkAbiertosLectura(true)
        setTkAbiertosModificacion(true)
        setObservacionesTecnicaLectura(true)
        setObservacionesTecnicaModificacion(true)
      }
    }, [todosInformacionTecnica])


    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <div className='container-fluid'>
                <div className='row mt-2'>
                  <div className='col-2'>
                      <h4>
                      EDITAR ROL:
                      </h4>
                  </div>
                  <div className='offset-2 col-4 text-center'>
                    <h4>
                      {item.rol.nombre_r}
                    </h4>
                  </div>
                </div>
              </div>          
            </Modal.Header>
            <Modal.Body>
                <Container>
                                    <Tabs
                                        id="contactosTab"
                                        // activeKey={key}
                                        // onSelect={(k) => setKey(k)}
                                    >
                                      {/* DATOS CLIENTES */}
                                      
                                        <Tab 
                                                eventKey="datosClientes" 
                                                title={(
                                                  <div className='row'>
                                                      <div className='col-10'>
                                                        <span>Datos Clientes</span>
                                                      </div>
                                                      <div className='col-2'>
                                                          <button
                                                              title="Seleccionar todos"
                                                              className={todosDatosClientes === false ? ('btn btn-xs btn-outline-primary') : ('btn btn-xs btn-primary')}
                                                              type="button"
                                                              onClick={()=>setTodosDatosClientes(true)}
                                                              disabled={clienteLectura && clienteModificacion && servicioIdLectura && servicioIdModificacion && localidadLectura && localidadModificacion && servicioVigenteLectura && servicioVigenteModificacion && segmentosLectura && segmentosModificacion && servicioLectura && servicioModificacion && detalleServicioLectura && detalleServicioModificacion && categoriaLectura && categoriaModificacion && puntoLectura && puntoModificacion}

                                                          >
                                                            <i className="fas fa-check" />
                                                          </button>
                                                      </div>
                                                  </div>
                                                )}
                                                tabClassName="card-body"
                                        >
                              

                                                  <table className="table table-bordered shadow-sm">
                                                    <thead>
                                                      <tr>
                                                        <th scope="col">Campo</th>
                                                        <th scope="col">Visible</th>
                                                        <th scope="col">Modificar</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <th scope="row">Cliente</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={clienteLectura !== false} onChange={()=>{setClienteLectura(!clienteLectura); setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={clienteLectura === false} checked={clienteModificacion !== false} onChange={()=>{setClienteModificacion(!clienteModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Id Servicio</th>
                                                        <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={servicioIdLectura !== false} onChange={()=>{setServicioIdLectura(!servicioIdLectura); setTodosDatosClientes(false)}} />
                                                        </td>
                                                        <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={servicioIdLectura === false} checked={servicioIdModificacion !== false} onChange={()=>{setServicioIdModificacion(!servicioIdModificacion); setTodosDatosClientes(false)}} />
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Punto</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={puntoLectura !== false} onChange={()=>{setPuntoLectura(!puntoLectura);; setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={puntoLectura === false} checked={puntoModificacion !== false} onChange={()=>{setPuntoModificacion(!puntoModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Localidad</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={localidadLectura !== false} onChange={()=>{setLocalidadLectura(!localidadLectura); setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={localidadLectura === false} checked={localidadModificacion !== false} onChange={()=>{setLocalidadModificacion(!localidadModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Servicio Vigente</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={servicioVigenteLectura !== false} onChange={()=>{setServicioVigenteLectura(!servicioVigenteLectura); setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={servicioVigenteLectura === false} checked={servicioVigenteModificacion !== false} onChange={()=>{setServicioVigenteModificacion(!servicioVigenteModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Segmentos</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={segmentosLectura !== false} onChange={()=>{setSegmentosLectura(!segmentosLectura); setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={segmentosLectura === false} checked={segmentosModificacion !== false} onChange={()=>{setSegmentosModificacion(!segmentosModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Servicio</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={servicioLectura !== false} onChange={()=>{setServicioLectura(!servicioLectura); setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={servicioLectura === false} checked={servicioModificacion !== false} onChange={()=>{setServicioModificacion(!servicioModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Det de Servicio</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={detalleServicioLectura !== false} onChange={()=>{setDetalleServicioLectura(!detalleServicioLectura); setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={detalleServicioLectura === false} checked={detalleServicioModificacion !== false} onChange={()=>{setDetalleServicioModificacion(!detalleServicioModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                        <th scope="row">Categoria</th>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={categoriaLectura !== false} onChange={()=>{setCategoriaLectura(!categoriaLectura); setTodosDatosClientes(false)}} />
                                                          </td>
                                                          <td>
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={categoriaLectura === false} checked={categoriaModificacion !== false} onChange={()=>{setCategoriaModificacion(!categoriaModificacion); setTodosDatosClientes(false)}} />
                                                          </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>

                                        </Tab>

                                      {/* FIN DATOS CLIENTES */}

                                      {/* PLATAFORMA */}

                                      
                                        <Tab
                                            eventKey="tareas"
                                            title={(
                                              <div className='row'>
                                                  <div className='col-10'>
                                                    <span>Plataforma</span>
                                                  </div>
                                                  <div className='col-2'>
                                                  <button
                                                          title="Seleccionar todos"
                                                          className={todosPlataforma === false ? ('btn btn-xs btn-outline-primary') : ('btn btn-xs btn-primary')}
                                                          type="button"
                                                          onClick={()=>setTodosPlataforma(true)}
                                                          disabled={requerimientosLectura && requerimientosModificacion && plataformaLectura && plataformaModificacion && linkLectura && linkModificacion && claveLectura && claveModificacion}
                                                  >
                                                        <i className="fas fa-check" />
                                                  </button>
                                                  </div>
                                              </div>
                                            )}
                                            tabClassName="card-body"
                                        >
                                                    <table className="table table-bordered shadow-sm">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">Campo</th>
                                                          <th scope="col">Visible</th>
                                                          <th scope="col">Modificacíon</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <th scope="row">Requerimientos</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={requerimientosLectura} onChange={()=>{setRequerimientosLectura(!requerimientosLectura);setTodosPlataforma(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={requerimientosLectura === false} checked={requerimientosModificacion} onChange={()=>{setRequerimientosModificacion(!requerimientosModificacion);setTodosPlataforma(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Plataformas</th>
                                                          <td>
                                                                  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={plataformaLectura} onChange={()=>{setPlataformaLectura(!plataformaLectura);setTodosPlataforma(false)}} />
                                                          </td>
                                                          <td>
                                                                  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={plataformaLectura === false} checked={plataformaModificacion} onChange={()=>{setPlataformaModificacion(!plataformaModificacion);setTodosPlataforma(false)}} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Link</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={linkLectura} onChange={()=>{setLinkLectura(!linkLectura);setTodosPlataforma(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={linkLectura === false} checked={linkModificacion} onChange={()=>{setLinkModificacion(!linkModificacion);setTodosPlataforma(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Clave</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={claveLectura} onChange={()=>{setClaveLectura(!claveLectura);setTodosPlataforma(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={claveLectura === false} checked={claveModificacion} onChange={()=>{setClaveModificacion(!claveModificacion);setTodosPlataforma(false)}} />
                                                            </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                        </Tab>

                                      {/* FIN PLATAFORMA */}
                                      
                                      {/* ADMINISTRACION */}


                                        <Tab
                                          eventKey="contratos"
                                          title={(
                                            <div className='row'>
                                                <div className='col-10'>
                                                  <span>Administración</span>
                                                </div>
                                                <div className='col-2'>
                                                  <button
                                                          title="Seleccionar todos"
                                                          className={todosAdministracion === false ? ('btn btn-xs btn-outline-primary') : ('btn btn-xs btn-primary')}
                                                          type="button"
                                                          onClick={()=>setTodosAdministracion(true)}
                                                          disabled={idFactLectura && idFactModificacion && cuitLectura && cuitModificacion && domicilioFiscalLectura && domicilioFiscalModificacion && mailFacturacionLectura && mailFacturacionModificacion && contactosAdministrativosLectura && contactosAdministrativosModificacion
                                                            && fechaFinContratoLectura && fechaFinContratoModificacion && costoMbLectura && costoMbModificacion && costoInstalacionLectura && costoInstalacionModificacion && contratoFirmadoLectura && contratoFirmadoModificacion
                                                            && ordenDeCompraLectura && ordenDeCompraModificacion && contratoCertificadoLectura && contratoCertificadoModificacion && agendaLectura && agendaModificacion && estatutoEmpresaLectura && estatutoEmpresaModificacion
                                                            && designacionActaLectura && designacionActaModificacion && contratoSocialLectura && contratoSocialModificacion && poderLectura && poderModificacion && copiaDniLectura && copiaDniModificacion && constanciaAfipLectura && constanciaAfipModificacion
                                                            && constaciaRentaLectura && constaciaRentaModificacion && pagoAlDiaLectura && pagoAlDiaModificacion && observacionesLectura && observacionesModificacion}
                                                  >
                                                        <i className="fas fa-check" />
                                                  </button>
                                                </div>
                                            </div>
                                          )}
                                          tabClassName="card-body"
                                        >
                                                <div className='col-12'>
                                                    <table className="table table-bordered shadow-sm">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">Campo</th>
                                                          <th scope="col">Visible</th>
                                                          <th scope="col">Modificación</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <th scope="row">ID Fact</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={idFactLectura} onChange={()=>{setIdFactLectura(!idFactLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={idFactLectura === false} checked={idFactModificacion} onChange={()=>{setIdFactModificacion(!idFactModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Cuit</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={cuitLectura} onChange={()=>{setCuitLectura(!cuitLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={cuitLectura === false} checked={cuitModificacion} onChange={()=>{setCuitModificacion(!cuitModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Domicilio Fiscal</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={domicilioFiscalLectura} onChange={()=>{setDomicilioFiscalLectura(!domicilioFiscalLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={domicilioFiscalLectura === false} checked={domicilioFiscalModificacion} onChange={()=>{setDomicilioFiscalModificacion(!domicilioFiscalModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Mail Fac</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={mailFacturacionLectura} onChange={()=>{setMailFacturacionLectura(!mailFacturacionLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={mailFacturacionLectura === false} checked={mailFacturacionModificacion} onChange={()=>{setMailFacturacionModificacion(!mailFacturacionModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Contactos Administrativos</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={contactosAdministrativosLectura} onChange={()=>{setContactosAdministrativosLectura(!contactosAdministrativosLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={contactosAdministrativosLectura === false} checked={contactosAdministrativosModificacion} onChange={()=>{setContactosAdministrativosModificacion(!contactosAdministrativosModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Fecha Fin de C</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={fechaFinContratoLectura} onChange={()=>{setFechaFinContratoLectura(!fechaFinContratoLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={fechaFinContratoLectura === false} checked={fechaFinContratoModificacion} onChange={()=>{setFechaFinContratoModificacion(!fechaFinContratoModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Costo MB</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={costoMbLectura} onChange={()=>{setCostoMbLectura(!costoMbLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={costoMbLectura === false} checked={costoMbModificacion} onChange={()=>{setCostoMbModificacion(!costoMbModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Costo por Inst.</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={costoInstalacionLectura} onChange={()=>{setCostoInstalacionLectura(!costoInstalacionLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={costoInstalacionLectura === false} checked={costoInstalacionModificacion} onChange={()=>{setCostoInstalacionModificacion(!costoInstalacionModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Contrato firmado</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={contratoFirmadoLectura} onChange={()=>{setContratoFirmadoLectura(!contratoFirmadoLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={contratoFirmadoLectura === false} checked={contratoFirmadoModificacion} onChange={()=>{setContratoFirmadoModificacion(!contratoFirmadoModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Orden de compra</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={ordenDeCompraLectura} onChange={()=>{setOrdenDeCompraLectura(!ordenDeCompraLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={ordenDeCompraLectura === false} checked={ordenDeCompraModificacion} onChange={()=>{setOrdenDeCompraModificacion(!ordenDeCompraModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Contracto Certificado</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={contratoCertificadoLectura} onChange={()=>{setContratoCertificadoLectura(!contratoCertificadoLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={contratoCertificadoLectura === false} checked={contratoCertificadoModificacion} onChange={()=>{setContratoCertificadoModificacion(!contratoCertificadoModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Addenda</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={agendaLectura} onChange={()=>{setAgendaLectura(!agendaLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={agendaLectura === false} checked={agendaModificacion} onChange={()=>{setAgendaModificacion(!agendaModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Estatuto Empresa</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={estatutoEmpresaLectura} onChange={()=>{setEstatutoEmpresaLectura(!estatutoEmpresaLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={estatutoEmpresaLectura === false} checked={estatutoEmpresaModificacion} onChange={()=>{setEstatutoEmpresaModificacion(!estatutoEmpresaModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Designacion de acta act</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={designacionActaLectura} onChange={()=>{setDesignacionActaLectura(!designacionActaLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={designacionActaLectura === false} checked={designacionActaModificacion} onChange={()=>{setDesignacionActaModificacion(!designacionActaModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Contrato Social</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={contratoSocialLectura} onChange={()=>{setContratoSocialLectura(!contratoSocialLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={contratoSocialLectura === false} checked={contratoSocialModificacion} onChange={()=>{setContratoSocialModificacion(!contratoSocialModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Poder</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={poderLectura} onChange={()=>{setPoderLectura(!poderLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={poderLectura === false} checked={poderModificacion} onChange={()=>{setPoderModificacion(!poderModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Copia DNI</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={copiaDniLectura} onChange={()=>{setCopiaDniLectura(!copiaDniLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={copiaDniLectura === false} checked={copiaDniModificacion} onChange={()=>{setCopiaDniModificacion(!copiaDniModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Constancia Afip</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={constanciaAfipLectura} onChange={()=>{setConstanciaAfipLectura(!constanciaAfipLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={constanciaAfipLectura === false} checked={constanciaAfipModificacion} onChange={()=>{setConstanciaAfipModificacion(!constanciaAfipModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Constancia Rentas</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={constaciaRentaLectura} onChange={()=>{setConstaciaRentaLectura(!constaciaRentaLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={constaciaRentaLectura === false} checked={constaciaRentaModificacion} onChange={()=>{setConstaciaRentaModificacion(!constaciaRentaModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Pagos al día</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={pagoAlDiaLectura} onChange={()=>{setPagoAlDiaLectura(!pagoAlDiaLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={pagoAlDiaLectura === false} checked={pagoAlDiaModificacion} onChange={()=>{setPagoAlDiaModificacion(!pagoAlDiaModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Observaciones</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={observacionesLectura} onChange={()=>{setObservacionesLectura(!observacionesLectura);setTodosAdministracion(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={observacionesLectura === false} checked={observacionesModificacion} onChange={()=>{setObservacionesModificacion(!observacionesModificacion);setTodosAdministracion(false)}} />
                                                            </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                </div>                                            
                                        </Tab>

                                      {/* FIN ADMINISTRACION */}

                                      {/* INFORMACION TECNICA */}                                      
                                      
                                        <Tab
                                            eventKey="editar"
                                            title={(
                                              <div className='row'>
                                                  <div className='col-10'>
                                                    <span>Información Tecnica</span>
                                                  </div>
                                                  <div className='col-2'>
                                                    <button
                                                            title="Seleccionar todos"
                                                            className={todosInformacionTecnica === false ? ('btn btn-xs btn-outline-primary') : ('btn btn-xs btn-primary')}
                                                            type="button"
                                                            onClick={()=>setTodosInformacionTecnica(true)}
                                                            disabled={contactoTecnicoLectura && contactoTecnicoModificacion && fechaInstalacionLectura && fechaInstalacionModificacion && fechaAltaLectura && fechaAltaModificacion 
                                                              && fechaBajaLectura && fechaBajaModificacion && latitudLectura && latitudModificacion && longitudLectura && longitudModificacion && mbSubidaLectura && mbSubidaModificacion && mbBajadaLectura && mbBajadaModificacion 
                                                              && cantidadIpPublicaLectura && cantidadIpPublicaModificacion && numeroIpPublicaLectura && numeroIpPublicaModificacion && tkAbiertosLectura && tkAbiertosModificacion && observacionesTecnicaLectura && observacionesTecnicaModificacion}
                                                    >
                                                          <i className="fas fa-check" />
                                                    </button>
                                                  </div>
                                              </div>
                                            )}
                                            tabClassName="card-body"
                                        >
                                                    <table className="table table-bordered shadow-sm">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">Campo</th>
                                                          <th scope="col">Visible</th>
                                                          <th scope="col">Modificacíon</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <th scope="row">Contactos Tecnicos</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={contactoTecnicoLectura} onChange={()=>{setContactoTecnicoLectura(!contactoTecnicoLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={contactoTecnicoLectura === false} checked={contactoTecnicoModificacion} onChange={()=>{setContactoTecnicoModificacion(!contactoTecnicoModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">F. Instalación</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={fechaInstalacionLectura} onChange={()=>{setFechaInstalacionLectura(!fechaInstalacionLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={fechaInstalacionLectura === false} checked={fechaInstalacionModificacion} onChange={()=>{setFechaInstalacionModificacion(!fechaInstalacionModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Fecha Alta</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={fechaAltaLectura} onChange={()=>{setFechaAltaLectura(!fechaAltaLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={fechaAltaLectura === false} checked={fechaAltaModificacion} onChange={()=>{setFechaAltaModificacion(!fechaAltaModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Fecha Baja</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={fechaBajaLectura} onChange={()=>{setFechaBajaLectura(!fechaBajaLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={fechaBajaLectura === false} checked={fechaBajaModificacion} onChange={()=>{setFechaBajaModificacion(!fechaBajaModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Latitud</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={latitudLectura} onChange={()=>{setLatitudLectura(!latitudLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={latitudLectura === false} checked={latitudModificacion} onChange={()=>{setLatitudModificacion(!latitudModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Longitud</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={longitudLectura} onChange={()=>{setLongitudLectura(!longitudLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={longitudLectura === false} checked={longitudModificacion} onChange={()=>{setLongitudModificacion(!longitudModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">MB Subida</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={mbSubidaLectura} onChange={()=>{setMbSubidaLectura(!mbSubidaLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={mbSubidaLectura === false} checked={mbSubidaModificacion} onChange={()=>{setMbSubidaModificacion(!mbSubidaModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">MB Bajada</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={mbBajadaLectura} onChange={()=>{setMbBajadaLectura(!mbBajadaLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={mbBajadaLectura === false} checked={mbBajadaModificacion} onChange={()=>{setMbBajadaModificacion(!mbBajadaModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Cantidad IP Publica</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={cantidadIpPublicaLectura} onChange={()=>{setCantidadIpPublicaLectura(!cantidadIpPublicaLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={cantidadIpPublicaLectura === false} checked={cantidadIpPublicaModificacion} onChange={()=>{setCantidadIpPublicaModificacion(!cantidadIpPublicaModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Número de IP Publicas</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={numeroIpPublicaLectura} onChange={()=>{setNumeroIpPublicaLectura(!numeroIpPublicaLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={numeroIpPublicaLectura === false} checked={numeroIpPublicaModificacion} onChange={()=>{setNumeroIpPublicaModificacion(!numeroIpPublicaModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">TK Abiertos</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={tkAbiertosLectura} onChange={()=>{setTkAbiertosLectura(!tkAbiertosLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={tkAbiertosLectura === false} checked={tkAbiertosModificacion} onChange={()=>{setTkAbiertosModificacion(!tkAbiertosModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                          <th scope="row">Observaciones</th>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={observacionesTecnicaLectura} onChange={()=>{setObservacionesTecnicaLectura(!observacionesTecnicaLectura);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                            <td>
                                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" disabled={observacionesTecnicaLectura === false} checked={observacionesTecnicaModificacion} onChange={()=>{setObservacionesTecnicaModificacion(!observacionesTecnicaModificacion);setTodosInformacionTecnica(false)}} />
                                                            </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                        </Tab>

                                      {/* FIN INFORMACION TECNICA */}
                                      
                                    </Tabs>                      
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                {
                  permisos['api.campos-visibles.update'] && (
                  <Button
                      theme="primary"
                      onClick={handleSubmit(onSubmit)}
                      isLoading={isLoading}
                  >
                      GUARDAR
                  </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

ModalPermisosEditar.defaultProps = {
    show: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(ModalPermisosEditar);