import axios from 'axios';
const base = 'calendario';
function getAll(query = null, cancel = null) {
    return axios.get(base, {
        params: query,
        cancelToken: cancel,
    });
}

function disponibles(query = null) {
    return axios.get([base,'disponibles'].join('/'), {
        params: query
    });
}

const CalendarioService = {
    getAll: getAll,
    disponibles: disponibles,
};
export default CalendarioService;
