import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import Button from '../button/Button';

function ModalAlert({show, hideModal, title, message}) {
    const handleClose = () => hideModal();

    return (
        <Modal show={show} backdrop="static" animation onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalAlert.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
};

ModalAlert.defaultProps = {
    show: false,
    title: '',
    message: ''
};

export default ModalAlert;
