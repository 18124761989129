import React from 'react';
import {connect} from 'react-redux';
import {showModal} from 'react-redux-modal-provider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalCuadrillaLugarEditar from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarEditar';
import {enviarWS} from '@app/store/actions/calendar';


function EventCuadrillaDisponible({event, enviar}) {
    const {extendedProps, startStr} = event;
    const cuadrillasDisponibles = "Cuadrillas disponibles ";
    const numeroCuadrillas =event.title.charAt(4);
    const onAgregar = () => {
        showModal(ModalCuadrillaLugarEditar, {
            item: {
                fecha: startStr,
                cuadrillaDisponibleId: extendedProps.id
            },
            onConfirm: (data) => enviar(data)
        });
    };
    return (
        <div className="fc-event-main-frame">
            <div
                className="fc-event-title-container d-flex flex-row w-100"
                onClick={onAgregar}
                aria-hidden="true"
                title={cuadrillasDisponibles + numeroCuadrillas}
            >
                <div className="fc-event-title fc-sticky mr-auto">
                     {event.title}
                </div>
                <div className="float-right">
                    <FontAwesomeIcon icon={['fas', 'plus']} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    enviar: (data) => dispatch(enviarWS(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventCuadrillaDisponible);
