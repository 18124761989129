import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import JugadoresService from '@services/jugadores.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalAsesor from '@app/modals/tipo-foto/ModalAsesor';
import {Button, ModalConfirm} from '@app/components/index';

const JugadoresPage = () => {
    AOS.init();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await JugadoresService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Asesor',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                JugadoresService.destroy(item.dni).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalAsesor, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'DNI',
                accessor: 'dni'
            },
            {
                Header: 'Departamento',
                accessor: 'departamento'
            },
            {
                Header: 'Puesto',
                accessor: 'puesto'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Teléfono',
                accessor: 'telefono'
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 my-3" data-aos="fade-right">
                    <h2>Jugadores</h2>
                </div>
                <div className="col-12" data-aos="fade-right">
                    {/* <Button color="primary" onClick={() => onOpenEditar()}>
                        NUEVO
                    </Button> */}
                    <TablePaginated columns={columns} data={items} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JugadoresPage);