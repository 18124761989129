import ZohoLocalidadService from '@app/services/ZohoLocalidad.service';
import React, {useState, useEffect, useRef, forwardRef, Fragment} from 'react';
import {AsyncTypeahead, InputProps} from 'react-bootstrap-typeahead';

const InputLocalidadZoho = forwardRef(({
    id,
    field,
    value,
    onSelect,
    ...defaultProps
},ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query) => {
        setIsLoading(true);
        const search = {
            word: query
        };
        ZohoLocalidadService.searchLocalidad(search)
            .then(({data}) => {
                setOptions(
                    data.map((item) => {
                        const localidad = `${item.Name}`;
                        const localidadId = `${item.id}`;

                        return {
                            localidad: localidad,
                            localidadId: localidadId,
                        };
                    })
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onSelected = (newSelect) => {
        onSelect(newSelect);
    };
    
    useEffect(() => {
        /*
        console.log(ref.current);
        */
        /* ref.current.clear()
        ref.current.setState({text:value}) */
    }, [value])

    return (
        <AsyncTypeahead
            {...defaultProps}
            ref={ref}
            id={id}
            inputProps={{
                id: id
            }}
            isLoading={isLoading}
            labelKey={field}
            minLength={3}
            onSearch={handleSearch}
            onChange={onSelected}
            filterBy={() => true}
            options={options}
            placeholder="Buscar localidad..."
            emptyLabel="No hay coincidencias"
            promptText="Escribe para buscar..."
            renderMenuItemChildren={(option, props) => {
                return (
                    <>
                        <p>
                            <strong>{option.localidad}</strong>
                        </p>
                    </>
                )
            }}
            useCache={false}
        />
    );
});

InputLocalidadZoho.defaultProps = {
    field: 'localidad',
    value: '',
    onSelect: () => {},
};

export default InputLocalidadZoho;