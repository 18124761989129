import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, NavLink, withRouter} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactRouterPropTypes from 'react-router-prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Colors, TypeMappings} from './PropTypes';
import {splitIcon} from './Utilities';

const isActiveItem = ({link, activeOn, history}) => {
    const {location} = history || {};
    const {pathname} = location || {pathname: ''};
    let activeArray = [];
    if (activeOn) {
        activeArray =
            activeOn.length && typeof activeOn !== 'string'
                ? activeOn
                : [activeOn];
    }
    const active =
        pathname === link ||
        !!activeArray.find((p) => pathname.match(new RegExp(p)));
    return active;
};

const Item = ({
    id,
    icon,
    text,
    labels,
    color,
    history,
    children,
    activeOn,
    to,
    highlighted,
    toggleMenuSidebar
}) => {
    const [activeChild, setActiveChild] = useState(false);
    const localTo = to;
    const active = isActiveItem({link: localTo, activeOn, history});
    const localLabels =
        labels && labels.length ? labels : (labels && [labels]) || [];
    const mappedLabels = localLabels.map((p) =>
        p.small ? (
            <small key={uuidv4()} className={`label pull-right bg-${p.color}`}>
                {p.text}
            </small>
        ) : (
            <span key={uuidv4()} className={`label label-${p.type} pull-right`}>
                {p.text}
            </span>
        )
    );
    const localColor = color ? TypeMappings.byColor[color].colorCode : null;
    const localIcon = splitIcon(icon);

    const hasChildren = !!children;
    const hasLabels = Array.isArray(localLabels);

    const onClickNav = () => {
        setActiveChild(!activeChild);
    };
    useEffect(() => {
        if (hasChildren) {
            let localChildren = children;
            children = children.length ? children : [children];
            localChildren = children.map((p) =>
                React.cloneElement(p, {key: p.to})
            );
            const newactiveChild = !!localChildren.find((p) =>
                isActiveItem({
                    history,
                    link: p.props.to,
                    ...p.props
                })
            );
            setActiveChild(newactiveChild);
        }
    }, []);
    let actualComponent = (
        <React.Fragment key={uuidv4()}>
            <>
                <FontAwesomeIcon
                    className="nav-icon"
                    color={localColor}
                    icon={localIcon}
                    style={{marginRight: '6px'}}
                />
{' '}
                <p>
                    {text}
                    {(hasChildren || hasLabels) && (
                        <>
                            {hasChildren && (
                                <i className="right fas fa-angle-left"></i>
                            )}
                            {hasLabels && mappedLabels}
                        </>
                    )}
                </p>
            </>
        </React.Fragment>
    );
    if (localTo) {
        actualComponent = (
            <NavLink
                to={localTo}
                exact
                className="nav-link"
                onClick={() => toggleMenuSidebar()}
            >
                {actualComponent}
            </NavLink>
        );
    } else {
        actualComponent = (
            // eslint-disable-next-line no-script-url, jsx-a11y/anchor-is-valid
            <a className="nav-link" href={void 0} onClick={() => !onClickNav()}>
                {actualComponent}
            </a>
        );
    }
    const liClasses = [
        active ? ' active ' : null,
        hasChildren ? ' ' : null,
        activeChild ? ' menu-is-opening menu-open ' : null,
        highlighted ? ' active' : undefined
    ]
        .filter((p) => p)
        .join(' ');
    return (
        <li className={`nav-item ${liClasses}`}>
            {actualComponent}
            {hasChildren && (
                <ul
                    className="nav nav-treeview"
                    style={{display: activeChild ? 'block' : 'none'}}
                >
                    {React.Children.map(children, (child) => {
                        return React.cloneElement(child, {toggleMenuSidebar});
                    })}
                </ul>
            )}
        </li>
    );
};

Item.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
    labels: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({}),
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.arrayOf(PropTypes.shape({}))
    ]),
    color: PropTypes.oneOf(Colors),
    history: ReactRouterPropTypes.history,
    activeOn: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    to: PropTypes.string,
    highlighted: PropTypes.bool
};

Item.defaultProps = {
    id: undefined,
    children: null,
    icon: 'far-circle',
    labels: null,
    color: null,
    history: null,
    activeOn: null,
    to: undefined,
    highlighted: false
};
export default withRouter(Item);
