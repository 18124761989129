import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import Modal from 'react-bootstrap/esm/Modal';
import {MapBase, Poligono} from '@app/components/index';
import * as MapaAction from "@app/store/actions/mapas";
import useCurrentLocation from "@app/hooks/useCurrentLocation";



function ModalZonaAsesor({
    show,
    hideModal,
    id,
    recuperarPoligono,
    nombreCompleto,
    coordinates,
    poliasesor}) {
    const [isLoading, setIsLoading] = useState(false);
    const [maplocation, setMapLocation] = useState({
            lat:coordinates[0][0][1],
            lng:coordinates[0][0][0]
        })
    const [zona, setZona] = useState([poliasesor])
    const { location: currentLocation, error: currentError } = useCurrentLocation();
    const handleClose = () => hideModal();

    const recuperar = (coord) => {
        const query = {
            coordenadas: `${coord.lat},${coord.lng}`,
        }
        recuperarPoligono(query);
    }

    const onChange = (newCoordinates) => {
        recuperar(newCoordinates);
        setMapLocation(newCoordinates);
    }

    // useEffect(() => {
    // setMapLocation({
    //     lat:coordinates[0][0][1],
    //     lng:coordinates[0][0][0]
    // })
    // }, [])



    return (
        <Modal show={show} backdrop="static" size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {id ?(
                            `Zona de ${nombreCompleto} en ${poliasesor.lugar.nombre}`
                    )
                    : 'Error al mostrar zona'}
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                        <MapBase
                            id="mapaAsesor"
                            mapContainerStyle={{
                                width: '100%',
                                height: '70vh'
                            }}
                            options={{
                                maxZoom: 19,
                                minZoom: 14,
                            }}
                            zoom={16}
                            coordinates={maplocation}
                            onSearch={false}
                            onChange={onChange}

                        >
                            {
                                zona?.map(poly=>{
                                    return <Poligono item={poly} key={poly.id} />
                                })
                            }
                        </MapBase>
                </Modal.Body>
        </Modal>
    );
}

ModalZonaAsesor.defaultProps = {
    show: false
};

const mapStateToProps = (state) => ({
    poligonos: state.mapas.poligonos,
    prefactibilidad: state.mapas.prefactibilidad,
});

const mapDispatchToProps = (dispatch) => ({
    recuperarPoligono: (data) => dispatch(MapaAction.recuperarPoligonos(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalZonaAsesor);