import React,{useState,useEffect} from 'react';
import { connect } from "react-redux";
import {Link, useHistory} from 'react-router-dom';
import usePermisos from '@app/hooks/usePermisos';
import FechaFunction from '@app/functions/FechaFunction';
import './home.scss';

const HomePage = ({user}) => {
    const history = useHistory();
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek());
    const permisos = usePermisos();
    const {puesto} = user;

    return (
                <div className="container-fluid">
                    <div className="row">

                        {/* ASESORES */}
                        
                        {puesto === "Asesor" && (

                        <div className="offset-1 col-10 border mt-2 mt-lg-5 mb-3 shadow border redondeo p-3 pb-lg-4  bg-white">
                            <div className="row">
                                <div className="col-12 ml-lg-3 my-lg-3">
                                    <h5>
                                        ¡Bienvenido! ¿Qué vas a hacer hoy? Asesor
                                    </h5>
                                </div>

                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-primary redondeo shadow pt-2 pl-3">
                                                    <Link to="/contactos/nuevo">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Vender</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="redondeo shadow bg-info pt-2 pl-3">
                                                    <Link to="/contactos">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Contactos</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-secondary redondeo shadow pt-2 pl-3">
                                                    <Link to="/asesor/detalle">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Detalles</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-success redondeo shadow pt-2 pl-3">
                                                    <Link to="/mapas/asesor">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3"> Tu zona</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                            </div>
                        </div>

                        )}
                        
                        {/* AGENCIAS */}

                        {puesto === "Agencia" && (

                        <div className="offset-1 col-10 border mt-2 mt-lg-5 mb-3 shadow border redondeo p-3 pb-lg-4  bg-white">
                            <div className="row">
                                <div className="col-12 ml-lg-3 my-lg-3">
                                    <h5>
                                        ¡Bienvenido!
                                    </h5>
                                </div>
                                    {
                                        !permisos['api.asesor'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-primary redondeo shadow pt-2 pl-3">
                                                    <Link to="/personas/nuevo">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-plus border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Agregar Asesor</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    )}
                                        
                                    {
                                        !permisos['api.asesor'] &&
                                        permisos['api.personas.index'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="redondeo shadow bg-info pt-2 pl-3">
                                                    <Link to="/personas">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-table border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Tabla Asesores</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    )}
                                    
                                    {
                                        !permisos['api.asesor'] &&
                                        permisos['api.asesores.lugares.index'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-secondary redondeo shadow pt-2 pl-3">
                                                    <Link to="/asesores/lugares">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-street-view border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Zonas de Asesores</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    )}
                                    
                                    {
                                        permisos['api.detalles.contratos.index'] &&
                                        permisos['api.personas.index'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-success redondeo shadow pt-2 pl-3">
                                                    <Link to="/asesor/detalle">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-list border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3"> Detalles</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                    )}
                                    

                            </div>
                        </div>

                        )}

                        {/* COORPORATIVO */}

                        {puesto === "Corporativo" && (

                        <div className="offset-1 col-10 border mt-2 mt-lg-5 mb-3 shadow border redondeo p-3 pb-lg-4  bg-white">
                            <div className="row">
                                <div className="col-12 ml-lg-3 my-lg-3">
                                    <div className='row'>

                                        <div className='col-2'>
                                            <h5>
                                                ¡Bienvenido!
                                            </h5>
                                        </div>

                                        {
                                        permisos['api.general.index'] && (
                                            <div className='col-3'>
                                                <Link to="/coorporativos">
                                                    <h5 className='text-primary'>
                                                        <u>
                                                        Ir a Tabla Admin
                                                        </u>
                                                    </h5>
                                                </Link>
                                            </div>
                                        )}
                                        

                                    </div>
                                </div>
                                        {
                                            permisos['api.datos-personales.index'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-primary redondeo shadow pt-2 pl-3">
                                                    <Link to="/coorporativo/clientes">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Datos Clientes</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                        
                                        {
                                            permisos['api.plataformas.index'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="redondeo shadow bg-info pt-2 pl-3">
                                                    <Link to="/coorporativo/plataforma">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Plataforma</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                        {
                                            permisos['api.administraciones.index'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-secondary redondeo shadow pt-2 pl-3">
                                                    <Link to="/coorporativo/administracion">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3">Administración</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                        
                                        {
                                        permisos['api.informaciones-tecnicas.index'] && (
                                            <div className="col-12 mt-2 col-lg-3 mt-lg-0">
                                                <div className="bg-success redondeo shadow pt-2 pl-3">
                                                    <Link to="/coorporativo/tecnica">
                                                        <div className="row text-white">
                                                            <div className="col-12"><i className="fas fa-arrow-right border rounded p-1 mt-3"></i></div>
                                                            <div className="col-12 mb-5 mt-3"> Información Tecnica</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                        

                            </div>
                        </div>

                        )}
                           
                    </div>
                </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

