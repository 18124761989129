import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Carousel, InputDropZone, FormTipoMaterial, FormTipoMaterialSchema} from '@app/components/index';
import FotoService from '@app/services/foto.service';
import styled from 'styled-components';
import ContactosAction from '@app/store/actions/contactos';

const WrapImage = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
`;

const TareaForm3 = ({
    contacto,
    nextStep,
    previousStep,
    iniciarContrato,
}) => {
    const {handleSubmit,register, setValue, errors} = useForm({
        resolver: yupResolver(FormTipoMaterialSchema),
        defaultProps: contacto,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [defaultImageDF, setDefaultImageDF] = useState(null);
    const [defaultImageDD, setDefaultImageDD] = useState(null)
    const [defaultImageF1, setDefaultImageF1] = useState(null);
    const [defaultImageF2, setDefaultImageF2] = useState(null)
    const [imgPreviewDF, setImgPreviewDF] = useState(null);
    const [imgPreviewDD, setImgPreviewDD] = useState(null);
    const [imgPreviewF1, setImgPreviewF1] = useState(null);
    const [imgPreviewF2, setImgPreviewF2] = useState(null);
    const [fotos, setFotos] = useState([]);

    const actualizar = () => {
        FotoService.getAll({
            contactoId:contacto.id
        })
        .then((response)=>{
            setFotos(response);
            response.forEach((foto)=>{
                switch (foto.tipoFotoId) {
                    case 1:  /* foto DNI FRENTE */
                        setDefaultImageDF(foto);
                        break;
                    case 2: /* foto DNI DORSO */
                        setDefaultImageDD(foto);
                        break;
                    default:
                        break;
                }
            });
        });
    }

    const onSubmit = (data) => {
        const forms = [];
        setIsLoading(true);
        const contrato = {
            ...data,
            contactoId: contacto.id,
            contacto: contacto,
        };
        if(imgPreviewDF){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 1,
                foto: imgPreviewDF,
            }).then((response)=>{
                setDefaultImageDF(response);
                setImgPreviewDF(null);
                contrato.fotoDocumentoFrenteId = response.id;
                contrato.fotoDocumentoFrente = response;
            }))
        } else if(defaultImageDF){
            contrato.fotoDocumentoFrenteId = defaultImageDF.id;
            contrato.fotoDocumentoFrente = defaultImageDF;
        } else {
            contrato.fotoDocumentoFrenteId = null;
            contrato.fotoDocumentoFrente = null;
        }

        if(imgPreviewDD){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 2,
                foto: imgPreviewDD,
            }).then((response)=>{
                setDefaultImageDD(response);
                setImgPreviewDD(null);
                contrato.fotoDocumentoDorsoId = response.id;
                contrato.fotoDocumentoDorso = response;
            }))
        } else if(defaultImageDD){
            contrato.fotoDocumentoDorsoId = defaultImageDD.id;
            contrato.fotoDocumentoDorso = defaultImageDD;
        } else {
            contrato.fotoDocumentoDorsoId = null;
            contrato.fotoDocumentoDorso = null;
        }

        if(imgPreviewF1){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 3,
                foto: imgPreviewF1,
            }).then((response)=>{
                setDefaultImageF1(response);
                setImgPreviewF1(null);
                contrato.fotoLugar1Id = response.id;
                contrato.fotoLugar1 = response;
            }))
        } else if(defaultImageF1){
            contrato.fotoLugar1Id = defaultImageF1.id;
            contrato.fotoLugar1 = defaultImageF1;
        } else {
            contrato.fotoLugar1Id = null;
            contrato.fotoLugar1 = null;
        }

        if(imgPreviewF2){
            forms.push(FotoService.register({
                tabla: 'contactos',
                tablaId: contacto?.id,
                tipoFotoId: 4,
                foto: imgPreviewF2,
            }).then((response)=>{
                setDefaultImageF2(response);
                setImgPreviewF2(null);
                contrato.fotoLugar2Id = response.id;
                contrato.fotoLugar2 = response;
            }))
        } else if(defaultImageF2){
            contrato.fotoLugar2Id = defaultImageF2.id;
            contrato.fotoLugar2 = defaultImageF2;
        } else {
            contrato.fotoLugar2Id = null;
            contrato.fotoLugar2 = null;
        }
        Promise.all(forms).then(()=>{
            iniciarContrato(contrato);
            actualizar();
            nextStep();
        })
        .finally(()=>{
            setIsLoading(false);
        });
    };

    const onClickF1 = (foto) =>{
        setDefaultImageF1(foto)
    }
    const onClickF2 = (foto) =>{
        setDefaultImageF2(foto)
    }


    useEffect(() => {
        setDefaultImageDF(null);
        setDefaultImageDD(null);
        setDefaultImageF1(null);
        setDefaultImageF2(null);
        if(contacto){
            actualizar();
        }
    }, [contacto])
    return (
        <>
            <div className="container-fluid shadow-sm ">
                <div className="row bg-white rounded-lg border p-lg-3 p-1 p-md-3 pt-md-5">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-4 text-gray my-3">
                                <h5>
                                    <i className="fas fa-angle-down mr-2"></i>
                                    Documento de Identidad
                                </h5>
                                <span>Frente y Dorso</span>
                            </div>
                            <div className="col-12 col-md-8 my-lg-2">
                                <div className="row">
                                    <div className="col-12 col-md-5 offset-md-1 col-lg-5">
                                        <label className="control-label" htmlFor="dniF">
                                            DNI Frente
                                        </label>
                                        <InputDropZone
                                            id="dniF"
                                            defaultImage={defaultImageDF?.foto_url}
                                            backgroundImage="/img/Frente.png"
                                            onChange={setImgPreviewDF}
                                        />
                                    </div>
                                    <div className="col-12 my-lg-0 offset-md-1 col-md-5 col-lg-5">
                                        <label className="control-label mt-3 mt-md-0 mt-lg-0" htmlFor="dniD">
                                            DNI Dorso
                                        </label>
                                        <InputDropZone
                                            id="dniD"
                                            defaultImage={defaultImageDD?.foto_url}
                                            backgroundImage="/img/Dorso.png"
                                            onChange={setImgPreviewDD}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 border my-3">
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <FormTipoMaterial register={register} setValue={setValue} errors={errors} />
                            <div className="col-12 col-md-8 my-2">
                                <div className="row">
                                    <div className="col-12 offset-md-1 col-md-5">
                                        <label className="control-label" htmlFor="foto1L">
                                            Foto 1
                                        </label>
                                        <InputDropZone
                                            id="foto1L"
                                            defaultImage={defaultImageF1?.foto_url}
                                            backgroundImage="/img/Imagen_1.png"
                                            onChange={setImgPreviewF1}
                                        />
                                        {
                                            fotos.filter(fo=>fo.tipoFotoId===3).length>0 && (
                                                <Carousel size="small">
                                                    {
                                                        fotos.filter(fo=>fo.tipoFotoId===3).map((fo)=>{
                                                            return (
                                                                <WrapImage key={fo.id} onClick={()=>onClickF1(fo)} aria-hidden="false">
                                                                    <img src={fo.foto_url} alt="" className="w-50 img-thumbnail" />
                                                                </WrapImage>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            )
                                        }
                                    </div>
                                    <div className="col-12 my-lg-0 offset-md-1 col-md-5">
                                        <label className="label-control mt-4 mt-md-0 mt-lg-0" htmlFor="foto2L">
                                            Foto 2
                                        </label>
                                        <InputDropZone
                                            id="foto2L"
                                            defaultImage={defaultImageF2?.foto_url}
                                            backgroundImage="/img/Imagen_2.png"
                                            onChange={setImgPreviewF2}
                                        />
                                        {
                                            fotos.filter(fo=>fo.tipoFotoId===4).length>0 && (
                                                <Carousel size="small">
                                                    {
                                                        fotos.filter(fo=>fo.tipoFotoId===4).map((fo)=>{
                                                            return (
                                                                <WrapImage key={fo.id} onClick={()=>onClickF2(fo)} aria-hidden="false">
                                                                    <img src={fo.foto_url} alt="" className="w-50 img-thumbnail" />
                                                                </WrapImage>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4 my-md-3 my-lg-3 text-right">
                        <div className="row">
                            <div className="col-12 offset-md-6 col-md-6 py-md-4 pt-md-5  pb-lg-2 pt-lg-3 offset-lg-8 col-lg-4">
                                <Button className="btn-secondary" onClick={previousStep}>
                                    Anterior
                                </Button>
                                <Button
                                    isLoading={isLoading}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Guardar y Continuar
                                </Button>
                                <Link to="/contactos">
                                    <Button theme="default">
                                        Cancelar
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TareaForm3);