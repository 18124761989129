import React, {useState, useEffect} from 'react';
import { Marker} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { recuperarPrefactibilidad } from "@app/store/actions/mapas";
import { connect } from 'react-redux';
import {Spinner} from 'react-bootstrap';

function BoxPrefactibilidad({coordinates, onChange, prefactibilidad, recuperarPrefa}) {
    const [marker, setMarker] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { color, descripcion} = prefactibilidad;

    const onLoadMarker = React.useCallback(function callback(markerLoaded) {
        setMarker(markerLoaded);
    }, []);

    const recuperar = (coords) => {
        const data = {
            coordenadas: `${coords.lat},${coords.lng}`,
        }
        setIsLoading(true);
        recuperarPrefa(data).finally(()=>{
            setIsLoading(false);
        });
    }

    const onDragEnd = () => {
        const newCoordinates = {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng(),
        };
        recuperar(newCoordinates);
        onChange(newCoordinates);
    };

    useEffect(() => {
        if(marker){
            marker.setPosition(coordinates);
            recuperar(coordinates);
        }
    }, [coordinates]);

    useEffect(() => {
        recuperar(coordinates);
    }, [])

    return (
        <>
            <Marker
                onLoad={onLoadMarker}
                position={coordinates}
                draggable
                onDragEnd={onDragEnd}
                icon={{
                    url:'/img/location_home.svg',
                    scaledSize: {
                        width:50,
                        height:50,
                    }
                }}
            />
            <div
                id="divcard"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: "50%",
                    bottom: 0,
                    marginLeft: "-120px",
                    backgroundColor: color,
                    textAlign: 'center',
                }}
            >
                {
                    !isLoading ? (
                        <label id="labelcoberturaMb">{descripcion}</label>
                    ) : (
                        <Spinner animation="border" />
                    )
                }
            </div>
        </>
    );  
}

const mapStateToProps = (state) => ({
    prefactibilidad: state.mapas.prefactibilidad,
});

const mapDispatchToProps = (dispatch) => ({
    recuperarPrefa: (data) => dispatch(recuperarPrefactibilidad(data)),
});

BoxPrefactibilidad.defaultProps = {
    onChange: () => {},
    onClick: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxPrefactibilidad);
