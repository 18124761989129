import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
    name: 'default',
    initialState: {
        isPresent: false,
        isLoadingBlock: false,
        message: '',
    },
    reducers: {
        setPresent: (state,action) => {
            state.isPresent = action.payload;
        },
        startLoadingBlock: (state,action) => {
            state.isPresent = true;
            state.isLoadingBlock = true;
            state.message = action.payload;
        },
        endLoadingBlock: (state) => {
            state.isPresent = false;
            state.isLoadingBlock = false;
            state.message = '';
        }
    },
});


export const { setPresent, startLoadingBlock, endLoadingBlock } = modalSlice.actions;

export default modalSlice.reducer
