import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import  {Button, CuadrillaDisponibleCard, FormTareaHorario, InputCheck, ListCard, SelectLugar, TimeSlider } from '@app/components/index';
import { Collapse } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import CalendarioService from '@app/services/calendario.service';
import MapaFunction from '@app/functions/MapaFunction';
import ContactosAction from '@app/store/actions/contactos';
import EchoWS from '@app/utils/ws';
import {showModal} from 'react-redux-modal-provider';
import ModalCrearCuadrillaLugarDisponibles from '@app/modals/cuadrilla-lugar/ModalCrearCuadrillaLugarDisponibles';
import ModalCrearEspCuadrillaLugarDisponibles from '@app/modals/cuadrilla-lugar/ModalCrearEspCuadrillaLugarDisponibles';
import usePermisos from '@app/hooks/usePermisos';

function ModalCuadrillaDisponibleLugarDisponibles({
    item,
    show,
    hideModal,
    onGuardar,
    iniciarContacto,
    user,
    redirigir
}) {
    const {dni} = user;
    const [items, setItems] = useState([]);
    const permisos = usePermisos();
    const [lugarId, setLugarId] = useState(item.lugarId || 0);
    const [isLoading, setIsLoading] = useState(false);
    const [cuadrillaLugarSelected, setCuadrillaLugarSelected] = useState(null);
    const [filtroCuadrilla, setFiltroCuadrilla] = useState(false);
    const [time, setTime] = useState(null);
    const {current} = useSelector( state => state.contactos );
    // const history = useHistory();

    const handleClose = () => hideModal();

    const actualizar = () => {
        const form = {
            lugarId: lugarId,
        };
        form.tipoTareaId = item?.tipoTareaId;
        if(filtroCuadrilla){
            form.filtroTCua = 1;
        }
        setIsLoading(true);
        CalendarioService.disponibles(form).then((response)=>{
            if(item.id > 0 && item.coordenadas){
                setItems(MapaFunction.calculoCostos(response,item));
            } else {
                setItems(response);
            }
        })
        .catch(()=>{
            setItems([]);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    };
    const onSubmitCrearCuadrilla = () => {
        showModal(ModalCrearCuadrillaLugarDisponibles,{onActualizar:actualizar})
    }
    const onSubmitCrearCuadrillaEsp = () => {
        showModal(ModalCrearEspCuadrillaLugarDisponibles,{onActualizar:actualizar})
    }

    const reprogramarCalendario = () => {
        redirigir.push('/calendario');
    } 
    
    const onSubmit = () => {
        const aux = {...item};
        aux.cuadrillaLugarId = cuadrillaLugarSelected.id;
        aux.fecha = cuadrillaLugarSelected.fecha;
        aux.horaIni = time.start;
        aux.horaFin = time.end;
        aux.tareaId = item.id;
        if(cuadrillaLugarSelected.cartelAdvertencia){
            /* aux.estadoTareaId = 8; */ // LA IDEA ERA EN ESTADO DE PENDIENTE PERO QUEDO DESCARTADO POR CONTACT CENTER
            aux.estadoTareaId = 1;
        }else{
            aux.estadoTareaId = 1;
        }
        hideModal();
        onGuardar(aux);
    };

    const onSelectLugar = ({item:lugar}) => {
        setLugarId(lugar.id);
    };

    useEffect(() => {
        iniciarContacto(item);
        return () => {
            iniciarContacto(null);
        }
    }, [item])

    useEffect(() => {
        if(item.id>0){
            actualizar();
        }
        setCuadrillaLugarSelected(null)
    }, [filtroCuadrilla]);

    useEffect(()=>{
        let listen;
        if(lugarId>0){
            actualizar();
            if(listen){
                listen.unsubscribe();
            }
            listen = EchoWS.private(`tareas.lugares.${lugarId}`)
                .listen('.tareas.created',actualizar)
                .listen('.tareas.deleted',actualizar)
                .listen('.tareas.updated',actualizar)
        } else {
            setItems([]);
        }
        return () => {
            if(listen){
                listen.unsubscribe();
            }
        }
    },[lugarId])
    return (
        <Modal show={show} backdrop="static" size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-9">
                                <h3 className="mt-3">
                                    Proximos disponibles por Lugar
                                </h3>
                            </div>
                        </div>
                    </div>

                {/* <Button theme="primary" onClick={onSubmitCrearCuadrilla}>
                    Crear Cuadrilla
                </Button> */}

                {/* {
                    permisos['api.cuadrillas.lugares.disp.disponibleCuadrilla'] && (
                    <Button theme="primary" onClick={onSubmitCrearCuadrillaEsp}>       
                     // COMENTADO POR MANTENER EL MISMO FORMATO DEL CALENDAR VIEJO
                        Crear Cuadrilla Especial
                    </Button>
                )} */}

            </Modal.Header>
            <Modal.Body>
                {/* <div className="row">
                
                    <div className="col-12">
                        {
                            (dni === 35928822 || dni === 27571355 || dni === 28832775 || dni === 41530626 || dni === 33159590) && item.id > 0 && (
                                <InputCheck
                                    className="ml-3"
                                    label="Todas las Cuadrillas"
                                    onChange={() =>setFiltroCuadrilla(!filtroCuadrilla)} // NO VA PORQUE CECILIA QUIERE RECARGAR O REPROGRAMAR DESDE CALENDARIO
                                    checked={filtroCuadrilla}
                                />
                            )
                        }
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-12">
                        <SelectLugar disabled={Boolean(item.id>0)} value={lugarId} onChange={onSelectLugar} />
                    </div>
                </div>
                <div className="row">
                    <Collapse
                        in={cuadrillaLugarSelected===null}
                    >
                    <div className="col-12">
                        <ListCard isLoading={isLoading}>
                        {
                            items.map((it)=>{
                                return (
                                <CuadrillaDisponibleCard 
                                    key={it.id} 
                                    item={it} 
                                    tipoTrabajoId={it?.tipoTareaId} 
                                    onSelect={setCuadrillaLugarSelected} 
                                    isSelectable={Boolean(item.id>0)}
                                    filtroCuadrilla={filtroCuadrilla}
                                />
                                )
                            })
                        }
                        </ListCard>
                    </div>
                    </Collapse>
                    <Collapse
                        in={cuadrillaLugarSelected!==null}
                    >
                    <div className="col-12">
                        <Button onClick={()=>setCuadrillaLugarSelected(null)}>
                            Volver a seleccionar fecha
                        </Button>
                        {
                            cuadrillaLugarSelected && (
                                <FormTareaHorario cuadrillaLugar={cuadrillaLugarSelected} onChange={setTime} />
                            )
                        }
                    </div>
                    </Collapse>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
                {
                    item.id > 0 && (
                        <Button theme="primary" onClick={onSubmit} disabled={!cuadrillaLugarSelected}>
                            GUARDAR
                        </Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

ModalCuadrillaDisponibleLugarDisponibles.propTypes = {
}

/**
 * item: Tarea || Contacto
 */
ModalCuadrillaDisponibleLugarDisponibles.defaultProps = {
    item: {},
}

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalCuadrillaDisponibleLugarDisponibles);

