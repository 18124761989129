import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import TareasService from '@services/tareas.service';
import {Link, useHistory, useParams} from 'react-router-dom';
import {showModal} from 'react-redux-modal-provider';
import usePermisos from '@app/hooks/usePermisos';
import {
    Button,
    DateSingle,
    SelectLugar,
    SelectTipoTarea,
    SelectMotivoTarea,
    SelectTipoCanal,
    TimeSlider,
    InputZohoSearch,
    InputEditor,
    MapClick,
    CuadrillaDisponibleCard,
    FlotaCard
} from '@app/components/index';
import CalendarFunction from '@app/functions/CalendarFunction';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import ModalTareaCancelar from '@app/modals/tarea/ModalTareaCancelar';
import ModalTareaRepro from '@app/modals/tarea/ModalReprogramarTarea';
import ModalCuadrillaLugarDiponibles from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarDiponibles';
import * as CalendarAction from '@app/store/actions/calendar';
import useCurrentLocation from '@app/hooks/useCurrentLocation';
import MapaFunction from '@app/functions/MapaFunction';
import ModalTareaLog from '@app/modals/tarea/ModalTareaLog';

const schema = Yup.object({
    id:Yup.mixed(),
    lugarId: Yup.mixed().required(),
    tipoTareaId: Yup.mixed().required(),
    motivoTareaId: Yup.mixed().when('tipoTareaId', {
        is: (id) => id > 0,
        then: Yup.mixed().required()
    }),
    clienteDireccion: Yup.string().max(200),
    clienteTelefono: Yup.string()
        .matches(/^(\s*|\d+)$/, 'Debe ser solo numeros')
        .max(200),
    clienteCelular: Yup.string()
        .matches(/^(\s*|\d+)$/, 'Debe ser solo numeros')
        .max(200),
    clienteId: Yup.string()
        .matches(/^(\s*|\d+)$/, 'Debe ser solo numeros')
        .max(200),
    clienteCategoria: Yup.string().max(200),
    categoria: Yup.string().when('id',{
        is: (value) => value > 0,
        then: Yup.string(),
        otherwise: Yup.string().required(),
    }),
    tipoCanalId: Yup.string().required()
});

const externalDefaultTime = {
    start: '09:00:00',
    end: '10:00:00'
};

function TareaNuevo({
    itemReprogramado,
    agregarReprogramacion,
    location,
    recuperar,
    user
}) {
    const {id} = useParams();
    const { location:currentLocation } = useCurrentLocation();
    const history = useHistory();
    const {
        backTo,
        item,
        action,
    } = (location && location.state) || { item:{}};


    const permisos = usePermisos();
    const {calendar}=useSelector(state=>state)
    const [tarea, setTarea] = useState(item || {});
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstLoading, setIsFirstLoading] = useState(true)
    const [mapLocation, setMapLocation] = useState((item && item.coordenadas)?MapaFunction.gJsonToGoogleMaps(item.coordenadas)[0]:currentLocation)
    const [coordenadas, setCoordenadas] = useState(mapLocation);
    const [time, setTime] = useState((item && item.horaIni && item.horaFin)?{ start: item.horaIni, end: item.horaFin }:externalDefaultTime);
    const [template, setTemplate] = useState('');
    const [tipoTareaOption, setTipoTareaOption] = useState(item?.tipoTareaId || itemReprogramado?.tipoTareaId);
    const [guardarCoordenadas, setGuardarCoordenadas] = useState(`${mapLocation.lat} , ${mapLocation.lng}`)

    const {register, handleSubmit, errors, setValue, watch, control} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            id:id,
        }
    });


    const fecha = watch('fecha',null);
    const lugarId = watch('lugarId',item?.lugarId || 0);
    const cuadrillaLugarId = watch('cuadrillaLugarId', 0);
    const tipoTareaId = watch('tipoTareaId', item?.tipoTareaId || itemReprogramado?.tipoTareaId || '');
    const motivoTareaId = watch('motivoTareaId',item?.motivoTareaId || 0);
    const clienteNombre = watch('clienteNombre', '');
    const clienteDocumento = watch('clienteDocumento', '');
    const tipoCanalId = watch('tipoCanalId', item?.tipoCanalId || itemReprogramado?.tipoCanalId);

    const setItem = (newItem) =>{
        setTarea(newItem);
        setValue('fecha', newItem.fecha);
        setValue('categoria', newItem.categoria);
        setValue('clienteNombre', newItem.clienteNombre || '');
        setValue('clienteDocumento', newItem.clienteDocumento || '');
        // console.log('clienteDireccion',newItem.clienteDireccion)
        setValue('clienteDireccion', newItem.clienteDireccion);
        setValue('clienteTelefono', newItem.clienteTelefono);
        setValue('clienteCelular', newItem.clienteCelular);
        setValue('clienteCategoria', newItem.clienteCategoria);
        setValue('contratoId', newItem.contratoId || Number(newItem.cuentaContratoId));
        setValue('ticketId', newItem.ticketId);
        setValue('cuadrillaLugarId', newItem.cuadrillaLugarId);
        setValue('lugarId', newItem.lugarId);
        setValue('motivoTareaObservaciones', newItem.motivoTareaObservaciones);
        setValue('motivoTareaId', newItem.motivoTareaId);
        setValue('tipoCanalId', newItem.tipoCanalId);
        setValue('tipoTareaId', newItem.tipoTareaId);
        setTipoTareaOption({value:newItem.tipoTareaId, label:newItem.tipoTarea})
        setTemplate(newItem.motivoTareaObservaciones || '');
        if(newItem.coordenadas){
            setCoordenadas(MapaFunction.gJsonToGoogleMaps(newItem.coordenadas)[0])
            setMapLocation(MapaFunction.gJsonToGoogleMaps(newItem.coordenadas)[0])
        }else{
            setCoordenadas(currentLocation)
            setMapLocation(currentLocation)
        }
    }

    const actualizar = (tareaId) => {
        return TareasService.getById(tareaId).then((response)=>{
            setItem(response);
            setTimeout(() => {
                setIsFirstLoading(false);
            }, 300);
        })
    }

    const onBack = () =>{
        history.goBack();
    }

    const onBackTo = () => {
        history.push(backTo || '/calendario');
    };

    const copiarCoordenadas = () =>{
        const aux = document.createElement("input");
        aux.setAttribute("value",guardarCoordenadas);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }

    const onOpenCancelar = () => {
        showModal(ModalTareaCancelar, {itemModal: item, onGuardar:onBackTo});
    };

    const onGuardarSinFecha = () => {
        history.push({
            pathname: '/calendario',
        });
    };

    const onGuardarConFecha = (form) => {
        history.push({
            pathname: '/calendario',
            reprogramar: form
        });
    };

    const onOpenRepro = () => {
        showModal(ModalTareaRepro, {itemModal: tarea, onGuardarConFecha, onGuardarSinFecha});
    };

    const onClickLogs = () => {
        showModal(ModalTareaLog,{item: tarea});
    }

    const onEnviarTarea = () =>{
        history.push({
            pathname: '/calendario',
            tarea: tarea
        });
    }

    useEffect(() => {
        register('id');
        register('fecha');
        register('cuadrillaLugarId');
        register('lugarId');
        register('tipoTareaId');
        register('motivoTareaId');
        register('motivoTareaObservaciones');
        register('tipoCanalId');
        register('motivoTareaId');
        register('contratoId');
    }, []);

    const onSubmit = (data) => {
        const form = {
            ...tarea,
            ...data,
            clienteLatitud: coordenadas.lat,
            clienteLongitud: coordenadas.lng,
            horaIni: time.start,
            horaFin: time.end
        };
        if (cuadrillaLugarId > 0) {
            form.cuadrillaLugarId = cuadrillaLugarId;
        }
        setIsLoading(true);
        if(item && item.id>0 ){
            form.id = item.id;
            TareasService.update(form)
            .then((response) => {
                 history.goBack();
            })
            .finally(() => {
                setIsLoading(false);
            });
        } else {
            if(itemReprogramado && action === 'reprogramar'){
                form.tareaId = itemReprogramado.id;
                form.motivoReprogramadoObservaciones = itemReprogramado.motivoReprogramadoObservaciones;
                form.motivoReprogramadoId = itemReprogramado.motivoReprogramadoId;
            }
            TareasService.register(form)
            .then(() => {
                history.goBack();
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    };

    const setZohoDate = (data) => {
        setValue('clienteNombre', data.clienteNombre);
        setValue('clienteDocumento', data.clienteDocumento);
        setValue('clienteDireccion', data.clienteDireccion);
        setValue('clienteTelefono', AuxiliarFunction.extractNumber(data.clienteTelefono));
        setValue('clienteCelular', AuxiliarFunction.extractNumber(data.clienteCelular));
        setValue('clienteCategoria', data.clienteCategoria);
        setValue('coontratoId', data.contratoId || Number(data.cuentaContratoId));
        if(data.coordenadas){
            setCoordenadas(data.coordenadas)
            setMapLocation(data.coordenadas)
            setGuardarCoordenadas()
        }else{
            setCoordenadas(currentLocation)
            setMapLocation(currentLocation)
        }
    };

    useEffect(()=>{
        if(id>0){
            actualizar(id);
        }
    },[id]);

    return (
                <div className="container-fluid p-3 border rounded-lg bg-white">
                    <div className="row">
                        <div className="col-1">
                            <button type="button" className="btn btn-primary btn-regresar" onClick={onBack}>
                                <i className="fas fa-arrow-left"></i>
                            </button>
                        </div>
                        <div className="col-11 col-lg-5 my-lg-1">
                            <h2>
                                {
                                    id > 0 ? (
                                        `Tarea Editar: ${id} - ${tarea.estadoTarea??''} ${tarea.ticketId??''}`
                                    ):(
                                        `Tarea Nueva`
                                    )
                                }
                                {
                                    isLoading && <Spinner className="ml-2 mb-2 spinner-border text-primary" animation="border" />
                                }
                            </h2>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                    {
                                        permisos['api.logs.tareas'] && (
                                        <div className="offset-5 col-2">
                                            {
                                                id > 0 && (
                                                    <Button className="btn btn-gray btn-block p-2" onClick={()=>onClickLogs()}>
                                                        LOGS
                                                        <i className="fas fa-list ml-2">
                                                        </i>
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    )}
                                    <div className="col-5">
                                        <Button
                                            className="btn btn-block p-2 btn-warning text-dark"
                                            type="button"
                                            onClick={copiarCoordenadas}
                                        >
                                            COPIAR COORDENADAS
                                        </Button>
                                    </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            {
                                tarea.cuadrillaDisponibleLugar && (
                                    <CuadrillaDisponibleCard item={tarea.cuadrillaDisponibleLugar} />
                                )
                            }
                        </div>
                        {
                            tarea.flotas && tarea.flotas.map((flota)=>{
                                return (
                                    <div className="col-4" key={flota.id}>
                                        <FlotaCard item={flota} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="fecha"
                                    >
                                        Fecha
                                    </label>
                                    {
                                        item.fecha ? (
                                            <DateSingle
                                                id="fecha"
                                                value={fecha}
                                                disabled={tarea.id>0}
                                                onChange={(value)=>setValue('fecha',value)}
                                            />
                                        ) : (
                                            <Button className="btn btn-primary btn-block p-2" onClick={onEnviarTarea}>
                                                Poner en calendar
                                            </Button>
                                        )
                                    }
                                    {errors.fecha ? (
                                        <div className="default-invalid-feedback">
                                            {errors.fecha?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="lugarId"
                                    >
                                        Lugar
                                    </label>
                                    <SelectLugar
                                        id="lugarId"
                                        value={lugarId}
                                        isInvalid={Boolean(errors.lugarId)}
                                        onChange={(opt) => {
                                            setValue('lugarId', opt.value);
                                        }}
                                        disabled
                                    />
                                    {errors.lugarId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.lugarId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoTareaId"
                                    >
                                        Tipo de Tarea
                                    </label>
                                    <SelectTipoTarea
                                        id="tipoTareaId"
                                        value={tipoTareaOption}
                                        isInvalid={Boolean(errors.tipoTareaId)}
                                        onChange={(opt) => {
                                            setValue('tipoTareaId', opt.value);
                                            setTipoTareaOption(opt);
                                            setTemplate('');
                                        }}
                                        isLoading={isFirstLoading}
                                    />
                                    {errors.tipoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaId"
                                    >
                                        Motivo de la Tarea
                                    </label>
                                    <SelectMotivoTarea
                                        id="motivoTareaId"
                                        value={motivoTareaId}
                                        tipoTareaId={tipoTareaId}
                                        isInvalid={Boolean(
                                            errors.motivoTareaId
                                        )}
                                        onChange={(opt) => {
                                            setValue(
                                                'motivoTareaId',
                                                opt.value
                                            );
                                            setValue(
                                                'motivoTareaObservaciones',
                                                opt.descripcion
                                            );
                                            setTemplate(opt.descripcion);
                                        }}
                                        isLoading={isFirstLoading}
                                    />
                                    {errors.motivoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.motivoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaObservaciones"
                                    >
                                        Observaciones
                                    </label>
                                    <InputEditor
                                        id="motivoTareaObservaciones"
                                        template={template}
                                        disabled={!(tipoTareaId > 0)}
                                        onChange={(html) => {
                                            setValue(
                                                'motivoTareaObservaciones',
                                                html
                                                );
                                            }}
                                    />
                                    {errors.motivoTareaObservaciones ? (
                                        <div className="default-invalid-feedback">
                                            {
                                                errors.motivoTareaObservaciones
                                                ?.message
                                            }
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-12 mt-1 mb-2">
                                <TimeSlider
                                    defaultPeriodo={tarea.id>0?CalendarFunction.getPeriodoFromTime(tarea.horaIni):null}
                                    value={time}
                                    onChange={setTime}
                                    id="timerInput"
                                />
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <label
                                    className="label-control"
                                    htmlFor="clienteNombre"
                                >
                                    Nombre del cliente
                                </label>
                                <Controller
                                    disabled
                                    as={InputZohoSearch}
                                    control={control}
                                    field="nombreCompleto"
                                    name="clienteNombre"
                                    id="clienteNombre"
                                    onSelect={(opt) => {
                                        if (opt && opt.length > 0) {
                                            setZohoDate(opt[0]);
                                        }
                                    }}
                                />
                                {errors.clienteNombre&& (
                                    <div className="default-invalid-feedback">
                                        {errors.clienteNombre?.message}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <label
                                    className="label-control"
                                    htmlFor="clienteDocumento"
                                >
                                    Dni del cliente
                                </label>
                                <Controller
                                    disabled
                                    as={InputZohoSearch}
                                    control={control}
                                    field="documento"
                                    name="clienteDocumento"
                                    id="clienteDocumento"
                                    onSelect={(opt) => {
                                        if (opt && opt.length > 0) {
                                            setZohoDate(opt[0]);
                                        }
                                    }}
                                />
                                {errors.clienteDocumento ? (
                                    <div className="default-invalid-feedback">
                                        {errors.clienteDocumento?.message}
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="contratoId"
                                    >
                                        Contrato ID
                                    </label>
                                    <input
                                        readOnly
                                        id="contratoId"
                                        className={`form-control ${
                                            errors.contratoId ? 'is-invalid' : ''
                                        }`}
                                        type="text"
                                        name="contratoId"
                                        ref={register}
                                        autoComplete="off"
                                    />
                                    {errors.contratoId ? (
                                        <div className="invalid-feedback">
                                            {errors.contratoId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="ticketId"
                                    >
                                        Ticket
                                    </label>
                                    <input
                                        disabled
                                        id="ticketId"
                                        className={`form-control ${
                                            errors.ticketId ? 'is-invalid' : ''
                                        }`}
                                        type="text"
                                        name="ticketId"
                                        ref={register}
                                        autoComplete="off"
                                    />
                                    {errors.ticketId ? (
                                        <div className="invalid-feedback">
                                            {errors.ticketId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteDireccion"
                                    >
                                        Dirección
                                    </label>
                                    <input
                                        id="clienteDireccion"
                                        className={`form-control ${
                                            errors.clienteDireccion
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteDireccion"
                                        ref={register}
                                        autoComplete="off"
                                    />
                                    {errors.clienteDireccion ? (
                                        <div className="invalid-feedback">
                                            {errors.clienteDireccion?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteTelefono"
                                    >
                                        Telefono
                                    </label>
                                    <input
                                        id="clienteTelefono"
                                        className={`form-control ${
                                            errors.clienteTelefono
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteTelefono"
                                        ref={register}
                                        autoComplete="off"
                                    />
                                    {errors.clienteTelefono ? (
                                        <div className="invalid-feedback">
                                            {errors.clienteTelefono?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteCelular"
                                    >
                                        Celular
                                    </label>
                                    <input
                                        id="clienteCelular"
                                        className={`form-control ${
                                            errors.clienteCelular
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteCelular"
                                        ref={register}
                                        autoComplete="off"
                                    />
                                    {errors.clienteCelular ? (
                                        <div className="invalid-feedback">
                                            {errors.clienteCelular?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="categoria"
                                    >
                                        Categoria
                                    </label>
                                    <select
                                        id="categoria"
                                        name="categoria"
                                        className={`form-control ${
                                            errors.categoria ? 'is-invalid' : ''
                                        }`}
                                        disabled={tarea.id>0}
                                        ref={register}
                                    >
                                        <option value="">
                                            Seleccione una opcion
                                        </option>
                                        <option value="NORMAL">NORMAL</option>
                                        <option value="VIP 1">VIP 1</option>
                                        <option value="VIP 2">VIP 2</option>
                                        <option value="VIP 3">VIP 3</option>
                                    </select>
                                    {errors.categoria ? (
                                        <div className="invalid-feedback">
                                            {errors.categoria?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoCanalId"
                                    >
                                        Canal
                                    </label>
                                    <SelectTipoCanal
                                        id="tipoCanalId"
                                        value={tipoCanalId}
                                        onChange={(opt) => {
                                            setValue('tipoCanalId', opt.value);
                                        }}
                                        isLoading={isFirstLoading}
                                    />
                                    {errors.tipoCanalId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoCanalId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="coordenadas"
                                    >
                                        Coordenadas
                                    </label>
                                    <input
                                    id='coordenadas'
                                    className="form-control"
                                    value={`${parseFloat(coordenadas.lat).toFixed(5)}, ${parseFloat(coordenadas.lng).toFixed(6)}`}
                                    disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 border bg-white shadow-sm ml-1 mt-2 mt-md-0 mt-lg-0 py-2 rounded">
                                <MapClick
                                    id="mapaComercial"
                                    zoom={15}
                                    coordinates={mapLocation}
                                    onChange={setCoordenadas}
                                    options={{
                                        maxZoom: 19,
                                        minZoom: 14,
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="col-12 my-lg-4 col-lg-12">
                        <div className="row">
                            <div className="col-6 col-lg-4">
                                {
                                    id>0 && (
                                        <>
                                            {
                                                permisos['api.tareas.destroy'] && (
                                                <Button color="primary" className="btn btn-danger" onClick={() => onOpenCancelar()} disabled={tarea.estadoTareaId === 6 || tarea.estadoTareaId === 4}>
                                                    Cancelar Cita
                                                </Button>
                                            )}
                                            {
                                                permisos['api.reprogramados.index'] && (
                                                <Button color="primary" className="btn btn-warning" onClick={() => onOpenRepro()} disabled={tarea.estadoTareaId === 4 || tarea.estadoTareaId === 7}>
                                                    Reprogramar Cita
                                                </Button>
                                            )}
                                        </>

                                    )
                                }
                            </div>
                            <div className="col-6 offset-lg-4 col-lg-4 text-right">
                                {/* <Link to={backTo || "/calendario"} className="btn btn-gray btn-sm">
                                    Cancelar
                                </Link> */}
                                <button onClick={onBack} className="btn btn-gray btn-sm" type="button">
                                    Cancelar
                                </button>
                                {
                                    (permisos['api.tareas.store'] ||
                                    permisos['api.tareas.update']) && (
                                    <Button
                                        className="btn btn-primary"
                                        theme="primary"
                                        onClick={handleSubmit(onSubmit)}
                                        isLoading={isLoading}
                                        disabled={tarea.estadoTareaId === 4 || tarea.estadoTareaId === 6}
                                    >
                                        Guardar
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
    );
}

TareaNuevo.defaultProps = {
    item: {
        id: 0,
        latitud: -24.7821,
        longitud: -65.4232,
        motivoTareaObservaciones: '',
        lugarId: 0,
        tipoTareaId: 0,
        tipoCanalId: 0,
        motivoTarea: {
            id: 0,
        }
    },
    cuadrillas: []
};

const mapStateToProps = (state) => ({
    itemReprogramado: state.calendar.itemReprogramado,
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    agregarReprogramacion: (data) =>  dispatch(CalendarAction.agregarReprogramacion(data)),
    recuperar: (data) => dispatch(CalendarAction.recuperarEventos(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TareaNuevo);
