import React from 'react';
import FechaFunction from '@app/functions/FechaFunction';

function LogTareaCard({
        item
    }) {
    let tipo = "";
    if(item.estadoTareaIdNuevo === 1 && item.estadoTareaIdPrevio === null){
        tipo = "ALTA";
    }
    if(item.estadoTareaIdPrevio === 1 && item.estadoTareaIdNuevo === 1){
        tipo = "MODIFICAR";
    }
    if(item.estadoTareaIdNuevo === 2){
        tipo = "A DESIGNAR";
    }
    if(item.estadoTareaIdNuevo === 3){
        tipo = "ASIGNADO";
    }
    if(item.estadoTareaIdNuevo === 4){
        tipo = "REPROGRAMAR CON FECHA";
    }
    if(item.estadoTareaIdNuevo === 6){
        tipo = "ELIMINAR";
    } 
    if(item.estadoTareaIdPrevio === 2 && item.estadoTareaIdNuevo === 1){
        tipo = "A DESIGNAR";
    }
    if(item.estadoTareaIdNuevo === 7){
        tipo = "REPROGRAMAR SIN FECHA";
    }
    if(item.estadoTareaIdPrevio === 8){
        tipo = "TAREA PENDIENTE";
    }
    const mods = Object.keys(item.old);

    const descripcion = [item.persona && item.persona.nombre?item.persona.nombre:"S/N",tipo,item.tarea && item.tarea.clienteDocumento?item.tarea.clienteDocumento:"S/DNI",item.tarea && item.tarea.fecha?FechaFunction.format(item.tarea.fecha,'dd/MM/yy'):"SIN FECHA"];
    // const descripcion = (`${item.persona.nombre} Realizo la accion ${tipo} del cliente con DNI/CUIT ${item.tarea.clienteDocumento} pactada para el dia ${FechaFunction.format(item.tarea.fecha,'dd/MM/yy')}`);

    return (
        <div className="card border border mb-2 p-2 mx-1">
            <div className="row">
                <div className="col-12 card-header">
                    {`${FechaFunction.format(item.fecha,'dd/MM/yy HH:mm:ss')}
                    
                    ID: ${item.id}`}
                </div>
                <div className="col-12 card-body">

                    <span className="font-weight-bold">
                        {` ${descripcion[0]} `}
                    </span>
                    <span>
                        Realizo la accion
                    </span>
                    <span className="font-weight-bold">
                        {` ${descripcion[1]} `}
                    </span>
                        del cliente con DNI/CUIT
                    <span className="font-weight-bold">
                        {` ${descripcion[2]} `}
                    </span>
                        pactada para el dia
                    <span className="font-weight-bold">
                        {` ${descripcion[3]} `}
                    </span>

                </div>
                <div className="col-12">

                    {
                        mods.length > 0 && (
                            <table className="table table-responsive-sm table-bordered table-responsive table-sm">
                                <thead className="bg-primary t-white">
                                    <tr>
                                        <th>CAMPO</th>
                                        <th>ANTES</th>
                                        <th>LUEGO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    mods.map((key,index)=>{
                                        const old = (item.old[key] === null || item.old[key] === "null") ? ('-'):(item.old[key]);
                                        const nuevo = (item.new[key] === null || item.old[key] === "null") ? ('-'):(item.new[key]);
                                        return (
                                            <tr key={index}>
                                                <td>{key.split("_",1)}</td>
                                                <td>{old}</td>
                                                <td>{nuevo}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default LogTareaCard;
