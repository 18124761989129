import FlotaJugadoresService from '@app/services/flotaJugadores.service';

export const CUADRILLAS_LUGARES = 'CUADRILLAS_LUGARES';
export const CUADRILLAS_JUGADORES = 'CUADRILLAS_JUGADORES';
export const CUADRILLAS_TAREAS = 'CUADRILLAS_TAREAS';

export const CUADRILLAS_LUGARES_MODIFICAR = 'CUADRILLAS_LUGARES_MODIFICAR';
export const CUADRILLAS_LUGARES_LIMPIAR = 'CUADRILLAS_LUGARES_LIMPIAR';

export const CUADRILLAS_TAREAS_AGREGAR = 'CUADRILLAS_TAREAS_AGREGAR';
export const CUADRILLAS_TAREAS_MODIFICAR = 'CUADRILLAS_TAREAS_MODIFICAR';
export const CUADRILLAS_TAREAS_LIMPIAR = 'CUADRILLAS_TAREAS_LIMPIAR';

export const recuperarFlotas = (data) => (dispatch) => {
    return FlotaJugadoresService.getAll(data).then((response) => {
        dispatch({
            type: CUADRILLAS_JUGADORES,
            payload: response
        });
        return Promise.resolve(response);
    });
};

export const iniciarCuadrillas = (data) => ({
    type: CUADRILLAS_LUGARES,
    payload: data
});

export const modificarCuadrillas = (data) => ({
    type: CUADRILLAS_LUGARES_MODIFICAR,
    payload: data
});

export const limpiarCuadrillas = () =>({
    type: CUADRILLAS_LUGARES_LIMPIAR,
});

export const iniciarTareas = (data) => ({
    type: CUADRILLAS_TAREAS,
    payload: data
});

export const agregarTarea = (data) => ({
    type: CUADRILLAS_TAREAS_AGREGAR,
    payload: data
});

export const modificarTarea = (data) => ({
    type: CUADRILLAS_TAREAS_MODIFICAR,
    payload: data
});

export const limpiarTareas = () =>({
    type: CUADRILLAS_TAREAS_LIMPIAR,
});