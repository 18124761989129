import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const recibosSlice = createSlice({
    name: 'recibos',
    initialState: {
        detalles: [],
    },
    reducers: {
        setDetalles: (state,action) => {
            state.detalles = action.payload;
        },
        addDetalle: (state,action) => {
            state.detalles = [ ...state.detalles, action.payload];
        },
        deleteDetalle: (state,action) => {
            const { id } = action.payload;
            const aux = [...state.detalles];
            const index = aux.findIndex(ite=>id===ite.id)
            aux.splice(index,1);
            state.detalles = aux;
        },
    },
});



export const { addDetalle, setDetalles, deleteDetalle } = recibosSlice.actions;

export default recibosSlice.reducer
