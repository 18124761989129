import axios from 'axios';
const base = 'zoho/insertNotes';

function register(data) {
    return axios.post(base, data);
}

const ZohoNotaImagenService = {
    register: register,
};
export default ZohoNotaImagenService;