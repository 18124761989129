import {
    CUADRILLAS_LUGARES,
    CUADRILLAS_JUGADORES,
    CUADRILLAS_TAREAS,

    CUADRILLAS_LUGARES_MODIFICAR,
    CUADRILLAS_LUGARES_LIMPIAR,

    CUADRILLAS_TAREAS_AGREGAR,
    CUADRILLAS_TAREAS_MODIFICAR,
    CUADRILLAS_TAREAS_LIMPIAR,
} from '../actions/cuardillas';
const initialState = {
    flotas: [],
    cuadrillas: [],
    tareas: [],
};

const reducer = (state = initialState, {type, payload}) => {
    const { cuadrillas, flotas, tareas } = state;
    let aux;
    let aux2;
    switch (type) {
        case CUADRILLAS_JUGADORES:
            return {
                ...state,
                flotas: payload,
            };
        case CUADRILLAS_LUGARES:
            return {
                ...state,
                cuadrillas: payload,
            };
        case CUADRILLAS_LUGARES_MODIFICAR:
            aux2 = [...cuadrillas];
            aux = aux2.findIndex(
                (fj) => fj.cuadrillaLugarId === payload.cuadrillaLugarId
            );
            if(aux>=0){
                aux2[aux]=payload;
            }
            return {
                ...state,
                cuadrillas: aux2,
            };
        case CUADRILLAS_LUGARES_LIMPIAR:
            return {
                ...state,
                cuadrillas: [...cuadrillas],
            };

        case CUADRILLAS_TAREAS:
            return {
                ...state,
                tareas: payload,
            };
        case CUADRILLAS_TAREAS_AGREGAR:
            aux2 = [...tareas];
            aux = aux2.findIndex((t) => t.id === payload.id);
            if(aux<0){
                aux2.push(payload);
            } else {
                aux2[aux]=payload;
            }
            return {
                ...state,
                tareas: aux2,
            };
        case CUADRILLAS_TAREAS_MODIFICAR:
            aux2 = [...tareas];
            aux = aux2.findIndex((t) => t.id === payload.id);
            if(aux>=0){
                aux2[aux]=payload;
            }
            return {
                ...state,
                tareas: aux2,
            };
        case CUADRILLAS_TAREAS_LIMPIAR:
            return {
                ...state,
                tareas: [],
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;
