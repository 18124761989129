import React, { useState } from 'react'
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ContactosService from '@app/services/contactos.service';
import { Button, FormContacto,FormContactoSchema } from '@app/components/index';
import ContactosAction from '@app/store/actions/contactos';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

function TabContactoEditar({
    contacto,
    iniciarContacto,
}) {
    const [isLoading, setIsLoading] = useState(false);

    const {handleSubmit,control,errors,register, setValue, setError} = useForm({
        resolver: yupResolver(FormContactoSchema),
        defaultValues: contacto,
    });

    const onSubmit = (data) => {
        const form = {
            ...contacto,
            ...data,
        };
        setIsLoading(true);
        ContactosService.update(form).then((response)=>{
            iniciarContacto(response);
        })
        .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
        .finally(()=>{
            setIsLoading(false);
        });
    };

    return (
        <div className="p-5">
            <FormContacto isModal control={control} errors={errors} register={register} setValue={setValue} hideTareaForm />
            <Button
                theme="primary"
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
            >
                GUARDAR
            </Button>
        </div>
    )
}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
})

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TabContactoEditar)
