import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import AOS from 'aos';
import {useTranslation} from 'react-i18next';
import AsesorService from '@services/asesor.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm, SelectLugar} from '@app/components/index';
import {Link, useHistory} from 'react-router-dom';

const AsesorPage = () => {
    AOS.init();
    const history = useHistory();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const query = {
            puesto : 'Asesor',
        };


        const response = await AsesorService.getAll(query);
        setItems(response);
        // console.log(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Asesor',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                AsesorService.destroy(item.dni).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenVer = (item) => {
        history.push({
            pathname: `/personas/${item.dni}/ver`,
            state: {
                item: item
            }
        });
    }

    const onOpenEditar = (item) => {
        history.push({
            pathname: `/personas/${item.dni}/editar`,
            state: {
                item: item
            }
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Apellido y Nombre',
                accessor: 'nombre',
                Cell: ({row}) =>{
                    const { original } = row;
                    return `${original.apellido} ${original.nombre}`;
                }
            },
            {
                Header: 'Teléfono',
                accessor: 'telefono'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Domicilio',
                accessor: 'domicilio'
            },
            {
                Header: 'Barrio',
                accessor: 'barrio'
            },
            {
                Header: 'Localidad',
                ClassName: 'm-0',
                accessor: 'localidad.nombre',
                Cell: ({row}) =>{
                    const { original } = row;
                    if(original.localidad){
                        return original.localidad.nombre;
                    }
                    return '';
                },
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectLugar
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.label) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
            },
            {
                Header: 'Venc. de Licencia',
                accessor: 'vtoLicencia'
            },
            {
                Header: 'Cuit/Cuil',
                accessor: 'cuit'
            },
            {
                width: 10,
                Header: '',
                accessor: 'dni',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenVer(row.original)}
                                >
                                    Ver
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 col-lg-6 my-lg-3" data-aos="fade-right">
                        <h3 className="ml-lg-3">Listado de asesores</h3>
                        <p className='text-gray ml-lg-3'>En este apartado puede visualizar una lista de todos los asesores registrados en el sistema.</p>
                    </div>
                    <div className="offset-2 col-4 offset-lg-4 col-lg-2 my-lg-3" data-aos="fade-up">
                        <Link to="/personas/nuevo">
                            <Button className="btn-block">
                                NUEVO
                            </Button>
                        </Link>
                    </div>
                    <div className="col-12" data-aos="fade-right">
                        <div className="container-fluid">
                            <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AsesorPage);
