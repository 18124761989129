import {parseISO} from 'date-fns';
import { saveAs } from 'file-saver';
import colorLib from '@kurkle/color';

function toIntegerNumber(string,response = null){
    const parse = Number(string);
    if(Number.isNaN(parse)){
        return response;
    }
    return parse;
}

function isNormalInteger(str) {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}


function isObject(object){
    return typeof object === 'object' && object !== null;
}

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
   

function getContrastYIQ(hexcolor) {
    if (hexcolor == null) {
        return 'black';
    }
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
}

export function getTransparent(value, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
  }

function getTareaEstadoBadge(estado) {
    let response = '';
    switch (estado) {
        case 1:
            /*
            EN ESPERA
            */
            response = `<span class="badge badge-pill bg-primary">EN ESPERA</span>`;
            break;
        case 2:
            /*
            A DESIGNAR
            */
            response = `<span class="badge badge-pill bg-secondary">A DESIGNAR</span>`;
            break;
        case 3:
            /*
            ASIGNADO
            */
            response = `<span class="badge badge-pill bg-success">ASIGNADO</span>`;
            break;
        case 4:
            /*    
            REPROGRAMADO
            */
            response = `<span class="badge badge-pill bg-warning text-dark">REPROGRAMADO</span>`;
            break;
        case 5:
            /*
            VENCIDO
            */
            response = `<span class="badge badge-pill bg-warning text-dark">VENCIDO</span>`;
            break;
        case 6:
            /*
            ELIMINADO
            */
            response = `<span class="badge badge-pill bg-danger">ELIMINADO</span>`;
            break;
        case 7:
            /*
            PARA REPROGRAMAR
            */
            response = `<span class="badge badge-pill bg-warning text-dark">PARA REPROGRAMAR</span>`;
            break;
        default:
            break;
    }
    return response;
}

function setItem(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
}

function getItem(key, defaultReturn = null) {
    let item;
    try {
        item = JSON.parse(localStorage.getItem(key));
        if (item === null) {
            item = defaultReturn;
        }
    } catch (e) {
        item = defaultReturn;
    }
    return item;
}

function removeItem(key) {
    localStorage.setItem(key);
}

function validaDateString(value) {
    let response;
    if (typeof value === 'string') {
        response = parseISO(value);
    } else {
        response = value;
    }
    return response;
}

function primeraLetraNombre(nombre){
    if(nombre == null){
        return "";
    }
    return nombre.split(/\s/).reduce((response,word) => {
        return response+word.slice(0,1)
    },'');
}

function extractNumber(string){
    if(String(string).length === 0 || string === null){
        return '';
    }
    const regex = /\d+/g;
    return regex.exec(string).join('');
}

function extractErrors({errors},setError){
    if(errors){
        Object.keys(errors).forEach((key)=>{
            if(errors[key]){
                setError(key, {
                    type: "manual",
                    message: errors[key][0],
                });
            }
        });
    }
}

function jsonToFormData(data) {
    const formData = new FormData()
    const entries = Object.entries(data)
    for (let i = 0; i < entries.length; i+=1) {
        const arKey = entries[i][0]
        let arVal = entries[i][1]
        if (typeof arVal === 'boolean') {
            arVal = arVal === true ? 1 : 0
        }
        if (Array.isArray(arVal)) {
            if (this.isFile(arVal[0])) {
                for (let z = 0; z < arVal.length; z+=1) {
                    formData.append(`${arKey}[]`, arVal[z])
                }
                // eslint-disable-next-line no-continue
                continue;
            } else if (arVal[0] instanceof Object) {
                for (let j = 0; j < arVal.length; j+=1) {
                    if (arVal[j] instanceof Object) {
                    
                    // eslint-disable-next-line no-restricted-syntax
                    for (const prop in arVal[j]) {
                        if (Object.prototype.hasOwnProperty.call(arVal[j], prop)) {
                        
                        // eslint-disable-next-line no-restricted-globals
                        if (!isNaN(Date.parse(arVal[j][prop]))) {
                            formData.append(
                            `${arKey}[${j}][${prop}]`,
                            new Date(arVal[j][prop])
                            )
                        } else {
                            formData.append(`${arKey}[${j}][${prop}]`, arVal[j][prop])
                        }
                        }
                    }
                    }
                }
                // eslint-disable-next-line no-continue
                continue;
            } else {
                arVal = JSON.stringify(arVal)
            }
        }

        if (arVal === null) {
            // eslint-disable-next-line no-continue
            continue;
        }
        formData.append(arKey, arVal)
    }
    return formData
}

function formatMatricula(value){
    let rsp = '';
    if(value && value.length>6){
        rsp = value.replace(/(\w{2})(\w{3})(\w{2})/, '$1 $2 $3');
    } else if(value && value.length === 6) {
        rsp = value.replace(/(\w{3})(\w{3})/, '$1 $2');
    }
    return rsp;
}

function downloadFile(promise){
    return promise.then((response)=>{
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'SinNombre.xlsx';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/(?<=filename=).*/);
            [fileName,] = fileNameMatch;
        }
        saveAs(response.data, fileName);
    })
}

function formatQueryWithSearch(query){
    const params = new URLSearchParams();
    if(query){
        Object.keys(query).forEach(key=>{
            if(key === 'search' && Array.isArray(query[key])){
                query[key].forEach(opt=>{
                    params.set(`search[${opt.id}]`,opt.value);
                });
            } else if(typeof query[key] !== "undefined"){
                params.set(key,query[key]);
            }
        })
    }
    return params;
}

const AuxiliarFunction = {
    isObject: isObject,
    isFunction: isFunction,
    isNormalInteger: isNormalInteger,
    toIntegerNumber: toIntegerNumber,
    formatQueryWithSearch: formatQueryWithSearch,
    getContrastYIQ: getContrastYIQ,
    getTareaEstadoBadge: getTareaEstadoBadge,
    getTransparent: getTransparent,
    setItem: setItem,
    getItem: getItem,
    removeItem: removeItem,
    validaDateString: validaDateString,
    primeraLetraNombre: primeraLetraNombre,
    extractNumber: extractNumber,
    extractErrors: extractErrors,
    jsonToFormData: jsonToFormData,
    downloadFile: downloadFile,
    formatMatricula: formatMatricula,
};
export default AuxiliarFunction;
