import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { MapDrawing,Button,SelectLugar,SelectPersonal, Poligono, InputCheck } from '@app/components/index';
import AsesorxLugaresService from '@app/services/asesorxLugares.service';
import * as MapaAction from "@app/store/actions/mapas";
import MapaFunction from '@app/functions/MapaFunction';
import useCurrentLocation from "@app/hooks/useCurrentLocation"
import { useHistory } from 'react-router';

function AsesorxLugarPage ({
    poligonos,
    esMostrarPaneles,
    recuperarPoligono,
    cambiarMostrarPanel,
}) {
    const { location: currentLocation, error: currentError } = useCurrentLocation();

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [validarLugar, setValidarLugar] = useState(true);
    const [items, setItems] = useState([]);
    const [lugarId, setLugarId] = useState(0);
    const [personaId, setPersonaId] = useState(0);
    const [selectedShape, setSelectedShape] = useState(null);
    const [poligonosSelect, setPoligonosSelect] = useState(null);
    const [currentCoordinates, setCurrentCoordinates] = useState(currentLocation);
    const [stackRefs, setStackRefs] = useState();

    const recuperar = (coord) => {
        const query = {
            coordenadas: `${coord.lat},${coord.lng}`,
        }
        recuperarPoligono(query);
    }

    const onBack = () =>{
        history.goBack()
    }

    const actualizar = () => {
        const form = {
            lugarId:lugarId,
            personaId:personaId,
        };
        AsesorxLugaresService.getAll(form).then((response)=>{
            setItems(response);
        })
        .catch(()=>{
            setItems([]);
        });

    };
    const onClickGuardar = () => {
        if(lugarId!==0 && validarLugar ===true){
            if(items.length>0 && stackRefs && !selectedShape){
                const forms = [];
                setIsLoading(true);
                items.forEach((item)=>{
                    const shape = stackRefs.current[item.id];
                    const form = {
                        ...item,
                        aristas: MapaFunction.getCoordinatesFromShape(shape),
                    };
                    forms.push(AsesorxLugaresService.update(form));
                });
                Promise.all(forms).then(()=>{
                    actualizar();
                })
                .finally(()=>{
                    setIsLoading(false);
                });
                return;
            }
            if(personaId === null){
                return;
            }
            if(lugarId === null){
                return;
            }
            const form = {
                dni: personaId,
                lugarId: lugarId,
                aristas: MapaFunction.getCoordinatesFromShape(selectedShape),
            };
            setIsLoading(true);
            AsesorxLugaresService.register(form).then(()=>{
                setSelectedShape(null);
                actualizar();
            })
            .finally(()=>{
                setIsLoading(false);
            });
        }
    }

    const onClickRemove = (item) => {
        AsesorxLugaresService.destroy(item.id).then(()=>{
            actualizar();
        });
    }

    const onSelectedShape = (shape) => {
        if(!shape){
            setSelectedShape(null);
            return ;
        }
        setSelectedShape(shape);
        const aristas = MapaFunction.getCoordinatesFromShape(shape);
    }

    const onSelectCobertura = (newPoligono) => {
        setPoligonosSelect(newPoligono);
    }

    const onClickGuardarCobertura = (newPoligono) => {
        setPoligonosSelect(null);
        const form = {
            ...newPoligono,
            /*
            aristas: MapaFunction.getCoordinatesFromShape(stackRefs.current[newPoligono.id]),
            */
        };
        AsesorxLugaresService.update(form).then(()=>{
            actualizar();
        })
    }

    const onChangeLugar = (opt) => {
        if(opt !==null){
            setLugarId(opt.value);
            setValidarLugar(true);
            let newCoord;
            if(opt.item.coordenadas){
                newCoord = MapaFunction.gJsonToGoogleMaps(opt.item.coordenadas).pop();
            } else {
                newCoord = MapaFunction.gJsonToGoogleMaps(opt.item.coordenadas).pop();;
            }
            setCurrentCoordinates(newCoord);
            recuperar(newCoord);
        }else if(personaId===0){
            setValidarLugar(true);
            setLugarId(0);
        }else{
            setValidarLugar(false);
            setLugarId(0);


        }
    }
    const onChangePersonal = (opt) => {
        if(opt !=null){
            setPersonaId(opt.value)
            setValidarLugar(false);
        }else{
            setPersonaId(0)
            setValidarLugar(true);
        }
    }

    /* const onSelectLugar = ({item}) => {
        let newCoord;
        const { coordenadas } = item;
        if(coordenadas){
            newCoord = MapaFunction.gJsonToGoogleMaps(coordenadas).pop();
        } else {
            newCoord = {lat:item.latitud,lng:item.longitud};
        }
        setCurrentCoordinates(newCoord);
        recuperar(newCoord);
    } */


    useEffect(() => {
        actualizar();
    }, [personaId,lugarId,currentCoordinates]);

    useEffect(() => {
        recuperar(currentLocation);
    }, []);

    const unionPoligonos = MapaFunction.unirPoligonos(poligonos);

    // console.log(currentLocation)
    // console.log(MapaFunction.unirPoligonos(poligonos));
    // console.log(currentCoordinates);


     return (
            <div className="container-fluid text-center">
                <div className="row">
                            <div className="col-lg-1">
                                <button className="btn btn-primary btn-regresar mt-3" type="button" onClick={onBack}>
                                    <i className="fas fa-arrow-left" />
                                </button>
                            </div>
                            <div className="col-lg-8">
                                <h2 className="text-left mt-3">Mapa Asesores</h2>
                            </div>

                            <div className="col-lg-2">
                                <InputCheck className="mt-3" checked={esMostrarPaneles} label='Mostrar Paneles' onClick={cambiarMostrarPanel} />
                            </div>

                            <div className="col-4">
                                <Button className="btn btn-block mt-5" onClick={onClickGuardar} isLoading={isLoading}>
                                    GUARDAR
                                </Button>
                            </div>

                            <div className="col-4">
                                <label className="label-control mb-3" htmlFor="personaId">
                                     Buscar asesor
                                </label>
                                <SelectPersonal
                                    filtroAsesor
                                    id="personaId"
                                    onChange={onChangePersonal}
                                    isClearable
                                >
                                </SelectPersonal>
                            </div>

                            <div className="col-4">
                                    <label className={validarLugar ? ("text-dark mb-3") : ("text-danger mb-3")}>
                                        {validarLugar ? (

                                            <span>Ciudad</span>
                                        ) : (
                                            <span>
                                                <i className="fas fa-exclamation-circle mr-2"></i>
                                                Ciudad
                                            </span>
                                        )}
                                    </label>
                                <SelectLugar
                                    id="lugarId"
                                    onChange={onChangeLugar}
                                    isClearable
                                >
                                </SelectLugar>
                            </div>

                            <div className="mt-2 col-lg-12 p-lg-2 bg-white shadow-sm rounded border">
                                <MapDrawing
                                    id="mapaComercial"
                                    isLoading={isLoading}
                                    options={{
                                        maxZoom: 20,
                                        minZoom: 11,
                                    }}
                                    coordinates={currentCoordinates}
                                    /*
                                    onAddPolygon={onSubmit}
                                    */
                                    items={items}
                                    onSelectShape={onSelectedShape}
                                    onInstaced={setStackRefs}
                                    onRemove={onClickRemove}
                                >
                                    {
                                        esMostrarPaneles && unionPoligonos.map(poly=>{
                                            return <Poligono item={poly} key={poly.id} />
                                        })
                                    }
                                </MapDrawing>
                            </div>
                </div>
            </div>
     )
};

const mapStateToProps = (state) => ({
    poligonos: state.mapas.poligonos,
    prefactibilidad: state.mapas.prefactibilidad,
    esMostrarPaneles: state.mapas.esMostrarPaneles,
});

const mapDispatchToProps = (dispatch) => ({
    recuperarPoligono: (data) => dispatch(MapaAction.recuperarPoligonos(data)),
    recuperarTarea: (data) => dispatch(MapaAction.recuperarTareas(data)),
    cambiarMostrarPanel: (data) => dispatch(MapaAction.cambiarMostrarPaneles(data)),
    cambiarMostrarTarea: (data) => dispatch(MapaAction.cambiarMostrarTareas(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsesorxLugarPage);
