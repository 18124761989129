import axios from 'axios';
const base = 'zoho';

function searchContacts(data) {
    return axios.get([base, 'searchContacts'].join('/'), {
        params: data
    });
}

const ZohoService = {
    searchContacts: searchContacts
};
export default ZohoService;
