import React from 'react'
import styled from 'styled-components';
import { isSameDay } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';
import { Button } from '@app/components/index';
import Col from '@app/../node_modules/react-bootstrap/esm/Col';

const Styles = styled.div`
    .day-header-content-week{
        display: flex;
        justify-content: center;
    }
`;
function DayHeaderContentWeek({
    date,
    cuadrillas,
    onClick,
}) {
    let total = 0;
    cuadrillas.forEach( el => {
        total += el;
        if(total < 0){
            total = 0;
        }
    });
    const tareasDisponibles = `T:${total}`
    const now = new Date();
    let title = '';
    let todayClass = '';
    if (isSameDay(date, now)) todayClass = 'calendar-week-today';
    const todayElement = (<div className={todayClass}>{FechaFunction.format(date,'dd')}</div>);
    switch (date.getDay()) {
        case 0:
            title = `Domingo `;
            break;
        case 1:
            title = `Lunes `;
            break;
        case 2:
            title = `Martes `;
            break;
        case 3:
            title = `Miércoles `;
            break;
        case 4:
            title = `Jueves `;
            break;
        case 5:
            title = `Viernes `;
            break;
        case 6:
            title = `Sábado `;
            break;
        default:
            break;
    }
    const onClickInterpolar = () => {
        onClick({
            date: date,
            type: 'interpolar',
        })
    }

    const onClickVer = () =>{
        onClick({
            date: date,
            type: 'ver',
        })
    }

    return (
        <Styles>
            <div className="container">
                <div className="row">
                    <div className="offset-2 col-8">
                        <div className="day-header-content-week">
                            <b className='calendar-week-day-title'>
                                {title}
                            </b>
                        </div>
                    </div>
                    <div className="col-2">
                        <Button className="btn-xs float-right" title="Info del día" onClick={onClickVer}><i className="fas fa-info"></i></Button>
                    </div>
                    <div className="offset-4 col-4">
                            <Button className="btn-xs" onClick={onClickInterpolar} title="Interpolar">
                                <i className="fa fa-retweet"></i>
                            </Button>
                    </div>
                    <div className="offset-4 col-4">
                        <span>{FechaFunction.format(date,'dd')}</span>
                    </div>
                    <div className="col-4">
                        <span className="float-right text-dark" title="Total de tareas disponibles">{tareasDisponibles}</span>
                    </div>
                </div>
            </div>
        </Styles>
    )
}

DayHeaderContentWeek.defaultProps = {
    onClick: () => {},
}

export default DayHeaderContentWeek
