import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link , Prompt} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';
import PlataformaService from '@app/services/coorporativo/plataforma.service';
import ModalPlataformaEditar from '@app/modals/coorporativos/ModalPlataformaEditar';
import FechaFunction from '@app/functions/FechaFunction';
import Tooltip from '@app/../node_modules/react-bootstrap/esm/Tooltip';
import ReactTooltip from 'react-tooltip';
import CamposVisiblesService from '@app/services/coorporativo/camposVisibles.service';

const PlataformaPage = ({user}) => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);
    const [estadoTabla, setEstadoTabla] = useState('d.cliente');
    const [tituloTabla, setTituloTabla] = useState();
    const [tabla, setTabla] = useState([])
    const [requerimientos, setRequerimientos] = useState()


    const actualizar = async () => {
        const response = await PlataformaService.getAll();
        setItems(response)        
    };


    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Datos',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                PlataformaService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(PlataformaService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenEditar = async (item) => {

        const query={
            camposVisibles:true
        }

        const camposVisibles = await CamposVisiblesService.getAll(query);

        showModal(ModalPlataformaEditar, {item: item, onGuardar: onGuardar, camposVisibles:camposVisibles});
    };


    const plataformas = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'contactoCorporativo.clienteNombre'
            },
            {
                Header: 'Id Servicio',
                accessor: 'contactoCorporativo.servicioId'
            },
            {
                Header: 'Requerimiento',
                accessor: 'requerimiento',
                Cell: ({row}) => {
                    
                    return (
                        <>
                            {row.original?.requerimiento !== "[]" ? (
                                <>
                                    <a
                                        className='btn btn-sm btn-info'
                                        data-tip
                                        data-for={row?.original.id.toString()}
                                    >
                                        Ver
                                    </a>
                                        <ul>
                                            <ReactTooltip id={row.original?.id.toString()} aria-haspopup='true'>
                                                    <p className='text-center'>Requerimientos</p>
                                                {JSON.parse(row.original.requerimiento).map((requerimiento) => {
                                                    return (
                                                        <>
                                                                <li className='text-left'>
                                                                        {requerimiento.item}
                                                                </li>
                                                        </>
                                                   )
                                                })}
                                            </ReactTooltip>
                                        </ul>
                                </>
                            ) : (
                                <p>
                                    Sin requerimientos
                                </p>
                            )}
                        </>
                    );
                }
            },
            {
                Header: 'Plataformas',
                accessor: 'plataforma',
            },
            {
                Header: 'Link',
                accessor: 'link'
            },
            {
                Header: 'Clave',
                accessor: 'clave'
            },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd-MM-yyyy')}
                        </span>
                    );
                }
            },
            {
                width: 10,
                Header: 'Editar',
                // accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                                <button
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    <i className="fas fa-edit" />
                                </button>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
                <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-lg-3 my-lg-3">
                                <h3 className="ml-1" data-aos="fade-right">Plataforma</h3>
                            </div>
                            <div className="col-lg-5 mt-2">
                            </div>
                            {/* <div className="offset-2 col-lg-2 mt-2">
                                <Button disabled={isLoadingExportar} icon={['fas','file-excel']} className="btn btn-block btn-success" data-aos="fade-up" onClick={()=>onClickExportar()}>
                                    EXCEL
                                </Button>
                            </div> */}

                            <div className="col-12 overflow-hidden" data-aos="fade-right">
                                    <TablePaginated columns={plataformas} data={items} />
                            </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PlataformaPage);
