import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import Service from '@services/motivoTarea.service';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectMotivoTarea = ({
    id,
    value,
    onChange,
    isInvalid,
    tipoTareaId,
    isLoading,
    isClearable
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        const motivo = items.find((item) => item.id === ev.value);
        setSelectedValue(ev);
        onChange({
            ...ev,
            descripcion: motivo.descripcion ?? ''
        });
    };

    const updateOptions = () => {
        if (tipoTareaId > 0) {
            const newOptions = items
                .filter((it) => String(it.tipoTareaId) === tipoTareaId || it.tipoTareaId === tipoTareaId )    
                .map((item) => {
                return {
                    value: item.id,
                    label: item.nombre
                };
            });
            setOptions(newOptions);
            if (typeof value === 'string' || typeof value === 'number') {
                setSelectedValue(
                    newOptions.find((opt) => opt.value === Number(value) || opt.value === String(value) )
                );
            } else if(Array.isArray(value)){
                setSelectedValue(value.map(va=>({label:va.nombre,value:va.id})));
            }
        } else {
            setOptions();
        }
    };

    
    useEffect(() => {
        updateOptions();
        return () => {
            
        }
    }, [items,isLoading]);

    useEffect(() => {
        updateOptions();
        setSelectedValue(null);
    }, [tipoTareaId]);
    
    useEffect(() => {
        Service.getAll().then((response) => {
            setItems(response);
        });
    }, []);
    
    return (
        <Styles>
            <Select
                inputId={id}
                isDisabled={!tipoTareaId}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectMotivoTarea.defaultProps = {
    isClearable: false
};

export default SelectMotivoTarea;
