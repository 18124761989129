import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link , Prompt} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalDatosCoorporativosEditar from '@app/modals/coorporativos/ModalDatosCoorporativos';
import {Button, ModalConfirm} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';
import CorporativoGeneralService from '@app/services/coorporativo/general.service';
import ModalAdministracionEditar from '@app/modals/coorporativos/ModalAdministracionEditar';
import ModalDatosClientesEditar from '@app/modals/coorporativos/ModalDatosClientesEditar';
import ModalPlataformaEditar from '@app/modals/coorporativos/ModalPlataformaEditar';
import ModalInformacionTecnicaEditar from '@app/modals/coorporativos/ModalInformacionTecnicaEditar';
import FechaFunction from '@app/functions/FechaFunction';
import ReactTooltip from 'react-tooltip';
import ModalEditarObservacionesInformacionTecnica from '@app/modals/coorporativos/ModalEditarObservacionesTecnica';
import CamposVisiblesService from '@app/services/coorporativo/camposVisibles.service';


const DatosCoorporativosPage = ({user}) => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);
    const [estadoTabla, setEstadoTabla] = useState('d.cliente');
    const [tituloTabla, setTituloTabla] = useState()
    const [tabla, setTabla] = useState([])
    const [tablaDatosCliente, setDatosTablaCliente] = useState([])
    const [tablaPlataforma, setTablaPlataforma] = useState([])
    const [tablaAdministracion, setTablaAdministracion] = useState([])
    const [tablaInformacionTecnica, setTablaInformacionTecnica] = useState([])

    const actualizar = async () => {
        const response = await CorporativoGeneralService.getAll();
        
            setItems(response.contactoCorporativo);
            
            setDatosTablaCliente(response.contactoCorporativo);
        
            setTablaPlataforma(response.plataformaCorporativo);
       
            setTablaAdministracion(response.administracionCorporativo);
       
            setTablaInformacionTecnica(response.tecnicaCorporativo);
        
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Datos',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                CorporativoGeneralService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(CorporativoGeneralService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }


    const onOpenNuevo = () =>{
        showModal(ModalDatosCoorporativosEditar, {onGuardar: onGuardar});
    }

    const onOpenEditar = async (item, modal) =>{

        const query={
            camposVisibles:true
        }
        const campos = await CamposVisiblesService.getAll(query);

        if(modal === "Datos Clientes"){
                showModal(ModalDatosClientesEditar,{item:item, onGuardar:onGuardar, camposVisibles:campos})

        }else if(modal === "Plataforma"){
                showModal(ModalPlataformaEditar,{item:item, onGuardar:onGuardar, camposVisibles:campos})

        }else if(modal === "Administracion"){
                showModal(ModalAdministracionEditar,{item:item, onGuardar:onGuardar, camposVisibles:campos})

        }else{
                showModal(ModalInformacionTecnicaEditar,{item:item, onGuardar:onGuardar, camposVisibles:campos})

        }

    }

    const editarObservacionesTecnicas = (item) =>{
        showModal(ModalEditarObservacionesInformacionTecnica, {item: item, onGuardar: onGuardar});
    }

    const datosCliente = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'clienteNombre'
            },
            {
                Header: 'Id Servicio',
                accessor: 'servicioId'
            },
            {
                Header: 'Punto',
                accessor: 'punto'
            },
            {
                Header: 'Localidad',
                accessor: 'localidad'
            },
            {
                Header: 'Servicio Vigente',
                accessor: 'servicioVigente'
            },
            {
                Header: 'Segmentos',
                accessor: 'segmento'
            },
            {
                Header: 'Servicio',
                accessor: 'servicio'
            },
            {
                Header: 'Detalle de Servicio',
                accessor: 'detalleServicio'
            },
            {
                Header: 'Categoria',
                accessor: 'categoria'
            },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd-MM-yyyy')}
                        </span>
                    );
                }
            },
            {
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original, 'Datos Clientes')}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    const plataformas = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'contactoCorporativo.clienteNombre'
            },
            {
                Header: 'Id Servicio',
                accessor: 'contactoCorporativo.servicioId'
            },
            {
                Header: 'Requerimiento',
                accessor: 'requerimiento',
                Cell: ({row}) => {

                    const lista = row.original.requerimiento
                    
                    return (
                        <>
                            {lista !== "[]" ? (
                                <>
                                    <a
                                        className='btn btn-sm btn-info'
                                        data-tip
                                        data-for={row.original.id.toString()}
                                    >
                                        Ver
                                    </a>
                                        <ul>
                                            <ReactTooltip id={row.original.id.toString()} aria-haspopup='true'>
                                                    <p className='text-center'>Requerimientos</p>
                                                {JSON.parse(row.original.requerimiento).map((requerimiento) => {
                                                    return (
                                                        <>
                                                                <li className='text-left'>
                                                                        {requerimiento.item}
                                                                </li>
                                                        </>
                                                   )
                                                })}
                                            </ReactTooltip>
                                        </ul>
                                </>
                            ) : (
                                <p>
                                    Sin requerimientos
                                </p>
                            )}
                        </>
                    );
                }
            },
            {
                Header: 'Plataformas',
                accessor: 'plataforma'
            },
            {
                Header: 'Link',
                accessor: 'link'
            },
            {
                Header: 'Clave',
                accessor: 'clave'
            },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                width:100,
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd-MM-yyyy')}
                        </span>
                    );
                }
            },
            {
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original,'Plataforma')}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );
    const administracion = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'contactoCorporativo.clienteNombre'
            },
            {
                Header: 'Id Servicio',
                accessor: 'contactoCorporativo.servicioId'
            },
            {
                Header: 'ID Fact',
                accessor: 'facturaId'
            },
            {
                Header: 'Cuit',
                accessor: 'cuit'
            },
            {
                Header: 'Domicilio Fiscal',
                accessor: 'domicilioFiscal'
            },
            {
                Header: 'Mail Fac',
                accessor: 'emailFacturacion'
            },
            {
                Header: 'Contactos Administrativos',
                accessor: 'contactoAdministrativo'
            },
            {
                Header: 'Fecha Fin de C',
                accessor: 'fecha'
            },
            {
                Header: 'Costo MB',
                accessor: 'costoMega'
            },
            {
                Header: 'Costo por Inst.',
                accessor: 'costoInstalacion'
            },
            // {
            //     Header: 'Contrato firmado',
            //     accessor: 'contradoFirmado'
            // },
            // {
            //     Header: 'Orden de compra',
            //     accessor: 'ordenCompra'
            // },
            // {
            //     Header: 'Contracto Certificado',
            //     accessor: 'contratoCertificado'
            // },
            // {
            //     Header: 'Agenda',
            //     accessor: 'agenda'
            // },
            // {
            //     Header: 'Estatuto de la Empresa',
            //     accessor: 'estatuto'
            // },
            // {
            //     Header: 'Designacion de acta act',
            //     accessor: 'designacion'
            // },
            // {
            //     Header: 'Contrato Social',
            //     accessor: 'contratoSocial'
            // },
            // {
            //     Header: 'Poder a nombre...',
            //     accessor: 'poder'
            // },
            // {
            //     Header: 'Copia DNI',
            //     accessor: 'copiaDni'
            // },
            // {
            //     Header: 'Constancia Afip',
            //     accessor: 'constanciaAfip'
            // },
            // {
            //     Header: 'Constancia Rentas',
            //     accessor: 'constanciaRenta'
            // },
            // {
            //     Header: 'Pagos al día',
            //     accessor: 'pagosDia'
            // },
            // {
            //     Header: 'Observaciones',
            //     accessor: 'observacionFacturacion'
            // },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd-MM-yyyy')}
                        </span>
                    );
                }
            },
            {
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original, 'Administracion')}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );
    const informacionTecnica = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'contactoCorporativo.clienteNombre'
            },
            {
                Header: 'Id Servicio',
                accessor: 'contactoCorporativo.servicioId'
            },
            {
                Header: 'Contactos Tecnicos',
                accessor: 'contactoTecnico',
                Cell: ({row}) => {

                    const lista = row.original.contactoTecnico
                    
                    return (
                        <>
                            {lista !== "[]" ? (
                                <>
                                    <a
                                        className='btn btn-sm btn-info'
                                        data-tip
                                        data-for={row.original?.id.toString()}
                                    >
                                        Ver
                                    </a>
                                        <ul>
                                            <ReactTooltip id={row.original.id.toString()} aria-haspopup='true'>
                                                    <p className='text-center'>Contactos Tecnicos</p>
                                                {JSON.parse(row.original.contactoTecnico).map((contacto) => {
                                                    return (
                                                        <>
                                                                <li className='text-left'>
                                                                        {contacto.item}
                                                                </li>
                                                        </>
                                                   )
                                                })}
                                            </ReactTooltip>
                                        </ul>
                                </>
                            ) : (
                                <p>
                                    Sin contactos
                                </p>
                            )}
                        </>
                    );
                }
            },
            {
                Header: 'F. Instalación',
                accessor: 'fechaInstalacion',
            },
            {
                Header: 'Fecha Alta',
                accessor: 'fechaAlta'
            },
            {
                Header: 'Fecha Baja',
                accessor: 'fechaBaja'
            },
            {
                Header: 'Latitud',
                accessor: 'latitud'
            },
            {
                Header: 'Longitud',
                accessor: 'longitud'
            },
            {
                Header: 'MB Subida',
                accessor: 'mbSubida'
            },
            {
                Header: 'MB Bajada',
                accessor: 'mbBajada'
            },
            {
                Header: 'Cantidad IP Publica',
                accessor: 'cantidadIpPublica'
            },
            {
                Header: 'Número de IP Publicas',
                accessor: 'numeroIpPublica'
            },
            {
                Header: 'TK Abiertos',
                accessor: 'tkAbierto'
            },
            {
                Header: 'Obs',
                accessor: 'observacionTecnica',
                Cell: ({row}) => {
                    return(
                        <>
                            <button
                                type='button'
                                className='btn btn-sm btn-info'
                                data-tip
                                data-for={row.original.id.toString()+1}
                                onClick={()=>editarObservacionesTecnicas(row.original)}
                            >
                                <i className="fas fa-eye" />
                            </button>
                                <ul>
                                    <ReactTooltip id={row.original.id.toString()+1} aria-haspopup='true'>
                                            <p className='text-center'>Observaciones</p>
                                            <p>{row.original.observacionTecnica}</p>
                                    </ReactTooltip>
                                </ul>
                        </>
                    )
                }
            },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd-MM-yyyy')}
                        </span>
                    );
                }
            },
            {
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original,'Informacion Tecnica')}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
        if(estadoTabla === 'd.cliente'){
            setTabla(datosCliente);
            setTituloTabla('Datos Clientes');
        }
        if(estadoTabla === 'plataformas'){
            setTabla(plataformas);
            setTituloTabla('Plataformas');
        }
        if(estadoTabla === 'administracion'){
            setTabla(administracion);
            setTituloTabla('Administración');
        }
        if (estadoTabla === 'tecnica'){
            setTabla(informacionTecnica);
            setTituloTabla('Información Técnica');
        }
    }, [])

    useEffect(() => {
        actualizar();
        if(estadoTabla === 'd.cliente'){
            setTabla(datosCliente);
            setTituloTabla('Datos Clientes');
        }
        if(estadoTabla === 'plataformas'){
            setTabla(plataformas);
            setTituloTabla('Plataformas');
        }
        if(estadoTabla === 'administracion'){
            setTabla(administracion);
            setTituloTabla('Administración');
        }
        if (estadoTabla === 'tecnica'){
            setTabla(informacionTecnica);
            setTituloTabla('Información Técnica');
        }
    }, [estadoTabla])

    return (
        <>
                <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-lg-3 my-lg-3">
                                <h3 className="ml-1" data-aos="fade-right">{tituloTabla}</h3>
                            </div>
                            <div className="col-lg-5 mt-2">
                                <div className="row">
                                    <div className="col-3">
                                        <Button className={estadoTabla === 'd.cliente' ? ("btn btn-block btn-outline-secondary border-secondary" ) : ("btn btn-block btn-secondary bg-secondary")} disabled={estadoTabla === 'd.cliente'} onClick={()=>setEstadoTabla('d.cliente')}>
                                            D. Clientes
                                        </Button>
                                    </div>

                                    <div className="col-3">
                                        <Button className={estadoTabla === 'plataformas' ? ("btn btn-block btn-outline-secondary border-secondary" ) : ("btn btn-block btn-secondary bg-secondary")} disabled={estadoTabla === 'plataformas'} onClick={()=>setEstadoTabla('plataformas')}>
                                            Plataforma
                                        </Button>
                                    </div>

                                    <div className="col-3">
                                        <Button className={estadoTabla === 'administracion' ? ("btn btn-block btn-outline-secondary border-secondary" ) : ("btn btn-block btn-secondary bg-secondary")} disabled={estadoTabla === 'administracion'} onClick={()=>setEstadoTabla('administracion')}>
                                            Admin
                                        </Button>
                                    </div>

                                    <div className="col-3">
                                        <Button className={estadoTabla === 'tecnica' ? ("btn btn-block btn-outline-secondary border-secondary" ) : ("btn btn-block btn-secondary bg-secondary")} disabled={estadoTabla === 'tecnica'} onClick={()=>setEstadoTabla('tecnica')}>
                                            Tecnica
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-2 mt-2">
                                <Button disabled={isLoadingExportar} icon={['fas','file-excel']} className="btn btn-block btn-success" data-aos="fade-up">
                                    EXCEL
                                </Button>
                            </div> */}

                            {
                                permisos['api.general.store'] && (
                                <div className="offset-2 col-lg-2 mt-2">
                                    <Button color="primary" className="btn-block" onClick={() => onOpenNuevo()} data-aos="fade-up">
                                        NUEVO
                                    </Button>
                                </div>
                            )}

                            {estadoTabla === 'd.cliente' &&(
                                <div className="col-12 overflow-hidden">
                                        <TablePaginated columns={datosCliente} data={tablaDatosCliente} />
                                </div>
                            )}
                            {estadoTabla === 'plataformas' &&(
                                <div className="col-12 overflow-hidden">
                                        <TablePaginated columns={plataformas} data={tablaPlataforma} />
                                </div>
                            )} 
    
                            {estadoTabla === 'administracion' &&(
                                <div className="col-12 overflow-hidden">
                                        <TablePaginated columns={administracion} data={tablaAdministracion} />
                                </div>
                            )}
                            {estadoTabla === 'tecnica' &&(
                                <div className="col-12 overflow-hidden">
                                        <TablePaginated columns={informacionTecnica} data={tablaInformacionTecnica} />
                                </div>
                            )}
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DatosCoorporativosPage);
