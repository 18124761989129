import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import CalendarioService from '@app/services/calendario.service';
import {Button, CuadrillaDisponibleCard, FormTareaHorario, ListCard, TimeSlider} from '@app/components/index';
import Collapse from 'react-bootstrap/Collapse';
import CalendarFunction from '@app/functions/CalendarFunction';
import ContratosService from '@app/services/contratos.service';
import TareasService from '@app/services/tareas.service';
import MapaFunction from '@app/functions/MapaFunction';
import EchoWS from '@app/utils/ws';
import {showModal} from 'react-redux-modal-provider';
import ModalCrearCuadrillaLugarDisponibles from '@app/modals/cuadrilla-lugar/ModalCrearCuadrillaLugarDisponibles';
import ModalCrearEspCuadrillaLugarDisponibles from '@app/modals/cuadrilla-lugar/ModalCrearEspCuadrillaLugarDisponibles';
import usePermisos from '@app/hooks/usePermisos';

const ContactosF4Page = ({
    contacto,
    contrato,
    previousStep,
    user
}) => {
    const {puesto} = user;
    const history = useHistory();
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [cuadrillaLugarSelected, setCuadrillaLugarSelected] = useState(null);
    const [time, setTime] = useState(null);
    const actualizar = () => {
        const form = {
            lugarId: contacto?.lugarId || 1,
            tipoTareaId: contacto?.tipoTareaId || 3,
        };
        setIsLoading(true);
        CalendarioService.disponibles(form).then((response)=>{
            const costos = MapaFunction.calculoCostos(response,contacto);
            setItems(costos);
        })
        .catch(()=>{
            setItems([]);
        })
        .finally(()=>{
            setIsLoading(false);
        });
    };
    const onSubmitCrearCuadrilla = () => {
        showModal(ModalCrearEspCuadrillaLugarDisponibles,{onActualizar:actualizar})
    }
    const onSubmit = () => {
        const form = {
            ...contrato,
            ...contacto,
            contactoId: contacto.id,
            cuadrillaLugarId: cuadrillaLugarSelected.id,
            clienteNombre: contacto.nombreCompleto,
            clienteDocumento:contacto.documento,
            clienteCelular:contacto.celular,
            clienteTelefono: contacto.telefono,
            clienteDireccion:contacto.direccion,
            clienteLatitud:contacto.latitud,
            clienteLongitud:contacto.longitud,
            clienteCategoria:'NORMAL',
            tareaId:contacto.tipoTareaId,
            fecha: cuadrillaLugarSelected.fecha,
            observaciones:'',
            tipoTareaId:3,
            tipoCanalId:contacto.tipoCanalId,
            clienteId: contacto.clienteId, /* viene del zoho */
            lugarId: contacto?.lugarId || 1,
            horaIni: time.start,
            horaFin: time.end,
            cableEmbutido: 0,
        }
        if(contrato.cableEmbutido === "SI"){
            form.cableEmbutido = 1;
        }
        if(cuadrillaLugarSelected.cartelAdvertencia){
            form.estadoTareaId = 8;
        }else{
            form.estadoTareaId = 1;
        }
        setIsLoading(true);
        TareasService.register(form)
        .then((response)=>{
            const form2={
                ...response,
                ...form,
                fechaInstalacion: cuadrillaLugarSelected.fecha,
                lugarId: contacto?.lugarId || 1,
                tareaId: response.id,
                clienteId: response.clienteId,
                tipoMaterial:contrato.tipoMaterial,
                tipoTecho:contrato.tipoTecho,
                altura:contrato.altura,
            }
            return ContratosService.register(form2)
            .then((resp)=>{
                if(user.puesto === "Asesor"){
                history.push('/asesor/detalle');
                }else{
                history.push('/contactos');
                }
            });
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }

    const onSubmitExistente= () =>{
        const formExistente = {
            ...contacto,
            contactoId: contacto.contactoId,
            motivoTareaId:contacto.motivoTareaId.value,
            clienteDocumento:contacto.documento,
            clienteCelular:contacto.celular,
            clienteTelefono: contacto.telefono,
            clienteDireccion:contacto.direccion,
            clienteLatitud:contacto.latitud,
            clienteLongitud:contacto.longitud,
            clienteCategoria:contacto.categoriaZoho,
            tipoTareaId:contacto.tipoTareaId,
            observaciones:'',
            cuadrillaLugarId: cuadrillaLugarSelected.id,
            fecha: cuadrillaLugarSelected.fecha,
            horaIni: time.start,
            horaFin: time.end,
            cableEmbutido: 0,
        }
        if(contrato.cableEmbutido === "SI"){
            formExistente.cableEmbutido = 1;
        }
        if(cuadrillaLugarSelected.cartelAdvertencia){
            formExistente.estadoTareaId = 8;
        }else{
            formExistente.estadoTareaId = 1;
        }
        TareasService.register(formExistente)
        .then((respu)=>{
                history.push('/contactos');
        })
    }

    useEffect(() => {
        let listen;
        if(contacto && contacto.lugarId){
            actualizar();
            if(listen){
                listen.unsubscribe();
            }
            listen = EchoWS.private(`tareas.lugares.${contacto.lugarId}`)
                .listen('.tareas.created',actualizar)
                .listen('.tareas.deleted',actualizar)
                .listen('.tareas.updated',actualizar)
        }
        setCuadrillaLugarSelected(null)
        return () => {
            if(listen){
                listen.unsubscribe();
            }
        }
    }, [contacto,contrato]);

    useEffect(() => {
        if(cuadrillaLugarSelected){
            setTime(CalendarFunction.getDefaultTimeFromPeriod(cuadrillaLugarSelected.periodo));
        }
    }, [cuadrillaLugarSelected])

    return (
        <>
            <div className="container-fluid border shadow-sm rounded-lg bg-white">
                <div className="row">
                    <div className="p-3 col-12 col-lg-12 col-md-12 text-gray my-lg-3 col-8">
                        <h5 className="ml-lg-3">
                            <i className="fas fa-angle-down mr-2"></i>
                            Horarios Disponibles
                        </h5>
                        {
                            contacto && (
                                <span className="ml-lg-3">{`En ${contacto.lugar?.nombre}`}</span>
                            )
                        }
                        {
                            permisos['api.cuadrillas.lugares.disp.disponibleCuadrilla'] && (
                            <Button theme="primary" onClick={onSubmitCrearCuadrilla}>
                                Crear Cuadrilla
                            </Button>
                        )}
                    </div>
                    <Collapse
                        in={cuadrillaLugarSelected===null}
                    >
                    <div className="offset-lg-2 col-lg-8 ">
                        <ListCard isLoading={isLoading}>
                        {
                            items.map((item)=>{
                                return <CuadrillaDisponibleCard item={item} tipoTrabajoId={contacto?.tipoTareaId} key={item.id} onSelect={setCuadrillaLugarSelected} isSelectable />
                            })
                        }
                        </ListCard>
                    </div>
                    </Collapse>
                    <Collapse
                        in={cuadrillaLugarSelected!==null}
                    >
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="offset-2 col-8 offset-md-0 ml-md-3 col-md-11 col-lg-12">
                                <Button onClick={()=>setCuadrillaLugarSelected(null)}>
                                    Volver a seleccionar fecha
                                </Button>
                            </div>
                            <div className="offset-lg-2 col-lg-8">
                                {
                                    cuadrillaLugarSelected && (
                                        <FormTareaHorario cuadrillaLugar={cuadrillaLugarSelected} onChange={setTime} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    </Collapse>
                </div>
                <div className="row">
                    <div className="col-12 my-3">
                        <div className="row">
                            <div className="offset-1 col-sm-12 offset-md-7 col-md-5 pb-md-5 offset-lg-8 col-lg-4 float-right">
                                {/* <Button className="btn-secondary" onClick={previousStep}>
                                    Anterior
                                </Button> */}
                                {
                                    contacto && (contacto.tipoTareaId === '1' || contacto.tipoTareaId === '2' || contacto.tipoTareaId === '6' ) ? (
                                    <Button
                                        isLoading={isLoading}
                                        disabled={cuadrillaLugarSelected===null}
                                        onClick={onSubmitExistente}
                                    >
                                        Registrar Tarea
                                    </Button>

                                    ):(
                                    <Button
                                        isLoading={isLoading}
                                        disabled={cuadrillaLugarSelected===null}
                                        onClick={onSubmit}
                                    >
                                        Registrar Trato
                                    </Button>
                                    )
                                }
                                <Link to="/contactos">
                                    <Button theme="default">
                                        Cancelar
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </>
     )
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
    contrato: state.contactos.contrato,
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactosF4Page);
