import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectTipoTrabajo = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled = false,
    itemsFO = false,
    defaultProps
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [options, setOptions] = useState([
        {value: 'NORMAL',label: 'NORMAL'},
        { value: 'RETIRO', label: 'RETIRO' },
        { value: 'ASISTENCIA', label: 'ASISTENCIA' },
        { value: 'INSTALACION', label: 'INSTALACION' },
        { value: 'INSTALACION FO', label: 'INSTALACION FO' }
    ]);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }else{
            setSelectedValue(null);
        }
    }, [options,value]);

    useEffect(() => {
        if(itemsFO){
            setOptions([
                { value: 'INSTALACION FO', label: 'INSTALACION FO' }
            ])
        }else{
            setOptions([
                {value: 'NORMAL',label: 'NORMAL'},
                { value: 'RETIRO', label: 'RETIRO' },
                { value: 'ASISTENCIA', label: 'ASISTENCIA' },
                { value: 'INSTALACION', label: 'INSTALACION' },
            ])
        }
    }, [itemsFO])

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isDisabled={disabled}
            />
        </Styles>
    );
};

SelectTipoTrabajo.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectTipoTrabajo;
