import axios from 'axios';

function getAll(query=null){
    return axios.get('lugares',{
        params:query
    });
}

function register(data){
    return axios.post('lugares',data);
}

function getById(id){
    return axios.get(['lugares',id].join('/'));
}

function update(data){
    return axios.put(['lugares',data.id].join('/'),data);
}

function destroy(id){
    return axios.delete(['lugares',id].join('/'));
}

const LugaresService = {
    getAll:getAll,
    register:register,
    getById:getById,
    update:update,
    destroy:destroy,
};
export default LugaresService;