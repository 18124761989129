import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import MotivoTareaServiceService from '@services/motivoTarea.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalMotivoTareaEditar from '@app/modals/motivos/ModalMotivoTareaEditar';
import usePermisos from '@app/hooks/usePermisos';
import {Button, ModalConfirm} from '@app/components/index';

const MotivoTareasPage = () => {
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await MotivoTareaServiceService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Motivo Tarea',
            message: '¿Esta seguro que desea eliminar el motivo tarea?',
            onConfirm: () => {
                MotivoTareaServiceService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalMotivoTareaEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Tarea',
                accessor: 'tipoTareaNombre'
            },
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'Descripcion',
                accessor: 'descripcion'
            },{
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-8 col-lg-6 my-2 my-lg-0 my-lg-3">
                        <h3 className="">Motivos Tareas</h3>
                    </div>
                    {
                        permisos['api.motivoTarea.tareas.store'] && (
                        <div className="offset-1 col-3 offset-lg-4 col-lg-2 my-2 my-lg-3">
                            <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                                NUEVO
                            </Button>
                        </div>
                    )}
                    <div className="col-12">
                        <TablePaginated columns={columns} data={items} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MotivoTareasPage);
