import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button, InputLocalidadZoho, InputZohoSearch, SelectCategoriaCorporativo, SelectSegmentoCorporativo, SelectServicioCorporativo} from '@app/components/index';
import {useForm, Controller} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import InputContactsSearchZoho from '@app/components/input/InputContactSearchZoho';
import DatosClientesService from '@app/services/coorporativo/datosClientes.service';
import usePermisos from '@app/hooks/usePermisos';

const schema = Yup.object({

});

function ModalDatosClientesEditar({show, hideModal, item, onGuardar, camposVisibles}) {

    const {register, handleSubmit, errors, control, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [servicioId, setservicioId] = useState(item?.servicioId);
    const [punto, setPunto] = useState(item?.punto);
    const [servicioVigente, setServicioVigente] = useState(item?.servicioVigente);
    const [segmento, setSegmento] = useState(item?.segmento);
    const [servicio, setServicio] = useState(item?.servicio);
    const [detalleServicio, setDetalleServicio] = useState(item?.detalleServicio);
    const [categoria, setCategoria] = useState(item?.categoria);
    const [localidad, setLocalidad] = useState(item?.localidad);
    const [cliente, setCliente] = useState(item?.clienteNombre);



    const onSubmit = () => {

        const form = {
            id: item.id,
            clienteNombre:cliente,
            servicioId:servicioId,
            localidad:localidad,
            servicio:servicio,
            punto:punto,
            servicioVigente:servicioVigente,
            segmento:segmento,
            detalleServicio:detalleServicio,
            categoria:categoria
        };

    
            DatosClientesService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        
    };



    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Editar Registro
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12 border-primary">
                                <div className="row">
                                
                                    <div className={JSON.parse(camposVisibles[0].cliente_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="patenteL">
                                                Cliente
                                            </label>
                                            <input
                                               id="clienteNombre"
                                               className="form-control"
                                               type="text"
                                               name="clienteNombre"
                                               ref={register}
                                               value={cliente}
                                               autoComplete="off"
                                               onChange={(e) =>setCliente(e.target.value)}
                                               disabled={JSON.parse(camposVisibles[0].cliente_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].servicioId_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="patenteL">
                                                ID Servicio
                                            </label>
                                            <input
                                               id="servicioId"
                                               className="form-control"
                                               type="text"
                                               name="servicioId"
                                               defaultValue={servicioId}
                                               ref={register}
                                               autoComplete="off"
                                               onChange={(e) =>setservicioId(e.target.value)}
                                               disabled={JSON.parse(camposVisibles[0].servicioId_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].punto_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="patenteL">
                                                Punto
                                            </label>
                                            <input
                                                className="form-control"
                                                id="punto"
                                                name="punto"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                defaultValue={punto}
                                                onChange={(e) =>setPunto(e.target.value)}
                                                ref={register}
                                                disabled={JSON.parse(camposVisibles[0].punto_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].localidad_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="patenteL">
                                                Localidad
                                            </label>
                                            <input
                                                className="form-control"
                                                id="localidad"
                                                name="localidad"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                defaultValue={localidad}
                                                onChange={(e) =>setLocalidad(e.target.value)}
                                                ref={register}
                                                disabled={JSON.parse(camposVisibles[0].localidad_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].servicioVigente_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="cantidadL">
                                                Servicio Vigente
                                            </label>
                                            <select
                                                className="form-control"
                                                id="servicioVigente"
                                                name="servicioVigente"
                                                ref={register}
                                                defaultValue={servicioVigente}
                                                onChange={(e) =>setServicioVigente(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].servicioVigente_cv)[1].modificar === false}
                                            >
                                                <option value="ACTIVO">ACTIVO</option>
                                                <option value="A INSTALAR">A INSTALAR</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].segmento_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="segmentos">
                                                Segmentos
                                            </label>
                                            <SelectSegmentoCorporativo 
                                                name="segmento"
                                                id="segmento"
                                                ref={register}
                                                value={segmento}
                                                onChange={(opt) =>setSegmento(opt.value)}
                                                isDisabled={JSON.parse(camposVisibles[0].segmento_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].servicio_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="servicio">
                                                Servicio
                                            </label>
                                            <SelectServicioCorporativo 
                                                id="servicio"
                                                name="servicio"
                                                value={servicio}
                                                onChange={(opt) =>setServicio(opt.value)}
                                                isDisabled={JSON.parse(camposVisibles[0].servicio_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].detalleServicio_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="detalleServicio">
                                                Det. de Servicio
                                            </label>
                                            <input
                                                className="form-control"
                                                id="detalleServicio"
                                                name="detalleServicio"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                defaultValue={detalleServicio}
                                                onChange={(e)=>setDetalleServicio(e.target.value)}
                                                ref={register}
                                                disabled={JSON.parse(camposVisibles[0].detalleServicio_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].categoria_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="servicio">
                                                Categoria
                                            </label>
                                            <SelectCategoriaCorporativo
                                            name="categoria"
                                            id="categoria"
                                            ref={register}
                                            value={categoria}
                                            onChange={(opt)=>setCategoria(opt.value)}
                                            disabled={JSON.parse(camposVisibles[0].categoria_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                

                        </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                {
                    permisos['api.datos-personales.update'] && (
                    <Button
                        theme="primary"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                    >
                        GUARDAR
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

ModalDatosClientesEditar.defaultProps = {
    show: false,
    item: {
        id: 0
    }
};

export default ModalDatosClientesEditar;