import React, {useState, useEffect, useRef, createRef, forwardRef, useCallback} from "react";
import {GridStack} from 'gridstack';
import 'gridstack/dist/h5/gridstack-dd-native';

import { v4 as uuidv4 } from 'uuid';
import AuxiliarFunction from "@app/functions/AuxiliarFunction";

/**
 * Errores encontrados:
 * 1 - Con GridStack, al borrar un widget con su API, este limpia el doom por lo que si hay una referencia en react y este lo manipula por aparte, por lo que pierde su integridad
 * 2 - De la misma manera, si al manipular el doom en react, con GridStack al agregar un widget, el SortableJs del widget no inicia
 * Una solucion fue convivir con el 1:
 *  - Mantener el dom, sin eliminar el Widget pero ocultandolo al querer eliminarlo (eliminacion logica:estado)
 *  - Al agregar un widget, este se adjuntan a los ya existentes
 *  - Para eliminar o limpiar los widget se tiene que desmontar este componente
 *  - Posibles problemas: ¿Que pasaria al agregar tantos widget?
 * @param {any} param0 
 * @returns 
 */
function Grilla ({
    isLoading,
    acceptWidgets,
    defaultValues,
    entryValues,
    isFullWidth,
    stackOptions,
    uniqueKeyLabel,
    values,
    widget: WidgetComponent,
    
    getItem,
    getHeight,
    onChange,
    onRemove,
}) {
    const makeWidget = (item,options = {isOptions:true,y: null, x: null}) => {
        return {
            id: uuidv4(),
            item: item,
            widget: null,
            estado: true,
            w: isFullWidth?12:2,
            h: getHeight(item),
            ...options,
        };
    }

    const [gridId, setGridId] = useState(uuidv4());
    const [length, setLength] = useState(0);
    const [isOnDrag, setIsOnDrag] = useState(false);
    const gridRef = useRef();
    const [stackItems, setStackItems] = useState(defaultValues.map((value)=>{
        return makeWidget(value,{
            isOptions: false,
        });
    }));
    const stackRefs = useRef({})

    if (Object.keys(stackRefs.current).length !== stackItems.length) {
        stackItems.forEach(({ id }) => {
            stackRefs.current[id] = stackRefs.current[id] || createRef()
        })
    }

    const updateItem = (stack) => {
        const existe = document.getElementById(stack.id);
        if(existe && stack.item){
            stack.widget = gridRef.current.makeWidget(`#${stack.id}`);
        }
        return stack;
    }
    const updateGrid = (newStackItems) => {
        gridRef.current.batchUpdate();
        gridRef.current.removeAll(false);
        let isCompat = false;
        const changedStackItems = newStackItems.map((stack) => {
            const newStack  = updateItem(stack);
            if(!newStack.isOptions){
                isCompat = true;
                newStack.isOptions = true;
            }
            return newStack;
        });
        if(isCompat){
            gridRef.current.compact();
        }
        gridRef.current.commit();
        if(isCompat){
            gridRef.current.save().forEach(({id,x,y})=>{
                const index = changedStackItems.findIndex(st=>st.id === id && st.x === null && st.y === null);
                if(index>=0){
                    changedStackItems[index].x = x;
                    changedStackItems[index].y = y;
                }
            });
        }
        setStackItems(changedStackItems);
    }
    
    const eventoGridDropped = (ev,oldWidget,currentWidget)=>{
        const keyValue = Number(currentWidget.id);
        if(getItem){
            currentWidget.grid.removeWidget(currentWidget.el);
        } else {
            return;
        }
        if(Number.isNaN(keyValue)){
            return;
        }
        const entryItem = getItem(keyValue);
        if(!entryItem){
            return;
        }
        const item = makeWidget(entryItem,{
            x:currentWidget.x,
            y:currentWidget.y,
            isOptions: true,
        });
        const newStacks = [...stackItems,item];
        setStackItems(newStacks);
        setLength(newStacks.length);
    }

    const removeWidget = (id) => {
        const aux = [...stackItems];
        const index = aux.findIndex(st=>st.id === id);
        aux[index].estado = false;
        
        if(stackRefs.current[id]){
            gridRef.current.removeWidget(aux[index].widget,false,false);
        }
        onRemove(aux[index].item);
        setStackItems(aux);
        setLength(aux.length - 1);
    }

    const modifyWidget = (id,options) => {
        const aux = [...stackItems];
        const index = aux.findIndex(st=>st.id === id);
        if(index>=0){
            const modifyItem = aux[index];
            const newItem = {
                ...modifyItem,
                ...options,
            };
            if(options.item){
                onChange([newItem.item]);
            }
            /*
            setStackItems(aux);
            setLength(aux.length - 1);
            */
        }
    }

    const onAcceptWidgets = (el) => {
        const keyValue = Number(el.id);
        const existe = values.find(fj=>{
            return fj[uniqueKeyLabel] === keyValue;
        });
        return !existe;
    }

    const gridStackOptions = {
        ...stackOptions,
        float: !isFullWidth,
        staticGrid: isFullWidth,
    }
    
    useEffect(() => {
        gridRef.current = GridStack.init(gridStackOptions,`#${gridId}`);
        gridRef.current.on('dropped',eventoGridDropped);
        if(acceptWidgets){
            gridRef.current.opts.acceptWidgets = onAcceptWidgets;
        } else {
            gridRef.current.opts.acceptWidgets = false;
        }
        /*
        gridRef.current.compact();
        */
        setTimeout(()=>{
            updateGrid(stackItems);
        });
        return () => {
            
        }
    }, [length])


    useEffect(() => {
        gridRef.current = GridStack.init(gridStackOptions,`#${gridId}`);
        gridRef.current.on('dropped',eventoGridDropped);
        if(acceptWidgets){
            gridRef.current.opts.acceptWidgets = onAcceptWidgets;
        } else {
            gridRef.current.opts.acceptWidgets = false;
        }
        return () => {
            
        }
    }, [values]);
    
    useEffect(() => {
        if(entryValues === null){
            return;
        }
        const iterator = [...stackItems];
        iterator.forEach((st)=>{
            const index = entryValues.findIndex((entry)=>st.item[uniqueKeyLabel] === entry[uniqueKeyLabel] && st.estado);
            if(index<0){
                if(stackRefs.current[st.id]){
                    gridRef.current.removeWidget(stackRefs.current[st.id].current);
                }
                st.estado = false;
            }
        });
        
        entryValues.forEach((entry)=>{
            const index = iterator.findIndex((st)=>st.item[uniqueKeyLabel] === entry[uniqueKeyLabel] && st.estado);
            if(index>=0){
                iterator[index].item = entry;
            } else {
                iterator.push(makeWidget(entry));
            }
        });
        setStackItems(iterator);
        setLength(entryValues.length);
    }, [entryValues]);
    
    useEffect(() => {
        /**
         * quitar dom basura luego de la segunda iteracion
         */
        const aux = [...stackItems].filter(it=> it.counter > 2).map(it=>{
            if(!it.estado){
                it.counter = (it.counter || 0) + 1;
            }
            return it;
        });
        const newEntrys = [];
        defaultValues.forEach(entry=>{
            const index = aux.findIndex(it=>it.item.id === entry.id && it.estado);
            if(index>=0){
                aux[index].item = entry;
            } else {
                newEntrys.push(entry);
            }
        })
        const aux2 = [...aux,...newEntrys.map((value)=>{
            return makeWidget(value,{
                isOptions: false,
            });
        })]
        setStackItems(aux2)
        setLength(aux2.length);
    }, [defaultValues]);

    useEffect(() => {
        if(isLoading){
            const aux = [...stackItems].map(it=>{
                it.estado = false;
                return it;
            });
            setStackItems(aux);
            setLength(aux);
        }
    }, [isLoading])
    return (
        <>
            <div 
                className={`grid-stack ml-1 ${isOnDrag?'grid-on-drag':''}`}
                id={gridId}
            >
                {stackItems?.map((stack, i) => {
                    const {isOptions,id,item,widget,w,h,x,y,minH, estado} = stack;
                    let currentOptions = {};
                    if(x && y){
                        currentOptions = {
                            'gs-w':w,
                            'gs-h':h,
                            'gs-x':x,
                            'gs-y':y,
                        }
                    } else {
                        currentOptions = {
                            'gs-w': w,
                            'gs-h': h,
                            'gs-auto-position':'true',
                        }
                    }
                    if(minH){
                        currentOptions['gs-min-h'] = minH;
                    } else {
                        currentOptions['gs-min-h'] = 3;
                    }
                    
                    return (
                        <div 
                            ref={stackRefs.current[id]}
                            {...currentOptions}
                            id={id}
                            key={id}
                            className={`grid-stack-item grid-stack-item-grilla grilla-${id} ${estado?'':'d-none'}`}
                            gs-id={id}
                            
                        >
                            <div className="grid-stack-item-content shadow-sm border" style={{backgroundColor:'#f2f2f2'}}>
                                <WidgetComponent 
                                    item={item}
                                    grid={gridRef.current}
                                    widget={widget}
                                    onRemove={()=>removeWidget(id)} 
                                    onChange={(modifyItem)=>modifyWidget(id,{item:modifyItem})}
                                    onChangeWidget={(newOptions)=>modifyWidget(id,newOptions)}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

Grilla.defaultProps = {
    isLoading: false,
    acceptWidgets: true,
    defaultValues:[],
    entryValues: null,
    getItem:null,
    isFullWidth: false,
    uniqueKeyLabel:'id',
    stackOptions:{
        float: true,
        disableResize: true,
        column: 12,
        minRow: 12,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 5,
        marginLeft: 5,
        cellHeight: '50px',
        disableOneColumnMode: true,
        staticGrid: false,
        acceptWidgets: () => {},
    },
    values:[],
    
    onChange: () => {},
    onRemove: () => {},
    getHeight: () => {},
};

export default Grilla;