import React,{ useEffect } from 'react';
import { Controller } from "react-hook-form";
import { InputLocalidadZoho, Select, SelectLugar, SelectTipoCanal } from '@app/components/index';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import { useHistory } from 'react-router';

export const schema = Yup.object({
    apellido: Yup.string().required().max(200),
    lugarId: Yup.mixed().required(),
});

function FormContacto({
    user,
    hideTareaForm,
    isModal,
    control,
    errors,
    register,
    setValue
})
{
    const history = useHistory();

    const onBack = () =>{
        history.goBack()
    }

    return (
        <>
            <div className="col-12 bg-white my-2 my-md-0 mt-lg-3">
                <div className="row">
                    <div className={`col-12 ${isModal?'col-md-12':'col-md-4'} text-md-left text-gray mb-3`}>
                        <h5>
                            <i className="fas fa-angle-down mr-2"></i>
                            Datos Personales
                        </h5>
                        <h6 className="mb-lg-3">
                            Informacion sobre el cliente
                        </h6>
                    </div>
                    <div className={`col-12 ${isModal?'col-md-12':'offset-md-1 col-md-7'}`}>
                        <div className={`row rounded ${isModal?'':'border-md border shadow-sm'} bg-white  p-2`}>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="apellido">
                                        Apellido
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.apellido? 'is-invalid': ''}`}
                                        id="apellido"
                                        name="apellido"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.apellido && (
                                        <div className="invalid-feedback">
                                            {errors.apellido.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombre">
                                        Nombre
                                    </label>
                                    <input
                                        type="text"
                                        id="nombre"
                                        className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                        name="nombre"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.nombre && (
                                        <div className="invalid-feedback">
                                            {errors.nombre.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="dnil">
                                        DNI
                                    </label>
                                    <input
                                        type="number"
                                        className={`form-control ${errors.documento? 'is-invalid': ''}`}
                                        id="documento"
                                        name="documento"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.documento && (
                                        <div className="invalid-feedback">
                                            {errors.documento.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="telefono">
                                        Telefono(opcional)
                                    </label>
                                    <input
                                        type="number"
                                        className={`form-control ${errors.telefono? 'is-invalid': ''}`}
                                        id="telefono"
                                        name="telefono"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.telefono && (
                                        <div className="invalid-feedback">
                                            {errors.telefono.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="celular">
                                        Celular
                                    </label>
                                    <input
                                        type="number"
                                        className={`form-control ${errors.celular? 'is-invalid': ''}`}
                                        id="celular"
                                        name="celular"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.celular && (
                                        <div className="invalid-feedback">
                                            {errors.celular.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${errors.email? 'is-invalid': ''}`}
                                        id="email"
                                        name="email"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.email && (
                                        <div className="invalid-feedback">
                                            {errors.email.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 border my-4">
            </div>
            {/* SEGUNDA CARD */}
            <div className="col-12 pb-md-5 pb-1 bg-white">
                <div className="row">
                    <div className={`col-12 ${isModal?'col-md-12':'col-md-4'} text-gray mb-3`}>
                        <h5>
                            <i className="fas fa-angle-down mr-2"></i>
                            Datos de Direccion
                        </h5>
                        <h6 className="">Ubicacion del cliente</h6>
                        {/* <button className="btn btn-primary float-bottom d-none d-lg-block btn-regresar mt-lg-5" type="button" onClick={onBack}>
                            <i className="fas fa-arrow-left" />
                        </button> */}
                    </div>
                    <div className={`col-12 ${isModal?'col-md-12':'offset-md-1 col-md-7'}`}>
                        <div className={`row ${isModal?'':'border-md border shadow-sm'} rounded bg-white p-2`}>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="barrio">
                                        Barrio
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.barrio? 'is-invalid': ''}`}
                                        id="barrio"
                                        name="barrio"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.barrio && (
                                        <div className="invalid-feedback">
                                            {errors.barrio.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 offset-md-1 offset-lg-0 col-md-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="direccion">
                                        Calle
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.direccion? 'is-invalid': ''}`}
                                        id="direccion"
                                        name="direccion"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.direccion && (
                                        <div className="invalid-feedback">
                                            {errors.direccion.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
								<div className="form-group">
									<label className="label-control" htmlFor="lugarId">
										Localidad
									</label>
									<Controller
										name="lugarId"
										control={control}
										render={({onChange,value},{invalid}) => (
											<SelectLugar
												id="lugarId"
												value={value}
												isInvalid={invalid}
												onChange={(opt)=>onChange(opt.value)}
											/>
										)}
									/>
									{errors.lugarId && (
										<div className="default-invalid-feedback">
											{errors.lugarId.message}
										</div>
									)}
								</div>
                            </div>
                            {/* <div className="col-4 col-lg-2">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="iva"
                                    >
                                        IVA
                                    </label>
                                    <Select
                                        id="iva"
                                        isInvalid={errors.iva}
                                        options={[
                                            {
                                                label:"21%",
                                                value: 21,
                                            },
                                            {
                                                label:"27%",
                                                value: 27,
                                            },
                                            {
                                                label:"NO APLICA",
                                                value: 0,
                                            }
                                        ]}
                                        onChange={(opt)=>{
                                            setValue('iva',opt.value);
                                        }}
                                    />
                                    {errors.iva  ? (
                                        <div className="default-invalid-feedback">
                                            {errors.iva?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div> */}
                            {
                                !hideTareaForm && !isModal && (
                                    <>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                    <label
                                                        className="label-control"
                                                        htmlFor="nombre"
                                                    >
                                                        Localidad CRM
                                                    </label>
                                                    <Controller
                                                        as={InputLocalidadZoho}
                                                        control={control}
                                                        field="nombreLocalidad"
                                                        name="Localidad"
                                                        id="Localidad"
                                                        onSelect={(opt) => {
                                                            setValue('Localidad', opt[0]);
                                                        }}
                                                        ref={register}
                                                    />
                                                    {errors.idLocalidad ? (
                                                        <div className="default-invalid-feedback">
                                                            {errors.idLocalidad?.message}
                                                        </div>
                                                    ) : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8">
                                            <div className="form-group">
                                                <label
                                                    className="label-control"
                                                    htmlFor="condicionImpositiva"
                                                >
                                                    Condición Impositiva
                                                </label>
                                                <Select
                                                    id="condicionImpositiva"
                                                    isInvalid={errors.condicionImpositiva}
                                                    options={[
                                                        {
                                                            label:"CONSUMIDOR FINAL",
                                                            value: "CONSUMIDOR FINAL",
                                                        },
                                                        {
                                                            label:"EXENTO",
                                                            value: "EXENTO",
                                                        },
                                                        {
                                                            label:"RESPONSABLE INSCRIPTO",
                                                            value: "RESPONSABLE INSCRIPTO",
                                                        },
                                                        {
                                                            label:"RESPONSABLE MONOTRIBUTISTA",
                                                            value: "RESPONSABLE MONOTRIBUTISTA",
                                                        }
                                                    ]}
                                                    onChange={(opt)=>{
                                                        setValue('condicionImpositiva',opt.value);
                                                    }}
                                                    ref={register}
                                                />
                                                {errors.condicionImpositiva  ? (
                                                    <div className="default-invalid-feedback">
                                                        {errors.condicionImpositiva?.message}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="tipoCanalId">
                                                    Canal
                                                </label>

                                                    <SelectTipoCanal
                                                        id="tipoCanalId"
                                                        isInvalid={errors.tipoCanalId}
                                                        onChange={(opt) => {
                                                        setValue('tipoCanalId', opt.value)}}
                                                        users={user}
                                                    />

                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-8">
                                            <div className="form-group">
                                                <label
                                                    className="label-control"
                                                    htmlFor="motivoTareaId"
                                                >
                                                    Motivo de la Tarea
                                                </label>

                                                {user.puesto === "Asesor" ? (
                                                    <Select
                                                        id="motivoTareaId"
                                                        isInvalid={errors.motivoTareaId}
                                                        options={[
                                                            {
                                                                label:"INSTALACION MASIVOS CON COSTO",
                                                                value: "10",
                                                            }
                                                        ]}
                                                         onChange={(opt)=>{
                                                             setValue('motivoTareaId',opt.value);
                                                         }}
                                                    />
                                                ):(
                                                    <Select
                                                        id="motivoTareaId"
                                                        isInvalid={errors.motivoTareaId}
                                                        options={[
                                                            {
                                                                label:"INSTALACION MASIVOS CON COSTO",
                                                                value: "10",
                                                            },
                                                            {
                                                                label:"INSTALACION MASIVOS SIN COSTO",
                                                                value: "4",
                                                            }
                                                        ]}
                                                         onChange={(opt)=>{
                                                             setValue('motivoTareaId',opt.value);
                                                         }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

FormContacto.defaultProps = {
    isModal: false,
    tipoCanalId: 0,
    hideTareaForm: false,
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(FormContacto);

