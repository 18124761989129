import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import CuadrillaDisponiblesService from '@services/cuadrillaDisponibles.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalCuadrillaDisponible from '@app/modals/cuadrilla-disponible/ModalCuadrillaDisponible';
import {Button, ModalConfirm,DateRange} from '@app/components/index';
import FechaFunction from '@app/functions/FechaFunction';
import usePermisos from '@app/hooks/usePermisos';

const CuadrillaDisponiblesPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const [t] = useTranslation();
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [rangeDate, setRangeDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const actualizar = async (fecha) => {
        const query = {
            ...(rangeDate?.start && { fechaIni: FechaFunction.format(rangeDate.start) }),
            ...(rangeDate?.end && { fechaFin: FechaFunction.format(rangeDate.end) }),
        };
        setIsLoading(true);
        const response = await CuadrillaDisponiblesService.getAll(query);
        setItems(response);
        setIsLoading(false);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Cuadrilla Disponible',
            message: '¿Esta seguro que desea eliminar la Cuadrilla Disponible?',
            onConfirm: () => {
                CuadrillaDisponiblesService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenVer = (item) => {
        history.push({
            pathname:`/cuadrillas/disponibles/${item.id}/ver`,
            state:{
                item,
            }
        })
    }

    const onOpenEditar = (item) => {
        showModal(ModalCuadrillaDisponible, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Inicio',
                Cell: ({row}) => {
                    return FechaFunction.format(row.original.fechaIni,'dd/MM/yyyy');
                },
                accessor: 'fechaIni',
                Filter: false,
                disableGlobalFilter: true,
            },
            {
                Header: 'Fin',
                Cell: ({row}) => {
                    return FechaFunction.format(row.original.fechaFin,'dd/MM/yyyy');
                },
                accessor: 'fechaFin',
                Filter: false,
                disableGlobalFilter: true,
            },
            {
                Header: 'Cantidad',
                accessor: 'cantidad'
            },{
                Width: 10,
                Header: 'Acciones',
                accessor: 'id',
                Filter: false,
                disableSortBy: true,
                disableGlobalFilter: true,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* <Dropdown.Item
                                    onClick={() => onOpenVer(row.original)}
                                >
                                    Ver
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    const onChangeDate = (newRange) => {
        setRangeDate(newRange);
    };

    useEffect(() => {
        actualizar();
    }, []);
    useEffect(() => {
        actualizar(rangeDate);
    }, [rangeDate]);


    return (
        <>
                <div className="container">
                    <div className="row">

                        <div className="col-6 col-lg-4">
                            <h3 data-aos="fade-right" className="my-lg-3">Cuadrillas Disponibles</h3>
                        </div>
                        <div className="col-3 col-lg-4 text-center">
                            Filtrar por Fecha
                            <DateRange
                                onChange={(f) => {setRangeDate(f)}}
                                defaultValue={rangeDate}
                                isClearable
                            />
                        </div>

                        {
                            permisos['api.disponibles.store'] && (
                            <div className="offset-3 col-3 offset-lg-2 col-lg-2">
                                <Button color="primary" className="btn-block my-lg-3" onClick={() => onOpenEditar()}>
                                    NUEVO
                                </Button>
                            </div>
                        )}

                        <div className="col-12" data-aos="fade-right">
                            <TablePaginated filterDisabled columns={columns} data={items} />
                        </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CuadrillaDisponiblesPage);
