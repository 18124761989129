import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import FormPaso1 from '@app/pages/contactos/FormPaso1';
import FormPaso2 from '@app/pages/contactos/FormPaso2';
import FormPaso3 from '@app/pages/contactos/FormPaso3';
import FormPaso4 from '@app/pages/contactos/FormPaso4';
import { Wizard } from '@app/components/index';
import ContactosAction from '@app/store/actions/contactos';

const ContactoNuevoPage = ({
    iniciarContacto,
    iniciarContrato,
    location,
}) => {
    const [t] = useTranslation();
    const history = useHistory();

    const { backTo, item } = (location && location.state) || { };

    const options = [
        {
            title: 'Contacto',
            subtitle: 'Datos del cliente',
        },
        {
            title: 'Documentación',
            subtitle: 'Archivos e imagenes',
        },
        {
            title: 'Plan',
            subtitle: 'Ubicación',
        },
        {
            title: 'Agenda',
            subtitle: 'Calendario de confirmación',
        },
    ];

    useEffect(() => {
        if(item){
            iniciarContacto(item);
            if(item.contrato){
                iniciarContrato(item.contrato);
            }
        }
        history.replace('/contactos/nuevo#step1');
        return () => {
            iniciarContacto(null);
            iniciarContrato(null);
        }
    }, []);

    return (
        <div className="container-fluid mt-3 mt-md-3 mt-lg-0">
            <div className="row">
                <div className="col-12">
                    <Wizard options={options}>
                        <FormPaso1 item={item} />
                        <FormPaso2 />
                        <FormPaso3 />
                        <FormPaso4 />
                    </Wizard>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
    iniciarContrato: (data) => dispatch(ContactosAction.iniciarContrato(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactoNuevoPage);
