import React from 'react'
import styled from 'styled-components';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

const Styles = styled.div`
`;

function VehiculoCard({
    item,
}) {
    return (
        <Styles className="border border-primary redondeado shadow-sm p-3 m-3">
            <div className="row">
                <div className="col-10">
                    <h5 className="m-0 text-bold">
                        {AuxiliarFunction.formatMatricula(item?.patente)}
                    </h5>
                </div>
                <div className="col-12"></div>
            </div>
        </Styles>
    )
}

export default VehiculoCard
