import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import PlataformaService from '@app/services/coorporativo/plataforma.service';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { actualizarAcciones } from '@app/store/slices/default.slice';
import usePermisos from '@app/hooks/usePermisos';


const schema = Yup.object({

});

function ModalPlataformaEditar({show, hideModal, item,onGuardar,camposVisibles}) {

    AOS.init();
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [itemAgregar, setItemAgregar] = useState()
    const [requerimientos, setRequerimientos] = useState(JSON.parse(item?.requerimiento));
    const [plataformas, setPlataformas] = useState(item?.plataforma);
    const [link, setLink] = useState(item?.link);
    const [clave, setClave] = useState(item?.clave);
    const [estadoAgregar, setEstadoAgregar] = useState(false)
    const [usuario, setUsuario] = useState()
    const {register, handleSubmit, errors, control, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = () => {

        const form = {
            id:item?.id,
            requerimientos:JSON.stringify(requerimientos),
            plataformas:plataformas,
            link:link,
            clave:clave
        };


            PlataformaService.update(form)
                .then(() => {
                    onGuardar()
                })
                .finally(() => {
                    setIsLoading(false);
                    hideModal();

                });
    
    };

    const agregarItem = () =>{
        requerimientos.push({item:itemAgregar});
        setEstadoAgregar(false)
    }

    const onEliminarItem = (itemLista) =>{
        const resultado = requerimientos.filter((requerimiento)=> requerimiento.item !== itemLista )
        setRequerimientos(resultado)        
    }
          

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Editar Registro
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className={JSON.parse(camposVisibles[0].requerimiento_cv)[0].visible ? "col-6" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="detalleServicio">
                                                Requerimientos
                                            </label>
                                            <button className='btn btn-xs btn-primary float-right' type='button' disabled={JSON.parse(camposVisibles[0].requerimiento_cv)[1].modificar === false} onClick={()=>setEstadoAgregar(!estadoAgregar)}>
                                            <i className={estadoAgregar !== false ? ("fas fa-angle-up") : ("fas fa-plus")} />
                                            
                                            </button>
                                                {estadoAgregar !== false && (
                                                    <div className="input-group mb-3 mt-2" data-aos="fade-up">
                                                            <input
                                                                id="requerimientos"
                                                                name="requerimientos"
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e)=>setItemAgregar(e.target.value)}
                                                                ref={register}
                                                                placeholder="Agregar a la lista"
                                                                autoComplete="off"
                                                                disabled={JSON.parse(camposVisibles[0].requerimiento_cv)[1].modificar === false}
                                                            />
                                                            <div className="input-group-append">
                                                                <button 
                                                                    className="btn btn-sm btn-primary" 
                                                                    type="button"
                                                                    onClick={()=>agregarItem()}
                                                                >
                                                                    Agregar
                                                                </button>
                                                            </div>
                                                    </div>
                                                )}


                                                <ul className={requerimientos?.length > 0 ? ("list-group mt-2 overflow-auto") : ("d-none")}>
                                                            { requerimientos?.map((requerimiento)=>(
                                                                <li className='list-group-item list-group-item-primary'>
                                                                    <span className="">{requerimiento?.item}</span>
                                                                    <button className="btn btn-sm text-danger float-right" type='button' onClick={()=>onEliminarItem(requerimiento?.item)}>x</button>
                                                                </li>
                                                            ))}
                                                </ul>
                                        </div>
                                    </div>
                                
                        
                                    <div className="col-6">
                                        <div className="row">
                                            <div className={JSON.parse(camposVisibles[0].plataforma_cv)[0].visible ? "col-12" : "d-none"}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="detalleServicio">
                                                        Plataformas
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="plataformas"
                                                        name="plataformas"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        value={plataformas}
                                                        onChange={(e)=>setPlataformas(e.target.value)}
                                                        ref={register}
                                                        disabled={JSON.parse(camposVisibles[0].plataforma_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].link_cv)[0].visible ? "col-12" : "d-none"}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="link">
                                                        Link
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="link"
                                                        name="link"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        value={link}
                                                        onChange={(e)=>setLink(e.target.value)}
                                                        ref={register}
                                                        disabled={JSON.parse(camposVisibles[0].link_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                        
                                            <div className={JSON.parse(camposVisibles[0].usuario_cv)[0].visible ? "col-12" : "d-none"}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="usuario">
                                                        Usuario
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="usuario"
                                                        name="usuario"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        value={usuario}
                                                        onChange={(e)=>setUsuario(e.target.value)}
                                                        ref={register}
                                                        disabled={JSON.parse(camposVisibles[0].usuario_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].clave_cv)[0].visible ? "col-12" : "d-none"}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="detalleServicio">
                                                        Clave
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="clave"
                                                        name="clave"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        value={clave}
                                                        onChange={(e)=>setClave(e.target.value)}
                                                        ref={register}
                                                        disabled={JSON.parse(camposVisibles[0].clave_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>                            
                        </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                {
                    permisos['api.plataformas.update'] && (
                    <Button
                        theme="primary"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                    >
                        GUARDAR
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

ModalPlataformaEditar.defaultProps = {
    show: false,
    item: {
        id: 0
    }
};

export default ModalPlataformaEditar;