import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';

const Styles = styled.div`
    width: 100%;
`;

const DateSingle = ({
    id, 
    value, 
    onChange, 
    disabled, 
    showTimeSelect,
    ...defaultProps
}) => {
    const [fecha, setFecha] = useState(value?FechaFunction.parse(value):null);

    const CustomInput = React.forwardRef(
        ({value: valueField, onClick}, ref) => (
            <input
                onClick={onClick}
                className="form-control"
                value={valueField}
                ref={ref}
                readOnly
            />
        )
    );

    const onDateSelected = (newDate) => {
        setFecha(newDate);
        if (onChange) {
            if(FechaFunction.isDate(newDate)){
                onChange(FechaFunction.format(newDate));
            } else {
                onChange(null);
            }
        }
    };

    useEffect(() => {
        if(typeof value === 'string'){
            setFecha(FechaFunction.parse(value))
        }
        return () => {
            
        }
    }, [value])
    return (
        <Styles>
            <DatePicker
                {...defaultProps}
                id={id}
                name={id}
                disabled={disabled}
                dateFormat={showTimeSelect?"dd/MM/yyyy h:mm aa":"P"}
                showTimeSelect={showTimeSelect}
                customInput={<CustomInput />}
                selected={fecha}
                onChange={date => onDateSelected(date)}
            />
        </Styles>
    );
};

DateSingle.defaultProps = {
    value: new Date(),
    disabled: false,
    showTimeSelect: false,
};

export default DateSingle;
