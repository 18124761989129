import React, {useState,useEffect, useRef, createRef} from 'react';
import {GoogleMap, useJsApiLoader, Autocomplete, DrawingManager, OverlayView } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import {Spinner} from 'react-bootstrap';
import MapaFunction from '@app/functions/MapaFunction';
import { defaultMinMax } from '@app/constants/geolocationOptions';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { Button, Poligono, Carousel } from '@app/components/index';
import {showModal} from 'react-redux-modal-provider';
import CardAsesorLugar from '@app/pages/asesor/CardAsesorLugar';
import {useWindowWidth} from '@react-hook/window-size';
import styled from 'styled-components';
import ControlPanel from '@app/components/map/ControlPanel';
import ModalConfirm from '@app/components/modals/ModalConfirm';

const StylesContainer = styled.div`
    position: relative;

    .control-panel{
        position: absolute;
        right: 10px;
        top:10px;
    }

    .carousel-panel{
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 10;
    }
`;

const libraries = ['places','drawing']

function MapDrawing({
    id,
    zoom,
    items,
    options,
    coordinates,
    onChange,
    onAddPolygon,
    onSelectShape,
    onClick,
    onRemove,
    onSearch,
    children,
    drawing,
    defaultProps,
    onInstaced,
    isLoading,
}) {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBtCQByICBfwfxHs-ny0LftBhY6iMwzMGg',
        libraries,
    });

    const [map, setMap] = useState(null);
    const [searchBox, setSearchBox] = useState(null);
    const [lastCoordinatesSend, setLastCoordinatesSend] = useState(coordinates);
    const [drawer, setDrawer] = useState(null);
    const [drawingMode, setDrawingMode] = useState(null);
    const [shapes, setShapes] = useState([]);
    const [selectedShape, setSelectedShape] = useState();
    const [selectedStack, setSelectedStack] = useState()
    const [stackItems, setStackItems] = useState([]);
    const [stackIndex, setStackIndex] = useState(0);
    const [currentZoom, setCurrentZoom] = useState(zoom);
    const [mapTypeId, setMapTypeId] = useState('roadmap');
    const stackRefs = useRef({});
    const onlyWidth = useWindowWidth();

    if (Object.keys(stackRefs.current).length !== stackItems.length) {
        stackItems.forEach(({ id:idRef }) => {
            stackRefs.current[idRef] = stackRefs.current[idRef] || createRef()
        })
    }

    const removeAllEditables = () => {
        shapes.forEach(sh=>{
            sh.setEditable(false);
        });
        stackItems.forEach(aux=>{
            stackRefs.current[aux.id].setEditable(false);
        });
        if(selectedShape){
            selectedShape.setMap(null);
        }
        setStackIndex(0);
        setSelectedShape(null);
        setSelectedStack(null);
    }

    const onDragEnd = () => {
        const newCoordinates = {
            lat: map.center.lat(),
            lng: map.center.lng()
        };
        if(MapaFunction.getDistanceCoordinates(lastCoordinatesSend,newCoordinates)>defaultMinMax.distance){
            onChange(newCoordinates);
            setLastCoordinatesSend(newCoordinates);
        }
    };

    const onClickMap = (ev) => {
        onClick({
            lat: ev.latLng.lat(),
            lng: ev.latLng.lng()
        });
    };

    const onLoad = React.useCallback(function callback(mapLoaded) {
        setMap(mapLoaded);
    }, []);

    const onPolygonComplete = polygon => {
        onAddPolygon(MapaFunction.getCoordinatesFromShape(polygon));
    }

    const onLoadDrawing = React.useCallback(function callback(newDrawing) {
        setDrawer(newDrawing)
    }, []);

    const onUnmount = React.useCallback(function callback() {
        setMap(null);
    }, []);

    const onDrawing = () => {

    }

    const onClickRemoveShape = () => {
        showModal(ModalConfirm, {
            title: 'Eliminar Zona',
            message: '¿Esta seguro que desea eliminar la zona del asesor?',
            onConfirm: () => {
                if(selectedShape){
                    selectedShape.setMap(null);
                    setSelectedShape(null);
                }
                if(selectedStack){
                    onRemove(selectedStack)
                }
            }
        });
    };

    // const onClickRemoveShape = () => {
    //     if(selectedShape){
    //         selectedShape.setMap(null);
    //         setSelectedShape(null);
    //     }
    //     if(selectedStack){
    //         onRemove(selectedStack)
    //     }
    // }

    const onClickAddPolygon = () => {
        setDrawingMode('polygon');
    }

    const onClickStop = () => {
        setDrawingMode(null);
    }

    const onChangeCarousel = (value) => {
        setStackIndex(value)
    }

    const onClickCard = (item) => {
        const newCenter = MapaFunction.getCentroidFromGJson(item);
        const indexCard = stackItems.findIndex(st=>st.id===item.id);
        onChangeCarousel(indexCard);
        map.setCenter(newCenter);
    }

    const onClickCancel = () => {
        removeAllEditables();
    }
    const onClickEdit = (item) => {
        onClickCancel();
        setSelectedStack(item);
        stackRefs.current[item.id].setEditable(true);
        onClickCard(item)
    }

    const onCompleteShape = (ev) => {
        onClickCancel();
        const newShape = ev.overlay;
        const newShapes = [...shapes,newShape];
        setShapes(newShapes);
        removeAllEditables();
        newShape.setEditable(true);
        setSelectedShape(newShape);
    }

    useEffect(() => {
        if(map){
            map.setCenter(coordinates);
        }
    }, [coordinates]);

    useEffect(() => {
        onSelectShape(selectedShape);
    }, [selectedShape]);

    useEffect(()=>{
        removeAllEditables();
        setStackItems(items);
    },[items]);

    useEffect(() => {
        onInstaced(stackRefs)
    }, [stackRefs]);

    useEffect(() => {
        shapes.forEach(currentShape=>{
            /* eslint-disable func-names */
            window.google.maps.event.clearListeners(currentShape,'click');
            window.google.maps.event.addListener(currentShape, 'click', function() {
                removeAllEditables();
                currentShape.setEditable(true);
                setSelectedShape(currentShape);
            });
            /* eslint-enable func-names */
        });
        return () => {

        }
    }, [shapes])

    return (
    <StylesContainer>
        <div className="carousel-panel">
            <Carousel>
                {stackItems.map((item)=>{
                    const isSelected = item.id === selectedStack?.id;
                    return <CardAsesorLugar className="bg-danger" item={item} key={`card_${item.id}`} selected={isSelected} onClick={onClickCard} onEdit={onClickEdit} onCancelEdit={onClickCancel} />;
                })}
            </Carousel>
        </div>

        {isLoaded ? (
            <GoogleMap
                {...defaultProps}
                id={id}
                center={coordinates}
                options={{
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControl: false,
                    ...options,
                }}
                zoom={currentZoom}
                mapTypeId={mapTypeId}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onDragEnd={onDragEnd}
                onClick={onClickMap}
                drawing={onDrawing}
            >
                <ControlPanel
                    zoom={currentZoom}
                    options={options}
                    map={map}
                    coordinates={coordinates}
                    mapTypeId={mapTypeId}
                    onChangeMapType={setMapTypeId}
                    onChangeZoom={setCurrentZoom}
                    isSearchable={false}
                    bottomMin={100}
                />

                <DrawingManager
                    onLoad={onLoadDrawing}
                    drawingMode={drawingMode}
                    /*
                    onPolygonComplete={onPolygonComplete}
                    */
                    onOverlayComplete={onCompleteShape}
                    options={{
                        drawingControl: false,
                        drawingControlOptions:{
                            drawingModes:['polygon']
                        }
                    }}
                />

                <div className="control-panel">
                    <Button
                        icon="fas fa-hand-paper"
                        disabled={drawingMode === null}
                        onClick={onClickStop}
                    />
                    <Button
                        icon="fas fa-draw-polygon"
                        onClick={onClickAddPolygon}
                        disabled={drawingMode === 'polygon'}
                    />
                    <Button
                        className="btn btn-danger"
                        icon="fas fa-trash"
                        disabled={!selectedShape && !selectedStack}
                        onClick={()=>onClickRemoveShape(selectedStack)}
                    />
                </div>

                {children}
                {
                    stackItems.map((item)=>{
                        const centroid = MapaFunction.getCentroidFromGJson(item);
                        const isSelected = item.id === selectedStack?.id;
                        return <Poligono item={item} key={item.id} onLoaded={(ref)=>{stackRefs.current[item.id]=ref}} />;
                    })
                }
                {
                    stackItems.map((item)=>{
                        const centroid = MapaFunction.getCentroidFromGJson(item);
                        const isSelected = item.id === selectedStack?.id;
                        return (
                            <OverlayView
                                key={`overlay_${item.id}`}
                                position={centroid}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <CardAsesorLugar item={item} onClick={onClickCard} onEdit={onClickEdit} onCancelEdit={onClickCancel} selected={isSelected} />
                            </OverlayView>
                        )
                    })
                }
            </GoogleMap>
            ) : (<Spinner></Spinner>)
        }
    </StylesContainer>
    )
}

MapDrawing.defaultProps = {
    zoom: 10,
    items: [],
    coordinates: {
        lat: -24.7821,
        lng: -65.4232
    },
    mapContainerStyle: {
        width: '100%',
        height: '100%'
    },
    isLoading: false,
    onChange: () => {},
    onClick: () => {},
    onRemove: () => {},
    onSearch: () => {},
    onDrawing: () => {},
    onAddPolygon: () => {},
    onSelectShape: () => {},
    onInstaced: () => {},
};

export default React.memo(MapDrawing);
