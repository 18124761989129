import React, {useState, useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { connect } from "react-redux";
import {useTranslation} from 'react-i18next';
import {BoxPrefactibilidad, InputCheck, MapBase, Poligono} from '@app/components/index';
import './MapaComercial.scss';
import * as MapaAction from "@app/store/actions/mapas";
import useCurrentLocation from "@app/hooks/useCurrentLocation";
import MapaFunction from "@app/functions/MapaFunction";
import AsesorxLugaresService from "@app/services/asesorxLugares.service";
import { useHistory } from "react-router";

function MapaAsesorPage ({
    poligonos,
    recuperarPoligono,
    esMostrarPaneles,
    cambiarMostrarPanel,
    user
}) {
    AOS.init();
    const {puesto} = user;
    const [t] = useTranslation();
    const { location: currentLocation, error: currentError } = useCurrentLocation();
    const [poliasesor, setPoliasesor] = useState();
    const [zonaAsignada, setZonaAsignada] = useState("");
    const [mostrarMapa, setMostrarMapa] = useState(true);
    const [mostrarPrefactibilidad, setMostrarPrefactibilidad] = useState(false)
    const [mapLocation, setMapLocation] = useState(currentLocation);
    const [prefactibilidadLocation, setPrefactibilidadLocation] = useState(currentLocation);
    const history = useHistory();


    const onBack = () =>{
        history.goBack()
    }

    const recuperar = (coord) => {
        const query = {
            coordenadas: `${coord.lat},${coord.lng}`,
        }
        recuperarPoligono(query);
    }

    // const cargarAsesor = () =>{
    //     recuperar(currentLocation);
    //     AsesorxLugaresService.getAll().then((response)=>{
    //         setPoliasesor(response);
    //         console.log(poliasesor)
    //             // let [id] = response;
    //             // let {aristas} = response;
    //             // let {coordinates} = aristas;
    //             // setMapLocation({lat:coordinates[0][0],lng:coordinates[0][1]});
    //             // console.log(mapLocation);

    //     })
    // }
    // cargarAsesor();
    const onChange = (newCoordinates) => {
        recuperar(newCoordinates);
    }

    const onClickMap = (newCoord) => {
        setPrefactibilidadLocation(newCoord);
        setMostrarPrefactibilidad(true);
    }

    const onSearchBox = ({coordenadas}) => {
        setPrefactibilidadLocation(coordenadas);
        recuperar(coordenadas);
    }

    useEffect(() => {
        recuperar(currentLocation);
        AsesorxLugaresService.getAll().then((response)=>{
            if(puesto ==="Asesor" && response.length===1){
                setMostrarMapa(true)
                setPoliasesor(response);
                setMapLocation({
                    lat:response[0].aristas.coordinates[0][0][1],
                    lng:response[0].aristas.coordinates[0][0][0]
                })
            }
            else if(puesto ==="Asesor" && response.length>1){
                setMostrarMapa(true)
                setPoliasesor(response);
                setMapLocation(currentLocation)
            }
            else if(puesto !== "Asesor" && response.length>=1){
                setMostrarMapa(true)
                setPoliasesor(response);
                setMapLocation(currentLocation)
            }else{
                setPoliasesor(response);
                setZonaAsignada("Por el momento no tiene una zona asignada");
                setMostrarMapa(false)
            }
        })
    }, []);


    const unionPoligonos = MapaFunction.unirPoligonos(poligonos);


    return (
                <div className="container-fluid">
                        <div className="row">
                            {user.puesto === "Asesor" && mostrarMapa === true? (
                                <div className="col-12 text-center mt-3 my-1" data-aos="fade-up">
                                    <h2>Tu zona</h2>
                                </div>
                            ):(
                          "")}
                            {user.puesto !== "Asesor" && mostrarMapa===true ? (
                                <div className="col-12 text-center mt-3 my-1" data-aos="fade-up">
                                    <h2>Lugares Disponibles Asesor</h2>
                                    <InputCheck label="Mostrar Paneles" checked={esMostrarPaneles} onClick={cambiarMostrarPanel} />
                                </div>
                            ) : (
                                ""
                            )}

                            {mostrarMapa && (
                                    <div className="offset-1 offset-lg-0 mt-3 mt-lg-0 border bg-white rounded col-10 col-lg-12 mt-lg-2 p-2" data-aos="fade-right">
                                        <MapBase
                                            id="mapaAsesor"
                                            mapContainerStyle={{
                                                width: '100%',
                                                height: '70vh'
                                            }}
                                            options={{
                                                maxZoom: 19,
                                                minZoom: 14,
                                            }}
                                            zoom={15}
                                            coordinates={mapLocation===null ? null : mapLocation}
                                            onChange={onChange}
                                            onClick={onClickMap}
                                            onSearch={onSearchBox}
                                        >
                                            {
                                                user.puesto !=="Asesor" && esMostrarPaneles && unionPoligonos.map(poly=>{
                                                    return <Poligono item={poly} key={poly.id} />
                                                })
                                        }
                                            {
                                                poliasesor?.map(poly=>{
                                                    return <Poligono item={poly} key={poly.id} />
                                                })
                                            }
                                            {
                                                mostrarPrefactibilidad && (
                                                    <BoxPrefactibilidad coordinates={prefactibilidadLocation} />
                                                )
                                            }
                                        </MapBase>
                                    </div>
                            )}

                            {mostrarMapa === false && (
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="offset-lg-2 col-lg-8 mt-lg-5 mt-5">
                                            <div className="alert alert-primary text-center text-white" role="alert">
                                                Todavía no tienes una zona asignada
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}

                        </div>
                </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.currentUser,
    poligonos: state.mapas.poligonos,
    tareas: state.mapas.tareas,
    prefactibilidad: state.mapas.prefactibilidad,
    esMostrarPaneles: state.mapas.esMostrarPaneles,
});

const mapDispatchToProps = (dispatch) => ({
    recuperarPoligono: (data) => dispatch(MapaAction.recuperarPoligonos(data)),
    cambiarMostrarPanel: (data) => dispatch(MapaAction.cambiarMostrarPaneles(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapaAsesorPage);