import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,SelectMotivoRepro} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import TareasService from '@services/tareas.service';
import FechaFunction from '@app/functions/FechaFunction';

const schema = Yup.object({
    motivoReprogramadoId: Yup.mixed().required(),
    motivoReprogramadoObservaciones: Yup.string().max('5000')
});

function ModalTareaRepro({show, hideModal, itemModal, onGuardarConFecha, onGuardarSinFecha}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [observaciones, setObservaciones] = useState()
    const {register, handleSubmit, errors,setValue} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data,bandera) => {
        const form = {
            ...itemModal,
            ...data,
        };

        // REPROGRAMAR CON FECHA DESDE CALENDARIO

        if(bandera){
            form.tkupdate = true;
            onGuardarConFecha(form);
            hideModal();
        }else{

        // REPROGRAMAR SIN FECHA

            form.estadoTareaId = 7;
            form.fechaTicket= FechaFunction.addDaysDate(new Date(),30)
            setIsLoading(true);
            TareasService.estados(form).then(()=>{
                onGuardarSinFecha();
                hideModal();
            })
            .finally(()=>{
                setIsLoading(false);
            })
        }

    };
    useEffect(() => {
        
    }, []);

    useEffect(() => {
        register('motivoReprogramadoId');
    }, [register]);

    // console.log(itemModal)
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Esta por reprogramar la tarea. ¿Desea continuar?
                            </Modal.Title>
                        </Modal.Header>
                    </div>
                    <div className="col-12">
                        <Modal.Body>
                            <div className="form-group">
                                <label htmlFor="motivoReprogramadoId">
                                    Seleccione el motivo por el cual se reprograma:
                                </label>
                                <SelectMotivoRepro
                                    id="motivoReprogramadoId"
                                    isInvalid={errors.motivoReprogramadoId}
                                    onChange={(opt)=>setValue('motivoReprogramadoId',opt.value)}
                                />
                                {errors.motivoReprogramadoId && (
                                    <div className="default-invalid-feedback pl-1">
                                        El motivo es requerido
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="label-control" htmlFor="motivoReprogramadoObservaciones">
                                    Por favor, deje sus observaciones:
                                </label>
                                <textarea
                                    className={`form-control ${errors.motivoReprogramadoObservaciones? 'is-invalid': ''}`}
                                    id="motivoReprogramadoObservaciones"
                                    name="motivoReprogramadoObservaciones"
                                    type="text"
                                    autoComplete="off"
                                    ref={register} 
                                />
                                {errors.motivoReprogramadoObservaciones && (
                                    <div className="invalid-feedback">
                                        {errors.motivoReprogramadoObservaciones.message}
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                    </div>
                    <div className="col-12">
                            <Modal.Footer>
                                <div className="row">
                                    <div className="col text-left">
                                            <Button
                                                theme="warning"
                                                onClick={handleSubmit((form)=>onSubmit(form,false))}
                                                isLoading={isLoading}
                                            >
                                                Reprogramar Sin Fecha
                                            </Button>
                                    </div>
                                    <div className="col text-center">
                                        <Button theme="default" onClick={handleClose} disabled={isLoading}>
                                            CANCELAR
                                        </Button>
                                    </div>
                                    <div className="col text-right">
                                            <Button
                                                theme="info"
                                                onClick={handleSubmit((form)=>onSubmit(form,true))}
                                                isLoading={isLoading}
                                            >
                                                Reprogramar con Fecha
                                            </Button>
                                    </div>
                                </div>
                            </Modal.Footer>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

// ModalTareaRepro.propTypes = {
//     show: PropTypes.bool,
//     itemModal: PropTypes.shape({
//         id: PropTypes.number,
//         observaciones: PropTypes.string
//     })
// };

ModalTareaRepro.defaultProps = {
    show: false,
    itemModal: {
        id: 0,
        observaciones: ''
    }
};

export default ModalTareaRepro;