import React from 'react';
import {connect} from 'react-redux';
import {NavLink, Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Sidebar from '@app/modules/main/menu-sidebar/SideBar';
import SideItem from '@app/modules/main/menu-sidebar/SideItem';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import usePermisos from '@app/hooks/usePermisos';
import UserDropdown from '@app/modules/main/header/user-dropdown/UserDropdown';

const MenuSidebar = ({user, toggleMenuSidebar, onUserLogout}) => {
    const {puesto}=user;
    const {t} = useTranslation();
    const permisos = usePermisos();
    const history = useHistory();

    // if(!permisos['api.check']){

    // }

     const logOut = (event) => {
        onUserLogout().then(()=>{
             history.push('/login');
        })
    };


    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to={puesto === "Asesor" || puesto ==="Agencia" || puesto==="Corporativo" ? ("/home") : ("/")} className="brand-link">
                <img
                    src="/img/logo.png"
                    alt=""
                    className="brand-image mr-2 mr-3"
                    style={{opacity: '.8'}}
                />
                <span className="brand-text font-weight-normal ml-2">Nubicom SRL</span>
            </Link>
            <OverlayScrollbarsComponent
                options={{
                    scrollbars: {
                        autoHide: 'leave',
                        clickScrolling: true
                    },
                    className: 'os-theme-light',
                    sizeAutoCapable: true
                }}
                className="sidebar"
            >
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={
                                (user && user.picture) ||
                                '/img/default-profile.png'
                            }
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="text-white info">
                        {/* <Link className="d-block"> */}
                            {user.nombre}
                        {/* </Link> */}
                    </div>
                </div>

                <nav className="mt-2">
                    <Sidebar toggleMenuSidebar={toggleMenuSidebar}>
                                {/* //////////////////SECCION DASHBOARD/////////////////// */}
                        {
                            !permisos['api.asesor'] &&
                            !permisos['api.corporativo'] &&
                            permisos['api.estadisticas.diarias'] &&
                            permisos['api.estadisticas-totales'] && (
                                <SideItem
                                icon="fas-tachometer-alt"
                                text="Dashboard"
                                to="/"
                                />
                        )}
                                {/* //////////////////SECCION HOME////////////////// */}
                        {
                            permisos['api.asesor'] &&
                            permisos['api.estadisticas.diarias'] &&
                            permisos['api.estadisticas-totales'] && (
                                <SideItem
                                    icon="fas-tachometer-alt"
                                    text="Home"
                                    to="/home"
                                />

                        )}
                                {/* /////////////////////SECCION CALENDARIO//////////////////// */}
                        {
                            !permisos['api.asesor'] &&
                            permisos['api.calendario.index'] && (
                                <SideItem
                                icon="fas-calendar-alt"
                                text="Calendario"
                                to="/calendario"
                                />
                        )}
                                {/* //////////////////////SECCION TAREAS//////////////////// */}
                        {
                            permisos['api.venta'] &&
                            !permisos['api.asesor'] &&
                            (permisos['api.tareas.index'] ||
                            permisos['api.personas.index']) && (
                                <SideItem
                                icon="fas-list"
                                text="Tareas"
                                to="/tareas"
                                />
                        )}
                                {/* ////////////////////SECCION TAREAS PYMES///////////////////// */}
                        {
                            !permisos['api.asesor'] &&
                            permisos['api.pyme'] &&
                            (permisos['api.tareas.index'] ||
                            permisos['api.personas.index']) && (
                                <SideItem
                                icon="fa-clipboard-check"
                                text="Tareas Pymes Vip"
                                to="/tareas/pymes"
                                />
                        )}
                                {/* //////////////////////SECCION MAPAS//////////////////////// */}
                        {
                            !permisos['api.asesor'] &&
                            (permisos['api.tareas.mapa.disp.tareasbuscarmap'] ||
                            permisos['api.poligonos.index'] ||
                            permisos['api.nodos.index']) && (
                            <SideItem text="Mapas" icon="fas-map-marked-alt">

                                {
                                    permisos['api.tareas.mapa.disp.tareasbuscarmap'] &&
                                    permisos['api.poligonos.index'] && (
                                        <SideItem
                                        icon="fas-map"
                                        text="Comercial"
                                        to="/mapas/comercial"
                                        />
                                )}

                                {
                                    permisos['api.tareas.mapa.disp.tareasbuscarmap'] &&
                                    permisos['api.poligonos.index'] && (
                                        <SideItem
                                        icon="fas-map-marked"
                                        text="Ingieneria"
                                        to="/mapas/ingenieria"
                                        />
                                )}

                                <SideItem
                                    text="Lugares"
                                    to="/mapas/lugares"
                                    icon="fas-map-signs"
                                />
                                <SideItem
                                    text="Zonas"
                                    to="/zonas"
                                    icon="fas-road"
                                />

                                {
                                    permisos['api.nodos.index'] && (
                                        <SideItem
                                        text="Nodos"
                                        to="/mapas/nodos"
                                        icon="fas-network-wired"
                                        />
                                )}

                                {
                                    permisos['api.nodos.index'] &&
                                    permisos['api.poligonos.index'] && (
                                        <SideItem
                                        text="Paneles"
                                        to="/mapas/poligonos"
                                        icon="fas-satellite-dish"
                                        />
                                )}

                            </SideItem>
                        )}
                                {/* ////////////////////SECCION CUADRILLAS/////////////////////// */}
                        {
                            (permisos['api.jugadores.index'] ||
                            permisos['api.flotaLugar.lugares.index'] ||
                            permisos['api.disponibles.index']) &&
                            permisos['api.cuadrillaLugar.lugares.index'] &&
                            permisos['api.tareas.index'] && (
                            <SideItem text="Cuadrillas" icon="fas-users">

                                {
                                    permisos['api.jugadores.index'] &&
                                    permisos['api.cuadrillaLugar.lugares.index'] &&
                                    permisos['api.flotaLugar.lugares.index'] && (
                                        <SideItem
                                        icon="fas-calendar-day"
                                        text="Asignaciones"
                                        to="/cuadrillas/planificaciones"
                                        />
                                )}

                                {
                                    permisos['api.disponibles.index'] && (
                                        <SideItem
                                        icon="fas-users"
                                        text="Disponibilidad"
                                        to="/cuadrillas/disponibles"
                                        />
                                )}

                                {
                                    permisos['api.jugadores.index'] &&
                                    permisos['api.tareas.index'] && (
                                        <SideItem
                                        icon="fas-star"
                                        text="Pyme - Vip - SC"
                                        to="/cuadrillas/lugares"
                                        />
                                )}

                                {
                                    permisos['api.cuadrillaLugar.lugares.index'] && (
                                        <SideItem
                                        icon="fas-map-signs"
                                        text="Lugares"                                  // /////////////REVISAR TIENE ERROR CUANDO SE AGREGA LA RUTA /////////// 
                                        to="/cuadrillas/lugares/listado"
                                        />
                                )}
                            </SideItem>
                        )}
                                {/* ///////////////////SECCION FLOTAS////////////////////////// */}
                        {
                            permisos['api.vehiculos.index'] &&
                            permisos['api.jugadores.index'] &&
                            permisos['api.jugadores'] && (
                            <SideItem text="Flotas" icon="fas-car">
                                {
                                    permisos['api.vehiculos.index'] &&
                                    permisos['api.jugadores.index'] &&
                                    permisos['api.jugadores'] && (
                                        <SideItem
                                        icon="fa-calendar-day"
                                        text="Asignaciones"
                                        to="/flotas/asignaciones"
                                        />
                                )}

                                {
                                    permisos['api.jugadores'] && (
                                        <SideItem
                                        text="Jugadores"
                                        to="/jugadores"
                                        icon="fas-people-carry"
                                        />
                                )}

                                {
                                    permisos['api.vehiculos.index'] && (
                                        <SideItem
                                        icon="fas-truck-pickup"
                                        text="Vehículos"
                                        to="/vehiculos"
                                        />
                                )}

                            </SideItem>
                        )}
                                {/* ////////////////////SECCION CONFIGURACIONES//////////////////// */}
                        {
                            !permisos['api.asesor'] &&
                            (permisos['api.roles.index'] ||
                            permisos['api.acciones.index'] ||
                            permisos['api.puestos.index'] ||
                            permisos['api.tipos.fotos.index'] ||
                            permisos['api.canales.index'] ||
                            permisos['api.reprogramados.index'] ||
                            permisos['api.motivoTarea.tareas.index'] ||
                            permisos['api.planes.index']) && (
                            <SideItem text="Configuraciones" icon="fas-cog">

                                {
                                    permisos['api.roles.index'] && (
                                        <SideItem
                                        icon="fas-address-book"
                                        text="Roles"
                                        to="/roles"
                                        />
                                )}

                                {
                                    permisos['api.acciones.index'] && (
                                        <SideItem
                                        icon="fas-id-card-alt"
                                        text="Acciones"
                                        to="/acciones"
                                        />
                                )}

                                {
                                    permisos['api.puestos.index'] && (
                                        <SideItem
                                        icon="fas-id-card-alt"
                                        text="Puestos"
                                        to="/puestos"
                                        />
                                )}

                                {
                                    permisos['api.tipos.fotos.index'] && (
                                        <SideItem
                                        icon="far-images"
                                        text="Tipo Foto"
                                        to="/tipo/tipoFoto"
                                        />
                                )}

                                {
                                    permisos['api.canales.index'] && (
                                        <SideItem
                                        icon="fas-share-alt-square"
                                        text="Canales"
                                        to="/tipoCanales"
                                        />
                                )}

                                {
                                    permisos['api.reprogramados.index'] && (
                                        <SideItem
                                        icon="fas-sticky-note"
                                        text="Mot. de reprogramacion"
                                        to="/motivos/reprogramados"
                                        />
                                )}

                                {
                                    permisos['api.motivoTarea.tareas.index'] && (
                                        <SideItem
                                        text="Mot. Tareas"
                                        to="/motivos/tareas"
                                        icon="fas-tasks"
                                        />
                                )}

                                {
                                    permisos['api.planes.index'] && (
                                        <SideItem
                                        icon="fas-wifi"
                                        text="Planes"
                                        to="/planes"
                                        />
                                )}

                            </SideItem>
                        )}
                                {/* /////////////////SECCION CONTACTOS///////////////////// */}
                        {
                            (permisos['api.contactos.index'] ||
                            permisos['api.planes.index']) &&
                            permisos['api.poligonos.index'] && (
                            <SideItem icon="far-address-book" text="Contactos">
                                {
                                    permisos['api.contactos.index'] && (
                                        <SideItem
                                        icon="far-images"
                                        text="Buscar"
                                        to="/contactos"
                                        />
                                )}

                                {
                                    permisos['api.contactos.index'] && (
                                        <SideItem
                                        icon="fas-share-alt-square"
                                        text="Mapa"
                                        to="/contactos/mapa"
                                        />
                                )}

                                {
                                    permisos['api.canales.index'] &&
                                    permisos['api.planes.index'] &&
                                    permisos['api.poligonos.index'] && (
                                    <SideItem
                                        icon="fas-user-plus"
                                        text="Agregar"
                                        to="/contactos/nuevo"
                                    />
                                )}
                            </SideItem>
                        )}
                                {/* ///////////////SECCION ASESORES////////////////// */}
                        {
                            permisos['api.detalles.contratos.index'] &&
                            (permisos['api.personas.index'] ||
                            permisos['api.asesores.lugares.index'] ||
                            permisos['api.poligonos.index']) && (
                            <SideItem
                            icon="fas-id-card-alt"
                            text={puesto !== "Asesor" ? ("Asesores") : ("Ver")}
                            >
                                {
                                    !permisos['api.asesor'] &&
                                    permisos['api.personas.index'] && (
                                        <SideItem
                                        icon="fas-user-tie"
                                        text="Buscar"
                                        to="/personas"
                                        />
                                )}

                                {
                                    !permisos['api.asesor'] &&
                                    permisos['api.asesores.lugares.index'] && (
                                        <SideItem
                                        icon="fa-draw-polygon"
                                        text="Lugares"
                                        to="/asesores/lugares"
                                        />
                                )}

                                {
                                    !permisos['api.asesor'] && (
                                        <SideItem
                                        icon="fas-user-plus"
                                        text="Agregar"
                                        to="/personas/nuevo"
                                        />
                                )}

                                {
                                    permisos['api.detalles.contratos.index'] &&
                                    permisos['api.personas.index'] && (
                                        <SideItem
                                        icon="fas-table"
                                        text="Detalles"
                                        to="/asesor/detalle"
                                        />
                                )}

                                {
                                    permisos['api.poligonos.index'] &&
                                    permisos['api.asesores.lugares.index'] && (
                                        <SideItem
                                        icon="fas-map"
                                        text={puesto ==="Asesor" ? ("Zona") : ("Cobertura")}
                                        to="/mapas/asesor"
                                        />
                                )}

                            </SideItem>
                        )}
                                {/* ////////////////SECCION FOTOS//////////////// */}
                        {/* {
                            permisos['api.tipos.fotos.index'] &&
                            permisos['api.fotos.index'] && (
                                <SideItem
                                icon="fas-portrait"
                                text="Fotos"
                                to="/fotos"
                                />
                        )} */}

                        {/* <SideItem
                            icon="fas-file-contract"
                            text="Recibos"
                            to="/recibos/nuevo"
                        />
                        <SideItem
                            icon="fas-folder-open"
                            text="Recibos"
                        >
                            <SideItem
                                icon="fas-list-ol"
                                text="Lista"
                                to="/recibos/recibos"
                            />
                            <SideItem
                                icon="fas-file-medical"
                                text="Nuevo"
                                to="/recibos/nuevo"
                            />
                        </SideItem> */}
                        {/* <SideItem
                            icon="fas-folder-open"
                            text="Comisiones"
                        >
                            <SideItem
                                icon="fas-list-ol"
                                text="Comisiones"
                                to="/comisiones/generales"
                            />
                        </SideItem> */}
                                {/* ////////////////SECCION CORPORATIVOS/////////////////////////// */}
                        {
                            (permisos['api.general.index'] || 
                            permisos['api.datos-personales.index'] || 
                            permisos['api.plataformas.index'] || 
                            permisos['api.administraciones.index'] ||
                            permisos['api.informaciones-tecnicas.index']) && (
                            <SideItem icon="fas-file-medical-alt" text="Corporativo">
                            
                                {
                                    permisos['api.general.index'] && (
                                    <SideItem
                                        icon="fas-user-shield"
                                        text="Admin"
                                        to="/coorporativos"
                                    />
                                )}
                                {
                                    permisos['api.datos-personales.index'] && (
                                    <SideItem
                                        icon="fas-user-friends"
                                        text="Datos Clientes"
                                        to="/coorporativo/clientes"
                                    />
                                )}

                                {
                                    permisos['api.plataformas.index'] && (
                                    <SideItem
                                        icon="far-images"
                                        text="Plataformas"
                                        to="/coorporativo/plataforma"
                                    />
                                )}

                                {
                                    permisos['api.administraciones.index'] && (
                                    <SideItem
                                        icon="fas-address-book"
                                        text="Administración"
                                        to="/coorporativo/administracion"
                                    />
                                )}
                            
                                {
                                    permisos['api.informaciones-tecnicas.index'] && (
                                    <SideItem
                                        icon="fas-tools"
                                        text="Información Tecnica"
                                        to="/coorporativo/tecnica"
                                    />
                                )}
                                {
                                    permisos['api.general.index'] && (
                                    <SideItem
                                        icon="fas-user-cog"
                                        text="Administrar Usuarios"
                                        to="/coorporativo/administrar/usuarios"
                                    />
                                )}
                                {
                                    permisos['api.general.index'] && (
                                    <SideItem
                                        icon="fas-list"
                                        text="Logs"
                                        to="/coorporativo/logs"
                                    />
                                )}
                            </SideItem>
                        )}
                                {/* ////////////////SECCION LOGS/////////////////////////// */}
                        {
                            permisos['api.logs.tareas'] &&
                            permisos['api.logs.index'] && (
                                <SideItem
                                icon="fas-info-circle"
                                text="Logs"
                                to="/log/log"
                                />
                        )}
                    </Sidebar>
                </nav>
            </OverlayScrollbarsComponent>
        </aside>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

export default connect(mapStateToProps, null)(MenuSidebar);
