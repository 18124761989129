import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import { connect } from "react-redux";
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import FechaFunction from '@app/functions/FechaFunction';

function ModalContactoHistorialTicket({
    show,
    hideModal,
    id,
   contactosTickets}) {
    const [isLoading, setIsLoading] = useState(false);
    const [tickets, setTickets] = useState(contactosTickets.data)
    const handleClose = () => hideModal();

    // console.log(tickets);


    useEffect(() => {

    }, [])


    return (
        <Modal show={show} backdrop="static" size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Historial de Tickets
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                        {
                        tickets?.map((ticket) =>{
                            return(
                                <div className="row">
                                    <div className="col-2 text-center">
                                        <p>{FechaFunction.format(ticket.createdTime)}</p>
                                    </div>
                                    <div className="col-1 text-center">
                                    {ticket.channel === "Web" && (
                                            <i className="fab fa-internet-explorer mt-3"></i>
                                        )}
                                    {ticket.channel === "Twitter" && (
                                            <i className="fab fa-twitter mt-3"></i>
                                        )}
                                    {ticket.channel === "Telefono" && (
                                            <i className="fas fa-phone-alt mt-3"></i>
                                        )}
                                    {ticket.channel === "Email" && (
                                            <i className="far fa-envelope mt-3"></i>
                                        )}
                                    {ticket.channel === "Whatsapp" && (
                                            <i className="fab fa-whatsapp mt-3"></i>
                                        )}
                                    </div>
                                    <div className="col-8">
                                        <a className="text-dark" target="_blank" rel="noopener noreferrer" href={ticket.webUrl}>
                                            <h5>{`${ticket.ticketNumber} - ${ticket.subject}`}</h5>
                                        </a>
                                        <p className={ticket.status === "Cerrado" ? `text-danger`:`text-success`}>{ticket.status}</p>
                                    </div>
                                    <div className="col-1 px-3">

                                        {
                                        ticket.team===null ?(
                                            <div className="bg-white border text-center border-dark rounded-circle">
                                                nul
                                            </div>
                                        ):(
                                            <>
                                                {ticket.team.name === "ADMINISTRACION" && (
                                                    <div className="bg-white border text-center border-dark rounded-circle">
                                                        AD
                                                    </div>
                                                )}
                                                {ticket.team.name === "LOGISTICA" && (
                                                    <div className="bg-white border text-center border-dark rounded-circle">
                                                        AD
                                                    </div>
                                                )}
                                                {ticket.team.name === "ATENCION AL CLIENTE" && (
                                                    <div className="bg-white border text-center border-dark rounded-circle">
                                                        AC
                                                    </div>
                                                )}
                                                {ticket.team.name === "IDA MASIVOS" && (
                                                    <div className="bg-white border text-center border-dark rounded-circle">
                                                        ID
                                                    </div>
                                                )}
                                                {ticket.team.name === "GESTION ADMIN DA" && (
                                                    <div className="bg-white border text-center border-dark rounded-circle">
                                                        GD
                                                    </div>
                                                )}
                                                {ticket.team.name === "STOCK" && (
                                                    <div className="bg-white border text-center border-dark rounded-circle">
                                                        ST
                                                    </div>
                                                )}
                                            </>

                                        )

                                    }
                                    </div>
                                </div>
                                )

                        })
                        }
                </Modal.Body>
        </Modal>
    );
}

ModalContactoHistorialTicket.defaultProps = {
    show: false
};



export default ModalContactoHistorialTicket;