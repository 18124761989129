import React, {useState, useEffect, useRef, createRef, forwardRef, useCallback} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { connect } from "react-redux";
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import { Spinner, Collapse } from "react-bootstrap";
import {GridStack} from 'gridstack';
import { Button, DateWeek, Grilla, InputCheck, TableCellJugador, TableCellVehiculo, TablePaginated, WidgetFlota, WidgetVehiculo } from "@app/components/index";
import { v4 as uuidv4 } from 'uuid';
import {showModal} from 'react-redux-modal-provider';

import './FlotasAsignacion.scss';
import * as FlotasAction from "@app/store/actions/flotas";
import FlotaJugadoresService from "@app/services/flotaJugadores.service";
import ModalFlotaJugadorCopiar from "@app/modals/flota-jugador/ModalFlotaJugadorCopiar";
import FechaFunction from "@app/functions/FechaFunction";
import usePermisos from '@app/hooks/usePermisos';

function FlotasAsignacionPage ({
    jugadores,
    vehiculos,
    flotas,

    recuperarJugadores,
    recuperarVehiculos,
    recuperarFlotas,
    iniciarFlotas,
    agregarFlota,
    modificarFlota,
    eliminarFlota,
    limpiarFlotas,
}) {
    AOS.init();
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeWeek())
    const [jugadoresData, setJugadoresData] = useState([]);
    const [vehiculosData, setVehiculosData] = useState([]);
    const [isVehiculosTable, setIsVehiculosTable] = useState(true);
    const [isJugadoresTable, setIsJugadoresTable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState([]);
    const [isFiltrarSeleccionados, setIsFiltrarSeleccionados] = useState(false);

    const onClickVehiculosTable = () =>{
        setIsVehiculosTable(!isVehiculosTable);
        setIsJugadoresTable(isVehiculosTable);
    }
    const onClickJugadoresTable = () =>{
        setIsVehiculosTable(isJugadoresTable);
        setIsJugadoresTable(!isJugadoresTable);
    }

    const onChangeFiltrarSeleccionados = () => {
        setIsFiltrarSeleccionados(!isFiltrarSeleccionados)
    }

    const onChangeVehiculoTable = () => {
        GridStack.setupDragIn('#vehiculosTable .grid-stack-item-vehiculo',{ revert: 'invalid', scroll: false, appendTo: 'body', helper: 'clone' });
    };

    const onFilterVehiculos = useCallback((rows, columnNames, filterValue) => {
        return rows.filter(({original}) => {
            const { patente, marca, modelo } = original;
            const cleanRowData = [String(patente).toLocaleLowerCase(), String(marca).toLocaleLowerCase(), String(modelo).toLocaleLowerCase()];
            return cleanRowData.find(rowString => rowString.includes(filterValue.toLowerCase()));
        });
    }, []);

    const onFilterJugadores = useCallback((rows, columnNames, filterValue) => {
        return rows.filter(({original}) => {
            const { dni, nombre } = original;
            const cleanRowData = [String(dni).toLocaleLowerCase(), String(nombre).toLocaleLowerCase()];
            return cleanRowData.find(rowString => rowString.includes(filterValue.toLowerCase()));
        });
    }, []);

    const columnsVehiculoTable = React.useMemo(
        () => [
            {
                Header: 'Vehiculo',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0 d-flex justify-content-between',
                isGlobalFilter: true,
                Cell: (props) => <TableCellVehiculo {...props} isSelected={flotas.find(({vehiculoId})=>vehiculoId===props.row.original.id)} />,
            },
        ],
        [flotas,vehiculosData]
    );

    const columnsJugadorTable = React.useMemo(
        () => [
            {
                Header: 'Jugador',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0 d-flex justify-content-between',
                isGlobalFilter: true,
                Cell: (props) => (
                    <TableCellJugador
                        {...props}
                        isSelected={flotas.find(({jugadores:currentJugadores})=>{
                            return currentJugadores.find(jg=>jg.dni === props.row.original.dni);
                        })} 
                    />
                ),
            },
        ],
        [flotas]
    );

    const onGetItem = useCallback(
        (id) => {
            const existe = flotas.find(fj=>fj.vehiculoId === id);
            if(existe){
                return null;
            }
            const vehiculo = vehiculos.find(ve=>ve.id===id);
            const newItem = {
                id: uuidv4(),
                vehiculoId: id,
                vehiculo: vehiculo,
                jugadores: [],
                campo: 'IDA',
            }
            agregarFlota(newItem);
            return newItem;
        },
        [flotas],
    );

    const onGetHeight = (item)=>{
        const newH = item.jugadores.length + 2;
        return newH;
    }

    const onRemoveFlota = (removedFlota) => {
        eliminarFlota(removedFlota);
    }
    const onChangeGrilla = (lista) => {
        lista.forEach(li=>{
            modificarFlota(li);
        });
    }

    const actualizar = ( customDates = rangeDate) => {
        const { start, end} = customDates;
        return recuperarFlotas({
            fechaIni: FechaFunction.format(start),
            fechaFin: FechaFunction.format(end),
        }).then((response)=>{
            setDefaultValues(response);
        });
    }

    const onClickGuardar = () =>{
        const forms = [];
        defaultValues.forEach((flota)=>{
            const existe = flotas.find(fj=>fj.vehiculoId === flota.vehiculoId);
            if(!existe && typeof flota.id === 'number'){
                forms.push(FlotaJugadoresService.destroy(flota.id));
            }
        })
        flotas.forEach((flota)=>{
            const { start } = rangeDate;
            const { id, jugadores:flotaJugadores, campo, vehiculoId } = flota;
            const form = {};
            form.campo = campo;
            form.vehiculoId = vehiculoId;
            form.fecha = FechaFunction.format(start);
            flotaJugadores.forEach((jg,index)=>{
                switch(index){
                    case 0:
                        form.colaboradorIdConductor = jg.dni;
                        break;
                    case 1:
                        form.colaboradorIdTecnico = jg.dni;
                        break;
                    case 2:
                        form.colaboradorIdTecnico2 = jg.dni;
                        break;
                    case 3:
                        form.colaboradorIdTecnico3 = jg.dni;
                        break;
                    case 4:
                        form.colaboradorIdTecnico4 = jg.dni;
                        break;
                    default:
                        break;
                }
            });
            if(typeof id === 'number'){
                form.id = id;
                forms.push(FlotaJugadoresService.update(form));
            } else {
                forms.push(FlotaJugadoresService.register(form));
            }
        })
        setIsLoading(true);
        Promise.all(forms).then(()=>{
            return actualizar(); 
        }).finally(()=>{
            setIsLoading(false);
        });
    }

    const onChangeDate = (newDates) => {
        setRangeDate(newDates);
        setIsLoading(true);
        actualizar(newDates).finally(()=>{
            setIsLoading(false);
        });
    }

    const onCopiarFlotas = (newFlotas)=>{
        const finalFlotas = [];

        newFlotas.forEach((fl)=>{
            const existeVehiculo = vehiculos.findIndex(ve=>ve.id === fl.vehiculoId);
            if(existeVehiculo>=0){
                const final = {...fl};
                const existeJugadores = fl.jugadores.filter((jg)=>jugadores.findIndex(jg2=>jg.dni === jg2.dni)>=0);
                final.id= uuidv4();
                final.jugadores = existeJugadores;
                finalFlotas.push(final);
            }
        });
        setIsLoading(true);
        setTimeout(()=>{
            iniciarFlotas(finalFlotas);
            setDefaultValues(finalFlotas);
            setIsLoading(false);
        },100)
    }

    const onOpenCopiar = () => {
        showModal(ModalFlotaJugadorCopiar,{onGuardar:onCopiarFlotas});
    }

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            recuperarVehiculos().then((response)=>{
                setJugadoresData(response);
            }),
            recuperarJugadores().then((response)=>{
                setVehiculosData(response);
            }),
            actualizar(),
        ]).then(()=>{
            onChangeVehiculoTable();
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if(isFiltrarSeleccionados){
            setJugadoresData(jugadores.filter(({dni})=>{
                return !flotas.find(({jugadores:internos})=>{
                    return internos.find(({dni:dniInterno})=>dniInterno === dni);
                });
            }))
            setVehiculosData(vehiculos.filter(({id})=>{
                return !flotas.find(({vehiculoId})=>vehiculoId === id);
            }))
        } else {
            setJugadoresData(jugadores);
            setVehiculosData(vehiculos);
        }
    }, [isFiltrarSeleccionados,flotas]);

    useEffect(() => {
        onChangeVehiculoTable();
    }, [vehiculosData,isLoading,flotas]);

    return (
    <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-3 d-flex justify-content-center align-content-center" data-aos="fade-up">
                    <Button
                        disabled={defaultValues.length > 0 || flotas.length > 0}
                        block
                        theme="info"
                        className="m-auto"
                        onClick={()=>onOpenCopiar()}
                    >
                        COPIAR ANTERIORES
                    </Button>
                </div>
                <div className="col-9 text-center" data-aos="fade-up">
                    <h2>
                        Flotas
                        {
                            isLoading && (
                                <Spinner className="spinner-border text-primary mb-1 ml-1" animation="border" />
                            )
                        }
                    </h2>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 shadow-sm text-left bg-white border rounded-lg p-3 bordered" data-aos="fade-up">
                    <div id="draggableZoneFlotaJugadores">
                        <div className="grid-panel">
                            {
                                permisos['api.jugadores.store'] && (
                                <div className="d-grid gap-2">
                                    <Button
                                        block
                                        disabled={defaultValues.length === 0 && flotas.length === 0}
                                        onClick={onClickGuardar}
                                        isLoading={isLoading}
                                    >
                                        GUARDAR
                                    </Button>
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="semana-input">Semana</label>
                                <DateWeek onChange={onChangeDate} isLoading={isLoading} />
                            </div>
                            <div className="form-check">
                                <InputCheck label="Filtrar seleccionados" onChange={onChangeFiltrarSeleccionados} checked={isFiltrarSeleccionados} />
                            </div>
                            <Button block theme='gray' className="my-1" onClick={onClickVehiculosTable}>Vehiculos</Button>
                            <Collapse in={isVehiculosTable}>
                                <div id="vehiculosTable">
                                    <TablePaginated
                                        customGlobalFilter={onFilterVehiculos}
                                        isShowHeader={false}
                                        columns={columnsVehiculoTable}
                                        data={vehiculosData}
                                        onPageChange={onChangeVehiculoTable}
                                        onFilteredChange={onChangeVehiculoTable}
                                    />
                                </div>
                            </Collapse>
                            <Button block theme='gray' onClick={onClickJugadoresTable}>Jugadores</Button>
                            <Collapse in={isJugadoresTable}>
                                <div>
                                    <TablePaginated
                                        customGlobalFilter={onFilterJugadores} 
                                        isShowHeader={false}
                                        columns={columnsJugadorTable}
                                        data={jugadoresData}
                                    />
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <div className="col-9 asignacion" id="draggableGrid" data-aos="fade-up">
                    <Grilla
                        isLoading={isLoading}
                        values={flotas}
                        defaultValues={defaultValues}
                        widget={WidgetFlota}
                        uniqueKeyLabel='vehiculoId'
                        getItem={onGetItem}
                        getHeight={onGetHeight}
                        onChange={onChangeGrilla}
                        onRemove={onRemoveFlota}
                    />
                </div>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = (state) => ({
    vehiculos: state.flotas.vehiculos,
    jugadores: state.flotas.jugadores,
    flotas: state.flotas.flotas,
});

const mapDispatchToProps = (dispatch) => ({
    recuperarVehiculos: (data) => dispatch(FlotasAction.recuperarVehiculos(data)),
    recuperarJugadores: (data) => dispatch(FlotasAction.recuperarJugadores(data)),
    recuperarFlotas: (data) => dispatch(FlotasAction.recuperarFlotas(data)),
    iniciarFlotas: (data) => dispatch(FlotasAction.iniciarFlotas(data)),
    agregarFlota: (data) => dispatch(FlotasAction.agregarFlota(data)),
    modificarFlota: (data) => dispatch(FlotasAction.modificarFlota(data)),
    eliminarFlota: (data) => dispatch(FlotasAction.eliminarFlota(data)),
    limpiarFlotas: () => dispatch(FlotasAction.limpiarFlotas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlotasAsignacionPage);