import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import AccionesService from '@app/services/acciones.service';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({
    nombre: Yup.string().max(200).required(),
});

function ModalAcciones({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id
        };
        setIsLoading(true);
        if (form.id > 0) {
            AccionesService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            AccionesService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
        if (item.id > 0) {
            setValue('nombre', item.nombre);
            setValue('metodo', item.metodo);
            setValue('descripcion', item.descripcion); 
        }
    }, []);
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar Accion' : 'Registrar Accion'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombreL">
                            Nombre
                        </label>
                        <input
                            className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                            id="nombre"
                            name="nombre"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.nombre && (
                            <div className="invalid-feedback">
                                {errors.nombre.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="metodo">
                            Metodo
                        </label>
                        <input
                            className={`form-control ${errors.metodo? 'is-invalid': ''}`}
                            id="metodo"
                            name="metodo"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.metodo && (
                            <div className="invalid-feedback">
                                {errors.metodo.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="descripcion">
                            Descripcion
                        </label>
                        <input
                            className={`form-control ${errors.descripcion? 'is-invalid': ''}`}
                            id="descripcion"
                            name="descripcion"
                            type="text" 
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.descripcion && (
                            <div className="invalid-feedback">
                                {errors.descripcion.message}
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalAcciones.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
        nombre: PropTypes.string
    })
};

ModalAcciones.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: ''
    }
};

export default ModalAcciones;