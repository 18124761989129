import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Modal from 'react-bootstrap/esm/Modal';
import {Button} from '@app/components/index';
import MotivoReprogramadoService from '@app/services/motivoReprogramado.service';
import * as Yup from 'yup';

const schema = Yup.object({
    nombre: Yup.string().max(200).required(),
    descripcion: Yup.string().max(255)
});

function ModalMotivoReprogramado({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);

    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(schema),
        defaultValues: item
    });

    const onSubmit = (values) => {
        const data = {
            ...values
        };
        setIsLoading(true);
        if (item.id > 0) {
            data.id = item.id;
            MotivoReprogramadoService.update(data)
                .then(() => {
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            MotivoReprogramadoService.register(data)
                .then(() => {
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
        if (onGuardar) {
            onGuardar();
        }
    }

    const handleClose = () => hideModal();
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0
                        ? 'Editar Motivo de Reprogramacion'
                        : 'Registrar Motivo de Reprogramacion'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre">
                            Nombre
                        </label>
                        <input
                            className={`form-control ${errors.nombre ? 'is-invalid': ''}`}
                            id="nombre"
                            name="nombre"
                            type="text"
                            autoComplete="off"
                            ref={register}
                        />
                        {errors.nombre ? (
                            <div className="invalid-feedback">
                                {errors.nombre?.message}
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label htmlFor='descripcion' className="label-control">Descripcion</label>
                        <textarea
                            className={`form-control ${errors.descripcion ? 'is-invalid': ''}`}
                            id="descripcion"
                            name="descripcion"
                            type="text"
                            autoComplete="off"
                            ref={register}
                        >
                        </textarea>
                        {errors.descripcion ? (
                            <div className="invalid-feedback">
                                {errors.descripcion?.message}
                            </div>
                        ) : null}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalMotivoReprogramado.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
        descripcion: ''
    }
};

export default ModalMotivoReprogramado;
