import {toast} from 'react-toastify';
import {
    LOGIN_USER,
    LOGOUT_USER,
    LOAD_USER,
    LOAD_MENSAJES,
} from "../actions/auth";

const initialState = {
    isLoggedIn: !!localStorage.getItem('token'),
    token: localStorage.getItem('token'),
    tokenNubi: localStorage.getItem('tokenNubi'),
    currentUser: {
        email: 'mail@example.com',
        picture: null
    },
    mensajes_last:[],
    mensajes_total:0,
    mensajes_noVistos:0,
};

const reducer = (state = initialState, {type,payload}) => {
    switch (type) {
        case LOGIN_USER:
            localStorage.setItem('token', payload.token);
            localStorage.setItem('tokenNubi', payload.tokenNubi);
            return {
                ...state,
                isLoggedIn: true,
                token: payload.token,
                tokenNubi: payload.tokenNubi
            };
        case LOGOUT_USER:
            localStorage.removeItem('token');
            localStorage.removeItem('tokenNubi');
            if(state.isLoggedIn){
                toast.dismiss();
                toast.warn('Saliendo del sistema...', {

                }); 
            }
            return {
                ...state,
                isLoggedIn: false,
                token: null,
                currentUser: {
                    email: 'mail@example.com',
                    picture: null
                }
            };
        case LOAD_USER:
            return {
                ...state,
                currentUser: payload.currentUser,
            };
        case LOAD_MENSAJES:
            return {
                ...state,
                mensajes_last: payload.items,
                mensajes_total: payload.total,
                mensajes_noVistos: payload.noVistos,
            };
        default:
            return {...state};
    }
};

export default reducer;
