import axios from 'axios';

function login(email,password){
    return axios.post('login',{
        email:email,
        password:password,
    });
}

function me(){
    return axios.get('check');
}

function logout(){
    return axios.post('logout');
}

function mensajes(query = null){
    return axios.get('auth/mensajes',{
        params:query,
    });
}

function mensajeById(id){
    return axios.get(`auth/mensajes/${id}`);
}

const AuthService = {
    login,
    me,
    logout,
    mensajes,
    mensajeById,
};
export default AuthService;