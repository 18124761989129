import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import TareasService from '@services/tareas.service';

const schema = Yup.object({
});

function ModalTareaCancelar({show, hideModal, itemModal, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const {register, handleSubmit, errors,} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: itemModal.id
        };
        TareasService.destroy(form)
            .then(() => {
                hideModal();
                onGuardar();
            }) 
    };    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Esta por cancelar la tarea. ¿Desea continuar?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="observacionesL">
                            Por favor, deje sus observaciones:
                        </label>
                        <input
                            className={`form-control ${errors.observaciones? 'is-invalid': ''}`}
                            id="observaciones"
                            name="observaciones"
                            type="text"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.observaciones && (
                            <div className="invalid-feedback">
                                {errors.observaciones.message}
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalTareaCancelar.propTypes = {
    show: PropTypes.bool,
    itemModal: PropTypes.shape({
        id: PropTypes.number,
        observaciones: PropTypes.string
    })
};

ModalTareaCancelar.defaultProps = {
    show: false,
    itemModal: {
        id: 0,
        observaciones: ''
    }
};

export default ModalTareaCancelar;