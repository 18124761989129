import React from 'react';
import 'aos/dist/aos.css';
import FechaFunction from '@app/functions/FechaFunction';

function LogCard({
    item
}) {
    let nombre = "";
    switch(item.tabla){
        case 'flotaJugadores':
        nombre = (`Aisgnacion de Flota al vehiculo ${item.raw.vehiculoPatente} con fecha  ${FechaFunction.format(item.raw.fecha,'dd/MM/yy')}`);
     break;
     case 'lugares':
        nombre = `Lugares a ${item.raw.nombre}.`;
     break;
     case 'cuadrillaDisponibleLugar':
        nombre = `Destinos de Cuadrillas del día  ${FechaFunction.format(item.raw.fecha,'dd/MM/yy')} con destino en ${ item.raw.descripcion}.`;
     break;
     case 'cuadrilla_disponibles_lugar':
        nombre = `Destinos de Cuadrillas del día  ${FechaFunction.format(item.raw.fecha,'dd/MM/yy')} con destino en ${ item.raw.descripcion}.`;
     break;
     case 'flotaLugares':
        nombre = `Asignacion de Lugares a las Cuadrillas`;
     break;
     case 'cuadrillasDisponibles':
        nombre = `Asignacion de Cantidad de Cuadrillas con fecha de inicio ${FechaFunction.format(item.raw.fechaIni,'dd/MM/yy')}  y fecha de finalizacion${FechaFunction.format(item.raw.fechaFin,'dd/MM/yy')} con una cantidad de 
        ${item.new.cantidad} cuadrillas diarias.`;
     break;
     case 'vehiculos':
        nombre = `la tabla Vehiculos al vehiculo con dominio ${item.raw.patente}.`;
     break;
     case 'contactos':
        nombre = `la tabla contactos`;
     break;
     case 'poligonos':
        nombre = `el Nodo fue .`;
     break;
     case 'contratos':
        nombre = `la tabla contratos / DNI ${item.new.contactoDocumento_c}.`;
     break;
     case 'plataformas_corporativos':
        nombre = `la Sección Plataforma del cliente ${item.raw?.contactoCorporativo.clienteNombre} con ID de Servicio ${item.raw?.contactoCorporativo.servicioId}`;
     break;
     case 'tecnica_corporativos':
        nombre = `la Sección Técnica del cliente ${item.raw?.contactoCorporativo.clienteNombre} con ID de Servicio ${item.raw?.contactoCorporativo.servicioId}`;
     break;
     case 'contactos_corporativos':
        nombre = `la Sección Datos Clientes del cliente ${item.raw?.clienteNombre} con ID de Servicio ${item.raw?.servicioId}`;
     break;
     case 'administracion_corporativos':
        nombre = `la Sección Administración del cliente ${item.raw?.contactoCorporativo.clienteNombre} con ID de Servicio ${item.raw?.contactoCorporativo.servicioId}`;
     break;
     default:
     break;
    }



    const descripcion = [item.persona.nombre,item.accion,nombre];

    const mods = Object.keys(item.old);

    return (
        <div className="card border border p-2 mb-2 mx-1 overflow-hidden">
            <div className="row">
                <div className="col-12 card-header">
                    <div className='row'>
                        <div className='col-8'>
                            {`${FechaFunction.format(item.fecha,'dd/MM/yy HH:mm:ss')}`}
                        </div>

                        <div className='col-4 text-right'>
                            {`ID: ${item.id}`}
                        </div>
                    </div>
                </div>
                <div className="col-12 card-body">
                    <span className="font-weight-bold">
                        {` ${descripcion[0]} `}
                    </span>
                    <span>
                        Realizo la accion
                    </span>
                    <span className="font-weight-bold">
                        {` ${descripcion[1]} `}
                    </span>
                        en
                    <span className="font-weight-bold">
                        {` ${descripcion[2]} `}
                    </span>
                </div>

                <div className="col-12">
                    {
                        mods.length > 0 && (
                            <div className='container text-center'>
                                <table className="table table-responsive-sm table-bordered table-responsive table-sm">
                                    <thead className="bg-primary t-white">
                                        <tr>
                                            <th>CAMPO</th>
                                            <th>ANTES</th>
                                            <th>LUEGO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mods.map((key,index)=>{
                                            const old = (item.old[key] === null || item.old[key] === "null") ? ('-'):(item.old[key]);
                                            const nuevo = (item.new[key] === null || item.new[key] === "null") ? ('-'):(item.new[key]);
                                            return (
                                                <tr key={index}>
                                                    <td>{key}</td>
                                                    <td>{old}</td>
                                                    <td>{nuevo}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default LogCard
