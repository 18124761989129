import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link , Prompt} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';
import CorporativoGeneralService from '@app/services/coorporativo/general.service';
import ModalDatosClientesEditar from '@app/modals/coorporativos/ModalDatosClientesEditar';
import ModalPermisosEditar from '@app/modals/coorporativos/ModalPermisosEditar';
import CamposVisiblesService from '@app/services/coorporativo/camposVisibles.service';

const AdministrarUsuariosPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);
    const [estadoTabla, setEstadoTabla] = useState('d.cliente');
    const [tituloTabla, setTituloTabla] = useState()
    const [tabla, setTabla] = useState([])

    const actualizar = async () => {
        const response = await CamposVisiblesService.getAll();
        setItems(response) 
        // console.log(response) 
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Datos',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                CorporativoGeneralService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(CamposVisiblesService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenEditar = (item) => {
            showModal(ModalPermisosEditar, {item: item, onGuardar: onGuardar});
    };

    const usuarios = React.useMemo(
        () => [
            {
                Header: 'Roles',
                accessor: 'rol.nombre_r',
                Width:1500
            },
            {
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
      actualizar()
    }, [])
    

    return (
        <div className='container'>
                <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                            <div className="offset-1 text-center col-lg-9 my-lg-3">
                                <h3 className="ml-1" data-aos="fade-right">Usuarios</h3>
                            </div>

                            <div className="col-12 overflow-hidden" data-aos="fade-right">
                                    <TablePaginated columns={usuarios} data={items} />
                            </div>
                    </div>
                </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrarUsuariosPage);
