import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import AOS from 'aos';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import ContactosService from '@services/contactos.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import {Dropdown, Popover, OverlayTrigger} from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm, SelectEstadoEmail, SelectEstadoVenta, SelectLugar, SelectPersonal} from '@app/components/index';
import ModalContactoEditar from '@app/modals/contacto/ModalContactoEditar';
import FechaFunction from '@app/functions/FechaFunction';
import useGetContactos from '@app/hooks/useGetContactos';
import ModalContactoContratoSeleccionar from '@app/modals/contacto/ModalContactoContratoSeleccionar';
import EchoWS from '@app/utils/ws';
import ContactoEmailService from '@app/services/envioEmail.service';
import ModalContactoEditarEstado from '@app/modals/contacto/ModalContactoEditarEstado';
import usePermisos from '@app/hooks/usePermisos';

const ContactosPage = ({
    iniciarContacto,
    user
}) => {
    const {isLoading, response:{items},setQuery} = useGetContactos({})
    AOS.init();
    const [t] = useTranslation();
    const permisos = usePermisos();
    const history = useHistory();
    const [channel, setChannel] = useState();
    const {puesto} = user;
    const [agenteId, setAgenteId] = useState(null);

    const actualizar = () => {
        setQuery({})
    };


    const onGuardar = () => {
        actualizar();
    };


    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Asesor',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                ContactosService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onConfirmarEmail = (id) => {
        const data = {
            id:id,
            envioEmail:true
        }

        showModal(ModalConfirm, {
            title: 'Confirmar Email',
            message: '¿Esta seguro de que se envio el correo al contacto?',
            onConfirm: () => {
                ContactoEmailService.update(data).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickNuevo = () => {
        history.push('/contactos/nuevo');
    };
    const onClickExistente = () => {
        history.push({
            pathname:'/ContactoContactCenter',
            state:{
                action: 'noencontrado'
            }
        });
    };

    const onClickVender = (item) => {
        history.push({
            pathname: '/contactos/nuevo',
            state:{
                item:item,
            }
        });
    }
    const onClickAsistencia = (item) => {
        if(item.contratos.length>1){
            const onSeleccionarContrato = (contrato) => {
                history.push({
                    pathname: '/ContactoContactCenter',
                    state:{
                        item:{
                            ...item,
                            latitud: contrato.latitud,
                            longitud: contrato.longitud,
                            coordenadas: contrato.coordenadas,
                            contratoId: contrato.id,
                            contrato: contrato,
                        },
                        action: 'tablacontacto'
                    }
                });
            }
            showModal(ModalContactoContratoSeleccionar, {item: item, onGuardar: onSeleccionarContrato});
        } else if(item.contratos.length === 1){
            history.push({
                pathname: '/ContactoContactCenter',
                state:{
                    item:{
                        ...item,
                        latitud: item.contratos[0].latitud,
                        longitud: item.contratos[0].longitud,
                        coordenadas: item.contratos[0].coordenadas,
                        contratoId: item.contratos[0].id,
                        contrato: item.contratos[0],
                    },
                    action: 'tablacontacto'
                }
            });
        } else {
            history.push({
                pathname: '/ContactoContactCenter',
                state:{
                    item,
                    action: 'tablacontacto'
                }
            });
        }
    }

    const onOpenVer = (item) => {
        history.push({
            pathname: `/contactos/${item.id}/ver`,
            state:{
                item:item,
            }
        });
    }

    const onOpenEditar = (item) => {
        showModal(ModalContactoEditar, {item: item, onGuardar: onGuardar});
    };


    const onOpenEditarEstado = (item) => {
        showModal(ModalContactoEditarEstado, {item: item, onGuardar: onGuardar});
    };

    const tablaAsesor = React.useMemo(
            () => [
                {
                    Header: 'Registrado',
                    Filter: false,
                    accessor: 'createdAt',
                    disableGlobalFilter: true,
                    Width: 80,
                    Cell: ({row}) => {
                        return FechaFunction.format(row.original.createdAt,'dd/MM/yy');
                    },
                },
                {
                    Header: 'Apellido y Nombre',
                    accessor: 'nombreCompleto',
                },
                {
                    Header: 'Documento',
                    accessor: 'documento',
                },
                {
                    Header: 'CLIENTE ID',
                    accessor: 'clienteId'
                },
                {
                    Width: 50,
                    Header: 'Contratos',
                    accessor: 'isTieneContratos',
                    Cell: ({row}) =>{
                        if(row.original.isTieneContratos !== null){
                            return row.original.isTieneContratos?row.original.contratos.length:'NO';
                        }
                        return '-';
                    },
                },
                {
                    Header: 'Estado',
                    accessor: 'estadoCRM',
                    Filter: ({
                        column: {filterValue, setFilter, preFilteredRows, id}
                    }) => {
                        return (
                            <SelectEstadoVenta
                                onChange={(opt) => {
                                    if (opt) {
                                        setFilter(opt.value);
                                    } else {
                                        setFilter('');
                                    }
                                }}
                                isClearable
                            />
                        );
                    }
                },
                {
                    Header: 'Email',
                    accessor: 'envioEmail',
                    Filter: ({
                        column: {filterValue, setFilter, preFilteredRows, id}
                    }) => {
                        return (
                            <SelectEstadoEmail
                                onChange={(opt) => {
                                    if (opt) {
                                        setFilter(opt.value);
                                    } else {
                                        setFilter('');
                                    }
                                }}
                                isClearable
                            />
                        );
                    },
                    Cell: ({row}) => {
                        return (
                                <>
                                    {row.original.contratos.length > 0 && (
                                    <button className={row.original.envioEmail?("btn btn-sm btn-success"):("btn btn-sm btn-warning")} type="button" onClick={puesto !=="Asesor" && row.original.envioEmail===false ? ()=>onConfirmarEmail(row.original.id) : ({})}>
                                        <i className={row.original.envioEmail?("fas fa-check"):("fas fa-file-import")}></i>
                                    </button>
                                    )}
                                </>
                            )
                    }
                },{
                    Width: 10,
                    Header: 'Acc',
                    accessor: 'id',
                    disableSortBy: false,
                    Filter: false,
                    Cell: ({row}) => {
                        return (
                            <Dropdown size="sm">
                                <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                >
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => onOpenVer(row.original)}
                                    >
                                        Ver
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => onOpenEditar(row.original)}
                                    >
                                        Editar
                                    </Dropdown.Item>
                                    {
                                        row.original.contratos.length === 0 && (
                                        <Dropdown.Item
                                            onClick={() => onClickVender(row.original)}
                                        >
                                            Vender
                                        </Dropdown.Item>
                                    )}
                                    {
                                        row.original.estadoCRM !== "VENTA FINALIZADA" && (
                                            <Dropdown.Item
                                                onClick={() => onOpenEditarEstado(row.original)}
                                            >
                                                Estado
                                            </Dropdown.Item>
                                        )
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    }
                }
            ],
            []
    );
    const tablaAdmin = React.useMemo(
            () => [
                {
                    Header: 'Registrado',
                    Filter: false,
                    accessor: 'createdAt',
                    disableGlobalFilter: true,
                    Width: 80,
                    Cell: ({row}) => {
                        return FechaFunction.format(row.original.createdAt,'dd/MM/yy');
                    },
                },
                {
                    Header: 'Apellido y Nombre',
                    accessor: 'nombreCompleto',
                    Width:250
                },
                {
                    Header: 'Documento',
                    accessor: 'documento',
                    Width:150
                },
                {
                    Header: 'Cliente ID',
                    accessor: 'clienteId',
                    Width:150
                },
                {
                    Width: 50,
                    Header: 'Contratos',
                    accessor: 'isTieneContratos',
                    Cell: ({row}) =>{
                        if(row.original.isTieneContratos !== null){
                            return row.original.isTieneContratos?row.original.contratos.length:'NO';
                        }
                        return '-';
                    },
                },
                {
                    Header: 'Localidad',
                    ClassName: 'm-0',
                    accessor: 'lugar.nombre',
                    Width:200,
                    Cell: ({row}) =>{
                            return (
                                row.original.lugar ? row.original.lugar.nombre : "" 
                            )
                    },
                    Filter: ({
                        column: {filterValue, setFilter, preFilteredRows, id}
                    }) => {
                        return (
                            <SelectLugar
                                onChange={(opt) => {
                                    if (opt) {
                                        setFilter(String(opt.label) || undefined);
                                    } else {
                                        setFilter('');
                                    }
                                }}
                                isClearable
                            />
                        );
                    },
                },
                {
                    Header: 'Estado',
                    accessor: 'estadoCRM',
                    Width:150,
                    Filter: ({
                        column: {filterValue, setFilter, preFilteredRows, id}
                    }) => {
                        return (
                            <SelectEstadoVenta
                                onChange={(opt) => {
                                    if (opt) {
                                        setFilter(opt.value);
                                    } else {
                                        setFilter('');
                                    }
                                }}
                                isClearable
                            />
                        );
                    }
                },
                {
                    Header: 'Ult. Tarea Pro.',
                    accessor: 'fechatarea',
                    Filter: false,
                    Cell: ({row}) => {
                        if(row.original.tareaContacto){
                            const content = `La ultima tarea programada fue para el día
                            ${FechaFunction.format(row.original.tareaContacto.fechatarea,'dd-MM-yyyy')} /
                            Motivo ${row.original.tareaContacto.tipotarea} /
                            Hora de inicio ${row.original.tareaContacto.horaini} /
                            Estado ${row.original.tareaContacto.estado}`;
                            const popover = (
                                <Popover
                                    id="popover-basic"
                                >
                                    <Popover.Title as="h3">Ultima tarea</Popover.Title>
                                    <Popover.Content>{content}</Popover.Content>
                                </Popover>
                            );
                            return (
                                <OverlayTrigger trigger="click" placement="left" overlay={popover}>
                                    <Button variant="success">
                                        {FechaFunction.format(row.original.tareaContacto.fechatarea,'dd/MM/yyyy')}
                                    </Button>
                                </OverlayTrigger>
                            )
                        }
                        return " - ";
                    }
                },
                {
                    Header: 'CRM',
                    accessor: 'zohoCRMContactUrl',
                    Filter:false,
                    Cell: ({row}) => {
                        if(row.original.zohoCRMContactUrl){
                            return (
                                <a href={row.original.zohoCRMContactUrl} target="_blank" rel="noopener noreferrer">
                                    <button className="btn btn-sm btn-gray" type="button">
                                        <i className="fas fa-share-square"></i>
                                    </button>
                                </a>
                            )
                        }
                        return "";
                    }
                },
                {
                    Header: 'Email',
                    accessor: 'envioEmail',
                    Filter: ({
                        column: {filterValue, setFilter, preFilteredRows, id}
                    }) => {
                        return (
                            <SelectEstadoEmail
                                onChange={(opt) => {
                                    if (opt) {
                                        setFilter(opt.value);
                                    } else {
                                        setFilter('');
                                    }
                                }}
                                isClearable
                            />
                        );
                    },
                    Cell: ({row}) => {
                        return (
                                <>
                                    {row.original.contratos.length > 0 && (
                                    <button className={row.original.envioEmail?("btn btn-sm btn-success"):("btn btn-sm btn-warning")} type="button" onClick={puesto !=="Asesor" && row.original.envioEmail===false? ()=>onConfirmarEmail(row.original.id) : ({})}>
                                        <i className={row.original.envioEmail?("fas fa-check"):("fas fa-file-import")}></i>
                                    </button>
                                    )}
                                </>
                            )
                    }
                },{
                    Width: 10,
                    Header: 'Acc',
                    accessor: 'id',
                    disableSortBy: false,
                    Filter: false,
                    Cell: ({row}) => {
                        return (
                            <Dropdown size="sm">
                                <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                >
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => onOpenVer(row.original)}
                                    >
                                        Ver
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => onOpenEditar(row.original)}
                                    >
                                        Editar
                                    </Dropdown.Item>
                                    {
                                        row.original.contratos.length === 0 && (
                                        <Dropdown.Item
                                            onClick={() => onClickVender(row.original)}
                                        >
                                            Vender
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                        onClick={() => onClickAsistencia(row.original)}
                                    >
                                        Asistencia/Retiro
                                    </Dropdown.Item>
                                    {
                                        row.original.estadoCRM !== "VENTA FINALIZADA" && (
                                            <Dropdown.Item
                                                onClick={() => onOpenEditarEstado(row.original)}
                                            >
                                                Estado
                                            </Dropdown.Item>
                                        )
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    }
                }
            ],
            []
    );


    useEffect(() => {
        actualizar()
        if(channel){
            channel
            .listen('.contactos.created',actualizar)
            .listen('.contactos.updated',actualizar);
        }

        return () => {
        }
    }, [channel])

    useEffect(()=>{
        actualizar();
         setChannel(EchoWS.private('contactos'))
        return () => {
            if(channel){
                channel.unsubscribe();
             }
        }
    },[]);

    
    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col my-1 col-md-3 col-lg-3 mt-lg-3" data-aos="fade-right">
                        <h2>Contactos</h2>
                    </div>
                    {/* {user.dni === 37729875 && ( */}
                            <div className="col my-1 col-md-3 col-lg-3 mt-lg-3" data-aos="fade-right">
                                Asesor
                                <SelectPersonal
                                filtroAsesor
                                id="personaId"
                                onChange={(option)=>(option ? setAgenteId(option.value) : setAgenteId(null))}
                                isClearable
                                defaultValue={null}
                                >
                                </SelectPersonal>
                            </div>
                    {/* )} */}

                    {
                        permisos['api.zoho.searchContacts.searchDesk'] && (
                        <div className="col my-1 col-md-3 col-lg-2 offset-lg-2 mt-lg-3" data-aos="fade-up">
                            <Button className="btn-block btn-secondary" onClick={onClickExistente}>
                                Cliente no encontrado
                            </Button>
                        </div>
                    )}

                    {
                        permisos['api.contactos.store'] && (
                        <div className="col my-1 col-md-3 col-lg-2 mt-lg-3" data-aos="fade-up">
                            <Button className="btn-block" onClick={onClickNuevo}>
                                NUEVO
                            </Button>
                        </div>
                    )}
                    <div className="col-12 overflow-hidden" data-aos="fade-right">
                        {
                            agenteId === null? (
                                <TablePaginated columns={puesto === 'Asesor' ? tablaAsesor : tablaAdmin} data={puesto === 'Asesor' || user.dni === 37729875 ? items : items.filter( item => item.createdPersona.puesto !== "Asesor" || item.createdPersona.departamento !== "Preventista" )} />
                                ) : (
                                <TablePaginated columns={tablaAdmin} data={items.filter( item => item.createdPersona.dni === agenteId)} />
                            )
                        }
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ContactosPage);
