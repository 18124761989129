import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Modal from 'react-bootstrap/esm/Modal';
import {Container,Spinner} from 'react-bootstrap';
import TareasService from '@services/tareas.service';
import * as Yup from 'yup';
import {useHistory} from 'react-router-dom';
import usePermisos from '@app/hooks/usePermisos';
import {
    Button,
    DateSingle,
    TimeSlider,
} from '@app/components/index';
import * as CalendarAction from '@app/store/actions/calendar';
import useCurrentLocation from '@app/hooks/useCurrentLocation';
import FechaFunction from '@app/functions/FechaFunction';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {toast} from 'react-toastify';

const schema = Yup.object({
    id:Yup.mixed(),
    lugarId: Yup.mixed().required(),
    tipoTareaId: Yup.mixed().required(),
    motivoTareaId: Yup.mixed().when('tipoTareaId', {
        is: (id) => id > 0,
        then: Yup.mixed().required()
    }),
    clienteDireccion: Yup.string().max(200),
    clienteTelefono: Yup.string()
        .max(200),
    clienteCelular: Yup.string()
        .max(200),
    clienteId: Yup.string()
        .matches(/^(\s*|\d+)$/, 'Debe ser solo numeros')
        .max(200),
    clienteCategoria: Yup.string().max(200),
    categoria: Yup.string().when('id',{
        is: (value) => value > 0,
        then: Yup.string(),
        otherwise: Yup.string().required(),
    }),
    tipoCanalId: Yup.string().required()
});


function ModalMoverTarea({show, hideModal, onGuardar,cuadrillas,eliminarTarea, item, fechaCalendar}) {
    const handleClose = () => hideModal();
    const { location:currentLocation } = useCurrentLocation();
    const history = useHistory();
    const permisos = usePermisos();
    const {calendar}=useSelector(state=>state)
    const [isLoading, setIsLoading] = useState(false);
    const [cuadrillaDisponible, setcuadrillaDisponible] = useState(0)
    const [zonaId, setZonaId] = useState(0);
    const [time, setTime] = useState();
    const [validacionHora, setValidacionHora] = useState((FechaFunction.format(new Date(fechaCalendar), 'H')));
    const [hora, setHora] = useState((FechaFunction.format(new Date(fechaCalendar), 'HH:mm:ss')));
    const [fecha, setFecha] = useState(FechaFunction.format(calendar.estado.initialDate));
    const [turno, setTurno] = useState('TM')
    const [btnGuardar, setBtnGuardar] = useState(false);
    const [cuadrillaLugarId, setCuadrillaLugarId] = useState(0)
    const [enviarEmail, setEnviarEmail] = useState(false)
    const [tarea, setTarea] = useState(null);

    const noMover = () =>{
        eliminarTarea()
        hideModal()
    }

    const onSubmit = () => {
        setIsLoading(true);
        const form = {
            ...tarea,
            horaIni: time.start,
            horaFin: time.end,
            fecha:fechaCalendar,
            cuadrillaLugarId:Number(cuadrillaLugarId),
            mover:true

        };
     
        TareasService.update(form)
        .then(() => {
            onGuardar();
            eliminarTarea()
            hideModal();
            setBtnGuardar(false)
            toast.success('Tarea movida correctamente');
        })
        .finally(() => {
            setIsLoading(false);
        });
        
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'white',
          color: 'black',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          padding:'0',
          border: '1px solid #dee2e6',
          boxShadow:'0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
          borderRadius:'0.25rem !important'
        },
    }));

    
    useEffect(() => {
        const filtrarZona = (cuadrillas.filter(cuadrilla => cuadrilla.id === Number(cuadrillaDisponible)))
        if(filtrarZona.length !== 0){
         if(filtrarZona[0].zonaIdTM !== null && filtrarZona[0].zonaIdTT === null){
                setZonaId(filtrarZona[0].zonaIdTM)
            }else if(filtrarZona[0].zonaIdTT  !== null && filtrarZona[0].zonaIdTM === null ){
                    setZonaId(filtrarZona[0].zonaIdTT)
            }else if(filtrarZona[0].zonaIdTT === filtrarZona[0].zonaIdTM){
                setZonaId(filtrarZona[0].zonaIdTT)
            }else if(filtrarZona[0].zonaIdTT !== null && filtrarZona[0].zonaIdTM !==null && filtrarZona[0].zonaIdTM !== filtrarZona[0].zonaIdTT && validacionHora >=8 && validacionHora < 13){
                setZonaId(filtrarZona[0].zonaIdTM)
            }else if(filtrarZona[0].zonaIdTT !== null && filtrarZona[0].zonaIdTM !==null && filtrarZona[0].zonaIdTM !== filtrarZona[0].zonaIdTT && validacionHora >= 13){
                setZonaId(filtrarZona[0].zonaIdTT)
            }else{
                setZonaId(undefined)
            }
        }
    }, [cuadrillaDisponible, zonaId])


    useEffect(() => {
        setIsLoading(true)
        TareasService.getById(item.id).then((response)=>{
            setTarea(response)
            setIsLoading(false)
        })
        if(Number(validacionHora) === 12){
            setTime({
                start: '11:00:00',
                end: '12:00:00',
            });
        }else if(Number(validacionHora) === 17){
            setTime({
                start: '16:00:00',
                end: '17:00:00',
            });
            setTurno('TT');
        }else{
            setTime({
                start: hora,
                end: `${String((Number(validacionHora)+1))}:00:00`,
            });
        }

        if(validacionHora >= 8 && validacionHora < 13 ){
            setTurno('TM');
        }else{
            setTurno('TT');
        }
    }, [])

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="md">
            <Modal.Header closeButton>
                <div className="container">
                    <div className="row mt-2">
                        <div className="col-8">
                            <h3> 
                                Mover Tarea
                                {
                                    isLoading && <Spinner className="ml-2 mb-2 spinner-border text-primary" animation="border" />
                                }
                            </h3>
                        </div>
                        <div className='offset-2 col-1'>
                            <HtmlTooltip
                                className="m-0 p-0"
                                title={(
                                        <>
                                            <div className='col-12'>
                                                <span>
                                                    {item.clienteNombre}
                                                </span>
                                            </div>
                                            <div className='col-12'>
                                                <span>
                                                    {item.lugarNombre}
                                                </span>
                                            </div>
                                            <div className='col-12'>
                                                <span>
                                                    {item.tipoTarea}
                                                </span>
                                            </div>
                                        </>
                                    
                                )}
                            >
                                <button className='btn btn-sm btn-primary float-rig' type='button'>
                                    <i className="fas fa-info" />
                                </button>
                            </HtmlTooltip>
                        </div>

                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form>
                        <div className="row">
                            <div className="col-5">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="fecha"
                                    >
                                        Fecha Actual
                                    </label>
                                    <DateSingle
                                        id="fecha"       
                                        value={item.fecha}
                                        name="fecha"
                                        disabled
                                      
                                    />
                                </div>
                            </div>
                            <div className="col-2 text-center">
                                <h2 className='mt-4'>
                                    <i className="fas fa-sync text-primary" />
                                </h2>
                            </div>
                            <div className="col-5">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="fecha"
                                    >
                                        Fecha a cambiar
                                    </label>
                                    <DateSingle
                                        id="fecha"       
                                        value={fechaCalendar}     
                                        name="fecha"
                                        disabled
                                      
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="cuadrillasDisponibles"
                                    >
                                        Cuadrilla Disponible
                                    </label>
                                    <select
                                        id="cuadrillasDisponibles"
                                        onChange={
                                            (e)=>setCuadrillaLugarId(e.target.value)}
                                        name="cuadrillasDisponibles"
                                        className="form-control"
                                    >
                                        <option value={null}>Seleccione una cuadrilla</option>
                                        {
                                            validacionHora >= 8 && validacionHora < 13 ? (
                                            cuadrillas.map((cuadrilla)=>
                                                (
                                                    <option
                                                        value={cuadrilla.id}
                                                        // disabled={cuadrilla.periodoNombre === "Turno tarde"}
                                        
                                                    >
                                                        {cuadrilla.descripcion.split(" - T",1)} 
                                                        - TAREAS DISPONIBLES: 
                                                        {cuadrilla.tareasDisponiblesCantidadTM + cuadrilla.tareasDisponiblesCantidadTT}
                                                        
                                                    </option>
                                                )
                                            )
                                            ):(
                                                cuadrillas.map((cuadrilla)=>
                                                (
                                                    <option
                                                        value={cuadrilla.id}
                                                        // disabled={cuadrilla.periodoNombre === "Turno mañana"}
                    
                                                    >
                                                        {cuadrilla.descripcion.split(" - T",1)} 
                                                        - TAREAS DISPONIBLES: 
                                                        {cuadrilla.tareasDisponiblesCantidadTM + cuadrilla.tareasDisponiblesCantidadTT}
                                                    </option>
                                                )
                                            )
                                            )
                                        }
                                      
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-12 mt-1 mb-2">
                                <TimeSlider
                                    defaultPeriodo={time}
                                    value={time}
                                    onChange={setTime}
                                    id="timerInput"
                                    turno={turno}
                                />
                            </div>
                            
                        </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12">
                    <div className="row">
                        <div className='col-4'>
                            <button className='btn btn-sm btn-blue' type='button' title='Cancelar el movimiento de tarea' onClick={()=>noMover()}>
                                 NO MOVER
                                 <i className="fas fa-ban ml-2" />
                            </button>
                        </div>
                        <div className="offset-4 col-2 text-right">
                                <Button theme="default" onClick={handleClose}>
                                    CANCELAR
                                </Button>
                        </div>
                        <div className="col-2 text-right">
                            {
                                (permisos['api.tareas.store'] ||
                                permisos['api.tareas.update']) && (
                                <Button
                                    theme="primary"
                                    onClick={()=>onSubmit()}
                                    isLoading={isLoading}
                                    disabled={cuadrillaLugarId === 0 || cuadrillaLugarId === "Seleccione una cuadrilla"}

                                >
                                    GUARDAR
                                </Button>
                            )}
                        </div>
                    </div>
                </div>   
            </Modal.Footer>
        </Modal>
    );
}

ModalMoverTarea.defaultProps = {
    show: false,
    item: {
        id: 0,
        latitud: -24.7821,
        longitud: -65.4232,
        motivoTareaObservaciones: '',
        lugarId: 0,
        tipoTareaId: 0,
        tipoCanalId: 0,
        motivoTarea: {
            id: 0,
        }
    },
    cuadrillas: []
};

const mapStateToProps = (state) => ({
    itemReprogramado: state.calendar.itemReprogramado,
});

const mapDispatchToProps = (dispatch) => ({
    agregarReprogramacion: (data) =>  dispatch(CalendarAction.agregarReprogramacion(data)),
    recuperar: (data) => dispatch(CalendarAction.recuperarEventos(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalMoverTarea);