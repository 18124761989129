import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Link, useHistory} from 'react-router-dom';
import {
    Button,
    SelectTipoCanal,
    SelectLugar,
    InputZohoSearch,
    MapBase,
    MapClick,
} from '@app/components/index';

import AuxiliarFunction from '@app/functions/AuxiliarFunction';

import * as CalendarAction from '@app/store/actions/calendar';
import ContactosService from '@app/services/contactos.service';
import ContactosAction from '@app/store/actions/contactos';
import useCurrentLocation from '@app/hooks/useCurrentLocation';


const schema = Yup.object({
    nombre: Yup.string().required(),
    apellido: Yup.string().required(),
    direccion: Yup.string().required(),
});


function TareaForm1({
    item: itemDefault,
    iniciarContacto,
    contacto,
    nextStep,
}) {
    const { location } = useCurrentLocation();
    const history = useHistory();
    const {
        item,
    } =  { item:itemDefault};
    const [isLoading, setIsLoading] = useState(false);
    const [coordenadas, setCoordenadas] = useState(location);
    const [lugarOption, setLugarOption] = useState(item?.lugarId);
    const [tipoCanalId, setTipoCanalId] = useState(item?.tipoCanalId);
    const [datosZoho, setDatosZoho]=useState({});
    const {register, handleSubmit, errors, setValue, control, setError} = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        register('barrio');
        register('contactoId');
        register('clienteNombre');
        register('lugarId');
        register('tipoCanalId');
        register('zohoCRMContactId');
        register('zohoDESKContactId');
        register('zohoDESKContactUrl');
    }, [register]);

    const onSubmit = (data) => {
        const form = {
            ...data,
            latitud: coordenadas.lat,
            longitud: coordenadas.lng,
        };
        if(contacto){
            ContactosService.update({
                ...form,
                ...contacto,
            }).then((response)=>{
                const datosContacto= {
                    ...response,
                    ...form,
                    contactoId: response.id,
                }
                iniciarContacto(datosContacto);
                nextStep();
            })
            .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
        } else {
            ContactosService.register(form)
            .then((response) => {
                const datosContacto= {
                    ...response,
                    ...form,
                    contactoId: response.id,
                }
                iniciarContacto(datosContacto);
                nextStep();
            })
            .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
        }
    };

    const setZohoDate = (data) => {
        setDatosZoho(data);
        setValue('apellido', data.lastName);
        setValue('barrio','barrio');
        setValue('categoriaZoho', data.clienteCategoria);
        setValue('celular', data.mobile);
        setValue('clienteNombre', data.clienteNombre);
        setValue('clienteId', data.clienteId);
        setValue('direccion', data.clienteDireccion);
        setValue('documento', data.clienteDocumento);
        setValue('email', data.email);
        setValue('nombre', data.firstName);
        setValue('telefono', AuxiliarFunction.extractNumber(data.clienteTelefono));
        setValue('zohoCRMContactId', data.zohoCRMContact.id);
        setValue('zohoDESKContactId', data.id);
        setValue('zohoDESKContactUrl', data.webUrl);
        if(data.coordenadas){
            setCoordenadas(data.coordenadas)
        }else{
            setCoordenadas(location)
        }
    };
    useEffect(()=>{
        if (item){
            setValue('apellido', item.apellido);
            setValue('barrio', item.barrio);
            setValue('categoria', item.categoria);
            setValue('categoriaZoho', item.categoriaZoho);
            setValue('celular', item.celular);
            setValue('contactoId', item.id);
            setValue('clienteId', item.clienteId);
            setValue('clienteNombre', item.nombreCompleto);
            setValue('direccion', item.direccion);
            setValue('documento', item.documento);
            setValue('email', item.email);
            setValue('lugarId', item.lugarId);
            setValue('nombre', item.nombre);
            setValue('telefono', item.telefono);
            setValue('tipoCanalId', item.tipoCanalId);
            setValue('zohoCRMContactId', item.zohoCRMContactId);
            setValue('zohoDESKContactId', item.zohoDESKContactId);
            setValue('zohoDESKContactUrl', item.zohoDESKContactUrl);
            setCoordenadas({
                lat: item.latitud,
                lng: item.longitud,
            });
            setDatosZoho(item);
        }
    },[]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="my-3 my-lg-3 ml-lg-3">Tareas</h2>
                        </div>
                        <div className="col-12 col-md-7 col-lg-7">
                            <div className="row border bg-white p-2 p-lg-3 rounded shadow-sm">
                                <div className="col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label
                                                className="label-control"
                                                htmlFor="nombre"
                                            >
                                                Buscar por Nombre
                                                <i className="fas fa-search text-primary mx-2"></i>

                                            </label>
                                            <Controller
                                                as={InputZohoSearch}
                                                control={control}
                                                field="nombreCompleto"
                                                name="clienteNombreBuscador"
                                                id="clienteNombreBuscador"
                                                onSelect={(opt) => {
                                                    if (opt && opt.length > 0) {
                                                        setZohoDate(opt[0]);
                                                    }
                                                }}
                                            />
                                            {errors.clienteNombreBuscador&& (
                                                <div className="default-invalid-feedback">
                                                    {errors.clienteNombreBuscador?.message}
                                                </div>
                                            )}
                                        </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                            <label
                                                className="label-control"
                                                htmlFor="nombre"
                                            >
                                                    Buscar por DNI
                                                <i className="fas fa-search text-primary mx-2"></i>

                                            </label>
                                            <Controller
                                                as={InputZohoSearch}
                                                control={control}
                                                field="documento"
                                                name="clienteDocumento"
                                                id="clienteDocumento"
                                                onSelect={(opt) => {
                                                    if (opt && opt.length > 0) {
                                                        setZohoDate(opt[0]);
                                                    }
                                                }}
                                            />
                                            {errors.clienteDocumento ? (
                                                <div className="default-invalid-feedback">
                                                    {errors.clienteDocumento?.message}
                                                </div>
                                            ) : null}
                                    </div>
                                </div>

                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                            <label
                                                className="label-control"
                                                htmlFor="nombre"
                                            >
                                                Nombre del cliente
                                            </label>
                                                <input
                                                    id="nombre"
                                                    className={`form-control ${
                                                        errors.nombre ? 'is-invalid' : ''
                                                    }`}
                                                    type="text"
                                                    name="nombre"
                                                    ref={register}
                                                    autoComplete="off"
                                                />
                                                {errors.nombre ? (
                                                    <div className="invalid-feedback">
                                                        {errors.nombre?.message}
                                                    </div>
                                                ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                            <label
                                                className="label-control"
                                                htmlFor="apellido"
                                            >
                                                Apellido del cliente
                                            </label>
                                                <input
                                                    id="apellido"
                                                    className={`form-control ${
                                                        errors.apellido ? 'is-invalid' : ''
                                                    }`}
                                                    type="text"
                                                    name="apellido"
                                                    ref={register}
                                                    autoComplete="off"
                                                />
                                                {errors.apellido ? (
                                                    <div className="invalid-feedback">
                                                        {errors.apellido?.message}
                                                    </div>
                                                ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                            <label
                                                className="label-control"
                                                htmlFor="documento"
                                            >
                                                Dni del cliente
                                            </label>
                                                <input
                                                    id="documento"
                                                    className={`form-control ${
                                                        errors.documento ? 'is-invalid' : ''
                                                    }`}
                                                    type="text"
                                                    name="documento"
                                                    ref={register}
                                                    autoComplete="off"
                                                />
                                                {errors.documento ? (
                                                    <div className="invalid-feedback">
                                                        {errors.documento?.message}
                                                    </div>
                                                ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="clienteId"
                                        >
                                            Cliente ID
                                        </label>
                                        <input
                                            id="clienteId"
                                            className={`form-control ${
                                                errors.clienteId ? 'is-invalid' : ''
                                            }`}
                                            type="text"
                                            name="clienteId"
                                            ref={register}
                                            autoComplete="off"
                                        />
                                        {errors.clienteId ? (
                                            <div className="invalid-feedback">
                                                {errors.clienteId?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="direccion"
                                        >
                                            Dirección
                                        </label>
                                        <input
                                            id="direccion"
                                            className={`form-control ${
                                                errors.direccion
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            type="text"
                                            name="direccion"
                                            ref={register}
                                            autoComplete="off"
                                        />
                                        {errors.direccion ? (
                                            <div className="invalid-feedback">
                                                {errors.direccion?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="telefono"
                                        >
                                            Telefono
                                        </label>
                                        <input
                                            id="telefono"
                                            className={`form-control ${
                                                errors.telefono
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            type="text"
                                            name="telefono"
                                            ref={register}
                                            autoComplete="off"
                                        />
                                        {errors.telefono ? (
                                            <div className="invalid-feedback">
                                                {errors.telefono?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="celular"
                                        >
                                            Celular
                                        </label>
                                        <input
                                            id="celular"
                                            className={`form-control ${
                                                errors.celular
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            type="text"
                                            name="celular"
                                            ref={register}
                                            autoComplete="off"
                                        />
                                        {errors.celular ? (
                                            <div className="invalid-feedback">
                                                {errors.celular?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="categoriaZoho"
                                        >
                                            Categoria ZOHO
                                        </label>
                                        <input
                                            id="categoriaZoho"
                                            className={`form-control ${
                                                errors.categoriaZoho
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            type="text"
                                            name="categoriaZoho"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                        {errors.categoriaZoho ? (
                                            <div className="invalid-feedback">
                                                {errors.categoriaZoho?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="email"
                                        >
                                            Correo ZOHO
                                        </label>
                                        <input
                                            id="email"
                                            className={`form-control ${
                                                errors.email
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            type="email"
                                            name="email"
                                            ref={register}
                                            autoComplete="off"
                                        />
                                        {errors.email ? (
                                            <div className="invalid-feedback">
                                                {errors.email?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="lugarId"
                                        >
                                            Localidad
                                        </label>
                                        <SelectLugar
                                            id="lugarId"
                                            value={lugarOption}
                                            isInvalid={Boolean(errors.lugarId)}
                                            onChange={(opt) => {
                                                setValue('lugarId', opt.value);
                                                setLugarOption(opt);
                                            }}
                                        />
                                        {errors.lugarId ? (
                                            <div className="default-invalid-feedback">
                                                {errors.lugarId?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="categoria"
                                        >
                                            Categoria
                                        </label>
                                        <select
                                            id="categoria"
                                            name="categoria"
                                            className={`form-control ${
                                                errors.categoria ? 'is-invalid' : ''
                                            }`}
                                            ref={register}
                                        >
                                            <option value="">
                                                Seleccione una opcion
                                            </option>
                                            <option value="NORMAL">NORMAL</option>
                                            <option value="PYME 1">PYME 1</option>
                                        </select>
                                        {errors.categoria ? (
                                            <div className="invalid-feedback">
                                                {errors.categoria?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <label
                                            className="label-control"
                                            htmlFor="tipoCanalId"
                                        >
                                            Canal
                                        </label>
                                        <SelectTipoCanal
                                            id="tipoCanalId"
                                            value={tipoCanalId}
                                            onChange={(opt) => {
                                                setValue('tipoCanalId', opt.value);
                                            }}
                                        />
                                        {errors.tipoCanalId ? (
                                            <div className="default-invalid-feedback">
                                                {errors.tipoCanalId?.message}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-12 col-lg-5">
                            <div className="row">
                                <div className="col-12 border bg-white shadow-sm ml-1 mt-2 mt-md-0 mt-lg-0 py-2 rounded">
                                    <MapClick
                                        id="mapaComercial"
                                        zoom={15}
                                        coordinates={coordenadas}
                                        options={{
                                            maxZoom: 19,
                                            minZoom: 14,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 my-lg-4 col-lg-12">
                    <div className="row">

                        <div className="col-lg-12 mt-3 text-right">
                            <Link to="/contactos" className="btn btn-gray btn-sm">
                                Cancelar
                            </Link>
                            <Button
                                className="btn btn-primary"
                                theme="primary"
                                onClick={handleSubmit(onSubmit)}
                                isLoading={isLoading}
                            >
                                Guardar
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

TareaForm1.defaultProps = {
    item: {
        id: 0,
        latitud: -24.7821,
        longitud: -65.4232,
        lugarId: 0,
        tipoCanalId: 0,
        barrio:'',
        motivoTarea: {
            id: 0,
        }
    },
    cuadrillas: []
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),

});

export default connect(mapStateToProps, mapDispatchToProps)(TareaForm1);