import { getType, getCoords } from '@turf/invariant';
import { polygon, point } from '@turf/helpers';
import centroid from '@turf/centroid';
import union from '@turf/union';
import distance from '@turf/distance';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { defaultCoordinates } from '@app/constants/geolocationOptions';

function turfjsToGoogleMaps(geom){
    if(!geom){
        return [];
    }
    const auxCoord = [];
    const type = getType(geom);
    let aux;
    switch (type) {
        case "MultiPolygon":
            getCoords(geom).forEach(coordinates=>{
                coordinates.forEach(coordinate=>{
                    auxCoord.push(
                        coordinate.map((coord)=>({ lat:coord[1] ,lng:coord[0] }))
                    );
                });
            });
            break;
        case "Point":
            aux = getCoords(geom);
            auxCoord.push({
                lat:aux[1],
                lng:aux[0]
            });
            break;
        default:
            getCoords(geom).forEach(coordinate=>{
                    auxCoord.push(
                        coordinate.map(coord=>({
                            lat:coord[1],lng:coord[0]
                        }))
                    );
                });
            break;
    }
    return auxCoord;
}

/**
 * FROM GEOJSON TO GOOGLE MAPS
 * @param {latLng} json 
 * @returns 
 */
function gJsonToGoogleMaps(json){
    if(!json){
        return [];
    }
    const { coordinates } = json;
    let auxCoord = [];
    switch (json.type) {
        case "Polygon":
            coordinates.forEach(pool=>{
                pool.forEach(coords=>{
                    auxCoord.push({lat:coords[1],lng:coords[0]});
                });
            });
            break;
        case "Point":
            auxCoord.push({lat:coordinates[1],lng:coordinates[0]});
            break;
        default:
            if(Array.isArray(json)){
                auxCoord = json;
            }
            break;
    }
    return auxCoord;
}

function shadeColor(color, percent) {
    let R = parseInt(color.substring(1,3),16);
    let G = parseInt(color.substring(3,5),16);
    let B = parseInt(color.substring(5,7),16);
    R = parseInt(R * (100 + percent) / 100,10);
    G = parseInt(G * (100 + percent) / 100,10);
    B = parseInt(B * (100 + percent) / 100,10);
    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;
    const RR = ((R.toString(16).length===1)?`0${R.toString(16)}`:`${R.toString(16)}`);
    const GG = ((G.toString(16).length===1)?`0${G.toString(16)}`:`${G.toString(16)}`);
    const BB = ((B.toString(16).length===1)?`0${B.toString(16)}`:`${B.toString(16)}`);
    return `#${RR}${GG}${BB}`;
}

function getPoligonColor(tipoCovertura,selected){
    let color = "";
    if(selected){
        color = "#000000"
    }else{
        switch (tipoCovertura) {
            case 'DISPONIBLE':
                color = "#64F76C";
                break;
            case 'SATURADO':
                color = "#ff0000";
                break;
            case 'MEDIO':
                color = "#fffb00";
                break;
            default:
                break;
        }
    }
    return {
        strokeColor: color,
        fillColor: shadeColor(color,50),
    };
}

function unirPoligonos(poligonos){
    let auxPoligonosDisponibles = [];
    let auxPoligonosSaturados = [];
    let auxPoligonosMedio = [];

    poligonos.forEach(poligono=>{
        switch (poligono.tipoCovertura) {
            case 'DISPONIBLE':
                auxPoligonosDisponibles.push(polygon(poligono.aristas.coordinates));
                break;
            case 'SATURADO':
                auxPoligonosSaturados.push(polygon(poligono.aristas.coordinates));
                break;
            case 'MEDIO':
                auxPoligonosMedio.push(polygon(poligono.aristas.coordinates));
                break;
            default:
                break;
        }
    });

    let auxPol = null;
    auxPoligonosDisponibles.forEach(pol=>{
        if(auxPol == null){
            auxPol = pol;
        } else {
            auxPol = union(auxPol,pol);
        }
    });
    auxPoligonosDisponibles = auxPol;
    auxPol = null;
    auxPoligonosSaturados.forEach(pol=>{
        if(auxPol == null){
            auxPol = pol;
        } else {
            auxPol = union(auxPol,pol);
        }
    });
    auxPoligonosSaturados = auxPol;
    auxPol = null;
    auxPoligonosMedio.forEach(pol=>{
        if(auxPol == null){
            auxPol = pol;
        } else {
            auxPol = union(auxPol,pol);
        }
    });
    auxPoligonosMedio = auxPol;
    return [
        ...turfjsToGoogleMaps(auxPoligonosDisponibles).map((aristas,index)=>{
            return {
                id: `d0${index}`,
                aristas: aristas,
                tipoCovertura: 'DISPONIBLE',
            }
        }),
        ...turfjsToGoogleMaps(auxPoligonosSaturados).map((aristas,index)=>{
            return {
                id: `s0${index}`,
                aristas: aristas,
                tipoCovertura: 'SATURADO',
            }
        }),
        ...turfjsToGoogleMaps(auxPoligonosMedio).map((aristas,index)=>{
            return {
                id: `m0${index}`,
                aristas: aristas,
                tipoCovertura: 'MEDIO',
            }
        }),
    ]
}

function getCoordinatesFromShape(shape){
    const newPoligono = [];
    if(!shape){
        return [];
    }
    const paths = shape.getPaths();
    paths.getArray().forEach(path=>{
        path.forEach(latLng=>{
            newPoligono.push({
                lat: latLng.lat(),
                lng: latLng.lng(),
            });
        })
    })
    
    newPoligono.push(newPoligono[0]);
    return newPoligono;
}

/**
 * 
 * @param {latLng} from 
 * @param {latLng} to 
 * @returns {number} kilometers
 */
function getDistanceCoordinates(from,to){
    const origin = point([from.lat, from.lng]);
    const destination = point([to.lat, to.lng]);
    return distance(origin, destination);
}

function getCentroidFromGJson(entryPoligono){
    const poligono = polygon(entryPoligono.aristas.coordinates);
    const punto = turfjsToGoogleMaps(centroid(poligono));
    return punto[0];
}

function isPointInPolygon(coordinates,poligono){
    const origen = point([coordinates.lat, coordinates.lng]);
    const area = polygon(poligono.aristas.coordinates);
    return booleanPointInPolygon(origen,area);
}

/**
 * 
 * @param {Tarea[]} tareas //marcas actuales
 * @param {Tarea|Contacto} tarea //nueva marca
 */
function nuevoCostoPromedio(tareas,tarea){
    const promedios = [];
    tareas.forEach(t=>{
        let from;
        let to;
        if(t.coordenadas){
            [from,] = gJsonToGoogleMaps(t.coordenadas);
            if(tarea.coordenadas){
                [to,] = gJsonToGoogleMaps(tarea.coordenadas);
            } else {
                to = {
                    lat: tarea.latitud || tarea.clienteLatitud,
                    lng: tarea.longitud || tarea.clienteLongitud,
                };
            }
            const dist = getDistanceCoordinates(from,to);
            promedios.push(dist);
            t.distancia = Number(dist.toFixed(2));
        }
        return t;
    })
    if(promedios.length>0){
        const prom = Math.round(( promedios.reduce((acc,value)=>acc+value,0) / promedios.length)/80*60);
        if(prom === 1){
            return 1;
        }
        return prom;
    }
    return 0;
}

/**
 * Calculo de promedios para el TM y TT, por turnos separadosh
 * @param {Cuadrilla[]} items 
 * @param {Tarea|Contacto} contacto 
 * @returns 
 */
function calculoCostos(items,contacto){
    const promediosTM = [];
    const promediosTT = [];
    const aux = items.map(item=>{
        const costoMinimoTMPromedio = nuevoCostoPromedio(item.tareasTM,contacto);
        const costoMinimoTTPromedio = nuevoCostoPromedio(item.tareasTT,contacto);
        if(item.costoMinimoTM>0){
            promediosTM.push(item.costoMinimoTM+costoMinimoTMPromedio);
        } else {
            promediosTM.push(costoMinimoTMPromedio);
        }
        if(item.costoMinimoTT>0){
            promediosTT.push(item.costoMinimoTT+costoMinimoTTPromedio);
        } else {
            promediosTT.push(costoMinimoTTPromedio);
        }
        return {
            ...item,
            esSolucionCompletaTM: item.tareasTM.length === item.tareasTM.filter(t=>t.coordenadas).length,
            esSolucionCompletaTT: item.tareasTT.length === item.tareasTT.filter(t=>t.coordenadas).length,
            costoMinimoTMPromedio,
            costoMinimoTTPromedio,
        };
    });
    const maxTM = Math.max(promediosTM);
    const maxTT = Math.max(promediosTT);
    let promedioTM = promediosTM.reduce((acc,val)=>acc+val,0);
    let promedioTT = promediosTT.reduce((acc,val)=>acc+val,0);
    
    if(promediosTM.length > 0){
        promedioTM /= promediosTM.length;
    }
    if(promediosTT.length > 0){
        promedioTT /= promediosTT.length;
    }

    return aux.map((item)=>{
        let incrementoCostoTM;
        let incrementoCostoTT;
        if(item.costoMinimoTM>0){
            incrementoCostoTM = Number(((item.costoMinimoTMPromedio*100)/item.costoMinimoTM).toFixed(2));
        } else {
            incrementoCostoTM = Number((item.costoMinimoTMPromedio).toFixed(2));
        }
        if(item.costoMinimoTT>0){
            incrementoCostoTT = Number(((item.costoMinimoTTPromedio*100)/item.costoMinimoTT).toFixed(2));
        } else {
            incrementoCostoTT = Number((item.costoMinimoTTPromedio).toFixed(2));
        }
        
        let eficienciaCostoEstadoTM;
        if(item.costoMinimoTMPromedio < promedioTM && item.esSolucionCompletaTM){
            eficienciaCostoEstadoTM = 'BUENO';
        } else {
            eficienciaCostoEstadoTM = 'MALO';
        }
        let eficienciaCostoEstadoTT;
        if(item.costoMinimoTTPromedio < promedioTT && item.esSolucionCompletaTT){
            eficienciaCostoEstadoTT = 'BUENO';
        } else {
            eficienciaCostoEstadoTT = 'MALO';
        }
        return {
            ...item,
            eficienciaCostoEstadoTM,
            eficienciaCostoEstadoTT,
            corteEficienciaCostoTM: promedioTM,
            corteEficienciaCostoTT: promedioTT,
            incrementoCostoTM,
            incrementoCostoTT,
        }
    });
}

const MapaFunction = {
    gJsonToGoogleMaps: gJsonToGoogleMaps,
    unirPoligonos: unirPoligonos,
    turfjsToGoogleMaps: turfjsToGoogleMaps,
    getPoligonColor: getPoligonColor,
    shadeColor: shadeColor,
    getDistanceCoordinates:getDistanceCoordinates,
    getCoordinatesFromShape:getCoordinatesFromShape,
    getCentroidFromGJson:getCentroidFromGJson,
    isPointInPolygon: isPointInPolygon,
    nuevoCostoPromedio: nuevoCostoPromedio,
    calculoCostos: calculoCostos,
};
export default MapaFunction;