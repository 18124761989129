import axios from 'axios';
const base = 'contratos';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function getById(id) {
    return axios.get([base, id].join('/'));
}

function getCrmTrato(query=null) {
    return axios.get('crm/tratos',{params: query});
}

function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const ContratosService = {
    getAll,
    register,
    getById,
    update,
    destroy,
    getCrmTrato:getCrmTrato

};
export default ContratosService;