import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import PoligonosService from '@app/services/poligonos.service';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({
    id: Yup.number(),
    archivo: Yup.mixed()
    .when('id',{
        is: (value) => value === 0,
        then: Yup.mixed().test(
            "type",
            "We only support kml",
            (value) => !value || (value && value[0].type === "kml")
          ), 
    }),
});

function ModalPoligonoEditar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id
        };
        setIsLoading(true);
        if (form.id > 0) {

            PoligonosService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }else{
            PoligonosService.register(form)
            .then(() => {
                onGuardar();
                hideModal();
            })
        }   
    };
    useEffect(()=>{
        register('id',item.id);
    },[register]);
    
    useEffect(() => {
        setValue('id', item.id);
        if (item.id > 0) {
            setValue('nombre', item.nombre);
            setValue('tipoEquipo', item.tipoEquipo);
            setValue('tipoCovertura', item.tipoCovertura);
        }
    }, []);
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Registrar Poligonos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {
                        item.id === 0 && (
                            <>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombreL">
                                        Tecnologia
                                    </label>  
                                    <input 
                                        type="file" 
                                        className="form-control"
                                        name="kmlinput"
                                        id="archivo" 
                                        accept=".kml" 
                                        required
                                        ref={register} 
                                    />
                                </div>
                            </>
                        )
                    }
                    {
                        item.id > 0 && (
                            <>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombreL">
                                        Nombre
                                    </label>
                                    <input
                                        className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        autoComplete="off"
                                        ref={register}
                                    />
                                    {errors.nombre && (
                                        <div className="invalid-feedback">
                                            {errors.nombre.message}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="tipoCoverturaL">
                                        Tecnologia
                                    </label>
                                    <select 
                                        className={`form-control ${errors.tipoCovertura? 'is-invalid': ''}`}
                                        ref={register}
                                        name="tipoCovertura"
                                        id="tipoCovertura"
                                    >
                                        <option value="DISPONIBLE">DISPONIBLE</option>
                                        <option value="MEDIO">MEDIO</option>
                                        <option value="SATURADO">SATURADO</option>
                                    </select>
                                </div>
                            </>
                        )
                    }
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombreL">
                            Tecnologia
                        </label>
                        <select className="form-control" ref={register} name="tipoEquipo" id="tipoEquipo" required>
                            <option value="ePMP">ePMP</option>
                            <option value="PMP450m">PMP450</option>
                        </select>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
ModalPoligonoEditar.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
        tipoEquipo: '',
        tipoCovertura: ''
    }
};

export default ModalPoligonoEditar;