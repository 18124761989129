import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, MapClick, SelectZona} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import LugaresService from '@app/services/lugares.service';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({
    nombre: Yup.string().max(200).required(),
    zonaId: Yup.number().required(),
    nombreCorto: Yup.string().max(100).required(),
    color: Yup.string().nullable()
});

function ModalLugarEditar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const [coordenadas, setCoordenadas] = useState({
        lat: item.latitud,
        lng: item.longitud
    });
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id,
            latitud: coordenadas.lat,
            longitud: coordenadas.lng
        };
        setIsLoading(true);
        if (form.id > 0) {
            LugaresService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            LugaresService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    const zonaId = watch('zonaId', item.zonaId);

    useEffect(() => {
        register('zonaId');
    }, [register]);

    useEffect(() => {
        if (item.id > 0) {
            setValue('zonaId', item.zonaId);
            setValue('nombre', item.nombre);
            setValue('nombreCorto', item.nombreCorto);
            setValue('color', item.color);
        }
    }, []);
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar lugar' : 'Registrar lugar'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="label-control" htmlFor="nombre">
                                    Nombre
                                </label>
                                <input
                                    className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                    id="nombre"
                                    name="nombre"
                                    type="text"
                                    autoComplete="off"
                                    ref={register} 
                                />
                                {errors.nombre && (
                                    <div className="invalid-feedback">
                                        {errors.nombre.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label
                                    className="label-control"
                                    htmlFor="nombreCorto"
                                >
                                    Nombre Corto
                                </label>
                                <input
                                    className={`form-control ${errors.nombreCorto? 'is-invalid': ''}`}
                                    id="nombreCorto"
                                    name="nombreCorto"
                                    type="text"
                                    autoComplete="off"
                                    ref={register} 
                                />
                                {errors.nombreCorto && (
                                    <div className="invalid-feedback">
                                        {errors.nombreCorto.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="label-control" htmlFor="zonaId">
                                    ZONA
                                </label>
                                <SelectZona 
                                    id="zonaId"
                                    isInvalid={errors.zonaId}
                                    value={zonaId}
                                    onChange={(opt)=>setValue('zonaId',opt.value)}
                                />
                                {errors.zonaId && (
                                    <div className="default-invalid-feedback">
                                        {errors.zonaId.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label
                                    className="label-control"
                                    htmlFor="color"
                                >
                                    Color
                                </label>
                                <input
                                    className={`form-control-color border border-ligth rounded w-100 bg-white ${errors.color? 'is-invalid': ''}`}
                                    id="color"
                                    name="color"
                                    type="color"
                                    ref={register} 
                                />
                                {errors.color && (
                                    <div className="invalid-feedback">
                                        {errors.color.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <MapClick
                        coordinates={coordenadas}
                        onChange={setCoordenadas}
                    />
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalLugarEditar.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
        nombre: PropTypes.string,
        nombreCorto: PropTypes.string,
        zonaId: PropTypes.number,
        color: PropTypes.string,
        latitud: PropTypes.number,
        longitud: PropTypes.number
    })
};

ModalLugarEditar.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
        nombreCorto: '',
        zonaId: null,
        color: '#FFFFFF',
        latitud: -24.7821,
        longitud: -65.4232
    }
};

export default ModalLugarEditar;
