import React, {useState, useEffect} from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

const Styles = styled.div``;

function getOptionSelect(value,options){
    if(!Array.isArray(options)){
        return null;
    }
    if(AuxiliarFunction.isObject(value)){
        return value;
    }
    const option = options.find(opt => opt.value === value);
    if(option){
        return option;
    }
    return null;
}

const Select = ({
    id,
    options,
    defaultValue: externalDefaultValue,
    onChange,
    isInvalid,
    isClearable,
    isDisabled
}) => {
    const [defaultValue, setDefaultValue] = useState(getOptionSelect(externalDefaultValue,options));
    const onSelectValue = (ev) => {
        onChange(ev);
    };
    return (
        <Styles>
            <ReactSelect
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                defaultValue={defaultValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isDisabled={isDisabled}
            />
        </Styles>
    );
};

Select.propTypes = {
    isClearable: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
        })
    ),
};

Select.defaultProps = {
    isClearable: false,
    options: [],
};

export default Select;
