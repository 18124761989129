import React from 'react';
import {connect} from 'react-redux';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {useHistory} from 'react-router-dom';

function getTareaEstadoBadge(value) {
    switch (value) {
        case 1:
            return (<span className="position rounded badge badge-primary bg-primary">ESPERA</span>);
        case 2:
            return (<span className="rounded badge badge-secondary bg-secondary">A DESIGNAR</span>);
        case 3:
            return (<span className="rounded badge badge-success bg-success">ASIGNADO</span>);
        case 4:
            return (<span className="rounded badge badge-warning bg-warning text-dark">REPROGRAMADO</span>);
        case 5:
            return (<span className="rounded badge badge-warning bg-warning text-dark">VENCIDO</span>);
        case 6:
            return (<span className="rounded badge badge-danger bg-danger">ELIMINADO</span>);
        case 7:
            return (<span className="rounded badge badge-warning bg-warning text-dark">PARA REPROGRAMAR</span>);
        default:
            break;
    }
    return <></>;
}

function EventTarea({event}) {
    const {_def} = event
    const {extendedProps} = _def
    const history = useHistory();

    const copiarCoordenadas = () =>{
        const aux = document.createElement("input");
        aux.setAttribute("value",`${extendedProps.clienteLatitud},${extendedProps.clienteLongitud}`);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }

    const moverTarea = () =>{
        history.push({
            moverTarea: extendedProps
        });
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'white',
          color: 'black',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          padding:'0',
          border: '1px solid #dee2e6',
          boxShadow:'0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
          borderRadius:'0.25rem !important'
        },
      }));

    return (
            
        <HtmlTooltip
            className="m-0 p-0"
            title={(
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 mt-2'>
                                <span>
                                    {event.extendedProps.clienteNombre?.toUpperCase()}
                                    :
                                </span>
                                <br />
                                <span>
                                    •
                                    {event.extendedProps.clienteDireccion?.toUpperCase()}
                                </span>
                                <br />
                                <span>
                                    •
                                    {event.extendedProps.tipoTarea?.toUpperCase()}
                                </span>
                                <br />
                                <span>
                                    •
                                    {event.extendedProps.lugar.zona?.nombre.toUpperCase()}
                                </span>
                                <br />
                                <span>
                                    •
                                    {event.extendedProps.lugar.nombre?.toUpperCase()}
                                </span>                    
                            <button className="btn btn-xs btn-warning float-right mb-1" title={extendedProps.clienteLatitud === null || extendedProps.clienteLongitud === null ? "Sin Coordenadas" : "Copiar Coordenadas"} disabled={extendedProps.clienteLatitud === null || extendedProps.clienteLongitud === null} type='button' onClick={()=>copiarCoordenadas()}>
                                <i className="fas fa-map-marker-alt" />
                            </button>
                            <button className="btn btn-xs btn-blue float-right mb-1" title="Mover" type='button' onClick={()=>moverTarea()}>
                                <i className="fas fa-arrows-alt" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        >
            <div className="fc-event-main-frame">
                <div className="fc-event-title-container">
                    <div className="text-left fc-event-title fc-sticky ">
                        <div className="">
                                <div className="col-12 text-white">
                                    {event.extendedProps.lugarNombre}
                                </div>
                                <div className="col-12 text-white">
                                    {event.extendedProps.tipoTarea}
                                </div>
                                <div className="col-12 text-white">
                                    {event.extendedProps.clienteNombre}
                                </div>
        
                        </div>
                    </div>
                            <div className="text-left fixed-bottom position-absolute">
                                    {getTareaEstadoBadge(event.extendedProps.estadoTareaId)}
                            </div>
                </div>
            </div>
        </HtmlTooltip>
    );
}

const mapStateToProps = (state) => ({
    cuadrillas: state.calendar.cuadrillaDisponiblesLugar
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EventTarea);
