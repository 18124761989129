import React, {useState, useEffect, useRef, useMemo} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {showModal} from 'react-redux-modal-provider';
import ModalFiltroCalendar from '@app/modals/filtro/ModalFiltroCalendar';
import ModalDejarRepro from '@app/modals/tarea/ModalDejarRepro';
import ModalCrearTarea from '@app/modals/calendario/ModalCrearTarea';
import ModalCuadrillaLugarDiponibles from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarDiponibles';
import { isSameDay } from 'date-fns';
import { Spinner } from 'react-bootstrap';
import {useHistory } from 'react-router-dom';
import * as CalendarAction from '@app/store/actions/calendar';
import {Button, ButtonToggle, Calendar, ModalConfirmPromise, SelectLugar, SelectZona} from '@app/components/index';
import CalendarFunction from '@app/functions/CalendarFunction';
import EchoWS from '@app/utils/ws';
import useGetCalendario from '@app/hooks/useGetCalendario';
import FechaFunction from '@app/functions/FechaFunction';
import ModalCrearTareaRecibidaCrm from '@app/modals/calendario/ModalCrearTareaRecibidaCrm';
import ModalMoverTarea from '@app/modals/calendario/ModalMoverTarea';
import TareasService from '@app/services/tareas.service';
import EventCuadrillaLugar from './EventCuadrillaLugar';
import EventCuadrillaDisponible from './EventCuadrillaDisponible';
import EventTarea from './EventTarea';
import './Calendario.scss';

const viewOptions = [
    {
        label: 'Mes',
        value: 'dayGridMonth'
    },
    {
        label: 'Semana',
        value: 'timeGridWeek'
    },
    {
        label: 'Día',
        value: 'timeGridDay'
    }
];

function CalendarioPage({
    eventos,
    cuadrillas,
    estado,
    guardarEstado,
    recuperar,
    itemReprogramado,
    iniciarCuadrillaLugar,
}) {
    const history = useHistory();
    const [zonaId, setZonaId] = useState();
    const [clienteReprogramar, setClienteReprogramar] = useState()
    const [lugarId, setLugarId] = useState();
    const [nombreCliente, setNombreCliente] = useState("")
    const {initialView, initialDate} = estado;
    const [t] = useTranslation();
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangeByType(initialView,initialDate));
    const [filtros,setFiltros] = useState({
        tareaCategoria: 'NORMAL,VIP 1,VIP 2,VIP 3',
        tipoTareaId: '1,2,3,6',
        estadoTareaId: '1,2,3,5'
    });
    const [channel, setChannel] = useState();
    const calendarioRef = useRef();

    const { response:{items}, setQuery, isLoading} = useGetCalendario({
        defaultQuery:{
            fechaIni: FechaFunction.format(rangeDate.start),
            fechaFin: FechaFunction.format(rangeDate.end),
            lugarId: lugarId,
            zonaId: zonaId,
            nombreCliente: nombreCliente,
            ...filtros,
        },
        defaultResponse:{
            items:eventos
        }
    });
    const refrescar = () => {
        setQuery({
            fechaIni: FechaFunction.format(rangeDate.start),
            fechaFin: FechaFunction.format(rangeDate.end),
            lugarId: lugarId,
            zonaId: zonaId,
            nombreCliente: nombreCliente,
            ...filtros,
        })
    };

    const onGuardar = () =>  refrescar();
    const handleInputChange = ( e ) => {
        setNombreCliente(e.target.value);
    }

    const eliminarReprogramacion = () =>{
        setClienteReprogramar();
        history.push({
            reprogramar: undefined
        });
    }

    const eliminarTareaMover = () => {
        history.push({
            moverTarea: undefined
        });
    }

    const cancelarSeleccionFecha = () =>{
        history.push({
            tarea: undefined
        });
    }
    const onSinCuadrillaDisponible = () =>{
        showModal(ModalConfirmPromise, {
            title: 'Sin cuadrilla disponible.',
            message: '¿Esta seguro que desea continuar? Se creara un ticket con un vencimiento de 30 dias.',
            onConfirm: () => {
                TareasService.estados({
                    id:history.location.tarea.id,
                    estadoTareaId: 9
                })
                .then(() => {
                    history.push({
                        tarea: undefined
                    });
                })
            }
        });
    }

    const crearTarea = (fechaCalendario, cuadrilla) => {
            showModal(ModalCrearTarea, {fechaCalendario: fechaCalendario , cuadrillas:cuadrilla, onGuardar: onGuardar,clienteReprogramacion:history.location.reprogramar,eliminarReprogramacion})
    }

    const crearTareaRecibidaCrm = (fechaCalendario,cuadrilla, fechaCalendar) => {
            showModal(ModalCrearTareaRecibidaCrm, {fechaCalendario:fechaCalendario,cuadrillas:cuadrilla, onGuardar: onGuardar, nuevaTarea:history.location.tarea, fechaCalendar:fechaCalendar,cancelarSeleccionFecha:cancelarSeleccionFecha})
    }

    const moverTarea = (fechaCalendario, cuadrilla,fechaCalendar) =>{
        showModal(ModalMoverTarea, {fechaCalendario: fechaCalendario , cuadrillas:cuadrilla, onGuardar: onGuardar, item:history.location.moverTarea, eliminarTarea:eliminarTareaMover, fechaCalendar:fechaCalendar})
    }


    const dateClick = ({allDay, date, view}) => {
        const periodo = CalendarFunction.getPeriodoFromTime(date);
        const eventosLugaresFilter = view
            .getCurrentData()
            .calendarApi.getEvents()
            .filter((event) => {
                return (
                    isSameDay(FechaFunction.parse(event.extendedProps.fecha), date) &&
                    event.groupId === 'GRP-CUADRILLADISPONIBLELUGAR'
                );
            });
        const cuadrillasDisponiblesLugar = eventosLugaresFilter
            .filter((cl) => cl.extendedProps.periodo !== 'ND')
            .filter((cl) => {
                switch (periodo) {
                    case "TM":
                        if(cl.extendedProps.periodo === 'TT' ) {
                            return false;
                        }
                        break;
                    case "TT":
                        if(cl.extendedProps.periodo === 'TM' ) {
                        return false;
                        }
                        break;
                    default:
                        break;
                }
                return true;
            })
            .map((evento) => {
                return evento.extendedProps;
            });

        const fechaCalendar = FechaFunction.format(date,'Y-MM-dd HH:mm:ss');
        const fechaReal = FechaFunction.format(new Date(),'yyyy-MM-dd HH:mm:ss');

        if (cuadrillasDisponiblesLugar.length > 0 && !allDay && fechaReal <= fechaCalendar ) {

            // Mover Tarea
            if(history.location.moverTarea){
                    moverTarea({
                        state: {
                            fecha: date
                        }
                    },cuadrillasDisponiblesLugar,fechaCalendar)
            }

            // Seleccionar fecha para la tarea recibida desde Crm
            else if(history.location.tarea){
                crearTareaRecibidaCrm(date, cuadrillasDisponiblesLugar, fechaCalendar)
            }
            // Crear Tarea o Reprogramar
            else {
                guardarEstado({
                    initialView: view.type,
                    initialDate: date
                });
                if(history.location.reprogramar){
                    crearTarea({
                        state: {
                            fecha: date,
                            backTo: '/calendario',
                            action: 'nuevo',
                        }
                    }, cuadrillasDisponiblesLugar,history.location.reprogramar);
                }
            }
        }
    };

    const eventClick = (info) => {
        const tipo = info.event.groupId;
        if(tipo === "GRP-TAREAS"){
            history.push({
                pathname: `/tareas/${info.event.extendedProps.id}/editar`,
                state: {
                    item: info.event.extendedProps,
                    backTo: '/calendario'
                }
            });
        }

    };

    const eventContent = (props) => {
        const {event, view} = props;
        if (event.groupId === 'GRP-CUADRILLADISPONIBLELUGAR') {
            return <EventCuadrillaLugar {...props} />;
        }
        if (event.groupId === 'GRP-CUADRILLASDISPONIBLES') {
            return <EventCuadrillaDisponible {...props} />;
        }
        /* ocultar tareas view.type === "timeGridDay" && event.groupId === 'GRP-TAREAS' */
        if(event.groupId === 'GRP-TAREAS'){
            return <EventTarea {...props} />;
        }
        return false;
    };

    const onChangeView = (value) => {
        let newDate = rangeDate.start;
        if(rangeDate.start <= initialDate && initialDate <= rangeDate.end){
            newDate = initialDate;
        }
        guardarEstado({
            initialView: value,
            initialDate: newDate,
        });
        setRangeDate(FechaFunction.rangeByType(value,newDate));
        calendarioRef.current.getApi().changeView(value,newDate);
    };

    const onClickLeft = () => {
        setRangeDate(FechaFunction.rangePrev(rangeDate,initialView));
        calendarioRef.current.getApi().prev();
    };

    const onClickRight = () => {
        setRangeDate(FechaFunction.rangeNext(rangeDate,initialView));
        calendarioRef.current.getApi().next();
    };

    const onFiltrar = (newFilters) => {
        setFiltros(newFilters);
    }

    const onClickFiltrar = () => {
        showModal(ModalFiltroCalendar, { item:filtros, onConfirm:onFiltrar });
    };

    const onClickInterpolar = () => {
        const aux = items.filter(ev=>
            ev.groupId==='GRP-CUADRILLADISPONIBLELUGAR' && !cuadrillas.find(cl=>cl.id===ev.extendedProps.id)
        ).map(ev=>ev.extendedProps);
        iniciarCuadrillaLugar(aux);
    }

    const onClickHeader = ({date,type}) => {
        const range = {
            start:date,
            end:date,
        }
        let newDate = range.start;
        if(range.start <= initialDate && initialDate <= range.end){
            newDate = initialDate;
        }
        let aux;
        let aux2;
        switch(type){
            case 'ver':
                guardarEstado({
                    initialView: 'timeGridDay',
                    initialDate: newDate,
                });
                setRangeDate(range);
                calendarioRef.current.getApi().changeView('timeGridDay',newDate);
                break;
            case 'interpolar':
                aux = items.filter(ev=>
                    ev.groupId==='GRP-CUADRILLADISPONIBLELUGAR' && FechaFunction.isSame(ev.extendedProps.fecha,date) && !cuadrillas.find(cl=>cl.id===ev.extendedProps.id)
                ).map(ev=>ev.extendedProps);
                aux2 = cuadrillas.filter(cl=>!FechaFunction.isSame(cl.fecha,date));

                iniciarCuadrillaLugar([...aux,...aux2]);
                break;
            default:
                break;
        }

    }

    const filterEvents = (events,cuadrillasSeleccionadas,currentView) => {
        return events.filter((item)=>{
            if(currentView==='dayGridMonth'){
                return item.groupId !== 'GRP-TAREAS';
            }
            if(item.groupId === 'GRP-TAREAS'){
                return !(cuadrillasSeleccionadas.find(cl=>cl.id === item.extendedProps.cuadrillaLugarId));
            }
            return true;
        })
    }

    const filteredEventMemo = useMemo(() => filterEvents(items,cuadrillas,initialView), [items,cuadrillas])


    useEffect(() => {
        recuperar(items)
        if(channel){
            channel
            .listen('.cuadrillas.disponibles.updated',refrescar)
            .listen('.cuadrillas.lugares.created',refrescar)
            .listen('.cuadrillas.lugares.deleted',refrescar)
            .listen('.cuadrillas.lugares.updated',refrescar)
            .listen('.tareas.created',refrescar)
            .listen('.tareas.deleted',refrescar)
            .listen('.tareas.updated',refrescar);
        }

        return () => {

        }
    }, [items,channel])
    useEffect(() => {
        refrescar();
    }, [nombreCliente,lugarId,zonaId,filtros,rangeDate,initialView]);

    useEffect(()=>{
        setChannel(EchoWS.private('calendario'))
        return () => {
                if(channel){
                    channel.unsubscribe();
                }
        }
    },[]);

    return (
        <div className="container-fluid">
            <div className="row mt-3 mb-2">
                <div className="col-4">
                    <Button
                        icon="fas fa-chevron-left"
                        onClick={onClickLeft}
                    />
                    <Button
                        icon="fas fa-chevron-right"
                        onClick={onClickRight}
                        className="ml-1"
                    />
                    <Button onClick={onClickFiltrar}>Filtrar</Button>
                    <Button onClick={onClickInterpolar}>
                        <i className="fa fa-retweet mr-1"></i>
                        Interpolar
                    </Button>
                </div>

                <div className="col-lg-4">
                    <h3 className="text-center mes mt-2">
                        {`${FechaFunction.description(rangeDate,initialView)} `}
                        {
                            isLoading && <Spinner className="spinner-border text-primary mb-1 ml-1" animation="border" />
                        }
                    </h3>
                </div>

                <div className="col-4 text-right mt-2">
                        {history.location.moverTarea !== undefined && (
                            <button className='btn btn-sm btn-blue' type='button' onClick={()=>eliminarTareaMover()}>
                                NO MOVER TAREA
                                <i className="fas fa-ban ml-2" />
                            </button>
                        )}
                        {history.location.reprogramar !== undefined && (
                            <button className='btn btn-sm btn-danger' type='button' onClick={()=>eliminarReprogramacion()}>
                                DEJAR DE REPROGRAMAR
                                <i className="fas fa-ban ml-2" />
                            </button>
                        )}
                        {history.location.tarea !== undefined && (
                            <>
                                <button className='btn btn-sm btn-danger' type='button' onClick={()=>onSinCuadrillaDisponible()}>
                                    SIN CUADRILLA DISPONIBLE
                                </button>
                                <button className='btn btn-sm btn-primary' type='button' onClick={()=>cancelarSeleccionFecha()}>
                                    CANCELAR SELECCION
                                    <i className="fas fa-ban ml-2" />
                                </button>
                            </>
                        )}
                        <ButtonToggle
                            className=""
                            onChange={onChangeView}
                            defaultValue={initialView}
                            items={viewOptions}
                        />
                </div>

                <div className="col-4 mt-2">
                    <SelectZona
                        id="zonaId"
                        onChange={(opt) => {
                            if (opt) {
                                setZonaId(opt.value);
                            } else {
                                setZonaId("");
                            }
                        }}
                        placeHolder="Filtro por zona"
                        isClearable
                    />
                </div>

                <div className="col-4 mt-2">
                    <SelectLugar
                        id="lugarId"
                        placeHolder="Filtro por localidad"
                        onChange={(opt) => {
                            if (opt) {
                                setLugarId(String(opt.value));
                            } else {
                                setLugarId("");
                            }
                        }}
                        isClearable
                    />
                </div>

                <div className="col-4 mt-2">
                    <div className="form-group">
                        <input
                            placeholder="Filtro por Nombre Cliente"
                            className="form-control"
                            id="nombreCliente"
                            name="nombreCliente"
                            type="text"
                            onChange={handleInputChange}
                            isClearable
                        />
                    </div>
                </div>
                
            </div>

            <div className="row">
                <div className="col-12">
                    <Calendar
                        innerRef={calendarioRef}
                        initialView={initialView}
                        initialDate={initialDate}
                        events={filteredEventMemo}
                        dateClick={dateClick}
                        eventContent={eventContent}
                        eventClick={eventClick}
                        onClickHeader={onClickHeader}
                    />
                </div>
            </div>
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    eventos: state.calendar.eventos,
    cuadrillas: state.calendar.cuadrillaDisponiblesLugar,
    itemReprogramado: state.calendar.itemReprogramado,
    estado: state.calendar.estado,
});

const mapDispatchToProps = (dispatch) => ({
    guardarEstado: (data) => dispatch(CalendarAction.guardarEstadoCalendario(data)),
    recuperar: (data) => dispatch(CalendarAction.recuperarEventos(data)),
    iniciarCuadrillaLugar: (data) => dispatch(CalendarAction.iniciarCuadrillaLugar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarioPage);
