import { Button } from '@app/components/index';
import React, { useState } from 'react'
import styled from 'styled-components';

const Styles = styled.div`
    background: white;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius:8px;
    text-align:center;
    border: 1px solid #39C0ED; !important;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
`;

function CardAsesorLugar ({
    item,
    selected,

    onClick,
    onEdit,
    onCancelEdit,
}) {

    const onClickCard = () => {
        onClick(item);
        // console.log(item);
    }

    const onClickCancel = () =>{
        onCancelEdit(item)
    }

    const onSelect = () => {
        onEdit(item);
    }

    return (
        <Styles>
            <div onClick={onClickCard} aria-hidden="true">
                {`${item.personal?.nombreCompleto} - ${item.lugar?.nombre}`}
            </div>
            <div>
                {
                    selected ? (
                        <Button
                            icon="fa fa-times-circle"
                            size="sm"
                            variant="success"
                            onClick={onClickCancel}
                        />
                    ) : (
                        <Button
                            icon="fa fa-edit"
                            size="sm"
                            variant="success"
                            onClick={onSelect}
                        />
                    )
                }
            </div>
        </Styles>
    )
}

CardAsesorLugar.defaultProps = {
    selected: false,
    editable: false,
    onClick: () => {},
    onEdit: () => {},
    onCancelEdit: () => {},
}

export default CardAsesorLugar;