import axios from 'axios';
const base = 'tipos';

function tareas() {
    return axios.get([base, 'tareas'].join('/'));
}
function tareasEstados() {
    return axios.get([base, 'tareas/estados'].join('/'));
}

const TiposService = {
    tareas: tareas,
    tareasEstados: tareasEstados
};
export default TiposService;
