import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import {
    MAPAS_POLIGONOS,
    MAPAS_TAREAS,
    MAPAS_PREFACTIBILIDAD,
    MAPAS_MOSTRAR_PANELES,
    MAPAS_MOSTRAR_TAREAS
} from '../actions/mapas';
const initialState = {
    poligonos: [],
    tareas: [],
    prefactibilidad: {
        color: '#FFFFFF',
        descripcion: '',
    },
    esMostrarPaneles: AuxiliarFunction.getItem(MAPAS_MOSTRAR_PANELES,true),
    esMotrarTareas: AuxiliarFunction.getItem(MAPAS_MOSTRAR_TAREAS,false),
};

const reducer = (state = initialState, {type, payload}) => {
    const {esMostrarPaneles,esMotrarTareas} = state;
    switch (type) {
        case MAPAS_POLIGONOS:
            return {
                ...state,
                poligonos: payload,
            };
        case MAPAS_TAREAS:
            return {
                ...state,
                tareas: payload,
            };
        case MAPAS_PREFACTIBILIDAD:
            return {
                ...state,
                prefactibilidad: payload,
            };
        case MAPAS_MOSTRAR_PANELES:
            return {
                ...state,
                esMostrarPaneles: !esMostrarPaneles,
            };
        case MAPAS_MOSTRAR_TAREAS:
            return {
                ...state,
                esMotrarTareas: !esMotrarTareas,
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;
