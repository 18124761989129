import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import axios from 'axios';
const base = 'tareas';
const base2 = 'tareas/mapa/disp';
function getAll(query=null,cancel=null){
    const params = AuxiliarFunction.formatQueryWithSearch(query);
    return axios.get(base,{
        params: params,
        cancelToken: cancel,
    });
}

function register(data){
    return axios.post(base,data);
}

function getById(id){
    return axios.get([base,id].join('/'));
}

function update(data){
    return axios.put([base,data.id].join('/'),data);
}

function destroy(data){
    if(AuxiliarFunction.isObject(data)){
        return axios.delete([base,data.id].join('/'),{
            params:data
        });
    }
    return axios.delete([base,data].join('/'));
}

function exportar(query){
    return axios.get([base,'exportar'].join('/'),{
        params:query,
        responseType: 'blob',
    });
}

function estados(data){
    return axios.put([base,data.id,'estados'].join('/'),data);
}

function tareasbuscarmap(query = null) {
    return axios.get(base2, {
        params: query
    });
}

const TareasService = {
    getAll: getAll,
    register: register,
    getById: getById,
    update: update,
    destroy: destroy,
    estados: estados,
    exportar: exportar,
    tareasbuscarmap:tareasbuscarmap,
};
export default TareasService;