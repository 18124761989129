import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ZonasService from '@services/zonas.service';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import ModalZonaEditar from '@app/modals/zona/ModalZonaEditar';
import usePermisos from '@app/hooks/usePermisos';
import {Button, ModalConfirm, TablePaginated} from '@app/components/index';

const ZonasPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await ZonasService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Zona',
            message: '¿Esta seguro que desea eliminar la zona?',
            onConfirm: () => {
                ZonasService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalZonaEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'Iniciales',
                accessor: 'nombreCorto'
            },
            {
                Width:100,
                Header: 'Color',
                accessor: 'color',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <div
                            className="rounded"
                            style={{
                                width: 70,
                                height: 25,
                                backgroundColor: row.original.color,
                                borderWidth: 'thin',
                            }}
                        >
                        </div>
                    );
                }
            },
            {
                Width: 15,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <DropdownButton
                            size="sm"
                            variant="success"
                            id={row.original.id}
                            title=""
                        >
                            <Dropdown.Item
                                className="d-flex justify-content-between rounded align-items-center"
                                onClick={() => onOpenEditar(row.original)}
                            >
                                <i className="fa fa-edit"></i>
                                Editar
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="d-flex justify-content-between align-items-center"
                                onClick={() => onEliminar(row.original)}
                            >
                                <i className="fa fa-trash"></i>
                                Eliminar
                            </Dropdown.Item>
                        </DropdownButton>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 col-lg-6">
                            <h3 className="my-lg-3" data-aos="fade-right">Zonas</h3>
                        </div>
                        {
                            permisos['api.zonas.store'] && (
                            <div className="offset-2 col-4 offset-lg-4 col-lg-2">
                                <Button color="primary" className="btn-block my-lg-3" data-aos="fade-up" onClick={() => onOpenEditar()}>
                                    NUEVO
                                </Button>
                            </div>
                        )}
                        <div className="col-12" data-aos="fade-right">
                            <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>

                </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ZonasPage);
