import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import axios from 'axios';
const base = 'planes';
function getAll(query=null){
    return axios.get(base,{
        params:query
    });
}

function register(data) {
    const { foto } = {...data};
    delete data.foto;
    const form = AuxiliarFunction.jsonToFormData(data);
    if(Array.isArray(foto)){
        // eslint-disable-next-line prefer-destructuring
        form.append("foto", foto[0]);
    }
    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    }); 
}


function getById(id){
    return axios.get([base,id].join('/'));
}

function update(data){
    const { foto } = {...data};
    delete data.foto;
    const form = AuxiliarFunction.jsonToFormData(data);
    if(Array.isArray(foto)){
        // eslint-disable-next-line prefer-destructuring
        form.append("foto", foto[0]);
    }
    form.append("_method", "put");
    return axios.post([base,data.id].join('/'),form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function destroy(id){
    return axios.delete([base,id].join('/'));
}

const PlanesService = {
    getAll:getAll,
    register:register,
    getById:getById,
    update:update,
    destroy:destroy,
};
export default PlanesService;