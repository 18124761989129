import {
    FLOTAS,
    FLOTAS_JUGADORES,
    FLOTAS_VEHICULOS,

    FLOTAS_AGREGAR,
    FLOTAS_MODIFICAR,
    FLOTAS_ELIMINAR,
    FLOTAS_LIMPIAR,
} from '../actions/flotas';
const initialState = {
    vehiculos: [],
    jugadores: [],
    flotas: [],
};

const reducer = (state = initialState, {type, payload}) => {
    const { flotas, vehiculos, jugadores } = state;
    let aux2;
    let aux;
    switch (type) {
        case FLOTAS_VEHICULOS:
            return {
                ...state,
                vehiculos: payload,
            };
        case FLOTAS_JUGADORES:
            return {
                ...state,
                jugadores: payload,
            };
        case FLOTAS:
            return {
                ...state,
                flotas: payload,
            };
        case FLOTAS_AGREGAR:
            aux2 = [...flotas];
            aux = aux2.findIndex(
                (fj) => fj.vehiculoId === payload.vehiculoId && fj.estado
            );
            if(aux<0){
                payload.vehiculo = vehiculos.find(ve=>ve.id === payload.vehiculoId);
                aux2.push(payload)
            }
            return {
                ...state,
                flotas: aux2,
            };
        case FLOTAS_MODIFICAR:
            aux2 = [...flotas];
            aux = aux2.findIndex(
                (fj) => fj.vehiculoId === payload.vehiculoId
            );
            if(aux>=0){
                aux2[aux]=payload;
            }
            return {
                ...state,
                flotas: aux2,
            };
        case FLOTAS_ELIMINAR:
            return {
                ...state,
                flotas: [...flotas].filter((fj) => fj.id !== payload.id),
            };
        case FLOTAS_LIMPIAR:
            return {
                ...state,
                flotas: [],
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;
