import axios from 'axios';
const base = 'nodos';
const baseN = 'kml/nodos';
function getAll(query=null){
    return axios.get(base,{
        params:query
    });
}

function register(data) {
     const form = new FormData();
     form.append('tipoEquipo',data.tipoEquipo);
     form.append('archivo',data.kmlinput[0]);
    return axios.post(baseN, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    }); 
}


function getById(id){
    return axios.get([base,id].join('/'));
}

function update(data){
    return axios.put([base,data.id].join('/'),data);
}

function destroy(id){
    return axios.delete([base,id].join('/'));
}

const NodosService = {
    getAll:getAll,
    register:register,
    getById:getById,
    update:update,
    destroy:destroy,
};
export default NodosService;