import React,{ useEffect, useMemo, useState }  from 'react';
import FechaFunction from '@app/functions/FechaFunction';
import { parseISO } from 'date-fns';
import {Button, EficienciaCostoEstadoBadge} from '@app/components/index';
import styled from 'styled-components';
import {showModal} from 'react-redux-modal-provider';
import ModalCuadrillaDisponibleLugarVer from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarVer';

const Styles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;

    .card-description{
        font-size: larger;
    }
    .card-actions{

    }
    .divInformacion{
        font-size:12px;
        color:red;
    }

    .button-selecionable{
        position: relative;
    }

    .tipo-alerta-badge{
        position: absolute;
        right: 5px;
        top: -10px;
    }
    .button-ver{
        position: absolute;
        left: 5px;
        top: 5px;
    }
`;

function CuadrillaDisponibleCard({
    isSelectable,
    item,
    onSelect,
    filtroCuadrilla,
    tipoTrabajoId,
}){
    let tipotrab;
    if(item.periodo === "TC"){
        tipotrab = `${item.tipoTrabajo}/${item.tipoTrabajoC}`
    } else if (item.periodo === "TT"){
        tipotrab = `${item.tipoTrabajoC}`;
    } else {
        tipotrab = `${item.tipoTrabajo}`;
    }
    const filtroCartel = `Tipo de Cuadrilla : ${tipotrab} Periodo: ${item.periodoNombre}`;
    const filtroCantidad =`Tareas Disponibles TM: ${item.tareasDisponiblesCantidadTM} TT: ${item.tareasDisponiblesCantidadTT}`;   
    
    const verificar = (TurnoTarea) =>{
        const value = Number(tipoTrabajoId);
        let cartelAdvertencia = false;
        switch (TurnoTarea) {
            case "TM": 
                switch (value) {
                    case 1: /* Asistencia */
                    case 6: /* Relevamiento */
                        if(!item.isTMAsistencia){
                            cartelAdvertencia = true;
                        }
                    break;
                    case 2: /* Retiro */
                        if(!item.isTMRetiro){
                            cartelAdvertencia = true;
                        }
                    break;
                    case 3: /* Instalacion */
                        if(!item.isTMInstalacion){
                            cartelAdvertencia = true;
                        }
                    break;
                    default:
                        break;
                }
            break;
            case "TT": 
                switch (value) {
                    case 1: /* Asistencia */
                    case 6: /* Relevamiento */
                        if(!item.isTTAsistencia){
                            cartelAdvertencia = true;
                        }
                    break;
                    case 2: /* Retiro */
                        if(!item.isTTRetiro){
                            cartelAdvertencia = true;
                        }
                    break;
                    case 3: /* Instalacion */
                        if(!item.isTMInstalacion){
                            cartelAdvertencia = true;
                        }
                    break;
                    default:
                        break;
                }
            break;
            default:
                break;
        }

        return cartelAdvertencia;
    }

    const esDistintoTM = useMemo(()=>verificar('TM'),[item]);
    const esDistintoTT = useMemo(()=>verificar('TT'),[item]);

    const onSelectTurno = (periodo) => {
        let cartelAdvertencia = false;
        if(filtroCuadrilla){
            if(periodo === 'TM' && !item.esDisponibleTM){
                cartelAdvertencia = true;
            }
            if(periodo === 'TT' && !item.esDisponibleTT){
                cartelAdvertencia = true;
            }
        } else {
            if(periodo === 'TM' && !item.esDisponibleTM){
                cartelAdvertencia = true;
            }
            if(periodo === 'TT' && !item.esDisponibleTT){
                cartelAdvertencia = true;
            }
        }
        
        onSelect({
            ...item,
            periodo,
            cartelAdvertencia: cartelAdvertencia
        })
    }

    const onClickVer = () => {
        showModal(ModalCuadrillaDisponibleLugarVer,{item})
    }
    return (
        <Styles className="row border border-primary redondeado shadow-sm p-3 m-3">
            <Button className="button-ver" icon="fas fa-eye" onClick={()=>onClickVer()} />
            <div className="col-12 col-lg-7">
                <>
                    <div className="text-center">
                        {filtroCartel}
                    </div>
                    <div className="mb-2 mb-lg-0 text-center">
                        {filtroCantidad}
                    </div>
                </>
                <div className="text-capitalize mb-2 mb-lg-2 text-center">
                    {FechaFunction.format(parseISO(item.fecha),"EEEE do 'de' MMMM yyyy")}
                </div>
                
            </div>
            {
                isSelectable && (
                    <div className="card-actions">
                        <Button
                            className="button-selecionable mt-1 mt-md-0"
                            size="sm"
                            theme={item.esDisponibleTM?"primary":"danger"}
                            disabled={(!item.esDisponibleTM && !filtroCuadrilla) || (!item.esDisponibleTM && filtroCuadrilla)}
                            title=""
                            onClick={() => onSelectTurno('TM')}
                        >
                            <i className={`fa ${item.esDisponibleTM?'fa-check-circle':'fa-times-circle'} mr-1`} aria-hidden="true"></i>
                            Turno Mañana
                            {
                                filtroCuadrilla && !item.esDisponibleTM && (
                                    <span className="tipo-alerta-badge badge badge-pill badge-warning text-white">
                                        <i className="fa fa-exclamation" aria-hidden="true"></i>
                                    </span>
                                )
                            }
                        </Button>
                        <Button
                            className="button-selecionable mt-1 mt-md-0"
                            size="sm"
                            theme={item.esDisponibleTT?"primary":"danger"}
                            disabled={(!item.esDisponibleTT && !filtroCuadrilla) || (!item.esDisponibleTT && filtroCuadrilla)}
                            title=""
                            onClick={() => onSelectTurno('TT')}
                        >
                            <i className={`fa ${item.esDisponibleTT?'fa-check-circle':'fa-times-circle'} mr-1`} aria-hidden="true"></i>
                            Turno Tarde
                            {
                                filtroCuadrilla && !item.esDisponibleTT && (
                                    <span className="tipo-alerta-badge badge badge-pill badge-warning text-white">
                                        <i className="fa fa-exclamation" aria-hidden="true"></i>
                                    </span>
                                )
                            }
                        </Button>
                    </div>
                )
            }
            
        </Styles>
    )
}

CuadrillaDisponibleCard.defaultProps = {
    isSelectable: false,
    tipoTarea: 'INSTALACION',
    onSelect: () => {},
};

export default CuadrillaDisponibleCard;