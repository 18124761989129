import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import InformacionTecnicaService from '@app/services/coorporativo/informacionTecnica.service';
import {Row,Container,Col} from 'react-bootstrap';
import {Button} from '@app/components/index';


function ModalEditarObservacionesTecnica({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [contactosTecnicos, setContactosTecnicos] = useState(item?.contactoTecnico === '' ? [] : JSON.parse(item?.contactoTecnico));
    const [observaciones, setObservaciones] = useState(item?.observacionTecnica)
    

    const onSubmit = () => {
        setIsLoading(true);
        const form = {
            ...item,
            observacionesTecnicas:observaciones,
            contactosTecnicos:JSON.stringify(contactosTecnicos)
        };
        InformacionTecnicaService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
    
    }



    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <p className='text-gray'>
                                    Observaciones
                                </p>
                            </div>
                            <div className='col-12'>
                                <h4>
                                    {item.contactoCorporativo.clienteNombre}
                                </h4>
                            </div>
                        </div>
                    </div>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <textarea
                                        rows="5"
                                        className="form-control"
                                        id="observacionesTecnicas"
                                        name="observacionesTecnicas"
                                        type="text"
                                        icon="fas-car"
                                        autoComplete="off"
                                        required
                                        defaultValue={observaciones}
                                        onChange={(e)=>{setObservaciones(e.target.value)}}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={()=>onSubmit()}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalEditarObservacionesTecnica.defaultProps = {
    show: false,
    item: {
        id: 0
    }
};

export default ModalEditarObservacionesTecnica;