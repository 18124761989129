import React, {useState, useEffect} from "react";
import { connect } from "react-redux";

function FlotaJugadoresPage () {

    return (
        <>
        </>
    )
}

const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(FlotaJugadoresPage);