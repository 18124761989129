import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory, Prompt} from 'react-router-dom';
import TareasService from '@services/tareas.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import { Spinner } from 'react-bootstrap';
import {Button, DateRange, ModalConfirm, ModalConfirmPromise, Select, SelectEstadoTarea, SelectLugar, SelectPersonal, SelectTipoTarea, SelectZona} from '@app/components/index';
import * as CalendarAction from '@app/store/actions/calendar';
import useGetTareas from '@app/hooks/useGetTareas';
import FechaFunction from '@app/functions/FechaFunction';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import usePreventReload from '@app/hooks/usePreventReload';
import ModalCuadrillaLugarDiponibles from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarDiponibles';
import { startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import EchoWS from '@app/utils/ws';
import usePermisos from '@app/hooks/usePermisos';


function TareasPage({
    agregarReprogramacion,
    location,
    user
}) {
    AOS.init();
    const dispatch = useDispatch();
    const {
        estadoTareaId
    } = (location && location.state) || { };
    const [t] = useTranslation();
    const permisos = usePermisos();
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(10);
    const [lugarId, setLugarId] = useState(0);
    const [search, setSearch] = useState([]);
    const [sortBy, setSortBy] = useState();
    const [tipoTareaId, setTipoTareaId] = useState('');
    const [rangeFecha, setRangeFecha] = useState();
    const [rangeFechaInsercion, setRangeFechaInsercion] = useState();
    const [createdPersonaId, setCreatedPersonaId] = useState();
    const [zonaId, setZonaId] = useState();
    const [sinCuadrillaLugar, setSinCuadrillaLugar] = useState();
    const [sinAsignar, setSinAsignar] = useState();
    const [sinFecha, setSinFecha] = useState();
    const [isLoadingExportar, setisLoadingExportar] = useState(false);
    const [channel, setChannel] = useState();
    const [esTareaId, setEsTareaId] = useState(0)
    const history = useHistory();
    const [filterDisabled, setfilterDisabled] = useState(true)
    const [isLoadingAction, setIsLoadingAction] = useState(false)

    usePreventReload(isLoadingExportar);

    const {response:{items,total},setQuery, isLoading} = useGetTareas({
        search:search,
        page:page,
        length:length,
    });

    const actualizar = () => {
        const query = {
            categoria: "NORMAL,VIP 1,VIP 2,VIP 3",
            search:search,
            page:page,
            length:length,
            createdPersonaId:createdPersonaId,
            zonaId:zonaId,
            ...(rangeFecha?.start && { fechaIni: FechaFunction.format(rangeFecha.start) }),
            ...(rangeFecha?.end && { fechaFin: FechaFunction.format(rangeFecha.end) }),
            ...(rangeFechaInsercion?.start && { fechaIniInsercion: FechaFunction.format(rangeFechaInsercion.start) }),
            ...(rangeFechaInsercion?.end && { fechaFinInsercion: FechaFunction.format(rangeFechaInsercion.end) }),
        };

        if(sortBy && sortBy.length>0){
            query.sort = sortBy[0].id;
            query.order = sortBy[0].desc ? 'desc':'asc';
        }
        if(sinCuadrillaLugar){
            query.sinCuadrillaLugar = sinCuadrillaLugar;
        }

        if(tipoTareaId){
            query.tipoTareaId = tipoTareaId;
        }
        if(esTareaId){
            query.estadoTareaId = esTareaId;
        }

        if(lugarId){
            query.lugarId = lugarId;
        }

        if(sinAsignar){
            query.sinAsignar = sinAsignar;
        }

        if(sinFecha){
            query.sinFecha = sinFecha;
        }
        setQuery(query);
    };

    const onClickAprobar = (item) => {
        showModal(ModalConfirm, {
            title: 'Aprobar Tarea',
            message: '¿Esta seguro que desea aprobar la tarea?',
            onConfirm: () => {
                TareasService.estados({
                    id:item.id,
                    estadoTareaId:1
                }).then(() => {
                    actualizar();
                });
            }
        });
    }

    const onGuardarReprogramar = (newTarea) => {
        TareasService.register(newTarea)
        .then(() => {
            actualizar();
        });
    }

    // Tarea ya creada para Reprogramar
    const onEnviarTareaReprogramar = (item) => {
        history.push({
            pathname: '/calendario',
            reprogramar: item
        });
    };

    // Nueva Tarea que viene desde CRM
    const onEnviarTarea = (item) =>{
        history.push({
            pathname: '/calendario',
            tarea: item
        });
    }
    // Pasar estado TAREA SIN FECHA
    const onSinCuadrillaFecha = (item) =>{
        showModal(ModalConfirmPromise, {
            title: 'Sin cuadrilla disponible.',
            message: '¿Esta seguro que desea continuar? Se creara un ticket con un vencimiento de 30 dias.',
            onConfirm: () => {
                setIsLoadingAction(true)
                TareasService.estados({
                    id:item.id,
                    estadoTareaId: 9
                })
                .then(() => {
                    actualizar();
                })
            }
        });
    }
    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Tarea',
            message: '¿Esta seguro que desea eliminar la tarea?',
            onConfirm: () => {
                TareasService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        const query = {
            categoria: "NORMAL,VIP 1,VIP 2,VIP 3",
            search:search,
            page:page,
            length:length,
            createdPersonaId:createdPersonaId,
            zonaId:zonaId,
            ...(rangeFecha?.start && { fechaIni: FechaFunction.format(rangeFecha.start) }),
            ...(rangeFecha?.end && { fechaFin: FechaFunction.format(rangeFecha.end) }),
            ...(rangeFechaInsercion?.start && { fechaIniInsercion: FechaFunction.format(rangeFechaInsercion.start) }),
            ...(rangeFechaInsercion?.end && { fechaFinInsercion: FechaFunction.format(rangeFechaInsercion.end) }),
        };
        if(sortBy && sortBy.length>0){
            query.sort = sortBy[0].id;
            query.order = sortBy[0].desc ? 'desc':'asc';
        }
        if(sinCuadrillaLugar){
            query.sinCuadrillaLugar = sinCuadrillaLugar;
        }

        if(tipoTareaId){
            query.tipoTareaId = tipoTareaId;
        }
        if(esTareaId){
            query.estadoTareaId = esTareaId;
        }

        if(lugarId){
            query.lugarId = lugarId;
        }

        if(sinAsignar){
            query.sinAsignar = sinAsignar;
        }
        if(sinFecha){
            query.sinFecha = sinFecha;
        }
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(TareasService.exportar(query)).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenNuevo = () => {
        history.push({
            pathname:'/tareas/nuevo',
            state: {
                backTo: '/tareas',
            }
        });
    };

    const onOpenEditar = (item) => {
        history.push({
            pathname: `/tareas/${item.id}/editar`,
            state:{
                item: item,
                backTo: '/tareas'
            }
        });
    };


    const onClickIrAlCalendario =(item) =>{
        agregarReprogramacion(item);
        history.push('/calendario');
    }


    const columns = React.useMemo(
        () => [
            {
                Header: 'Registrado',
                accessor: 'createdAt',
                Width: 80,
                Filter:false,
                Cell: ({row}) => {
                    return FechaFunction.format(row.original.createdAt,'dd/MM/yyyy');
                },
            },
            {
                Header: 'Agendado',
                accessor: 'fecha',
                Filter: false,
                Width: 80,
                Cell: ({row}) => {
                    if(row.original.fecha){
                        return FechaFunction.format(`${row.original.fecha} ${row.original.horaIni}`,'dd/MM/yyyy HH:mm');
                    }
                    return 'SIN ASIGNAR';
                },
            },
            {
                Header: 'Tipo',
                accessor: 'tipoTareaId',
                ClassName: 'm-0',
                Width: 130,
                Filter:false,
                Cell: ({row}) => {
                    return row.original.tipoTarea;
                },
            },
            {
                Header: 'Estado',
                ClassName: 'm-0',
                accessor: 'estadoTareaId',
                Width: 140,
                Filter:false,
                Cell: ({row}) => {
                    return row.original.estadoTarea;
                },
                filter: 'includes'
            },
            {
                Header: 'Cliente',
                accessor: 'clienteNombre',
                Filter:false
            },
            {
                Header: 'Documento',
                accessor:'clienteDocumento',
                Filter:false
            },
            {
                Header: 'Cliente ID',
                accessor: 'clienteId',
                Filter:false,
            },
            {
                Header: 'Perido',
                accessor:'cuadrillaDisponibleLugar.periodo',
                Width: 130,
                Filter:false,
                Cell: ({row}) => (
                    <>
                        {
                            row.original.cuadrillaDisponibleLugar !== null ? (
                                row.original.cuadrillaDisponibleLugar.periodo
                            ) :
                            ( " S/P ")
                        }
                    </>
                )
            },
            {
                Header: 'Cuadrilla',
                accessor: 'lugar.zona.nombre',
                Width: 130,
                Filter:false,
                Cell: ({row}) => row.original.lugar.zona?.nombre
            },
            {
                Header: 'Lugar',
                accessor: 'lugar.nombre',
                Width: 130,
                Filter:false,
                Cell: ({row}) =>  row.original.lugar.nombre
            },
            {
                Width: 10,
                Header: '',
                accessor: 'id',
                ClassName: 'm-0',
                Filter:false,
                Cell: ({row}) => (
                    <Dropdown size="sm">
                        <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                        >
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            { (row.original.estadoTareaId === 7 || row.original.estadoTareaId === 9) && row.original?.observaciones === "CALENDAR" && (
                                    <Dropdown.Item
                                    onClick={() => onEnviarTareaReprogramar(row.original)}
                                    >
                                        Seleccionar fecha
                                    </Dropdown.Item>
                                )
                            }
                            { row.original.estadoTareaId === 8 && (!row.original.ticketId || row.original?.observaciones === "CRM") && row.original?.observaciones !== "CALENDAR" && (
                                    <>
                                        <Dropdown.Item
                                            disabled={isLoadingAction}
                                            onClick={() => onEnviarTarea(row.original)}
                                        >
                                            Poner en calendar
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => onOpenEditar(row.original)}
                                        >
                                            Editar
                                        </Dropdown.Item>
                                        {!row.original.ticketId && (
                                            <Dropdown.Item
                                                disabled={isLoadingAction}
                                                onClick={() => onSinCuadrillaFecha(row.original)}
                                            >
                                                Sin Cuadrilla Disponible
                                            </Dropdown.Item>
                                        )}
                                    </>
                                )
                            }
                            {row.original.estadoTareaId !== 8 && (
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                            )}
                            { row.original.estadoTareaId !== 6 && row.original.estadoTareaId !== 8 ? (
                                <Dropdown.Item
                                onClick={() => onEliminar(row.original)}
                                >
                                { row.original.estadoTareaId === 48 && !permisos['api.venta'] ? (
                                    "Rechazar Tarea"
                                ):(
                                    "Eliminar"
                                )}
                                </Dropdown.Item>
                                ):""
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                )
            },
        ],
        []
    );

    useEffect(() => {
        if(page === 0){
            actualizar();
        } else {
            setPage(0);
        }
    }, [search])

    useEffect(() => {
        if(channel){
            channel
            .listen('.tareas.created',actualizar)
            .listen('.tareas.deleted',actualizar)
            .listen('.tareas.updated',actualizar);
        }
        return () => {
        }
    }, [channel])

    useEffect(()=>{
        actualizar();
        setChannel(EchoWS.private('tareas'))
        return () => {
            if(channel){
                channel.unsubscribe();
            }
        }
    },[]);

    useEffect(() => {
        actualizar();
    }, [page,length,rangeFecha,rangeFechaInsercion,createdPersonaId,zonaId,sortBy,sinAsignar,sinCuadrillaLugar,sinFecha,tipoTareaId,lugarId,esTareaId])

    return (
        <>
            <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <h3 className="mt-4 ml-1" data-aos="fade-right">
                                Tareas
                                {
                                    isLoading && <Spinner className="ml-2 mb-1 spinner-border text-primary" animation="border" />
                                }
                            </h3>
                        </div>
                        <div className="col-3" data-aos="fade-right">
                            <div className="col-form-label">Filtro por Registrado</div>
                            <div className="form-group">
                                <DateRange
                                    onChange={setRangeFechaInsercion}
                                    defaultValue={rangeFechaInsercion}
                                    isClearable
                                />
                            </div>
                        </div>
                        <div className="col-3" data-aos="fade-right">
                            <div className="form-group">
                                <div className="col-form-label">Filtro por Agendado</div>
                                <DateRange
                                    onChange={setRangeFecha}
                                    defaultValue={rangeFecha}
                                    isClearable
                                />
                            </div>
                        </div>
                        {
                            permisos['api.tareas.exportar'] && (
                            <div className="offset-1 col-lg-2" data-aos="fade-up">
                                <Button
                                    disabled={isLoadingExportar}
                                    icon={['fas','file-excel']}
                                    className="btn btn-block btn-success float-right mt-3"
                                    onClick={() => onClickExportar()}
                                >
                                    EXPORTAR
                                </Button>
                            </div>
                        )}
                        <div className="col-6 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <SelectZona
                                    id="zonaId"
                                    onChange={(opt) => {
                                        if (opt) {
                                            setZonaId(String(opt.value) || undefined);
                                        } else {
                                            setZonaId('');
                                        }
                                    }}
                                    defaultValue={zonaId}
                                    isClearable
                                    placeHolder="Zona"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                <SelectTipoTarea
                                    onChange={(opt) => {
                                        if (opt) {
                                            setTipoTareaId(String(opt.value));
                                        } else {
                                            setTipoTareaId('');
                                        }
                                    }}
                                    isClearable
                                    placeHolder="Tipo de tarea"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                    <SelectEstadoTarea
                                        value={estadoTareaId}
                                        onChange={(opt) => {
                                            if (opt) {
                                                setEsTareaId(String(opt.value));
                                            } else {
                                                setEsTareaId('');
                                            }
                                        }}
                                        isClearable
                                        placeHolder="Estado"
                                    />
                            </div>
                        </div>
                        <div className="col-12 col-md-3" data-aos="fade-right">
                            <div className="form-group">
                                    <SelectLugar
                                        onChange={(opt) => {
                                            if (opt) {
                                                setLugarId(String(opt.value));
                                            } else {
                                                setLugarId('');
                                            }
                                        }}
                                        isClearable
                                        placeHolder="Lugar"
                                    />
                            </div>
                        </div>
                    </div>
                        <div className="col-12" data-aos="fade-right">
                            <TablePaginated
                                filterDisabled={filterDisabled}
                                columns={columns}
                                data={items}
                                onSearch={setSearch}
                                onPageChange={setPage}
                                onLengthChange={setLength}
                                onSortedChange={setSortBy}
                                total={total}
                                length={length}
                                currentPage={page}
                                search={search}
                                isLoading={isLoading}
                            />
                        </div>
                </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    agregarReprogramacion: (data) =>  dispatch(CalendarAction.agregarReprogramacion(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TareasPage);
