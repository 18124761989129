import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { startOfWeek, endOfWeek } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';

const Styles = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    input{
        text-align: center;
    }

    .react-datepicker-wrapper{
        width: 100%;
    }
`;

const options = {
    weekStartsOn:1,
}

function DateRange({
    id,
    value,
    defaultValue,
    onChange,
    disabled,
    isLoading,
    isClearable,
    ...defaultProps
}){
    const [startDate, setStartDate] = useState(defaultValue?.start);
    const [endDate, setEndDate] = useState(defaultValue?.end);


    const CustomInput = React.forwardRef(
        ({value: valueField, onClick}, ref) => (
            <input
                onClick={onClick}
                className="form-control"
                value={valueField}
                ref={ref}
                readOnly
            />
        )
    );

    const onChangeStart = (newDate) => {
        setStartDate(newDate);
        onChange({
            start: newDate,
            end: endDate,
        });
    }
    const onChangeEnd = (newDate) => {
        setEndDate(newDate);
        onChange({
            start: startDate,
            end: newDate,
        });
    }

    return (
        <Styles>
            <DatePicker
                disabled={disabled}
                dateFormat="P"
                customInput={<CustomInput />}
                selected={startDate}
                onChange={date => onChangeStart(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                isClearable={isClearable}
            />
            <DatePicker
                disabled={disabled}
                dateFormat="P"
                customInput={<CustomInput />}
                selected={endDate}
                onChange={date => onChangeEnd(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                isClearable={isClearable}
            />
        </Styles>
    );
};

DateRange.defaultProps = {
    value: new Date(),
    disabled: false,
    isClearable: false,
    onChange: () => { },
};

export default DateRange;
