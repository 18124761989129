import FechaFunction from '@app/functions/FechaFunction';
import {
    addMinutes,
    format,
    parse,
    differenceInMinutes,
    startOfDay,
    parseISO,
    isDate,
} from 'date-fns';

function formattedTime(minutes, formater = 'HH:mm:ss') {
    if (Number.isNaN(minutes)) {
        return '';
    }
    const helperDate = addMinutes(startOfDay(new Date()), minutes);
    return format(helperDate, formater);
}
function timeToMinute(time) {
    if (Number.isNaN(time)) {
        return '';
    }
    const date = parse(time, 'HH:mm:ss', new Date(0));
    const minutes = differenceInMinutes(date, startOfDay(date));
    return minutes;
}
function minuteToTime(minutes) {
    if (Number.isNaN(minutes)) {
        return null;
    }
    const helperDate = addMinutes(startOfDay(new Date()), minutes);
    return format(helperDate, 'HH:mm:ss');
}

/**
 *
 * @param {string|date} date
 * @param {string} time
 */
 function isBeforeTime(date, time = '13:00:00') {
    let dateObj;
    if (typeof date === 'string') {
        dateObj = parseISO(date);
    } else {
        dateObj = date;
    }
    const limit = timeToMinute(time);
    const minutes = differenceInMinutes(date, startOfDay(date));
    return minutes < limit;
}

/**
 * Hora inicial del rango, HORAINI
 * @param {string|Date} time 
 * @returns 
 */
function getPeriodoFromTime(time) {
    if (Number.isNaN(time)) {
        return false;
    }
    if(isDate(time)){
        const isTM = isBeforeTime(time);
        if (isTM) {
            return 'TM';
        }
        return 'TT';
    }
    const date = parse(time, 'HH:mm:ss', new Date(0));
    const minutes = differenceInMinutes(date, startOfDay(date));
    let response = 'TT';
    if (minutes < 780) {
        response = 'TM';
    }
    return response;
}

function getDefaultTimeFromPeriod(periodo,defecto='TM'){
    if(periodo === 'TM'){
        return {
            start:'09:00:00',
            end:'10:00:00',
        }
    }
    if(periodo === 'TT'){
        return {
            start:'14:00:00',
            end:'15:00:00',
        }
    }
    return {
        start:'09:00:00',
        end:'10:00:00',
    }
}

function tareasTotales(cuadrillasFilter,date){
    return cuadrillasFilter.map( e => {
        return e.extendedProps;
    }).filter( e => {
        return e.fecha === FechaFunction.format(date,'yyyy-MM-dd');
    }).map(el => {
        return el.tareasDisponiblesCantidadTM + el.tareasDisponiblesCantidadTT;
    })
}

const CalendarFunction = {
    formattedTime: formattedTime,
    timeToMinute: timeToMinute,
    minuteToTime: minuteToTime,
    isBeforeTime: isBeforeTime,
    getPeriodoFromTime: getPeriodoFromTime,
    getDefaultTimeFromPeriod: getDefaultTimeFromPeriod,
    tareasTotales: tareasTotales
};
export default CalendarFunction;
