import axios from 'axios';
const base = 'flotas/lugares';
function getAll(query=null){
    return axios.get(base,{
        params:query
    });
}

function register(data){
    return axios.post(base,data);
}

function getById(id){
    return axios.get([base,id].join('/'));
}

function update(data){
    return axios.put([base,data.id].join('/'),data);
}

function destroy(id){
    return axios.delete([base,id].join('/'));
}

const FlotaLugaresService = {
    getAll:getAll,
    register:register,
    getById:getById,
    update:update,
    destroy:destroy,
};
export default FlotaLugaresService;