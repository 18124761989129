import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import { Button, ListCard } from '@app/components/index';
import CuadrillaLugaresService from '@app/services/cuadrillaLugares.service';
import FechaFunction from '@app/functions/FechaFunction';


function ModalCrearCuadrillaLugarDisponibles({
    show,
    hideModal,
    onActualizar
}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState();
    
    const {handleSubmit} = useForm({
        resolver: yupResolver(),
    });
    useEffect(() => {
        CuadrillaLugaresService.fechasDisp().then((response)=>{
            setItems(response[0]) 
        })
    }, []);

    const ClickButton = (it) =>{
        const form = {
            fecha:it.fecha,
            periodo:it.periodo,
            lugarMTD:1,
            lugarCompuesto :1,
            tipoTarea:'NORMAL',
            tipoTareaCompuesto:'NORMAL',
            cuadrillaDisponibleId: items.cuadrillaDisponibleId,
        }
        CuadrillaLugaresService.register(form)
        .then((response)=>{
            handleClose();
            onActualizar();
        })

    }
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} style={{ backdropFilter: 'blur(0.7px)' }} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                     Fechas Disponibles Para Crear Cuadrillas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {
                        items?.disponibilidad.map((it)=>{
                            return (
                                    <div className='mt-2 ml-3'>
                                        <Button
                                            key={it.fecha}
                                            theme="primary"
                                            onClick={() => ClickButton(it)}
                                            size="lg"
                                        >
                                            {FechaFunction.format(it.fecha,'dd/MM/yyyy')}
                                        </Button>
                                    </div>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalCrearCuadrillaLugarDisponibles.propTypes = {

}

export default ModalCrearCuadrillaLugarDisponibles

