import React from 'react'
import styled from 'styled-components';
import FechaFunction from '@app/functions/FechaFunction';

const Styles = styled.div`
  
`;

function ComisionCard({
    item,
    button,
}) {
    return (
                   
            <div className="col-12 shadow-sm">
                <div className="row border bg-white fila-desaparecer">
                    <div className="col-4 p-lg-3">{item.contrato.nombreCompleto}</div>
                    <div className="col-4 p-lg-3 text-capitalize">{`${FechaFunction.format(item.fechaIni,'MMMM')}-${FechaFunction.format(item.fechaFin,'MMMM')}`}</div>
                    <div className="col-3 p-lg-3">
                        $
                        {item.monto}
                    </div>
                    <div className="col-1 p-lg-3">
                            {button}
                    </div>
                    {/* <div className="col-3 p-1 p-lg-3">
                        {
                            item.estadoCuenta === 'PAGADO' && (
                                <button type="button" className="btn btn-outline-info">{item.estadoCuenta}</button>
                            )
                        }
                        {
                            item.estadoCuenta !== 'PAGADO' && (
                                <button type="button" className="btn btn-outline-warning">{item.estadoCuenta}</button>
                            )
                        }
                    </div> */}
                </div>
            </div> 
                   
    )
}

ComisionCard.defaultProps = {
    button: null,
}

export default ComisionCard
