export const CONTACTOS_CURRENT = 'CONTACTOS_CURRENT';
export const CONTACTOS_CONTRATO = 'CONTACTOS_CONTRATO';
export const CONTACTOS_CREAR_CONTRATO = 'CONTACTOS_CREAR_CONTRATO';

const iniciarContacto = (data = null) => ({
    type: CONTACTOS_CURRENT,
    payload: data
});

const iniciarContrato = (data = null) => ({
    type: CONTACTOS_CONTRATO,
    payload: data
});

const habilitarCrearContrato = (data = false) => ({
    type: CONTACTOS_CREAR_CONTRATO,
    payload: data
});

export default {
    habilitarCrearContrato,
    iniciarContacto,
    iniciarContrato,
}