import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Button, DateRange} from '@app/components/index';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import CuadrillaDisponiblesService from '@app/services/cuadrillaDisponibles.service';
import {yupResolver} from '@hookform/resolvers/yup';
import FechaFunction from '@app/functions/FechaFunction';

const schema = Yup.object({
    cantidad: Yup.number().required().min(1),
    fechas: Yup.mixed().required(),
});

function ModalCuadrillaDisponible({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const [rangeDate, setRangeDate] = useState((item.fechaIni && item.fechaFin)?{
        start: FechaFunction.parse(item.fechaIni),
        end: FechaFunction.parse(item.fechaFin),
    }:{});
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues: item,
    });

    const onSubmit = (data) => {
        const form = {
            ...data,
            fechaIni: FechaFunction.format(rangeDate.start),
            fechaFin: FechaFunction.format(rangeDate.end),
        };
        setIsLoading(true);
        if (item.id > 0) {
            form.id = item.id;
            CuadrillaDisponiblesService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            CuadrillaDisponiblesService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
        register('fechas');
    }, [register]);

    useEffect(() => {
        if(item.id>0){
            setValue('fechas',{
                start: FechaFunction.parse(item.fechaIni),
                end: FechaFunction.parse(item.fechaFin),
            })
        }
        return () => {
            
        }
    }, [])
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar lugar' : 'Registrar lugar'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="fecha">
                            Rango de fecha
                        </label>
                            <DateRange
                                id="fecha"
                                disabled={item.id>0}
                                defaultValue={rangeDate}
                                onChange={(values)=>{
                                    setRangeDate(values);
                                    setValue('fechas',values);
                                }}
                            />
                        {errors.fechas && (
                            <div className="default-invalid-feedback">
                                {errors.fechas.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="label-control" htmlFor="cantidadL">
                            Cantidad de Cuadrillas
                        </label>
                        <input
                            className={`form-control ${errors.cantidad? 'is-invalid': ''}`}
                            id="cantidad"
                            name="cantidad"
                            type="number"
                            autoComplete="off"
                            ref={register} 
                        />
                        {errors.cantidad && (
                            <div className="invalid-feedback">
                                {errors.cantidad.message}
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalCuadrillaDisponible.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number
    })
};

ModalCuadrillaDisponible.defaultProps = {
    show: false,
    item: {
        id: 0,
        cantidad: 6,
    }
};

export default ModalCuadrillaDisponible;