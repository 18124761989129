import React, {useState, useEffect} from 'react';
import {Button, InputCheck} from '@app/components/index';
import {
    agregarCuadrillaLugar,
    quitarCuadrillaLugar,
    enviarWS
} from '@app/store/actions/calendar';
import ModalCuadrillaLugarEditar from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarEditar';
import {connect} from 'react-redux';
import {showModal} from 'react-redux-modal-provider';
import ModalCuadrillaDisponibleLugarVer from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarVer';
import CuadrillaLugaresService from '@services/cuadrillaLugares.service';

function EventCuadrillaLugar({
    timeText,
    view,
    event,
    agregar,
    quitar,
    cuadrillas,
    enviar
}) {
    const {extendedProps} = event;
    const [seleccionado, setSeleccionado] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const onChangeCuadrilla = (newCheck) => {
        if (newCheck) {
            setSeleccionado(true);
            quitar(extendedProps);
        } else {
            setSeleccionado(false);
            agregar(extendedProps);
        }
    };

    const  onClickEditar = async () => {
        setIsLoading(true);
        const cuadrilla = await CuadrillaLugaresService.getById(extendedProps.id);
        setIsLoading(false);
        showModal(ModalCuadrillaLugarEditar, {
            item: cuadrilla,
            onConfirm: (data) => enviar(data)
        });
    };

    useEffect(() => {
        const {id} = extendedProps;
        if (cuadrillas.find((cl) => cl.id === id)) {
            setSeleccionado(false);
        } else {
            setSeleccionado(true);
        }
    }, [cuadrillas]);

    let turnoM = false;
    let turnoT = false;

    if(view.type === 'timeGridDay'){
        switch(extendedProps.periodo){
            case "TD":
            turnoM = `${extendedProps.descripcion.split("-",2)}`;
            turnoT = `${extendedProps.descripcion.split("-",2)}`;
            break;
            case "TT":
            turnoT = `${extendedProps.checkRepro?extendedProps.descripcion.split("-",2):extendedProps.descripcion.split("-",1)}`;
            break;
            case "TM":
            turnoM = `${extendedProps.descripcion.split("-",2)}`;
            break;
            case "TC":
            turnoM = `${extendedProps.descripcion.split("-",2)}`;
            turnoT = `${extendedProps.descripcion.split("-",2)}`;
            break;
            default:
            break;
        }
    }

    const onClickVer = () => {
        showModal(ModalCuadrillaDisponibleLugarVer,{item:extendedProps})
    }
    const total = event.extendedProps.tareasDisponiblesCantidadTM + event.extendedProps.tareasDisponiblesCantidadTT;
    const titulo = `${event.extendedProps.descripcion.split(" - T",1)} - TAREAS DISPONIBLES: ${total > 0?total:0}`;
    return (
        <div className="fc-event-main-frame">
            <div className="fc-event-title-container d-flex flex-row ">
                <div className="fc-event-title fc-sticky mr-auto text-white w-100">
                    {view.type !== 'timeGridDay' ||  extendedProps.periodo ==='ND'  ? (
                        <div 
                            className="transicion" 
                            title={titulo}
                        >
                            {titulo}
                        </div>
                    ) : (
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row border-right border-white">
                                        <div className="col-3"><div className="ciudad transicion"><div className="ciudadDiv">{turnoM || '-'}</div></div></div>
                                        <div className="col-3">{`Instalaciones: ${extendedProps.instalacionDisponibleTM}`}</div>
                                        <div className="col-3">{`Asistencias: ${extendedProps.asistenciaDisponibleTM}`}</div>
                                        <div className="col-3">{`Retiro: ${extendedProps.retiroDisponibleTM}`}</div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-3 overflow-hidden"><div className="ciudad transicion">{turnoT || '-'}</div></div>
                                        <div className="col-3">{`Instalaciones: ${extendedProps.instalacionDisponibleTT}`}</div>
                                        <div className="col-3">{`Asistencias: ${extendedProps.asistenciaDisponibleTT}`}</div>
                                        <div className="col-3">{`Retiro: ${extendedProps.retiroDisponibleTT}`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {extendedProps.periodo !== 'ND' && (
                    <InputCheck
                        className="border rounded bg-white border-white"
                        checked={seleccionado}
                        onChange={onChangeCuadrilla}
                    />
                )}
                <Button className="btn btn-white text-blue btn-xs" size="xs" icon="fas fa-eye" title="Ver" onClick={onClickVer} />
                <Button className="btn btn-white text-blue btn-xs" size="xs" icon="fas fa-edit" title="Editar" onClick={onClickEditar} isLoading={isLoading} />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cuadrillas: state.calendar.cuadrillaDisponiblesLugar
});

const mapDispatchToProps = (dispatch) => ({
    agregar: (data) => dispatch(agregarCuadrillaLugar(data)),
    quitar: (data) => dispatch(quitarCuadrillaLugar(data)),
    enviar: (data) => dispatch(enviarWS(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventCuadrillaLugar);
