import React from 'react'
import styled from 'styled-components';
import { isSameDay } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';
import { Button } from '@app/components/index';

const Styles = styled.div`
    .day-header-content-week{
        display: flex;
        justify-content: center;
    }
`;
function DayHeaderContentDay({
    date,
    cuadrillas,
    onClick,
}) {
    let total = 0;
    cuadrillas.forEach( el => {
        total += el;
        if(total < 0){
            total = 0;
        }
    });
    const tareasDisponibles = `Tareas disponibles: ${total}`
    const now = new Date();
    let title = '';
    let todayClass = '';
    if (isSameDay(date, now)) todayClass = 'calendar-week-today';
    switch (date.getDay()) {
        case 0:
            title = `Domingo`;
            break;
        case 1:
            title = `Lunes`;
            break;
        case 2:
            title = `Martes`;
            break;
        case 3:
            title = `Miércoles`;
            break;
        case 4:
            title = `Jueves`;
            break;
        case 5:
            title = `Viernes`;
            break;
        case 6:
            title = `Sábado`;
            break;
        default:
            break;
    }

    const onClickInterpolar = () => {
        onClick({
            date: date,
            type: 'interpolar',
        })
    }

    return (
        <Styles>
            <div className="day-header-content-week">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <b className='calendar-week-day-title'>
                                {title}
                            </b>
                        </div>
                        <div className="offset-2 col-8 text-dark">
                            <div className="row">
                                <div className="offset-4 col-4">
                                    <div className={todayClass}>
                                        {FechaFunction.format(date,'d')}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Button className="btn-xs" onClick={onClickInterpolar} title="Interpolar">
                                        <i className="fa fa-retweet"></i>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 rounded">
                            <p className="text-dark" title="Total de tareas disponibles">{tareasDisponibles}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Styles>
    )
}

DayHeaderContentDay.defaultProps = {
    onClick: () => {},
}

export default DayHeaderContentDay
