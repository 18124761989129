import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import LugaresService from '@services/lugares.service';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectPeriodo = ({
    id,
    value,
    zonaId,
    onChange,
    isInvalid,
    isClearable,
    isFO = false,
    defaultProps
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const [options, setOptions] = useState([
        { value: 'TM', label: 'Turno Mañana' },
        { value: 'TT', label: 'Turno Tarde' },
        { value: 'TD', label: 'Todo el Día' },
        { value: 'TC', label: 'Turno Compuesto' },
        { value: 'ND', label: 'No Disponible' }
    ]);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };
    useEffect(() => {
        if(isFO){
            setOptions([
                { value: 'TM', label: 'Turno Mañana' },
                { value: 'TT', label: 'Turno Tarde' },
            ])
        }else{
            setOptions([
                { value: 'TM', label: 'Turno Mañana' },
                { value: 'TT', label: 'Turno Tarde' },
                { value: 'TD', label: 'Todo el Día' },
                { value: 'TC', label: 'Turno Compuesto' },
                { value: 'ND', label: 'No Disponible' }
            ]);
        }
    }, [isFO]);

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }else{
            setSelectedValue(null);
        }
    }, [options,value]);

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                name={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
                isOptionDisabled={(option) => option.value === zonaId}
            />
        </Styles>
    );
};

SelectPeriodo.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectPeriodo;
