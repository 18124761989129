import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import InformacionTecnicaService from '@app/services/coorporativo/informacionTecnica.service';
import usePermisos from '@app/hooks/usePermisos';
const schema = Yup.object({

});

function ModalInformacionTecnicaEditar({show, hideModal, item, onGuardar, camposVisibles}) {
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const [contactosTecnicos, setContactosTecnicos] = useState(item?.contactoTecnico === '' ? [] : JSON.parse(item?.contactoTecnico));
    const [fechaInstalacion, setFechaInstalacion] = useState(item?.fechaInstalacion);
    const [fechaAlta, setFechaAlta] = useState(item?.fechaAlta);
    const [fechaBaja, setFechaBaja] = useState(item?.fechaBaja);
    const [latitud, setLatitud] = useState(item?.latitud);
    const [longitud, setLongitud] = useState(item?.longitud);
    const [mbSubida, setMbSubida] = useState(item?.mbBajada);
    const [mbBajada, setMbBajada] = useState(item?.mbSubida);
    const [cantidadIp, setCantidadIp] = useState(item?.cantidadIpPublica);
    const [numeroIp, setNumeroIp] = useState(item?.numeroIpPublica);
    const [tkAbiertos, setTkAbiertos] = useState(item?.tkAbierto)
    const [observaciones, setObservaciones] = useState(item?.observacionTecnica)
    const [itemAgregar, setItemAgregar] = useState()
    const [estadoAgregar, setEstadoAgregar] = useState(false)


    const {register, handleSubmit, errors, control, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });


    const onSubmit = (data) => {
        setIsLoading(true);
        const form = {
            ...data,
            id:item?.id,
            contactosTecnicos:JSON.stringify(contactosTecnicos),
        };
        InformacionTecnicaService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
    
    }

    const agregarItem = () =>{
        contactosTecnicos.push({item:itemAgregar});
        setEstadoAgregar(false)
    }

    const onEliminarItem = (itemLista) =>{
        const resultado = contactosTecnicos.filter((contacto)=> contacto.item !== itemLista )
        setContactosTecnicos(resultado)        
    }

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Editar Registro
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className={JSON.parse(camposVisibles[0].contactoTecnico_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="detalleServicio">
                                                Contactos Tecnicos
                                            </label>
                                            <button className='btn btn-xs btn-primary float-right' type='button' disabled={JSON.parse(camposVisibles[0].contactoTecnico_cv)[1].modificar === false} onClick={()=>setEstadoAgregar(!estadoAgregar)} title={!estadoAgregar ? "Agregar a la lista" : "Ocultar"}>
                                                <i className={estadoAgregar !== false ? ("fas fa-angle-up") : ("fas fa-plus")} />
                                            
                                            </button>
                                                {estadoAgregar !== false && (
                                                    <div className="input-group mb-3 mt-2" data-aos="fade-up">
                                                            <input
                                                                id="contactosTecnico"
                                                                name="contactoTecnico"
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e)=>setItemAgregar(e.target.value)}
                                                                ref={register}
                                                                placeholder="Agregar a la lista"
                                                                autoComplete="off"
                                                                disabled={JSON.parse(camposVisibles[0].contactoTecnico_cv)[1].modificar === false}
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-sm btn-primary" type="button" onClick={()=>agregarItem()}>
                                                                    Agregar
                                                                </button>
                                                            </div>
                                                    </div>
                                                )}


                                                <ul className={contactosTecnicos?.length > 0 ? ("list-group mt-2 overflow-auto") : ("d-none")}>
                                                            { contactosTecnicos?.map((contacto)=>(
                                                                <li className='list-group-item list-group-item-primary'>
                                                                    <span className="">{contacto?.item}</span>
                                                                    <button className="btn btn-sm text-danger float-right" type='button' title="Eliminar de la lista" onClick={()=>onEliminarItem(contacto?.item)}>x</button>
                                                                </li>
                                                            ))}
                                                </ul>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className='row'>
                                            <div className={JSON.parse(camposVisibles[0].fechaInstalacion_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="fechaInstalacion">
                                                            Fecha de instalación
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="fechaInstalacion"
                                                        name="fechaInstalacion"
                                                        type="date"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        ref={register}
                                                        defaultValue={fechaInstalacion}
                                                        disabled={JSON.parse(camposVisibles[0].fechaInstalacion_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].fechaAlta_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="fechaAlta">
                                                            Fecha Alta
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="fechaAlta"
                                                        name="fechaAlta"
                                                        type="date"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        ref={register}
                                                        defaultValue={fechaAlta}
                                                        disabled={JSON.parse(camposVisibles[0].fechaAlta_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].fechaBaja_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="fechaBaja">
                                                            Fecha Baja
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="fechaBaja"
                                                        name="fechaBaja"
                                                        type="date"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={fechaBaja}
                                                        disabled={JSON.parse(camposVisibles[0].fechaBaja_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].latitud_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="latitud">
                                                            Latitud
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="latitud"
                                                        name="latitud"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={latitud}
                                                        disabled={JSON.parse(camposVisibles[0].latitud_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].longitud_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="longitud">
                                                            Longitud
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="longitud"
                                                        name="longitud"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={longitud}
                                                        disabled={JSON.parse(camposVisibles[0].longitud_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].mbSubida_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="mbSubida">
                                                            MB Subida
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="mbSubida"
                                                        name="mbSubida"
                                                        type="number"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={mbSubida}
                                                        disabled={JSON.parse(camposVisibles[0].mbSubida_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].mbBajada_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="latitud">
                                                            MB Bajada
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="mbBajada"
                                                        name="mbBajada"
                                                        type="number"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={mbBajada}
                                                        disabled={JSON.parse(camposVisibles[0].mbBajada_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].cantidadIpPublica_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="cantidadIpPublicas">
                                                            Cantidad de IP Publicas
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="cantidadIpPublicas"
                                                        name="cantidadIpPublicas"
                                                        type="number"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={cantidadIp}
                                                        disabled={JSON.parse(camposVisibles[0].cantidadIpPublica_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].numeroIpPublica_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="numeroIpPublicas">
                                                            Numero de IP Publicas
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="numeroIpPublicas"
                                                        name="numeroIpPublicas"
                                                        type="number"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={numeroIp}
                                                        disabled={JSON.parse(camposVisibles[0].numeroIpPublica_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].tkAbierto_cv)[0].visible ? 'col-4' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="numeroIpPublicas">
                                                            TK Abiertos
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        id="tkAbiertos"
                                                        name="tkAbiertos"
                                                        type="number"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={tkAbiertos}
                                                        disabled={JSON.parse(camposVisibles[0].tkAbierto_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={JSON.parse(camposVisibles[0].observacionTecnica_cv)[0].visible ? 'col-8' : 'd-none'}>
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="observacionesTecnicas">
                                                            Observaciones tecnicas
                                                    </label>
                                                    <textarea
                                                        rows="1"
                                                        className="form-control"
                                                        id="observacionesTecnicas"
                                                        name="observacionesTecnicas"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                        defaultValue={observaciones}
                                                        disabled={JSON.parse(camposVisibles[0].observacionTecnica_cv)[1].modificar === false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                {
                    permisos['api.informaciones-tecnicas.update'] && (
                    <Button
                        theme="primary"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                    >
                        GUARDAR
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

ModalInformacionTecnicaEditar.defaultProps = {
    show: false,
    item: {
        id: 0
    }
};

export default ModalInformacionTecnicaEditar;