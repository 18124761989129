import axios from 'axios';
const base = 'corporativos/plataformas';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function getById(id) {
    return axios.get([base, id].join('/'));
}

function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

function exportar(){
    return axios.get([base,'exportar'].join('/'),{
        responseType: 'blob',
    });
}


const PlataformaService = {
    getAll: getAll,
    register: register,
    getById: getById,
    update: update,
    destroy: destroy,
    exportar:exportar
};
export default PlataformaService;
