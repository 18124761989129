import ContactosService from '@app/services/contactos.service';
import React, {useState, useEffect, forwardRef,} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

const InputContactNubiSearch = forwardRef(({
    id,
    field,
    value,
    onSelect,
    ...defaultProps
},ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query) => {
        setIsLoading(true);
        ContactosService.getCrmContacto(query).then((data)=>{
            setOptions(
                data.map((item) =>{
                    if(!item?.id_c){
                        const clienteNombre = item?.nombre_completo;
                        const dniCliente = item?.dni_cuit?.numero; 
                        return {
                            ...item,
                            [field]: `${clienteNombre} - ${dniCliente}`,
                            clienteNombre: clienteNombre,
                            dniCliente: dniCliente
                        }
                    }
                    const clienteNombre = `${item.apellido_c} ${item.nombre_c}`;
                    const dniCliente = item.documento_c; 
                    return {
                        ...item,
                        [field]: `${clienteNombre} - ${dniCliente}`,
                        clienteNombre: clienteNombre,
                        dniCliente: dniCliente
                    }
                })
            )
        })
        .finally(() => {
            setIsLoading(false);
        }); 
    };

    const onSelected = (newSelect) => {
        onSelect(newSelect);
    };

    useEffect(() => {
        if(ref){
            ref.current.clear()
            ref.current.setState({text:value})
        }
    }, [value])

    return (
        <AsyncTypeahead
            {...defaultProps}
            clearButton
            ref={ref}
            id={id}
            inputProps={{
                id: id
            }}
            isLoading={isLoading}
            labelKey={field}
            minLength={4}
            onSearch={handleSearch}
            onChange={onSelected}
            filterBy={() => true}
            options={options}
            placeholder="Buscar por Nombre o DNI..."
            emptyLabel="No hay coincidencias"
            promptText="Escribe para buscar..."
            renderMenuItemChildren={(option, props) => {
                return (
                    <>
                        <p>
                            <strong>{option.clienteNombre ? option.clienteNombre: ''}</strong>
                            <br />
                            {`DNI/CUIT: ${option.dniCliente} `}
                            {option?.cuenta && ` N° CUENTA: ${option.cuenta}`}
                        </p>
                    </>
                )
            }}
            useCache={false}
        />
    );
});

 InputContactNubiSearch.defaultProps = {
    field: 'nombreCompleto',
    value: '',
    onSelect: () => {},
};

export default InputContactNubiSearch;