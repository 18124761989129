import React, {useState, useEffect} from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonToggle = ({
    defaultValue,
    value,
    items,
    onChange,
    disabled,
    defaultProps,
    periodo,
    turno
}) => {
    const [radioValue, setRadioValue] = useState(value);

    const onChangeSelect = (newValue) => {
        setRadioValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    useEffect(() => {
        if(defaultValue){
            setRadioValue(defaultValue)
        }
    }, [defaultValue])

    return (
            <ButtonGroup {...defaultProps} toggle>
                {items.map((radio) => (
                    <ToggleButton
                        disabled={turno ? turno !== radio.value : null}
                        className="btn btn-secondary"
                        size="sm"
                        key={radio.value}
                        type="radio"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => onChangeSelect(e.currentTarget.value)}
                    >
                        {radio.name}
                        {radio.label}
                    </ToggleButton>
                ))}
            </ButtonGroup>
    );
};

ButtonToggle.propTypes = {
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string.isRequired
        })
    ),
    disabled: PropTypes.bool
};

ButtonToggle.defaultProps = {
    defaultValue: null,
    value: null,
    items: [],
    disabled: false,
};

export default ButtonToggle;
