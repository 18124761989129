import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import { useSelector } from 'react-redux';
import Button from '../button/Button';

function ModalConfirmPromise({id, show, hideModal, title, message, onConfirm}) {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false)
    const onClickConfirm = () => {
        setIsLoading(true)
        onConfirm();
        setTimeout(() => {
            setIsLoading(false)
            handleClose();
        }, 3000);
    };

    const isLoadingBlock = useSelector(state => state.modal.isLoadingBlock);
    const isPresent = useSelector(state => state.modal.isPresent);

    useEffect(() => {
        if(!isLoadingBlock && isPresent){
            hideModal();
        }
    }, [isLoadingBlock])

    return (
        <Modal show={show} backdrop="static" className="fade" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-gray" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button color="primary" onClick={onClickConfirm} isLoading={isLoading}>
                    CONFIRMAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalConfirmPromise.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
};

ModalConfirmPromise.defaultProps = {
    show: false,
    title: '',
    message: ''
};

export default ModalConfirmPromise;
