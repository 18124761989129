import React from 'react'
import styled from 'styled-components';
import { isSameDay } from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';
import { Button } from '@app/components/index';

const Styles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .day-cell-title{
        margin: auto;
    }
`;
function DayCellContentMonth({
    date,

    onClick,
}) {
    const now = new Date();
    let todayClass = '';
    if (isSameDay(date, now)) todayClass = 'calendar-week-today';
    const onClickVer = () =>{
        onClick({
            date: date,
            type: 'ver',
        })
    }
    return (
        <Styles>
            <h4 className={`day-cell-title ${todayClass}`}>{FechaFunction.format(date,'dd')}</h4>
            <Button title="Info del día" className="btn-xs" onClick={onClickVer}><i className="fas fa-info"></i></Button>
        </Styles>
    )
}

DayCellContentMonth.defaultProps = {
    onClick: () => {},
}

export default DayCellContentMonth
