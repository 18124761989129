import VehiculosService from '@app/services/vehiculos.service';
import JugadoresService from '@app/services/jugadores.service';
import FlotaJugadoresService from '@app/services/flotaJugadores.service';

export const FLOTAS = 'FLOTAS_LISTADO';
export const FLOTAS_VEHICULOS = 'FLOTAS_VEHICULOS';
export const FLOTAS_JUGADORES = 'FLOTAS_JUGADORES';

export const FLOTAS_AGREGAR = 'FLOTAS_AGREGAR';
export const FLOTAS_MODIFICAR = 'FLOTAS_MODIFICAR';
export const FLOTAS_ELIMINAR = 'FLOTAS_ELIMINAR';
export const FLOTAS_LIMPIAR = 'FLOTAS_LIMPIAR';

export const recuperarVehiculos = (data) => (dispatch) => {
    return VehiculosService.getAll(data).then((response) => {
        
        dispatch({
            type: FLOTAS_VEHICULOS,
            payload: response
        });
        return Promise.resolve(response);
    });
};

export const recuperarJugadores = (data) => (dispatch) => {
    return JugadoresService.getAll(data).then((response) => {
        
        dispatch({
            type: FLOTAS_JUGADORES,
            payload: response
        });
        return Promise.resolve(response);
    });
};

export const recuperarFlotas = (data) => (dispatch) => {
    return FlotaJugadoresService.getAll(data).then((response) => {
        
        dispatch({
            type: FLOTAS,
            payload: response
        });
        return Promise.resolve(response);
    });
};

export const iniciarFlotas = (data) => ({
    type: FLOTAS,
    payload: data
});

export const agregarFlota = (data) => ({
    type: FLOTAS_AGREGAR,
    payload: {
        jugadores:[],
        campo:'IDA',
        estado:true,
        ...data
    }
});

export const modificarFlota = (data) => ({
    type: FLOTAS_MODIFICAR,
    payload: data
});

export const eliminarFlota = (data) => ({
    type: FLOTAS_ELIMINAR,
    payload: data,
});

export const limpiarFlotas = () =>({
    type: FLOTAS_LIMPIAR,
})