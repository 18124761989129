import React from 'react';
import styled from 'styled-components';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

const Styles = styled.div`
    width: 100%;
`;

function TableCellVehiculo({
    row,
    isSelected,
}) {
    const { original } = row;
    if(!original){
        return (<div></div>);
    }
    const title = `${original?.marca} ${original?.modelo}`
    const subtitle = `(C:${original?.cantidad})`;

    return (
        <Styles className="row">
            <div className="table-vehiculos-row-descripcion col-8">
                {title}
                <div>
                    {subtitle}
                </div>
            </div>
            <div className="col-4 m-0 p-0">
                <div className={`table-vehiculos-row-patente grid-stack-item-content grid-stack-row list-${original.id}-vehiculo ${isSelected?'grid-stack-item-seleccionado':''}`}>
                    <div
                        id={original.id}
                        className={`grid-stack-item grid-stack-item-vehiculo vehiculo-${original.id}`}
                        gs-w="2"
                        gs-h="2"
                        gs-id={original.id}
                    >
                        <div
                            className="grid-stack-item-content"
                            aria-hidden="true"
                        >
                            <div className="grid-stack-item-nombre" mouse="true">{AuxiliarFunction.formatMatricula(original.patente)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Styles>
    )
}

TableCellVehiculo.defaultProps = {
    isSelected: false,
};

export default TableCellVehiculo
