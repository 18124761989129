import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import { Spinner, Tabs, Tab} from 'react-bootstrap';
import TabContactoResumen from '@app/pages/contactos/ver/TabContactoResumen';
import TabContactoTareas from '@app/pages/contactos/ver/TabContactoTareas';
import TabContactoContratos from '@app/pages/contactos/ver/TabContactoContratos';
import TabContactoEditar from '@app/pages/contactos/ver/TabContactoEditar';
import ContactosService from '@app/services/contactos.service';
import ContactosAction from '@app/store/actions/contactos';

function ContactoVerPage({
    location,
    contacto: contactoInicial,
    iniciarContacto,
    history
}) {
    const {id} = useParams();
    const { item } = (location && location.state) || { };
    const [key, setKey] = useState('resumen');
    const [isLoading, setIsLoading] = useState(true);
    const [contacto, setContacto] = useState(contactoInicial || {});

    const onBack = () =>{
        history.goBack();
    }

    useEffect(() => {
        if(contactoInicial){
            setContacto(contactoInicial)
        }
        return () => {

        }
    }, [contactoInicial])

    useEffect(() => {
        ContactosService.getById(id).then((response)=>{
            setContacto(response)
            iniciarContacto(response);
        })
        .finally(()=>{
            setIsLoading(false);
        })
        return () => {

        }
    }, [])

    return (
        <section className="content">
            <div className="container-fluid p-3">
                <div className="row">
                    <div className="my-lg-3 col-1">
                        <button type="button" className="btn btn-primary btn-regresar" onClick={onBack}>
                            <i className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                    <div className="offset-lg-1 col-lg-10 my-lg-3">
                        <h2>
                            {
                                contacto && (
                                    `Contacto #${id} - ${contacto.nombreCompleto??''}`
                                )
                            }
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <img
                                        className="profile-user-img img-fluid img-circle"
                                        src="/img/default-profile.png"
                                        alt="User profile"
                                    />
                                </div>
                                <h3 className="profile-username text-center">
                                    {contacto.nombreCompleto}
                                </h3>
                                <p className="text-muted text-center">
                                    {contacto.clienteId}
                                </p>
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Apellido</b>
                                        <span className="float-right">
                                            {contacto.apellido}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Nombre</b>
                                        <span className="float-right">
                                            {contacto.nombre}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Documento</b>
                                        <span className="float-right">
                                            {contacto.documento}
                                        </span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Dirección</b>
                                        <span className="float-right">
                                            {contacto.direccion}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="card">
                            {
                                !isLoading && (
                                    <Tabs
                                        id="contactosTab"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                    >
                                        <Tab eventKey="resumen" title="Resumen" tabClassName="card-body">
                                            <TabContactoResumen />
                                        </Tab>
                                        <Tab eventKey="tareas" title="Tareas" tabClassName="card-body">
                                            <TabContactoTareas />
                                        </Tab>
                                        <Tab eventKey="contratos" title="Contratos" tabClassName="card-body">
                                            <TabContactoContratos />
                                        </Tab>
                                        <Tab eventKey="editar" title="Editar" tabClassName="card-body">
                                            <TabContactoEditar />
                                        </Tab>
                                    </Tabs>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
})

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactoVerPage)
