import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import RoleService from '@services/roles.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalRol from '@app/modals/rol/ModalRol';
import {Button, ButtonToggle, ModalConfirm} from '@app/components/index';

const RolesPage = () => {
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [tipoRole, setTipoRole] = useState(1);

    const actualizar = async () => {
        const response = await RoleService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Tipo de Foto Tarea',
            message: '¿Esta seguro que desea eliminar el Tipo de Foto?',
            onConfirm: () => {
                RoleService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };
    const onOpenNuevo = (tipo) => {
        showModal(ModalRol, {onGuardar: onGuardar,Tipo:tipo});
    }
    const onOpenEditar = (item) => {
        let tipoRol = 'Puestos';
        if(item && item.personas.length >= 1){
            tipoRol = 'DNI';
        }
        showModal(ModalRol, {item: item, onGuardar: onGuardar,Tipo:tipoRol});
    };
    const tipoRol = [
        {
            name: 'ROL PERSONAL',
            value: '1',
        },
        {
            name: 'ROL PUESTOS',
            value: '2',
        }
    ];
    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'Puestos',
                accessor: 'puestos',
                Filter: false,

                Cell: ({row}) => {
                    const join = row.original?.puestos.map(per => per.nombre).join(', ');
                    const personas = row.original?.personas.map(per => per.nombre).join(', ');
                    return(
                        join + personas
                    )
                }
            },{
                width: 10,
                Header: 'Acciones',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);
    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 my-lg-3">
                        <h3>Roles</h3>
                    </div>
                    <div className="col-lg-4 text-lg-right my-lg-3">
                        <div className="row">
                            <div className="col-7">
                                <ButtonToggle
                                    name="tipoRole"
                                    items={tipoRol}
                                    checked={1}
                                    onChange={(opt) => { setTipoRole(opt); }}
                                />
                            </div>
                            <div className="col-5">
                                { tipoRole === "2" ? (
                                        <Button className="btn btn-block btn-primary" onClick={() => onOpenNuevo('Puestos')}>
                                            NUEVO ROL PUESTO
                                        </Button>
                                ):(
                                        <Button className="btn btn-block btn-primary" onClick={() => onOpenNuevo('DNI')}>
                                            NUEVO ROL PERSONAL
                                        </Button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <TablePaginated columns={columns} data={items} />
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RolesPage);
