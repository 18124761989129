import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import AdministracionService from '@app/services/coorporativo/administracion.service';
import usePermisos from '@app/hooks/usePermisos';

const schema = Yup.object({

});

function ModalAdministracionEditar({show, hideModal, item, onGuardar, camposVisibles}) {
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const [facturaId, setFacturaId] = useState(item?.facturaId)
    const [cuit, setCuit] = useState(item?.cuit);
    const [domicilioFiscal, setDomicilioFiscal] = useState(item?.domicilioFiscal);
    const [mailFacturacion, setMailFacturacion] = useState(item?.emailFacturacion);
    const [contactoAdministrativo, setContactoAdministrativo] = useState(item?.contactoAdministrativo);
    const [fecha, setFecha] = useState(item?.fecha);
    const [costoMega, setCostoMega] = useState(item?.costoMega);
    const [costoInstalacion, setCostoInstalacion] = useState(item?.costoInstalacion);
    const [contratoFirmado, setContratoFirmado] = useState(item?.contratoFirmado);
    const [contratoCertificado, setContratoCertificado] = useState(item?.contratoCertificado);
    const [agenda, setAgenda] = useState(item?.agenda);
    const [estatutoEmpresa, setEstatutoEmpresa] = useState(item?.estatutoEmpresa);
    const [designacionActaActualizada, setDesignacionActaActualizada] = useState(item?.designacionActaActualizada);
    const [contratoSocial, setContratoSocial] = useState(item?.contratoSocial);
    const [poderNombreFirma, setPoderNombreFirma] = useState(item?.poderNombreFirma);
    const [ordenCompra, setOrdenCompra] = useState(item?.ordenCompra)
    const [copiaDni, setCopiaDni] = useState(item?.copiaDni);
    const [constanciaAfip, setConstanciaAfip] = useState(item?.constanciaAfip);
    const [constanciaRenta, setConstanciaRenta] = useState(item?.constanciaRenta);
    const [pagoAlDia, setPagoAlDia] = useState(item?.pagoAlDia);
    const [observacionFacturacion, setObservacionFacturacion] = useState(item?.observacionFacturacion);


    const {register, handleSubmit, errors, control, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = () => {
        setIsLoading(true);

        const form = {
            id:item?.id,
            idFact:facturaId,
            cuit:cuit,
            domicilioFiscal:domicilioFiscal,
            mailFacturacion:mailFacturacion,
            contactoAdministrativo:contactoAdministrativo,
            fecha:fecha,
            costoMega:costoMega,
            costoInstalacion:costoInstalacion,
            contratoFirmado:contratoFirmado,
            contratoCertificado:contratoCertificado,
            agenda:agenda,
            ordenCompra:ordenCompra,
            estatutoEmpresa:estatutoEmpresa,
            designacionActaActualizada:designacionActaActualizada,
            contratoSocial:contratoSocial,
            poderNombreFirma:poderNombreFirma,
            copiaDni:copiaDni,
            constanciaAfip:constanciaAfip,
            constanciaRenta:constanciaRenta,
            pagoAlDia:pagoAlDia,
            observacionFacturacion:observacionFacturacion  
        };

        // console.log(form)
            AdministracionService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        
    };

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Editar Registro
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className={JSON.parse(camposVisibles[0].facturaId_cv)[0].visible ? "col-4" : "d-none"}>
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="idFact">
                                                    Id Fact
                                                </label>
                                                <input
                                                    className="form-control"
                                                    id="idFact"
                                                    name="idFact"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                    defaultValue={facturaId}
                                                    onChange={(e)=>setFacturaId(e.target.value)}
                                                    disabled={JSON.parse(camposVisibles[0].facturaId_cv)[1].modificar === false}
                                                />
                                            </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].cuit_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="cuit">
                                                Cuit
                                            </label>
                                            <input
                                                className="form-control"
                                                id="cuit"
                                                name="cuit"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                ref={register}
                                                defaultValue={cuit}
                                                onChange={(e)=>setCuit(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].cuit_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].domicilioFiscal_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="domicilioFiscal">
                                                Domicilio Fiscal
                                            </label>
                                            <input
                                                className="form-control"
                                                id="domicilioFiscal"
                                                name="domicilioFiscal"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                required
                                                ref={register}
                                                defaultValue={domicilioFiscal}
                                                onChange={(e)=>setDomicilioFiscal(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].domicilioFiscal_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].emailFacturacion_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="domicilioFiscal">
                                                Mail Facturación
                                            </label>
                                            <input
                                                className="form-control"
                                                id="mailFacturacion"
                                                name="mailFacturacion"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                required
                                                ref={register}
                                                defaultValue={mailFacturacion}
                                                onChange={(e)=>setMailFacturacion(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].emailFacturacion_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].contactoAdministrativo_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="contactosAdministrativos">
                                                Contactos Administrativos
                                            </label>
                                            <input
                                                className="form-control"
                                                id="contactosAdministrativos"
                                                name="contactosAdministrativos"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                ref={register}
                                                defaultValue={contactoAdministrativo}
                                                onChange={(e)=>setContactoAdministrativo(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].contactoAdministrativo_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].fecha_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="fechaFinContrato">
                                                Fecha Fin de Contrato
                                            </label>
                                            <input
                                                className="form-control"
                                                id="fechaFinContrato"
                                                name="fechaFinContrato"
                                                type="date"
                                                icon="fas-car"
                                                autoComplete="off"
                                                ref={register}
                                                defaultValue={fecha}
                                                onChange={(e)=>setFecha(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].fecha_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className={JSON.parse(camposVisibles[0].costoMega_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="costoPorMega">
                                                Costo por Mega
                                            </label>
                                            <input
                                                className="form-control"
                                                id="costoPorMega"
                                                name="costoPorMega"
                                                type="number"
                                                icon="fas-car"
                                                autoComplete="off"
                                                defaultValue={costoMega}
                                                onChange={(e)=>setCostoMega(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].costoMega_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].costoInstalacion_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="costoPorInstalacion">
                                                Costo por Instalación
                                            </label>
                                            <input
                                                className="form-control"
                                                id="costoPorInstalacion"
                                                name="costoPorInstalacion"
                                                type="number"
                                                icon="fas-car"
                                                autoComplete="off"
                                                ref={register}
                                                defaultValue={costoInstalacion}
                                                onChange={(e)=>setCostoInstalacion(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].costoInstalacion_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].contratoFirmado_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="contratoFirmado">
                                                Contrato firmado
                                            </label>
                                            <select
                                                className="form-control"
                                                id="contratoFirmado"
                                                name="contratoFirmado"
                                                defaultValue={contratoFirmado}
                                                ref={register}
                                                onChange={(e)=>setContratoFirmado(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].contratoFirmado_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].ordenCompra_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="ordenCompra">
                                                    Orden de Compra
                                            </label>
                                            <input
                                                className="form-control"
                                                id="ordenCompra"
                                                name="ordenCompra"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                ref={register}
                                                defaultValue={ordenCompra}
                                                onChange={(e)=>setOrdenCompra(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].ordenCompra_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].contratoCertificado_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="contratoFirmado">
                                                Contrato Certificado
                                            </label>
                                            <select
                                                className="form-control"
                                                id="contratoCertificado"
                                                name="contratoCertificado"
                                                ref={register}
                                                defaultValue={contratoCertificado}
                                                onChange={(e)=>setContratoCertificado(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].contratoCertificado_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].agenda_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="agenda">
                                                Addenda
                                            </label>
                                            <select
                                                className="form-control"
                                                id="agenda"
                                                name="agenda"
                                                ref={register}
                                                defaultValue={agenda}
                                                onChange={(e)=>setAgenda(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].agenda_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].estatutoEmpresa_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="estatutoEmpresa">
                                                Estatuto de la empresa
                                            </label>
                                            <select
                                                className="form-control"
                                                id="estatutoEmpresa"
                                                name="estatutoEmpresa"
                                                ref={register}
                                                defaultValue={estatutoEmpresa}
                                                onChange={(opt)=>setEstatutoEmpresa(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].estatutoEmpresa_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].designacionActaActualizada_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="designacion">
                                                Designación de acta actualizada
                                            </label>
                                            <select
                                                className="form-control"
                                                id="designacion"
                                                name="designacion"
                                                ref={register}
                                                defaultValue={designacionActaActualizada}
                                                onChange={(opt)=>setDesignacionActaActualizada(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].designacionActaActualizada_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].contratoSocial_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="contratoSocial">
                                                Contrato Social
                                            </label>
                                            <select
                                                className="form-control"
                                                id="contratoSocial"
                                                name="contratoSocial"
                                                ref={register}
                                                defaultValue={contratoSocial}
                                                onChange={(opt)=>setContratoSocial(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].contratoSocial_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].poderNombreFirma_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="poder">
                                                Poder a nombre del que firma
                                            </label>
                                            <select
                                                className="form-control"
                                                id="poder"
                                                name="poder"
                                                ref={register}
                                                defaultValue={poderNombreFirma}
                                                onChange={(opt)=>setPoderNombreFirma(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].poderNombreFirma_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].copiaDni_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="copiaDni">
                                                Copia DNI
                                            </label>
                                            <select
                                                className="form-control"
                                                id="copiaDni"
                                                name="copiaDni"
                                                ref={register}
                                                defaultValue={copiaDni}
                                                onChange={(opt)=>setCopiaDni(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].copiaDni_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].constanciaAfip_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="copiaDni">
                                                Constancia de AFIP
                                            </label>
                                            <select
                                                className="form-control"
                                                id="constanciaAfip"
                                                name="constanciaAfip"
                                                ref={register}
                                                defaultValue={constanciaAfip}
                                                onChange={(opt)=>setConstanciaAfip(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].constanciaAfip_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].constanciaRenta_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="constanciaRenta">
                                                Constancia de Rentas
                                            </label>
                                            <select
                                                className="form-control"
                                                id="constanciaRenta"
                                                name="constanciaRenta"
                                                ref={register}
                                                defaultValue={constanciaRenta}
                                                onChange={(opt)=>setConstanciaRenta(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].constanciaRenta_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].pagoAlDia_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="pagosDia">
                                                Pagos al día
                                            </label>
                                            <select
                                                className="form-control"
                                                id="pagosDia"
                                                name="pagosDia"
                                                ref={register}
                                                defaultValue={pagoAlDia}
                                                onChange={(opt)=>setPagoAlDia(opt.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].pagoAlDia_cv)[1].modificar === false}
                                            >
                                                <option value="NO">NO</option>
                                                <option value="SI">SI</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={JSON.parse(camposVisibles[0].observacionFacturacion_cv)[0].visible ? "col-4" : "d-none"}>
                                        <div className="form-group">
                                            <label className="label-control" htmlFor="observaciones">
                                                    Observaciones de facturación
                                            </label>
                                            <input
                                                className="form-control"
                                                id="observaciones"
                                                name="observaciones"
                                                type="text"
                                                icon="fas-car"
                                                autoComplete="off"
                                                required
                                                ref={register}
                                                defaultValue={observacionFacturacion}
                                                onChange={(e)=>setObservacionFacturacion(e.target.value)}
                                                disabled={JSON.parse(camposVisibles[0].observacionFacturacion_cv)[1].modificar === false}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                {
                    permisos['api.administraciones.update'] && (
                    <Button
                        theme="primary"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                    >
                        GUARDAR
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

ModalAdministracionEditar.defaultProps = {
    show: false,
    item: {
        id: 0
    }
};

export default ModalAdministracionEditar;