import axios from 'axios';
const base = 'recibos/comisiones';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function getById(id) {
    return axios.get([base, id].join('/'));
}

function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const RecibosComisionesService = {
    getAll,
    register,
    getById,
    update,
    destroy
};
export default RecibosComisionesService;
