import React, {useEffect, useState} from 'react';
import { Button,DateSingle, ComisionCard, SelectPersonal } from '@app/components/index';
import {showModal} from 'react-redux-modal-provider';
import {useForm, Controller} from 'react-hook-form';
import {Link, useHistory, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalReciboAgregar from '@app/modals/recibo/ModalReciboAgregar';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setDetalles,deleteDetalle } from '@app/store/slices/recibos.slice';
import RecibosComisionesService from '@app/services/recibosComisione.service';

const schema = Yup.object({

});

export const ReciboNuevo = (props) => {
    const history = useHistory();

    const dispatch = useDispatch()
    const detalles = useSelector(state => state.recibos.detalles)

    const {register, handleSubmit, errors, setValue, watch, control} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            personaId: 0,
        }
    });

    const personaId = watch('personaId');

    const onClickEliminar = (item) => {
        dispatch(deleteDetalle(item))
    }

    const onOpenAgregar = () => {
        if(personaId>0){
            showModal(ModalReciboAgregar, {
                item: {
                    personaId:personaId,
                },
            });
        }

    };

    const onSubmit = (data) => {
        const form = {
            ...data,
            detalles: detalles,
        }

        RecibosComisionesService.register(form).then((response)=>{
            history.push('/recibos');
        })
    }

    useEffect(() => {
        register('fecha');
        register('personaId');
    }, [register]);



    return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        {/* ROTULO */}
                        <div className="col-12 mt-3">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-6 mt-lg-1">
                                    <div className="row">
                                        <div className="col-9 col-md-10 col-lg-12">
                                            <h3>Recibo</h3>
                                            {/* <button type="button" onClick={document.location.reload()}>Actualizar página</button> */}
                                        </div>
                                        <div className="col-3 col-md-2 col-lg-3">
                                            <Button
                                                className="mt-lg-5"
                                                theme="info"
                                                disabled={personaId === 0}
                                                onClick={onOpenAgregar}
                                                block
                                            >
                                                Agregar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-md-6 offset-lg-0 col-md-6 col-lg-6">
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label" htmlFor="fecha">
                                            Fecha
                                        </label>

                                        <div className="offset-sm-1 col-sm-9">
                                            <DateSingle 
                                                id="fecha" 
                                                isInvalid={errors.fecha}
                                                className="form-control"
                                                onChange={(value)=>setValue('fecha',value)}
                                            />
                                            {errors.fecha ? (
                                                <div className="default-invalid-feedback">
                                                    {errors.fecha?.message}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label" htmlFor="personaId">
                                            Asesor
                                        </label>
                                        <div className="offset-sm-1 col-sm-9">
                                            <SelectPersonal
                                                filtroAsesor
                                                id="personaId"
                                                isInvalid={errors.personaId}
                                                onChange={(option)=>setValue('personaId',Number(option.value))}
                                            />
                                            {errors.personaId ? (
                                                <div className="default-invalid-feedback">
                                                    {errors.personaId?.message}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-12 col-sm-2 col-form-label" htmlFor="numero">
                                            Recibo
                                        </label>
                                        <div className="col-12 offset-sm-1 col-sm-9">
                                            <input 
                                                className={`form-control ${errors.numero? 'is-invalid': ''}`}
                                                type="text"
                                                id="numero"
                                                name="numero"
                                                ref={register}
                                            />
                                            {errors.numero ? (
                                                <div className="invalid-feedback">
                                                    {errors.numero?.message}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* FIN ROTULO */}
                {/* TABLA */}
                <div className="col-12 text-center">
                    <div className="row">
                        <div className="col-12">
                            <div className="row bg-gray text-white">
                                <div className="col-4">Cliente</div>
                                <div className="col-4">Periodo</div>
                                <div className="col-3">Comision</div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                        {
                        detalles.map((item)=>{
                            return (
                                <ComisionCard
                                    key={item.id}
                                    item={item}
                                    button={<button className="btn btn-xs boton-desaparecer btn-outline-danger" type="button" onClick={()=>onClickEliminar(item)}>x</button>}  
                                />
                            )
                        })
                        }
                    </div>
                </div>
                <div className="col-12 ml-lg-2 my-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-12 order-3 order-md-1 col-lg-6 order-lg-5">
                                <div className="form-group">
                                    <label htmlFor="observaciones">Observaciones</label>
                                    <textarea
                                        className={`form-control ${errors.observaciones? 'is-invalid': ''}`}
                                        id="observaciones"
                                        name="observaciones"
                                        rows="2"
                                        ref={register}
                                    >
                                    </textarea>
                                    {errors.observaciones ? (
                                        <div className="invalid-feedback">
                                            {errors.observaciones?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="offset-5 col-3 offset-md-6 col-md-3 order-0 offset-lg-8 col-lg-1 mt-1 order-lg-1">
                                <h5>Subtotal</h5>
                            </div>
                            <div className="col-3 col-md-1 col-lg-1 order-0 text-center mt-1 order-lg-2">
                                <h5>
                                    {/* $
                                    {console.log(detalles.reduce((accumulator, item) => accumulator + Number(item.monto),0))} */}
                                </h5>
                            </div>
                            <div className="offset-md-4 col-md-4 col-lg-2 order-4 order-md-3 order-lg-6 mt-lg-5">
                            <Button 
                                theme="secondary"
                                block
                                disabled={detalles.length === 0}
                                onClick={handleSubmit(onSubmit)}
                                size="xl"
                            >
                                Generar
                            </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ReciboNuevo)
