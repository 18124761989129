import React from 'react';
import styled from 'styled-components';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { ReactSortable } from "react-sortablejs";

const Styles = styled.div`
    width: 100%;
`;

function itemListJugador(colaborador){
    return (
        <li className="btn btn-jugadores" id={colaborador.dni} data-nombre={colaborador.nombre}>
            <div className="list-group-item-title">{AuxiliarFunction.primeraLetraNombre(colaborador.nombre)}</div>
            <div className="list-group-item-subtitle"></div>
        </li>
    );
}

function TableCellJugador({
    row,
    isSelected,
}) {
    const { original } = row;
    const subtitle = `DNI:${original.dni}`;
    return (
        <Styles className="row">
            <div className="table-jugadores-row-descripcion col-8">
                {original?.nombreCompleto}
                <div>
                    {subtitle}
                </div>
            </div>
            <div className="col-4 m-0 p-0">
                <ReactSortable 
                    disabled={isSelected}
                    id={original.dni}
                    tag='ul'
                    className={`list-group ${isSelected?'table-jugador-row-seleccionado':''}`}
                    group={{
                        name: 'shared',
                        put: false,
                        revertClone: true,
                    }}
                    animation={150}
                    list={[original]} 
                    setList={()=>{}}
                >
                    {itemListJugador(original,isSelected)}
                </ReactSortable>
            </div>
            
        </Styles>
    )
}

TableCellJugador.defaultProps = {
    isSelected: false,
};

export default TableCellJugador
