import React, {useEffect, useState} from 'react';
import { Button,DateSingle, ComisionCard, SelectPersonal,TablePaginated } from '@app/components/index';
import {showModal} from 'react-redux-modal-provider';
import {useForm, Controller} from 'react-hook-form';
import {Link, useHistory, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect, useDispatch, useSelector } from 'react-redux';

const schema = Yup.object({

});

export const ComisionesGeneralesPage = (props) => {
    const history = useHistory();
    const [items, setItems] = useState([]);
    const dispatch = useDispatch()

    const {register, handleSubmit, errors, setValue, watch, control} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            personaId: 0,
        }
    });

    // const actualizar = async () => {
    //     const response = await ComisionesGeneralesService.getAll();
    //     setItems(response);
    // };


    const personaId = watch('personaId');


    // const onSubmit = (data) => {
    //     const form = {
    //         ...data,
    //         detalles: detalles,
    //     }

    //     console.log(detalles);
    //     RecibosComisionesService.register(form).then((response)=>{
    //         history.push('/recibos');
    //     })
    // }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Fecha Inicio',
                accessor: 'fecha'
            },
            {
                Header: 'Cliente',
                accessor: 'cliente'
            },
            {
                Header: 'Localidad',
                accessor: 'localidad'
            },
            {
                Header: 'Pago',
                accessor: 'pago'
            },
            {
                Header: 'Mes 1',
                accessor: 'mes 1'
            },
            {
                Header: 'Mes 2',
                accessor: 'mes 2'
            },
            {
                Header: 'Mes 3',
                accessor: 'mes 3'
            },
            {
                Header: 'Mes 4',
                accessor: 'mes 4'
            },
            {
                Header: 'Mes 5',
                accessor: 'mes 5'
            },
            {
                Header: 'Mes 6',
                accessor: 'mes 6'
            },
            {
                Header: 'Mes 7',
                accessor: 'mes 7'
            },
            {
                Header: 'Mes 8',
                accessor: 'mes 8'
            },
            {
                Header: 'Mes 9',
                accessor: 'mes 9'
            },
        ],
        []
    );

    useEffect(() => {

    }, []);



    return (
        <div className="container-fluid">
            <div className="row mt-lg-3">
                <div className="col-lg-4">
                    <h3>Comisiones</h3>
                </div>
                <div className="col-4">
                    <label className="" htmlFor="personaId">
                        Asesor
                    </label>
                        <SelectPersonal
                            filtroAsesor
                            id="personaId"
                            isInvalid={errors.personaId}
                            onChange={(option)=>setValue('personaId',Number(option.value))}
                        />
                        {errors.personaId ? (
                            <div className="default-invalid-feedback">
                                {errors.personaId?.message}
                            </div>
                        ) : null}
                </div>
                <div className="offset-3 col-1">
                    <button className="btn btn-block btn-success" type="button">Excel</button>
                </div>

                    {/* TABLA */}

                <div className="col-12">

                <TablePaginated columns={columns} data={items} />


                </div>
            </div>
        </div>
    )
}


export default ComisionesGeneralesPage;
