import React, {useState, useEffect, useLayoutEffect, forwardRef, useRef, useCallback} from "react";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import {showModal} from 'react-redux-modal-provider';

import AuxiliarFunction from "@app/functions/AuxiliarFunction";
import { Button, ModalSelect, TareaCard } from "@app/components/index";
import * as CuadrillasAction from "@app/store/actions/cuardillas";
import { getDay, parseISO } from "date-fns";
import FechaFunction from "@app/functions/FechaFunction";
import ModalTareaFechaEditar from "@app/modals/tarea/ModalTareaFechaEditar";
import { Collapse } from "react-bootstrap";
import styled, { css } from 'styled-components';
import ModalFlotaJugadorVer from "@app/modals/flota-jugador/ModalFlotaJugadorVer";

const Styles = styled.div`

    .data-stack-bottom{
        display: flex;
        width: 100%;
        position: absolute;
        bottom: 0px;
        justify-content: space-between;
        padding-left: 0.5rem;   
        padding-right: 0.5rem;   
        padding-top: 0.25rem;   
        padding-bottom: 0.25rem;   
    }
`;


const WidgetTarea = (({
    item,
    grid,
    widget,
    tareas,
    
    modificar,
    onChange,
    onChangeWidget,
    onRemove,

    ...defaultProps
})=>{
    const sortableRef = useRef();
    const [tarea, setTarea] = useState(item);
    const [innerList, setInnerList] = useState(item.flotas || []);
    const [showCard, setShowCard] = useState(false);

    const onChangeList = (list) => {
        onChange({
            ...item,
            flotas: list,
        });
        setInnerList(list);
    }

    const existeFlotaEnTarea = (flotaId)=>{
        const existe = innerList.find(fj=>fj.id === flotaId);
        return Boolean(existe);
    }

    const onCheckIntegrity = (to,from,el) => {
        if(to.el.id === from.el.id){
            return true;
        } 
        const flotaId = AuxiliarFunction.toIntegerNumber(el.id);
        if(flotaId>0){
            const existe = existeFlotaEnTarea(flotaId);
            if(existe){
                return false;
            }
        } 
        return to.el.children.length < 2;
    }

    const onMouseDown = () => {
        if(grid && widget){
            grid.movable(widget,false);
        }
    }

    const onMouseLeave = () => {
        if(grid && widget){
            grid.movable(widget,true);
        }
    }

    const onClickDescartar = (id) => {
        grid.movable(widget,true);
        const newList = innerList.filter(jg=>jg.id !==id);
        setInnerList(newList);
        onChange({
            ...item,
            flotas: null,
        });
    }

    const onEditar = (newItem) => {
        newItem.weekDay = getDay(parseISO(newItem.fecha));
        if(item.weekDay === newItem.weekDay){
            setTarea(newItem);
        }
        modificar(newItem);
    }

    const onClickEditar = () => {
        const {start, end} = FechaFunction.rangeWeek(parseISO(item.fecha));
        showModal(ModalTareaFechaEditar,{
            item: tarea,
            defaultDate: start,
            minDate: start,
            maxDate: end,
            onGuardar: onEditar,
        });
    }

    const onClickVerFlota = (flota) => {
        showModal(ModalFlotaJugadorVer,{item:flota})
    }

    useEffect(() => {
        const height = innerList.length + 2 + (showCard?1:0);
        if(grid && widget){
            const newOptions = {h:height,minH:height};
            onChangeWidget(newOptions);
            grid.update(widget,newOptions);
        }
    }, [widget,innerList,showCard]);

    useEffect(() => {
        if(sortableRef && sortableRef.current && sortableRef.current.sortable){
            sortableRef.current.sortable.option('group',{
                name:'shared',
                put:onCheckIntegrity
            });
        }
    }, [item,tareas]);

    return (
        <Styles>
            <div className="grid-stack-item-nombre" onClick={()=>setShowCard(!showCard)} aria-hidden="true">
                {item.lugar?.nombre}
            </div>
            <Collapse
                in={showCard}
            >
                <div className="p-0">
                    <TareaCard item={tarea} hideClickMap />
                </div>
            </Collapse>
            
            <ReactSortable
                ref={sortableRef}
                id={`flotas-${item.id}`}
                tag='div'
                className='list-group'
                group={{
                    name:'shared',
                    put:onCheckIntegrity
                }}
                emptyInsertThreshold={40}
                animation={150}
                ghostClass="grid-stack-item-content"
                list={innerList}
                setList={onChangeList}
                onAdd={(ev)=>{
                    setShowCard(false)
                }}
            >
                {innerList.map((flota,index) => {
                    const { id, vehiculoPatente } = flota;
                    return(
                        <div
                            className="list-group-item"
                            id={id}
                            key={id}
                            onMouseDown={onMouseDown}
                            onMouseLeave={onMouseLeave}
                            role="presentation"
                        >
                            <div className="list-group-item-title">{AuxiliarFunction.formatMatricula(vehiculoPatente)}</div>
                            <div className="list-group-item-subtitle">
                                <Button
                                    className="float-right" 
                                    size="xs" 
                                    theme="danger" 
                                    icon="fa fa-trash fa-xs" 
                                    onClick={()=>onClickDescartar(id)}
                                />
                                <Button
                                    className="float-right" 
                                    size="xs"  
                                    icon="fa fa-eye fa-xs" 
                                    onClick={()=>onClickVerFlota(flota)}
                                />
                            </div>
                        </div>
                    )}
                )}
            </ReactSortable>
            <div className="data-stack-bottom">
                <Button tooltip="Editar" size="xs" theme="info" icon="fa fa-edit fa-xs" onClick={onClickEditar} />
            </div>
        </Styles>
    );
})

WidgetTarea.defaultProps = {
    item:{
        id:0,
        lugar:{
            nombre: '',
        },
    },
    onChange: () => {},
    onRemove: () => {},
};

const mapStateToProps = (state) => ({
    tareas: state.cuadrillas.tareas,
});

const mapDispatchToProps = (dispatch) => ({
    modificar: (data) => dispatch(CuadrillasAction.modificarTarea(data)),
    
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetTarea);