import React from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interaction from '@fullcalendar/interaction';
import styled from 'styled-components';
import {isSameDay} from 'date-fns';
import FechaFunction from '@app/functions/FechaFunction';
import { Button } from '@app/components/index';
import DayHeaderContentWeek from '@app/components/calendar/DayHeaderContentWeek';
import DayHeaderContentDay from '@app/components/calendar/DayHeaderContentDay';
import DayCellContentMonth from '@app/components/calendar/DayCellContentMonth';
import CalendarFunction from '@app/functions/CalendarFunction';

const esLocale = {
    code: 'es',
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
        prev: 'Ant',
        next: 'Sig',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        list: 'Agenda',
    },
    weekText: 'Sm',
    allDayText: 'Todo el día',
    moreLinkText: 'más',
    noEventsText: 'No hay eventos para mostrar',
};

const Styles = styled.div`
    .fc-daygrid-day-number{
        width: 100%;
    }
    .calendar-week-today{
        color:#39C0ED !important;
        border-radius: 40%;

    }
`;

function Calendar({
    innerRef,
    initialDate,
    initialView,
    events,
    onClickHeader,
    ...defaultProps
}) {
    const calendarioHeaderDayGridMonth = (value) => {
        return `<b class='calendar-week-day-title'>${value}</b>`;
    }

    const cuadrillasFilter = events.filter( (el) => {
        return el.groupId === "GRP-CUADRILLADISPONIBLELUGAR";
    })

    const onClickDayHeader = (action) => {
        onClickHeader(action)
    }
    
    const views = {
        timeGridWeek: {
            dayHeaderContent: ({date}) => {
                const cuadrillaSemana = CalendarFunction.tareasTotales(cuadrillasFilter,date);
                return (
                    <DayHeaderContentWeek date={date} onClick={onClickDayHeader} cuadrillas={cuadrillaSemana} />
                );
            }
        },
        timeGridDay: {
            dayHeaderContent: ({date}) => {
                const cuadrillaDia = CalendarFunction.tareasTotales(cuadrillasFilter,date);
                return (
                    <DayHeaderContentDay date={date} onClick={onClickDayHeader} cuadrillas={cuadrillaDia} />
                );
            }
        },
        dayGridMonth: {
            dayMaxEventRows: 15,
            dayHeaderContent: ({date}) => {
                let title = '';
                switch (date.getDay()) {
                    case 0:
                        title = calendarioHeaderDayGridMonth('Domingo');
                        break;
                    case 1:
                        title = calendarioHeaderDayGridMonth('Lunes');
                        break;
                    case 2:
                        title = calendarioHeaderDayGridMonth('Martes');
                        break;
                    case 3:
                        title = calendarioHeaderDayGridMonth('Miércoles');
                        break;
                    case 4:
                        title = calendarioHeaderDayGridMonth('Jueves');
                        break;
                    case 5:
                        title = calendarioHeaderDayGridMonth('Viernes');
                        break;
                    case 6:
                        title = calendarioHeaderDayGridMonth('Sábado');
                        break;
                    default:
                        break;
                }
                return {
                    html: title
                };
            },
            dayCellContent: ({date}) => {
                return (
                    <DayCellContentMonth date={date} onClick={onClickDayHeader} />
                );
            }
        }
    };
    return (
        <Styles>
            <FullCalendar
                className="vh-100"
                ref={innerRef}
                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interaction
                ]}
                views={views}
                allDayContent={{html: '&nbsp;'}}
                headerToolbar={{
                    left: null,
                    center: null,
                    right: null
                }}
                slotMinTime='08:00:00'
                slotMaxTime='18:00:00'
                eventOrder='start,-duration,allDay,id'
                hiddenDays={[0]}
                firstDay={1}
                locales={[ esLocale ]}
                locale='es'
                events={events}
                initialDate={initialDate}
                initialView={initialView}
                {...defaultProps}
            />
        </Styles>
    )
}

Calendar.propTypes = {

}
Calendar.defaultProps = {
    initialView:'timeGridWeek',
    onClickHeader: () => {},
}

export default Calendar

