import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import TareaForm1 from '@app/pages/contactos/TareaForm1';
import TareaForm2 from '@app/pages/contactos/TareaForm2';
import FormPaso2 from '@app/pages/contactos/FormPaso2';
import FormPaso3 from '@app/pages/contactos/FormPaso3';
import FormPaso4 from '@app/pages/contactos/FormPaso4';
import { Wizard } from '@app/components/index';
import ContactosAction from '@app/store/actions/contactos';

const ContactoExistentePage = ({
    esHabilitadoCrearContrato,
    iniciarContacto,
    iniciarContrato,
    location,
}) => {
    const [t] = useTranslation();

    const { backTo, item } = (location && location.state) || { };

    const options = [
        {
            title: 'Contacto',
            subtitle: 'Datos del cliente',
        },
        {
            title: 'Motivo',
            subtitle: 'Informacion de la Tarea',
        },
        {
            title: 'Pactar Fecha',
            subtitle: 'Seleccionar Fecha y Turno',
        },
    ];

    useEffect(() => {
        if(item){
            iniciarContacto(item);
        } else {
            iniciarContacto(null);
        }
    }, []);

    return (
                <div className="container-fluid mt-3 mt-md-3 mt-lg-0">
                    <div className="row">
                        <div className="col-12">
                            <Wizard options={options}>
                                <TareaForm1 item={item} />
                                <TareaForm2 />
                                <FormPaso4 />
                            </Wizard>
                        </div>
                    </div>
                </div>
    );
};

const mapStateToProps = (state) => ({
    contactoExistente: state.contactos.current,
    esHabilitadoCrearContrato: state.contactos.esHabilitadoCrearContrato,
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (data) => dispatch(ContactosAction.iniciarContacto(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactoExistentePage);
