import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LogCard, LogTareaCard } from '@app/components/index';
import LogService from '@services/log.service';

function LogPage() {
    AOS.init();
    const [items, setItems] = useState([]);
    const [itemsTarea, setItemsTarea] = useState([]);

    useEffect(() => {
        /** AL SER INSTANCIADO */
        LogService.getAll({length:15}).then((response)=>{
            setItems(response);

        })
        LogService.getAllTareas({length:15}).then((response)=>{
            setItemsTarea(response);
        })
        return () => {
            /** AL SER ELIMINADO */
        }
    }, [])

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 offset-lg-1 col-lg-5 text-center my-2">
                        <h3 className="" data-aos="fade-up">General</h3>
                    </div>
                    <div className="col-6 col-lg-5 text-center my-2">
                        <h3 className="" data-aos="fade-up">Tareas</h3>
                    </div>

                    <div className="col-6 offset-lg-1 col-lg-5">
                        {
                            items.map((item)=>{
                                return <LogCard item={item} key={item.id} />
                            })
                        }
                    </div>
                    <div className="col-6 col-lg-5">
                        {
                            itemsTarea.map((item)=>{
                                return <LogTareaCard item={item} key={item.id} />
                            })
                        }
                    </div>
                </div>
            </div>
    )
}

export default LogPage;
