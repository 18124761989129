import axios from 'axios';
const base = 'fotos';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    const form = new FormData();
    form.append('tabla',data.tabla);
    form.append('tablaId',data.tablaId);
    form.append('tipoFotoId',data.tipoFotoId);
    if(Array.isArray(data.foto) && data.foto.length>0){
        form.append('file',data.foto[0]);
    } else {
        form.append('file',data.foto);
    }
    return axios.post(base, form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getById(id) {
    return axios.get([base, id].join('/'));
}

function update(data) {
    return axios.put([base, data.id].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

const FotoService = {
    getAll: getAll,
    register: register,
    getById: getById,
    update: update,
    destroy: destroy
};
export default FotoService;