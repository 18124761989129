import React, {useState, useEffect} from "react";
import { Modal, Spinner, Collapse} from "react-bootstrap";
import { Button, DateWeek, ListCard, FlotaCard } from "@app/components/index";
import FechaFunction from "@app/functions/FechaFunction";
import FlotaJugadoresService from "@app/services/flotaJugadores.service";

function ModalFlotaJugadorCopiar({
    show,
    hideModal,
    item,
    onGuardar,
}) {
    const [rangeDate, setRangeDate] = useState(FechaFunction.rangePrev(FechaFunction.rangeWeek(),'week'))
    const [isLoading, setIsLoading] = useState(false);
    const [flotas, setFlotas] = useState([]);

    const handleClose = () => hideModal();

    const onChangeDate = (range) => {
        setRangeDate(range);
    }

    const onSubmit = () => {
        onGuardar(flotas);
        hideModal();
    }

    useEffect(() => {
        const { start, end} = rangeDate;
        setIsLoading(true);
        FlotaJugadoresService.getAll({
            fechaIni: FechaFunction.format(start),
            fechaFin: FechaFunction.format(end),
        }).then((response)=>{
            setFlotas(response);
        }).finally(()=>{
            setIsLoading(false);
        });
        return () => {
            
        }
    }, [rangeDate])

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Seleccionar periodo a importar
                    {
                        isLoading && (
                            <Spinner animation="border" />
                        )
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>            
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="semana-input">Semana</label>
                            <DateWeek value={rangeDate} onChange={onChangeDate} isLoading={isLoading} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ListCard>
                            {
                                flotas.map((flota)=>{
                                    return <FlotaCard item={flota} key={flota.id} />
                                })
                            }
                        </ListCard>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
                <Button
                    theme="primary"
                    disabled={flotas.length === 0}
                    onClick={onSubmit}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalFlotaJugadorCopiar
