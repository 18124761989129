import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/esm/Modal';
import MotivoTareaService from '@app/services/motivoTarea.service';
import {SelectTipoTarea, Button} from '@app/components/index';

const schema = Yup.object({
    nombre: Yup.string().max(255).required(),
    descripcion: Yup.string(255).required(),
    zohoDeskTeamId: Yup.string().max(255).required(),
    tipoTareaId: Yup.number()
});

function ModalMotivoTareaEditar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues: item
    });

    const tipoTareaId = watch('tipoTareaId');

    const onSubmit = (values) => {
        const data = {
            ...values
        };
        setIsLoading(true);
        if (item.id > 0) {
            data.id = item.id;
            MotivoTareaService.update(data)
                .then(() => {
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            MotivoTareaService.register(data)
                .then(() => {
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
        if (onGuardar) {
            onGuardar();
        }
    };

    const handleClose = () => hideModal();

    useEffect(()=>{
        register('tipoTareaId')
    },[register])

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0
                        ? 'Editar motivo tarea'
                        : 'Registrar motivo tarea'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label className="label-control" htmlFor="nombre">
                            Nombre
                        </label>
                        <input
                            className={`form-control ${errors.nombre ? 'is-invalid': ''}`}
                            id="nombre"
                            name="nombre"
                            type="text"
                            autoComplete="off"
                            ref={register}
                        />
                        {errors.nombre ? (
                            <div className="invalid-feedback">
                                {errors.nombre?.message}
                            </div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <label htmlFor="tipoTareaId" className="label-control">Tipo de Tarea</label>
                        <SelectTipoTarea
                            id='tipoTareaId'
                            isInvalid={errors.tipoTareaId}
                            value={tipoTareaId}
                            onChange={(opt) =>
                                setValue('tipoTareaId', opt.value)
                            }
                        />
                        {errors.tipoTareaId ? (
                            <div className="invalid-feedback">
                                {errors.tipoTareaId?.message}
                            </div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <label htmlFor='zohoDeskTeamId' className="control-label">Destinatario</label>
                        <select
                            className={`form-control ${errors.zohoDeskTeamId ? 'is-invalid': ''}`}
                            id="zohoDeskTeamId"
                            name="zohoDeskTeamId"
                            ref={register}
                        >
                            <option value="" disabled>
                                Seleccione...
                            </option>
                            <option value="526450000000206405">
                                IDA MASIVOS
                            </option>
                            <option value="526450000000206363">
                                IDA CORPORATIVOS
                            </option>
                            <option value="526450000000206475">
                                IDA SALTA CONECTADA
                            </option>
                            <option value="526450000000206391">
                                LOGISTICA
                            </option>
                            <option value="526450000000206447">
                                FACTURACION
                            </option>
                            <option value="526450000000206335">
                                INGENIERO DE CUENTAS
                            </option>
                        </select>
                        {errors.zohoDeskTeamId ? (
                            <div className="invalid-feedback">
                                {errors.zohoDeskTeamId?.message}
                            </div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <label htmlFor='descripcion' className="control-label">Descripcion</label>
                        <textarea
                            className={`form-control ${errors.descripcion ? 'is-invalid': ''}`}
                            id="descripcion"
                            name="descripcion"
                            type="text"
                            autoComplete="off"
                            rows="10"
                            ref={register}
                        >
                        </textarea>
                        {errors.descripcion ? (
                            <div className="invalid-feedback">
                                {errors.descripcion?.message}
                            </div>
                        ) : null}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalMotivoTareaEditar.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: '',
        descripcion: '',
        zohoDeskTeamId: '',
        tipoTareaId: ''
    }
};

export default ModalMotivoTareaEditar;
