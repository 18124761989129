import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import RecibosService from '@services/recibosComisione.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalPlanes from '@app/modals/planes/ModalPlanes';
import {Button, ModalConfirm} from '@app/components/index';

const RecibosPage = () => {
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = () => {
        RecibosService.getAll().then((response)=>{
            setItems(response);

        });
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar',
            message: '¿Esta seguro que desea eliminar el Recibo?',
            onConfirm: () => {
                RecibosService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalPlanes, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                width: 10,
                MaxWidth: 15,
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,

                Cell: ({row}) => {
                    return (
                        <Dropdown size="sm">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            },
            {
                Header: 'Fecha',
                accessor: 'fecha'
            },
            {
                Header: 'N° de Recibo',
                accessor: 'numero'
            },
            {
                Header: 'Monto bruto',
                accessor: 'montoGeneral'
            },
            {
                Header: 'Subtotal',
                accessor: 'subTotalGeneral',
            },
            {
                Header: 'Penalizacion',
                accessor: 'penalizacionGeneral'
            },
            {
                Header: 'Saldo',
                accessor: 'saldoGeneral'
            }
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 col-lg-6 my-2 my-lg-3">
                            <h3>Lista de Recibos</h3>
                        </div>
                        <div className="offset-3 col-3 offset-lg-4 col-lg-2 my-2 my-lg-3">
                            <Link to="/recibos/nuevo">
                                <Button color="primary" className="btn-block">
                                    NUEVO
                                </Button>
                            </Link>
                            
                        </div>
                        <div className="col-12">
                            <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecibosPage);
