import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button, InputLocalidadZoho, InputZohoSearch, SelectCategoriaCorporativo, SelectSegmentoCorporativo, SelectServicioCorporativo} from '@app/components/index';
import {useForm, Controller} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import CorporativoGeneralService from '@app/services/coorporativo/general.service';
import InputContactsSearchZoho from '@app/components/input/InputContactSearchZoho';
import usePermisos from '@app/hooks/usePermisos';
import Tabs from '@app/../node_modules/react-bootstrap/esm/Tabs';
import Tab from '@app/../node_modules/react-bootstrap/esm/Tab';

const schema = Yup.object({

});

function ModalDatosCorporativos({show, hideModal, onGuardar,tipoModal}) {
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();
    const [categoria, setCategoria] = useState();
    const [segmento, setSegmento] = useState();
    const [servicio, setServicio] = useState();
    const [zohoCRMContactId, setZohoCRMContactId] = useState();
    const [estadoAgregarItemPlataforma, setEstadoAgregarItemPlataforma] = useState(false)
    const [itemAgregarPlataforma, setItemAgregarPlataforma] = useState()
    const [requerimientos, setRequerimientos] = useState([]);
    const [estadoAgregar, setEstadoAgregar] = useState(false)
    const [itemAgregar, setItemAgregar] = useState()
    const [contactosTecnicos, setContactosTecnicos] = useState([]);



    const {register, handleSubmit, errors, control, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    const agregarItem = () =>{
        requerimientos.push({item:itemAgregar});
        setEstadoAgregar(false)
    }

    const onEliminarItem = (item) =>{
        const resultado = requerimientos.filter((requerimiento)=> requerimiento.item !== item )
        setRequerimientos(resultado)
    }

    const agregarItemInformacionTecnica = () =>{
        contactosTecnicos.push({item:itemAgregar});
        setEstadoAgregar(false)
    }

    const onEliminarItemInformacionTecnica = (itemLista) =>{
        const resultado = contactosTecnicos.filter((contacto)=> contacto.item !== itemLista )
        setContactosTecnicos(resultado)
    }

    const setZohoDate = (data) => {
        let ubicacion;
        const coordenadas = data.Coordenadas_de_instalacion;
        if (coordenadas) {
            ubicacion = coordenadas?.split(',');
            if(ubicacion.length > 1){
                setValue('latitud',ubicacion[0])
                setValue('longitud',ubicacion[1])
            } else {
                ubicacion = coordenadas?.split('-');
                if(ubicacion.length > 2){
                    setValue('latitud',ubicacion[0])
                    setValue('longitud',ubicacion[1])
                }
            }
        } else {
            setValue('latitud','')
            setValue('longitud','')
        }
        setValue('clienteNombre', data.Full_Name);
        setValue('idServicio', data.Nro_Cliente);
        setValue('punto', data.Last_Name);
        setValue('cuit', data.DNI_CUIT);
        setValue('localidad', data.Cod_postal_Localidad_de_correspondencia?.name);
        setValue('localidadId', data.Cod_postal_Localidad_de_correspondencia?.id);
        setValue('detalleServicio', data.Servicio);
        setValue('contratoFirmado', data.Contrato_firmado?"SI":"NO");
        setValue('copiaDni', data.Copia_DNI?"SI":"NO");
        setValue('constanciaAfip', data.Constancia_de_AFIP?"SI":"NO");
        setValue('constanciaRenta', data.Constancia_de_rentas?"SI":"NO");
        setValue('contratoSocial', data.Contrato_social?"SI":"NO");
        setValue('poder', data.Poder_a_nombre_del_que_firma?"SI":"NO");
        setValue('mbSubida', data.MB_Subida);
        setValue('mbBajada', data.MB_Bajada);
        setValue('fecha', data.Created_Time);
        setValue('domicilioFiscal', data.Mailing_Street);


        setSegmento(data.Segmento);
        setCategoria(data.Categoria);
        setServicio(data.Servicio);
        setZohoCRMContactId(data.id);
        
    };

    useEffect(() => {
    }, [register]);

    useEffect(() => {
     
        onGuardar()
    
    }, [requerimientos])
    
    const onSubmit = (data) => {
        const form = {
            ...data,
            // id: item.id
        };
        form.servicio = servicio
        form.segmento = segmento
        form.categoria = categoria
        form.zohoCRMContactId = zohoCRMContactId
        form.requerimientos = JSON.stringify(requerimientos)
        form.contactosTecnicos = JSON.stringify(contactosTecnicos)

        setIsLoading(true);

            CorporativoGeneralService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });

    };


    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Nuevo Registro
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>

                                {/* DATOS CLIENTES */}
                      
                        <Tabs
                                id="contactosTab"
                                // activeKey={key}
                                // onSelect={(k) => setKey(k)}
                        >
                            <Tab
                                eventKey="datosClientes"
                                title="Datos Clientes"
                                tabClassName="card-body"

                            >

                                <div className="col-12 mt-4">
                                    <div className="row">
                                        

                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="patenteL">
                                                    Buscar Cliente
                                                </label>
                                                <Controller
                                                    as={InputContactsSearchZoho}
                                                    control={control}
                                                    field="clienteNombre"
                                                    name="clienteNombre"
                                                    id="clienteNombre"
                                                    onSelect={(opt) => {
                                                        if (opt && opt.length > 0) {
                                                            setZohoDate(opt[0]);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="patenteL">
                                                    ID Servicio
                                                </label>
                                                <input
                                                id="idServicio"
                                                className='form-control'
                                                type="text"
                                                name="idServicio"
                                                ref={register}
                                                autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="patenteL">
                                                    Punto
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="punto"
                                                    name="punto"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                                {errors.cliente && (
                                                    <div className="invalid-feedback">
                                                        {errors.punto.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="patenteL">
                                                    Localidad
                                                </label>
                                                <Controller
                                                    as={InputLocalidadZoho}
                                                    control={control}
                                                    field="localidad"
                                                    name="localidad"
                                                    id="localidad"
                                                    onSelect={(opt) => {
                                                        console.log(opt[0])
                                                        setValue('localidad', opt[0]);
                                                    }}
                                                    ref={register}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="patenteL">
                                                    Localidad
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="localidad"
                                                    name="localidad"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                                {errors.localidad && (
                                                    <div className="invalid-feedback">
                                                        {errors.localidad.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="cantidadL">
                                                    Servicio Vigente
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="servicioVigente"
                                                    name="servicioVigente"
                                                    ref={register}
                                                >
                                                    <option value="ACTIVO">ACTIVO</option>
                                                    <option value="A INSTALAR">A INSTALAR</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="segmentos">
                                                    Segmentos
                                                </label>
                                                <SelectSegmentoCorporativo 
                                                    name="segmento"
                                                    id="segmento"
                                                    ref={register}
                                                    value={segmento}
                                                    onChange={(opt)=>setSegmento(opt.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="servicio">
                                                    Servicio
                                                </label>
                                                
                                                <SelectServicioCorporativo 
                                                    id="servicio"
                                                    name="servicio"
                                                    value={servicio}
                                                    onChange={(opt)=>setServicio(opt.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="detalleServicio">
                                                    Det. de Servicio
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="detalleServicio"
                                                    name="detalleServicio"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="servicio">
                                                    Categoria
                                                </label>
                                                <SelectCategoriaCorporativo
                                                name="categoria"
                                                id="categoria"
                                                ref={register}
                                                value={categoria}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </Tab>
                              
                                {/* PLATAFORMAS */}
                            
                            <Tab
                                eventKey="plataforma"
                                title="Plataforma "
                                tabClassName="card-body"

                            >
                                <div className="col-12 mt-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="detalleServicio">
                                                    Requerimientos
                                                </label>
                                                <button className='btn btn-xs btn-primary float-right' type='button' onClick={()=>setEstadoAgregar(!estadoAgregar)}>
                                                <i className={estadoAgregar !== false ? ("fas fa-angle-up") : ("fas fa-plus")} />
                                                
                                                </button>
                                                    {estadoAgregar !== false && (
                                                        <div className="input-group mb-3 mt-2" data-aos="fade-up">
                                                                <input
                                                                    id="requerimientos"
                                                                    name="requerimientos"
                                                                    type="text"
                                                                    className="form-control"
                                                                    onChange={(e)=>setItemAgregar(e.target.value)}
                                                                    ref={register}
                                                                    placeholder="Agregar a la lista"
                                                                    autoComplete="off"
                                                                />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-sm btn-primary" type="button" onClick={()=>agregarItem()}>
                                                                        Agregar
                                                                    </button>
                                                                </div>
                                                        </div>
                                                    )}


                                                    <ul className={requerimientos?.length > 0 ? ("list-group mt-2 overflow-auto") : ("d-none")}>
                                                                { requerimientos?.map((requerimiento)=>(
                                                                    <li className='list-group-item list-group-item-primary'>
                                                                        <span className="">{requerimiento?.item}</span>
                                                                        <button className="btn btn-sm text-danger float-right" type='button' onClick={()=>onEliminarItem(requerimiento?.item)}>x</button>
                                                                    </li>
                                                                ))}
                                                    </ul>
                                            </div>
                                        </div>
                                    
                            
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="detalleServicio">
                                                    Plataformas
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="plataformas"
                                                    name="plataformas"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                            
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="link">
                                                    Link
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="link"
                                                    name="link"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="usuario">
                                                    Usuario
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="usuario"
                                                    name="usuario"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="detalleServicio">
                                                    Clave
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="clave"
                                                    name="clave"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Tab>
                            
                                {/* FIN PLATAFORMAS */}

                            <Tab
                                eventKey="administracion"
                                title="Administracion "
                                tabClassName="card-body"

                            >

                                {/* ADMINISTRACION */}
                                <div className="col-12 mt-4">
                                    <div className="row">
                                        <div className="col-4">
                                                <div className="form-group">
                                                    <label className="label-control" htmlFor="idFact">
                                                        Id Fact
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        id="idFact"
                                                        name="idFact"
                                                        type="text"
                                                        icon="fas-car"
                                                        autoComplete="off"
                                                        required
                                                        ref={register}
                                                    />
                                                </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="cuit">
                                                    Cuit
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="cuit"
                                                    name="cuit"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="domicilioFiscal">
                                                    Domicilio Fiscal
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="domicilioFiscal"
                                                    name="domicilioFiscal"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="domicilioFiscal">
                                                    Mail Facturación
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="emailFacturacion"
                                                    name="emailFacturacion"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="contactosAdministrativos">
                                                    Contactos Administrativos
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="contactosAdministrativos"
                                                    name="contactosAdministrativos"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="fechaFinContrato">
                                                    Fecha
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="fechaFinContrato"
                                                    name="fechaFinContrato"
                                                    type="date"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="costoPorMega">
                                                    Costo por Mega
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="costoPorMega"
                                                    name="costoPorMega"
                                                    type="number"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="costoPorInstalacion">
                                                    Costo por Instalación
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="costoPorInstalacion"
                                                    name="costoPorInstalacion"
                                                    type="number"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="ordenCompra">
                                                        Orden de Compra
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id="ordenCompra"
                                                    name="ordenCompra"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="contratoFirmado">
                                                    Contrato firmado
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="contratoFirmado"
                                                    name="contratoFirmado"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="contratoFirmado">
                                                    Contrato Certificado
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="contratoCertificado"
                                                    name="contratoCertificado"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="agenda">
                                                    Addenda
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="agenda"
                                                    name="agenda"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="estatutoEmpresa">
                                                    Estatuto de la empresa
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="estatutoEmpresa"
                                                    name="estatutoEmpresa"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="designacion">
                                                    Designación de acta actualizada
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="designacion"
                                                    name="designacion"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="contratoSocial">
                                                    Contrato Social
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="contratoSocial"
                                                    name="contratoSocial"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="poder">
                                                    Poder a nombre del que firma
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="poder"
                                                    name="poder"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="copiaDni">
                                                    Copia DNI
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="copiaDni"
                                                    name="copiaDni"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="copiaDni">
                                                    Constancia de AFIP
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="constanciaAfip"
                                                    name="constanciaAfip"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="constanciaRenta">
                                                    Constancia de Rentas
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="constanciaRenta"
                                                    name="constanciaRenta"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="pagosDia">
                                                    Pagos al día
                                                </label>
                                                <select
                                                    className='form-control'
                                                    id="pagosDia"
                                                    name="pagosDia"
                                                    ref={register}
                                                    defaultValue="NO"
                                                >
                                                    <option value="NO">NO</option>
                                                    <option value="SI">SI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="observaciones">
                                                        Observaciones de facturación
                                                </label>
                                                <textarea
                                                    className='form-control'
                                                    rows="1"
                                                    id="observaciones"
                                                    name="observaciones"
                                                    type="text"
                                                    icon="fas-car"
                                                    autoComplete="off"
                                                    required
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* FIN ADMINISTRACION */}

                            </Tab>
                            
                            <Tab
                                eventKey="informacionTecnica"
                                title="Informacion Tecnica"
                                tabClassName="card-body"

                            >

                                {/* INFORMACION TECNICA */}
                                <div className="col-12 mt-4">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="label-control" htmlFor="detalleServicio">
                                                    Contactos Tecnicos
                                                </label>
                                                <button className='btn btn-xs btn-primary float-right' type='button' onClick={()=>setEstadoAgregar(!estadoAgregar)} title={!estadoAgregar ? "Agregar a la lista" : "Ocultar"}>
                                                    <i className={estadoAgregar !== false ? ("fas fa-angle-up") : ("fas fa-plus")} />
                                                                    
                                                </button>
                                                    {estadoAgregar !== false && (
                                                        <div className="input-group mb-3 mt-2" data-aos="fade-up">
                                                                <input
                                                                    id="requerimientos"
                                                                    name="requerimientos"
                                                                    type="text"
                                                                    className="form-control"
                                                                    onChange={(e)=>setItemAgregar(e.target.value)}
                                                                    ref={register}
                                                                    placeholder="Agregar a la lista"
                                                                    autoComplete="off"
                                                                />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-sm btn-primary" type="button" onClick={()=>agregarItemInformacionTecnica()}>
                                                                        Agregar
                                                                    </button>
                                                                </div>
                                                        </div>
                                                    )}


                                                    <ul className={contactosTecnicos?.length > 0 ? ("list-group mt-2 overflow-auto") : ("d-none")}>
                                                                { contactosTecnicos?.map((contacto)=>(
                                                                    <li className='list-group-item list-group-item-primary'>
                                                                        <span className="">{contacto?.item}</span>
                                                                        <button className="btn btn-sm text-danger float-right" type='button' title="Eliminar de la lista" onClick={()=>onEliminarItemInformacionTecnica(contacto?.item)}>x</button>
                                                                    </li>
                                                                ))}
                                                    </ul>
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <div className='row'>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="fechaInstalacion">
                                                                Fecha de instalación
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="fechaInstalacion"
                                                            name="fechaInstalacion"
                                                            type="date"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="fechaAlta">
                                                                Fecha Alta
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="fechaAlta"
                                                            name="fechaAlta"
                                                            type="date"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="fechaBaja">
                                                                Fecha Baja
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="fechaBaja"
                                                            name="fechaBaja"
                                                            type="date"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="latitud">
                                                                Latitud
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="latitud"
                                                            name="latitud"
                                                            type="text"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="longitud">
                                                                Longitud
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="longitud"
                                                            name="longitud"
                                                            type="text"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="mbSubida">
                                                                MB Subida
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="mbSubida"
                                                            name="mbSubida"
                                                            type="number"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="latitud">
                                                                MB Bajada
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="mbBajada"
                                                            name="mbBajada"
                                                            type="number"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="cantidadIpPublicas">
                                                                Cantidad de IP Publicas
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="cantidadIpPublicas"
                                                            name="cantidadIpPublicas"
                                                            type="number"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="numeroIpPublicas">
                                                                Numero de IP Publicas
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="numeroIpPublicas"
                                                            name="numeroIpPublicas"
                                                            type="number"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="numeroIpPublicas">
                                                                TK Abiertos
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            id="tkAbiertos"
                                                            name="tkAbiertos"
                                                            type="number"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <div className="form-group">
                                                        <label className="label-control" htmlFor="observacionesTecnicas">
                                                                Observaciones tecnicas
                                                        </label>
                                                        <textarea
                                                            rows="1"
                                                            className='form-control'
                                                            id="observacionesTecnicas"
                                                            name="observacionesTecnicas"
                                                            type="text"
                                                            icon="fas-car"
                                                            autoComplete="off"
                                                            required
                                                            ref={register}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* FIN INFORMACION TECNICA */}

                            </Tab>
                            

                        </Tabs>


                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                {
                    permisos['api.general.store'] && (
                    <Button
                        theme="primary"
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                    >
                        GUARDAR
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

ModalDatosCorporativos.defaultProps = {
    show: false,
    item: {
        id: 0
    }
};

export default ModalDatosCorporativos;