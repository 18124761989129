import React from 'react'
import styled from 'styled-components';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import { ReactSortable } from "react-sortablejs";
import { Button } from '@app/components/index';
import ModalFlotaJugadorVer from '@app/modals/flota-jugador/ModalFlotaJugadorVer';
import {showModal} from 'react-redux-modal-provider';

const Styles = styled.div`
    width: 100%;
`;

function itemListFlota(flota){
    return (
        <li className="btn-jugadores" id={flota.id} key={flota.id}>
            <div className="list-group-item-title">{AuxiliarFunction.formatMatricula(flota.vehiculoPatente)}</div>
            <div className="list-group-item-subtitle"></div>
        </li>
    );
}

function TableCellFlota({
    row,
    isSelected,
}) {
    const { original } = row;
    const title = `${AuxiliarFunction.formatMatricula(original.vehiculoPatente)}`
    const subtitle = ` `;

    const onClickVerFlota = () => {
        showModal(ModalFlotaJugadorVer,{item:original})
    }

    return (
        <Styles>
            <div className="table-flotas-row-descripcion">
                {title}
                {
                    original.jugadores?.map(({nombreCompleto,dni})=>{
                        return (
                            <div key={dni}>{`${nombreCompleto} - ${dni}`}</div>
                        )
                    })
                }
                <div>
                    {subtitle}
                </div>
                
            </div>
            <div className="row">
                <div className="col-4 d-flex justify-content-start">
                    <Button 
                        icon="fas fa-eye"
                        onClick={()=>onClickVerFlota(original)}
                    />
                </div>
                <div className="col-8">
                    <ReactSortable 
                        disabled={isSelected}
                        id={original.id}
                        tag='ul'
                        className='list-group'
                        group={{
                            name: 'shared',
                            put: false,
                            revertClone: true,
                        }}
                        animation={150}
                        list={[original]} 
                        setList={()=>{}}
                    >
                        {itemListFlota(original)}
                    </ReactSortable>
                </div>
            </div>
        </Styles>
    )
}

TableCellFlota.defaultProps = {
    isSelected: false,
};

export default TableCellFlota
