import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as Yup from 'yup';
import AOS from 'aos';
import {useForm} from 'react-hook-form';
import {Button,SelectLugar,InputDropZone, DateSingle, DateRange, DateWeek} from '@app/components/index';
import {yupResolver} from '@hookform/resolvers/yup';
import AsesorService from '@app/services/asesor.service';
import FotoService from '@app/services/foto.service';
import { toast } from 'react-toastify';
import {Link, useHistory, useParams} from 'react-router-dom';
import FechaFunction from '@app/functions/FechaFunction';

const requerido = 'Este campo es requerido.';
const schema = Yup.object({
    apellido: Yup.string().required(),
    barrio: Yup.string().required(),
    cuit: Yup.number().required(),
    domicilio: Yup.string().required(),
    dni: Yup.number().required(),
    email: Yup.string().required(),
    nombre: Yup.string().required(),
    telefono: Yup.number().required(),
});
const FormAsesorPage = ({
    location,
}) => {
    AOS.init();
    const history = useHistory();
    const { id } = useParams();

    const { item } = (location && location.state) || {};

    const {handleSubmit,errors,register, setValue, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues: item,
    });
    const toastId = React.useRef(null);
    const localidad = watch('localidad');
    const vtoLicencia = watch('vtoLicencia');
    const fNacimiento = watch('fNacimiento');
    /* Hace un preview de las imagenes que trae el asesor */
    const [defaultImageAs, setDefaultImageAs] = useState(null);
    const [defaultImageDF, setDefaultImageDF] = useState(null);
    const [defaultImageDD, setDefaultImageDD] = useState(null);
    const [defaultImageLF, setDefaultImageLF] = useState(null);
    const [defaultImageLD, setDefaultImageLD] = useState(null);
    const [defaultImageMO, setDefaultImageMO] = useState(null);
    const [defaultImageANT, setDefaultImageANT] = useState(null);
/*     Hace un preview de las imagenes cuando son cargadas */
    const [imgPreviewAs, setImgPreviewAS] = useState(null);
    const [imgPreviewDF, setImgPreviewDF] = useState(null);
    const [imgPreviewDD, setImgPreviewDD] = useState(null);
    const [imgPreviewLF, setImgPreviewLF] = useState(null);
    const [imgPreviewLD, setImgPreviewLD] = useState(null);
    const [imgPreviewMO, setImgPreviewMO] = useState(null);
    const [imgPreviewANT, setImgPreviewANT] = useState(null);

    const onBack = () =>{
        history.goBack()
    }

    const onSubmit = (data) => {
        const form = {
            ...data,
        };
        if (item?.dni > 0) {
            form.dni = item.dni;
            AsesorService.update(form)
            const forms = [];
            if(imgPreviewAs && defaultImageAs){
                forms.push(FotoService.destroy(defaultImageAs.id));
            }
            if(imgPreviewAs){
                forms.push(FotoService.register({
                    tabla: 'personas',
                    tablaId: item.dni,
                    tipoFotoId: 5,
                    foto: imgPreviewAs,
                }));
            }
            if(imgPreviewDF && defaultImageDF){
                forms.push(FotoService.destroy(defaultImageDF.id));
            }
            if(imgPreviewDF){
                forms.push(FotoService.register({
                    tabla: 'personas',
                    tablaId: item.dni,
                    tipoFotoId: 1,
                    foto: imgPreviewDF,
                }));
            }
            if(imgPreviewDD && defaultImageDD){
                forms.push(FotoService.destroy(defaultImageDD.id));
            }
            if(imgPreviewDD){
                forms.push(FotoService.register({
                    tabla: 'personas',
                    tablaId: item.dni,
                    tipoFotoId: 2,
                    foto: imgPreviewDD,
                }));
            }
            if(imgPreviewLF && defaultImageLF){
                forms.push(FotoService.destroy(defaultImageLF.id));
            }
            if(imgPreviewLF){
                forms.push(FotoService.register({
                    tabla: 'personas',
                    tablaId: item.dni,
                    tipoFotoId: 6,
                    foto: imgPreviewLF,
                }));
            }
            if(imgPreviewLD && defaultImageLD){
                forms.push(FotoService.destroy(defaultImageLD.id));
            }
            if(imgPreviewLD){
                forms.push(FotoService.register({
                    tabla: 'personas',
                    tablaId: item.dni,
                    tipoFotoId: 7,
                    foto: imgPreviewLD,
                }));
            }
            if(imgPreviewMO && defaultImageMO){
                forms.push(FotoService.destroy(defaultImageMO.id));
            }
            if(imgPreviewMO){
                forms.push(FotoService.register({
                    tabla: 'personas',
                    tablaId: item.dni,
                    tipoFotoId: 8,
                    foto: imgPreviewMO,
                }));
            }
            if(imgPreviewANT && defaultImageANT){
                forms.push(FotoService.destroy(defaultImageANT.id));
            }
            if(imgPreviewANT){
                forms.push(FotoService.register({
                    tabla: 'personas',
                    tablaId: item.dni,
                    tipoFotoId: 9,
                    foto: imgPreviewANT,
                }));
            }
            toast.success('Actualizado con exito');
            history.push({
                pathname: '/personas',
            });
        }else{
            AsesorService.register(form)
            .then((response) => {
                const forms = [];
                if(imgPreviewAs){
                    forms.push(FotoService.register({
                        tabla: 'personas',
                        tablaId: response.dni,
                        tipoFotoId: 5,
                        foto: imgPreviewAs,
                    }))
                }
                if(imgPreviewDF){
                    forms.push(FotoService.register({
                        tabla: 'personas',
                        tablaId: response.dni,
                        tipoFotoId: 1,
                        foto: imgPreviewDF,
                    }))
                }
                if(imgPreviewDD){
                    forms.push(FotoService.register({
                        tabla: 'personas',
                        tablaId: response.dni,
                        tipoFotoId: 2,
                        foto: imgPreviewDD,
                    }))
                }
                if(imgPreviewLF){
                    forms.push(FotoService.register({
                        tabla: 'personas',
                        tablaId: response.dni,
                        tipoFotoId: 6,
                        foto: imgPreviewLF,
                    }))
                }
                if(imgPreviewLD){
                    forms.push(FotoService.register({
                        tabla: 'personas',
                        tablaId: response.dni,
                        tipoFotoId: 7,
                        foto: imgPreviewLD,
                    }))
                }
                if(imgPreviewMO){
                    forms.push(FotoService.register({
                        tabla: 'personas',
                        tablaId: response.dni,
                        tipoFotoId: 8,
                        foto: imgPreviewMO,
                    }))
                }
                if(imgPreviewANT){
                    forms.push(FotoService.register({
                        tabla: 'personas',
                        tablaId: response.dni,
                        tipoFotoId: 9,
                        foto: imgPreviewANT,
                    }))
                }
                toast.success('Se registro con exito');
            })
            history.push({
                pathname: '/personas',
            });
        }
    }

    const handleImageChangeAsesorFoto = (e) =>{
        setImgPreviewAS(e);
    };
    const handleImageChangeDNIFrente = (e) =>{
        setImgPreviewDF(e);
    };

    const handleImageChangeDNIDorso = (e) =>{
        setImgPreviewDD(e);
    };
    const handleImageChangeLicenciaFrente = (e) =>{
        setImgPreviewLF(e);
    };
    const handleImageChangeLicenciaDorso = (e) =>{
        setImgPreviewLD(e);
    };
    const handleImageChangeANT = (e) =>{
        setImgPreviewANT(e);
    };
    const handleImageChangeMonotributo = (e) =>{
        setImgPreviewMO(e);
    };
    useEffect(() => {
        register('localidad');
        register('vtoLicencia');
        register('fNacimiento');
        register('pass');
    }, [register]);
     useEffect(() => {
        if ( id > 0) {
            FotoService.getAll({personaId:item.dni}).
            then((response) => {
                response.forEach(foto=>{
                    switch (foto.tipoFotoId) {
                        case 5: /* foto Asesor */
                            setDefaultImageAs(foto)
                            break;
                        case 1:  /* foto DNI FRENTE */
                            setDefaultImageDF(foto)
                            break;
                        case 2: /* foto DNI DORSO */
                            setDefaultImageDD(foto)
                            break;
                        case 6:  /* foto LICENCIA FRENTE */
                            setDefaultImageLF(foto)
                            break;
                        case 7:  /* foto LICENCIA DORSO */
                            setDefaultImageLD(foto)
                            break;
                        case 8:  /* foto MONOTRIBUTO */
                            setDefaultImageMO(foto)
                            break;
                        case 9:  /* foto ANTECEDENTES */
                            setDefaultImageANT(foto)
                            break;
                        default:
                            break;
                    }
                })
            });
            AsesorService.getById(id).then((response)=>{
                setValue('nombre', response.nombre);
                setValue('dni', response.dni);
                setValue('apellido', response.apellido);
                setValue('telefono', response.telefono);
                setValue('email', response.email);
                setValue('domicilio', response.domicilio);
                setValue('barrio', response.barrio);
                setValue('vtoLicencia', response.vtoLicencia);
                setValue('cuit', response.cuit);
                setValue('fNacimiento', response.fNacimiento);
                setValue('localidad',response.localidad.id);
            })
        }else{
            const now = FechaFunction.format(new Date,'yyyy-MM-dd');
            const pass = Array(8).fill().map(_ => String.fromCharCode(33 + Math.random() * (127 - 33))).join('');
            setValue('pass', pass);
            setValue('fNacimiento', now);
            setValue('vtoLicencia', now);
            setValue('localidad',1);
        }
    }, []);
     return (
        <div className="container-fluid bg-white p-3 border rounded-lg">
            <div className="row">
                <div className="col-2">
                    <button className="btn btn-primary btn-regresar" type="button" onClick={onBack}>
                        <i className="fas fa-arrow-left" />
                    </button>
                </div>
                <div className="col-8 text-center" data-aos="fade-up">
                    <h2 className="ml-2">
                    {
                       id>0 ? (`Editar Asesor #${id}`) : ('Nuevo Asesor')
                    }
                    </h2>
                </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12 bg-white my-1 my-lg-4">
                    <div className="row">
                        <div className="col-12 col-md-3 text-md-left text-gray my-3" data-aos="fade-up">
                            <h5>
                            <i className="fas fa-angle-down mr-2"></i>
                                     Datos Personales
                            </h5>
                            <span>Informacion sobre el asesor</span>
                        </div>
                        <div className="col-12 offset-md-1 col-md-8" data-aos="fade-up">
                            <div className="row border-md rounded border bg-white shadow-sm p-2">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="apellidoL">
                                            Apellido
                                        </label>
                                        <input
                                            className={`form-control ${errors.apellido? 'is-invalid': ''}`}
                                            id="apellido"
                                            name="apellido"
                                            type="text"
                                            autoComplete="off"
                                            required
                                            ref={register}
                                        />
                                        {errors.apellido && (
                                            <div className="invalid-feedback">
                                                El Apellido es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="nombreL">
                                            Nombre
                                        </label>
                                        <input
                                            className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                            id="nombre"
                                            name="nombre"
                                            type="text"
                                            autoComplete="off"
                                            required
                                            ref={register}
                                        />
                                        {errors.nombre && (
                                            <div className="invalid-feedback">
                                                El Nombre es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="dniL">
                                            DNI
                                        </label>
                                        <input
                                            className={`form-control ${errors.dni? 'is-invalid': ''}`}
                                            id="dni"
                                            name="dni"
                                            type="number"
                                            autoComplete="off"
                                            readOnly={id>0}
                                            ref={register}
                                        />
                                        {errors.dni && (
                                            <div className="invalid-feedback">
                                                El DNI es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="telefonoL">
                                            Telefono
                                        </label>
                                        <input
                                            className={`form-control ${errors.telefono? 'is-invalid': ''}`}
                                            id="telefono"
                                            name="telefono"
                                            type="number"
                                            autoComplete="off"
                                            required
                                            ref={register}
                                        />
                                        {errors.telefono && (
                                            <div className="invalid-feedback">
                                                El telefono es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="emailL">
                                            Email
                                        </label>
                                        <input
                                            className={`form-control ${errors.email? 'is-invalid': ''}`}
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                        {errors.email && (
                                            <div className="invalid-feedback">
                                                El email es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="cuitL">
                                            Cuit/Cuil
                                        </label>
                                        <input
                                            className={`form-control ${errors.cuit? 'is-invalid': ''}`}
                                            id="cuit"
                                            name="cuit"
                                            type="text"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                        {errors.cuit && (
                                            <div className="invalid-feedback">
                                                El cuit o cuil es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="vtoLicenciaL">
                                            Localidad
                                        </label>
                                        <SelectLugar
                                            id="localidad"
                                            isInvalid={Boolean(errors.localidad)}
                                            value={localidad}
                                            onChange={(opt) => {
                                            setValue('localidad', opt.value);
                                            }}
                                            ref={register}
                                        />
                                        {errors.localidad && (
                                            <div className="default-invalid-feedback">
                                                {errors.localidad?.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="barrioL">
                                            Barrio
                                        </label>
                                        <input
                                            className={`form-control ${errors.barrio? 'is-invalid': ''}`}
                                            id="barrio"
                                            name="barrio"
                                            type="text"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                        {errors.barrio && (
                                            <div className="invalid-feedback">
                                                El barrio es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="domicilioL">
                                            Domicilio
                                        </label>
                                        <input
                                            className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                            id="domicilio"
                                            name="domicilio"
                                            type="text"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                        {errors.domicilio && (
                                            <div className="invalid-feedback">
                                                El domicilio es requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="fNacimiento">
                                            Fecha de Nacimiento
                                        </label>
                                        <input
                                            className="form-control"
                                            id="fNacimiento"
                                            type="date"
                                            value={fNacimiento && vtoLicencia ? fNacimiento : null}
                                            onChange={(e)=>setValue('fNacimiento',e.target.value)}
                                        />
                                        {errors.fNacimiento && (
                                            <div className="custom-invalid-feedback">
                                                {errors.fNacimiento.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="label-control" htmlFor="vtoLicencia">
                                            Venc. de Licencia
                                        </label>
                                        <input
                                            id="vtoLicencia"
                                            type="date"
                                            className={`form-control ${errors.vtoLicencia? 'is-invalid': ''}`}
                                            value={vtoLicencia && fNacimiento ? vtoLicencia : null}
                                            onChange={(e)=>setValue('vtoLicencia',e.target.value)}
                                        />
                                        {errors.vtoLicencia && (
                                            <div className="custom-invalid-feedback">
                                                {errors.vtoLicencia.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 border my-5">
                </div>

                {/* SEGUNDA CARD */}
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-5">
                            <h5 className="text-gray my-md-3">
                                <i className="fas fa-angle-down mr-2"></i>
                                 Foto del asesor
                            </h5>
                        </div>
                        <div className="col-md-12 col-lg-7">
                            <div className="row">
                                <div className="col-md-5 col-lg-6" data-aos="fade-up">
                                    <label className="label-control" htmlFor="dnil">
                                        Asesor
                                    </label>
                                    <InputDropZone
                                        id="asesorFoto"
                                        defaultImage={defaultImageAs?.foto_url}
                                        backgroundImage="/img/asesor.png"
                                        onChange={handleImageChangeAsesorFoto}
                                    />
                                    {errors.asesorFoto && (
                                        <div className="invalid-feedback">
                                            {errors.asesorFoto.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                {/* TERCERA CARD */}
                <div className="col-12 my-5">
                    <div className="row">
                        <div className="col-md-3 col-lg-5">
                            <h5 className="text-gray my-md-3">
                                <i className="fas fa-angle-down mr-2"></i>
                                 Imagenes del DNI
                            </h5>
                        </div>
                        <div className="col-md-12 col-lg-7">
                            <div className="row">
                                <div className="col-md-5 col-lg-6">
                                    <label className="label-control" htmlFor="dnil">
                                        DNI Frente
                                    </label>
                                    <InputDropZone
                                        id="dniFrente"
                                        defaultImage={defaultImageDF?.foto_url}
                                        backgroundImage="/img/Frente.png"
                                        onChange={handleImageChangeDNIFrente}
                                    />
                                    {errors.dniFrente && (
                                        <div className="invalid-feedback">
                                            {errors.dniFrente.message}
                                        </div>
                                    )}
                                </div>
                                <div className="offset-md-1 col-md-5 col-lg-6 mt-4 mt-md-0 mt-lg-0 offset-lg-0 mt-lg-0">
                                    <label className="label-control" htmlFor="nombre">
                                        DNI Dorso
                                    </label>
                                    <InputDropZone
                                        id="dniDorso"
                                        defaultImage={defaultImageDD?.foto_url}
                                        backgroundImage="/img/Dorso.png"
                                        onChange={handleImageChangeDNIDorso}
                                    />
                                    {errors.dniDorso && (
                                        <div className="invalid-feedback">
                                            {errors.dniDorso.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                {/* CUARTA CARD */}
                <div className="col-12 my-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <h5 className="text-gray">
                                <i className="fas fa-angle-down mr-2"></i>
                                Imagenes de Licencia
                            </h5>
                        </div>
                        <div className="col-md-12 offset-lg-1 col-lg-7">
                            <div className="row">
                                <div className="col-md-5 col-lg-6">
                                    <label className="label-control" htmlFor="dnil">
                                        Licencia Frente
                                    </label>
                                    <InputDropZone
                                        id="licenciaFrente"
                                        defaultImage={defaultImageLF?.foto_url}
                                        backgroundImage="/img/licencia_frente.png"
                                        onChange={handleImageChangeLicenciaFrente}
                                    />
                                    {errors.licenciaFrente && (
                                        <div className="invalid-feedback">
                                            {errors.licenciaFrente.message}
                                        </div>
                                    )}
                                </div>
                                <div className="offset-md-1 col-md-5 col-lg-6 mt-md-0 offset-lg-0 mt-lg-0 mt-4">
                                    <label className="label-control" htmlFor="licenciaDorso">
                                        Licencia Dorso
                                    </label>
                                    <InputDropZone
                                        id="licenciaDorso"
                                        defaultImage={defaultImageLD?.foto_url}
                                        backgroundImage="/img/licencia_dorso.png"
                                        onChange={handleImageChangeLicenciaDorso}
                                    />
                                    {errors.licenciaDorso && (
                                        <div className="invalid-feedback">
                                            {errors.licenciaDorso.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                                {/* CUARTA CARD */}
                <div className="col-12 my-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <h5 className="text-gray my-md-3">
                                <i className="fas fa-angle-down mr-2"></i>
                                Imagenes de Monotributo y Antecedentes
                            </h5>
                        </div>
                        <div className="col-md-12 offset-lg-1 col-lg-7">
                            <div className="row">
                                <div className="col-md-5 col-lg-6">
                                    <label className="label-control" htmlFor="dnil">
                                        Monotributo
                                    </label>
                                    <InputDropZone
                                        id="monotributo"
                                        defaultImage={defaultImageMO?.foto_url}
                                        backgroundImage="/img/monotributo.png"
                                        onChange={handleImageChangeMonotributo}
                                    />
                                    {errors.dniFrente && (
                                        <div className="invalid-feedback">
                                            {errors.dniFrente.message}
                                        </div>
                                    )}
                                </div>
                                <div className="offset-md-1 col-md-5 offset-lg-0 mt-lg-0 col-lg-6 mt-4 mt-md-0">
                                    <label className="label-control" htmlFor="nombre">
                                        Antecedentes
                                    </label>
                                    <InputDropZone
                                        id="antecedentes"
                                        defaultImage={defaultImageANT?.foto_url}
                                        backgroundImage="/img/antecedentes.png"
                                        onChange={handleImageChangeANT}
                                    />
                                    {errors.antecedentes && (
                                        <div className="invalid-feedback">
                                            {errors.antecedentes.message}
                                        </div>
                                    )} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="col-12">
                <div className="row">
                    <div className="offset-lg-10 col-lg-2">
                            <Button
                                onClick={handleSubmit(onSubmit)}
                            >
                                Guardar
                            </Button>
                        <Link to="/personas">
                            <Button theme="default">
                                Cancelar
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
     )
};

FormAsesorPage.defaultProps = {
    item: {
        dni: 0,
    }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormAsesorPage);
