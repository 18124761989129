import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/esm/Modal';
import {Button,DateSingle,InputDropZone, Select} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import PlanesService from '@app/services/planes.service';
import {yupResolver} from '@hookform/resolvers/yup';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

const schema = Yup.object({
    nombre: Yup.string().max(200).required(),
});

const tipoAnchoOptions = [
    {
        label: 'MB',
        value: 'MB',
    },
    {
        label: 'KB',
        value: 'KB',
    },
]

function ModalPlanes({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const [foto, setFoto] = useState(null);
    const [defaultFoto, setDefaultFoto] = useState(null);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, setError, watch} = useForm({
        resolver: yupResolver(schema),
        defaultValues: item,
    });

    const tipoAnchoBajada = watch('tipoAnchoBajada');
    const tipoAnchoSubida = watch('tipoAnchoSubida');
    const fechaVigencia = watch('fechaVigencia');
    const fechaVencimiento = watch('fechaVencimiento');

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id
        };
        if(foto){
            form.foto = foto;
        }
        setIsLoading(true);
        if (form.id > 0) {
            PlanesService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            PlanesService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        register('tipoAnchoBajada');
        register('tipoAnchoSubida');
        register('fechaVigencia');
        register('fechaVencimiento');
        return () => {
            
        }
    }, [register])

    useEffect(() => {
        if (item.id > 0) {
            if(item.foto_url){
                setDefaultFoto(item.foto_url);
            }
        }
    }, []);
    
    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar Plan' : 'Registrar Plan'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombreL">
                                        Categoria
                                    </label>
                                    <select 
                                        className={`form-control ${errors.categoria? 'is-invalid': ''}`}
                                        ref={register}
                                        name="categoria"
                                        id="categoria"
                                    >
                                        <option value="">--Seleccione--</option>
                                        <option value="NUBIHOGAR">NUBIHOGAR</option>
                                        <option value="NUBIPYME">NUBIPYME</option>
                                        <option value="NUBIGOB">NUBIGOB</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombreL">
                                        Nombre
                                    </label>
                                    <input
                                        className={`form-control ${errors.nombre? 'is-invalid': ''}`}
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.nombre && (
                                        <div className="invalid-feedback">
                                            {errors.nombre.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="nombreCorto">
                                        Nombre Corto
                                    </label>
                                    <input
                                        className={`form-control ${errors.nombreCorto? 'is-invalid': ''}`}
                                        id="nombreCorto"
                                        name="nombreCorto"
                                        type="text"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.nombreCorto && (
                                        <div className="invalid-feedback">
                                            {errors.nombreCorto.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="importe">
                                        Importe
                                    </label>
                                    <input
                                        className={`form-control ${errors.importe? 'is-invalid': ''}`}
                                        id="importe"
                                        name="importe"
                                        type="number"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.importe && (
                                        <div className="invalid-feedback">
                                            {errors.importe.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-8 col-lg-8">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="descripcion">
                                        Descripcion
                                    </label>
                                    <input
                                        className={`form-control ${errors.descripcion? 'is-invalid': ''}`}
                                        id="descripcion"
                                        name="descripcion"
                                        type="text"
                                        autoComplete="off"
                                        ref={register} 
                                    />
                                    {errors.descripcion && (
                                        <div className="invalid-feedback">
                                            {errors.descripcion.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="anchoBajada">
                                        Ancho de Bajada
                                        <i className="far fa-arrow-alt-circle-down ml-2 text-primary"></i>
                                        
                                    </label>
                                    <div className="input-group">
                                        <input
                                            className={`form-control p-2 ${errors.anchoBajada? 'is-invalid': ''}`}
                                            id="anchoBajada"
                                            name="anchoBajada"
                                            type="number"
                                            autoComplete="off"
                                            ref={register} 
                                        />
                                        <div className="input-group-append">
                                            <Select
                                                options={tipoAnchoOptions}
                                                defaultValue={tipoAnchoBajada}
                                                onChange={(opt)=>setValue('tipoAnchoBajada',opt.value)}
                                            />
                                        </div>
                                    </div>
                                    {errors.anchoBajada && (
                                        <div className="invalid-feedback">
                                            {errors.anchoBajada.message}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="anchoSubida">
                                        Ancho de Subida
                                        <i className="far fa-arrow-alt-circle-up ml-2 text-primary"></i>
                                        
                                    </label>
                                    <div className="input-group">
                                        <input
                                            className={`form-control p-2 ${errors.anchoSubida? 'is-invalid': ''}`}
                                            id="anchoSubida"
                                            name="anchoSubida"
                                            type="number"
                                            autoComplete="off"
                                            ref={register} 
                                        />
                                        <div className="input-group-append">
                                            <Select
                                                options={tipoAnchoOptions}
                                                defaultValue={tipoAnchoSubida}
                                                onChange={(opt)=>setValue('tipoAnchoSubida',opt.value)}
                                            />
                                        </div>
                                    </div>
                                    {errors.anchoSubida && (
                                        <div className="invalid-feedback">
                                            {errors.anchoSubida.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="fechaVigencia">
                                        Fecha de Vigencia
                                    </label>
                                    <DateSingle
                                        id="fechaVigencia"
                                        value={fechaVigencia}
                                        isInvalid={errors.fechaVigencia}
                                        isClearable
                                        onChange={(value)=>setValue('fechaVigencia',value)}
                                    />
                                    {errors.fechaVigencia && (
                                        <div className="invalid-feedback">
                                            {errors.fechaVigencia.message}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label className="label-control" htmlFor="fechaVencimiento">
                                        Fecha de Vencimiento
                                    </label>
                                    <DateSingle
                                        id="fechaVencimiento"
                                        value={fechaVencimiento}
                                        isInvalid={errors.fechaVencimiento}
                                        isClearable
                                        onChange={(value)=>setValue('fechaVencimiento',value)}
                                    />
                                    {errors.fechaVencimiento && (
                                        <div className="invalid-feedback">
                                            {errors.fechaVencimiento.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="foto">
                                        Foto
                                    </label>
                                    <InputDropZone 
                                        id="foto" 
                                        defaultImage={defaultFoto}
                                        backgroundImage="/img/default-image.png" 
                                        onChange={setFoto} 
                                    />
                                </div>
                            </div>    
                        </div>
                    </div>    
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalPlanes.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
        nombre: PropTypes.string
    })
};

ModalPlanes.defaultProps = {
    show: false,
    item: {
        id: 0,
        nombre: ''
    }
};

export default ModalPlanes;