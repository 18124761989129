import React from 'react'
import styled from 'styled-components';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import {showModal} from 'react-redux-modal-provider';
import { Button } from '@app/components/index';
import ModalFlotaJugadorVer from '@app/modals/flota-jugador/ModalFlotaJugadorVer';

const Styles = styled.div`
`;

function FlotaCard({
    item,
}) {

    const onClickVerFlota = () => {
        showModal(ModalFlotaJugadorVer,{item:item})
    }

    return (
        <Styles className="row border border-primary redondeado shadow-sm p-3 m-3">
            <div className="col-3">
                <Button theme="info" onClick={()=>onClickVerFlota()}>
                    {AuxiliarFunction.formatMatricula(item.vehiculoPatente)}
                </Button>
            </div>
            <div className="col-9">
                {
                    item.jugadores && item.jugadores.map((jugador)=>{
                        const esConductor = (jugador.dni === item.colaboradorIdConductor);
                        return (
                            <div className="text-capitalize" key={jugador.dni}>
                                {jugador.nombreCompleto}
                                {
                                    esConductor && (
                                        <span className="badge rounded-pill badge-warning ml-2" title="Conductor">C</span>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
        </Styles>
    )
}

export default FlotaCard
