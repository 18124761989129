import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import LugaresService from '@services/lugares.service';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {showModal} from 'react-redux-modal-provider';
import ModalLugarEditar from '@app/modals/lugar/ModalLugarEditar';
import usePermisos from '@app/hooks/usePermisos';
import {Button, ModalConfirm, SelectZona, TablePaginated} from '@app/components/index';

const LugaresPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await LugaresService.getAll();
        setItems(response);
    };


    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Lugar',
            message: '¿Esta seguro que desea eliminar el lugar?',
            onConfirm: () => {
                LugaresService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalLugarEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'Iniciales',
                accessor: 'nombreCorto'
            },
            {
                Header: 'Zona',
                accessor: 'zona.nombre',
                Width: 300,
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectZona
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(opt.label || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
                filter: 'includes'
            },
            {
                Width:100,
                Header: 'Color',
                accessor: 'color',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <div
                            className="rounded"
                            style={{
                                width: 70,
                                height: 25,
                                backgroundColor: row.original.color
                            }}
                        >
                        </div>
                    );
                }
            },{
                Width: 15,
                MaxWidth: 40,
                Header: 'Acc',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <DropdownButton
                            size="sm"
                            variant="success"
                            id={row.original.id}
                            title=""
                        >
                            <Dropdown.Item
                                className="d-flex justify-content-between align-items-center"
                                onClick={() => onOpenEditar(row.original)}
                            >
                                <i className="fa fa-edit"></i>
                                Editar
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="d-flex justify-content-between align-items-center"
                                onClick={() => onEliminar(row.original)}
                            >
                                <i className="fa fa-trash"></i>
                                Eliminar
                            </Dropdown.Item>
                        </DropdownButton>
                    );
                }
            },
        ],
        []
    );


    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 col-lg-6">
                            <h3 className="my-lg-3" data-aos="fade-right">Lugares</h3>
                        </div>
                        {
                            permisos['api.lugares.store'] && (
                            <div className="offset-2 col-4 offset-lg-4 col-lg-2 my-lg-3" data-aos="fade-up">
                                <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                                    NUEVO
                                </Button>
                            </div>
                        )}

                        <div className="col-12" data-aos="fade-right">
                                <TablePaginated columns={columns} data={items} />
                        </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LugaresPage);
