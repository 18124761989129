import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import PoligonosService from '@services/poligonos.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalPoligonoEditar from '@app/modals/poligono/ModalPoligonoEditar';
import {Button, ModalConfirm, SelectEquipo,SelectTipoCovertura} from '@app/components/index';
import usePermisos from '@app/hooks/usePermisos';

const PoligonosPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await PoligonosService.getAll();
        setItems(response.items);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Panel',
            message: '¿Esta seguro que desea eliminar el panel?',
            onConfirm: () => {
                PoligonosService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalPoligonoEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'Tecnologia',
                accessor: 'tipoEquipo',
                ClassName: 'm-0',
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectEquipo
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.value) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
                Cell: ({row}) => {
                    return row.original.tipoEquipo;
                },
                filter: 'includes'
            },
            {
                Header: 'Estado',
                accessor: 'tipoCovertura',
                ClassName: 'm-0',
                Filter: ({
                    column: {filterValue, setFilter, preFilteredRows, id}
                }) => {
                    return (
                        <SelectTipoCovertura
                            onChange={(opt) => {
                                if (opt) {
                                    setFilter(String(opt.value) || undefined);
                                } else {
                                    setFilter('');
                                }
                            }}
                            isClearable
                        />
                    );
                },
                Cell: ({row}) =>{
                    if(row.original.tipoCovertura === "DISPONIBLE"){
                        return (
                            <div className="text-center">
                                <button type="button" className="btn btn-success btn-xs">Disponible</button>
                            </div>
                        );
                    }
                    if(row.original.tipoCovertura === "MEDIO"){
                        return (
                                <div className="text-center">
                                    <button type="button" className="btn btn-warning btn-xs">Medio</button>
                                </div>

                        );
                    }
                    return (
                        <div className="text-center">
                            <button type="button" className="btn btn-danger btn-xs">Saturado</button>
                        </div>
                    );
                }
            },{
                Width:10,
                Header: 'Acc',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="xs">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }

        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3">
                        <h3 className="my-lg-3" data-aos="fade-right">Paneles</h3>
                    </div>
                    {
                        permisos['api.kml.poligonos.storePoligonos'] && (
                        <div className="offset-2 col-4 offset-lg-7 col-lg-2">
                            <Button color="primary" className="btn-block my-lg-3" data-aos="fade-up" onClick={() => onOpenEditar()}>
                                Subir KML
                            </Button>
                        </div>
                    )}
                    <div className="col-12" data-aos="fade-right">
                        <TablePaginated columns={columns} data={items} />
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PoligonosPage);