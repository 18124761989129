import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Styles = styled.div``;

const SelectEquipo = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    defaultProps
}) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        onChange(ev);
    };

    const options = [
        {
            value: 'ePMP',
            label: 'ePMP'
        },
        {
            value: 'PMP450m',
            label: 'PMP450m'
        }
    ];

    useEffect(() => {
        if (typeof value === 'string') {
            setSelectedValue(options.find((opt) => opt.value === value));
        }
    }, []);

    return (
        <Styles>
            <Select
                {...defaultProps}
                inputId={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPortalTarget={document.body}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectEquipo.defaultProps = {
    isClearable: false,
    value: null
};

export default SelectEquipo;
