import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import {endOfWeek, startOfWeek} from 'date-fns';
import {
    CALENDAR_ADD_CUADRILLA_LUGAR,
    CALENDAR_SUB_CUADRILLA_LUGAR,
    CALENDAR_CLEAR_CUADRILLA_LUGAR,
    CALENDAR_START_CUADRILLA_LUGAR,
    CALENDAR_ESTADO,
    CALENDAR_EVENTOS,
    CALENDAR_WS,
    CALENDAR_REPROGRAMACION,
} from '../actions/calendar';
const CALENDAR_CUADRILLA_LUGAR = 'CALENDAR_CUADRILLA_LUGAR';
const initialDate = new Date();

const initialState = {
    cuadrillaDisponiblesLugar: AuxiliarFunction.getItem(
        CALENDAR_CUADRILLA_LUGAR,
        []
    ),
    estado: {
        initialView: 'timeGridWeek',
        initialDate: initialDate,
        start: startOfWeek(initialDate, {weekStartsOn: 0}),
        end: endOfWeek(initialDate)
    },
    eventos: [],
    ws: null,
    itemReprogramado: null,
};

const reducer = (state = initialState, {type, payload}) => {
    const {cuadrillaDisponiblesLugar} = state;
    let aux;
    let aux2;
    switch (type) {
        case CALENDAR_ADD_CUADRILLA_LUGAR:
            aux2 = [...cuadrillaDisponiblesLugar];
            if (!cuadrillaDisponiblesLugar.find((cl) => cl.id === payload.id)) {
                aux2.push(payload);
                AuxiliarFunction.setItem(CALENDAR_CUADRILLA_LUGAR,aux2);
            }
            return {
                ...state,
                cuadrillaDisponiblesLugar: aux2
            };
        case CALENDAR_SUB_CUADRILLA_LUGAR:
            aux = cuadrillaDisponiblesLugar.filter(
                (cl) => cl.id !== payload.id
            );
            AuxiliarFunction.setItem(CALENDAR_CUADRILLA_LUGAR, aux);
            return {
                ...state,
                cuadrillaDisponiblesLugar: aux
            };
        case CALENDAR_CLEAR_CUADRILLA_LUGAR:
            AuxiliarFunction.setItem(CALENDAR_CUADRILLA_LUGAR, []);
            return {
                ...state,
                cuadrillaDisponiblesLugar: []
            };
        case CALENDAR_START_CUADRILLA_LUGAR:
            return {
                ...state,
                cuadrillaDisponiblesLugar: [...payload]
            }
        case CALENDAR_ESTADO:
            return {
                ...state,
                estado: payload
            };
        case CALENDAR_EVENTOS:
            return {
                ...state,
                eventos: payload
            };
        case CALENDAR_WS:
            return {
                ...state,
                ws: payload
            };
        case CALENDAR_REPROGRAMACION:
            return {
                ...state,
                itemReprogramado: payload,
            }
        default:
            return {
                ...state
            };
    }
};

export default reducer;
