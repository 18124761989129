import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Modal, Container} from 'react-bootstrap';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import { Button, FormContacto, FormContactoSchema, Select } from '@app/components/index';
import ContactosService from '@app/services/contactos.service';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import ContactoEstadoService from '@app/services/contactos.estado.service';

function ModalContactoEditarEstado({
    show, hideModal, item, onGuardar
}) {
    const handleClose = () => hideModal();
    const [estado, setEstado] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {

        const data = {
            id:item.id,
            estadoCRM:estado
        }
        setIsLoading(true);
        ContactoEstadoService.update(data).then(()=>{
            onGuardar();
            hideModal();
        })
    };

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar estado del contacto' : 'Registrar registrar Contacto'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Select
                        defaultValue={item.estadoCRM}
                        options={[
                            {
                                label:"EVALUANDO PROPUESTA",
                                value:"EVALUANDO PROPUESTA",
                            },
                            {
                                label:"NO LE INTERESA",
                                value:"NO LE INTERESA",
                            },
                        ]}
                        onChange={(opt)=>{
                            setEstado(opt.value);
                        }}
                    />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={onSubmit}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalContactoEditarEstado.propTypes = {

}

export default ModalContactoEditarEstado

