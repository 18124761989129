import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import AOS from 'aos';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {Button,FormContacto,FormContactoSchema} from '@app/components/index';
import {yupResolver} from '@hookform/resolvers/yup';
import ContactosService from '@app/services/contactos.service';
import ContactosAction from '@app/store/actions/contactos';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';

const ContactoNuevoSchema = Yup.object({
    tipoCanalId: Yup.mixed().required(),
    motivoTareaId: Yup.number().required(),
});

const ContactosF1Page = ({
    item,
    contacto,
    nextStep,
    iniciarContacto,
    user
}) => {
    AOS.init();
    const {puesto}=user;
    const [isLoading, setIsLoading] = useState(false);

    const {handleSubmit,control,errors,register, setValue, setError} = useForm({
        resolver: yupResolver(FormContactoSchema.concat(ContactoNuevoSchema)),
        defaultValues: item,
    });
    const onSubmit = (data) => {
        const form = {
            ...data,
            categoria:"NORMAL",
        };
        form.nombreLocalidad = data.Localidad.nombreLocalidad;
        form.idLocalidad = data.Localidad.idLocalidad;
        setIsLoading(true);
        // console.log(form)
        if(contacto){
            ContactosService.update({
                ...contacto,
                ...form,
            }).then((response)=>{
            const recuperarDatos={
                ...response,
                ...form,
                contactoId: response.id,
            }
                // console.log(recuperarDatos)
                iniciarContacto(recuperarDatos);
                nextStep();
            })
            .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
            .finally(()=>setIsLoading(false));
        } else {
            // console.log(form)
            ContactosService.register(form).then((response)=>{
                response.tipoCanalId = form.tipoCanalId;
                response.contactoId = response.id;
                response.motivoTareaId = form.motivoTareaId
                // console.log(response)
                iniciarContacto(response);
                nextStep();
            })
            .catch((e)=>AuxiliarFunction.extractErrors(e,setError))
            .finally(()=>setIsLoading(false));
        }
    }

    useEffect(() => {
        register('lugarId');
        register('tipoCanalId');
        register('motivoTareaId');
        register('condicionImpositiva');
    }, [register]);

    return (
        <div className="container-fluid border shadow-sm rounded-lg bg-white p-3 p-md-4" data-aos="fade-left">
            <FormContacto control={control} errors={errors} register={register} setValue={setValue} />
            <div className="col-12 bg-white text-right">
                <div className="row">
                    <div className="col-12 my-4 my-md-0 offset-md-6 col-md-6 my-lg-3 offset-lg-8 col-lg-4">
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            isLoading={isLoading}
                        >
                            {
                                contacto?('Guardar y Continuar'):('Registrar Contacto y Continuar')
                            }
                        </Button>
                        <Link to="/contactos">
                            <Button theme="default">
                                Cancelar
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
     )
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
    iniciarContacto: (contacto) => dispatch(ContactosAction.iniciarContacto(contacto)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactosF1Page);
