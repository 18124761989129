import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import NodosService from '@services/nodos.service';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import Dropdown from 'react-bootstrap/Dropdown';
import {showModal} from 'react-redux-modal-provider';
import ModalNodoEditar from '@app/modals/nodo/ModalNodoEditar';
import {Button, ModalConfirm} from '@app/components/index';
import usePermisos from '@app/hooks/usePermisos';

const NodosPage = () => {
    AOS.init();
    const permisos = usePermisos();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);

    const actualizar = async () => {
        const response = await NodosService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Nodo',
            message: '¿Esta seguro que desea eliminar el Nodo?',
            onConfirm: () => {
                NodosService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onOpenEditar = (item) => {
        showModal(ModalNodoEditar, {item: item, onGuardar: onGuardar});
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre'
            },
            {
                Header: 'Tecnologia',
                accessor: 'tipoEquipo'
            },
            {
                Width:10,
                Header: 'Acc',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <Dropdown size="xs">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onOpenEditar(row.original)}
                                >
                                    Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => onEliminar(row.original)}
                                >
                                    Eliminar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
            
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 col-lg-6" data-aos="fade-right">
                            <h3 className="ml-2 my-2 my-lg-3">Nodos</h3>
                        </div>
                        {
                            permisos['api.kml.nodos.storeNodos'] && (
                            <div className="offset-2 my-2 col-4 my-lg-2 offset-lg-4 col-lg-2 my-lg-3" data-aos="fade-up">
                                <Button color="primary" className="btn-block" onClick={() => onOpenEditar()}>
                                    Subir KML
                                </Button>
                            </div>
                        )}
                        <div className="col-12" data-aos="fade-right">
                            <div className="container-fluid">
                                <TablePaginated columns={columns} data={items} />
                            </div>
                        </div>
                    </div>
                </div>
            
            
            
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NodosPage);

