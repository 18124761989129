import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import CalendarioService from '@app/services/calendario.service';
import {Button, CuadrillaDisponibleCard, FormTareaHorario, InputCheck, ListCard, ModalConfirm, TimeSlider} from '@app/components/index';
import Collapse from 'react-bootstrap/Collapse';
import CalendarFunction from '@app/functions/CalendarFunction';
import ContratosService from '@app/services/contratos.service';
import TareasService from '@app/services/tareas.service';
import {showModal} from 'react-redux-modal-provider';
import MapaFunction from '@app/functions/MapaFunction';
import { Spinner}  from 'react-bootstrap';
import EchoWS from '@app/utils/ws';
import ModalCrearCuadrillaLugarDisponibles from '@app/modals/cuadrilla-lugar/ModalCrearCuadrillaLugarDisponibles';
import ModalCrearEspCuadrillaLugarDisponibles from '@app/modals/cuadrilla-lugar/ModalCrearEspCuadrillaLugarDisponibles';
import usePermisos from '@app/hooks/usePermisos';

const TareaForm5 = ({
    contacto,
    contrato,
    previousStep,
    goToStep,
}) => {
    const history = useHistory();
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [cuadrillaLugarSelected, setCuadrillaLugarSelected] = useState(null);
    const [time, setTime] = useState(null);
    const [filtroCuadrilla, setFiltroCuadrilla] = useState(false);

    const actualizar = () => {
        const form = {
            lugarId: contacto?.lugarId || 1,
        };
        form.tipoTareaId = contacto?.tipoTareaId;
        if(filtroCuadrilla){
            form.filtroTCua = 1;
        }
        setItems([]);
        setIsLoading(true);
        CalendarioService.disponibles(form).then((response)=>{
            if(contacto.coordenadas){
                setItems(MapaFunction.calculoCostos(response,contacto));
            } else {
                setItems(response);
            }
        })
        .finally(()=>{
            setIsLoading(false);
        });
    };

    const onGuardar = () => {
        const form = {
            ...contacto,
            contactoId: contacto.contactoId,
            clienteNombre: contacto.nombreCompleto,
            clienteDocumento: contacto.documento,
            clienteCelular: contacto.celular,
            clienteTelefono: contacto.telefono,
            clienteDireccion: contacto.direccion,
            clienteLatitud: contacto.latitud,
            clienteLongitud: contacto.longitud,
            clienteCategoria: contacto.categoria,
            cuadrillaLugarId: cuadrillaLugarSelected.id,
            fecha: cuadrillaLugarSelected.fecha,
            observaciones:'',
            horaIni: time.start,
            horaFin: time.end,
        }
        if(contrato?.id>0){
            form.contratoId = contrato.id;
        }
        if(cuadrillaLugarSelected.cartelAdvertencia){
            form.estadoTareaId = 8;
        }else{
            form.estadoTareaId = 1;
        }
        // console.log(form)
        TareasService.register(form)
        .then((response)=>{
            if(contacto && contacto?.crearContrato === 'SI') {
                const form2 = {
                    ...contacto,
                    ...contrato,
                    fechaInstalacion: cuadrillaLugarSelected.fecha,
                    tareaId: response.id,
                    contactoId: contacto.id,
                    clienteId: contacto.clienteId,
                }
                return ContratosService.register(form2)
                .then((resp)=>{
                    history.push('/contactos');
/*                     iniciarContacto(null);
                    iniciarContrato(null); */
                })
            }
            history.push('/contactos');
            return true;
        }).finally(()=>{
            setIsLoading(false);
        })
    }
    const onSubmit = () => {
        if(cuadrillaLugarSelected.cartelAdvertencia){
            showModal(ModalConfirm,{title:'¿Esta seguro que desea continuar?',message:'Aviso: La tarea se creara en estado de PENDIENTE. Logistica debera autorizar la misma.', onConfirm:onGuardar})
        }else{
            onGuardar()
        }
    }
    const onSubmitCrearCuadrilla = () => {
        showModal(ModalCrearEspCuadrillaLugarDisponibles,{onActualizar:actualizar})
    }
    const AnteriorStep = () =>{
        if( contacto.tipoTareaId === 3){
            previousStep();
        }else{
            goToStep(2);
        }
    }

    useEffect(() => {
        let listen;
        if(contacto && contacto.lugarId){
            actualizar();
            if(listen){
                listen.unsubscribe();
            }
            listen = EchoWS.private(`tareas.lugares.${contacto.lugarId}`)
                .listen('.tareas.created',actualizar)
                .listen('.tareas.deleted',actualizar)
                .listen('.tareas.updated',actualizar)
        }
        setCuadrillaLugarSelected(null)
        return () => {
            if(listen){
                listen.unsubscribe();
            }
        }
    }, [contacto,contrato,filtroCuadrilla]);

    useEffect(() => {
        if(cuadrillaLugarSelected){
            setTime(CalendarFunction.getDefaultTimeFromPeriod(cuadrillaLugarSelected.periodo));
        }
    }, [cuadrillaLugarSelected]);

    return (
        <>
            <div className="container-fluid border shadow-sm rounded-lg bg-white">
                <div className="row">
                    <div className="p-3 col-12 col-lg-12 col-md-12 text-gray my-lg-3 col-8">
                        <h5 className="ml-lg-3">
                            <i className="fas fa-angle-down mr-2"></i>
                            Horarios Disponibles
                            {
                                isLoading && (
                                    <Spinner animation="border" />
                                )
                            }
                        </h5>
                            <InputCheck
                                className="ml-3"
                                label="Todas las Cuadrillas"
                                onChange={() =>setFiltroCuadrilla(!filtroCuadrilla)}
                                checked={filtroCuadrilla}
                            />
                        {
                            contacto && (
                                <span className="ml-lg-3">{`En ${contacto.lugar?.nombre}`}</span>
                            )
                        }
                        {
                            permisos['api.cuadrillas.lugares.disp.disponibleCuadrilla'] && (
                            <Button theme="primary" className="ml-5" onClick={onSubmitCrearCuadrilla}>
                                Crear Cuadrilla
                            </Button>
                        )}
                    </div>
                    <Collapse
                        in={cuadrillaLugarSelected===null}
                    >
                    <div className="offset-lg-2 col-lg-8">
                        <ListCard isLoading={isLoading}>
                        {
                            items.map((item)=>{
                                return(
                                <CuadrillaDisponibleCard
                                    key={item.id}
                                    item={item}
                                    tipoTrabajoId={contacto?.tipoTareaId}
                                    onSelect={setCuadrillaLugarSelected}
                                    isSelectable={Boolean(item.id>0)}
                                    filtroCuadrilla={filtroCuadrilla}
                                />
                                )
                            })
                        }
                        </ListCard>
                    </div>
                    </Collapse>
                    <Collapse
                        in={cuadrillaLugarSelected!==null}
                    >
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="offset-2 col-8 offset-md-0 ml-md-3 col-md-11 col-lg-12">
                                <Button onClick={()=>setCuadrillaLugarSelected(null)}>
                                    Volver a seleccionar fecha
                                </Button>
                            </div>
                            <div className="offset-lg-2 col-lg-8">
                            {
                                cuadrillaLugarSelected && (
                                    <FormTareaHorario cuadrillaLugar={cuadrillaLugarSelected} onChange={setTime} />
                                )
                            }
                            </div>
                        </div>
                    </div>
                    </Collapse>
                </div>
                <div className="row">
                    <div className="col-12 my-3">
                        <div className="row">
                            <div className="offset-1 col-sm-12 offset-md-7 col-md-5 pb-md-5 offset-lg-8 col-lg-4 float-right">
                                <Button className="btn-secondary" onClick={AnteriorStep}>
                                    Anterior
                                </Button>
                                <Button
                                    disabled={cuadrillaLugarSelected === null}
                                    isLoading={isLoading}
                                    onClick={onSubmit}
                                >
                                    {
                                        contacto && contacto.crearContrato==='SI'?('Registrar tarea y trato'):('Registrar tarea')
                                    }
                                </Button>
                                <Link to="/contactos">
                                    <Button disabled={cuadrillaLugarSelected === null} theme="default">
                                        Cancelar
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
     )
};

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
    contrato: state.contactos.contrato,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TareaForm5);