import StepTitle from "@app/components/wizard/StepTitle";
import React, {useState, useEffect, useRef, createRef, forwardRef, useCallback} from "react";
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import StepWizard from 'react-step-wizard';

const StylesTitleSteps = styled.div`
    display: flex;
    .step{
        &:first-child{
            .box-step{
                padding-left:15px;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                @include border-radius(4px 0 0 4px);
                &:before{
                    border:none;
                }
            }
        }
        &:last-child{
            .box-step{
                padding-right:15px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                @include border-radius(0 4px 4px 0);
                &:after{
                    border:none;
                }
            }
            .box-circle::before{
                display: none;
            }
        }
        
    }
`

function Wizard({
    options,
    children,
}){
    const [stepIndex, setStepIndex] = useState(1);
    const [wizardInstance, setWizardInstance] = useState();
    const [currentOptions, setCurrentOptions] = useState(options.map(opt=>({...opt,validate:false})));

    const onStepChange = ({activeStep,previousStep}) => {
        if(previousStep<activeStep){
            currentOptions[previousStep-1].validate = true;
            currentOptions[activeStep-1].validate = true;
        }
        setCurrentOptions([...currentOptions]);
        setStepIndex(activeStep);
    }

    const onClickStep = (index) => {
        const currentIndex = index - 1;
        if((currentIndex-1)>0 && !currentOptions[currentIndex-1].validate){
            return;
        }
        wizardInstance.goToStep(index);
    }


    return(
    <>
        <StylesTitleSteps className="mb-2 mb-lg-3">
            {
                currentOptions.map((opt,key)=>{
                    const currentIndex = key + 1;
                    return (
                        <StepTitle 
                            {...opt} 
                            className="step" 
                            selected={stepIndex === currentIndex}
                            key={key} 
                            index={currentIndex}
                            onClick={() => onClickStep(currentIndex)}
                        />
                    );
                })
            }
        </StylesTitleSteps>
        <div className="row">
            <div className="col-12">
                <StepWizard 
                    instance={setWizardInstance}
                    isHashEnabled
                    onStepChange={onStepChange}
                    initialStep={stepIndex}
                >
                    {children}
                </StepWizard>
            </div>
        </div>
    </>
    );
}

Wizard.defaultProps = {
    options:[],
};

export default Wizard;