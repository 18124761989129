import React, {useState, useEffect} from 'react';
import {Collapse, Modal} from 'react-bootstrap';
import {Button, ListCard, MensajeCard} from '@app/components/index';
import AuthService from '@app/services/auth.service';
import * as AuthAction from '@app/store/actions/auth';
import { useDispatch } from 'react-redux';

function ModalMisMensajes({
    show,
    hideModal,
    item,
}) {
    const dispatch = useDispatch();
    const [mensajes, setMensajes] = useState([]);
    const [mensaje, setMensaje] = useState(item);
    const handleClose = () => hideModal();

    const actualizar = () => {
        return AuthService.mensajes().then((response)=>{
            setMensajes(response.items);
        });
    }

    useEffect(() => {
        if(mensaje){
            AuthService.mensajeById(mensaje.id).then(()=>{
                actualizar();
            });
            dispatch(AuthAction.mensajes())
        }
        return () => {
            
        }
    }, [mensaje])
    
    useEffect(() => {
        actualizar();
        return () => {
            
        }
    }, [])

    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Mis Mensajes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Collapse in={mensaje===null}>
                    <div className="row">
                        <div className="col-12">
                            <ListCard>
                            {
                                mensajes.map(m=>{
                                    return (
                                        <MensajeCard
                                            key={m.id}
                                            item={m}
                                            buttons={()=>(
                                                <Button 
                                                    theme="info" 
                                                    icon={m.fechaVisto?['fas','check-square']:['far','check-square']}
                                                    onClick={()=>setMensaje(m)}
                                                />
                                            )}
                                        />
                                    )
                                })
                            }
                            </ListCard>
                        </div>
                    </div>
                </Collapse>
                <Collapse in={mensaje}>
                    <div className="row">
                        <div className="col-12">
                        {
                            mensaje && (
                                <MensajeCard isFullContent item={mensaje} buttons={()=><Button theme="info" icon={['fas','times']} onClick={()=>setMensaje(null)} />} />
                            )
                        }
                        </div>
                    </div>
                    
                </Collapse>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalMisMensajes.defaultProps = {
    item: null,
};

export default ModalMisMensajes
