import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import FotoService from '@app/services/foto.service'
import { PhotoConsumer } from 'react-photo-view';
import { Carousel } from '@app/components/index';

const WrapImage = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
`;

function TabContactoResumen({
    contacto,
}) {
    const [fotos, setFotos] = useState([])

    const onClickVerFoto = () => {

    }

    useEffect(() => {
        FotoService.getAll({
            contactoId: contacto.id
        })
        .then((response)=>{
            setFotos(response);
        });
        return () => {

        }
    }, [])
    return (
        <div className="p-5">
            {
                fotos.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <Carousel size="small">
                                {
                                    fotos.map((fo)=>{
                                        return (
                                        <PhotoConsumer key={fo.id} src={fo.foto_url} intro={fo.foto_url}>
                                            <img src={fo.foto_url} alt={fo.tipoFoto.nombre} className="w-50 img-thumbnail" />
                                        </PhotoConsumer>
                                        )
                                    })
                                }
                            </Carousel>
                        </div>
                    </div>
                )
            }
            <div className="row">
                <div className="col-12"></div>
            </div>
            <div className="row">
                <div className="col-12"></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    contacto: state.contactos.current,
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(TabContactoResumen)
