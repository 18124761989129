import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function usePermisos() {
    const user = useSelector(state => state.auth.currentUser);

    const [resultado, setResultado] = useState({})

    useEffect(() => {
        if(user){
            const {rolDni,rolPuesto} = user;
            const json = {};
            if(rolDni){
                rolDni.acciones.forEach((accion)=>{
                    json[accion.nombre] = accion.metodo
                })
            }
            if(rolPuesto){
                rolPuesto.acciones.forEach((accion)=>{
                    json[accion.nombre] = accion.metodo
                })
            }
            setResultado(json);
        }
        return () => {

        }
    }, [user])

    return resultado
}

export default usePermisos
