import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { connect } from 'react-redux';
import AsesorService from '@app/services/asesor.service';

const Styles = styled.div``;

const SelectMultiPersonal = ({
    id,
    value,
    onChange,
    isInvalid,
    isClearable,
    disabled,
    stack,
    ...defaultProps
}) => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const onSelectValue = (ev) => {
        setSelectedValue(ev);
        if(Array.isArray(ev)){
            onChange(ev.map(e=>{
                const item = items.find(it=>it.dni === e.value);
                return {
                    ...e,
                    item: item,
                }
            }));
        } else if(ev){
            ev.item = items.find(it=>it.dni === ev.value);
            onChange(ev);
        } else {
            onChange(null);
        }
    };
    useEffect(() => {
        AsesorService.getAll().then((response) => {
            setItems(response);
        });
    }, []);
    
    useEffect(() => {
        const newOptions = items
            .map((item) => {
            return {
                value: item.dni,
                label: item.nombre
            };
        });
        setOptions(newOptions);
        if(Array.isArray(value)){
            setSelectedValue(value.map(va=>{
            const item = items.find(it=>it.dni === va);
            if(item){
                return {
                    label:item.nombre,
                    value:item.dni,
                };  
            }
            return {
                label:va.nombre,
                value:va.dni,
            };
            }))
        }
    }, [items]);

    const conditionalProps = {}
    if(stack && stack.length === 0){
        conditionalProps.menuPortalTarget = document.body;
    }
    return (
        <Styles>
            <Select
                {...defaultProps}
                isDisabled={disabled}
                inputId={id}
                name={id}
                className={`react-select-container ${
                    isInvalid ? 'is-invalid' : ''
                }`}
                noOptionsMessage={()=>"Sin opciones"}
                classNamePrefix="react-select"
                placeholder="Seleccione..."
                options={options}
                value={selectedValue}
                onChange={onSelectValue}
                isClearable={isClearable}
            />
        </Styles>
    );
};

SelectMultiPersonal.defaultProps = {
    isClearable: false,
    onChange: () => {},
};

const mapStateToProps = (state) => ({
    stack: state.modalProvider.stack,
})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(SelectMultiPersonal)