import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm, Controller} from 'react-hook-form';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col,Spinner} from 'react-bootstrap';
import TareasService from '@services/tareas.service';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Link, useHistory, useParams} from 'react-router-dom';
import usePermisos from '@app/hooks/usePermisos';
import {
    Button,
    DateSingle,
    SelectLugar,
    SelectTipoTarea,
    SelectMotivoTarea,
    SelectTipoCanal,
    TimeSlider,
    InputZohoSearch,
    InputEditor,
    MapClick,
    CuadrillaDisponibleCard,
    FlotaCard,
    InputCheck
} from '@app/components/index';
import CalendarFunction from '@app/functions/CalendarFunction';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import ModalTareaCancelar from '@app/modals/tarea/ModalTareaCancelar';
import ModalTareaRepro from '@app/modals/tarea/ModalReprogramarTarea';
import ModalCuadrillaLugarDiponibles from '@app/modals/cuadrilla-lugar/ModalCuadrillaLugarDiponibles';
import * as CalendarAction from '@app/store/actions/calendar';
import useCurrentLocation from '@app/hooks/useCurrentLocation';
import MapaFunction from '@app/functions/MapaFunction';
import ModalTareaLog from '@app/modals/tarea/ModalTareaLog';
import FechaFunction from '@app/functions/FechaFunction';
import SelectTratosxContacto from '@app/components/select/SelectTratosxContacto';
import InputContactNubiSearch from '@app/components/input/InputContactNubiSearch';
import SelectMotivoTicket from '@app/components/select/SelectMotivoTicket';


const schema = Yup.object({
    id:Yup.mixed(),
    lugarId: Yup.mixed().required(),
    tipoTareaId: Yup.mixed().required(),
    // motivoTareaId: Yup.mixed().when('tipoTareaId', {
    //     is: (id) => id > 0,
    //     then: Yup.mixed().required()
    // }),
    clienteDireccion: Yup.string().max(200),
    telefono: Yup.string()
        .max(200),
    celular: Yup.string()
        .max(200),
    clienteId: Yup.string()
        .matches(/^(\s*|\d+)$/, 'Debe ser solo numeros')
        .max(200),
    clienteCategoria: Yup.string().max(200),
    categoria: Yup.string().when('id',{
        is: (value) => value > 0,
        then: Yup.string(),
        otherwise: Yup.string().required(),
    }),
    tipoCanalId: Yup.string().required()
});


    function ModalCrearTarea({show, hideModal, onGuardar,itemReprogramado,location,cuadrillas,clienteReprogramacion,eliminarReprogramacion}) {
    const handleClose = () => hideModal();
    const { location:currentLocation } = useCurrentLocation();
    const {
        item,
        action,
    } = (location && location.state) || { item:{}};
    const permisos = usePermisos();
    const {calendar}=useSelector(state=>state)
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [cuadrillaDisponible, setcuadrillaDisponible] = useState(0)
    const [zonaId, setZonaId] = useState(0);
    const [tipoTrabajo, setTipoTrabajo] = useState(null);
    const [coordenadas, setCoordenadas] = useState((item && item.coordenadas)?MapaFunction.gJsonToGoogleMaps(item.coordenadas)[0]:currentLocation);
    const [time, setTime] = useState();
    const [template, setTemplate] = useState('');
    const [tipoTareaOption, setTipoTareaOption] = useState(item?.tipoTareaId || itemReprogramado?.tipoTareaId);
    const [tipoCanalId, setTipoCanalId] = useState(clienteReprogramacion !== undefined ? clienteReprogramacion.tipoCanalId : undefined);
    const [guardarCoordenadas, setGuardarCoordenadas] = useState("")
    const [validacionHora, setValidacionHora] = useState((FechaFunction.format(new Date(calendar.estado.initialDate), 'H')));
    const [hora, setHora] = useState((FechaFunction.format(new Date(calendar.estado.initialDate), 'HH:mm:ss')));
    const [fecha, setFecha] = useState(FechaFunction.format(calendar.estado.initialDate));
    const [tareaId, setTareaId] = useState(clienteReprogramacion !== undefined ? clienteReprogramacion.id : undefined)
    const [turno, setTurno] = useState('TM')
    const [verObservaciones, setVerObservaciones] = useState(false)
    const [btnGuardar, setBtnGuardar] = useState(false);
    const [contactoCrm, setContactoCrm] = useState()
    const [crmContact, setCrmContact] = useState()
    const [contratosSearch, setContratosSearch] = useState()

    const {register, handleSubmit, errors, setValue, watch, control} = useForm({
        resolver: yupResolver(schema)
    });

    const lugarId = watch('lugarId',item?.lugarId || 0);
    const cuadrillaLugarId = watch('cuadrillaLugarId', 0);
    const tipoTareaId = watch('tipoTareaId', item?.tipoTareaId || itemReprogramado?.tipoTareaId);
    const motivoTareaId = watch('motivoTareaId',item?.motivoTareaId || 0);
    const clienteNombre = watch('clienteNombre', '');
    const clienteDocumento = watch('clienteDocumento', '');

    const setDatosClienteReprogramacion = () =>{
        setValue('categoria', clienteReprogramacion.categoria);
        setValue('clienteNombre', clienteReprogramacion.clienteNombre);
        setValue('clienteDocumento', clienteReprogramacion.clienteDocumento || '');
        setValue('clienteDireccion', clienteReprogramacion.clienteDireccion);
        setValue('clienteTelefono', clienteReprogramacion.clienteTelefono);
        setValue('clienteCelular', clienteReprogramacion.clienteCelular);
        setValue('clienteCategoria', clienteReprogramacion.clienteCategoria);
        setValue('numeroContrato', clienteReprogramacion.contratoId);
        setValue('cuadrillaLugarId', cuadrillaDisponible);
        setValue('lugarId', clienteReprogramacion.lugarId);
        setValue('motivoTareaObservaciones', clienteReprogramacion.motivoTareaObservaciones);
        setValue('motivoTareaId', clienteReprogramacion.motivoTareaId);
        setValue('tipoCanalId', clienteReprogramacion.tipoCanalId);
        setValue('tipoTareaId', clienteReprogramacion.tipoTareaId);
        setTemplate(clienteReprogramacion.motivoTareaObservaciones || '');
        if(clienteReprogramacion.coordenadas){
            setCoordenadas(MapaFunction.gJsonToGoogleMaps(clienteReprogramacion.coordenadas)[0])
        }else{
            setCoordenadas(currentLocation)
        }
    }

    const setCrmData = (data) => {

        if(data.cuenta){
            // setValue('clienteCelular',data.celular_c)
            setValue('cuentaId', data.cuenta);
            setValue('clienteDocumento', data.dni_cuit.numero);
            setValue('clienteNombre', data.nombre_completo);
            setValue('clienteTelefono', data.contacto.telefono);
        }else{
            setValue('clienteCelular',data.celular_c)
            setValue('contactoId',data.id_c)
            setValue('clienteTelefono',data.telefono_c)
            setValue('clienteNombre', data.nombreCompleto_c);
            setValue('clienteDocumento', data.documento_c);
        }
    };

    const setTratoData = (data) => {

        if(data?.cuenta){
                setValue('cuentaContratoId',data.numero)
                setValue('cuentaId',data.cuenta)
                setValue('numeroContrato',data.numero)
                setCoordenadas({lat:Number(data.ubicacion.latitud),lng:Number(data.ubicacion.longitud)})
                setValue('clienteDireccion',data.ubicacion.domicilio)
        }else if(data.id_t){
            setValue('telefono',data.telefono_c)
            setValue('numeroContrato',data.id_t)
            setValue('contratoId',data.id_t)
            setCoordenadas({lat:Number(data.latitud_t),lng:Number(data.longitud_t)})
            setValue('clienteDireccion',data.direccion_t)
            setValue('clienteCelular',data.celular_t)
        }
    }

    const copiarCoordenadas = () =>{
        const aux = document.createElement("input");
        aux.setAttribute("value",guardarCoordenadas);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }

    useEffect(() => {
        register('id');
        register('clienteNombre');
        register('zohoDESKContactId');
        register('fecha');
        register('cuadrillaLugarId');
        register('cuentaContratoId');
        register('cuentaId');
        register('contratoId');
        register('lugarId');
        register('tipoTareaId');
        register('motivoTareaId');
        register('motivoTareaObservaciones');
        register('numeroContrato');
        register('tipoCanalId');
        register('motivoTareaId');
        register('clienteTelefono');
        register('clienteDireccion');
        register('clienteCelular');
    }, [register]);


    const eliminarRepro = () =>{
        eliminarReprogramacion();
        hideModal();
    }

    const onSubmit = (data) => {
        setBtnGuardar(true)
        const form = {
            ...clienteReprogramacion,
            ...data,
            clienteLatitud: coordenadas.lat,
            clienteLongitud: coordenadas.lng,
            horaIni: time.start,
            horaFin: time.end,
            fecha:fecha,
            motivoTareaObservaciones:template,
            cuadrillaLugarId,
            observaciones:"CALENDAR"
        };

        if(clienteReprogramacion){
            form.tareaId = clienteReprogramacion.id;
        }
        if(crmContact?.cuenta){
            form.cuentaId = crmContact?.cuenta;
        }else{
            form.contactoId = crmContact?.id_c;
        }
        TareasService.register(form)
        .then(() => {
            onGuardar();
            eliminarRepro();
            hideModal();
            setBtnGuardar(false);
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        const filtrarZona = (cuadrillas.filter(cuadrilla => cuadrilla.id === Number(cuadrillaDisponible)))

        if(filtrarZona.length !== 0){
            if(filtrarZona[0].zonaIdTM !== null && filtrarZona[0].zonaIdTT === null){
                setZonaId(filtrarZona[0].zonaIdTM)
            }else if(filtrarZona[0].zonaIdTT  !== null && filtrarZona[0].zonaIdTM === null ){
                    setZonaId(filtrarZona[0].zonaIdTT)
            }else if(filtrarZona[0].zonaIdTT === filtrarZona[0].zonaIdTM){
                setZonaId(filtrarZona[0].zonaIdTT)
            }else if(filtrarZona[0].zonaIdTT !== null && filtrarZona[0].zonaIdTM !==null && filtrarZona[0].zonaIdTM !== filtrarZona[0].zonaIdTT && validacionHora >=8 && validacionHora < 13){
                setZonaId(filtrarZona[0].zonaIdTM)
            }else if(filtrarZona[0].zonaIdTT !== null && filtrarZona[0].zonaIdTM !==null && filtrarZona[0].zonaIdTM !== filtrarZona[0].zonaIdTT && validacionHora >= 13){
                setZonaId(filtrarZona[0].zonaIdTT)
            }else{
                setZonaId(undefined)
            }
        }
}, [cuadrillaDisponible, zonaId])


useEffect(() => {
    if(Number(validacionHora) === 12){
        setTime({
            start: '11:00:00',
            end: '12:00:00',
        });
    }else if(Number(validacionHora) === 17){
        setTime({
            start: '16:00:00',
            end: '17:00:00',
        });
        setTurno('TT');
    }else{
        setTime({
            start: hora,
            end: `${String((Number(validacionHora)+1))}:00:00`,
        });
    }

    if(validacionHora >= 8 && validacionHora < 13 ){
        setTurno('TM');
    }else{
        setTurno('TT');
    }
}, [])

useEffect(() => {
    if(clienteReprogramacion){
        setDatosClienteReprogramacion();
    }
}, [])


    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <div className="container">
                    <div className="row">
                        <div className="col-8">
                            <h3>
                                { clienteReprogramacion !== undefined ? "Reprogramación de Tarea" : "Tarea Nueva" }
                                { isLoading && <Spinner className="ml-2 mb-2 spinner-border text-primary" animation="border" /> }
                            </h3>
                        </div>
                        <div className="col-4">
                            <Button
                                className="btn p-2 btn-warning text-dark float-right"
                                type="button"
                                onClick={copiarCoordenadas}
                            >
                                    COPIAR COORDENADAS
                                    <i className="fas fa-map-marker-alt ml-1" />
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="fecha"
                                    >
                                        Fecha
                                    </label>
                                    <DateSingle
                                        id="fecha"
                                        value={calendar.estado.initialDate}
                                        ref={register}
                                        name="fecha"
                                        disabled
                                    />
                                    {errors.fecha ? (
                                        <div className="default-invalid-feedback">
                                            {errors.fecha?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="cuadrillasDisponibles"
                                    >
                                        Cuadrilla Disponible
                                    </label>
                                    <select
                                        id="cuadrillasDisponibles"
                                        onChange={
                                            (e)=>{setcuadrillaDisponible(e.target.value);
                                                setValue('cuadrillaLugarId', e.target.value);
                                            }}
                                        name="cuadrillasDisponibles"
                                        className={`form-control ${
                                            errors.cuadrillasDisponibles ? 'is-invalid' : ''
                                        }`}
                                    >
                                        <option value={null}>Seleccione una cuadrilla</option>
                                        {
                                            validacionHora >= 8 && validacionHora < 13 ? (
                                            cuadrillas.map((cuadrilla)=>
                                                (
                                                    <option
                                                        value={cuadrilla.id}
                                                        disabled={cuadrilla.periodoNombre === "Turno tarde"}
                                                    >
                                                        {cuadrilla.descripcion.split(" - T",1)}
                                                        - TAREAS DISPONIBLES:
                                                        {cuadrilla.tareasDisponiblesCantidadTM + cuadrilla.tareasDisponiblesCantidadTT}
                                                    </option>
                                                )
                                            )
                                            ):(
                                                cuadrillas.map((cuadrilla)=>
                                                (
                                                    <option
                                                        value={cuadrilla.id}
                                                        disabled={cuadrilla.periodoNombre === "Turno mañana"}
                                                    >
                                                        {cuadrilla.descripcion.split(" - T",1)}
                                                        - TAREAS DISPONIBLES:
                                                        {cuadrilla.tareasDisponiblesCantidadTM + cuadrilla.tareasDisponiblesCantidadTT}
                                                    </option>
                                                )
                                            )
                                            )
                                        }
                                    </select>
                                    {errors.categoria ? (
                                        <div className="invalid-feedback">
                                            {errors.categoria?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoTareaId"
                                    >
                                        Tipo de Tarea
                                    </label>
                                    <SelectTipoTarea
                                        id="tipoTareaId"
                                        value={clienteReprogramacion !== undefined ? clienteReprogramacion.tipoTareaId : null}
                                        tipoTrabajo={tipoTrabajo}
                                        isInvalid={Boolean(errors.tipoTareaId)}
                                        onChange={(opt) => {
                                            setValue('tipoTareaId', opt.value);
                                            setTipoTareaOption(opt);
                                            setTemplate('');
                                        }}
                                    />
                                    {errors.tipoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="lugarId"
                                    >
                                        Lugar
                                    </label>
                                    <SelectLugar
                                        id="lugarId"
                                        zonaId={zonaId}
                                        value={clienteReprogramacion !== undefined ? clienteReprogramacion.lugarId : null}
                                        isInvalid={Boolean(errors.lugarId)}
                                        onChange={(opt) => {
                                            setValue('lugarId', opt.value || undefined);
                                        }}
                                    />
                                    {errors.lugarId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.lugarId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaId"
                                    >
                                        Motivo de la Tarea
                                    </label>
                                    <SelectMotivoTarea
                                        id="motivoTareaId"
                                        value={motivoTareaId}
                                        tipoTareaId={tipoTareaId}
                                        isInvalid={Boolean(
                                            errors.motivoTareaId
                                        )}
                                        onChange={(opt) => {
                                            setValue('motivoTareaId', opt.value)
                                            setTemplate(opt.descripcion)
                                            // setDataTicket(opt)
                                        }}
                                    />
                                    {errors.motivoTareaId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.motivoTareaId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-12">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="motivoTareaObservaciones"
                                        // onClick={() => {setVerObservaciones(!verObservaciones)}}
                                    >
                                        <button
                                            className="btn btn-secondary"
                                            type="button"
                                            onClick={() => setVerObservaciones(!verObservaciones)}
                                        >
                                            Observaciones
                                            <i className={verObservaciones === false ? ("fas fa-chevron-down ml-3") : ("fas fa-chevron-up ml-3")} />
                                        </button>
                                    </label>
                                    {verObservaciones !== false && (
                                        <InputEditor
                                            id="motivoTareaObservaciones"
                                            template={template}
                                            disabled={!(tipoTareaId > 0)}
                                            onChange={(html) => {
                                                setValue(
                                                    'motivoTareaObservaciones',
                                                    html
                                                    );
                                                }}
                                        />
                                    )}
                                    {errors.motivoTareaObservaciones ? (
                                        <div className="default-invalid-feedback">
                                            {
                                                errors.motivoTareaObservaciones
                                                ?.message
                                            }
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-lg-12 mt-1 mb-2">
                                <TimeSlider
                                    defaultPeriodo={time}
                                    value={time}
                                    onChange={setTime}
                                    id="timerInput"
                                    turno={turno}
                                />
                            </div>

                            <div className="col-lg-4">
                                <label
                                    className="label-control"
                                    htmlFor="clienteNombre"
                                >
                                    Contacto Crm
                                </label>
                                {!clienteReprogramacion ? (
                                    <>
                                <InputContactNubiSearch
                                    control={control}
                                    id="clienteNombre"
                                    field="clienteNombre"
                                    name="clienteNombre"
                                    onChange={(e)=>setContactoCrm(e.target.value)}
                                    onSelect={(opt) => {
                                        if (opt && opt.length > 0) {
                                            setCrmContact(opt[0]);
                                            setCrmData(opt[0]);
                                            if(opt[0]?.cuenta){
                                                setContratosSearch({ cuentaId: opt[0].cuenta,type: 'nubicom'})
                                            }else{
                                                setContratosSearch({ contactoId: opt[0].id_cxe,type: 'nubicom'})
                                            }
                                        }
                                    }}
                                />
                                {errors.clienteNombre&& (
                                    <div className="default-invalid-feedback">
                                        {errors.clienteNombre?.message}
                                    </div>
                                )}
                                    </>
                                ):(
                                    <div className='form-group'>
                                        <input
                                            type="text"
                                            disabled
                                            className='form-control'
                                            value={clienteReprogramacion.clienteNombre}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className={clienteReprogramacion ? "d-none" : "col-lg-4"}>
                                <label
                                    className="label-control"
                                    htmlFor="clienteNombre"
                                >
                                    Contrato
                                </label>
                                <SelectTratosxContacto
                                    id="contratoId"
                                    filtro={contratosSearch}
                                    onChange={(opt) => {
                                        setTratoData(opt)
                                        if(opt.contrato){
                                            // setContratoSoft(opt.value)
                                            setValue(
                                                'contratoId',
                                                opt.value
                                            );
                                        }else{
                                            // setContrato(opt.value)
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className='form-group'>
                                    <label
                                        className="label-control"
                                        htmlFor="clienteDocumento"
                                    >
                                        Dni del cliente
                                    </label>
                                    <input
                                        name="clienteDocumento"
                                        id="clienteDocumento"
                                        onChange={(e)=>setValue(clienteDocumento,e.target.value)}
                                        className="form-control"
                                        ref={register}
                                        readOnly
                                    />
                                    {errors.clienteDocumento ? (
                                        <div className="default-invalid-feedback">
                                            {errors.clienteDocumento?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="numeroContrato"
                                    >
                                        Numero de Contrato
                                    </label>
                                    <input
                                        id="numeroContrato"
                                        className={`form-control ${
                                            errors.numeroContrato ? 'is-invalid' : ''
                                        }`}
                                        type="text"
                                        name="numeroContrato"
                                        ref={register}
                                        autoComplete="off"
                                        readOnly
                                    />
                                    {errors.numeroContrato ? (
                                        <div className="invalid-feedback">
                                            {errors.numeroContrato?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteDireccion"
                                    >
                                        Dirección
                                    </label>
                                    <input
                                        id="clienteDireccion"
                                        className={`form-control ${
                                            errors.clienteDireccion
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteDireccion"
                                        ref={register}
                                        readOnly
                                    />
                                    {errors.clienteDireccion ? (
                                        <div className="invalid-feedback">
                                            {errors.direccion?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteTelefono"
                                    >
                                        Telefono
                                    </label>
                                    <input
                                        id="clienteTelefono"
                                        className={`form-control ${
                                            errors.telefono
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteTelefono"
                                        ref={register}
                                        readOnly
                                    />
                                    {errors.telefono ? (
                                        <div className="invalid-feedback">
                                            {errors.telefono?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="clienteCelular"
                                    >
                                        Celular
                                    </label>
                                    <input
                                        id="clienteCelular"
                                        className={`form-control ${
                                            errors.celular
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        type="text"
                                        name="clienteCelular"
                                        ref={register}
                                    />
                                    {errors.celular ? (
                                        <div className="invalid-feedback">
                                            {errors.celular?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="categoria"
                                    >
                                        Categoria
                                    </label>
                                    <select
                                        id="categoria"
                                        name="categoria"
                                        className={`form-control ${
                                            errors.categoria ? 'is-invalid' : ''
                                        }`}
                                        ref={register}
                                    >
                                        <option value="">
                                            Seleccione una opcion
                                        </option>
                                        <option value="NORMAL">NORMAL</option>
                                        <option value="VIP 1">VIP 1</option>
                                        <option value="VIP 2">VIP 2</option>
                                        <option value="VIP 3">VIP 3</option>
                                    </select>
                                    {errors.categoria ? (
                                        <div className="invalid-feedback">
                                            {errors.categoria?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label
                                        className="label-control"
                                        htmlFor="tipoCanalId"
                                    >
                                        Canal
                                    </label>
                                    <SelectTipoCanal
                                        id="tipoCanalId"
                                        value={tipoCanalId}
                                        onChange={(opt) => {
                                            setValue('tipoCanalId', opt.value);
                                        }}
                                    />
                                    {errors.tipoCanalId ? (
                                        <div className="default-invalid-feedback">
                                            {errors.tipoCanalId?.message}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 border bg-white shadow-sm ml-1 mt-2 mt-md-0 mt-lg-0 py-2 rounded">
                                <MapClick
                                    id="mapaComercial"
                                    zoom={15}
                                    coordinates={coordenadas}
                                    onChange={setCoordenadas}
                                    options={{
                                        maxZoom: 19,
                                        minZoom: 14,
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12">
                    <div className="row">
                            {clienteReprogramacion !== undefined &&(
                                <div className="col-3">
                                    <button className="btn btn-sm btn-danger" type="button" onClick={eliminarRepro}>Dejar de reprogramar</button>
                                </div>
                            )}
                        <div className="col-5 text-center">
                            {`Coordenadas :   ${coordenadas.lat}, ${coordenadas.lng}`}
                        </div>
                        <div className={clienteReprogramacion !== undefined ? ("col-4 text-right") : ("offset-3 col-4 text-right")}>
                                <Button theme="default" onClick={handleClose}>
                                    CANCELAR
                                </Button>
                            {
                                (permisos['api.tareas.store'] ||
                                permisos['api.tareas.update']) && (
                                <Button
                                    disabled={btnGuardar}
                                    theme="primary"
                                    onClick={
                                        handleSubmit(onSubmit)
                                    }
                                    isLoading={isLoading}
                                >
                                    GUARDAR
                                </Button>
                            )}

                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

ModalCrearTarea.defaultProps = {
    show: false,
    item: {
        id: 0,
        latitud: -24.7821,
        longitud: -65.4232,
        motivoTareaObservaciones: '',
        lugarId: 0,
        tipoTareaId: 0,
        tipoCanalId: 0,
        motivoTarea: {
            id: 0,
        }
    },
    cuadrillas: []
};

const mapStateToProps = (state) => ({
    itemReprogramado: state.calendar.itemReprogramado,
});

const mapDispatchToProps = (dispatch) => ({
    agregarReprogramacion: (data) =>  dispatch(CalendarAction.agregarReprogramacion(data)),
    recuperar: (data) => dispatch(CalendarAction.recuperarEventos(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalCrearTarea);