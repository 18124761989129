import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import VehiculosService from '@services/vehiculos.service';
import Modal from 'react-bootstrap/esm/Modal';
import {Row,Container,Col} from 'react-bootstrap';
import {Button} from '@app/components/index';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = Yup.object({

});

function ModalVehiculoEditar({show, hideModal, item, onGuardar}) {
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => hideModal();

    const {register, handleSubmit, errors, setValue, watch} = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
    }, [register]);

    const onSubmit = (data) => {
        const form = {
            ...data,
            id: item.id
        };
        setIsLoading(true);
        if (form.id > 0) {
            VehiculosService.update(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        } else {
            VehiculosService.register(form)
                .then(() => {
                    onGuardar();
                    hideModal();
                })
                .finally(() => {
                    setIsLoading(true);
                });
        }
    };

    useEffect(() => {
        if (item.id > 0) {
            setValue('patente', item.patente);
            setValue('marca',item.marca);
            setValue('modelo',item.modelo);
            setValue('estado',item.estado);
            setValue('cantidad',item.cantidad);
        }
    }, []);

    return (
        <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id > 0 ? 'Editar Vehiculo' : 'Registrar Vehiculo holaaa'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="patenteL">
                                        Patente
                                    </label>
                                    <input
                                        className={`form-control ${errors.patente? 'is-invalid': ''}`}
                                        id="patente"
                                        name="patente"
                                        type="text"
                                        icon="fas-car"
                                        autoComplete="off"
                                        required
                                        ref={register}
                                    />
                                    {errors.patente && (
                                        <div className="invalid-feedback">
                                            {errors.patente.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="marcaL">
                                        Marca
                                    </label>
                                    <input
                                        className={`form-control ${errors.marca? 'is-invalid': ''}`}
                                        id="marca"
                                        name="marca"
                                        type="text"
                                        autoComplete="off"
                                        required
                                        ref={register}
                                    />
                                    {errors.marca && (
                                        <div className="invalid-feedback">
                                            {errors.marca.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="modeloL">
                                        Modelo
                                    </label>
                                    <input
                                        className={`form-control ${errors.modelo? 'is-invalid': ''}`}
                                        id="modelo"
                                        name="modelo"
                                        type="text"
                                        autoComplete="off"
                                        required
                                        ref={register}
                                    />
                                    {errors.modelo && (
                                        <div className="invalid-feedback">
                                            {errors.modelo.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="estadoL">
                                        Estado
                                    </label>
                                    <select
                                        className={`form-control ${errors.estado? 'is-invalid': ''}`}
                                        id="estado"
                                        name="estado"
                                        ref={register}
                                    >
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                    {errors.estado && (
                                        <div className="invalid-feedback">
                                            {errors.estado.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="form-group">
                                    <label className="label-control" htmlFor="cantidadL">
                                        Pasajeros
                                    </label>
                                    <select
                                        className={`form-control ${errors.cantidad? 'is-invalid': ''}`}
                                        id="cantidad"
                                        name="cantidad"
                                        ref={register}
                                    >
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="5">5</option>
                                    </select>
                                    {errors.cantidad && (
                                        <div className="invalid-feedback">
                                            {errors.cantidad.message}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CANCELAR
                </Button>
                <Button
                    theme="primary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                >
                    GUARDAR
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalVehiculoEditar.defaultProps = {
    show: false,
    item: {
        id: 0,
        patente: '',
        marca: '',
        modelo: '',
        estado: '',
        cantidad: ''
    }
};

export default ModalVehiculoEditar;