import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {connect, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Prompt} from 'react-router-dom';
import TablePaginated from '@app/components/table-paginated/TablePaginated';
import {showModal} from 'react-redux-modal-provider';
import {Button, ModalConfirm} from '@app/components/index';
import AuxiliarFunction from '@app/functions/AuxiliarFunction';
import {startLoadingBlock, endLoadingBlock } from '@app/store/slices/modal.slice';
import usePermisos from '@app/hooks/usePermisos';
import AdministracionService from '@app/services/coorporativo/administracion.service';
import ModalAdministracionEditar from '@app/modals/coorporativos/ModalAdministracionEditar';
import FechaFunction from '@app/functions/FechaFunction';
import CamposVisiblesService from '@app/services/coorporativo/camposVisibles.service';

const AdministracionPage = ({user}) => {
    AOS.init();
    const permisos = usePermisos();
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const [items, setItems] = useState([]);
    const [isLoadingExportar, setisLoadingExportar] = useState(false);
    const [estadoTabla, setEstadoTabla] = useState('d.cliente');
    const [tituloTabla, setTituloTabla] = useState()
    const [tabla, setTabla] = useState([])

    const actualizar = async () => {
        const response = await AdministracionService.getAll();
        setItems(response);
    };

    const onGuardar = () => {
        actualizar();
    };

    const onEliminar = (item) => {
        showModal(ModalConfirm, {
            title: 'Eliminar Datos',
            message: '¿Esta seguro que desea eliminar?',
            onConfirm: () => {
                AdministracionService.destroy(item.id).then(() => {
                    actualizar();
                });
            }
        });
    };

    const onClickExportar = () => {
        setisLoadingExportar(true);
        dispatch(startLoadingBlock());
        AuxiliarFunction.downloadFile(AdministracionService.exportar()).finally(()=>{
            setisLoadingExportar(false);
            dispatch(endLoadingBlock());
        });
    }

    const onOpenEditar = async (item) => {

        const query={
            camposVisibles:true
        }

        const camposVisibles = await CamposVisiblesService.getAll(query);
        showModal(ModalAdministracionEditar, {item: item, onGuardar: onGuardar, camposVisibles:camposVisibles});
    };
    
    const onVerMas = (item) =>{
        showModal(ModalAdministracionEditar, {item: item, onGuardar: onGuardar});

    }

  
    const administracion = React.useMemo(
        () => [
            {
                Header: 'Cliente',
                accessor: 'contactoCorporativo.clienteNombre',
                Width: 400
            },
            {
                Header: 'Id Servicio',
                accessor: 'contactoCorporativo.servicioId',
                Width: 200
            },
            {
                Header: 'ID Fact',
                accessor: 'facturaId',
                Width: 100
            },
            {
                Header: 'Cuit',
                accessor: 'cuit',
                Width: 200                
            },
            {
                Header: 'Domicilio Fiscal',
                accessor: 'domicilioFiscal',
                Width: 300                
            },
            {
                Header: 'Mail Fac',
                accessor: 'emailFacturacion',
                Width: 300                
            },
            {
                Header: 'Contactos Administrativos',
                accessor: 'contactoAdministrativo',
            },
            {
                Header: 'Fecha Fin de C',
                accessor: 'fecha',
                Width: 200              
            },
            {
                Header: 'Costo MB',
                accessor: 'costoMega',
                Width: 200                

            },
            {
                Header: 'Editado',
                accessor: 'updatedAt',
                Cell: ({row}) => {
                    return (
                        <span>
                            {FechaFunction.format(row.original.updatedAt,'dd-MM-yyyy')}
                        </span>
                    );
                }
            },
            // {
            //     Header: 'Costo por Inst.',
            //     accessor: 'costoInstalacion',
            //     Width: 200                

            // },
            // {
            //     Header: 'Contrato firmado',
            //     accessor: 'contratoFirmado',
            //     Width: 100
            // },
            // {
            //     Header: 'Orden de compra',
            //     accessor: 'ordenCompra',
            //     Width: 100
            // },
            // {
            //     Header: 'Contracto Certificado',
            //     accessor: 'contratoCertificado',
            //     Width: 100
            // },
            // {
            //     Header: 'Agenda',
            //     accessor: 'agenda',
            //     Width: 300
            // },
            // {
            //     Header: 'Estatuto de la Empresa',
            //     accessor: 'estatutoEmpresa',
            //     Width: 30
            // },
            // {
            //     Header: 'Designacion de acta act',
            //     accessor: 'designacionActaActualizada',
            //     Width: 30
            // },
            // {
            //     Header: 'Contrato Social',
            //     accessor: 'contratoSocial',
            //     Width: 30,
            // },
            // {
            //     Header: 'Poder a nombre...',
            //     accessor: 'poderNombreFirma',
            //     Width: 30
            // },
            // {
            //     Header: 'Copia DNI',
            //     accessor: 'copiaDni',
            //     Width: 30
            // },
            // {
            //     Header: 'Constancia Afip',
            //     accessor: 'constanciaAfip',
            //     Width: 30
            // },
            // {
            //     Header: 'Constancia Rentas',
            //     accessor: 'constanciaRenta',
            //     Width: 30
            // },
            // {
            //     Header: 'Pagos al día',
            //     accessor: 'pagoAlDia',
            //     Width: 30,
            // },
            // {
            //     Header: 'Observaciones',
            //     accessor: 'observacionFacturacion'
            // },
            {
                Width: 30,
                Header: 'Editar',
                accessor: 'id',
                disableSortBy: true,
                ClassName: 'm-0',
                Filter: false,
                Cell: ({row}) => {
                    return (
                        <>
                            <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => onOpenEditar(row.original)}
                            >
                                <i className="fas fa-edit" />
                            </button>  
                        </>
                               
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        actualizar();
    }, []);

    return (
        <>
                <Prompt when={isLoadingExportar} message='Se esta realiando la exportacion ¿Desea continuar?' />
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-lg-3 my-lg-3">
                                <h3 className="ml-1" data-aos="fade-right">Administración</h3>
                            </div>
                            <div className="col-lg-5 mt-2">
                            </div>
                            {/* <div className="offset-2 col-lg-2 mt-2">
                                <Button disabled={isLoadingExportar} icon={['fas','file-excel']} className="btn btn-block btn-success" data-aos="fade-up" onClick={()=>onClickExportar()}>
                                    EXCEL
                                </Button>
                            </div> */}


                            <div className="col-12 overflow-auto">
                                    <TablePaginated columns={administracion} data={items} />
                            </div>
                    </div>
                </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdministracionPage);
