import React, {useState, useEffect} from 'react';
import {Modal, Spinner } from 'react-bootstrap';
import {Button, ContratoCard, ListCard} from '@app/components/index';
import TareasService from '@app/services/tareas.service';
import ContactosService from '@app/services/contactos.service';

function ModalContactoContratoSeleccionar({
    show,
    hideModal,
    item,
    onGuardar,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [contacto, setContacto] = useState(item);

    const handleClose = () => hideModal();

    const onClickSeleccionar = (contrato) => {
        onGuardar(contrato);
        hideModal();
    }

    useEffect(() => {
        setIsLoading(true)
        ContactosService.getById(item.id)
            .then(setContacto)
            .finally(()=>setIsLoading(false));
        return () => {
            
        }
    }, [])
    return (
        <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`#${item.id} CONTACTO `}
                    {
                        isLoading && (
                            <Spinner animation="border" />
                        )
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <ListCard>
                        {
                        contacto.contratos && contacto.contratos.map((contrato)=>{
                            return (
                                <ContratoCard 
                                    canShowMore 
                                    item={contrato} 
                                    key={contrato.id}
                                    buttons={()=>(
                                        <Button theme="info" onClick={()=>onClickSeleccionar(contrato)}>Continuar</Button>
                                    )}
                                />
                            )
                        })
                        }
                        </ListCard>
                    </div>
                </div>
                
            </Modal.Body>
            <Modal.Footer>
                <Button theme="default" onClick={handleClose}>
                    CERRAR
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalContactoContratoSeleccionar.defaultProps = {
    
};

export default ModalContactoContratoSeleccionar
