import React, { useEffect } from 'react'
import styled, { css } from 'styled-components';
const StylesContainer = styled.div`
    text-align: center;

    .widget-title{
        font-weight: bold;
    }
    .widget-subtitle{

    }

    ${props => props.active && css`
        background-color:#39C0ED;
        border-radius:3px 3px 2px 2px;
        color:white;
    `}
`;


function WidgetTitle({
    title,
    subtitle,
    active
}){

    return (
        <StylesContainer active={active}>
            <div className={`widget-box border border-top-0 border-left-0 border-right-0 ${active && 'active'}`}>
                <div className="widget-title">{title}</div>
                <div className="widget-subtitle">{subtitle}</div>
            </div>
        </StylesContainer>
    )
}

export default WidgetTitle;